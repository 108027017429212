import _ from 'lodash'

import { CHAT_CONTENT_TYPES } from 'constants/constants'

import localStore from 'app/services/state/local-store'
import {
  getStickersAndCategories,
  updateRecentlyUsedStickers,
} from 'lib/sticker-utils'

import {
  actions as channelRoomActions,
  ACTION_TYPES as CHANNEL_ROOM_ACTION_TYPES,
  STORE_NAME as CHANNEL_ROOM_STORE_NAME,
} from 'app/state/api/channels/channel-room.reducer'
import {
  ACTION_TYPES,
  STORE_NAME,
  actions,
  selectors,
} from './stickers.reducer'

const categorizedStickers = getStickersAndCategories()

const middleware = (store) => (next) => (action) => {
  const { dispatch, getState } = store

  switch (action.type) {
    case ACTION_TYPES.INIT:
      updateRecentlyUsedStickers()

      dispatch(
        actions.initializeStickers({
          categories: categorizedStickers.categories,
          stickersByCategory: categorizedStickers.categoryToStickersMap,
        })
      )

      break

    case CHANNEL_ROOM_ACTION_TYPES.UPDATED_MEMBER_META:
      {
        const { [CHANNEL_ROOM_STORE_NAME]: channelRoom } = getState()

        if (action.memberId === channelRoom.member_id && action.meta.sticker) {
          const { [STORE_NAME]: stickers } = getState()
          const { sticker } = action.meta

          const recentlyUsed = selectors.getNextRecentlyUsed(stickers, sticker)

          if (action.meta.sticker.length > 0) {
            localStore.setRecentlyUsedStickers(recentlyUsed)

            dispatch(actions.updateRecentlyUsed(recentlyUsed))

            dispatch(
              channelRoomActions.sendChatMessage(
                {
                  content: {
                    type: CHAT_CONTENT_TYPES.STICKER,
                    sticker,
                  },
                },
                '*',
                action.memberId
              )
            )
          }
        }
      }
      break

    default:
      break
  }
  return next(action)
}

export default middleware
