import env from 'app/config/env'
import UAParser from 'ua-parser-js'

const uaParser = new UAParser()

const uaResults = uaParser.getResult()

const OS_NAMES = {
  ANDROID: 'Android',
  CHROMIUM_OS: 'Chromium OS',
  WINDOWS: 'Windows',
  MAC_OS: 'Mac OS',
}

// NOTE: These names are used directly in the config to set supported browsers
const BROWSER_NAMES = {
  CHROMIUM: 'Chromium',
  EDGE: 'Edge',
  OPERA: 'Opera',

  FIREFOX: 'Firefox',
  SAFARI: 'Safari',
  MOBILE_SAFARI: 'Mobile Safari',
}

const DEVICE_TYPES = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  SMART_TV: 'smarttv',
}

const SUPPORTED_BROWSERS = env('supported_browsers', [
  BROWSER_NAMES.CHROMIUM,
  BROWSER_NAMES.EDGE,
  BROWSER_NAMES.OPERA,

  BROWSER_NAMES.FIREFOX,
  BROWSER_NAMES.SAFARI,
  BROWSER_NAMES.MOBILE_SAFARI,
])

const SUPPORTED_BROWSER_VERSION = {
  [BROWSER_NAMES.CHROMIUM]: env('chrome_minimum_version', undefined, ''),
  [BROWSER_NAMES.OPERA]: env('chrome_minimum_version', undefined, ''),
  [BROWSER_NAMES.EDGE]: env('chrome_minimum_version', undefined, ''),

  [BROWSER_NAMES.FIREFOX]: env('firefox_minimum_version', undefined, ''),
  [BROWSER_NAMES.SAFARI]: env('safari_minimum_version', undefined, ''),
  [BROWSER_NAMES.MOBILE_SAFARI]: env(
    'ios_safari_minimum_version',
    undefined,
    ''
  ),
}

function isChromiumBasedBrowser() {
  return !!window.chrome
}

function getDeviceType() {
  return uaResults.device.type
}

function getBrowserName(): string {
  if (isChromiumBasedBrowser()) {
    return BROWSER_NAMES.CHROMIUM
  }
  return uaResults.browser.name
}

/**
 * Returns the major version of the used browser. When the version of the browser is
 * formatted invalid or couldn't be extracted from the useragent string, NaN is returned.
 * @returns
 */
function getVersion() {
  let { version } = uaResults.browser

  if (isChromiumBasedBrowser()) {
    // This regex checks if the browser engine is the correct Chromium browser engine
    const match = uaResults.ua.match(/webkit\/537\.36.+chrome\/(?!27)([\w.]+)/i)
    if (match && match.length) {
      version = match[1]
    }
  }

  // When the version could not be extracted from the ua result return NaN.
  if (!version) {
    return Number.NaN
  }

  return +version.split('.')[0]
}

function getOSName() {
  return uaResults.os.name
}

function isAndroid() {
  return getOSName() === OS_NAMES.ANDROID
}

function isWindows() {
  return getOSName() === OS_NAMES.WINDOWS
}

function isChromiumOS() {
  return getOSName() === OS_NAMES.CHROMIUM_OS
}

function isMacOS() {
  return getOSName() === OS_NAMES.MAC_OS
}

function isChromium() {
  return getBrowserName() === BROWSER_NAMES.CHROMIUM
}

function isEdge() {
  return getBrowserName() === BROWSER_NAMES.EDGE
}

function isOpera() {
  return getBrowserName() === BROWSER_NAMES.OPERA
}

function isFirefox() {
  return getBrowserName() === BROWSER_NAMES.FIREFOX
}

function isSafari() {
  return getBrowserName() === BROWSER_NAMES.SAFARI
}

function isMobileSafari() {
  return getBrowserName() === BROWSER_NAMES.MOBILE_SAFARI
}

function isMobile() {
  return getDeviceType() === DEVICE_TYPES.MOBILE
}

function isTablet() {
  return getDeviceType() === DEVICE_TYPES.TABLET
}

function isMobileTouchDevice() {
  return isMobile() || isTablet()
}

function isSupportedVersion() {
  const version = getVersion()
  const name = getBrowserName()
  const requiredVersion = SUPPORTED_BROWSER_VERSION[name]

  return requiredVersion && version >= +SUPPORTED_BROWSER_VERSION[name]
}

function isWebRTCSupported() {
  // Some browsers like Firefox allow users to disable WebRTC
  if (!window.RTCPeerConnection) return false
  if (!navigator.mediaDevices) return false
  if (!navigator.mediaDevices.getUserMedia) return false
  if (!navigator.mediaDevices.enumerateDevices) return false

  return true
}

function isSupportedBrowser() {
  if (!isWebRTCSupported()) return false

  if (getOSName() === 'iOS' && !isMobileSafari())
    // NOTE: On iOS, only safari is currently supported
    return false
  // NOTE: On Android, only chromium-browsers are currently supported
  if (getOSName() === 'Android' && !isChromium()) return false

  return SUPPORTED_BROWSERS.indexOf(getBrowserName()) > -1 || isElectron()
}

/**
 * boolean indicating whether or not current javascript is running in electron window
 * @see https://github.com/electron/electron/issues/2288
 */
function isElectron() {
  return window.screenSharing !== undefined
}

function usesBrowserHistory() {
  return !isElectron()
}

function usesHashHistory() {
  return !usesBrowserHistory()
}

function usesTouchDragBackend() {
  return isMobileTouchDevice() || isChromium()
}

/**
 * Checks whether the browser supports WebGL without major performance caveat
 * @returns
 */
function isWebGLSupported() {
  const contextTypes = ['webgl', 'experimental-webgl', 'webgl2']

  const getContext = (canvas) => (contextId) =>
    canvas.getContext(contextId, { failIfMajorPerformanceCaveat: true })

  const canvas = document.createElement('canvas')

  return contextTypes.some(getContext(canvas))
}

/**
 * Checks whether the browser supports setting the sinkId of an HTMLMediaElement.
 * Read more: https://caniuse.com/?search=setSinkId
 * @returns
 */
function isSinkIdSupported() {
  return !!HTMLMediaElement.prototype.setSinkId
}

export default {
  SUPPORTED_BROWSERS,
  SUPPORTED_BROWSER_VERSION,

  getDeviceType,
  getBrowserName,
  getVersion,
  getOSName,

  isAndroid,
  isWindows,
  isChromiumOS,
  isMacOS,

  isChromiumBasedBrowser,

  isChromium,
  isFirefox,
  isEdge,
  isOpera,
  isSafari,
  isMobileSafari,

  isMobile,
  isTablet,
  isMobileTouchDevice,

  isSupportedVersion,
  isSupportedBrowser,

  isElectron,

  usesBrowserHistory,
  usesHashHistory,
  usesTouchDragBackend,

  isWebGLSupported,
  isSinkIdSupported,
  isWebRTCSupported,
}
