import Task from 'lib/tasks/coligo-task'

import { actions as channelConferenceActions } from 'app/state/api/channels/channel-conference.reducer'

/**
 * @class
 * @classdesc JoinConferenceChannelTask joins the conference channel
 */
class JoinConferenceChannelTask extends Task {
  name = 'JoinConferenceChannelTask'

  store

  constructor(store) {
    super()

    this.store = store
  }

  async run() {
    await this.store.dispatch(channelConferenceActions.join())
  }
}

export default JoinConferenceChannelTask
