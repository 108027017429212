import { useState, useEffect } from 'react'
import _ from 'lodash'

import browserUtils from 'lib/browser-utils'

import { useOrientationChange, useWindowResize } from './use-event.hook'

const isMobile = browserUtils.isMobileTouchDevice()
const supportsWindowScreen = !!window.screen.orientation

const portraitPrimary = 'portrait-primary'
const portraitSecondary = 'portrait-secondary'
const landscapePrimary = 'landscape-primary'
const landscapeSecondary = 'landscape-secondary'

/**
 * A hook returning the viewport size of the current window.
 * @return
 */
export default function useWindowSize() {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    ratio: window.innerWidth / window.innerHeight,
  })

  useWindowResize((e) => {
    setSize({
      width: e.target.innerWidth,
      height: e.target.innerHeight,
      ratio: e.target.innerWidth / e.target.innerHeight,
    })
  })

  return size
}

/**
 * A hook returning the viewport width of the current window.
 * @return
 */
export function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth)

  useWindowResize((e) => {
    if (e.target.innerWidth !== width) {
      setWidth(e.target.innerWidth)
    }
  })

  return width
}

/**
 * A hook returning the viewport height of the current window.
 * @return
 */
export function useWindowHeight() {
  const [height, setHeight] = useState(window.innerHeight)

  useWindowResize((e) => {
    if (e.target.innerHeight !== height) {
      setHeight(e.target.innerHeight)
    }
  })

  return height
}

/**
 * A function returning the current window angle as an orientation-string
 * @return
 */
function angleToOrientation(orientation) {
  const angleOrientations = {
    0: portraitPrimary,
    180: portraitSecondary,
    90: landscapePrimary,
    '-90': landscapeSecondary,
  }

  return angleOrientations[orientation]
}

/**
 * A hook returning the orientation of the current window on mobile devices.
 * @return
 */
export function useWindowOrientationMobile() {
  const [orientation, setOrientation] = useState(
    supportsWindowScreen
      ? window.screen.orientation.type
      : angleToOrientation(window.orientation)
  )

  useOrientationChange((e) => {
    if (
      supportsWindowScreen &&
      e.target.screen.orientation.type !== orientation
    ) {
      setOrientation(e.target.screen.orientation.type)
    } else if (angleToOrientation(e.orientation) !== orientation) {
      setOrientation(angleToOrientation(window.orientation))
    }
  })

  return orientation
}

/**
 * A hook returning the orientation of the current window on non-mobile devices.
 * @return
 */
export function useWindowOrientationDesktop() {
  const { width, height } = useWindowSize()
  const isOrientation = width < height ? portraitPrimary : landscapePrimary

  const [orientation, setOrientation] = useState(isOrientation)

  if (isOrientation !== orientation) setOrientation(isOrientation)

  return orientation
}

/**
 * A hook returning the viewport sizes of different orientations of the current window on mobile devices.
 * @param orientation
 * @param viewportHeight
 * @return
 */
export function useInitialViewport(orientation, viewportHeight) {
  const [orientations, setOrientations] = useState({})

  useEffect(() => {
    if (!_.size(orientations)) {
      const { availHeight, availWidth } = window.screen
      const toolbarHeight = availHeight - viewportHeight

      const orientationsPort = [portraitPrimary, portraitSecondary]
      const orientationsLands = [landscapePrimary, landscapeSecondary]

      const isPortrait = orientationsPort.includes(orientation)

      _.forEach(isPortrait ? orientationsPort : orientationsLands, (i) => {
        orientations[i] = {
          initialHeight: availHeight - toolbarHeight,
          initialWidth: availWidth,
        }
      })

      _.forEach(isPortrait ? orientationsLands : orientationsPort, (j) => {
        orientations[j] = {
          initialHeight: availWidth - toolbarHeight,
          initialWidth: availHeight,
        }
      })

      setOrientations(orientations)
    }
  }, [])

  return orientations
}

/**
 * A hook returning a boolean on if the virtual keyboard is currently open on mobile devices.
 * @param initialValues
 * @param orientation
 * @param viewportHeight
 * @return
 */
export function useKeyboardMobile(initialValues, orientation, viewportHeight) {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false)

  const isDifferent = _.size(initialValues)
    ? initialValues[orientation].initialHeight > viewportHeight
    : false

  useEffect(() => {
    if (isMobile && isKeyboardOpen !== isDifferent) {
      setIsKeyboardOpen(isDifferent)
    }
  }, [isDifferent])

  return isKeyboardOpen
}
