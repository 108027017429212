import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { useId } from 'app/react/hooks/id-registry.hooks'
import Button from 'ui/revision/button'
import Dialog, { DialogContent, DialogActions } from 'ui/revision/dialog'
import { Typography } from 'ui/revision'

function ConfirmDialog({ content, title, onCancel, onSubmit, ...other }) {
  const { t } = useTranslation()
  const { id } = useId()
  return (
    <Dialog
      {...other}
      open
      header={<Typography>title</Typography>}
      onClosed={onCancel}
    >
      <DialogContent>
        <Typography>{content}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          data-testid={id('Dialogs.Confirm.cancel')}
          key='cancel'
          onClick={onCancel}
        >
          {t('common.actions.cancel', 'Cancel')}
        </Button>
        <Button
          color='primary'
          data-testid={id('Dialogs.Confirm.ok')}
          key='submit'
          type='submit'
          onClick={onSubmit}
        >
          {t('common.actions.ok', 'Ok')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmDialog.propTypes = {
  content: PropTypes.node,
  title: PropTypes.node,

  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}

ConfirmDialog.defaultProps = {
  content: '',
  title: '',

  onSubmit: Function.prototype,
  onCancel: Function.prototype,
}

export default ConfirmDialog
