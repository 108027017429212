import type { SVGProps } from 'react'
const SvgEmoji14 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle cx={79.4} cy={96.28} r={48.66} fill='rgb(var(--color-brand-100))' />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.4 146.94c-27.93 0-50.66-22.72-50.66-50.66S51.46 45.62 79.4 45.62s50.66 22.72 50.66 50.66-22.72 50.66-50.66 50.66zm0-97.31c-25.73 0-46.66 20.93-46.66 46.66s20.93 46.66 46.66 46.66 46.66-20.93 46.66-46.66-20.93-46.66-46.66-46.66z'
    />
    <circle
      cx={52.89}
      cy={91.3}
      r={11.99}
      fill='rgb(var(--color-neutral-50))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M52.89 104.54c-7.3 0-13.25-5.94-13.25-13.24s5.94-13.24 13.25-13.24S66.13 84 66.13 91.3s-5.94 13.24-13.24 13.24zm0-23.99c-5.92 0-10.75 4.82-10.75 10.74s4.82 10.74 10.75 10.74 10.74-4.82 10.74-10.74-4.82-10.74-10.74-10.74z'
    />
    <circle cx={52.89} cy={91.3} r={3.1} fill='rgb(var(--color-brand-700))' />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M91.55 117.87H65.98c-1.08 0-1.96-.88-1.96-1.96s.88-1.96 1.96-1.96h25.57c1.08 0 1.96.88 1.96 1.96s-.88 1.96-1.96 1.96z'
    />
    <circle
      cx={106.38}
      cy={91.3}
      r={11.99}
      fill='rgb(var(--color-neutral-50))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M106.38 104.54c-7.3 0-13.24-5.94-13.24-13.24s5.94-13.24 13.24-13.24S119.62 84 119.62 91.3s-5.94 13.24-13.24 13.24zm0-23.99c-5.92 0-10.74 4.82-10.74 10.74s4.82 10.74 10.74 10.74 10.74-4.82 10.74-10.74-4.82-10.74-10.74-10.74z'
    />
    <circle cx={106.38} cy={91.3} r={3.1} fill='rgb(var(--color-brand-700))' />
  </svg>
)
export default SvgEmoji14
