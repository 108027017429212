import { useMemo } from 'react'
import { shallowEqual } from 'react-redux'

import { useSelector } from 'app/services/state/redux-store'
import { STORE_NAME as CHANNEL_ROOM_STORE_NAME } from 'app/state/api/channels/channel-room.reducer'
import { getUser, isDialIn } from 'app/state/utils'
import { Icons } from 'constants/icons'
import getUserInitials from 'lib/get-user-initials'
import { Avatar } from 'ui/revision'

type Props = {
  avatarImage?: string
  memberId: string
  userName?: string
  overlayIcon?: Icons
} & React.ComponentPropsWithRef<typeof Avatar>

export const AvatarContainer: React.FC<Props> = ({
  avatarImage: imageFromProps,
  memberId,
  userName: userNameFromProps,
  pictogram: pictogramFromProps,
  ...other
}: Props) => {
  const { avatarImage, userName, isDialInUser } = useSelector(
    ({ [CHANNEL_ROOM_STORE_NAME]: channelRoom }) => {
      const member = getUser(channelRoom, memberId)
      const isDialInUser = isDialIn(member)
      return {
        avatarImage: member?.meta.avatarImage,
        userName: member?.meta.userName,
        isDialInUser: isDialInUser,
      }
    },
    shallowEqual
  )

  const pictogram = useMemo(() => {
    // NOTE: eds avatar will fallback to pictogram 'other--avatar--dummy'
    if (isDialInUser) {
      return Icons.communicationDevicePhone
    } else if (pictogramFromProps) {
      // NOTE: overlay will for now not be displayed as semi transparent avatar as it won't
      // be displayed like that in new designs
      return pictogramFromProps
    }
  }, [isDialInUser, pictogramFromProps])

  const initials: string | undefined = useMemo(() => {
    // If the user is dialing in or has a pictogram, do not show their initials.
    if (isDialInUser || pictogram) {
      return undefined
    }
    // If the user has a custom name, use it to get the initials. Otherwise, use the actual user name.
    return getUserInitials(userNameFromProps || userName) as string
  }, [isDialInUser, pictogram, userNameFromProps, userName])

  return (
    <Avatar
      {...other}
      initials={initials}
      pictogram={pictogram}
      src={imageFromProps || avatarImage}
    />
  )
}
export default AvatarContainer
