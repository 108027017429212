import _ from 'lodash'

import { DEFAULT_MAX_GUESTS, USER_STATUS } from 'constants/constants'

import {
  actions as channelRoomActions,
  STORE_NAME as CHANNEL_ROOM_STORE_NAME,
} from 'app/state/api/channels/channel-room.reducer'
import { STORE_NAME as FLAGS_STORE_NAME } from 'app/features/flags/flags.reducer'

import {
  getOnlineGuests,
  getUser,
  isRoomAudioMuted,
  isRoomVideoMuted,
  phoneTypePredicate,
} from 'app/state/utils'

import FeatureUtils from 'lib/feature-utils'

import { START_RIGHTS } from './waiting-room.constants'

function getRightsByMemberType(type) {
  const rights = START_RIGHTS[type]

  if (!rights) {
    throw new Error('No start rights for given member type')
  }

  return rights
}

/**
 * Give rights to the guest and afterwards move him to the room.
 * @param memberId
 * @param [type='guest']
 * @return
 */
export const putGuestInRoom =
  (memberId, type = 'guest') =>
  async (dispatch, getState) => {
    const rights = getRightsByMemberType(type)

    if (isRoomVideoMuted(getState()[CHANNEL_ROOM_STORE_NAME])) {
      rights.video_send = false
    }

    await channelRoomActions.setMemberRights(memberId, rights)

    if (isRoomAudioMuted(getState()[CHANNEL_ROOM_STORE_NAME])) {
      await dispatch(channelRoomActions.powermuteAudio(memberId, true, false))
    }

    // Move the user in the room after the rights has been given.
    await channelRoomActions.updateMemberMeta(memberId, {
      modified: Date.now(),
      status: USER_STATUS.IN_ROOM,
    })
  }

/**
 * Get the amount of space left in the room.
 * @param channelRoom
 * @return
 */
export const getRoomLeft = (channelRoom, flags) => {
  const onlineGuests = _.chain(getOnlineGuests(channelRoom))
    .reject(phoneTypePredicate)
    .size()
    .value()
  const maxGuests =
    +FeatureUtils.getMaxGuests(channelRoom, flags) || DEFAULT_MAX_GUESTS

  return maxGuests - onlineGuests
}

/**
 * Automatically approve the guests which are in the waiting room.
 * @param waitingGuests
 * @param store
 * @param store.dispatch
 * @param store.getState
 * @return
 */
export const autoApproveWaitingGuests = async (waitingGuests, store) => {
  const { [CHANNEL_ROOM_STORE_NAME]: channelRoom, [FLAGS_STORE_NAME]: flags } =
    store.getState()
  /**
   * Amount of space left in the room.
   *
   */
  const roomLeft = getRoomLeft(channelRoom, flags)

  const approvableGuests = [
    // Dial in users
    ...waitingGuests.filter((memberId) => {
      const member = getUser(channelRoom, memberId)
      return _.isMatch(member, phoneTypePredicate)
    }),
    // Regular guests (capped to the space left into the meeting)
    ...waitingGuests
      .filter((memberId) => {
        const member = getUser(channelRoom, memberId)
        return !_.isMatch(member, phoneTypePredicate)
      })
      .slice(0, roomLeft),
  ]

  await Promise.all(
    approvableGuests.map((memberId) => {
      const member = getUser(channelRoom, memberId)
      return store.dispatch(putGuestInRoom(memberId, member.meta.type))
    })
  )
}
