import { Schema } from 'app/services/commands/schema'

import { alt, main, shift } from 'constants/keys'
import ACTIONS from 'constants/shortcut-actions'

const schema = new Schema('coligo', [
  { action: ACTIONS.ROOM_LINK_COPY, keys: [alt, 'i'] },
  { action: ACTIONS.END_MEETING, keys: [alt, 'e'] },
  { action: ACTIONS.LOGOUT, keys: [alt, 'l'] },
  { action: ACTIONS.SET_VIEW_ACTIVE_SPEAKER, keys: [alt, 'a'] },
  { action: ACTIONS.SET_VIEW_GRID, keys: [alt, 'g'] },
  { action: ACTIONS.TABS_NEXT, keys: [alt, 'right'] },
  { action: ACTIONS.TABS_PREVIOUS, keys: [alt, 'left'] },
  { action: ACTIONS.TOGGLE_DIALOG_INVITE, keys: [alt, 'c'] },
  { action: ACTIONS.TOGGLE_DIALOG_SHORTCUTS, keys: [alt, 'h'] },
  { action: ACTIONS.TOGGLE_DIALOG_STICKERS, keys: [main, alt, 's'] },
  { action: ACTIONS.TOGGLE_FOOTER, keys: [alt, 'f'] },
  { action: ACTIONS.TOGGLE_FULLSCREEN, keys: [main, shift, 'f'] },
  { action: ACTIONS.TOGGLE_MEDIA_LOCAL_MICROPHONE, keys: [alt, 'm'] },
  // {
  //   action: ACTIONS.TOGGLE_PUSH_TO_TALK_START,
  //   keys: { keys: 't', state: 'keydown' },
  // },
  // {
  //   action: ACTIONS.TOGGLE_PUSH_TO_TALK_STOP,
  //   keys: { keys: 't', state: 'keyup' },
  // },
  { action: ACTIONS.TOGGLE_RAISE_HAND, keys: [main, shift, 'h'] },
  { action: ACTIONS.TOGGLE_MEDIA_ROOM_AUDIO, keys: [main, shift, 'm'] },
  { action: ACTIONS.TOGGLE_MEDIA_ROOM_VIDEO, keys: [main, shift, 'v'] },
  { action: ACTIONS.TOGGLE_SCREENSHARE, keys: [alt, 's'] },
  { action: ACTIONS.TOGGLE_SESSION_RECORDING, keys: [main, shift, 'r'] },
  // TODO: reintroduce the ability to open the settings view with a shortcut
  // { action: ACTIONS.TOGGLE_SETTINGS, keys: [main, shift, 'r'] },
  { action: ACTIONS.TOGGLE_MEDIA_LOCAL_VIDEO, keys: [alt, 'v'] },
  { action: ACTIONS.TOGGLE_SIDE_PANEL, keys: [alt, 's'] },
])
export default schema
