import { ClTextField } from '@enreach/core-component-library-react'
import { defineCustomElementClTextField } from '@enreach/core-component-library/dist/components/index'

import cn from 'clsx'
import {
  cloneElement,
  forwardRef,
  useCallback,
  useMemo,
  type ForwardedRef,
  type ReactElement,
} from 'react'

import { TextFieldChangeEvent, type TextFieldProps } from './text-field.types'

import classNames from './text-field.module.scss'

// TODO: Make text field transparent by default to match designs
export const TextField = forwardRef(function TextField(
  {
    className,
    error,
    name,
    prefix,
    suffix,
    min,
    max,
    onChange,
    ...other
  }: TextFieldProps,
  ref: ForwardedRef<HTMLClTextFieldElement>
) {
  const children = useMemo(() => {
    const ret: ReactElement[] = []

    if (prefix) {
      ret.push(cloneElement(prefix, { key: ret.length, slot: 'leading' }))
    }
    if (suffix) {
      ret.push(cloneElement(suffix, { key: ret.length, slot: 'action' }))
    }
    return ret
  }, [prefix, suffix])

  const onInputHandler = useCallback(
    (event: TextFieldChangeEvent) => {
      const value = event.target.value || event.detail
      onChange(value)
    },
    [onChange]
  )

  return (
    <ClTextField
      uncontrolled
      className={cn(classNames.errorHelperText, className)}
      error={error}
      inputName={name}
      ref={ref}
      onInput={onInputHandler} // Required for react-hook-form
      min={min && parseInt(String(min), 10)}
      max={min && parseInt(String(max), 10)}
      {...other}
    >
      {children}
    </ClTextField>
  )
})

export default TextField

defineCustomElementClTextField()
