import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { alpha } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import cn from 'clsx'
import 'draft-js-emoji-plugin/lib/plugin.css'

import useForkRef from 'lib/react/hooks/use-fork-ref.hook'

import { withStyles } from 'app/ui'
import { FONTS } from 'constants/constants'

const styles = (theme) => ({
  disabled: {
    position: 'relative',
    backgroundColor: alpha('#000', 0.12),
    color: alpha('#000', 0.67),
  },

  editor: {
    fontFamily: `${FONTS.emojiFont}, "Roboto", sans-serif`,
    fontWeight: 100,
    padding: theme.spacing(2, 8, 2, 2),
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    border: 'none',
    resize: 'none',
  },
})

const ChatInputMobileEditor = ({
  classes,
  className,
  countSize,
  'data-testid': dataTestid,
  disabled,
  emojiPickerPlugin,
  inputRef,
  messageIsSending,
  onFocus,
  onInputChange,
  onSaveTempMessage,
  placeholder,
  tempMessage,
}) => {
  const mobileEditor = useForkRef(inputRef)

  const [chatInput, setChatInput] = useState(tempMessage)

  const [cursorPosition, setCursorPosition] = useState({})

  const focusEditor = useCallback(() => {
    if (mobileEditor.current) mobileEditor.current.focus()
  }, [mobileEditor])

  const addEmojiToChat = useCallback(
    (emojiString) => {
      focusEditor()
      setChatInput(emojiString)
    },
    [disabled, focusEditor, setChatInput]
  )

  const handleChange = useCallback(
    (e) => {
      setChatInput(e.target.value)
    },
    [setChatInput]
  )
  const handleSubmit = useCallback(() => {
    focusEditor()
    setChatInput('')
  }, [focusEditor, setChatInput])

  const handleCursorPositionClick = useCallback(() => {
    setCursorPosition({
      start: mobileEditor.current.selectionStart,
      end: mobileEditor.current.selectionEnd,
    })
  }, [mobileEditor, setCursorPosition])

  useEffect(() => {
    emojiPickerPlugin.initialize({
      getChatInput: { chatInput, cursorPosition },
      setChatInput: addEmojiToChat,
    })
  }, [chatInput, cursorPosition])

  useEffect(() => {
    if (typeof inputRef === 'function') {
      inputRef(mobileEditor.current)
    }

    return () => {
      if (mobileEditor.current) onSaveTempMessage(mobileEditor.current.value)
    }
  }, [inputRef])

  useEffect(() => {
    if (!disabled) setCursorPosition({ start: countSize, end: countSize })
  }, [disabled, countSize])

  useEffect(() => {
    onInputChange(chatInput)

    if ((disabled && chatInput !== '') || typeof chatInput !== 'string')
      setChatInput('')
  }, [chatInput, disabled])

  useEffect(() => {
    if (messageIsSending) handleSubmit()
  }, [messageIsSending])

  return (
    <Grid item>
      <textarea
        className={cn(classes.editor, disabled && classes.disabled, className)}
        data-testid={dataTestid}
        id='ChatInputMobileEditor'
        placeholder={placeholder}
        readOnly={disabled}
        ref={mobileEditor}
        value={chatInput}
        onChange={handleChange}
        onClick={!disabled && handleCursorPositionClick}
        onFocus={onFocus}
      />
    </Grid>
  )
}

ChatInputMobileEditor.propTypes = {
  classes: PropTypes.shape({
    disabled: PropTypes.string,
    editor: PropTypes.string,
  }).isRequired,

  className: PropTypes.string,
  countSize: PropTypes.number,
  'data-testid': PropTypes.string,
  disabled: PropTypes.bool,
  emojiPickerPlugin: PropTypes.objectOf(PropTypes.func),
  inputRef: PropTypes.oneOf([PropTypes.func, PropTypes.object]),
  messageIsSending: PropTypes.bool,
  placeholder: PropTypes.string,
  tempMessage: PropTypes.string,

  onFocus: PropTypes.func,
  onInputChange: PropTypes.func,
  onSaveTempMessage: PropTypes.func,
}

ChatInputMobileEditor.defaultProps = {
  className: '',
  countSize: 0,
  'data-testid': '',
  disabled: false,
  emojiPickerPlugin: {},
  inputRef: {},
  placeholder: '',
  messageIsSending: false,
  tempMessage: '',

  onFocus: Function.prototype,
  onInputChange: Function.prototype,
  onSaveTempMessage: Function.prototype,
}

export default withStyles(styles, { name: 'ChatInputMobileEditor' })(
  ChatInputMobileEditor
)
