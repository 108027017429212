import _ from 'lodash'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { bindActionCreators } from 'redux'

import { useId } from 'app/react/hooks/id-registry.hooks'
import { useDispatch, useSelector } from 'app/services/state/redux-store'
import ChatInput from 'ui/chat-input'
import {
  STORE_NAME as PRIVATE_CHATS_STORE_NAME,
  actions,
} from '../private-chats.reducer'

type Props = {
  chatId: string
  chatsNo: number
  disabled: boolean
}

const PrivateChatInput: React.FC<Props> = ({ chatId, disabled }: Props) => {
  const { id } = useId()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const chatsNo = useSelector(
    ({ [PRIVATE_CHATS_STORE_NAME]: privateChats }) => {
      return _.size(_.get(privateChats, 'chats', []))
    }
  )

  const onSubmit = useCallback(
    bindActionCreators(
      (message) => actions.sendMessage(chatId, message),
      dispatch
    ),
    []
  )
  const onSaveTempMessage = useCallback(
    bindActionCreators(
      (message) => actions.saveTempChat(chatId, message),
      dispatch
    ),
    []
  )
  const onTyping = useCallback(
    bindActionCreators(
      (typing) => actions.sendUserTyping(chatId, typing),
      dispatch
    ),
    []
  )

  const placeholder = disabled
    ? t('RoomPage.Chat.disabledInputState')
    : t(`RoomPage.${chatsNo ? 'MessagePlaceholder' : 'Chat.inputPlaceholder'}`)

  return (
    <ChatInput
      autoFocus
      InputProps={{ 'data-testid': id('PrivateChat.input') }}
      placeholder={placeholder}
      SendButtonProps={{ 'data-testid': id('PrivateChat.privateChatSend') }}
      onSaveTempMessage={onSaveTempMessage}
      onSubmit={onSubmit}
      onTyping={onTyping}
    />
  )
}

export default PrivateChatInput
