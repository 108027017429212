import type { SVGProps } from 'react'
const SvgReaction2Coffee = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M124.06 82.81c-8.01 0-14.53-6.52-14.53-14.53s6.52-14.53 14.53-14.53 14.53 6.52 14.53 14.53-6.52 14.53-14.53 14.53zm0-20.61c-3.35 0-6.07 2.73-6.07 6.08s2.72 6.08 6.07 6.08 6.08-2.73 6.08-6.08-2.73-6.08-6.08-6.08z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M124.06 55.24c7.19 0 13.03 5.85 13.03 13.03s-5.85 13.03-13.03 13.03-13.03-5.85-13.03-13.03 5.85-13.03 13.03-13.03m0 20.61c4.18 0 7.58-3.4 7.58-7.58s-3.4-7.58-7.58-7.58-7.57 3.4-7.57 7.58 3.4 7.58 7.57 7.58m0-23.61c-8.84 0-16.03 7.19-16.03 16.03s7.19 16.03 16.03 16.03 16.03-7.19 16.03-16.03-7.19-16.03-16.03-16.03zm0 20.61c-2.52 0-4.57-2.05-4.57-4.58s2.05-4.58 4.57-4.58 4.58 2.05 4.58 4.58-2.05 4.58-4.58 4.58z'
    />
    <path
      fill='rgb(var(--color-brand-100))'
      d='M17.92 113.81c2.86 16.7 28.96 29.78 60.75 29.78s57.89-13.08 60.75-29.78H17.92z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M78.67 145.1c-32.45 0-59.2-13.34-62.23-31.03l-.3-1.75h125.05l-.3 1.75c-3.03 17.69-29.78 31.03-62.23 31.03zM19.8 115.32c4.26 15.16 29.5 26.78 58.87 26.78s54.61-11.63 58.87-26.78H19.8z'
    />
    <ellipse
      cx={78.67}
      cy={109.96}
      fill='rgb(var(--color-neutral-50))'
      rx={61.02}
      ry={26.06}
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M78.67 138.01c-35.34 0-63.02-12.33-63.02-28.06s27.68-28.06 63.02-28.06 63.02 12.33 63.02 28.06-27.68 28.06-63.02 28.06zm0-52.12c-31.99 0-59.02 11.02-59.02 24.06s27.03 24.06 59.02 24.06 59.02-11.02 59.02-24.06-27.03-24.06-59.02-24.06z'
    />
    <ellipse
      cx={78.67}
      cy={107.75}
      fill='rgb(var(--color-brand-100))'
      rx={29.77}
      ry={12.69}
    />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M113.2 55.8c0 7.4 5.14 59.54-34.53 59.54S44.14 63.2 44.14 55.8 59.6 42.4 78.67 42.4s34.53 6 34.53 13.4z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M78.67 116.84c-9.83 0-17.82-3.12-23.76-9.28-13.6-14.1-12.68-39.75-12.33-49.4.04-1.01.07-1.81.07-2.36 0-8.35 15.83-14.9 36.03-14.9s36.03 6.54 36.03 14.9c0 .55.03 1.35.07 2.36.35 9.65 1.27 35.29-12.33 49.4-5.94 6.16-13.94 9.28-23.76 9.28zm0-72.94c-15.62 0-33.03 4.89-33.03 11.9 0 .57-.03 1.41-.07 2.47-1.23 34.02 8.34 43.95 11.49 47.21 5.35 5.55 12.62 8.36 21.6 8.36s16.25-2.81 21.61-8.36c12.72-13.19 11.79-38.79 11.49-47.21-.04-1.06-.07-1.89-.07-2.47 0-7.01-17.41-11.9-33.03-11.9z'
    />
    <path
      fill='rgb(var(--color-brand-100))'
      d='M79.35 103.97c-8.91 0-17.45-2.19-24.05-6.17-.95-.57-1.25-1.8-.68-2.74.57-.95 1.8-1.25 2.74-.68 5.99 3.61 13.8 5.59 21.99 5.59 7.56 0 14.91-1.72 20.7-4.86.97-.53 2.19-.17 2.71.81a2 2 0 0 1-.81 2.71c-6.36 3.44-14.39 5.34-22.6 5.34z'
    />
    <ellipse
      cx={78.67}
      cy={55.36}
      fill='rgb(var(--color-brand-100))'
      rx={34.53}
      ry={12.96}
    />
    <path
      fill='rgb(var(--color-brand-500))'
      d='M78.67 69.82c-20.2 0-36.03-6.35-36.03-14.46S58.47 40.9 78.67 40.9s36.03 6.35 36.03 14.46-15.83 14.46-36.03 14.46zm0-25.92c-15.62 0-33.03 4.71-33.03 11.46s17.41 11.46 33.03 11.46 33.03-4.71 33.03-11.46S94.29 43.9 78.67 43.9z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M78.67 49.7c-15.45 0-27.97 4.7-27.97 10.5 0 1.25.58 2.44 1.64 3.55 6.33 2.8 15.77 4.58 26.33 4.58s19.99-1.78 26.33-4.58c1.06-1.11 1.64-2.3 1.64-3.55 0-5.8-12.52-10.5-27.97-10.5z'
    />
    <path
      fill='rgb(var(--color-brand-500))'
      d='M78.67 69.82c-10.35 0-20.17-1.72-26.93-4.71l-.27-.12-.21-.21c-1.37-1.43-2.06-2.97-2.06-4.59 0-5.82 10.33-12 29.47-12s29.47 6.18 29.47 12c0 1.62-.69 3.16-2.06 4.59l-.21.21-.27.12c-6.77 2.99-16.58 4.71-26.93 4.71zm-25.44-7.33c6.39 2.75 15.63 4.33 25.44 4.33s19.05-1.58 25.44-4.33c.69-.78 1.03-1.55 1.03-2.3 0-4.26-10.87-9-26.47-9s-26.47 4.74-26.47 9c0 .75.35 1.52 1.03 2.3z'
    />
    <path
      fill='rgb(var(--color-brand-50))'
      d='M80.26 56.6c-.41-.05-.74-.36-.84-.76-.75-3.14-1.83-5.34-3.62-7.34-2.03-2.15-3.77-4.18-5.3-6.17-.31-.44-.69-.99-.98-1.54-1.53-2.75-1.82-6.19-.77-9.43 1.05-3.23 3.3-5.84 6.18-7.17 1.59-.78 3.39-1.2 5.19-1.2.56 0 1.11.04 1.65.12.35.07.75.13 1.15.2.56.09 1.15.18 1.57.28.39.09.81.14 1.23.14 1.58 0 3.2-.68 4.44-1.86.62-.6 1.23-1.19 1.84-1.71.18-.16.41-.24.64-.24a1.012 1.012 0 0 1 1 1.07c-.37 5.69-4.25 9.61-9.89 9.98-.5.05-1.79.16-2.34.2-2.27.14-4.19 2.2-4.1 4.4 0 .77.22 1.56.61 2.22l.26.46c2.89 5.15 6.48 11.56 3.06 17.83-.18.32-.52.52-.88.52h-.13z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M92.66 20.93c-.33 5.13-3.75 8.71-8.96 9.05-.52.05-1.8.16-2.34.2-2.78.17-5.14 2.64-5.03 5.43 0 .93.27 1.89.75 2.69 2.96 5.28 6.55 11.35 3.3 17.3-.75-3.12-1.87-5.55-3.84-7.77-1.84-1.95-3.63-4-5.26-6.12-.31-.44-.63-.92-.88-1.39-2.97-5.32-.56-12.68 4.95-15.23 1.47-.72 3.14-1.11 4.77-1.11.5 0 1 .04 1.49.11.7.15 1.95.29 2.64.46.49.12.98.17 1.48.17 1.87 0 3.73-.8 5.13-2.13.56-.55 1.19-1.16 1.8-1.67m0-2c-.46 0-.92.16-1.29.47-.65.55-1.3 1.18-1.91 1.77-1.04.98-2.41 1.56-3.73 1.56-.34 0-.68-.04-1.01-.12a22.4 22.4 0 0 0-1.64-.3c-.39-.06-.77-.12-1.05-.18a.36.36 0 0 1-.11-.02c-.58-.09-1.19-.13-1.79-.13-1.95 0-3.91.45-5.65 1.31-3.09 1.42-5.53 4.25-6.67 7.75-1.14 3.49-.83 7.21.84 10.22.29.55.64 1.06 1 1.58.02.03.04.05.06.08 1.55 2.01 3.31 4.06 5.37 6.25 1.65 1.87 2.66 3.93 3.37 6.89a2.006 2.006 0 0 0 1.94 1.54c.72 0 1.4-.39 1.76-1.04 3.68-6.76-.06-13.44-3.07-18.8l-.24-.43-.03-.05a3.36 3.36 0 0 1-.47-1.65v-.09c-.06-1.68 1.41-3.25 3.16-3.36.56-.04 1.83-.15 2.37-.2 6.15-.42 10.39-4.7 10.8-10.92.05-.8-.38-1.55-1.09-1.92-.29-.14-.59-.21-.9-.21zm0 4z'
    />
  </svg>
)
export default SvgReaction2Coffee
