import { ClIconButton } from '@enreach/core-component-library-react'
import { defineCustomElementClIconButton } from '@enreach/core-component-library/dist/components/index'
import clsx from 'clsx'

import { makeStyles } from 'app/ui'
import { useAsyncClick } from 'lib/react/hooks/use-async-click.hook'

import { type IconButtonProps } from './icon-button.types'

const useStyles = makeStyles({
  neutral: {
    '--button-background-color': 'rgba(var(--color-neutral-600))',
  },
})

export const IconButton = ({
  neutral = false,
  onClick: onClickProp,
  disabled: disabledProp,
  ...other
}: IconButtonProps) => {
  const classes = useStyles()

  const { onClick, disabled } = useAsyncClick((event) => {
    event.stopPropagation()
    event.preventDefault()
    return onClickProp ? onClickProp(event) : Promise.resolve()
  })

  return (
    <ClIconButton
      {...other}
      className={clsx(other.className, neutral && classes.neutral)}
      disabled={disabledProp || (onClickProp && disabled) || undefined}
      onClick={onClickProp ? onClick : undefined}
    />
  )
}
export default IconButton

defineCustomElementClIconButton()
