import _ from 'lodash'
import { ThemeOptions, createTheme, responsiveFontSizes } from '@mui/material'

import sharedTheme from './theme-shared'

const themeOverrides: ThemeOptions = {
  palette: {
    mode: 'dark',
  },
}

const themeConfig = _.merge({}, sharedTheme, themeOverrides)
const defaultTheme = responsiveFontSizes(createTheme(themeConfig))

export default defaultTheme
