import type { SVGProps } from 'react'
const SvgEmoji15 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle cx={79.4} cy={96.28} r={48.66} fill='rgb(var(--color-brand-100))' />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.4 146.94c-27.93 0-50.66-22.72-50.66-50.66S51.46 45.62 79.4 45.62s50.66 22.72 50.66 50.66-22.72 50.66-50.66 50.66zm0-97.31c-25.73 0-46.66 20.93-46.66 46.66s20.93 46.66 46.66 46.66 46.66-20.93 46.66-46.66-20.93-46.66-46.66-46.66z'
    />
    <circle
      cx={52.65}
      cy={91.3}
      r={11.99}
      fill='rgb(var(--color-neutral-50))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M52.65 104.54c-7.3 0-13.24-5.94-13.24-13.24s5.94-13.24 13.24-13.24S65.89 84 65.89 91.3s-5.94 13.24-13.24 13.24zm0-23.99c-5.92 0-10.74 4.82-10.74 10.74s4.82 10.74 10.74 10.74 10.74-4.82 10.74-10.74-4.82-10.74-10.74-10.74z'
    />
    <circle cx={49.65} cy={88.3} r={3.1} fill='rgb(var(--color-brand-700))' />
    <circle
      cx={106.13}
      cy={91.3}
      r={11.99}
      fill='rgb(var(--color-neutral-50))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M106.13 104.54c-7.3 0-13.25-5.94-13.25-13.24s5.94-13.24 13.25-13.24S119.37 84 119.37 91.3s-5.94 13.24-13.24 13.24zm0-23.99c-5.92 0-10.75 4.82-10.75 10.74s4.82 10.74 10.75 10.74 10.74-4.82 10.74-10.74-4.82-10.74-10.74-10.74z'
    />
    <circle cx={103.13} cy={88.3} r={3.1} fill='rgb(var(--color-brand-700))' />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.39 113.11c4.32 0 8.4 1.63 11.2 4.48.71.72.7 1.87-.02 2.58s-1.87.7-2.58-.02c-2.12-2.15-5.25-3.39-8.6-3.39s-6.48 1.23-8.6 3.39c-.71.72-1.86.73-2.58.02a1.82 1.82 0 0 1-.02-2.58c2.8-2.85 6.88-4.48 11.2-4.48z'
    />
  </svg>
)
export default SvgEmoji15
