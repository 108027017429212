import { Icon } from 'ui/revision'

const BackgroundImageSelectedIcon = (props) => (
  <Icon {...props}>
    <svg viewBox='0 0 18 18'>
      <circle cx='9' cy='9' fill='#90E095' r='9' />
      <path
        d='M7.529 11.133L5.269 8.873 4.5 9.637 7.529 12.666 14.031 6.164 13.267 5.4z'
        fill='#FFF'
      />
    </svg>
  </Icon>
)

export default BackgroundImageSelectedIcon
