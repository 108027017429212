import type { SVGProps } from 'react'
const SvgEmoji1 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle cx={79.4} cy={96.28} r={48.66} fill='rgb(var(--color-brand-100))' />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.4 146.94c-27.93 0-50.66-22.72-50.66-50.66S51.46 45.62 79.4 45.62s50.66 22.72 50.66 50.66-22.72 50.66-50.66 50.66zm0-97.31c-25.73 0-46.66 20.93-46.66 46.66s20.93 46.66 46.66 46.66 46.66-20.93 46.66-46.66-20.93-46.66-46.66-46.66z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M79.4 111.89c-5.15 0-10.07-.98-14.58-2.76-1.34-.53-2.81.43-2.82 1.87v.13c0 8.3 7.79 15.04 17.41 15.04s17.41-6.73 17.41-15.04V111c-.01-1.43-1.49-2.39-2.82-1.87a39.713 39.713 0 0 1-14.58 2.76z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.4 127.16c-10.15 0-18.41-7.19-18.41-16.04v-.13c0-1 .51-1.93 1.34-2.49.84-.57 1.91-.69 2.85-.31 4.53 1.78 9.31 2.69 14.22 2.69s9.69-.9 14.22-2.69c.94-.37 2.01-.25 2.85.31a3.02 3.02 0 0 1 1.34 2.48v.14c0 8.84-8.26 16.04-18.41 16.04zm-15.35-17.18c-.21 0-.42.06-.6.19-.29.19-.45.5-.46.84v.12c0 7.74 7.36 14.04 16.41 14.04s16.41-6.3 16.41-14.04V111c0-.33-.17-.64-.46-.83-.3-.2-.66-.24-1-.11-4.77 1.88-9.8 2.83-14.95 2.83s-10.19-.95-14.95-2.83c-.13-.05-.26-.08-.4-.08z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M79.4 126.16c3.72 0 7.17-1.01 10-2.73-1.81-3.72-5.61-6.29-10-6.29s-8.2 2.57-10 6.29c2.83 1.72 6.28 2.73 10 2.73z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.4 127.16c-3.79 0-7.42-1-10.52-2.88a.996.996 0 0 1-.38-1.29c2.05-4.23 6.23-6.85 10.9-6.85s8.85 2.62 10.9 6.85c.23.46.06 1.02-.38 1.29-3.1 1.88-6.74 2.88-10.52 2.88zm-8.66-4.12c2.6 1.39 5.57 2.12 8.66 2.12s6.06-.73 8.66-2.12c-1.83-3.05-5.07-4.9-8.66-4.9s-6.83 1.86-8.66 4.9z'
    />
    <circle cx={44.33} cy={113.62} r={5.6} fill='rgb(var(--color-brand-200))' />
    <circle
      cx={114.48}
      cy={113.62}
      r={5.6}
      fill='rgb(var(--color-brand-200))'
    />
    <circle
      cx={52.89}
      cy={91.3}
      r={11.99}
      fill='rgb(var(--color-neutral-50))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M52.89 104.54c-7.3 0-13.25-5.94-13.25-13.25s5.94-13.24 13.25-13.24 13.24 5.94 13.24 13.24-5.94 13.25-13.24 13.25zm0-23.99c-5.92 0-10.75 4.82-10.75 10.74s4.82 10.75 10.75 10.75 10.74-4.82 10.74-10.75-4.82-10.74-10.74-10.74z'
    />
    <circle cx={52.89} cy={91.3} r={3.1} fill='rgb(var(--color-brand-700))' />
    <circle
      cx={106.38}
      cy={91.3}
      r={11.99}
      fill='rgb(var(--color-neutral-50))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M106.38 104.54c-7.3 0-13.24-5.94-13.24-13.25s5.94-13.24 13.24-13.24 13.24 5.94 13.24 13.24-5.94 13.25-13.24 13.25zm0-23.99c-5.92 0-10.74 4.82-10.74 10.74s4.82 10.75 10.74 10.75 10.74-4.82 10.74-10.75-4.82-10.74-10.74-10.74z'
    />
    <circle cx={106.38} cy={91.3} r={3.1} fill='rgb(var(--color-brand-700))' />
  </svg>
)
export default SvgEmoji1
