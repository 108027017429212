import { FEATURE_NAMES } from 'constants/features-map'

import { ACTION_TYPES as FOOTER_ACTION_TYPES } from 'app/features/footer/footer.reducer'
import { ACTION_TYPES as CONTENT_ACTION_TYPES } from 'app/features/content/content.reducer'
import { ACTION_TYPES as CONFERENCE_ACTION_TYPES } from 'app/features/conference/conference.reducer'
import { ACTION_TYPES as SIDE_PANEL_ACTION_TYPES } from 'app/features/side-panel/side-panel.reducer'
import { ACTION_TYPES as SETTINGS_ACTION_TYPES } from 'app/features/settings/settings.reducer'
import { Action } from 'app/services/commands/schema'

function asI18nLabel(featureStoreName, featureActionType) {
  return `${featureStoreName}.actions.${featureActionType}.label`
}

function asI18nDescription(featureStoreName, featureActionType) {
  return `${featureStoreName}.actions.${featureActionType}.description`
}

const asShortcutAction = (
  actionType: string,
  featureName: keyof typeof FEATURE_NAMES = undefined
): Action => {
  if (!actionType) {
    throw new Error(
      `ShortcutActions::asShortcutAction() actionType invalid expected string, received "${actionType}"`
    )
  }
  const match = actionType.match(/sm-web\/(.*)\/(.*)/)
  if (match.length !== 3) {
    logger.error(
      `ShortcutActions::asShortcutAction() actionType '${actionType}' does not follow convention 'sm-web/[storename]/[action]'`
    )
    return undefined
  }
  if (match[2] === 'INIT' || match[2] === 'RESET') {
    logger.error(
      `ShortcutActions::asShortcutAction() actionType 'INIT' and 'RESET' not allowed`
    )
    return undefined
  }

  const featureStoreName = match[1].replace('/', '.').toLowerCase()
  const featureActionType = match[2].toLowerCase()
  return {
    actionType,
    label: asI18nLabel(featureStoreName, featureActionType),
    description: asI18nDescription(featureStoreName, featureActionType),
    feature: featureName,
  }
}

const ACTIONS: Record<string, Action> = {
  ROOM_LINK_COPY: asShortcutAction(
    CONFERENCE_ACTION_TYPES.ROOM_LINK_COPY,
    FEATURE_NAMES.ROOM_INVITING
  ),
  END_MEETING: asShortcutAction(CONFERENCE_ACTION_TYPES.END_MEETING),
  LOGOUT: asShortcutAction(CONFERENCE_ACTION_TYPES.LOGOUT),
  SET_VIEW_ACTIVE_SPEAKER: asShortcutAction(
    CONTENT_ACTION_TYPES.SET_VIEW_ACTIVE_SPEAKER,
    FEATURE_NAMES.ACTIVE_SPEAKER
  ),
  SET_VIEW_GRID: asShortcutAction(CONTENT_ACTION_TYPES.SET_VIEW_GRID),
  TABS_NEXT: asShortcutAction(SIDE_PANEL_ACTION_TYPES.TABS_NEXT),
  TABS_PREVIOUS: asShortcutAction(SIDE_PANEL_ACTION_TYPES.TABS_PREVIOUS),
  TOGGLE_DIALOG_INVITE: asShortcutAction(
    CONFERENCE_ACTION_TYPES.SHOW_INVITE_DIALOG,
    FEATURE_NAMES.ROOM_INVITING
  ),
  TOGGLE_DIALOG_SHORTCUTS: asShortcutAction(
    CONTENT_ACTION_TYPES.TOGGLE_DIALOG_SHORTCUTS
  ),
  TOGGLE_DIALOG_STICKERS: asShortcutAction(
    CONTENT_ACTION_TYPES.TOGGLE_DIALOG_STICKERS
  ),
  TOGGLE_FOOTER: asShortcutAction(FOOTER_ACTION_TYPES.TOGGLE_FOOTER),
  TOGGLE_MEDIA_LOCAL_MICROPHONE: asShortcutAction(
    CONFERENCE_ACTION_TYPES.TOGGLE_MICROPHONE
  ),
  TOGGLE_MEDIA_LOCAL_VIDEO: asShortcutAction(
    CONFERENCE_ACTION_TYPES.TOGGLE_VIDEO
  ),
  TOGGLE_MEDIA_ROOM_AUDIO: asShortcutAction(
    CONFERENCE_ACTION_TYPES.TOGGLE_ROOM_AUDIO
  ),
  TOGGLE_MEDIA_ROOM_VIDEO: asShortcutAction(
    CONFERENCE_ACTION_TYPES.TOGGLE_ROOM_VIDEO
  ),
  TOGGLE_PUSH_TO_TALK_START: asShortcutAction(
    CONFERENCE_ACTION_TYPES.TOGGLE_PUSH_TO_TALK_START
  ),
  TOGGLE_PUSH_TO_TALK_STOP: asShortcutAction(
    CONFERENCE_ACTION_TYPES.TOGGLE_PUSH_TO_TALK_STOP
  ),
  TOGGLE_RAISE_HAND: asShortcutAction(
    CONFERENCE_ACTION_TYPES.TOGGLE_RAISE_HAND
  ),
  TOGGLE_SIDE_PANEL: asShortcutAction(
    SIDE_PANEL_ACTION_TYPES.SIDE_PANEL_TOGGLE
  ),
  TOGGLE_SESSION_RECORDING: asShortcutAction(
    CONTENT_ACTION_TYPES.TOGGLE_RECORDING,
    FEATURE_NAMES.SESSION_RECORDING
  ),
  TOGGLE_FULLSCREEN: asShortcutAction(CONTENT_ACTION_TYPES.TOGGLE_FULLSCREEN),
  TOGGLE_SCREENSHARE: asShortcutAction(
    CONFERENCE_ACTION_TYPES.TOGGLE_SCREENSHARE
  ),
  TOGGLE_FACING_MODE: asShortcutAction(
    SETTINGS_ACTION_TYPES.TOGGLE_FACING_MODE
  ),
}

export default ACTIONS
