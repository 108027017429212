import _ from 'lodash'
import { v4 as uuid } from 'uuid'

import {
  actions as channelRoomActions,
  STORE_NAME as CHANNEL_ROOM_STORE_NAME,
} from 'app/state/api/channels/channel-room.reducer'

import { EVENT_TYPES, type WaitingRoomEvents } from './waiting-room.constants'

export const STORE_NAME = 'feature/waiting-room'

// ------------------------------------
// Selectors
// ------------------------------------

export type WaitingRoomEvent = {
  memberId: string
  type: WaitingRoomEvents
  from?: string
  content?: string
  ts?: number
} & Record<string, unknown>

export type WaitingRoomState = {
  events: WaitingRoomEvent[]
  tempMessage: string
  autoAccept: boolean
}

/**
 * Get events with the given eventType.
 *
 * @param state
 * @param [eventType='']
 * @return
 */
function getEvents(
  state: WaitingRoomState,
  eventType = ''
): WaitingRoomEvent[] {
  const { events } = state
  if (eventType) {
    return _.filter(events, { type: eventType }) as WaitingRoomEvent[]
  }
  return events
}

/**
 * Get all the waiting room messages.
 *
 * @param state
 * @return
 */
function getMessageEvents(state: WaitingRoomState) {
  return getEvents(state, EVENT_TYPES.MESSAGE)
}

/**
 * Get all the waiting events.
 *
 * @param state
 * @return
 */
function getWaitingEvents(state: WaitingRoomState) {
  return getEvents(state, EVENT_TYPES.WAITING)
}

/**
 * Get all the joined events.
 *
 * @param state
 * @return
 */
function getJoinedEvents(state: WaitingRoomState) {
  return getEvents(state, EVENT_TYPES.JOINED)
}

/**
 * Get all the denied events.
 *
 * @param state
 * @return
 */
function getDeniedEvents(state: WaitingRoomState) {
  return getEvents(state, EVENT_TYPES.DENIED)
}

/**
 * Get all the left events.
 *
 * @param state
 * @return
 */
function getLeftEvents(state: WaitingRoomState) {
  return getEvents(state, EVENT_TYPES.LEFT)
}

/**
 * Get all the full events.
 *
 * @param state
 * @return
 */
function getFullEvents(state: WaitingRoomState) {
  return getEvents(state, EVENT_TYPES.FULL)
}

/**
 * Get the auto accept state.
 *
 * @param state
 * @return
 */
function getAutoAccept(state: WaitingRoomState) {
  return state.autoAccept
}

export const selectors = {
  getEvents,
  getMessageEvents,
  getWaitingEvents,
  getJoinedEvents,
  getDeniedEvents,
  getLeftEvents,
  getFullEvents,
  getAutoAccept,
}

// ------------------------------------
// Constants
// ------------------------------------
const INIT = `sm-web/${STORE_NAME}/INIT`
const RESET = `sm-web/${STORE_NAME}/RESET`
const ADD_EVENT = `sm-web/${STORE_NAME}/ADD_EVENT`
const REPLACE_EVENT = `sm-web/${STORE_NAME}/REPLACE_EVENT`
const RESET_WAITING_ROOM = `sm-web/${STORE_NAME}/RESET_WAITING_ROOM`
const SET_TEMP_MESSAGE = `sm-web/${STORE_NAME}/SET_TEMP_MESSAGE`
const SET_AUTO_ACCEPT = `sm-web/${STORE_NAME}/SET_AUTO_ACCEPT`

export const ACTION_TYPES = {
  INIT,
  RESET,
  ADD_EVENT,
  REPLACE_EVENT,
  RESET_WAITING_ROOM,
  SET_TEMP_MESSAGE,
  SET_AUTO_ACCEPT,
}

// ------------------------------------
// Actions
// ------------------------------------
/**
 * Replace the event with the given memberId with the eventType and the payload.
 *
 * @param memberId
 * @param eventType
 * @param]
 * @return
 */
function replaceEvent(memberId, eventType, payload = {}) {
  return {
    type: REPLACE_EVENT,
    memberId,
    eventType,
    payload,
  }
}

/**
 * Add an event for the member with the given memberId and with the given eventType and payload.
 *
 * @param memberId
 * @param eventType
 * @param]
 * @return
 */
function addEvent(memberId, eventType, payload = {}) {
  return {
    type: ADD_EVENT,
    memberId,
    eventType,
    payload,
  }
}

/**
 * Approve the guest with the given memberId.
 *
 * @param memberId
 * @param meta
 * @return
 */
function joinedGuest(memberId, meta) {
  return replaceEvent(memberId, EVENT_TYPES.JOINED, { meta })
}

/**
 * Send a message to all the members in the waiting room of the room.
 *
 * @param content
 * @return
 */
function sendWaitingMessage(content) {
  return async (dispatch, getState) => {
    const {
      [CHANNEL_ROOM_STORE_NAME]: channelRoom,
      [STORE_NAME]: waitingRoom,
    } = getState()

    // Gather the events with the type EVENT_TYPES.WAITING.
    const waitingMembers = selectors.getWaitingEvents(waitingRoom)

    /**
     * Await all the messages send by the host to the waiting users.
     */
    await Promise.all(
      _.map(waitingMembers, (member) =>
        dispatch(
          channelRoomActions.sendChatMessage(
            content,
            member.memberId,
            channelRoom.member_id
          )
        )
      )
    )

    /**
     * Add the sended message to the message of the waiting room.
     */
    dispatch(
      addEvent(uuid(), EVENT_TYPES.MESSAGE, {
        from: channelRoom.member_id,
        content,
        ts: Date.now(),
      })
    )
  }
}

/**
 * Deny the guest with the given memberId.
 *
 * @param memberId
 * @param meta
 * @return
 */
function deniedGuest(memberId, meta) {
  return replaceEvent(memberId, EVENT_TYPES.DENIED, meta)
}

/**
 * Replace the members event with a left event type if he was in the waiting room events list.
 *
 * @param memberId
 * @param meta
 * @return
 */
function leftGuest(memberId, meta) {
  return replaceEvent(memberId, EVENT_TYPES.LEFT, meta)
}

/**
 * Reset the state of the waitingRoom
 *
 * @return
 */
function resetWaitingRoom() {
  return { type: RESET_WAITING_ROOM }
}

/**
 * [setTempMessage description]
 *
 * @param [payload=null] [description]
 * @return
 */
function setTempMessage(payload = null) {
  return { type: SET_TEMP_MESSAGE, payload }
}

/**
 * [setAutoAccept description]
 *
 * @param payload
 * @return
 */
function setAutoAccept(payload) {
  return { type: SET_AUTO_ACCEPT, payload }
}

function reset() {
  return { type: RESET }
}

export const actions = {
  deniedGuest,
  leftGuest,
  addEvent,
  replaceEvent,
  sendWaitingMessage,
  joinedGuest,
  resetWaitingRoom,
  setTempMessage,
  setAutoAccept,
  reset,
}

const INITIAL_STATE: WaitingRoomState = {
  events: [],
  tempMessage: null,
  autoAccept: false,
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [ADD_EVENT]: (state, action) => {
    return {
      ...state,
      events: [
        ...state.events,
        {
          ...action.payload,
          type: action.eventType,
          memberId: action.memberId,
        },
      ],
    }
  },
  [REPLACE_EVENT]: (state, action) => {
    if (_.some(state.events, { memberId: action.memberId })) {
      return {
        ...state,
        events: [
          ..._.reject(state.events, { memberId: action.memberId }),
          {
            ...action.payload,
            type: action.eventType,
            memberId: action.memberId,
          },
        ],
      }
    }

    return state
  },
  [SET_TEMP_MESSAGE]: (state, action) => ({
    ...state,
    tempMessage: action.payload,
  }),
  [SET_AUTO_ACCEPT]: (state, action) => ({
    ...state,
    autoAccept: action.payload,
  }),
  [RESET_WAITING_ROOM]: (state) => {
    const waitingEvents = _.filter(state.events, { type: EVENT_TYPES.WAITING })

    return {
      ...INITIAL_STATE,
      events: waitingEvents,
      tempMessage: state.tempMessage,
    }
  },
  [RESET]: () => INITIAL_STATE,
  'sm-web/RESET': () => INITIAL_STATE,
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = INITIAL_STATE, action = {}) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
