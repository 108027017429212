import type { SVGProps } from 'react'
const SvgAgile6Up = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle cx={82.17} cy={78.48} r={62} fill='rgb(var(--color-brand-50))' />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M82.03 40.39 58.67 86.71l14.14-.21 1.21 29.23 2.51 1.68h12.55l2.24-1.68v-29.3h15.06L94.67 61.59l-12.64-21.2z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M85.98 119.02h-7.63c-3.88 0-7.04-3.16-7.04-7.04V87.67h-11.5c-1.1 0-2.09-.56-2.67-1.49-.57-.93-.62-2.07-.13-3.05l22.4-44.46c.53-1.05 1.58-1.7 2.76-1.7s2.23.65 2.76 1.7l22.39 44.46c.49.98.44 2.12-.13 3.05-.58.93-1.57 1.49-2.67 1.49h-11.5v13.23c0 1.1-.9 2-2 2s-2-.9-2-2V87c0-1.84 1.5-3.33 3.33-3.33h10.76l-20.95-41.6-20.95 41.6h10.76c1.84 0 3.33 1.5 3.33 3.33v24.98c0 1.68 1.37 3.04 3.04 3.04h7.63c1.68 0 3.04-1.37 3.04-3.04v-2.38c0-1.1.9-2 2-2s2 .9 2 2v2.38c0 3.88-3.16 7.04-7.04 7.04z'
    />
  </svg>
)
export default SvgAgile6Up
