import * as Sentry from '@sentry/browser'

import Task from './coligo-task'

export class OptionalTask extends Task {
  /**
   *
   */
  name = 'OptionalTask'

  /**
   *
   */
  task

  constructor(task) {
    super()
    this.task = task
  }

  cancel() {
    if (this.task) {
      logger.debug(`canceling optional task ${this.task.name}`)
      this.task.cancel()
    }
  }

  async run() {
    try {
      await this.task.run()
    } catch (err) {
      Sentry.captureException(err)
    }
  }
}

export default OptionalTask
