import PropTypes from 'prop-types'

import Field from '../field.component'
import TextFieldBase from './text-field-base.component'

const TextField = ({ name = 'text', type = 'text', ...props }) => (
  <Field component={TextFieldBase} name={name} type={type} {...props} />
)

TextField.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
}

TextField.defaultProps = {
  name: 'text',
  type: 'text',
}

export default TextField
