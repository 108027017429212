import coligoTrackFactory from 'lib/rtc/coligo-track-factory'

function createDummyAudio() {
  const AudioContext = window.AudioContext || window.webkitAudioContext

  if (!AudioContext) {
    throw new Error('AudioContext is not supported on this browser.')
  }

  const audioContext = new AudioContext()
  // Create a destination stream for the local and remote audio tracks.
  const streamDestination = audioContext.createMediaStreamDestination()

  const channels = 1 // Mono
  // Create an empty sixty second mono buffer at the sample rate of the AudioContext
  const frameCount = audioContext.sampleRate * 60

  const myArrayBuffer = audioContext.createBuffer(
    channels,
    frameCount,
    audioContext.sampleRate
  )
  const gainNode = audioContext.createGain()

  for (let channel = 0; channel < channels; channel += 1) {
    // This gives us the actual ArrayBuffer that contains the data
    const nowBuffering = myArrayBuffer.getChannelData(channel)
    for (let i = 0; i < frameCount; i += 1) {
      // Math.random() is in [0; 1.0]
      // audio needs to be in [-1.0; 1.0]
      nowBuffering[i] = Math.random() * 2 - 1
    }
  }

  const source = audioContext.createBufferSource()
  // set the buffer in the AudioBufferSourceNode
  source.buffer = myArrayBuffer
  // connect the AudioBufferSourceNode to the
  // destination so we can hear the sound
  source.connect(gainNode)

  gainNode.gain.value = 0

  gainNode.connect(streamDestination)
  // start the source playing
  source.start()
  // Get an array of all the audio track
  const tracks = streamDestination.stream.getAudioTracks()

  return coligoTrackFactory.createTrack(coligoTrackFactory.TYPES.LOCAL, {
    stream: streamDestination.stream,
    track: tracks[0],
  })
}

export default createDummyAudio
