import Task from 'lib/tasks/coligo-task'

import * as authUtils from 'lib/auth-utils'
import localStore from 'app/services/state/local-store'
import { actions as channelAuthActions } from 'app/state/api/channels/channel-auth.reducer'

class RefreshOrGenerateTokenTask extends Task {
  name = 'RefreshOrGenerateTokenTask'

  context

  /**
   *
   * @param context
   * @param context.token
   */
  constructor(context) {
    super()

    this.context = context
  }

  async run() {
    let newToken
    if (!this.context.token) {
      /**
       * When no token was used for authentication we will use the authentication
       * channel to generate a new token and store it in the local storage.
       */
      newToken = await channelAuthActions.generateToken()
      newToken.expireDate = authUtils.getTokenExpireDate(newToken)
      localStore.setAuthToken(newToken)
    } else {
      /**
       * When the locally stored token was used for authentication we refresh that token by using
       * the authentication channel.
       * Afrer refreshing the token store the new token in the local storage.
       */
      newToken = await channelAuthActions.refreshToken(this.context.token)
      newToken.expireDate = authUtils.getTokenExpireDate(newToken)
      localStore.setAuthToken(newToken)
    }

    this.context.token = newToken.token
  }
}

export default RefreshOrGenerateTokenTask
