import { uploadFile, calculateProgress } from 'lib/files'
import urlUtils from 'lib/url-utils'

import { actions as channelRoomActions } from 'app/state/api/channels/channel-room.reducer'

import {
  actions as queueActions,
  STORE_NAME as FILE_UPLOAD_STORE_NAME,
  selectors as fileUploadQueueSelectors,
} from './file-upload-queue.reducer'

export const TASK_TYPE = 'fileUpload'

/**
 * creates callback with fileId, member_id and dispatch bound to scope
 * @param  internalFileId internal uuid for file
 * @param  externalFileId external uuid for file coming from uploadlink service
 * @param  member_id
 * @param  dispatch
 */
const sendSystemFileMessage =
  (internalFileId, externalFileId, memberId, dispatch) => () => {
    dispatch(queueActions.complete(internalFileId))
    channelRoomActions.addFile(externalFileId)
    channelRoomActions.sendChatMessage(
      { type: 'file_user', externalFileId, userId: memberId },
      '*',
      memberId
    )
  }
/**
 * this task can handle file upload tasks pushed in the taskQueue
 */
export default async function uploadTask(task: {
  file: { id: string; name: string; size: number }
  store: any
  memberId: string
}): Promise<void> {
  const {
    file,
    store: { getState, dispatch },
    memberId,
  } = task
  const { [FILE_UPLOAD_STORE_NAME]: fileUploadQueueStore } = getState()

  // FIXME: skip this file if it no longer exists in the queue, better would be to activly remove from queue
  if (!fileUploadQueueSelectors.selectFile(fileUploadQueueStore, file.id))
    return

  dispatch(queueActions.progress(file.id, 0))
  try {
    const { fileId, link } = await channelRoomActions.getUploadLink()

    const url = urlUtils.composeUrlFromLinkData(link)

    const onProgress = (event) => {
      // TODO: if not computable maybe we should just show amount of data?
      if (event.lengthComputable) {
        dispatch(queueActions.progress(file.id, calculateProgress(event)))
      }
    }
    await uploadFile(file, url, onProgress)

    setTimeout(sendSystemFileMessage(file.id, fileId, memberId, dispatch), 1000)
  } catch (e) {
    if (e instanceof ProgressEvent && e.type === 'abort') {
      dispatch(queueActions.remove(file.id))
    } else {
      const message = e.responseText || e.message || 'Failed to upload file'
      dispatch(queueActions.error(file.id, message))
    }
  }
}
