import _ from 'lodash'
import { v4 as uuid } from 'uuid'

import { CHAT_COMMAND_TYPES } from 'constants/constants'

import {
  actions as channelRoomActions,
  STORE_NAME as CHANNEL_ROOM_STORE_NAME,
} from 'app/state/api/channels/channel-room.reducer'
import { formatChatMessageForSending } from 'app/state/api/channels/utils'

import {
  ACTION_TYPES as PRIVATE_CHAT_ACTION_TYPES,
  reducer as privateChatReducer,
} from './private-chat'

// ------------------------------------
// Constants
// ------------------------------------
export const STORE_NAME = 'feature/private-chats'

const CHAT_ADD = `sm-web/${STORE_NAME}/CHAT_ADD`

export const ACTION_TYPES = {
  CHAT_ADD,
}

// ------------------------------------
// filters
// ------------------------------------
const findChatWithMember = ({ [STORE_NAME]: privateChats }, participant) => {
  if (!privateChats) return null
  return _.first(_.filter(privateChats.chats, { participant }))
}

export const filters = {
  findChatWithMember,
}

// ------------------------------------
// Actions
// ------------------------------------

const openChat = (chatId) => ({
  type: PRIVATE_CHAT_ACTION_TYPES.CHAT_OPEN,
  chatId,
})

const closeChat = (chatId) => ({
  type: PRIVATE_CHAT_ACTION_TYPES.CHAT_CLOSE,
  chatId,
})

const focusChat = (chatId) => ({
  type: PRIVATE_CHAT_ACTION_TYPES.CHAT_FOCUS,
  chatId,
})

const minimizeChat = (chatId) => ({
  type: PRIVATE_CHAT_ACTION_TYPES.CHAT_MINIMIZE,
  chatId,
})

const maximizeChat = (chatId) => ({
  type: PRIVATE_CHAT_ACTION_TYPES.CHAT_MAXIMIZE,
  chatId,
})

const unfocusChat = (chatId) => ({
  type: PRIVATE_CHAT_ACTION_TYPES.CHAT_UNFOCUS,
  chatId,
})

const resetUnreadChat = (chatId) => ({
  type: PRIVATE_CHAT_ACTION_TYPES.CHAT_UNREAD_RESET,
  chatId,
})

const addChat = (from, to) => ({
  type: CHAT_ADD,
  payload: {
    id: uuid(),
    user: from,
    participant: to,
    messages: [],
    minimize: false,
    totalMessages: 0,
    open: true,
    typing: false,
    focused: false,
  },
})

const startPrivateChat = (to) => (dispatch, getState) => {
  const { [STORE_NAME]: privateChats, [CHANNEL_ROOM_STORE_NAME]: channelRoom } =
    getState()

  let chat = privateChats.chats.find((c) => c.participant === to)

  const otherChatOpen = _.find(privateChats.chats, { open: true })

  if (!chat) {
    const action = addChat(channelRoom.member_id, to)
    chat = action.payload
    dispatch(action)
    // we need to have only one chat open, I choose to chat with this user now
    if (otherChatOpen) {
      dispatch(closeChat(otherChatOpen.id))
    }
  } else if (otherChatOpen && otherChatOpen.id !== chat.id) {
    // our existing chat is not opened but other
    dispatch(closeChat(otherChatOpen.id))
    dispatch(openChat(chat.id))
  } else {
    // no chat is open open this one I want
    dispatch(openChat(chat.id))
  }

  dispatch(focusChat(chat.id))
}

const addMessage = (chatId, from, to, content) => ({
  type: PRIVATE_CHAT_ACTION_TYPES.MESSAGE_ADD,
  chatId,
  payload: { content, from, to, ts: Date.now() },
})

const sendMessage = (chatId, message) => (dispatch, getState) => {
  const { [STORE_NAME]: privateChats } = getState()

  const chat = _.find(privateChats.chats, { id: chatId })
  const { participant: to, user: from } = chat

  dispatch(channelRoomActions.sendChatMessage(message, to, from)).then(() => {
    const content = formatChatMessageForSending(message)
    dispatch(addMessage(chatId, from, to, content))
  })
}

const receiveMessage =
  ({ from, to, content }) =>
  (dispatch, getState) => {
    const { [STORE_NAME]: privateChats } = getState()

    let chat = privateChats.chats.find((c) => c.participant === from)

    const anyChatOpen = _.find(privateChats.chats, { open: true })

    if (!chat) {
      const action = addChat(to, from)
      chat = action.payload
      dispatch(action)
      // if new message comes and we already have one chat open do not open another
      if (anyChatOpen) {
        dispatch(closeChat(chat.id))
      }
    }

    // if we closed the new chat (because other is opened already)
    // we still want the unread count to work
    setTimeout(() => {
      dispatch(addMessage(chat.id, from, to, content))
    }, 100)
  }

const saveTempChat = (chatId, message) => ({
  type: PRIVATE_CHAT_ACTION_TYPES.SAVE_TEMP_CHATS,
  chatId,
  message,
})

/**
 *
 * @param chatId
 * @param typing
 * @returns {import('redux-thunk').ThunkAction}
 */
const sendUserTyping = (chatId, typing) => (dispatch, getState) => {
  const { [STORE_NAME]: privateChats } = getState()

  const chat = _.find(privateChats.chats, { id: chatId })
  const { participant: to, user: from } = chat

  dispatch(
    channelRoomActions.sendChatMessage(
      { content: { type: CHAT_COMMAND_TYPES.USER_TYPING, typing } },
      to,
      from
    )
  )
}

const addUserTyping = (from, typing) => (dispatch, getState) => {
  const { [STORE_NAME]: privateChats } = getState()

  const chat = privateChats.chats.find((c) => c.participant === from)

  if (chat) {
    dispatch({
      type: PRIVATE_CHAT_ACTION_TYPES.USER_TYPING,
      chatId: chat.id,
      payload: { ts: Date.now(), typing },
    })
  }
}

const INITIAL_STATE = {
  /**
   * @type Array<PrivateChat>
   */
  chats: [],
}

export const actions = {
  startPrivateChat,
  closeChat,
  focusChat,
  minimizeChat,
  maximizeChat,
  unfocusChat,
  sendMessage,
  receiveMessage,
  openChat,
  saveTempChat,
  addChat,
  addMessage,
  sendUserTyping,
  addUserTyping,
  resetUnreadChat,
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [CHAT_ADD]: (state, action) => ({
    ...state,
    chats: [...state.chats, action.payload],
  }),
  'sm-web/RESET': () => INITIAL_STATE,
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = INITIAL_STATE, action = {}) => {
  if (action.type.startsWith('sm-web/private-chat/')) {
    const index = _.findIndex(state.chats, { id: action.chatId })

    return {
      ...state,
      chats: [
        ...state.chats.slice(0, index),
        privateChatReducer(state.chats[index], action),
        ...state.chats.slice(index + 1),
      ],
    }
  }

  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
