import ColigoTrack from './coligo-track'

type Props = {
  track: MediaStreamTrack
  stream: MediaStream
  memberId: string
}
class ExternalColigoTrack extends ColigoTrack {
  memberId: string

  constructor(options: Props) {
    super(options)

    this.memberId = options.memberId
  }
}

export default ExternalColigoTrack
