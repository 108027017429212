import type { SVGProps } from 'react'
const SvgReaction1Break = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <path
      fill='rgb(var(--color-brand-200))'
      d='M78.74 45.7c-10.09 0-18.29-8.21-18.29-18.29S68.66 9.12 78.74 9.12s18.29 8.21 18.29 18.29S88.82 45.7 78.74 45.7zm0-33.59c-8.43 0-15.29 6.86-15.29 15.29s6.86 15.29 15.29 15.29 15.29-6.86 15.29-15.29-6.86-15.29-15.29-15.29zm.02 134.92c-27.93 0-50.66-22.72-50.66-50.66s22.72-50.66 50.66-50.66 50.66 22.72 50.66 50.66-22.72 50.66-50.66 50.66z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M78.75 47.71c26.87 0 48.66 21.78 48.66 48.66s-21.78 48.66-48.66 48.66-48.66-21.78-48.66-48.66 21.78-48.66 48.66-48.66m0-4c-29.03 0-52.66 23.62-52.66 52.66s23.62 52.66 52.66 52.66 52.66-23.62 52.66-52.66-23.62-52.66-52.66-52.66z'
    />
    <path
      fill='rgb(var(--color-brand-100))'
      d='M76.44 21.07h4.23c1.5 0 2.72 1.22 2.72 2.72v8.55h-9.67v-8.55c0-1.5 1.22-2.72 2.72-2.72z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M84.89 33.85H72.22V23.8c0-2.33 1.9-4.22 4.22-4.22h4.23c2.33 0 4.22 1.9 4.22 4.22v10.05zm-9.67-3h6.67V23.8c0-.67-.55-1.22-1.22-1.22h-4.23c-.67 0-1.22.55-1.22 1.22v7.05z'
    />
    <circle
      cx={78.75}
      cy={96.37}
      r={38.08}
      fill='rgb(var(--color-neutral-50))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M78.76 135.95c-21.82 0-39.58-17.75-39.58-39.58s17.75-39.58 39.58-39.58 39.58 17.75 39.58 39.58-17.75 39.58-39.58 39.58zm0-76.15c-20.17 0-36.58 16.41-36.58 36.58s16.41 36.58 36.58 36.58 36.58-16.41 36.58-36.58S98.93 59.8 78.76 59.8zm-4.54-27.23h8.66v12.61h-8.66zm46.045 16.458 6.124 6.123-7.948 7.948-6.124-6.123z'
    />
    <rect
      width={20.96}
      height={4.79}
      x={68.07}
      y={30.43}
      fill='rgb(var(--color-brand-400))'
      rx={1.77}
      ry={1.77}
    />
    <path
      fill='rgb(var(--color-brand-100))'
      d='m128.898 41.412 5.106 5.106a2.723 2.723 0 0 1 0 3.846l-7.1 7.1a2.006 2.006 0 0 1-2.828 0l-6.138-6.138a2.006 2.006 0 0 1 0-2.828l7.1-7.1a2.723 2.723 0 0 1 3.846 0z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M125.49 59.55c-.9 0-1.79-.34-2.47-1.02l-6.13-6.13a3.513 3.513 0 0 1 0-4.95l7.1-7.1a4.219 4.219 0 0 1 5.97 0l5.11 5.11a4.219 4.219 0 0 1 0 5.97l-7.1 7.1a3.49 3.49 0 0 1-2.47 1.02zm1.48-17.44c-.33 0-.63.13-.87.36l-7.1 7.1c-.19.19-.19.51 0 .71l6.13 6.13c.2.19.51.19.71 0l7.1-7.1c.48-.48.48-1.25 0-1.73l-5.11-5.11c-.23-.23-.54-.36-.87-.36z'
    />
    <path
      fill='rgb(var(--color-brand-100))'
      d='M94.55 125.94c-.35 0-.69-.18-.87-.51l-1.62-2.91a1 1 0 0 1 1.74-.98l1.62 2.91a1 1 0 0 1-.87 1.49zM64.04 71.36c-.35 0-.69-.18-.87-.51l-1.62-2.91a.994.994 0 0 1 .39-1.36.994.994 0 0 1 1.36.39l1.62 2.91c.27.48.1 1.09-.39 1.36-.15.09-.32.13-.49.13zm40.28 11.4c-.35 0-.69-.18-.87-.51a1 1 0 0 1 .38-1.36l2.91-1.62a1 1 0 0 1 .98 1.74l-2.91 1.62c-.16.09-.32.13-.49.13zm-54.59 30.5c-.35 0-.69-.18-.87-.51a1 1 0 0 1 .38-1.36l2.91-1.62a1 1 0 0 1 .98 1.74l-2.91 1.62c-.15.09-.32.13-.49.13zm56.07 2.33c-.19 0-.39-.05-.56-.17l-2.76-1.86a.999.999 0 0 1-.27-1.39c.31-.46.93-.58 1.39-.27l2.76 1.86c.46.31.58.93.27 1.39-.19.29-.51.44-.83.44zM53.92 80.67c-.19 0-.39-.05-.56-.17l-2.76-1.86a.999.999 0 0 1-.27-1.39c.31-.46.93-.58 1.39-.27l2.76 1.86c.46.31.58.93.27 1.39-.19.29-.51.44-.83.44zm41.09-8.03a.999.999 0 0 1-.83-1.56l1.86-2.76c.31-.46.93-.58 1.39-.27.46.31.58.93.27 1.39l-1.86 2.76c-.19.29-.51.44-.83.44zm-34.92 51.87a.999.999 0 0 1-.83-1.56l1.86-2.76c.31-.46.93-.58 1.39-.27.46.31.58.93.27 1.39l-1.86 2.76c-.19.29-.51.44-.83.44zm51.32-27.31h-4.74c-.55 0-1-.45-1-1s.45-1 1-1h4.74c.55 0 1 .45 1 1s-.45 1-1 1zm-61.12 0h-4.74c-.55 0-1-.45-1-1s.45-1 1-1h4.74c.55 0 1 .45 1 1s-.45 1-1 1zm28.19-28.19c-.55 0-1-.45-1-1v-4.74c0-.55.45-1 1-1s1 .45 1 1v4.74c0 .55-.45 1-1 1zm0 61.12c-.55 0-1-.45-1-1v-4.75c0-.55.45-1 1-1s1 .45 1 1v4.75c0 .55-.45 1-1 1z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M70.67 105.82c-.32 0-.64-.12-.88-.37a1.25 1.25 0 0 1 0-1.77L98.67 74.8c.49-.49 1.28-.49 1.77 0s.49 1.28 0 1.77l-28.88 28.88c-.24.24-.56.37-.88.37z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M99.13 118.44c-.19 0-.38-.07-.53-.22l-29.86-30a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l29.86 30c.29.29.29.77 0 1.06-.15.15-.34.22-.53.22z'
    />
    <circle cx={78.75} cy={96.37} r={4.84} fill='rgb(var(--color-brand-600))' />
    <circle
      cx={79.31}
      cy={113.95}
      r={4.84}
      fill='rgb(var(--color-brand-200))'
    />
  </svg>
)
export default SvgReaction1Break
