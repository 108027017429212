import { asyncScheduler, concat } from 'rxjs'
import { debounceTime, connect, take } from 'rxjs/operators'

/**
 *
 * @param amount
 * @param dueTime
 * @param scheduler
 * @returns {import('rxjs').OperatorFunction<number, number>}
 */
function debounceTimeAfter(amount, dueTime, scheduler = asyncScheduler) {
  return connect((value) =>
    concat(
      value.pipe(take(amount)),
      value.pipe(debounceTime(dueTime, scheduler))
    )
  )
}

/**
 *
 * @param dueTime
 * @param scheduler
 * @returns {import('rxjs').OperatorFunction<number, number>}
 */
function debounceTimeAfterFirst(dueTime, scheduler = asyncScheduler) {
  return debounceTimeAfter(1, dueTime, scheduler)
}

export { debounceTimeAfter, debounceTimeAfterFirst }
