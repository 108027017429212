import { useTranslation } from 'react-i18next'

import { makeStyles } from 'app/ui'
import Link from 'ui/link'

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '1.4rem',
    margin: theme.spacing(2),
  },
}))

export interface BlockerFormActionProps {
  action: string
  browser: string
  url: string
}

const BlockerFormAction = ({
  action,
  browser,
  url,
  ...other
}: BlockerFormActionProps) => {
  const { t } = useTranslation()
  const classes = useStyles({})
  return (
    <Link
      mui
      className={classes.root}
      rel='noopener noreferrer'
      target='_blank'
      to={url}
      {...other}
    >
      {t(`BlockerForm.${action}Browser`, { browser })}
    </Link>
  )
}

export default BlockerFormAction
