import { Icon } from 'ui/revision'

const BackgroundBlurLowIcon = (props) => (
  <Icon {...props}>
    <svg viewBox='0 0 19 19'>
      <path
        d='M79.5 389c.28 0 .5.221.5.5 0 .28-.22.5-.5.5s-.5-.22-.5-.5c0-.279.22-.5.5-.5zm4 0c.28 0 .5.221.5.5 0 .28-.22.5-.5.5-.279 0-.5-.22-.5-.5 0-.279.221-.5.5-.5zm-4-3.5c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm4 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-7.965.454c.28 0 .5.22.5.5s-.22.5-.5.5-.5-.22-.5-.5.22-.5.5-.5zm11.756 0c.28 0 .5.22.5.5s-.22.5-.5.5-.5-.22-.5-.5.22-.5.5-.5zM75.5 381.5c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm12 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-8-.046c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm4 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM73 382v1h-1v-1h1zm17.5 0c.28 0 .5.221.5.5 0 .28-.22.5-.5.5-.279 0-.5-.22-.5-.5 0-.279.221-.5.5-.5zm-15-4.5c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm12 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-4-.023c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-4 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm11 .523c.28 0 .5.22.5.5s-.22.5-.5.5c-.279 0-.5-.22-.5-.5s.221-.5.5-.5zm-18 0c.28 0 .5.22.5.5s-.22.5-.5.5-.5-.22-.5-.5.22-.5.5-.5zm11-4.5c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-4 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm7.791.523c.28 0 .5.22.5.5s-.22.5-.5.5-.5-.22-.5-.5.22-.5.5-.5zm-11.756 0c.28 0 .5.22.5.5s-.22.5-.5.5-.5-.22-.5-.5.22-.5.5-.5zM83.5 371c.28 0 .5.22.5.5s-.22.5-.5.5c-.279 0-.5-.22-.5-.5s.221-.5.5-.5zm-4 0c.28 0 .5.22.5.5s-.22.5-.5.5-.5-.22-.5-.5.22-.5.5-.5z'
        transform='translate(-72 -371)'
      />
    </svg>
  </Icon>
)

export default BackgroundBlurLowIcon
