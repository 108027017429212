import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  STORE_NAME as APP_STORE_NAME,
  selectors as appSelectors,
} from 'app/features/app/app.reducer'

import useDateTime from 'lib/react/hooks/use-date-time.hook'

import SystemMaintenanceEvent from './system-maintenance-event.component'

const mapStateToProps = ({ [APP_STORE_NAME]: app }) => ({
  config: appSelectors.getMaintenance(app).info,
})

const SystemMaintenanceEventContainer = ({ config, ...other }) => {
  const { t } = useTranslation()

  const endDateTime = useDateTime(config.end).format(config.displayFormat)
  const startDateTime = useDateTime(config.start).format(config.displayFormat)

  return (
    <SystemMaintenanceEvent {...other}>
      {t('Message.maintenance', { endDateTime, startDateTime })}
    </SystemMaintenanceEvent>
  )
}

SystemMaintenanceEventContainer.propTypes = {
  config: PropTypes.shape({
    displayFormat: PropTypes.string,
    enabled: PropTypes.bool,
    end: PropTypes.string,
    hideMessageAfter: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
    start: PropTypes.string,
  }),
}

SystemMaintenanceEventContainer.defaultProps = {
  config: {},
}

export default connect(mapStateToProps, () => ({}))(
  SystemMaintenanceEventContainer
)
