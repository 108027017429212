import * as Sentry from '@sentry/browser'

import env from 'app/config/env'

if (env('sentry_config')) {
  const config = {
    environment: 'development', // default if not passed in the config
    release: `summa-meetings@${env<string>('version')}`,
    serverName: env('api'),
    ...env('sentry_config', {}),
  } as Sentry.BrowserOptions

  Sentry.init(config)
}
