import { ClIcon } from '@enreach/core-component-library-react'
import { defineCustomElementClIcon } from '@enreach/core-component-library/dist/components/index'
import { Sms, SmsFailed } from '@mui/icons-material'
import clsx from 'clsx'

import { makeStyles } from 'app/ui'
import * as LocalIcons from 'ui/icons'

import { type IconProps } from './icon.types'

const useStyles = makeStyles(
  () => ({
    invisible: {
      display: 'none',
    },
  }),
  { name: 'Icon' }
)

export const Icon = ({
  name,
  className,
  invisible,
  accented,
  ...other
}: IconProps) => {
  const classes = useStyles()
  const clsName = clsx(className, invisible && classes.invisible)

  if (accented && !other.fillColor) {
    other.fillColor = 'rgba(var(--color-brand-main))'
  }
  /**
   * checks if there's a custom icon component in the ui/icons module that matches the name prop
   * see constants/icons
   */
  if (LocalIcons[name]) {
    const Component = LocalIcons[name]
    return <Component {...other} className={clsName} />
  } else if (name === 'sms') {
    return (
      <ClIcon {...other} className={clsName}>
        <Sms />
      </ClIcon>
    )
  } else if (name === 'sms_failed') {
    return (
      <ClIcon {...other} className={clsName}>
        <SmsFailed />
      </ClIcon>
    )
  }
  return <ClIcon {...other} className={clsName} name={name} />
}

export default Icon

defineCustomElementClIcon()
