import {
  SynchronousFrameProcessingStrategy,
  AsynchronousFrameProcessingStrategy,
} from '@enreach/person-segmentation'
import { combineLatest } from 'rxjs'
import { filter, take, takeWhile } from 'rxjs/operators'

import { BACKGROUND_EFFECT_TYPES } from 'constants/constants'
import BackgroundEffectImages, {
  defaultImage,
} from 'resources/images/background-effect-images'
import ROOM_SERVICES from 'app/services/media/room-services'

import localStore from 'app/services/state/local-store'
import roomMediaCommands from 'app/services/media/room-media-commands'
import roomMediaManager from 'app/services/media/room-media-manager'

import { ACTION_TYPES as GUEST_SETUP_ACTION_TYPES } from 'app/features/guest-setup/guest-setup.reducer'
import { ACTION_TYPES as CONFERENCE_ACTION_TYPES } from 'app/features/conference/conference.reducer'
import { areBackgroundEffectsSupported } from 'app/features/background-effect/background-effect.utils'

import {
  actions,
  selectors,
  ACTION_TYPES,
  STORE_NAME,
} from './background-effect.reducer'

import BackgroundEffectObserver from './background-effect-observer'

/**
 * @type {import('rxjs').Subscription | null}
 */
let devicePickerVideoSubscription

const handleGuestSetupStepInit = (store) => {
  if (devicePickerVideoSubscription) {
    devicePickerVideoSubscription.unsubscribe()
    devicePickerVideoSubscription = null
  }

  const backgroundEffectsSupported = areBackgroundEffectsSupported(store)

  if (backgroundEffectsSupported) {
    devicePickerVideoSubscription = roomMediaManager.trackManager.tracks$[
      ROOM_SERVICES.DEVICE_PICKER_VIDEO
    ]
      // When the first track has been set for the device picker in the guest setup,
      // we will start the selected background effect.
      .pipe(filter(Boolean), take(1))
      .subscribe(() => {
        const { [STORE_NAME]: backgroundEffect } = store.getState()

        const activeEffect = selectors.getActiveEffect(backgroundEffect)

        switch (activeEffect) {
          case BACKGROUND_EFFECT_TYPES.BLUR:
            store.dispatch(actions.startBlur(true))
            break
          case BACKGROUND_EFFECT_TYPES.REPLACE:
            store.dispatch(actions.startReplace(true))
            break

          default:
            break
        }
      })
  }
}

const handleConferenceInit = (store) => {
  const { [STORE_NAME]: backgroundEffect } = store.getState()

  const backgroundEffectsSupported = areBackgroundEffectsSupported(store)
  if (backgroundEffectsSupported) {
    const activeEffect = selectors.getActiveEffect(backgroundEffect)

    switch (activeEffect) {
      case BACKGROUND_EFFECT_TYPES.BLUR:
        store.dispatch(actions.startBlur(false))
        break
      case BACKGROUND_EFFECT_TYPES.REPLACE:
        store.dispatch(actions.startReplace(false))
        break
      default:
        break
    }
  }
}

export default (store) => (next) => (action) => {
  const { dispatch, getState } = store

  switch (action.type) {
    case GUEST_SETUP_ACTION_TYPES.INIT:
      handleGuestSetupStepInit(store)
      break

    case CONFERENCE_ACTION_TYPES.INIT:
      handleConferenceInit(store)
      break

    case ACTION_TYPES.START_BLUR:
      {
        const { [STORE_NAME]: backgroundEffect } = getState()

        const blurConfig = selectors.getBlurConfig(backgroundEffect)
        const rememberEffect = selectors.getRememberConfig(backgroundEffect)

        // Update effect config in local store
        localStore.setBackgroundBlurConfig(JSON.stringify(blurConfig))

        dispatch(actions.setLoading(true))
        roomMediaCommands
          // Start background effect with config
          .enableBackgroundBlur(blurConfig)

        const processorsRunning$ = combineLatest(
          [
            roomMediaManager.trackManager.processors[ROOM_SERVICES.ROOM_VIDEO]
              .running$,
            roomMediaManager.trackManager.processors[
              ROOM_SERVICES.DEVICE_PICKER_VIDEO
            ].running$,
          ],
          (roomVideoProcessorRunning, devicePickerVideoProcessorRunning) =>
            roomVideoProcessorRunning || devicePickerVideoProcessorRunning
        ).pipe(takeWhile((value) => !value, true))

        processorsRunning$.subscribe(
          new BackgroundEffectObserver(
            dispatch,
            rememberEffect,
            BACKGROUND_EFFECT_TYPES.BLUR
          )
        )
      }
      break

    case ACTION_TYPES.START_REPLACE:
      {
        const { [STORE_NAME]: backgroundEffect } = getState()

        const replaceConfig = selectors.getReplaceConfig(backgroundEffect)
        const rememberEffect = selectors.getRememberConfig(backgroundEffect)

        if (!BackgroundEffectImages[replaceConfig.backgroundImageName]) {
          // If the stored background image isn't available, use default image
          replaceConfig.backgroundImageName = defaultImage
        }

        // Update effect config in local store
        localStore.setBackgroundReplaceConfig(JSON.stringify(replaceConfig))

        dispatch(actions.setLoading(true))
        roomMediaCommands
          // Start background effect with config
          .enableBackgroundReplace(replaceConfig)

        const processorsRunning$ = combineLatest(
          [
            roomMediaManager.trackManager.processors[ROOM_SERVICES.ROOM_VIDEO]
              .running$,
            roomMediaManager.trackManager.processors[
              ROOM_SERVICES.DEVICE_PICKER_VIDEO
            ].running$,
          ],
          (roomVideoProcessorRunning, devicePickerVideoProcessorRunning) =>
            roomVideoProcessorRunning || devicePickerVideoProcessorRunning
        ).pipe(takeWhile((value) => !value, true))

        processorsRunning$.subscribe(
          new BackgroundEffectObserver(
            dispatch,
            rememberEffect,
            BACKGROUND_EFFECT_TYPES.REPLACE
          )
        )
      }
      break

    case ACTION_TYPES.STOP_EFFECT:
      dispatch(actions.setShouldBeEnabled(false))
      dispatch(actions.setRememberEffect(false))

      roomMediaCommands.stopBackgroundEffect()
      break

    case ACTION_TYPES.SET_BLUR_VALUE:
      {
        const { [STORE_NAME]: backgroundEffect } = getState()
        const newBlurAmount = action.payload

        const currentBlurConfig = selectors.getBlurConfig(backgroundEffect)

        if (newBlurAmount !== currentBlurConfig.backgroundBlurAmount) {
          // Merge current config and new blur amount
          const newBlurConfig = {
            ...currentBlurConfig,
            backgroundBlurAmount: newBlurAmount,
          }

          // Update config in local store
          localStore.setBackgroundBlurConfig(JSON.stringify(newBlurConfig))
          // Update config on running effect
          roomMediaCommands.setDrawingConfig(newBlurConfig)
        }
      }
      break

    case ACTION_TYPES.SET_BACKGROUND_IMAGE:
      {
        const { [STORE_NAME]: backgroundEffect } = getState()
        const newBackgroundImage = action.payload

        const currentReplaceConfig =
          selectors.getReplaceConfig(backgroundEffect)

        if (newBackgroundImage !== currentReplaceConfig.backgroundImageName) {
          // Merge current config and newly selected background image
          const newReplaceConfig = {
            ...currentReplaceConfig,
            backgroundImageName: newBackgroundImage,
          }

          // Update config in local store
          localStore.setBackgroundReplaceConfig(
            JSON.stringify(newReplaceConfig)
          )
          // Update config on running effect
          roomMediaCommands.setDrawingConfig(newReplaceConfig)
        }
      }
      break

    case ACTION_TYPES.SET_REMEMBER_EFFECT:
      {
        const { [STORE_NAME]: backgroundEffect } = getState()
        const rememberEffect = action.payload

        // If the user wants to remember this effect
        if (rememberEffect) {
          const activeEffect = selectors.getActiveEffect(backgroundEffect)

          // Store it in local store
          localStore.setActiveBackgroundEffect(activeEffect)
        } else {
          // Otherwise, remove it
          localStore.removeActiveBackgroundEffect()
        }
      }
      break

    case ACTION_TYPES.SET_ASYNC_PROCESSING:
      {
        const async = action.payload

        roomMediaCommands.setFrameProcessingStrategy(
          async
            ? new AsynchronousFrameProcessingStrategy()
            : new SynchronousFrameProcessingStrategy()
        )
      }
      break

    default:
      break
  }

  return next(action)
}
