import { useCallback, useEffect } from 'react'

function getSubscribe(element, event, handler) {
  if (element.addEventListener) {
    element.addEventListener(event, handler)
  } else if (element.addListener) {
    element.addListener(event, handler)
  } else if (element.on) {
    element.on(event, handler)
  }
}

function getUnsubscribe(element, event, handler) {
  if (element.removeEventListener) {
    element.removeEventListener(event, handler)
  } else if (element.removeListener) {
    element.removeListener(event, handler)
  } else if (element.off) {
    element.off(event, handler)
  }
}

function useEventSubcribe(
  element,
  event,
  handler,
  updatesOn = [element, event, handler]
) {
  const subscribe = useCallback(
    () => getSubscribe(element, event, handler),
    updatesOn
  )
  const unsubscribe = useCallback(
    () => getUnsubscribe(element, event, handler),
    updatesOn
  )

  return { subscribe, unsubscribe }
}

function useEventRefSubcribe(ref, event, handler) {
  const subscribe = () => {
    if (ref.current) {
      getSubscribe(ref.current, event, handler)
    }
  }
  const unsubscribe = () => {
    if (ref.current) {
      getUnsubscribe(ref.current, event, handler)
    }
  }

  return { subscribe, unsubscribe }
}

function useEventBase(useSubscribe, ...args) {
  const { subscribe, unsubscribe } = useSubscribe(...args)

  useEffect(() => {
    subscribe()
    return () => unsubscribe()
  }, [subscribe, unsubscribe])
}

export function useEventRef(ref, event, handler) {
  useEventBase(useEventRefSubcribe, ref, event, handler)
}

export default function useEvent(
  element,
  event,
  handler,
  updatesOn = [element, event, handler]
) {
  useEventBase(useEventSubcribe, element, event, handler, updatesOn)
}

export function useWindowEvent(event, handler, updatesOn = [event, handler]) {
  useEvent(window, event, handler, updatesOn)
}

export function useDocumentEvent(event, handler, updatesOn = [event, handler]) {
  useEvent(document, event, handler, updatesOn)
}

export function useWindowResize(handler, updatesOn = [handler]) {
  useWindowEvent('resize', handler, updatesOn)
}

export function useOrientationChange(handler, updatesOn = [handler]) {
  useWindowEvent('orientationchange', handler, updatesOn)
}

export function useKeyDown(handler, updatesOn = [handler]) {
  useDocumentEvent('keydown', handler, updatesOn)
}
