import React from 'react'
import {
  Navigate,
  Outlet,
  Routes as ReactRouterDomRoutes,
  Route,
  useLocation,
  useMatch,
} from 'react-router-dom'

import env from 'app/config/env'
import Login from 'app/features/login'
import Room from 'app/features/room'
import { RoutePaths } from 'constants/route-paths'
import browserUtils from 'lib/browser-utils'
import urlUtils from 'lib/url-utils'
import Loadable from 'ui/loadable'

import DialinPage from './dial-in-page'
const SignupPage = Loadable(async () => import('./signup-page'))
const AsyncRoom = Loadable(async () => import('app/features/room'))
/**
 * Get the meeting identifier from the current location
 * @return
 */
const useMeetingIdentifier = (): string | undefined => {
  const location = useLocation()
  let meetingIdentifier = urlUtils.getMeetingIdentifier(
    location.pathname,
    urlUtils.getUrlParams(location)
  )

  /**
   * When the hash history is used but there is no identifier in the router location,
   * we fallback to manually checking it in the url.
   */
  if (!meetingIdentifier && browserUtils.usesHashHistory()) {
    meetingIdentifier = urlUtils.getMeetingIdentifier(
      window.location.pathname,
      urlUtils.getUrlParams(window.location)
    )
  }
  return meetingIdentifier
}

const RoomGuard = (props: React.ComponentProps<typeof Room>) => {
  const match = useMatch(RoutePaths.ROOM)
  return (
    <AsyncRoom
      initialPayload={match.params.meetingIdentifier}
      // FIXME: is this necessary actually?
      match={match}
      {...props}
    />
  )
}

const RedirectorRoute = ({ redirect }: { redirect: Boolean }): JSX.Element => {
  const meetingIdentifier = useMeetingIdentifier()
  if (redirect && meetingIdentifier) {
    return (
      <Navigate
        to={urlUtils.getNewMeetingPathname(meetingIdentifier)}
        replace
      />
    )
  }

  return <Outlet />
}

const isSignupEnabled = env('signup_page.enabled', false) as boolean

const OptionalSignupPage: React.FC = () => {
  if (isSignupEnabled) {
    return <Outlet />
  }
  return <Navigate to={RoutePaths.HOME} replace />
}

export const Routes: React.FC = () => {
  return (
    <ReactRouterDomRoutes>
      <Route element={<RedirectorRoute redirect />}>
        <Route path={RoutePaths.HOME} element={<Login />} />
      </Route>
      <Route element={<OptionalSignupPage />}>
        <Route path={RoutePaths.SIGNUP} element={<SignupPage />} />
      </Route>
      <Route path={RoutePaths.DIALIN} element={<DialinPage />} />
      <Route path={RoutePaths.ROOM} element={<RoomGuard />} />
      <Route path='*' element={<Navigate to={RoutePaths.HOME} replace />} />
    </ReactRouterDomRoutes>
  )
}

export default Routes
