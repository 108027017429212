import {
  createContext,
  useContext,
  useCallback,
  useMemo,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

import { ConfirmDialog, AlertDialog } from 'ui/dialogs'

const dialogComponents = {
  confirm: ConfirmDialog,
  alert: AlertDialog,
  custom: null,
}

export const DialogContext = createContext({
  confirm: Function.prototype,
  alert: Function.prototype,
  custom: Function.prototype,
})

function DialogProvider({ children }) {
  const [{ dialogs, dialogsById }, setDialogsState] = useState({
    dialogs: [],
    dialogsById: {},
  })

  const dialogContent = useMemo(() => {
    if (!dialogs.length) return null

    const dialogId = dialogs[0]
    const dialog = dialogsById[dialogId]
    if (!dialog) return null
    const Comp = dialogComponents[dialog.type]
    return <Comp {...dialog.data} />
  }, [dialogs, dialogsById])

  const remove = useCallback(
    (id) => {
      setDialogsState({
        dialogs: _.filter(dialogs, (dId) => dId !== id),
        dialogsById: _.omit(dialogsById, id),
      })
    },
    [dialogs, dialogsById]
  )

  const add = useCallback(
    (type, data) => {
      const id = uuid()
      setDialogsState({
        dialogs: [...dialogs, id],
        dialogsById: { ...dialogsById, [id]: { type, data } },
      })
      return () => remove(id)
    },
    [dialogs, dialogsById, remove]
  )

  const confirm = useCallback(
    (data) =>
      new Promise((resolve) => {
        const ref = {}
        let confirmData = data
        if (typeof confirmData === 'string') {
          confirmData = { title: confirmData }
        }
        confirmData.onSubmit = () => {
          ref.remove()
          resolve({ ok: true })
        }
        confirmData.onCancel = () => {
          ref.remove()
          resolve({ ok: false })
        }
        ref.remove = add('confirm', confirmData)
      }),
    [add]
  )

  const alert = useCallback(
    (data) => {
      return new Promise((resolve) => {
        const ref = {}
        let alertData = data
        if (typeof alertData === 'string') {
          alertData = { title: alertData }
        }
        alertData.onSubmit = () => {
          ref.remove()
          resolve({ ok: true })
        }
        ref.remove = add('alert', alertData)
      })
    },
    [add]
  )

  const custom = useCallback(
    (data) => {
      return new Promise((resolve) => {
        const ref = {}
        const type = 'custom'
        const dialogProps = _.omit(data, ['component'])

        dialogComponents[type] = data.component
        dialogProps.onSubmit = () => {
          ref.remove()
          resolve({ ok: true })
        }
        dialogProps.onClose = () => {
          ref.remove()
          resolve({ ok: false })
        }
        ref.remove = add(type, dialogProps)
      })
    },
    [add]
  )

  return (
    <DialogContext.Provider value={{ confirm, alert, custom }}>
      {children}
      {dialogContent}
    </DialogContext.Provider>
  )
}

DialogProvider.propTypes = {
  children: PropTypes.node,
}

DialogProvider.defaultProps = {
  children: null,
}

export function useConfirm() {
  const { confirm } = useContext(DialogContext)
  return confirm
}
export function useAlert() {
  const { alert } = useContext(DialogContext)
  return alert
}
export function useCustom() {
  const { custom } = useContext(DialogContext)
  return custom
}

export default DialogProvider
