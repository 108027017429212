import Mousetrap from 'mousetrap'

import Schema from 'app/services/commands/schema'
import commandsRegistry from 'app/services/commands/commands-registry'

/**
 * @class
 */
export class KeyboardShortcutListener {
  /**
   * @param schema
   * @returns
   */
  setSchema(schema) {
    Schema.clear()
    Schema.merge(schema)
    return this
  }

  bindShortcuts() {
    Schema.entries().forEach(({ keys, action }) => {
      logger.debug(
        `KeyboardShortcutListener::bindShortcuts() binding ${keys} for action ${action.actionType}`
      )
      if (!(keys instanceof Array)) {
        const { keys: shortcut, state } = keys
        Mousetrap.bind(shortcut, this.createActionHandler(action, true), state)
      } else {
        const shortcut = keys.join('+')
        Mousetrap.bind(shortcut, this.createActionHandler(action, true))
      }
    })
  }

  createActionHandler(action, cancelRepeated) {
    return (e) => {
      e.preventDefault()
      if (cancelRepeated && e.repeat) {
        logger.debug('KeyboardShortcutListener:: cancel repeated event')
        return false
      }

      logger.debug(
        `KeyboardShortcutListener:: handle action ${action.actionType}`,
        action
      )
      const command = commandsRegistry.get(action.actionType)
      if (command) {
        command(e)
      } else {
        logger.error(
          `KeyboardShortcutListener:: no command found for registered action ${action.actionType}`
        )
      }
    }
  }

  enable() {
    logger.debug(`KeyboardShortcutListener::enable() enabling shortcuts`)
    this.bindShortcuts()
  }

  disable() {
    Mousetrap.reset()
  }
}

const listener = new KeyboardShortcutListener()

export default listener
