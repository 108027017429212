import { ClLoader } from '@enreach/core-component-library-react'
import { defineCustomElementClLoader } from '@enreach/core-component-library/dist/components/index'

type LoaderProps = React.ComponentProps<typeof ClLoader>

export const Loader = ({
  indeterminate = true,
  labelRight = false,
  size = 8,
  thickness = 6, // Thickness default has to be set explicitly due to issue: https://jira.voiceworks.com/browse/CCL-633

  ...other
}: LoaderProps) => {
  return (
    <ClLoader
      indeterminate={indeterminate}
      labelRight={labelRight}
      size={size}
      thickness={thickness}
      {...other}
    />
  )
}
export default Loader

defineCustomElementClLoader()
