type IPCRenderer = {
  on: (
    channel: string,
    listener: (event: string, ...args: any[]) => void
  ) => void
  once: (
    channel: string,
    listener: (event: string, ...args: any[]) => void
  ) => void
  removeListener: (channel: string, listener: (...args: any[]) => void) => void
  removeAllListeners: (channel: string) => void
  send: (channel: string, ...args: any[]) => void
  invoke: (channel: string, ...args: any[]) => Promise<any>
  sendSync: (channel: string, ...args: any[]) => any
  postMessage: (channel: string, message: any, transfer?: MessagePort[]) => any
  sendTo: (webContentsId: number, channel: string, ...args: any[]) => any
  sendToHost: (channel: string, ...args: any[]) => any
}
class IPCStrategy {
  /**
   *
   */
  ipcRenderer: IPCRenderer

  /**
   *
   */
  channelName: string

  /**
   *
   * @param ipcRenderer
   * @param channelName
   */
  constructor(ipcRenderer, channelName = 'sm-web') {
    this.ipcRenderer = ipcRenderer
    this.channelName = channelName
  }

  /**
   * Broadcast a message to the given IPCRenderer
   * @public
   * @param message
   * @param [data=null]
   * @return
   */
  broadcast(message, data = null) {
    this.ipcRenderer.postMessage(this.channelName, { message, data })
  }
}

export default IPCStrategy
