import cn from 'clsx'
import { useTranslation } from 'react-i18next'

import { Icons } from 'constants/icons'
import { Icon } from 'ui/revision'

import classes from './user-feedback-side-button.module.scss'

type Props = {
  open?: boolean
  position?: 'bottom' | 'middle'
  onClick?: () => void
}

export const FeedbackSideButton: React.FC<Props> = ({
  open = false,
  position = 'bottom',
  onClick,
}: Props) => {
  const { t } = useTranslation()

  return (
    <div
      className={cn(classes.feedbackButton, classes[position])}
      onClick={onClick}
    >
      <Icon
        name={Icons.actionClose}
        className={cn(classes.buttonIcon, !open && classes.hidden)}
      />
      <span className={cn(classes.buttonText, open && classes.hidden)}>
        {t('Errors.generic.feedback', 'Feedback')}
      </span>
    </div>
  )
}

export default FeedbackSideButton
