import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { MAINTENANCE_DATE_FORMATS } from 'constants/constants'
import moment from 'lib/moment'

function useDateTime(date) {
  const { i18n } = useTranslation()
  moment.locale(i18n.language)

  return useMemo(() => moment(date, MAINTENANCE_DATE_FORMATS), [date])
}

export default useDateTime
