import { push, replace } from '@lagunovsky/redux-react-router'
import { useCallback, useEffect } from 'react'

import env from 'app/config/env'
import { useAuthContext } from 'app/features/auth'
import {
  initializeRoomOutput,
  terminateRoomOutput,
} from 'app/services/media/room-output'
import { useDispatch, useSelector } from 'app/services/state/redux-store'
import * as authUtils from 'lib/auth-utils'
import urlUtils from 'lib/url-utils'
import asFeature from 'ui/feature'
import Loadable from 'ui/loadable'
import Loader from 'ui/loader'

import reducer, { STORE_NAME, actions } from './login.reducer'

const AsyncLogin = Loadable(async () => import('./login.component'))

const LoginContainer = () => {
  const dispatch = useDispatch()
  const { authenticateUser, authenticating, isAuth } = useAuthContext()
  const sessionToken = authUtils.getSessionToken()
  const localToken = authUtils.getLocalToken()

  // @ts-expect-error
  const redirectTo = useSelector(({ router }) => router?.location?.state?.from)

  const handleUserAuthenticate = useCallback(
    async (conf) =>
      authenticateUser({ ...conf, redirectToMyRoom: !redirectTo })
        .then(() => {
          if (redirectTo) {
            dispatch(replace(redirectTo))
          }
        })
        .catch(() => {
          authUtils.removeToken()
        }),
    [authenticateUser, redirectTo, dispatch]
  )

  const handleUserFormSubmit = useCallback(
    async ({ password, email }) => {
      if (!email) return

      dispatch(actions.setEmail(email))

      if (!password) return

      await initializeRoomOutput()

      try {
        await handleUserAuthenticate({ username: email, password })
      } catch (err) {
        await terminateRoomOutput()
      }
    },
    [dispatch, handleUserAuthenticate]
  )

  const handleGuestFormSubmit = useCallback(
    ({ roomName }) => {
      if (!roomName) return
      dispatch(push(urlUtils.getNewMeetingPathname(roomName)))
    },
    [dispatch]
  )

  useEffect(() => {
    if (!authenticating && !isAuth && (sessionToken || localToken)) {
      handleUserAuthenticate({ sessionToken, localToken })
    }
  }, [])

  if (authenticating) return <Loader />

  return (
    <AsyncLogin
      showGuestForm={env('login.guest.enabled', false)}
      showUserForm={env('login.guest.enabled', false)}
      onGuestFormSubmit={handleGuestFormSubmit}
      onUserFormSubmit={handleUserFormSubmit}
    />
  )
}

export default asFeature(STORE_NAME, {
  reducer,
  removeOnUnmount: false,
})(LoginContainer)
