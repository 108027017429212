import i18n from 'i18next'

import env from 'app/config/env'
import {
  actions as privateChatActions,
  filters as privateChatFilters,
} from 'app/features/private-chats/private-chats.reducer'
import { actions as sidePanelActions } from 'app/features/side-panel/side-panel.reducer'
import { TABS } from 'constants/constants'

import NOTIFICATION_TYPES from '../notifications/notification-types'
import NotificationBuilder from './desktop-notification-builder'
import { actions } from './desktop-notification.reducer'

/**
 * Returns anonymous if `userName` is falsy
 * @param userName Name of the user
 * @returns Either the username or anonymous
 */
const userNameOrAnonymous = (userName) =>
  userName || i18n.t('WaitingRoom.anonymous', 'Anonymous')

/**
 * Product name as title of notifications
 */
const notificationTitle = env(
  'brand_config.longProductName',
  'Meetings'
) as string

/**
 * Creates notifications enabled notification
 * @returns Action creator
 */
export const createNotificationsEnabledNotification = () => (dispatch) => {
  const notificationBody = i18n.t(
    'Notifications.enabled',
    'Notifications have been enabled'
  )

  const notifcation = new NotificationBuilder(notificationTitle)
    .withBody(notificationBody)
    .build()

  dispatch(actions.createdNotification(notifcation))
}

/**
 * Creates user joined waiting room notification
 * @param waitingGuests The online guests in the waiting room
 * @returns Action creator
 */
export const createWaitingRoomNotification = (waitingGuests) => (dispatch) => {
  const [firstWaitingGuest] = waitingGuests

  const { userName, avatarImage } = firstWaitingGuest.meta

  const multipleGuests = waitingGuests.length > 1

  // If there are multiple people in the waiting room, change the text to reflect this
  const notificationBody = multipleGuests
    ? i18n.t(
        'WaitingRoom.multipleJoinedRoom',
        '{{count}} guests have entered the waiting room',
        { count: waitingGuests.length }
      )
    : `${userNameOrAnonymous(userName)} ${i18n.t(
        'WaitingRoom.joinedRoom',
        'has entered the waiting room'
      )}`

  let notification = new NotificationBuilder(notificationTitle)
    .withBody(notificationBody)
    .withTag(NOTIFICATION_TYPES.USER_ENTERS_WAITING_ROOM)
    .withRequireInteraction()
    .withOnClick(() => dispatch(sidePanelActions.tabMenuChange(TABS.LOBBY)))
    .withAutoClose()

  if (!multipleGuests) {
    notification = notification.withIcon(avatarImage)
  }

  dispatch(actions.createdNotification(notification.build()))
}

/**
 * Creates user left waiting room notification
 * @param userName Name of the user
 * @param avatar Avatar URL of the user
 * @returns Action creator
 */
export const createLeftWaitingRoomNotification =
  (userName, avatar) => (dispatch) => {
    const notificationBody = `${userNameOrAnonymous(userName)} ${i18n.t(
      'WaitingRoom.leftRoom',
      'has left the waiting room'
    )}`

    const notification = new NotificationBuilder(notificationTitle)
      .withBody(notificationBody)
      .withTag(NOTIFICATION_TYPES.USER_ENTERS_WAITING_ROOM) // Using the waiting room tag allows us to update the status
      .withIcon(avatar)
      .withRequireInteraction()
      .withOnClick(() => dispatch(sidePanelActions.tabMenuChange(TABS.LOBBY)))
      .withAutoClose()
      .build()

    dispatch(actions.createdNotification(notification))
  }

/**
 * Creates user joined meeting notification
 * @param userName Name of the user that joined the meeting
 * @param avatar Avatar URL of the user that joined the meeting
 * @returns Action creator
 */
export const createUserJoinedMeetingNotification =
  (userName, avatar) => (dispatch) => {
    const notificationBody = `${userNameOrAnonymous(userName)} ${i18n.t(
      'WaitingRoom.accepted',
      'has been accepted to the meeting'
    )}`

    const notification = new NotificationBuilder(notificationTitle)
      .withBody(notificationBody)
      .withIcon(avatar)
      .withOnClick(() => dispatch(sidePanelActions.tabMenuChange(TABS.LOBBY)))
      .withAutoClose()
      .build()

    dispatch(actions.createdNotification(notification))
  }

/**
 * Creates screen share request notification
 * @param userName Name of the user requesting screen share
 * @param avatar Avatar URL of the user requesting screen share
 * @returns Action creator
 */
export const createScreenShareRequestNotification =
  (from, userName, avatar) => (dispatch) => {
    const notificationBody = `${userNameOrAnonymous(userName)} ${i18n.t(
      'Message.requestedSs'
    )}`

    // User specific tag prevents duplicate notifications from the same user
    const userSpecificTag = `${from} : ${NOTIFICATION_TYPES.SCREEN_SHARE_REQUEST}`

    const notification = new NotificationBuilder(notificationTitle)
      .withBody(notificationBody)
      .withTag(userSpecificTag)
      .withIcon(avatar)
      .withOnClick(() => dispatch(sidePanelActions.tabMenuChange(TABS.GROUP)))
      .withAutoClose()
      .build()

    dispatch(actions.createdNotification(notification))
  }

/**
 * Creates screen share started notification
 * @param userName Name of the user that started screen sharing
 * @param avatar Avatar URL of the user that started screen sharing
 * @returns Action creator
 */
export const createScreenShareStartedNotification =
  (userName, avatar) => (dispatch) => {
    const notificationBody = i18n.t('Message.userSS.external', {
      user: userNameOrAnonymous(userName),
    })

    const notification = new NotificationBuilder(notificationTitle)
      .withBody(notificationBody)
      .withIcon(avatar)
      .withOnClick(() => dispatch(sidePanelActions.tabMenuChange(TABS.GROUP)))
      .withAutoClose()
      .build()

    dispatch(actions.createdNotification(notification))
  }

/**
 * Using a shared tag for both muting and unmuting allows us to switch the notification as the host toggles
 */
const hostToggleVideoTag = `${NOTIFICATION_TYPES.VIDEO_MUTED_BY_HOST} || ${NOTIFICATION_TYPES.VIDEO_UNMUTED_BY_HOST}`

/**
 * Creates host mute video notification
 * @returns Action creator
 */
export const createHostMuteVideoNotification = () => (dispatch) => {
  const notificationBody = i18n.t(
    'RoomPage.Notifications.muteVideoByHost',
    'Your video was disabled by the host'
  )

  const notification = new NotificationBuilder(notificationTitle)
    .withBody(notificationBody)
    .withTag(hostToggleVideoTag)
    .withOnClick(() => dispatch(sidePanelActions.tabMenuChange(TABS.GROUP)))
    .withAutoClose()
    .build()

  dispatch(actions.createdNotification(notification))
}

/**
 * Creates host unmute video notification
 * @returns Action creator
 */
export const createHostUnmuteVideoNotification = () => (dispatch) => {
  const notificationBody = i18n.t(
    'RoomPage.Notifications.unmuteVideoByHost',
    'Your video is re-enabled by the host'
  )

  const notification = new NotificationBuilder(notificationTitle)
    .withBody(notificationBody)
    .withTag(hostToggleVideoTag)
    .withOnClick(() => dispatch(sidePanelActions.tabMenuChange(TABS.GROUP)))
    .withAutoClose()
    .build()

  dispatch(actions.createdNotification(notification))
}

/**
 * Using a shared tag for both muting and unmuting allows us to switch the notification as the host toggles
 */
const hostToggleAudioTag = `${NOTIFICATION_TYPES.AUDIO_MUTED_BY_HOST} || ${NOTIFICATION_TYPES.AUDIO_UNMUTED_BY_HOST}`

/**
 * Creates host mute audio notification
 * @returns Action creator
 */
export const createHostMuteAudioNotification = () => (dispatch) => {
  const notificationBody = i18n.t(
    'RoomPage.Notifications.muteAudioByHost',
    'Your audio is muted by the host'
  )

  const notifcation = new NotificationBuilder(notificationTitle)
    .withBody(notificationBody)
    .withTag(hostToggleAudioTag)
    .withOnClick(() => dispatch(sidePanelActions.tabMenuChange(TABS.GROUP)))
    .withAutoClose()
    .build()

  dispatch(actions.createdNotification(notifcation))
}

/**
 * Creates host unmute audio notification
 * @returns Action creator
 */
export const createHostUnmuteAudioNotification = () => (dispatch) => {
  const notificationBody = i18n.t(
    'RoomPage.Notifications.unmuteAudioByHost',
    'Your audio is unmuted by the host'
  )

  const notification = new NotificationBuilder(notificationTitle)
    .withBody(notificationBody)
    .withTag(hostToggleAudioTag)
    .withOnClick(() => dispatch(sidePanelActions.tabMenuChange(TABS.GROUP)))
    .withAutoClose()
    .build()

  dispatch(actions.createdNotification(notification))
}

/**
 * Creates raised hand notification for host
 * @param from ID of user that sent the message
 * @param userName Name of the user that raised their hand
 * @param avatar Avatar URL of the user that raised their hand
 * @returns Action creator
 */
export const createHostRaiseHandNotification =
  (from, userName, avatar) => (dispatch) => {
    const notificationBody = i18n.t(
      'RoomPage.Notifications.raiseHandRequest.external',
      '{{user}} raised hand',
      { user: userNameOrAnonymous(userName) }
    )

    // User specific tag prevents duplicate notifications from the same user
    const userSpecificTag = `${from} : ${NOTIFICATION_TYPES.HOST_RAISE_HAND}`

    const notification = new NotificationBuilder(notificationTitle)
      .withBody(notificationBody)
      .withTag(userSpecificTag)
      .withIcon(avatar)
      .withOnClick(() => dispatch(sidePanelActions.tabMenuChange(TABS.USER)))
      .withAutoClose()
      .build()

    dispatch(actions.createdNotification(notification))
  }

/**
 * Creates slow link notification
 * @returns Action creator
 */
export const createSlowLinkNotification = () => (dispatch) => {
  const notificationBody = i18n.t(
    'RoomPage.Notifications.slowLink',
    'You seem to have a bad network connection'
  )

  const notification = new NotificationBuilder(notificationTitle)
    .withBody(notificationBody)
    .withTag(NOTIFICATION_TYPES.SLOW_LINK)
    .withAutoClose()
    .build()

  dispatch(actions.createdNotification(notification))
}

/**
 * Creates private message notification
 * @param from ID of user that sent the message
 * @param userName Name of user that sent the message
 * @param avatar Avatar URL of the user that sent the message
 * @returns Action creator
 */
export const createPrivateMessageNotification =
  (from, userName, avatar) => (dispatch, getState) => {
    const notificationBody = i18n.t(
      'RoomPage.Chat.privateChatNotification',
      'New message from {{user}}',
      { user: userNameOrAnonymous(userName) }
    )
    // User specific tag prevents duplicate notifications from the same user
    const userSpecificTag = `${from} : ${NOTIFICATION_TYPES.PRIVATE_MESSAGE}`

    const chat = privateChatFilters.findChatWithMember(getState(), from)

    const notification = new NotificationBuilder(notificationTitle)
      .withBody(notificationBody)
      .withTag(userSpecificTag)
      .withIcon(avatar)
      .withOnClick(() => dispatch(privateChatActions.openChat(chat.id)))
      .withAutoClose()
      .build()

    dispatch(actions.createdNotification(notification))
  }

/**
 * Creates file uploaded notification
 * @param fileName Name of the file
 * @returns Action creator
 */
export const createFileUploadedNotification = (fileName) => (dispatch) => {
  const notificationBody = i18n.t(
    'Fileupload.fileUploadedNotification',
    'New file shared: {{fileName}}',
    { fileName }
  )

  const notification = new NotificationBuilder(notificationTitle)
    .withBody(notificationBody)
    .withOnClick(() => dispatch(sidePanelActions.tabMenuChange(TABS.FILE)))
    .withAutoClose()
    .build()

  dispatch(actions.createdNotification(notification))
}
