import type { SVGProps } from 'react'
const SvgAgile3L = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <path
      fill='rgb(var(--color-brand-200))'
      d='m113.96 38.21-17.1-6.18a4.227 4.227 0 0 0-4.04.64c-.89.69-1.86 1.32-2.89 1.86l-1.35.71a17.742 17.742 0 0 1-16.52 0l-1.35-.71c-1.04-.55-2.01-1.18-2.9-1.87-1.18-.92-2.73-1.2-4.13-.68l-17.4 6.37c-1.98.72-2.53 3.25-1.05 4.74l35.64 35.64s24.67-26.54 34.12-35.75c1.52-1.48.96-4.04-1.04-4.77z'
    />
    <path
      fill='rgb(var(--color-brand-500))'
      d='M81.16 80.4c-.49 0-.97-.2-1.32-.55L44.2 44.21c-1.15-1.15-1.63-2.79-1.28-4.38s1.47-2.87 3-3.43l17.4-6.37c1.99-.73 4.21-.37 5.92.96.82.64 1.7 1.21 2.63 1.7l1.35.71a15.885 15.885 0 0 0 14.79 0l1.35-.71c.92-.48 1.8-1.05 2.62-1.69a6.094 6.094 0 0 1 5.82-.92l17.1 6.18c1.55.56 2.68 1.85 3.02 3.46.35 1.6-.15 3.25-1.32 4.39-9.3 9.07-33.81 35.42-34.05 35.68-.34.37-.82.58-1.33.59h-.03zM65.4 33.39c-.27 0-.54.05-.8.14L47.2 39.9a.98.98 0 0 0-.64.74c-.04.16-.08.58.27.94l34.28 34.28c5.25-5.63 24.75-26.49 32.86-34.4.36-.35.32-.77.28-.94a.998.998 0 0 0-.65-.74L96.5 33.6c-.77-.28-1.62-.15-2.27.35-.99.77-2.05 1.46-3.17 2.04l-1.35.71a19.591 19.591 0 0 1-18.26 0l-1.35-.71c-1.12-.59-2.19-1.28-3.18-2.05-.46-.36-1-.55-1.54-.55z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='m145.56 64.96-24.05-24.05c-.25-.25-.56-.55-.88-.62L100.53 33l-.54 1.93c-1.32 4.69-4.42 8.52-8.72 10.78a23.259 23.259 0 0 1-21.64 0 17.615 17.615 0 0 1-8.74-10.84l-.54-1.94-20.09 7.35v.09c-.46 0-.93.19-1.29.54L14.92 64.96a2.44 2.44 0 0 0-.21 3.21l12.31 17.76.11.15c.54.63 1.32.97 2.12.97.39 0 .79-.08 1.16-.25l9.91-5.41c.66-.36 1.47.1 1.49.85l1.76 47.13a3.363 3.363 0 0 0 3.36 3.24h67.01c1.81 0 3.29-1.43 3.36-3.24l1.74-46.68c.03-.74.82-1.2 1.48-.86l9.46 4.93.08.04c.37.17.77.25 1.16.25.8 0 1.58-.34 2.12-.97l12.43-17.91c.74-.96.65-2.35-.21-3.21z'
    />
    <path
      fill='rgb(var(--color-brand-500))'
      d='M113.94 134.35H46.93c-2.76 0-5-2.16-5.11-4.92L40.11 83.5l-8.87 4.84s-.07.04-.11.05c-1.8.83-4.05.33-5.34-1.17l-.17-.21-12.37-17.84c-1.23-1.6-1.07-3.97.42-5.45l24.04-24.05c.49-.49 1.1-.82 1.77-.97.05-.02.1-.05.15-.07l20.09-7.35c.46-.17.97-.14 1.4.09.43.22.76.62.89 1.09l.54 1.94c1.17 4.19 4.03 7.74 7.87 9.76a21.46 21.46 0 0 0 20.01 0c3.87-2.04 6.66-5.49 7.85-9.71l.54-1.93c.13-.47.45-.86.88-1.08.43-.22.94-.25 1.4-.09l20.1 7.27c.05.02.11.04.16.07.63.25 1.08.69 1.37.98l24.05 24.05a4.193 4.193 0 0 1 .36 5.51l-12.38 17.84-.11.14c-1.29 1.51-3.54 2.01-5.34 1.18-.02 0-.11-.06-.13-.06l-8.45-4.41-1.7 45.51a5.094 5.094 0 0 1-5.11 4.92zM40.8 79.51c.47 0 .94.12 1.36.36.84.48 1.36 1.34 1.39 2.3l1.76 47.13c.03.87.74 1.55 1.61 1.55h67.02c.87 0 1.58-.68 1.61-1.55l1.74-46.68a2.76 2.76 0 0 1 4.04-2.34l9.46 4.93c.4.18.88.09 1.17-.22l12.37-17.82c.25-.34.23-.73-.01-.97l-24.05-24.05c-.07-.07-.14-.14-.21-.2-.08-.03-.15-.06-.22-.09L101.7 35.3l-.03.12c-1.45 5.16-4.86 9.37-9.59 11.86a25 25 0 0 1-23.27 0 19.382 19.382 0 0 1-9.61-11.92l-.03-.12-17.93 6.57c-.28.2-.61.33-.95.33L16.16 66.22a.7.7 0 0 0-.06.91l12.42 17.91c.2.23.72.38 1.11.22l9.85-5.38c.42-.23.87-.34 1.32-.34zm79.42-37.52z'
    />
    <path
      fill='rgb(var(--color-brand-500))'
      d='M80.02 50.7c-4.03 0-8.07-.97-11.75-2.9-4.74-2.5-8.29-6.89-9.73-12.07L58 33.75l3.83-1.39.54 2.31c1.15 4.12 3.97 7.63 7.75 9.62 6.2 3.26 13.6 3.26 19.79 0 3.82-2.01 6.56-5.4 7.74-9.56l.5-2.45 4.02 1.25-.69 2.28c-1.47 5.22-4.92 9.48-9.71 12-3.68 1.93-7.71 2.9-11.75 2.9zm-47.9 35.11 3.28-1.79-15.88-23.53-2.68 2.68 15.28 22.64zm96.29.09-3.28-1.79 15.88-23.53 2.69 2.68-15.29 22.64zm-50.3-18.69c3.24 0 5.87 2.63 5.87 5.87v19.78h5.08c3.24 0 5.87 2.63 5.87 5.87v2.12c0 3.24-2.63 5.87-5.87 5.87H75.42c-3.24 0-5.87-2.63-5.87-5.87V73.09c0-3.24 2.63-5.87 5.87-5.87h2.69m0-2h-2.69c-4.34 0-7.87 3.53-7.87 7.87v27.76c0 4.34 3.53 7.87 7.87 7.87h13.64c4.34 0 7.87-3.53 7.87-7.87v-2.12c0-4.34-3.53-7.87-7.87-7.87h-3.08V73.08c0-4.34-3.53-7.87-7.87-7.87z'
    />
    <path
      fill='rgb(var(--color-brand-50))'
      d='M75.42 71.21h2.69c1.03 0 1.87.84 1.87 1.87v23.78h9.08c1.03 0 1.87.84 1.87 1.87v2.12c0 1.03-.84 1.87-1.87 1.87H75.42c-1.03 0-1.87-.84-1.87-1.87V73.09c0-1.03.84-1.87 1.87-1.87z'
    />
  </svg>
)
export default SvgAgile3L
