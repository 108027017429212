import { Grid } from '@mui/material'

import { Loader as BaseLoader } from 'ui/revision'

import classes from './loader.module.scss'

export const Loader: React.FC = () => {
  return (
    // TODO: Replace with CCL grid
    (<Grid
      container
      alignContent='center'
      className={classes.root}
      justifyContent='center'
    >
      <BaseLoader />
    </Grid>)
  );
}
export default Loader
