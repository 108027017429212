import { createSelector } from 'reselect'
import _ from 'lodash'

import {
  getOfflineMembers,
  getRoomMembers,
  rejectMemberId,
  rejectOfflineMembers,
} from 'app/state/utils'

import { STORE_NAME as CHANNEL_ROOM_STORE_NAME } from 'app/state/api/channels/channel-room.reducer'

/**
 * Return the members from the channel room.
 */
export const stateMembersSelector = (state, sortIteratees = [_.identity]) =>
  _.sortBy(state[CHANNEL_ROOM_STORE_NAME].meeting_room.members, sortIteratees)

/**
 * Return the memberId of the current user.
 */
export const stateMemberIdSelector = (state) =>
  state[CHANNEL_ROOM_STORE_NAME].member_id

/**
 * Return the memberIds of all members in the room.
 * @param state The current state
 * @param sortIteratees The iteratees to sort the members by
 */
export const roomMembersSelector = createSelector(
  stateMembersSelector,
  stateMemberIdSelector,
  (members, memberId) =>
    _.map(rejectMemberId(getRoomMembers(members), memberId), 'member_id')
)

/**
 * Return the memberIds of all members in the room that are online.
 * @param state The current state
 * @param sortIteratees The iteratees to sort the members by
 */
export const onlineRoomMembersSelector = createSelector(
  stateMembersSelector,
  stateMemberIdSelector,
  (members, memberId) =>
    _.map(
      rejectOfflineMembers(rejectMemberId(getRoomMembers(members), memberId)),
      'member_id'
    )
) as (state: any, sortIteratees?: any) => string[]

export const offlineRoomMembersSelector = createSelector(
  stateMembersSelector,
  stateMemberIdSelector,
  (members, memberId) =>
    _.map(
      getOfflineMembers(rejectMemberId(getRoomMembers(members), memberId)),
      'member_id'
    )
) as (state: any, sortIteratees?: any) => string[]

export const sendingVideoMembersSelector = createSelector(
  stateMembersSelector,
  stateMemberIdSelector,
  (members, memberId) =>
    _.map(
      _.filter(rejectMemberId(getRoomMembers(members), memberId), {
        states: { video_send: true },
      }),
      'member_id'
    )
) as (state: any, sortIteratees?: any) => string[]
