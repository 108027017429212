import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import cn from 'clsx'

import HelpAdornment from 'ui/help-adornment'

const TextFieldBase = ({
  InputProps,
  className,
  icon,
  input,
  label,
  loginFailed,
  meta,
  tooltipText,
  ...other
}) => {
  const error = loginFailed || (meta.dirty ? meta.error : '')

  if (icon) {
    InputProps = {
      ...InputProps,
      startAdornment: (
        <InputAdornment position='start'>
          <Icon>{icon}</Icon>
        </InputAdornment>
      ),
    }
  }

  return (
    <Grid container alignItems='flex-end' spacing={1}>
      <Grid item style={{ flex: '1' }}>
        <TextField
          fullWidth
          {...other}
          {...input}
          InputProps={InputProps}
          className={cn(className, 'mt-0')}
          error={!!error}
          helperText={error || ' '}
          label={label}
          margin='normal'
        />
      </Grid>
      {Boolean(tooltipText) && (
        <Grid item className='my-auto'>
          <HelpAdornment position='end' text={tooltipText} />
        </Grid>
      )}
    </Grid>
  )
}

export default TextFieldBase
