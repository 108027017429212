import env from 'app/config/env'

import PalmsSunsetSmall from './default/small/palms_sunset.jpg'
import PalmsSunsetLarge from './default/large/palms_sunset.jpg'
import PalmsDaySmall from './default/small/palms_day.jpg'
import PalmsDayLarge from './default/large/palms_day.jpg'
import BambooSmall from './default/small/bamboo.jpg'
import BambooLarge from './default/large/bamboo.jpg'
import CherryFlowersSmall from './default/small/cherry_flowers.jpg'
import CherryFlowersLarge from './default/large/cherry_flowers.jpg'
import SkySmall from './default/small/sky.jpg'
import SkyLarge from './default/large/sky.jpg'
import DeskSmall from './default/small/desk.jpg'
import DeskLarge from './default/large/desk.jpg'
import Architecture2Small from './default/small/architecture_2.jpg'
import Architecture2Large from './default/large/architecture_2.jpg'
import ArchitectureSmall from './default/small/architecture.jpg'
import ArchitectureLarge from './default/large/architecture.jpg'
import BooksSmall from './default/small/books.jpg'
import BooksLarge from './default/large/books.jpg'
import WallSmall from './default/small/wall.jpg'
import WallLarge from './default/large/wall.jpg'
import ColorsSmall from './default/small/colors.jpg'
import ColorsLarge from './default/large/colors.jpg'
import BubblesSmall from './default/small/bubbles.jpg'
import BubblesLarge from './default/large/bubbles.jpg'

const BrandBackgroundImages = env('background_effects.customBackgrounds', {})

const BackgroundEffectImages = {
  PalmsSunset: {
    small: PalmsSunsetSmall,
    large: PalmsSunsetLarge,
  },
  PalmsDay: {
    small: PalmsDaySmall,
    large: PalmsDayLarge,
  },
  Bamboo: {
    small: BambooSmall,
    large: BambooLarge,
  },
  CherryFlowers: {
    small: CherryFlowersSmall,
    large: CherryFlowersLarge,
  },
  Sky: {
    small: SkySmall,
    large: SkyLarge,
  },
  Desk: {
    small: DeskSmall,
    large: DeskLarge,
  },
  Architecture2: {
    small: Architecture2Small,
    large: Architecture2Large,
  },
  Architecture: {
    small: ArchitectureSmall,
    large: ArchitectureLarge,
  },
  Books: {
    small: BooksSmall,
    large: BooksLarge,
  },
  Wall: {
    small: WallSmall,
    large: WallLarge,
  },
  Colors: {
    small: ColorsSmall,
    large: ColorsLarge,
  },
  Bubbles: {
    small: BubblesSmall,
    large: BubblesLarge,
  },
  ...BrandBackgroundImages,
}

export default BackgroundEffectImages

export const defaultImage = Object.keys(BackgroundEffectImages)[0]
