import { ACTION_TYPES as CONFERENCE_ACTION_TYPES } from 'app/features/conference/conference.reducer'
import {
  STORE_NAME as SNACKBAR_QUEUE_STORE_NAME,
  selectors as snackbarQueueSelectors,
  actions as snackbarQueueActions,
} from './snackbar-queue.reducer'
import { ACTION_TYPES as NOTIFICATION_ACTION_TYPES } from '../notifications/notification.creators'
import NOTIFICATION_TYPES from '../notifications/notification-types'
import {
  enqueueBackgroundEffectLoadingSnackbar,
  enqueueFailedJoinRoom,
  enqueueFullRoomSnackbar,
  enqueueGuestMessageSnackbar,
  enqueueHostMuteAudio,
  enqueueHostMuteVideo,
  enqueueHostRaiseHandSnackbar,
  enqueueHostUnmuteAudio,
  enqueueHostUnmuteVideo,
  enqueueIncreaseGridSizeSnackbar,
  enqueueLocalRaiseHandSnackbar,
  enqueueMadeHostbyHostSnackbar,
  enqueueModeratorRightsRevokedSnackbar,
  enqueueRoomLinkSnackbar,
  enqueueScreenShareRequestApprovedSnackbar,
  enqueueScreenShareRequestRejectedSnackbar,
  enqueueScreenShareRequestSnackbar,
  enqueueTalkingWhileMutedSnackbar,
  enqueueUserWaitingRoomSnackbar,
  findAndCloseFullRoomSnackbar,
  findAndCloseHostMuteAudio,
  findAndCloseHostMuteVideo,
  findAndCloseHostUnmuteAudio,
  findAndCloseHostUnmuteVideo,
  findAndCloseScreenShareRequestSnackbar,
  findAndCloseTalkingWhileMutedSnackbar,
} from './snackbar-queue.utils'

const createNotificationHandlers = {
  /* --- Local user notifications --- */
  // Local user can increase grid size
  [NOTIFICATION_TYPES.INCREASE_GRID_SIZE]: () => (dispatch, getState) => {
    const { [SNACKBAR_QUEUE_STORE_NAME]: snackbarQueue } = getState()

    const shownNamedSnackbars =
      snackbarQueueSelectors.getShownNamedSnackbars(snackbarQueue)
    const hasShownIncreaseGridSizeNotification =
      shownNamedSnackbars.includes('increaseGridSize')

    if (!hasShownIncreaseGridSizeNotification) {
      dispatch(enqueueIncreaseGridSizeSnackbar())
    }
  },

  // Local user gets made a host
  [NOTIFICATION_TYPES.MADE_HOST_BY_HOST]: enqueueMadeHostbyHostSnackbar,

  // Local user host revoked
  [NOTIFICATION_TYPES.REVOKED_HOST_BY_HOST]:
    enqueueModeratorRightsRevokedSnackbar,

  // Local user raises hand
  [NOTIFICATION_TYPES.LOCAL_RAISE_HAND]: enqueueLocalRaiseHandSnackbar,

  // Local user requests screenshare
  [NOTIFICATION_TYPES.REQUESTING_SCREEN_SHARE]:
    enqueueScreenShareRequestSnackbar,
  // Local user's screenshare request is approved
  [NOTIFICATION_TYPES.SCREEN_SHARE_REQUEST_APPROVED]: () => (dispatch) => {
    dispatch(findAndCloseScreenShareRequestSnackbar())
    dispatch(enqueueScreenShareRequestApprovedSnackbar())
  },
  // Local user's screenshare request is rejected
  [NOTIFICATION_TYPES.SCREEN_SHARE_REQUEST_REJECTED]: () => (dispatch) => {
    dispatch(findAndCloseScreenShareRequestSnackbar())
    dispatch(enqueueScreenShareRequestRejectedSnackbar())
  },

  // Host muted local user's video
  [NOTIFICATION_TYPES.VIDEO_MUTED_BY_HOST]: () => (dispatch) => {
    dispatch(enqueueHostMuteVideo())
    dispatch(findAndCloseHostUnmuteVideo())
  },
  // Host unmuted local user's video
  [NOTIFICATION_TYPES.VIDEO_UNMUTED_BY_HOST]: () => (dispatch) => {
    dispatch(enqueueHostUnmuteVideo())
    dispatch(findAndCloseHostMuteVideo())
  },
  // Host muted local user's audio
  [NOTIFICATION_TYPES.AUDIO_MUTED_BY_HOST]: () => (dispatch) => {
    dispatch(enqueueHostMuteAudio())
    dispatch(findAndCloseHostUnmuteAudio())
  },
  // Host unmuted local user's audio
  [NOTIFICATION_TYPES.AUDIO_UNMUTED_BY_HOST]: () => (dispatch) => {
    dispatch(enqueueHostUnmuteAudio())
    dispatch(findAndCloseHostMuteAudio())
  },

  // Local user in waiting room tries to join full room
  [NOTIFICATION_TYPES.ROOM_FULL]: enqueueFullRoomSnackbar,

  // Local user in waiting room recieves message
  [NOTIFICATION_TYPES.GUEST_MESSAGE]: enqueueGuestMessageSnackbar,

  [NOTIFICATION_TYPES.USER_WAITING_ROOM]: enqueueUserWaitingRoomSnackbar,

  // Local user failed to join room
  [NOTIFICATION_TYPES.JOIN_ROOM_FAILED]: enqueueFailedJoinRoom,

  // Local user copies room link to clipboard
  [NOTIFICATION_TYPES.ROOM_LINK]: enqueueRoomLinkSnackbar,

  // Local user's background effect is loading
  [NOTIFICATION_TYPES.BACKGROUND_EFFECT_LOADING]:
    enqueueBackgroundEffectLoadingSnackbar,

  // Local user is talking while being muted
  [NOTIFICATION_TYPES.TALKING_WHILE_MUTED]:
    (mutedBy) => (dispatch, getState) => {
      const { [SNACKBAR_QUEUE_STORE_NAME]: snackbarQueue } = getState()

      const shownNamedSnackbars =
        snackbarQueueSelectors.getShownNamedSnackbars(snackbarQueue)
      const hasNotShownSnackbarBefore =
        !shownNamedSnackbars.includes('talkingWhileMuted')

      if (hasNotShownSnackbarBefore) {
        dispatch(enqueueTalkingWhileMutedSnackbar(mutedBy))
      }
    },

  /* --- Host notifications --- */
  // Guest raises hand
  [NOTIFICATION_TYPES.HOST_RAISE_HAND]: enqueueHostRaiseHandSnackbar,
}

const closeNotificationHandlers = {
  [NOTIFICATION_TYPES.REQUESTING_SCREEN_SHARE]:
    findAndCloseScreenShareRequestSnackbar,
  [NOTIFICATION_TYPES.ROOM_FULL]: findAndCloseFullRoomSnackbar,
  [NOTIFICATION_TYPES.TALKING_WHILE_MUTED]: () => (dispatch, getState) => {
    const { [SNACKBAR_QUEUE_STORE_NAME]: snackbarQueue } = getState()

    const shownNamedSnackbars =
      snackbarQueueSelectors.getShownNamedSnackbars(snackbarQueue)
    const hasShownSnackbarBefore =
      shownNamedSnackbars.includes('talkingWhileMuted')

    if (hasShownSnackbarBefore) {
      dispatch(findAndCloseTalkingWhileMutedSnackbar())
      dispatch(
        snackbarQueueActions.removeNameFromShownSnackbars('talkingWhileMuted')
      )
    }
  },
}

export default (store) => (next) => (action) => {
  const { dispatch } = store

  switch (action.type) {
    case NOTIFICATION_ACTION_TYPES.CREATE_NOTIFICATION:
      {
        const { type, ...rest } = action.payload

        const notificationHandler = createNotificationHandlers[type]

        if (notificationHandler) {
          dispatch(notificationHandler(...Object.values(rest)))
        }
      }
      break

    case NOTIFICATION_ACTION_TYPES.CLOSE_NOTIFICATION:
      {
        const { type, ...rest } = action.payload

        const notificationHandler = closeNotificationHandlers[type]

        if (notificationHandler) {
          dispatch(notificationHandler(...Object.values(rest)))
        }
      }
      break

    case CONFERENCE_ACTION_TYPES.INIT:
      dispatch(
        snackbarQueueActions.removeNameFromShownSnackbars('talkingWhileMuted')
      )
      break

    default:
      break
  }

  return next(action)
}
