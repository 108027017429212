import type { SVGProps } from 'react'
const SvgEmoji10 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle cx={79.4} cy={96.28} r={48.66} fill='rgb(var(--color-brand-100))' />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.4 146.94c-27.93 0-50.66-22.72-50.66-50.66S51.46 45.62 79.4 45.62s50.66 22.72 50.66 50.66-22.72 50.66-50.66 50.66zm0-97.31c-25.73 0-46.66 20.93-46.66 46.66s20.93 46.66 46.66 46.66 46.66-20.93 46.66-46.66-20.93-46.66-46.66-46.66z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M79.4 111.89c-5.15 0-10.07-.98-14.58-2.76-1.34-.53-2.81.43-2.82 1.87v.13c0 8.3 7.79 15.04 17.41 15.04s17.41-6.73 17.41-15.04V111c-.01-1.43-1.49-2.39-2.82-1.87a39.713 39.713 0 0 1-14.58 2.76z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.4 127.16c-10.15 0-18.41-7.19-18.41-16.04v-.13c0-1 .51-1.93 1.34-2.49.84-.57 1.91-.68 2.85-.31 4.53 1.78 9.31 2.69 14.22 2.69s9.69-.9 14.22-2.69c.94-.37 2.01-.26 2.85.31a3.02 3.02 0 0 1 1.34 2.48v.14c0 8.84-8.26 16.04-18.41 16.04zm-15.35-17.18c-.21 0-.42.06-.6.19-.29.19-.45.5-.46.84v.12c0 7.74 7.36 14.04 16.41 14.04s16.41-6.3 16.41-14.04V111c0-.33-.17-.64-.46-.83-.3-.2-.66-.24-1-.11-4.77 1.88-9.8 2.83-14.95 2.83s-10.19-.95-14.95-2.83c-.13-.05-.26-.08-.4-.08z'
    />
    <circle
      cx={106.38}
      cy={91.3}
      r={11.99}
      fill='rgb(var(--color-neutral-50))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M106.38 104.54c-7.3 0-13.24-5.94-13.24-13.24s5.94-13.24 13.24-13.24S119.62 84 119.62 91.3s-5.94 13.24-13.24 13.24zm0-23.99c-5.92 0-10.74 4.82-10.74 10.74s4.82 10.74 10.74 10.74 10.74-4.82 10.74-10.74-4.82-10.74-10.74-10.74z'
    />
    <circle cx={106.38} cy={91.3} r={3.1} fill='rgb(var(--color-brand-700))' />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M45.68 103.3c-.63 0-1.24-.33-1.58-.91-.5-.87-.2-1.99.67-2.49l15.09-8.71-15.09-8.71c-.87-.5-1.17-1.62-.67-2.49.5-.87 1.62-1.17 2.49-.67l16.54 9.55c.83.48 1.34 1.37 1.34 2.33s-.52 1.85-1.34 2.33l-16.54 9.55c-.29.17-.6.24-.91.24z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M79.36 115.2c6.88 0 11.48 5.09 10.26 11.38-1.21 6.28-5.81 11.38-10.26 11.38-4.46 0-9.05-5.09-10.26-11.38-1.21-6.28 3.38-11.38 10.26-11.38z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.36 139.2c-5.1 0-10.15-5.44-11.49-12.39-.63-3.26.12-6.37 2.1-8.78 2.14-2.6 5.57-4.08 9.39-4.09 3.82 0 7.25 1.49 9.39 4.09 1.98 2.4 2.73 5.52 2.1 8.78-1.34 6.95-6.39 12.39-11.49 12.39zm0-22.75c-3.08 0-5.79 1.16-7.46 3.18-1.5 1.82-2.06 4.2-1.58 6.71 1.08 5.62 5.22 10.36 9.04 10.36s7.95-4.75 9.04-10.36c.48-2.51-.08-4.9-1.58-6.71-1.67-2.02-4.39-3.18-7.46-3.18z'
    />
  </svg>
)
export default SvgEmoji10
