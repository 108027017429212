import Task from 'lib/tasks/coligo-task'

import { actions as channelAuthActions } from 'app/state/api/channels/channel-auth.reducer'

/**
 * @class
 * @classdesc JoinAuthChannelTask joins the auth channel
 */
class JoinAuthChannelTask extends Task {
  name = 'JoinAuthChannelTask'

  store

  constructor(store) {
    super()

    this.store = store
  }

  async run() {
    await this.store.dispatch(channelAuthActions.join())
  }
}

export default JoinAuthChannelTask
