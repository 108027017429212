/**
 * Check whether the given string is a valid regular expression.
 * @param str The string to check.
 * @return True if the string is a valid regular expression, false otherwise.
 */
export const isStringRegex = (str: string): boolean => {
  try {
    new RegExp(str)
  } catch (e) {
    return false
  }
  return true
}

/**
 * Function returns true when given string is a meeting code.
 * The string is a meeting code when it merely contains 6 digits.
 * @param string The string to check.
 * @return True if the string is a meeting code, false otherwise.
 */
export const isStringMeetingCode = (string: string) =>
  !!/^[0-9]{6}$/.test(string)

/**
 * Function returns a string with the first letter capitalized
 * @param value The string to capitalize.
 * @return The string with the first letter capitalized.
 */
export const capitalizeFirst = (value: string) => {
  if (!value.length) {
    throw new Error('String must at least have length 1')
  }

  return value[0].toUpperCase() + value.substring(1)
}
