import type { SVGProps } from 'react'
const SvgAgile9Question = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle cx={82.17} cy={78.48} r={62} fill='rgb(var(--color-brand-50))' />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M92.03 53.13v5.94l-1.87 4.28L84 70.39l-5.39 7.04-2.64 7.58.55 8.36 2.31 2.64 3.08.22 6.27-1.1.66-2.53 1.1-8.36 10.88-12.53 4.73-8.8.66-9.12-2.97-9.79-4.95-5.93-7.58-3.19L85.1 34l-8.47.33-7.91 4.29-4.07 4.83-3.19 6.49-.88 6.16v2.97l3.41 2.63 7.92-.33 1.75-3.4 1.54-6.05 3.52-3.63 9.02-.11 2.53 1.21 1.76 3.74z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M102.05 39.34C97.69 34.47 91.45 31.9 84 31.9c-14.48 0-23.99 8.75-25.43 23.41l-.08.79c-.1.99-.02 1.91.22 2.75.88 3.01 3.57 5.03 6.68 5.03h3.59c3.5 0 6.47-2.62 6.9-6.1l.09-.75c.72-5.84 2.59-7.62 8.01-7.62 3.5 0 5.48 1.42 6.06 4.33.12.62.66 1.06 1.29 1.06h1.52c.39 0 .76-.17 1.01-.47.25-.3.36-.69.29-1.07-1.21-6.96-6.89-8-10.17-8-7.52 0-11.14 3.36-12.12 11.25l-.09.75a2.82 2.82 0 0 1-2.8 2.47h-3.59c-.79 0-1.55-.34-2.09-.93-.53-.59-.79-1.38-.72-2.17l.09-.94c1.23-12.5 9-19.67 21.31-19.67 6.24 0 11.42 2.1 14.97 6.06 3.18 3.56 4.86 8.35 4.86 13.87 0 9.21-3.53 14.33-10.49 21.09-4.61 4.44-6.17 7.75-6.17 13.13v.8c0 1.91-1.56 3.47-3.47 3.47h-2.3c-1.91 0-3.47-1.56-3.47-3.47v-.8c0-5.46.73-11.97 9.42-20.22 5.45-5.17 6.54-8.5 6.88-10.83.05-.38-.06-.75-.3-1.04-.24-.28-.6-.44-.97-.44h-1.6c-.63 0-1.16.45-1.27 1.08-.31 1.74-1.21 4.08-5.59 8.23-9.42 8.94-10.72 16.45-10.72 23.22v.8c0 4.19 3.41 7.61 7.61 7.61h2.3c4.19 0 7.61-3.41 7.61-7.61v-.8c0-4.16 1.15-6.53 4.91-10.15 7.62-7.41 11.76-13.44 11.76-24.07 0-6.55-2.05-12.3-5.92-16.63z'
    />
    <circle
      cx={82.78}
      cy={114.79}
      r={7.24}
      fill='rgb(var(--color-neutral-50))'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M82.78 124.03c-5.09 0-9.24-4.14-9.24-9.24s4.14-9.24 9.24-9.24 9.24 4.14 9.24 9.24-4.14 9.24-9.24 9.24zm0-14.47c-2.89 0-5.24 2.35-5.24 5.24s2.35 5.24 5.24 5.24 5.24-2.35 5.24-5.24-2.35-5.24-5.24-5.24z'
    />
  </svg>
)
export default SvgAgile9Question
