import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'

import FormBase from './form-base.component'

const Form = ({ name, ...other }) => {
  const FormComponent = useMemo(
    () => reduxForm({ form: name })(FormBase),
    [name]
  )
  return <FormComponent name={name} {...other} />
}

Form.propTypes = {
  name: PropTypes.string.isRequired,
}

export default Form
