import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import EventContent from '../event-content'

const FileUploadEventContent = ({
  filesNo,
  messageKey,
  tabId,
  onChangeTab,
}) => {
  const { t } = useTranslation()
  return (
    <EventContent>
      <div
        className='file-event-message'
        role='button'
        tabIndex='-1'
        onClick={() => onChangeTab(tabId)}
      >
        {t(messageKey, { filesNo })}
      </div>
    </EventContent>
  )
}

FileUploadEventContent.propTypes = {
  messageKey: PropTypes.string.isRequired,

  filesNo: PropTypes.number,
  tabId: PropTypes.number,

  onChangeTab: PropTypes.func,
}

FileUploadEventContent.defaultProps = {
  filesNo: 0,
  tabId: 0,

  onChangeTab: Function.prototype,
}

export default FileUploadEventContent
