import _ from 'lodash'

export const STORE_NAME = 'feature/app'

const INIT = `sm-web/${STORE_NAME}/INIT`
const SET_MAINTENANCE = `sm-web/${STORE_NAME}/SET_MAINTENANCE`
const UPDATE_MAINTENANCE = `sm-web/${STORE_NAME}/UPDATE_MAINTENANCE`
const BLUR = `sm-web/${STORE_NAME}/BLUR`
const FOCUS = `sm-web/${STORE_NAME}/FOCUS`

export const ACTION_TYPES = {
  INIT,
  SET_MAINTENANCE,
  UPDATE_MAINTENANCE,
  BLUR,
  FOCUS,
}

const setMaintenance = (payload) => ({ type: SET_MAINTENANCE, payload })

const updateMaintenance = (payload) => ({ type: UPDATE_MAINTENANCE, payload })

const blur = () => ({ type: BLUR })

const focus = () => ({ type: FOCUS })

export const actions = {
  setMaintenance,
  updateMaintenance,
  blur,
  focus,
}

const INITIAL_STATE = {
  hasFocus: document.hasFocus(),
  maintenance: {
    show: false,
    info: undefined,
  },
}

const ACTION_HANDLERS = {
  [FOCUS]: (state) => ({ ...state, hasFocus: true }),
  [BLUR]: (state) => ({ ...state, hasFocus: false }),
  [SET_MAINTENANCE]: (state, action) => ({
    ...state,
    maintenance: action.payload,
  }),
  [UPDATE_MAINTENANCE]: (state, action) => ({
    ...state,
    maintenance: {
      ...state.maintenance,
      ...action.payload,
    },
  }),
  'sm-web/RESET': () => INITIAL_STATE,
}

export default (state = INITIAL_STATE, action = {}) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export const selectors = {
  getHasFocus(state) {
    return _.get(state, 'hasFocus', false)
  },
  getMaintenance(state) {
    return _.get(state, 'maintenance', INITIAL_STATE.maintenance)
  },
}
