import _ from 'lodash'

// Prefix for all environment variables coming from public/config.js
const KEY_PREFIX = 'REACT_APP_'

function keyToPath(key, keyPrefix = KEY_PREFIX) {
  const parts = key.split('.')
  parts[0] = parts[0].toUpperCase()
  return `${keyPrefix}${parts.join('.')}`
}

/**
 * sets a config variable
 * @param key
 * @param value
 * @param keyPrefix
 */
export function setEnv(key, value, keyPrefix = KEY_PREFIX) {
  const path = keyToPath(key, keyPrefix)
  _.set(window, path, value)
}

/**
 * Get an environment parameter based on the key
 */
export const env = <T = unknown>(
  key: string,
  fallback?: any,
  keyPrefix = KEY_PREFIX
): T => {
  if (key === 'version') {
    return import.meta.env.VITE_VERSION as unknown as T
  }
  const path = keyToPath(key, keyPrefix)
  return _.get(window, path, fallback) as T
}

export default env
