/**
 * This reducer follows the Ducks budling style
 * see https://github.com/erikras/ducks-modular-redux
 * basic rules:
 * - MUST export default a function called reducer()
 * - MUST export its action creators as functions
 * - MUST have action types in the form npm-module-or-app/reducer/ACTION_TYPE
 * - MAY export its action types as UPPER_SNAKE_CASE, if an external reducer
 *   needs to listen for them, or if it is a published reusable library
 */
import _ from 'lodash'
import { v4 as uuid } from 'uuid'

import { getExtensionForFileName, getOnlyFileName } from 'lib/files'

import TaskQueue from 'app/services/task-queue'
import createUploadTask from './uploadTask'
import { AnyAction } from 'redux'
import { AppThunk } from 'app/services/state/redux-store'
import { assert } from 'console'

export const STORE_NAME = 'feature/file-upload-queue'

// ------------------------------------
// Constants
// ------------------------------------
const FILES_QUEUE_ADD = `sm-web/${STORE_NAME}/FILES_QUEUE_ADD`
const FILES_QUEUE_CONFIRM = `sm-web/${STORE_NAME}/FILES_QUEUE_CONFIRM`
const FILES_QUEUE_REMOVE = `sm-web/${STORE_NAME}/FILES_QUEUE_REMOVE`
const FILES_QUEUE_COMPLETE = `sm-web/${STORE_NAME}/FILES_QUEUE_COMPLETE`
const FILES_QUEUE_RESET = `sm-web/${STORE_NAME}/FILES_QUEUE_RESET`
const FILES_QUEUE_ERROR = `sm-web/${STORE_NAME}/FILES_QUEUE_ERROR`
const FILES_QUEUE_PROGRESS = `sm-web/${STORE_NAME}/FILES_QUEUE_PROGRESS`
const RESET_FILES = `sm-web/${STORE_NAME}/RESET_FILES`

export const ACTION_TYPES = {
  FILES_QUEUE_ADD,
  FILES_QUEUE_CONFIRM,
  FILES_QUEUE_REMOVE,
  FILES_QUEUE_COMPLETE,
  FILES_QUEUE_RESET,
  FILES_QUEUE_ERROR,
  FILES_QUEUE_PROGRESS,
  RESET_FILES,
}

type QueueFile = {
  id: string
  error?: string
  ext: string
  name: string
  fileName: string
  fileType: string
  size: number
  progress: number
  file: File
}

type FailedQueueFile = QueueFile & { err: string }

type FileUploadQueueState = {
  unconfirmed: string[]
  queueById: {
    [key: string]: QueueFile
  }
  queue: string[]
  queueCurrent: string | null
}

const INITIAL_STATE: FileUploadQueueState = {
  unconfirmed: [],
  queueById: {},
  queue: [],
  queueCurrent: null,
}

/**
 * returns true if given file has failed to upload
 * @param file
 */
const isFailedFile = (file) => !!file.error
const isNotFailed = (file) => !file.error

/**
 * returns true if given file is currently uploading file
 * @param state file upload queue state
 * @param file
 */
const isCurrentUploadingFile = (state: FileUploadQueueState, file) =>
  file.id === state.queueCurrent
const isNotCurrentUploadingFile = (state, file) =>
  file.id !== state.queueCurrent

export const filters = {
  isNotFailed,
  isFailedFile,
  isCurrentUploadingFile,
  isNotCurrentUploadingFile,
}

/**
 * get file by id
 * @param state file queue state
 * @return returns function that returns file by id from state
 */
const selectFile = (state: FileUploadQueueState, id: string): QueueFile =>
  _.get(state.queueById, `[${id}]`)

/**
 * @return returns list of file objects
 */
const selectFiles = (state: FileUploadQueueState) => _.values(state.queueById)

const selectQueueCurrentFile = (state: FileUploadQueueState): QueueFile =>
  state.queueCurrent ? selectFile(state, state.queueCurrent) : null

/**
 * return list of files that failed to upload
 * @param state file upload queue state
 */
const selectFailedFiles = (state: FileUploadQueueState): FailedQueueFile[] =>
  _.filter(selectFiles(state), isFailedFile) as FailedQueueFile[]

/**
 * return list of currently queued files
 * @param state file upload queue state
 */
const selectQueuedFiles = (state: FileUploadQueueState): QueueFile[] =>
  _.map(state.queue, (id) => selectFile(state, id))

/**
 * return list of file ids that are queued but not confirmed for uploading
 * @param state file upload queue state
 */
const selectUnconfirmedFiles = (state: FileUploadQueueState): QueueFile[] =>
  state['unconfirmed'].map((id) => selectFile(state, id))

export const selectors = {
  selectFile,
  selectFiles,
  selectQueueCurrentFile,
  selectFailedFiles,
  selectQueuedFiles,
  selectUnconfirmedFiles,
}

/**
 */
const add = (files: FileList): AnyAction => ({
  type: FILES_QUEUE_ADD,
  files: _.keyBy(
    Array.from(files).map((file) => ({
      id: uuid(),
      error: false,
      ext: getExtensionForFileName(file.name),
      name: getOnlyFileName(file.name),
      fileName: file.name,
      fileType: file.type,
      size: file.size,
      progress: 0,
      file,
    })),
    'id'
  ),
})

/**
 * @param id of queue entry
 * @param err
 */
const error = (id: string, err: string) => ({
  type: FILES_QUEUE_ERROR,
  id,
  err,
})

/**
 * @param file
 */
const confirm =
  (memberId: string): AppThunk =>
  (dispatch, getState) => {
    const { [STORE_NAME]: state } = getState()
    selectUnconfirmedFiles(state).forEach(async (file) =>
      TaskQueue.add(async () =>
        createUploadTask({
          file,
          store: { dispatch, getState },
          memberId,
        })
      ).catch((e: Error) => {
        let errorMessage: string
        try {
          /**
           * NOTE: when server throws a 500 it (potentially) includes a JSON body which is passed as error message
           * we're expecting an error message in the form of {reason: 'error message'}
           */
          errorMessage = (JSON.parse(e.message) as { reason: string }).reason
        } catch (err) {
          errorMessage = e.message
        }
        dispatch(error(file.id, errorMessage))
      })
    )
    dispatch({ type: FILES_QUEUE_CONFIRM })
  }

/**
 * this action result in the reset of unconfirmed files
 */
const reset = () => ({
  type: FILES_QUEUE_RESET,
})

/**
 * @param id of queue entry
 */
const remove = (id) => ({
  type: FILES_QUEUE_REMOVE,
  id,
})

/**
 * @param id of queue entry
 */
const complete = (id) => ({
  type: FILES_QUEUE_COMPLETE,
  id,
})

/**
 * @param id of queue entrys
 * @param percentage
 * @return
 */
const progress = (id, percentage) => ({
  type: FILES_QUEUE_PROGRESS,
  id,
  percentage,
})

export const actions = {
  add,
  confirm,
  remove,
  reset,
  error,
  progress,
  complete,
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [FILES_QUEUE_ADD]: (state, { files }) => ({
    ...state,
    queueById: {
      ...state.queueById,
      ...files,
    },
    unconfirmed: [...state.unconfirmed, ..._.keys(files)],
  }),
  [FILES_QUEUE_CONFIRM]: (state) => ({
    ...state,
    queue: [...state.queue, ...state.unconfirmed],
    unconfirmed: [],
  }),
  [FILES_QUEUE_REMOVE]: (state, { id }) => ({
    ...state,
    queueById: _.omit(state.queueById, id),
    unconfirmed: _.without(state.unconfirmed, id),
    queue: _.without(state.queue, id),
    queueCurrent: id === state.queueCurrent ? null : state.queueCurrent,
  }),
  [FILES_QUEUE_COMPLETE]: (state, { id }) => ({
    ...state,
    queueById: _.omit(state.queueById, id),
    unconfirmed: _.without(state.unconfirmed, id),
    queue: _.without(state.queue, id),
    queueCurrent: id === state.queueCurrent ? null : state.queueCurrent,
  }),
  [FILES_QUEUE_RESET]: (state) => ({
    ...state,
    queueById: _.omit(state.queueById, state.unconfirmed),
    unconfirmed: [],
  }),
  [FILES_QUEUE_ERROR]: (state, { id, err }) => ({
    ...state,
    queueById: {
      ...state.queueById,
      [id]: {
        ...state.queueById[id],
        error: err,
      },
    },
    queueCurrent: state.queueCurrent === id ? null : state.queueCurrent,
    queue: _.without(state.queue, id),
  }),
  [FILES_QUEUE_PROGRESS]: (state, { id, percentage }) => ({
    ...state,
    queueById: {
      ...state.queueById,
      [id]: {
        ...state.queueById[id],
        progress: percentage,
      },
    },
    queueCurrent: id,
    queue: _.without(state.queue, id),
  }),
  [RESET_FILES]: () => INITIAL_STATE,
  'sm-web/RESET': () => INITIAL_STATE,
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = INITIAL_STATE, action = {}) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
