import { createContext, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import browserUtils from 'lib/browser-utils'

import {
  useInitialViewport,
  useKeyboardMobile,
  useWindowOrientationDesktop,
  useWindowOrientationMobile,
  useWindowHeight,
} from 'lib/react/hooks/use-window-size.hook'

const isMobile = browserUtils.isMobileTouchDevice()

const orientationHook = isMobile
  ? useWindowOrientationMobile
  : useWindowOrientationDesktop

const ViewportContext = createContext()

export const useViewportContext = () => useContext(ViewportContext)

const ViewportProvider = ({ children }) => {
  const orientation = orientationHook()
  const viewportHeight = useWindowHeight()
  const initialValues = useInitialViewport(orientation, viewportHeight)

  const isKeyboardOpen = useKeyboardMobile(
    initialValues,
    orientation,
    viewportHeight
  )

  const values = {
    isKeyboardOpen,
    orientation,
    viewportHeight,
  }

  useEffect(() => {
    if (isMobile) {
      document.getElementsByTagName('body')[0].style.overflowY =
        isKeyboardOpen || orientation.includes('landscape') ? 'auto' : 'hidden'
    }
  }, [isKeyboardOpen, orientation])

  return (
    <ViewportContext.Provider value={values}>
      {children}
    </ViewportContext.Provider>
  )
}

ViewportProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const ViewportConsumer = ViewportContext.Consumer

export default ViewportProvider
