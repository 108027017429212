import env from 'app/config/env'

export const MAX_MESSAGES_FOR_WAITING_USERS = 5
export const DEFAULT_MAX_GUESTS = env('default_license_id') || 9
export const DEFAULT_MAX_GRID_MEMBERS = 4
export const DEFAULT_STICKER_TIMEOUT = 7000

export const MAX_FILE_SIZE = 50000000
export const MAX_FILES = 5
export const MAX_NOTIFICATION_BADGE = 9

export const SLOW_LINK_RESET_TIMEOUT = 4000

export const MESSAGE_DATA_TYPE = 'content.data.message_data.type'

export const FOOTER_BANNER_DISPLAY_TIME_MS = 10000

export const STICKER_CATEGORIES_WITH_TEXT = ['holacracy']

// Array that contains the sticker folder names (categories) in the order they should be displayed
export const STICKER_CATEGORY_ORDER = [
  'emoji',
  'reaction',
  'holacracy',
  'agile',
]

const ENVNAME = import.meta.env.MODE

export const MIN_SCREEN_SIZE =
  ENVNAME === 'development'
    ? { width: 1, height: 1 }
    : { width: 1024, height: 640 }

export const LANGUAGES = [
  {
    code: 'en',
    flagIsoCode: 'GB',
  },
  {
    code: 'da',
    flagIsoCode: 'DK',
  },
  {
    code: 'nl',
    flagIsoCode: 'NL',
  },
  {
    code: 'de',
    flagIsoCode: 'DE',
  },
  {
    code: 'fr',
    flagIsoCode: 'FR',
  },
  {
    code: 'it',
    flagIsoCode: 'IT',
  },
  {
    code: 'es',
    flagIsoCode: 'ES',
  },
]

export const ROOM_CODE_LEN = 6
export const USER_NAME_LEN = 25
export const MIN_ROOM_NAME_LEN = 4
export const UNDER_CONSTRUCTION_TXT = 'Feature under development'
export const USER_STATUS = {
  SETUP: 'in-setup',
  WAITING: 'in-waitingroom',
  IN_ROOM: 'in-conferenceroom',
}

export const SESSION_STORAGE_TYPES = {
  AUTH_TOKEN: 'AUTH_TOKEN',
}

export const LOCAL_STORAGE_TYPES = {
  CAM_DEVICE_LABEL: 'CAM_DEVICE_LABEL',
  MIC_DEVICE_LABEL: 'MIC_DEVICE_LABEL',
  OUT_DEVICE_LABEL: 'OUT_DEVICE_LABEL',
  FACING_MODE: 'FACING_MODE',
  AUTH_TOKEN: 'AUTH_TOKEN',
  DEBUG_MODE: 'DEBUG_MODE',
  CONFERENCE_CODE: 'CONFERENCE_CODE',
  EMAIL: 'EMAIL',
  AVATAR: `${import.meta.env.MODE.toUpperCase()}_AVATAR`,
  FEATURE_READ: 'FEATURE_READ',
  AUDIO_MUTED: 'AUDIO_MUTED',
  VIDEO_MUTED: 'VIDEO_MUTED',
  USER_NAME: 'USER_NAME',
  GRID_SIZE: 'GRID_SIZE',
  RECENTLY_USED_STICKERS: 'RECENTLY_USED_STICKERS',
  HOTKEYS_ENABLED: 'HOTKEYS_ENABLED',
  SMS_INVITE_SETTINGS: 'SMS_INVITE_SETTINGS',
  BACKGROUND_BLUR_CONFIG: 'BACKGROUND_BLUR_CONFIG',
  BACKGROUND_REPLACE_CONFIG: 'BACKGROUND_REPLACE_CONFIG',
  ACTIVE_BACKGROUND_EFFECT: 'ACTIVE_BACKGROUND_EFFECT',
  NOISE_CANCELLED: 'NOISE_CANCELLED',
  DESKTOP_NOTIFICATIONS_ENABLED: 'DESKTOP_NOTIFICATIONS_ENABLED',
  DESKTOP_NOTIFICATIONS_DONT_REMIND_AGAIN:
    'DESKTOP_NOTIFICATIONS_DONT_REMIND_AGAIN',
}

export const CHAT_COMMAND_TYPES = {
  CONFERENCE_NAME_CHANGE: 'conference_name_change',
  USER_TYPING: 'user_typing',
  SHARE_BOARD: 'share_board',
}

export const CHAT_CONTENT_TYPES = {
  KICK_USER: 'kick_user',
  REMOVE_SS: 'remove_ss',
  SS_ACCEPTED: 'ss_accepted',
  SS_REJECTED: 'ss_rejected',
  USER_IN: 'user_in',
  USER_OUT: 'user_out',
  USER_SS: 'user_ss',
  STOP_SS: 'stop_ss',
  H_STOP_SS: 'h_stop_ss',
  REQUEST_SS: 'request_ss',
  REQUEST_SS_REJECTED: 'request_ss_rejected',
  REQUEST_SS_ACCEPTED: 'request_ss_accepted',
  ROOM_MUTE_VIDEO: 'room_mute_video',
  ROOM_UNMUTE_VIDEO: 'room_unmute_video',
  ROOM_MUTE_AUDIO: 'room_mute_audio',
  ROOM_UNMUTE_AUDIO: 'room_unmute_audio',
  NEW_FILE: 'new_file',
  TOBEUPLOADED: 'tobeuploaded',
  VIDEO_HIDDEN: 'video_hidden',
  AUDIO_MUTE: 'audio_mute',
  DELETED_FILE: 'deleted_file',
  START_RECORDING: 'start_recording',
  STOP_RECORDING: 'stop_recording',
  MAINTENANCE: 'maintenance',
  STICKER: 'sticker',
}

export const UPLOAD_MIME_TYPES = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.rar',
  'application/vnd.apple.installer+xml',
  'application/vnd.ms-asf',
  'application/vnd.adobe.flash.movie',
  'application/msword',
  'application/pdf',
  'application/rtf',
  'application/x-tex',
  'application/zip',
  'application/postscript',
  'application/rss+xml',
  'application/sql',
  'audio/x-aiff',
  'audio/mp4',
  'audio/mpeg',
  'audio/x-wav',
  'audio/x-ms-wma',
  'video/x-msvideo',
  'video/x-flv',
  'video/quicktime',
  'video/mp4',
  'video/mpeg',
  'video/x-ms-wmv',
  'image/vnd.adobe.photoshop',
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/tiff',
  'image/svg+xml',
  'text/plain',
  'text/css',
  'text/csv',
  'text/html',
  'text/html',
  'text/vcard',
  'font/otf',
  'font/ttf',
]

/**
 * TODO: Merge the functionality of kicking users with a disconnect type instead of having disconnect types and reasons seperate.
 */
export const DISCONNECT_TYPES = {
  USER_DECLINED: 'USER_DECLINED',
  ROOM_EXPIRED: 'ROOM_EXPIRED',
  KICKED: 'KICKED',
  MEETING_ENDED: 'MEETING_ENDED',
  CLOSED: 'CLOSED',
  LOGOUT: 'LOGOUT',
}

export const DISCONNECT_REASONS = {
  MEETING_ENDED: 'meeting_ended',
  USER_DECLINED: 'user_declined',
  ROOM_EXPIRED: 'room_expired',
  LOGOUT: 'LOGOUT',
}

export const AUTHENTICATION_ERRORS = Object.freeze({
  INVALID_CREDENTIALS: 'invalidCredentials',
  INVALID_TOKEN: 'invalidToken',
  INVALID_CODE: 'unauthorized',
  CONNECTION_REFUSED: 'connection_refused',
})

export const TABS = {
  LOBBY: 'LOBBY',
  GROUP: 'GROUP',
  USER: 'USER',
  FILE: 'FILE',
}
export const USER_TABS = [TABS.GROUP, TABS.USER, TABS.FILE]
export const HOST_TABS = [TABS.LOBBY, ...USER_TABS]

export enum USER_TYPES {
  GUEST = 'guest',
  USER = 'user',
  PHONE = 'phone',
}

export const CHAT_HISTORY_LIMIT = 20

export const COLIGO_PRD = 'https://meetings.coligo.com'

export const RELAYING_DEVICES = ['default', 'communications']

export enum FACING_MODES {
  USER = 'user',
  ENVIRONMENT = 'environment',
}

export const ZINDEX = {
  filedropOverlay: 1302,
}

export const PREFERRED_VIDEO_RATIO = 16 / 9

export const DRAG_TYPES = {
  FOOTER_THUMBNAIL: 'footer_thumbnail',
  GRID_VIEW_ENTRY: 'grid_view_entry',
  GRID_VIEW_THUMBNAIL: 'grid_view_thumbnail',
}

export const DROP_TYPES = {
  GRID_VIEW_ENTRY: 'grid_view_entry',
}

export const GRID_VIEW_ENTRY_TYPES = {
  AVATAR: 'avatar',
  STREAM: 'stream',
}

export const RIGHT_REQUESTING_STATE = {
  REQUESTING: 'requesting',
  CANCELED: 'canceled',
  APPROVED: 'approved',
  REJECTED: 'rejected',
}

export const GUEST_SETUP_MUTE_STATES = {
  MUTED: 'muted',
  UNMUTED: 'unmuted',
  DISABLED: 'disabled',
}

export const COLLAB_BOARD_TYPES = {
  MIRO: 'Miro',
}

export const BACKGROUND_EFFECT_TYPES = {
  BLUR: 'blur',
  REPLACE: 'replace',
}

export const MAINTENANCE_DATE_FORMATS = [
  'DD-MM-YYYY Hmm',
  'DD-MM-YYYY HH:mm',
  'YYYY-MM-DD Hmm',
  'YYYY-MM-DD HH:mm',
]

export const MAINTENANCE_DATE_DISPLAY_FORMAT = 'ddd D MMM YYYY - HH:mm'

export const BRAND_LIBRARY_FLAGS = {
  al: 'flag--albania',
  am: 'flag--armenia',
  au: 'flag--australia',
  at: 'flag--austria',
  by: 'flag--belarus',
  be: 'flag--belgium',
  ba: 'flag--bosnia--herzegovina',
  bg: 'flag--bulgaria',
  ca: 'flag--canada',
  hr: 'flag--croatia',
  cz: 'flag--czech--republic',
  da: 'flag--denmark',
  dk: 'flag--denmark',
  ee: 'flag--estonia',
  eu: 'flag--eu',
  fi: 'flag--finland',
  fr: 'flag--france',
  de: 'flag--germany',
  gr: 'flag--greece',
  hu: 'flag--hungary',
  is: 'flag--iceland',
  ie: 'flag--ireland',
  it: 'flag--italy',
  lv: 'flag--latvia',
  lt: 'flag--lithuania',
  md: 'flag--moldova',
  me: 'flag--montenegro',
  nl: 'flag--netherlands',
  mk: 'flag--north--macedonia',
  no: 'flag--norway',
  pl: 'flag--poland',
  pt: 'flag--portugal',
  ro: 'flag--romania',
  ru: 'flag--russia',
  rs: 'flag--serbia',
  sk: 'flag--slovakia',
  si: 'flag--slovenia',
  es: 'flag--spain',
  se: 'flag--sweden',
  en: 'flag--uk',
  ch: 'flag--switzerland',
  tr: 'flag--turkey',
  gb: 'flag--uk',
  ua: 'flag--ukraine',
} as const

export type Flags =
  (typeof BRAND_LIBRARY_FLAGS)[keyof typeof BRAND_LIBRARY_FLAGS]

export const BRAND_LIBRARY_LOGOS = {
  LogoLarge: 'Logo--large',
  logoLargeInversed: 'logo--large--inversed',
  logoSmall: 'logo--small',
  logoSmallInversed: 'logo--small--inversed',
} as const

export const FONTS = {
  /**
   * Noto Color Emoji
   * Used for displaying emojis cross platform. Windows does not always contain certain glyphs
   */
  emojiFont: 'Noto Color Emoji',
}
