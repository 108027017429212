import PropTypes from 'prop-types'
import Icon from '@mui/material/Icon'

import UserEventContent from '../user-event-content/user-event-content.component'
import RoomEventIcon from '../room-event-icon'
import SystemEvent from '../system-event.component'

const SystemMaintenanceEvent = ({ children, icon, memberId, timestamp }) => {
  const iconEl = typeof icon === 'string' ? <Icon>{icon}</Icon> : icon

  const userEventEl = <RoomEventIcon icon={iconEl} />

  return (
    <SystemEvent icon={userEventEl} timestamp={timestamp}>
      <UserEventContent memberId={memberId}>{children}</UserEventContent>
    </SystemEvent>
  )
}

SystemMaintenanceEvent.propTypes = {
  memberId: PropTypes.string.isRequired,
  timestamp: PropTypes.number.isRequired,

  children: PropTypes.node,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

SystemMaintenanceEvent.defaultProps = {
  children: null,
  icon: 'help_outline',
}

export default SystemMaintenanceEvent
