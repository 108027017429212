import { type ForwardedRef, forwardRef } from 'react'

import useForkRef from 'lib/react/hooks/use-fork-ref.hook'
import useSrcObject from 'lib/react/hooks/use-src-object.hook'

import { type VideoProps } from './video.types'

export const Video = forwardRef(function Video(
  { srcObject = null, ...other }: VideoProps,
  ref: ForwardedRef<HTMLVideoElement>
) {
  const videoRef = useSrcObject<HTMLVideoElement>(srcObject)
  const rootRef = useForkRef(ref, videoRef)
  return <video ref={rootRef} {...other} />
})

export default Video
