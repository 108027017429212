import { useCallback, useEffect, useState } from 'react'

/**
 * A hook for maintaining the typing state.
 * @param [delay=1000]
 * @return
 */
export function useTypingState(
  delay = 1000
): [boolean, (typing: boolean) => void] {
  const [timeout, setTimeout] = useState(false)

  const setTimeoutState = useCallback(
    (typing) => {
      if (!typing) setTimeout(false)
      else
        setTimeout(
          window.setTimeout(() => {
            setTimeoutState(false)
          }, delay)
        )
    },
    [delay]
  )

  // When a new timeout is set, we clear the old timeout.
  useEffect(
    () => () => {
      timeout && window.clearTimeout(timeout)
    },
    [timeout]
  )

  return [!!timeout, setTimeoutState]
}

/**
 * A hook for adding key commands and the handlers for it.
 * @return Function for adding key commands and one as a callback for draftjs
 */
export function useKeyCommands() {
  const [keyCommands] = useState({})

  function useKeyCommand(key, callback) {
    keyCommands[key] = callback
  }

  function handleKeyCommand(command) {
    if (keyCommands[command] && typeof keyCommands[command] === 'function') {
      keyCommands[command]()
      return 'handled'
    }
    return 'not-handled'
  }

  return [useKeyCommand, handleKeyCommand]
}
/**
 * A hook containing merely an useEffect which on execution focusses the given editor.
 * @param editor
 * @param [delay=0]
 * @param [deps=[]]
 * @return
 * NOTE: deps default is an empty array, meaning that by default useEffect is only being called on mount and unmount.
 */
export function useAutoFocus(editor, delay = 0, deps = []) {
  useEffect(() => {
    const timeout = window.setTimeout(() => {
      if (editor && editor.current) editor.current.focus()
    }, delay)

    return () => {
      window.clearTimeout(timeout)
    }
  }, deps)
}
