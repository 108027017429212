import { createStore as createReduxStore } from 'redux'
import {
  type TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from 'react-redux'
import {
  createRouterReducer,
  createRouterMiddleware,
  ROUTER_REDUCER_MAP_KEY,
  type ReduxRouterState,
} from '@lagunovsky/redux-react-router'
import { reducer as formReducer } from 'redux-form'
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension'
import thunk from 'redux-thunk'
import type { $CombinedState, AnyAction, Reducer } from 'redux'
import type { History } from 'history'
import type { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { MiddlewareRegistry, ReducerRegistry } from 'app/services/state'

import channelAvatarReducer, {
  STORE_NAME as CHANNEL_AVATAR_STORE_NAME,
} from 'app/state/api/channels/channel-avatar.reducer'
import channelAuthReducer, {
  STORE_NAME as CHANNEL_AUTH_STORE_NAME,
} from 'app/state/api/channels/channel-auth.reducer'
import authReducer, {
  STORE_NAME as AUTH_STORE_NAME,
} from 'app/features/auth/auth.reducer'
import channelConferenceReducer, {
  STORE_NAME as CHANNEL_CONFERENCE_STORE_NAME,
} from 'app/state/api/channels/channel-conference.reducer'
import channelRoomReducer, {
  STORE_NAME as CHANNEL_ROOM_STORE_NAME,
} from 'app/state/api/channels/channel-room.reducer'
import channelSettingsReducer, {
  STORE_NAME as CHANNEL_SETTINGS_STORE_NAME,
} from 'app/state/api/channels/channel-settings.reducer'

import socketReducer, {
  STORE_NAME as SOCKET_STORE_NAME,
} from 'app/state/api/socket/socket.reducer'
import socketMiddleware from 'app/state/api/socket/socket.middleware'
import flagsReducer, {
  STORE_NAME as FLAGS_STORE_NAME,
} from 'app/features/flags/flags.reducer'
import flagsMiddleware from 'app/features/flags/flags.middleware'
import mediaReducer, {
  STORE_NAME as MEDIA_STORE_NAME,
} from 'app/features/media/media.reducer'
import mediaMiddleware from 'app/features/media/media.middleware'
import settingsReducer, {
  STORE_NAME as SETTINGS_STORE_NAME,
} from 'app/features/settings/settings.reducer'
import settingsMiddleware from 'app/features/settings/settings.middleware'
import devicesReducer, {
  STORE_NAME as DEVICES_STORE_NAME,
} from 'app/features/devices/devices.reducer'
import devicesMiddleware from 'app/features/devices/devices.middleware'
import meetingFilesReducer, {
  STORE_NAME as MEETING_FILES_STORE_NAME,
} from 'app/features/meeting-files/meeting-files.reducer'
import meetingFilesMiddleware from 'app/features/meeting-files/meeting-files.middleware'
import fileUploadQueueReducer, {
  STORE_NAME as FILE_UPLOAD_QUEUE_STORE_NAME,
} from 'app/features/file-upload-queue/file-upload-queue.reducer'
import fileUploadQueueMiddleware from 'app/features/file-upload-queue/file-upload-queue.middleware'
import videoStreamerReducer, {
  STORE_NAME as VIDEO_STREAMER_STORE_NAME,
} from 'app/features/video-streamer/video-streamer.reducer'
import videoStreamerMiddleware from 'app/features/video-streamer/video-streamer.middleware'
import stickersReducer, {
  STORE_NAME as STICKERS_STORE_NAME,
} from 'app/features/sticker-picker/stickers.reducer'
import stickersMiddleware from 'app/features/sticker-picker/stickers.middleware'
import waitingRoomReducer, {
  STORE_NAME as WAITING_ROOM_STORE_NAME,
} from 'app/features/waiting-room/waiting-room.reducer'
import waitingRoomMiddleware from 'app/features/waiting-room/waiting-room.middleware'
import usedMediaReducer, {
  STORE_NAME as USED_MEDIA_STORE_NAME,
} from 'app/features/used-media/used-media.reducer'
import backgroundEffectReducer, {
  STORE_NAME as BACKGROUND_EFFECT_STORE_NAME,
} from 'app/features/background-effect/background-effect.reducer'
import noiseCancellationReducer, {
  STORE_NAME as NOISE_CANCELLATION_STORE_NAME,
} from 'app/features/noise-cancellation/noise-cancellation.reducer'
import desktopNotificationReducer, {
  STORE_NAME as DESKTOP_NOTIFICATION_STORE_NAME,
} from 'app/features/desktop-notifications/desktop-notification.reducer'

import type appReducer from 'app/features/app/app.reducer'
import type { STORE_NAME as APP_STORE_NAME } from 'app/features/app/app.reducer'
import type conferenceReducer from 'app/features/conference/conference.reducer'
import type { STORE_NAME as CONFERENCE_STORE_NAME } from 'app/features/conference/conference.reducer'
import type roomReducer from 'app/features/room/room.reducer'
import type { STORE_NAME as ROOM_STORE_NAME } from 'app/features/room/room.reducer'
import type footerReducer from 'app/features/footer/footer.reducer'
import type { STORE_NAME as FOOTER_STORE_NAME } from 'app/features/footer/footer.reducer'
import type loginReducer from 'app/features/login/login.reducer'
import type { STORE_NAME as LOGIN_STORE_NAME } from 'app/features/login/login.reducer'
import type privateChatsReducer from 'app/features/private-chats/private-chats.reducer'
import type { STORE_NAME as PRIVATE_CHATS_STORE_NAME } from 'app/features/private-chats/private-chats.reducer'
import type guestSetupReducer from 'app/features/guest-setup/guest-setup.reducer'
import type { STORE_NAME as GUEST_SETUP_STORE_NAME } from 'app/features/guest-setup/guest-setup.reducer'
import type sidePanelReducer from 'app/features/side-panel/side-panel.reducer'
import type { STORE_NAME as RIGHT_BAR_STORE_NAME } from 'app/features/side-panel/side-panel.reducer'
import type activeSpeakerViewReducer from 'app/features/active-speaker-view/active-speaker-view.reducer'
import type { STORE_NAME as ACTIVE_SPEAKER_VIEW_STORE_NAME } from 'app/features/active-speaker-view/active-speaker-view.reducer'
import type contentReducer from 'app/features/content/content.reducer'
import type { STORE_NAME as CONTENT_STORE_NAME } from 'app/features/content/content.reducer'
import type gridViewReducer from 'app/features/grid-view/grid-view.reducer'
import type { STORE_NAME as GRID_VIEW_STORE_NAME } from 'app/features/grid-view/grid-view.reducer'
import type passiveTalkingReducer from 'app/features/passive-talking-indicator/passive-talking.reducer'
import type { STORE_NAME as PASSIVE_TALKING_STORE_NAME } from 'app/features/passive-talking-indicator/passive-talking.reducer'

import notificationMiddleware from 'app/features/notifications/notification.middleware'
import backgroundEffectMiddleware from 'app/features/background-effect/background-effect.middleware'
import noiseCancellationMiddleware from 'app/features/noise-cancellation/noise-cancellation.middleware'
import analyticsMiddleware from 'app/features/analytics/analytics.middleware'
import conferenceSelectorMiddleware from 'app/features/conference-selector/conference-selector.middleware'
import eventBroadcasterMiddleware from 'app/features/event-broadcaster/event-broadcaster.middleware'
import desktopNotificationMiddleware from 'app/features/desktop-notifications/desktop-notification.middleware'
import authMiddleware from 'app/features/auth/auth.middleware'

// Due to the lack of typings a bit of a hack is needed to get the type of the
// reducers exported by the reducer files.
export type ReducerFunction<S = any, A = any> = (
  state: S | undefined,
  action: A
) => any

export type StateAndActionFromReducer<R extends ReducerFunction> =
  R extends ReducerFunction<infer S, infer A> ? [NonNullable<S>, A] : never

export type StateFromReducerFunction<R extends ReducerFunction> =
  StateAndActionFromReducer<R>[0]

export type ActionFromReducerFunction<R extends ReducerFunction> =
  StateAndActionFromReducer<R>[1]

export interface OptionalReducers {
  [APP_STORE_NAME]: typeof appReducer
  [CONFERENCE_STORE_NAME]: typeof conferenceReducer
  [ROOM_STORE_NAME]: typeof roomReducer
  [CONTENT_STORE_NAME]: typeof contentReducer
  [GRID_VIEW_STORE_NAME]: typeof gridViewReducer
  [FOOTER_STORE_NAME]: typeof footerReducer
  [LOGIN_STORE_NAME]: typeof loginReducer
  [PRIVATE_CHATS_STORE_NAME]: typeof privateChatsReducer
  [GUEST_SETUP_STORE_NAME]: typeof guestSetupReducer
  [RIGHT_BAR_STORE_NAME]: typeof sidePanelReducer
  [ACTIVE_SPEAKER_VIEW_STORE_NAME]: typeof activeSpeakerViewReducer
  [PASSIVE_TALKING_STORE_NAME]: typeof passiveTalkingReducer
}

export function createReducerRegistry(history: History) {
  const reducers = {
    form: formReducer,
    [ROUTER_REDUCER_MAP_KEY]: createRouterReducer(
      history
    ) as Reducer<ReduxRouterState>,
    [SOCKET_STORE_NAME]: socketReducer,
    [FLAGS_STORE_NAME]: flagsReducer,
    [AUTH_STORE_NAME]: authReducer,
    [FILE_UPLOAD_QUEUE_STORE_NAME]: fileUploadQueueReducer,
    [MEDIA_STORE_NAME]: mediaReducer,
    [MEETING_FILES_STORE_NAME]: meetingFilesReducer,
    [DEVICES_STORE_NAME]: devicesReducer,
    [SETTINGS_STORE_NAME]: settingsReducer,
    [VIDEO_STREAMER_STORE_NAME]: videoStreamerReducer,
    [WAITING_ROOM_STORE_NAME]: waitingRoomReducer,
    [USED_MEDIA_STORE_NAME]: usedMediaReducer,
    [STICKERS_STORE_NAME]: stickersReducer,
    [BACKGROUND_EFFECT_STORE_NAME]: backgroundEffectReducer,
    [NOISE_CANCELLATION_STORE_NAME]: noiseCancellationReducer,
    [DESKTOP_NOTIFICATION_STORE_NAME]: desktopNotificationReducer,

    [CHANNEL_AVATAR_STORE_NAME]: channelAvatarReducer,
    [CHANNEL_AUTH_STORE_NAME]: channelAuthReducer,
    [CHANNEL_CONFERENCE_STORE_NAME]: channelConferenceReducer,
    [CHANNEL_ROOM_STORE_NAME]: channelRoomReducer,
    [CHANNEL_SETTINGS_STORE_NAME]: channelSettingsReducer,
  }

  return ReducerRegistry.create<
    {
      [K in keyof typeof reducers]: StateFromReducerFunction<
        (typeof reducers)[K]
      >
    } & {
      [K in keyof OptionalReducers]?: StateFromReducerFunction<
        OptionalReducers[K]
      >
    }
  >(reducers)
}

export function createMiddlewareRegistry(history: History) {
  return MiddlewareRegistry.create([
    createRouterMiddleware(history),

    socketMiddleware,
    flagsMiddleware,
    authMiddleware,
    mediaMiddleware,
    devicesMiddleware,
    settingsMiddleware,
    meetingFilesMiddleware,
    analyticsMiddleware,
    conferenceSelectorMiddleware,
    fileUploadQueueMiddleware,
    videoStreamerMiddleware,
    waitingRoomMiddleware,
    stickersMiddleware,
    eventBroadcasterMiddleware,
    backgroundEffectMiddleware,
    noiseCancellationMiddleware,
    notificationMiddleware,
    desktopNotificationMiddleware,
  ])
}

export function createStore(
  reducerRegistry: ReturnType<typeof createReducerRegistry>,
  middlewareRegistry: ReturnType<typeof createMiddlewareRegistry>
) {
  const composeEnhancer = composeWithDevToolsDevelopmentOnly({
    trace: true,
    traceLimit: 25,
    shouldHotReload: false,
  })

  return createReduxStore(
    reducerRegistry.combineReducers(),
    composeEnhancer(middlewareRegistry.applyMiddleware(thunk))
  )
}

export type RootStore = ReturnType<typeof createStore>

export type RootState = ReturnType<RootStore['getState']>

// As the internal "virtual" symbol $CombinedState is not a real key of the state,
// we need to use this hack to exclude it from the union type.
export type RootFeatureKeys = Exclude<keyof RootState, typeof $CombinedState>

export type RootReducers<K extends RootFeatureKeys = RootFeatureKeys> = Reducer<
  RootState[K]
>

export type AppThunk<R = void> = ThunkAction<R, RootState, unknown, AnyAction>

export type AppThunkDispatch = ThunkDispatch<
  Record<string, unknown>,
  unknown,
  AnyAction
>

export const useDispatch: () => AppThunkDispatch = () => useReduxDispatch()

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector
