import type { RootState } from 'app/services/state/redux-store'

/**
 * FIXME: This function will only work if the owner looks up a member Id. A guest (non owner), will always recieve false. The owner_id is only updated when the owner joins, only their session will have the owner_id set
 * TODO: https://jira.voiceworks.com/browse/SUM-4613 Implement isOwner
 * @param member_id The given owner
 * @param roomState The current room state
 */
function isOwner(
  member_id: string,
  roomState: Partial<RootState['channel/room']>
): boolean {
  return !!roomState?.meeting_room?.meta?.owners?.find(
    (ownerId) => ownerId === member_id
  )
}

export default isOwner
