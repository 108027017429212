import type { RefCallback } from 'react'
import { useCallback, useEffect, useRef } from 'react'

import ColigoTrack from 'lib/rtc/coligo-track'

export type SrcObject = ColigoTrack | MediaStream | MediaSource | Blob | File

function detachNodeFromSrcObject(
  node: HTMLMediaElement,
  srcObject: SrcObject | null = null
): void {
  if (srcObject instanceof ColigoTrack) {
    srcObject.detach(node)
  } else {
    node.srcObject = null
  }
}

function attachNodeToSrcObject(
  node: HTMLMediaElement,
  srcObject: SrcObject
): void {
  if (srcObject instanceof ColigoTrack) {
    srcObject.attach(node)
  } else {
    node.srcObject = srcObject
  }
}

export default function useSrcObject<
  R extends HTMLMediaElement = HTMLMediaElement,
>(srcObject: SrcObject | null = null): RefCallback<R> {
  const ref = useRef<R>(null)

  const updateRefObject = useCallback(
    (node: R) => {
      // When there was a node before, detach it
      if (ref.current) {
        detachNodeFromSrcObject(ref.current, srcObject)
      }

      // When there is a new node and we have a srcObject, attach it
      if (node && srcObject) {
        attachNodeToSrcObject(node, srcObject)
      }

      // Update the ref
      ref.current = node
    },
    [srcObject, ref]
  )

  useEffect(() => {
    // Guard clause: when the passed srcObject is falsey (null), we don't need to do anything
    if (!srcObject) return

    // When the srcObject changes, attach it to the node
    if (ref.current) {
      attachNodeToSrcObject(ref.current, srcObject)
    }

    return () => {
      // When the srcObject changes, detach the old srcObject from the node
      if (ref.current) {
        detachNodeFromSrcObject(ref.current, srcObject)
      }
    }
  }, [srcObject])

  return updateRefObject
}
