import type { SVGProps } from 'react'
const SvgEmoji3 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle
      cx={79.46}
      cy={96.28}
      r={48.66}
      fill='rgb(var(--color-brand-100))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.46 146.94c-27.93 0-50.66-22.72-50.66-50.66s22.72-50.66 50.66-50.66 50.66 22.72 50.66 50.66-22.72 50.66-50.66 50.66zm0-97.31c-25.73 0-46.66 20.93-46.66 46.66s20.93 46.66 46.66 46.66 46.66-20.93 46.66-46.66-20.93-46.66-46.66-46.66z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M79.4 111.89c-5.15 0-10.07-.98-14.58-2.76-1.34-.53-2.81.43-2.82 1.87v.13c0 8.3 7.79 15.04 17.41 15.04s17.41-6.73 17.41-15.04V111c-.01-1.43-1.49-2.39-2.82-1.87a39.713 39.713 0 0 1-14.58 2.76z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.4 127.16c-10.15 0-18.41-7.19-18.41-16.04v-.15c.01-.98.51-1.91 1.34-2.46.84-.57 1.91-.69 2.85-.31 4.53 1.78 9.31 2.69 14.22 2.69s9.69-.9 14.22-2.69c.94-.37 2.01-.25 2.85.31a3.02 3.02 0 0 1 1.34 2.48v.14c0 8.84-8.26 16.04-18.41 16.04zm-15.35-17.18c-.21 0-.42.06-.6.19-.29.19-.45.5-.46.84v.12c0 7.74 7.36 14.04 16.4 14.04s16.41-6.3 16.41-14.04v-.1c0-.35-.17-.66-.46-.85-.3-.2-.67-.24-1-.11-4.76 1.88-9.79 2.83-14.95 2.83s-10.18-.95-14.95-2.83c-.13-.05-.26-.08-.4-.08z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M79.4 126.16c3.72 0 7.17-1.01 10-2.73-1.81-3.72-5.61-6.29-10-6.29s-8.2 2.57-10 6.29c2.83 1.72 6.28 2.73 10 2.73z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.4 127.16c-3.79 0-7.42-1-10.52-2.88a.996.996 0 0 1-.38-1.29c2.05-4.23 6.23-6.85 10.9-6.85s8.85 2.63 10.9 6.85c.23.46.06 1.02-.38 1.29-3.1 1.88-6.74 2.88-10.52 2.88zm-8.66-4.12c2.6 1.39 5.57 2.12 8.66 2.12s6.06-.73 8.66-2.12c-1.83-3.05-5.07-4.91-8.66-4.91s-6.83 1.86-8.66 4.91z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M130.64 57.71c0 7.76-6.29 14.05-14.05 14.05s-14.05-6.29-14.05-14.05 9.08-27.6 14.05-27.6 14.05 19.85 14.05 27.6z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M116.59 73.76c-8.85 0-16.04-7.2-16.04-16.04 0-7.93 9.1-29.6 16.04-29.6s16.05 21.68 16.05 29.6c0 8.85-7.2 16.04-16.05 16.04zm0-41.65c-3.16.24-12.04 17.83-12.04 25.6 0 6.64 5.4 12.04 12.04 12.04s12.05-5.4 12.05-12.04c0-7.78-8.88-25.37-12.05-25.6z'
    />
    <circle
      cx={120.03}
      cy={58.64}
      r={4.66}
      fill='rgb(var(--color-neutral-50))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M90.85 94.21a1.82 1.82 0 0 1-1.3-3.1c3.32-3.38 8.18-5.32 13.32-5.32s9.99 1.94 13.32 5.32c.71.72.7 1.87-.02 2.58s-1.87.7-2.58-.02c-2.64-2.69-6.55-4.23-10.72-4.23s-8.07 1.54-10.72 4.23c-.36.36-.83.55-1.3.55zm-49.27 0a1.82 1.82 0 0 1-1.3-3.1c3.32-3.38 8.18-5.32 13.32-5.32s9.99 1.94 13.32 5.32c.71.72.7 1.87-.02 2.58s-1.87.7-2.58-.02c-2.64-2.69-6.55-4.23-10.72-4.23s-8.07 1.54-10.72 4.23c-.36.36-.83.55-1.3.55z'
    />
  </svg>
)
export default SvgEmoji3
