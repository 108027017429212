import jwt_decode from 'jwt-decode'

import { FEATURE_NAMES } from 'constants/features-map'

import { getMe, isHost, isOwner } from 'app/state/utils'
import * as LicenseUtils from 'lib/licenses-utils'
import browserUtils from 'lib/browser-utils'
import env from 'app/config/env'

import {
  STORE_NAME as CHANNEL_ROOM_STORE_NAME,
  selectors as channelRoomSelectors,
} from 'app/state/api/channels/channel-room.reducer'
import {
  STORE_NAME as CHANNEL_SETTINGS_STORE_NAME,
  selectors as channelSettingsSelectors,
} from 'app/state/api/channels/channel-settings.reducer'

import {
  STORE_NAME as FLAGS_STORE_NAME,
  selectors as flagsSelectors,
} from 'app/features/flags/flags.reducer'

function getIsFeatureIncluded(name, state) {
  const { [CHANNEL_ROOM_STORE_NAME]: channelRoom, [FLAGS_STORE_NAME]: flags } =
    state

  const id = LicenseUtils.getFeatureId(name)
  const license = LicenseUtils.getCurrentLicense(channelRoom, flags)
  const includedFeatures = flagsSelectors.getLicenseFeatures(license.id, flags)

  return !!includedFeatures[id]
}

const GENERAL_PREDICATES = {
  hostOnly: (state) => {
    const me = getMe(state[CHANNEL_ROOM_STORE_NAME])
    return !!me && isHost(me)
  },
  ownerOnly: (state) => {
    const channelRoom = state[CHANNEL_ROOM_STORE_NAME]
    const memberId = channelRoomSelectors.getMemberId(channelRoom)
    return isOwner(memberId, channelRoom)
  },
  mobile: browserUtils.isMobile(),
  tablet: browserUtils.isTablet(),
  notMobile: !browserUtils.isMobile(),
  notTablet: !browserUtils.isTablet(),
  firefox: browserUtils.isFirefox(),
  notFirefox: !browserUtils.isFirefox(),
  notSafari: !browserUtils.isSafari(),
  notMobileSafari: !browserUtils.isMobileSafari(),
  electron: browserUtils.isElectron(),
  notElectron: !browserUtils.isElectron(),
}

const FEATURE_PREDICATES = {
  [FEATURE_NAMES.SESSION_RECORDING]: [
    GENERAL_PREDICATES.hostOnly,
    GENERAL_PREDICATES.ownerOnly,
  ],
  [FEATURE_NAMES.EDIT_CONFERENCE_NAME]: GENERAL_PREDICATES.ownerOnly,
  [FEATURE_NAMES.EDIT_CONFERENCE_CODE]: GENERAL_PREDICATES.ownerOnly,
  [FEATURE_NAMES.ICS_INVITES]: !!env('ics_invite_enabled'),
  [FEATURE_NAMES.ROOM_INVITING]: [
    GENERAL_PREDICATES.hostOnly,
    !env('room_inviting_disabled'),
  ],
  [FEATURE_NAMES.END_MEETING_TO_SIGNUP]:
    env('meeting_ended_referral_form.enabled', false) &&
    GENERAL_PREDICATES.notElectron,
  [FEATURE_NAMES.ACTIVE_SPEAKER]: env('active_speaker.enabled', true),
  [FEATURE_NAMES.SMS_INVITES]: [
    GENERAL_PREDICATES.hostOnly,
    () => env('sms_invites.enabled', false),
    (state) => {
      const { [CHANNEL_SETTINGS_STORE_NAME]: channelSettings } = state

      const tokenKey = env('sms_invites.backend.tokenKey', 'ipnordic.sms.token')
      const tokenFromConfig = env('sms_invites.backend.token')
      const usernameKey = env(
        'sms_invites.backend.usernameKey',
        'ipnordic.sms.username'
      )
      const usernameFromConfig = env('sms_invites.backend.username')

      const token =
        tokenFromConfig ||
        channelSettingsSelectors.getSettings(channelSettings)[tokenKey]

      const username =
        usernameFromConfig ||
        channelSettingsSelectors.getSettings(channelSettings)[usernameKey]

      if (!token || !username) return false

      const decoded = jwt_decode(token)

      return decoded.username === username
    },
  ],
  [FEATURE_NAMES.MIRO]: env('miro.enabled', false),
  [FEATURE_NAMES.DIAL_IN]: env('dialin.enabled', true),
  [FEATURE_NAMES.BACKGROUND_EFFECTS]: env('background_effects.enabled', true),
  [FEATURE_NAMES.NOISE_CANCELLATION]: env('noise_cancellation.enabled', true),
  [FEATURE_NAMES.DESKTOP_NOTIFICATIONS]: env(
    'desktop_notifications.enabled',
    true
  ),
  [FEATURE_NAMES.SILENCED_MIC_DETECTION]: env(
    'mic_silence_detection.enabled',
    true
  ),
  [FEATURE_NAMES.GRANT_HOST]: env('grant_host.enabled', false),
  [FEATURE_NAMES.IDEANOTE]:
    env('ideanote.enabled', false) && !!env('ideanote.configId'),
}

const LICENSE_PREDICATES = {
  [FEATURE_NAMES.SESSION_RECORDING]: (state) =>
    getIsFeatureIncluded(FEATURE_NAMES.SESSION_RECORDING, state),
  [FEATURE_NAMES.VIDEO_BROADCAST]: (state) =>
    getIsFeatureIncluded(FEATURE_NAMES.VIDEO_BROADCAST, state),
  [FEATURE_NAMES.EDIT_CONFERENCE_NAME]: (state) =>
    getIsFeatureIncluded(FEATURE_NAMES.EDIT_CONFERENCE_NAME, state),
  [FEATURE_NAMES.DIAL_IN]: (state) =>
    getIsFeatureIncluded(FEATURE_NAMES.DIAL_IN, state),
}

const BROWSER_PREDICATES = {
  [FEATURE_NAMES.SESSION_RECORDING]: [
    !!window.MediaRecorder,
    !!HTMLCanvasElement.prototype.captureStream,
    GENERAL_PREDICATES.notMobile,
    GENERAL_PREDICATES.notTablet,
  ],
  [FEATURE_NAMES.VIDEO_BROADCAST]: [
    GENERAL_PREDICATES.notMobile,
    GENERAL_PREDICATES.notTablet,
    GENERAL_PREDICATES.notFirefox,
    !!HTMLMediaElement.prototype.captureStream,
  ],
  [FEATURE_NAMES.SCREENSHARE]: [
    GENERAL_PREDICATES.notMobile,
    GENERAL_PREDICATES.notTablet,
    !!navigator.mediaDevices && !!navigator.mediaDevices.getDisplayMedia,
  ],
  [FEATURE_NAMES.DEVICES_SELECT_FIELDS]: [
    GENERAL_PREDICATES.notMobile,
    GENERAL_PREDICATES.notTablet,
  ],
  [FEATURE_NAMES.LANGUAGE_DROPDOWN_FLAGS]: !(
    browserUtils.getOSName() === 'Windows' && GENERAL_PREDICATES.firefox
  ),
  [FEATURE_NAMES.AUDIO_OUTPUT_INDICATOR]: [
    !!(
      window.AudioContext &&
      window.AudioContext.prototype &&
      window.AudioContext.prototype.createScriptProcessor
    ),
  ],
  [FEATURE_NAMES.WEBSHARE]:
    !!window.navigator.share && browserUtils.isMobileTouchDevice(),
  [FEATURE_NAMES.MIRO]: !browserUtils.isMobileTouchDevice(),
  [FEATURE_NAMES.BACKGROUND_EFFECTS]: [
    GENERAL_PREDICATES.notMobile,
    GENERAL_PREDICATES.notTablet,
    !!window.OffscreenCanvas,
    !!window.Worker,
    !!HTMLCanvasElement.prototype.captureStream,
    !!window.CanvasRenderingContext2D &&
      'filter' in window.CanvasRenderingContext2D.prototype,
    browserUtils.isWebGLSupported(),
  ],
  [FEATURE_NAMES.NOISE_CANCELLATION]: [
    GENERAL_PREDICATES.notMobileSafari,
    GENERAL_PREDICATES.notSafari, // FIXME: Has been disabled temporarily, see https://jira.voiceworks.com/browse/SUM-4345 and https://jira.voiceworks.com/browse/SUM-4352 (14-10-2021)
    !!(
      window.AudioContext &&
      window.AudioContext.prototype &&
      (window.AudioWorkletNode || window.ScriptProcessorNode) &&
      window.WebAssembly
    ),
  ],
  [FEATURE_NAMES.DESKTOP_NOTIFICATIONS]: [
    GENERAL_PREDICATES.notMobile,
    GENERAL_PREDICATES.notTablet,
    !!window.Notification,
  ],
  [FEATURE_NAMES.AUDIO_GROUP_SELECTION]: !!HTMLMediaElement.prototype.setSinkId, // Detect if audio output selection is supported
  [FEATURE_NAMES.SILENCED_MIC_DETECTION]: [
    GENERAL_PREDICATES.notMobile,
    GENERAL_PREDICATES.notTablet,
    !browserUtils.isWindows(),
  ],
}

export { FEATURE_PREDICATES, LICENSE_PREDICATES, BROWSER_PREDICATES }
