import { LOCAL_STORAGE_TYPES } from 'constants/constants'
import {
  BROWSER_PREDICATES,
  FEATURE_PREDICATES,
} from 'constants/feature-predicates'
import { FEATURE_NAMES } from 'constants/features-map'

import AbstractStorage from './storage'

/**
 * maps from app value to storage value
 * since storage does not accept null this is needed
 * @param val
 */
const mapAppToStorage = (val) => (val === null ? '' : val)
/**
 * unmaps from storage value to app value
 * since storage does not accept null values but null would be a valid app value choice
 * @param ret
 */
const mapStorageToApp = (ret) => {
  if (ret === '') return null

  return ret === null ? '' : ret
}

export class LocalStore extends AbstractStorage {
  getDebugMode = () => this.getItem(LOCAL_STORAGE_TYPES.DEBUG_MODE) === 'true'

  setDebugMode = (val) => {
    this.setItem(LOCAL_STORAGE_TYPES.DEBUG_MODE, val)
  }

  /**
   * @public
   * @returns
   */
  getCamDeviceLabel = () =>
    mapStorageToApp(this.getItem(LOCAL_STORAGE_TYPES.CAM_DEVICE_LABEL))

  /**
   * @public
   * @param val
   * @returns
   */
  setCamDeviceLabel = (val) => {
    if (val === '') {
      this.removeItem(LOCAL_STORAGE_TYPES.CAM_DEVICE_LABEL)
    } else {
      this.setItem(LOCAL_STORAGE_TYPES.CAM_DEVICE_LABEL, mapAppToStorage(val))
    }
  }

  /**
   * @public
   * @returns
   */
  getMicDeviceLabel = () =>
    mapStorageToApp(this.getItem(LOCAL_STORAGE_TYPES.MIC_DEVICE_LABEL))

  /**
   * @public
   * @param val
   * @returns
   */
  setMicDeviceLabel = (val) => {
    if (val === '') {
      this.removeItem(LOCAL_STORAGE_TYPES.MIC_DEVICE_LABEL)
    } else {
      this.setItem(LOCAL_STORAGE_TYPES.MIC_DEVICE_LABEL, mapAppToStorage(val))
    }
  }

  /**
   * @public
   * @returns
   */
  getOutDeviceLabel = () =>
    mapStorageToApp(this.getItem(LOCAL_STORAGE_TYPES.OUT_DEVICE_LABEL))

  /**
   * @public
   * @param val
   * @returns
   */
  setOutDeviceLabel = (val) => {
    if (val === '') {
      this.removeItem(LOCAL_STORAGE_TYPES.OUT_DEVICE_LABEL)
    } else {
      this.setItem(LOCAL_STORAGE_TYPES.OUT_DEVICE_LABEL, mapAppToStorage(val))
    }
  }

  /**
   * @public
   * @returns
   */
  getAvatar = () => mapStorageToApp(this.getItem(LOCAL_STORAGE_TYPES.AVATAR))

  /**
   * @public
   * @param val
   * @returns
   */
  setAvatar = (val) => {
    this.setItem(LOCAL_STORAGE_TYPES.AVATAR, val)
  }

  /**
   * @public
   * @returns
   */
  getAuthToken = () => {
    const authTokenString = this.getItem(LOCAL_STORAGE_TYPES.AUTH_TOKEN)
    if (!authTokenString) return null
    try {
      return JSON.parse(authTokenString)
    } catch (err) {
      this.removeAuthToken()
      return null
    }
  }

  /**
   * @public
   * @param [token=null]
   * @param token.token
   * @param token.expires
   * @param token.expireDate
   * @returns
   */
  setAuthToken = (token = null) => {
    if (token === null) {
      this.removeAuthToken()
    } else {
      this.setItem(LOCAL_STORAGE_TYPES.AUTH_TOKEN, JSON.stringify(token))
    }
  }

  /**
   * @public
   * @returns
   */
  removeAuthToken = () => {
    this.removeItem(LOCAL_STORAGE_TYPES.AUTH_TOKEN)
  }

  /**
   * @public
   * @returns
   */
  getEmail = () => this.getItem(LOCAL_STORAGE_TYPES.EMAIL)

  /**
   * @public
   * @param val
   * @returns
   */
  setEmail = (val) => {
    this.setItem(LOCAL_STORAGE_TYPES.EMAIL, val)
  }

  /**
   * @returns
   */
  removeEmail = () => {
    this.removeItem(LOCAL_STORAGE_TYPES.EMAIL)
  }

  getReadFeatures() {
    return JSON.parse(this.getItem(LOCAL_STORAGE_TYPES.FEATURE_READ) || '[]')
  }

  setReadFeatures(readFeatures) {
    this.setItem(LOCAL_STORAGE_TYPES.FEATURE_READ, JSON.stringify(readFeatures))
  }

  addReadFeature(name) {
    const readFeatures = this.getReadFeatures()
    if (readFeatures.indexOf(name) === -1) {
      this.setReadFeatures([...readFeatures, name])
    }
  }

  getReadFeature(name) {
    const readFeatures = this.getReadFeatures()
    return readFeatures.indexOf(name) >= 0
  }

  getFacingMode = () => this.getItem(LOCAL_STORAGE_TYPES.FACING_MODE)

  setFacingMode = (val) => {
    this.setItem(LOCAL_STORAGE_TYPES.FACING_MODE, val)
  }

  removeFacingMode = () => {
    this.removeItem(LOCAL_STORAGE_TYPES.FACING_MODE)
  }

  getAvatarImage = () => this.getItem(LOCAL_STORAGE_TYPES.AVATAR)

  setAvatarImage = (val) => {
    this.setItem(LOCAL_STORAGE_TYPES.AVATAR, val)
  }

  removeAvatarImage = () => {
    this.removeItem(LOCAL_STORAGE_TYPES.AVATAR)
  }

  /**
   * @public
   * @returns {string|null}
   */
  getGridSize = () => this.getItem(LOCAL_STORAGE_TYPES.GRID_SIZE)

  /**
   * @public
   * @param val
   * @returns
   */
  setGridSize = (val) => {
    this.setItem(LOCAL_STORAGE_TYPES.GRID_SIZE, val)
  }

  /**
   * @public
   * @returns
   */
  removeGrideSize = () => {
    this.removeItem(LOCAL_STORAGE_TYPES.GRID_SIZE)
  }

  /**
   * @public
   * @param val
   * @returns
   */
  setUserName = (val) => {
    this.setItem(LOCAL_STORAGE_TYPES.USER_NAME, val)
  }

  /**
   * @public
   * @returns
   */
  getUserName = () => this.getItem(LOCAL_STORAGE_TYPES.USER_NAME)

  /**
   * @public
   * @returns
   */
  removeUserName = () => {
    this.removeItem(LOCAL_STORAGE_TYPES.USER_NAME)
  }

  /**
   * @public
   * @param val
   * @returns
   */
  setRecentlyUsedStickers = (val) => {
    this.setItem(
      LOCAL_STORAGE_TYPES.RECENTLY_USED_STICKERS,
      JSON.stringify(val)
    )
  }

  /**
   * @public
   * @returns
   */
  getRecentlyUsedStickers = (): string[] => {
    const recentlyUsed = this.getItem(
      LOCAL_STORAGE_TYPES.RECENTLY_USED_STICKERS
    ) as string

    if (recentlyUsed) {
      try {
        return JSON.parse(recentlyUsed) as string[]
      } catch {
        return []
      }
    }
    return []
  }

  /**
   * @returns {boolean|null}
   */
  getHotkeysEnabled = () => {
    const hotkeysEnabled = this.getItem(LOCAL_STORAGE_TYPES.HOTKEYS_ENABLED)
    if (hotkeysEnabled) return JSON.parse(hotkeysEnabled)
    return true
  }

  /**
   * @param val
   * @returns
   */
  setHotkeysEnabled = (val) => {
    this.setItem(LOCAL_STORAGE_TYPES.HOTKEYS_ENABLED, val)
  }

  /**
   * @public
   * @returns {string|null}
   */
  getSMSInviteSettings = () => {
    try {
      return JSON.parse(this.getItem(LOCAL_STORAGE_TYPES.SMS_INVITE_SETTINGS))
    } catch (e) {
      this.setSMSInviteSettings({})
    }
  }

  /**
   * @public
   * @param val
   * @returns
   */
  setSMSInviteSettings = (val) => {
    this.setItem(LOCAL_STORAGE_TYPES.SMS_INVITE_SETTINGS, JSON.stringify(val))
  }

  /**
   * @public
   * @param val
   * @returns
   */
  setBackgroundBlurConfig = (val) => {
    this.setItem(LOCAL_STORAGE_TYPES.BACKGROUND_BLUR_CONFIG, val)
  }

  /**
   * @public
   * @param val
   * @returns
   */
  setBackgroundReplaceConfig = (val) => {
    this.setItem(LOCAL_STORAGE_TYPES.BACKGROUND_REPLACE_CONFIG, val)
  }

  /**
   * @public
   * @param val
   * @returns
   */
  setActiveBackgroundEffect = (val) => {
    this.setItem(LOCAL_STORAGE_TYPES.ACTIVE_BACKGROUND_EFFECT, val)
  }

  /**
   * @public
   * @returns {Object|null}
   */
  getBackgroundBlurConfig = () => {
    const backgroundEffect = this.getItem(
      LOCAL_STORAGE_TYPES.BACKGROUND_BLUR_CONFIG
    )
    if (backgroundEffect) return JSON.parse(backgroundEffect)
    return null
  }

  /**
   * @public
   * @returns {Object|null}
   */
  getBackgroundReplaceConfig = () => {
    const backgroundEffect = this.getItem(
      LOCAL_STORAGE_TYPES.BACKGROUND_REPLACE_CONFIG
    )
    if (backgroundEffect) return JSON.parse(backgroundEffect)
    return null
  }

  /**
   * @public
   * @returns {string|null}
   */
  getActiveBackgroundEffect = () => {
    const activeEffect = this.getItem(
      LOCAL_STORAGE_TYPES.ACTIVE_BACKGROUND_EFFECT
    )
    if (activeEffect) return activeEffect
    return null
  }

  /**
   * @public
   * @returns
   */
  removeBackgroundBlurConfig = () => {
    this.removeItem(LOCAL_STORAGE_TYPES.BACKGROUND_BLUR_CONFIG)
  }

  /**
   * @public
   * @returns
   */
  removeBackgroundReplaceConfig = () => {
    this.removeItem(LOCAL_STORAGE_TYPES.BACKGROUND_REPLACE_CONFIG)
  }

  /**
   * @public
   * @returns
   */
  removeActiveBackgroundEffect = () => {
    this.removeItem(LOCAL_STORAGE_TYPES.ACTIVE_BACKGROUND_EFFECT)
  }

  /**
   * @public
   * @param val
   * @returns
   */
  setNoiseCancelled = (val) => {
    this.setItem(LOCAL_STORAGE_TYPES.NOISE_CANCELLED, val)
  }

  /**
   * @public
   * @param val
   * @returns
   */
  getNoiseCancelled = () => {
    let NoiseCancelled = this.getItem(LOCAL_STORAGE_TYPES.NOISE_CANCELLED)

    if (typeof NoiseCancelled === 'undefined' || NoiseCancelled === null) {
      NoiseCancelled = true
    }

    return (
      FEATURE_PREDICATES[FEATURE_NAMES.NOISE_CANCELLATION] &&
      !BROWSER_PREDICATES[FEATURE_NAMES.NOISE_CANCELLATION].includes(false) &&
      JSON.parse(NoiseCancelled)
    )
  }

  /**
   * @public
   * @returns
   */
  removeNoiseCancelled = () => {
    this.removeItem(LOCAL_STORAGE_TYPES.NOISE_CANCELLED)
  }

  /**
   * Get whether desktop notifications are enabled
   * @returns Whether desktop notifications are enabled
   */
  getDesktopNotificationsEnabled = () => {
    const notificationsEnabled = this.getItem(
      LOCAL_STORAGE_TYPES.DESKTOP_NOTIFICATIONS_ENABLED
    )
    return JSON.parse(notificationsEnabled)
  }

  /**
   * Set whether desktop notifications are enabled
   * @param enabled Whether desktop notifications should be enabled
   */
  setDesktopNotificationsEnabled = (enabled) => {
    this.setItem(LOCAL_STORAGE_TYPES.DESKTOP_NOTIFICATIONS_ENABLED, enabled)
  }

  /**
   * Get whether user wants to be reminded of desktop notifications feature
   * @returns Whether user wants to be reminded of desktop notifications feature
   */
  getDesktopNotificationsDontRemindAgain = () => {
    const dontRemindAgain = this.getItem(
      LOCAL_STORAGE_TYPES.DESKTOP_NOTIFICATIONS_DONT_REMIND_AGAIN
    )
    return !!JSON.parse(dontRemindAgain)
  }

  /**
   * Set whether user wants to be reminded of desktop notifications feature
   * @param remindAgain Whether user wants to be reminded of desktop notifications feature
   */
  setDesktopNotificationsDontRemindAgain = (remindAgain) => {
    this.setItem(
      LOCAL_STORAGE_TYPES.DESKTOP_NOTIFICATIONS_DONT_REMIND_AGAIN,
      remindAgain
    )
  }
}

export default new LocalStore(window.localStorage || window.electronStore)
