import { Icon } from 'ui/revision'

const Warning = (props) => (
  <Icon {...props}>
    <svg viewBox='0 0 24 24'>
      <g fill='none'>
        <path d='M0 0h24v24H0z' />
        <path
          d='M1 21h22L12 2 1 21z
          m12-3h-2v-2h2v2z
          m0-4h-2v-4h2v4z'
          fill='#FFF'
        />
      </g>
    </svg>
  </Icon>
)

export default Warning
