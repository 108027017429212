import localStore from 'app/services/state/local-store'
import { AnyAction } from 'redux'

export const STORE_NAME = 'feature/guest-setup'

const INIT = `sm-web/${STORE_NAME}/INIT`
const PREVIOUS_STEP = `sm-web/${STORE_NAME}/PREVIOUS_STEP`
const NEXT_STEP = `sm-web/${STORE_NAME}/NEXT_STEP`
const GOTO_STEP = `sm-web/${STORE_NAME}/GOTO_STEP`
const SET_META = `sm-web/${STORE_NAME}/SET_META`
const UPDATE_META = `sm-web/${STORE_NAME}/UPDATE_META`
const COMPLETED = `sm-web/${STORE_NAME}/COMPLETED`
const EXPIRED = `sm-web/${STORE_NAME}/EXPIRED`

export const ACTION_TYPES = {
  INIT,
  PREVIOUS_STEP,
  NEXT_STEP,
  GOTO_STEP,
  SET_META,
  UPDATE_META,
  COMPLETED,
  EXPIRED,
}

const previousStep = (): AnyAction => {
  return { type: PREVIOUS_STEP }
}

const nextStep = (): AnyAction => {
  return { type: NEXT_STEP }
}

const gotoStep = (payload): AnyAction => {
  return { type: GOTO_STEP, payload }
}

const setMeta = (payload): AnyAction => {
  return { type: SET_META, payload }
}

const updateMeta = (payload): AnyAction => {
  return { type: UPDATE_META, payload }
}

const complete = (payload): AnyAction => {
  return { type: COMPLETED, payload }
}

const expired = (): AnyAction => {
  return { type: EXPIRED }
}

export const actions = {
  previousStep,
  nextStep,
  gotoStep,
  setMeta,
  updateMeta,
  complete,
  expired,
}

export const INITIAL_STATE = {
  completed: false,
  expired: false,
  step: 0,
  meta: {
    isAudioMuted: false,
    isVideoMuted: false,
    userName: localStore.getUserName(),
  },
}

type SetupState = typeof INITIAL_STATE

const ACTION_HANDLERS = {
  [PREVIOUS_STEP]: (state) => ({
    ...state,
    step: Math.max(state.step - 1, 0),
  }),
  [NEXT_STEP]: (state) => ({
    ...state,
    step: state.step + 1,
  }),
  [GOTO_STEP]: (state, action) => ({
    ...state,
    step: Math.max(action.payload, 0),
  }),
  [SET_META]: (state, action) => ({
    ...state,
    meta: action.payload,
  }),
  [UPDATE_META]: (state, action) => ({
    ...state,
    meta: {
      ...state.meta,
      ...action.payload,
    },
  }),
  [COMPLETED]: (state, action) => ({
    ...state,
    completed: action.payload,
  }),
  [EXPIRED]: (state) => ({
    ...state,
    expired: true,
  }),
  'sm-web/RESET': () => INITIAL_STATE,
}

export default (state = INITIAL_STATE, action = {}) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export const selectors = {
  getStep(state: SetupState) {
    return state.step
  },
  getMeta(state: SetupState) {
    return state.meta
  },
  getCompleted(state: SetupState) {
    return state.completed
  },
  getExpired(state: SetupState) {
    return state.expired
  },
}
