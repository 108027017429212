import _ from 'lodash'

import validateMember from './validate-member'

/**
 * Get either the set username from the given member.
 * @param member
 * @return
 * @throws Error if the given member is not valid.
 */
function getMemberUserName(member): string {
  validateMember(member)

  return _.get(member, 'meta.userName') || _.get(member, 'meta.name')
}

export default getMemberUserName
