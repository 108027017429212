import type { SVGProps } from 'react'
const SvgAgileCard5 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <rect
      width={94.33}
      height={126.14}
      x={33.78}
      y={17.68}
      fill='rgb(var(--color-neutral-50))'
      rx={8.63}
      ry={8.63}
    />
    <path
      fill='rgb(var(--color-brand-500))'
      d='M119.48 145.82H42.41c-5.86 0-10.63-4.77-10.63-10.63V26.31c0-5.86 4.77-10.63 10.63-10.63h77.07c5.86 0 10.63 4.77 10.63 10.63V135.2c0 5.86-4.77 10.63-10.63 10.63zM42.41 19.68c-3.65 0-6.63 2.97-6.63 6.63V135.2c0 3.65 2.97 6.63 6.63 6.63h77.07c3.65 0 6.63-2.97 6.63-6.63V26.31c0-3.65-2.97-6.63-6.63-6.63H42.41z'
    />
    <rect
      width={79.02}
      height={110.67}
      x={41.43}
      y={25.42}
      fill='rgb(var(--color-brand-100))'
      rx={2.78}
      ry={2.78}
    />
    <circle cx={50.06} cy={34.43} r={5.57} fill='rgb(var(--color-brand-200))' />
    <circle
      cx={111.83}
      cy={127.08}
      r={5.57}
      fill='rgb(var(--color-brand-200))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M80.04 103.67c-3.76 0-6.7-1.12-8.73-3.31-2-2.17-3.02-5.26-3.02-9.2v-3.52c0-.69.56-1.25 1.25-1.25h6.66c.69 0 1.25.56 1.25 1.25v4.03c0 2.73 1.15 3.1 2.4 3.1s2.4-.38 2.4-3.1V81.62c0-2.78-1.15-3.17-2.4-3.17s-2.4.38-2.4 3.17V82c0 .69-.56 1.25-1.25 1.25h-6.66c-.34 0-.67-.14-.91-.39s-.36-.58-.34-.92l1.28-25.02c.03-.67.58-1.19 1.25-1.19h18.56c.69 0 1.25.56 1.25 1.25v6.4c0 .69-.56 1.25-1.25 1.25h-11.1l-.34 6.26c1.32-.89 2.92-1.33 4.79-1.33 2.96 0 5.25 1.05 6.82 3.12 1.48 1.97 2.24 4.78 2.24 8.37v10.11c0 3.94-1.02 7.03-3.02 9.2-2.03 2.2-4.96 3.31-8.73 3.31z'
    />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M89.39 56.98v6.4H77.1l-.58 10.69h.13c1.32-2.18 3.35-3.26 6.08-3.26 2.56 0 4.5.88 5.82 2.62 1.32 1.75 1.98 4.29 1.98 7.62v10.11c0 3.63-.9 6.41-2.69 8.35-1.79 1.94-4.4 2.91-7.81 2.91s-6.02-.97-7.81-2.91c-1.79-1.94-2.69-4.72-2.69-8.35v-3.52h6.66v4.03c0 2.9 1.22 4.35 3.65 4.35s3.65-1.45 3.65-4.35V81.62c0-2.94-1.22-4.42-3.65-4.42s-3.65 1.47-3.65 4.42V82h-6.66l1.28-25.02h18.56m0-2.5H70.81c-1.33 0-2.43 1.04-2.5 2.37l-1.28 25.02a2.496 2.496 0 0 0 2.49 2.63h6.66a2.5 2.5 0 0 0 2.5-2.5v-.38c0-1.92.46-1.92 1.15-1.92s1.15 0 1.15 1.92v10.05c0 1.85-.46 1.85-1.15 1.85s-1.15 0-1.15-1.85v-4.03a2.5 2.5 0 0 0-2.5-2.5h-6.66a2.5 2.5 0 0 0-2.5 2.5v3.52c0 4.26 1.13 7.64 3.35 10.05 2.27 2.47 5.52 3.72 9.64 3.72s7.37-1.25 9.65-3.72c2.22-2.41 3.35-5.79 3.35-10.05V81.05c0-3.87-.84-6.93-2.49-9.12-1.79-2.37-4.49-3.62-7.82-3.62-1.23 0-2.38.18-3.42.54l.16-2.96h9.92a2.5 2.5 0 0 0 2.5-2.5v-6.4a2.5 2.5 0 0 0-2.5-2.5z'
    />
  </svg>
)
export default SvgAgileCard5
