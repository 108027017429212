import type { SVGProps } from 'react'
const SvgEmoji22 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <path
      fill='rgb(var(--color-brand-400))'
      d='M120.85 34.68h-84.4l6.23 31.71H86.7c0 .15-.02.29-.02.44 0 4.32 3.5 7.83 7.83 7.83s7.83-3.5 7.83-7.83c0-.15-.01-.29-.02-.44h12.3l6.23-31.71z'
    />
    <path
      fill='rgb(var(--color-brand-100))'
      d='M62.8 55.52c-6 0-11.6-3.07-14.84-8.08-2.6-.36-5.09-1.28-7.3-2.68a14.36 14.36 0 0 1-6.62 1.62c-.25 0-.5 0-.75-.02-7.22-.37-13.2-6.32-13.62-13.53-.23-4.01 1.15-7.83 3.9-10.74 2.71-2.87 6.53-4.52 10.48-4.52.67 0 1.36.05 2.05.15 3.49-4.4 8.8-6.98 14.45-6.98.3 0 .59 0 .9.02C54.16 7.09 58.4 4.94 63 4.94c5.27 0 10.05 2.89 12.57 7.38 1.73-.88 3.63-1.33 5.62-1.33 3.27 0 6.4 1.3 8.72 3.56 2.67-3.94 7.17-6.39 12-6.39 4.18 0 8.07 1.76 10.83 4.88h.55c4.71 0 9.13 1.95 12.3 5.39.46-.06.92-.09 1.37-.09 6.1 0 11.06 4.96 11.06 11.06s-4.96 11.06-11.06 11.06c-.24 0-.49 0-.73-.03a16.791 16.791 0 0 1-12.94 6.12c-2.27 0-4.48-.45-6.56-1.33-.48-.21-.99-.31-1.52-.31-1.12 0-2.2.48-2.97 1.31a18.46 18.46 0 0 1-13.6 5.99c-3.77 0-7.39-1.13-10.48-3.27-.21-.15-.45-.22-.71-.22-.42 0-.83.2-1.11.53a17.647 17.647 0 0 1-13.51 6.28z'
    />
    <path
      fill='rgb(var(--color-brand-500))'
      d='M62.97 6.94c5.41 0 10 3.47 11.7 8.3 1.79-1.41 4.04-2.25 6.49-2.25 3.5 0 6.59 1.72 8.5 4.35.3.02.59.05.89.08 1.98-4.28 6.31-7.26 11.34-7.26 4.06 0 7.65 1.94 9.94 4.94.48-.05.96-.07 1.44-.07 4.67 0 8.83 2.17 11.54 5.56.69-.17 1.4-.26 2.14-.26 5 0 9.06 4.06 9.06 9.06s-4.05 9.06-9.06 9.06c-.55 0-1.1-.06-1.62-.15-2.67 3.78-7.07 6.25-12.05 6.25-2.05 0-4-.42-5.78-1.17-.74-.32-1.53-.47-2.3-.47-1.65 0-3.28.69-4.44 1.96a16.42 16.42 0 0 1-12.13 5.34c-3.47 0-6.69-1.08-9.34-2.91a3.26 3.26 0 0 0-1.85-.58c-.98 0-1.96.44-2.64 1.24a15.64 15.64 0 0 1-11.98 5.57c-5.85 0-10.95-3.21-13.65-7.96-3.13-.26-6.02-1.4-8.41-3.17a12.345 12.345 0 0 1-6.71 1.98c-.22 0-.43 0-.65-.02-6.25-.32-11.37-5.4-11.73-11.65-.42-7.19 5.29-13.15 12.38-13.15 1 0 1.97.13 2.91.36 2.96-4.34 7.94-7.19 13.59-7.19.64 0 1.27.04 1.9.11C54.63 9.3 58.54 6.93 63 6.93m0-4c-4.89 0-9.41 2.13-12.5 5.79-5.87.01-11.39 2.54-15.24 6.88-.4-.03-.81-.05-1.21-.05-4.5 0-8.85 1.88-11.93 5.15-3.13 3.32-4.71 7.66-4.44 12.23.49 8.35 7.16 14.97 15.52 15.41.29.01.58.02.86.02 2.25 0 4.47-.46 6.52-1.35 1.94 1.09 4.05 1.85 6.24 2.25a19.73 19.73 0 0 0 16.01 8.24c5.64 0 10.97-2.4 14.71-6.61 3.31 2.15 7.14 3.28 11.11 3.28 5.72 0 11.21-2.42 15.08-6.64.39-.42.93-.67 1.49-.67.25 0 .5.05.73.15 2.33.99 4.8 1.5 7.34 1.5 5.28 0 10.3-2.24 13.83-6.09 7.13-.08 12.9-5.91 12.9-13.06s-5.86-13.06-13.06-13.06c-.2 0-.4 0-.6.01A18.644 18.644 0 0 0 113.59 11c-3.08-3.12-7.24-4.87-11.69-4.87-4.76 0-9.22 2.08-12.31 5.53a14.536 14.536 0 0 0-8.41-2.71c-1.68 0-3.3.28-4.84.83C73.31 5.54 68.37 2.9 63 2.9z'
    />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M82.57 26.7c0-4.08-3.31-7.38-7.38-7.38-.96 0-1.88.19-2.72.53a5.793 5.793 0 0 0-4.96-2.81c-1.76 0-3.33.78-4.39 2.02a12.084 12.084 0 0 0-7.95-2.97c-5.56 0-10.25 3.74-11.69 8.84a6.254 6.254 0 0 0-3.7-1.21c-3.48 0-6.29 2.82-6.29 6.29s2.82 6.29 6.29 6.29c1.9 0 3.61-.85 4.76-2.19 1.93 3.47 5.5 5.9 9.67 6.23a6.574 6.574 0 0 0 5.5 2.97c3.47 0 6.3-2.68 6.56-6.09a5.792 5.792 0 0 0 9.82-3.2 7.375 7.375 0 0 0 6.49-7.32z'
    />
    <circle
      cx={114.25}
      cy={27.51}
      r={7.02}
      fill='rgb(var(--color-neutral-50))'
    />
    <circle cx={40.23} cy={52.71} r={6.6} fill='rgb(var(--color-brand-200))' />
    <path
      fill='rgb(var(--color-brand-500))'
      d='M40.23 60.81c-4.47 0-8.1-3.63-8.1-8.1s3.63-8.1 8.1-8.1 8.1 3.63 8.1 8.1-3.63 8.1-8.1 8.1zm0-13.2c-2.81 0-5.1 2.29-5.1 5.1s2.29 5.1 5.1 5.1 5.1-2.29 5.1-5.1-2.29-5.1-5.1-5.1z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M40.23 59.51c-3.75 0-6.8-3.05-6.8-6.8s3.05-6.8 6.8-6.8 6.8 3.05 6.8 6.8-3.05 6.8-6.8 6.8zm0-13.2c-3.53 0-6.4 2.87-6.4 6.4s2.87 6.4 6.4 6.4 6.4-2.87 6.4-6.4-2.87-6.4-6.4-6.4z'
    />
    <path
      fill='rgb(var(--color-brand-500))'
      d='M40.23 61.01c-4.58 0-8.3-3.72-8.3-8.3s3.72-8.3 8.3-8.3 8.3 3.72 8.3 8.3-3.72 8.3-8.3 8.3zm0-13.2c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9 4.9-2.2 4.9-4.9-2.2-4.9-4.9-4.9z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M131.32 40.59c-2.12 0-3.89 1.52-4.28 3.52-.75-.29-1.55-.45-2.4-.45-3.68 0-6.68 2.99-6.68 6.67s2.99 6.67 6.68 6.67 6.67-2.99 6.67-6.67c0-.35-.03-.69-.09-1.02h.09c2.41 0 4.37-1.96 4.37-4.37s-1.96-4.37-4.37-4.37z'
    />
    <path
      fill='rgb(var(--color-brand-500))'
      d='M124.64 58.51c-4.51 0-8.17-3.67-8.17-8.17s3.67-8.17 8.17-8.17c.49 0 .98.04 1.46.13.98-1.92 2.98-3.2 5.22-3.2 3.23 0 5.87 2.63 5.87 5.87 0 2.72-1.86 5.01-4.38 5.67-.16 4.37-3.76 7.88-8.17 7.88zm0-13.35c-2.85 0-5.17 2.32-5.17 5.17s2.32 5.17 5.17 5.17 5.17-2.32 5.17-5.17c0-.24-.02-.49-.07-.79l-.28-1.78 1.93.06c1.54-.04 2.79-1.31 2.79-2.87s-1.29-2.87-2.87-2.87c-1.36 0-2.54.97-2.81 2.31l-.35 1.75-1.67-.65a5.2 5.2 0 0 0-1.86-.35z'
    />
    <circle
      cx={144.15}
      cy={43.91}
      r={2.42}
      fill='rgb(var(--color-brand-200))'
    />
    <circle
      cx={143.91}
      cy={63.05}
      r={4.82}
      fill='rgb(var(--color-brand-200))'
    />
    <circle cx={25.18} cy={54.84} r={3.35} fill='rgb(var(--color-brand-400))' />
    <circle cx={12.12} cy={44.69} r={4.19} fill='rgb(var(--color-brand-200))' />
    <circle cx={105.18} cy={56.65} r={3.5} fill='rgb(var(--color-brand-600))' />
    <path
      fill='rgb(var(--color-brand-100))'
      d='M125.79 73.26a9.27 9.27 0 0 0-8.39-5.3h-15.28c-.69 0-1.26.48-1.45 1.15-.76 2.76-3.29 4.78-6.29 4.78s-5.53-2.03-6.29-4.78c-.18-.66-.76-1.15-1.45-1.15H42.59c-3.59 0-6.87 2.07-8.4 5.32a50.43 50.43 0 0 0-4.78 21.48c0 28.58 23.71 51.63 52.53 50.54 26.52-1 48-22.82 48.61-49.35.19-8.13-1.54-15.83-4.77-22.69z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.97 147.34c-13.68 0-26.55-5.18-36.45-14.7-10.39-10-16.11-23.46-16.11-37.88 0-7.8 1.67-15.31 4.97-22.33 1.85-3.93 5.85-6.47 10.21-6.47h44.05c1.56 0 2.95 1.07 3.37 2.61.54 1.95 2.33 3.32 4.36 3.32s3.82-1.36 4.36-3.32c.43-1.54 1.81-2.61 3.37-2.61h15.28c4.35 0 8.35 2.53 10.2 6.45 3.48 7.4 5.15 15.34 4.96 23.59-.64 27.72-22.84 50.26-50.53 51.3-.68.03-1.37.04-2.05.04zM42.59 69.96c-2.81 0-5.4 1.64-6.59 4.17a48.215 48.215 0 0 0-4.59 20.63c0 13.32 5.29 25.75 14.89 35 9.59 9.24 22.21 14.05 35.57 13.55 25.59-.96 46.09-21.78 46.69-47.39.18-7.62-1.37-14.96-4.58-21.79-1.19-2.52-3.77-4.15-6.58-4.15h-14.9c-1.12 3.51-4.42 5.93-8.12 5.93s-7-2.42-8.12-5.93H42.59z'
    />
    <circle
      cx={52.89}
      cy={97.23}
      r={11.99}
      fill='rgb(var(--color-brand-200))'
    />
    <circle
      cx={106.38}
      cy={97.23}
      r={11.99}
      fill='rgb(var(--color-brand-200))'
    />
    <circle
      cx={52.89}
      cy={92.3}
      r={11.99}
      fill='rgb(var(--color-neutral-50))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M52.89 105.54c-7.3 0-13.25-5.94-13.25-13.24s5.94-13.24 13.25-13.24S66.13 85 66.13 92.3s-5.94 13.24-13.24 13.24zm0-23.99c-5.92 0-10.75 4.82-10.75 10.74s4.82 10.74 10.75 10.74 10.74-4.82 10.74-10.74-4.82-10.74-10.74-10.74z'
    />
    <circle cx={52.89} cy={92.3} r={3.1} fill='rgb(var(--color-brand-700))' />
    <circle
      cx={106.38}
      cy={92.3}
      r={11.99}
      fill='rgb(var(--color-neutral-50))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M106.38 105.54c-7.3 0-13.24-5.94-13.24-13.24s5.94-13.24 13.24-13.24S119.62 85 119.62 92.3s-5.94 13.24-13.24 13.24zm0-23.99c-5.92 0-10.74 4.82-10.74 10.74s4.82 10.74 10.74 10.74 10.74-4.82 10.74-10.74-4.82-10.74-10.74-10.74z'
    />
    <circle cx={106.38} cy={92.3} r={3.1} fill='rgb(var(--color-brand-700))' />
    <rect
      width={19.01}
      height={27.33}
      x={70.13}
      y={99.77}
      fill='rgb(var(--color-brand-400))'
      rx={5.8}
      ry={5.8}
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M83.34 128.11h-7.41c-3.75 0-6.8-3.05-6.8-6.8v-15.73c0-3.75 3.05-6.8 6.8-6.8h7.41c3.75 0 6.8 3.05 6.8 6.8v15.73c0 3.75-3.05 6.8-6.8 6.8zm-7.41-27.33c-2.65 0-4.8 2.15-4.8 4.8v15.73c0 2.65 2.15 4.8 4.8 4.8h7.41c2.65 0 4.8-2.15 4.8-4.8v-15.73c0-2.65-2.15-4.8-4.8-4.8h-7.41z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M79.9 128.63c.62 0 1.23-.04 1.83-.1 3.2-.46 5.82-2.66 6.89-5.61-2.2-2.49-5.41-4.05-8.99-4.05s-6.78 1.57-8.99 4.05c1.07 2.95 3.69 5.16 6.89 5.61.6.06 1.21.09 1.83.1h.54z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.9 129.88h-.54c-.67 0-1.32-.04-1.95-.1-3.68-.52-6.7-2.99-7.94-6.43-.16-.43-.06-.91.24-1.25 2.52-2.84 6.14-4.47 9.92-4.47s7.4 1.63 9.92 4.47c.3.34.4.82.24 1.25a9.842 9.842 0 0 1-7.89 6.43c-.69.07-1.34.1-1.99.11h-.01zm-7.79-6.69a7.334 7.334 0 0 0 5.6 4.1c.5.05 1.08.08 1.67.08h.52c.58 0 1.16-.04 1.72-.09 2.4-.34 4.48-1.89 5.54-4.1-2.01-1.96-4.71-3.07-7.52-3.07s-5.51 1.11-7.52 3.07z'
    />
  </svg>
)
export default SvgEmoji22
