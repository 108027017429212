import { Action, useTooltip } from 'app/services/commands'
import { Tooltip, Button as UiButton } from 'ui/revision'

const colors = {
  black: '#000',
  white: '#fff',
  primary: 'var(--color-brand-main)',
  secondary: 'var(--color-accent-main)',
  error: 'var(--system-color-error-main)',
  warning: 'var(--system-color-warning-main)',
  info: 'var(--system-color-info-cobalt-main)',
  success: 'var(--system-color-success-main)',
}

const variants = {
  contained: 'primary',
  outlined: 'secondary',
  tertiary: 'tertiary',
  text: 'text',
  primary: 'primary',
  secondary: 'secondary',
}

type Props = React.ComponentPropsWithRef<typeof UiButton> & {
  action?: Action
  actionState?: boolean | string | number
  position?: 'top' | 'bottom' | 'left' | 'right'
  fullWidth?: boolean
  size?: 'small' | 'medium' | 'large'
  large?: boolean
}

// Mapping from MUI Button to Revision Button
export const Button: React.FC<Props> = ({
  fullWidth,
  size,
  large,
  color,
  variant,
  style,
  small,
  ...others
}: Props) => {
  const { tooltipProps, otherProps } = useTooltip<typeof UiButton>(
    others,
    'onClick'
  )

  const padding = {
    padding:
      'calc(var(--spacing-half) + var(--spacing-quarter) + var(--spacing-eighth))',
  }
  const fontSize = {
    fontSize:
      'calc(var(--spacing-2) + var(--spacing--half) + var(--spacing-quarter))',
  }
  const getColor = () => {
    const newColor = colors[color]
    return newColor ? { '--color': newColor } : { color }
  }
  const getVariant = () => {
    let newVariant = variants[variant]
    // If variant is not one of the above it will become undefined thus Revision button will consider this is primary!
    newVariant ??= undefined
    return newVariant
  }

  const cssColor = color ? getColor() : null
  const newVariant = variant ? getVariant() : null
  const cssFullWidth = fullWidth ? { width: '100%' } : null
  const newSmall = size === 'small' || small
  const cssLarge = size === 'large' || large ? { padding, fontSize } : null
  const css = { ...style, ...cssLarge, ...cssFullWidth, ...cssColor }

  return tooltipProps ? (
    <Tooltip {...tooltipProps}>
      <UiButton
        {...otherProps}
        small={newSmall}
        style={css}
        variant={newVariant}
      />
    </Tooltip>
  ) : (
    <UiButton
      {...otherProps}
      small={newSmall}
      style={css}
      variant={newVariant}
    />
  )
}

export default Button
