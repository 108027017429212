import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { bindActionCreators } from 'redux'

import { STORE_NAME as SETTINGS_STORE_NAME } from 'app/features/settings/settings.reducer'
import ShortcutListener from 'app/services/commands/keyboard-shortcut-listener'
import coligoSchema from 'app/services/commands/schema-coligo'
import { useDispatch, useSelector } from 'app/services/state/redux-store'
import moment from 'lib/moment'
import { useWindowEvent } from 'lib/react/hooks/use-event.hook'
import asFeature from 'ui/feature'

import App from './app.component'
import middleware from './app.middleware'
import reducer, { STORE_NAME, actions } from './app.reducer'

const AppContainer = (): JSX.Element => {
  const {
    i18n: { language },
  } = useTranslation()
  const dispatch = useDispatch()

  const onBlur = bindActionCreators(actions.blur, dispatch)
  const onFocus = bindActionCreators(actions.focus, dispatch)
  const hotkeysEnabled = useSelector(
    ({ [SETTINGS_STORE_NAME]: settings }) => !!settings.hotkeysEnabled
  )

  useWindowEvent('focus', onFocus)
  useWindowEvent('blur', onBlur)

  useEffect(() => {
    /**
     * on language change set the language on the moment lib used throughout
     * the app
     */
    logger.debug(`AppContainer:: updating moment.locale to "${language}"`)
    moment.locale(language)
  }, [language])

  useEffect(() => {
    ShortcutListener.setSchema(coligoSchema)

    if (hotkeysEnabled) ShortcutListener.enable()

    return () => {
      ShortcutListener.disable()
    }
  }, [])

  return <App />
}

export default asFeature(STORE_NAME, {
  middleware,
  reducer,
  removeOnUnmount: true,
})(AppContainer)
