import cn from 'clsx'
import {
  type ComponentPropsWithRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { useTranslation } from 'react-i18next'

import { useId } from 'app/react/hooks/id-registry.hooks'
import { type Flags } from 'constants/constants'
import { Dropdown, DropdownItem, Icon, SelectField } from 'ui/revision'
import { makeStyles } from 'app/ui'

const useStyles = makeStyles(
  () => ({
    root: ({ fullWidth }) => (fullWidth ? { width: '100%' } : {}),
    flag: {
      marginRight: 'var(--spacing-1)',
    },
    dropdown: {
      // Position the dropdown below the center of the container
      left: '-0.5rem',
      top: '0.25rem',
    },
  }),
  { name: 'LanguageDropdown' }
)

interface SelectItem {
  label: string
  value: string
}

type Props = {
  fullWidth?: boolean
  options: LanguageDropdownItem[]
  value?: string
  onChange?: (selection: LanguageDropdownItem) => void
} & Omit<ComponentPropsWithRef<typeof SelectField>, 'value'>

export interface LanguageDropdownItem {
  /**
   * language flag icon eg. 'flag--netherlands' see https://notion.designsystem.enreach.com/brand-assets/graphics
   */
  flag?: Flags
  /**
   * language label eg. 'Netherlands'
   */
  label: string
  /**
   * language option value eg. 'nl'
   */
  value: string
}

export const LanguageDropdown = ({
  className,
  options,
  value,
  onChange,
  ...selectFieldProps
}: Props) => {
  const { id } = useId()
  const { t } = useTranslation()
  const classes = useStyles(selectFieldProps)

  const defaultValue = 'selectLanguage'

  const currentOption = useMemo(() => {
    const found = options.find((option) => option.value === value)

    if (found?.value) return found

    // Safeguard, this should never be rendered but added incase there is a state issue, we display a default choice
    return {
      label: t('LanguageDropdown.DefaultOption', 'Select Language'),
      value: 'selectLanguage',
      flag: undefined,
    } as LanguageDropdownItem
  }, [options, value, t])

  const ref = useRef<HTMLClSelectFieldElement>(null)

  const handleChange = useCallback(
    (event: Event) => {
      onChange((event as CustomEvent<SelectItem[]>).detail[0])
    },
    [onChange]
  )

  /**
   * NOTE: This onChange workaround via ref is required due to ClSelectField's
   * onChange prop not working properly.
   * See: https://jira.voiceworks.com/browse/CCL-592
   */
  useEffect(() => {
    ref.current?.addEventListener('change', handleChange)
    return () => {
      ref.current?.removeEventListener('change', handleChange)
    }
  }, [handleChange])

  return (
    <SelectField
      {...selectFieldProps}
      closeOnSelect
      className={cn(classes.root, className)}
      data-testid={id('LanguageDropDown.select')}
      ref={ref}
      value={[currentOption]}
    >
      <span slot='leading'>
        {currentOption.flag && <Icon name={currentOption.flag} />}
      </span>
      <Dropdown
        fullWidth
        className={cn(classes.dropdown)}
        data-testid={id('LanguageDropDown.list')}
        variant='menu'
      >
        <span>
          {options.map((option) => (
            <DropdownItem
              data-testid={id('LanguageDropDown.item')}
              disabled={option.value === defaultValue}
              key={option.value}
              label={option.label}
              selected={option.value === value}
              value={option.value}
            >
              <span slot='leading'>
                {option.flag && <Icon name={option.flag} />}
              </span>
            </DropdownItem>
          ))}
        </span>
      </Dropdown>
    </SelectField>
  )
}
export default LanguageDropdown
