import PropTypes from 'prop-types'

import TextField from '../text-field'

const NumberField = (props) => <TextField type='number' {...props} />

NumberField.propTypes = {
  name: PropTypes.string,
}

NumberField.defaultProps = {
  name: 'number',
}

export default NumberField
