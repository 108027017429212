import Task from 'lib/tasks/coligo-task'

import {
  STORE_NAME as CHANNEL_AVATAR_STORE_NAME,
  actions as channelAvatarActions,
  selectors as channelAvatarSelectors,
} from 'app/state/api/channels/channel-avatar.reducer'

/**
 * @class
 * @classdesc JoinAvatarChannelTask joins the avatar channel and waits for the avatars upload and download link to be available.
 */
class JoinAvatarChannelTask extends Task {
  name = 'JoinAvatarChannelTask'

  store

  cancelPromise

  _unsubscribe

  constructor(store) {
    super()

    this.store = store
  }

  isStateValid() {
    const currentState = this.store.getState()
    if (
      channelAvatarSelectors.getDownloadLink(
        currentState[CHANNEL_AVATAR_STORE_NAME]
      ) &&
      channelAvatarSelectors.getUploadLink(
        currentState[CHANNEL_AVATAR_STORE_NAME]
      )
    ) {
      return true
    }
    return false
  }

  async run() {
    await new Promise(async (resolve, reject) => {
      this.cancelPromise = (err) => reject(new Error(err))

      try {
        await this.store.dispatch(channelAvatarActions.join())

        if (this.isStateValid()) {
          resolve()
        } else {
          this._unsubscribe = this.store.subscribe(() => {
            if (this.isStateValid()) {
              this._unsubscribe()
              resolve()
            }
          })
        }
      } catch (err) {
        reject(err)
      }
    })
  }

  cancel() {
    if (this._unsubscribe) {
      this._unsubscribe()
    }
    if (this.cancelPromise) {
      this.cancelPromise(
        `JoinAvatarChannelTask is canceled while is was joining the avatar channel and waiting for the download and upload links`
      )
    }
  }
}

export default JoinAvatarChannelTask
