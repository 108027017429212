import clsx from 'clsx'
import ReactCountryFlag from 'react-country-flag'

type Props = React.ComponentPropsWithRef<typeof ReactCountryFlag>

export const FlagIcon: React.FC<Props> = ({ className, ...other }: Props) => {
  return (
    <ReactCountryFlag {...other} className={clsx('emojiFlag', className)} />
  )
}

export default FlagIcon
