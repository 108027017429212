/**
 * This reducer follows the Ducks budling style
 * see https://github.com/erikras/ducks-modular-redux
 * basic rules:
 * - MUST export default a function called reducer()
 * - MUST export its action creators as functions
 * - MUST have action types in the form npm-module-or-app/reducer/ACTION_TYPE
 * - MAY export its action types as UPPER_SNAKE_CASE, if an external reducer
 *   needs to listen for them, or if it is a published reusable library
 */
import _ from 'lodash'

import {
  getExtensionForFileName,
  getOnlyFileName,
  parseLinkDataAndGetFile,
  parseLinkDataAndGetFileInElectron,
} from 'lib/files'
import browserUtils from 'lib/browser-utils'
import { actions as channelRoomActions } from 'app/state/api/channels/channel-room.reducer'

export const STORE_NAME = 'feature/meeting-files'

type MeetingsFile = {
  id: string
  ext: string
  name: string
  fileName: string
  fileType: string
  size: number
  time: number
}
// ------------------------------------
// Constants
// ------------------------------------
const UPDATE_READ_FILE = `sm-web/${STORE_NAME}/UPDATE_READ_FILE`
const FILES_DIFF = `sm-web/${STORE_NAME}/FILES_DIFF`
const FILES_PUT = `sm-web/${STORE_NAME}/FILES_PUT`
const FILES_CLEAN = `sm-web/${STORE_NAME}/FILES_CLEAN`
const SELECT_FILE = `sm-web/${STORE_NAME}/SELECT_FILE`
const SELECT_ALL_FILES = `sm-web/${STORE_NAME}/SELECT_ALL_FILES`
const REMOVE_SELECTED = `sm-web/${STORE_NAME}/REMOVE_SELECTED`

export const ACTION_TYPES = {
  UPDATE_READ_FILE,
  FILES_DIFF,
  FILES_PUT,
  FILES_CLEAN,
  SELECT_FILE,
  SELECT_ALL_FILES,
  REMOVE_SELECTED,
}

const INITIAL_STATE = {
  lastReadFile: null,
  filesById: {},
  files: [],
  selectedFiles: [],
  selectingAll: false,
}

/**
 * Update the read file.
 * @return
 */
const updateReadFile = () => ({ type: UPDATE_READ_FILE })

/**
 * @param *
 * @param id
 * @return
 */
const toLocalFileFormat = ({ name, time, type, size }, id) => ({
  id,
  ext: getExtensionForFileName(name),
  name: getOnlyFileName(name),
  fileName: name,
  fileType: type,
  size,
  time: new Date(time).getTime(),
})

/**
 * @param
 * @return
 */
const diff = ({ added, removed }) => ({
  type: FILES_DIFF,
  added: _.keyBy(
    _.map(added, ({ path, value }) => toLocalFileFormat(value, path)),
    'id'
  ),
  removed,
})

/**
 * @param files
 */
const put = (files) => ({
  type: FILES_PUT,
  files: _.keyBy(_.map(files, toLocalFileFormat), 'id'),
})

const clean = () => ({ type: FILES_CLEAN })

/**
 * @param fileId
 */
const select = (fileId) => ({
  type: SELECT_FILE,
  payload: fileId,
})

/**
 * @return
 */
const selectAll = () => ({ type: SELECT_ALL_FILES })

/**
 * Download all files which are in the `selectedFiles` state,
 * and empty the `selectedFiles` state.
 */
const downloadSelected = () => (dispatch, getState) => {
  getState()[STORE_NAME].selectedFiles.forEach(async (fileId) => {
    const fileLink = await channelRoomActions.getDownloadLink(fileId)
    if (browserUtils.isElectron()) {
      parseLinkDataAndGetFileInElectron(fileLink)
    } else {
      parseLinkDataAndGetFile(fileLink)
    }
  })

  dispatch({ type: REMOVE_SELECTED })
}

/**
 * Delete all files which are in the `selectedFiles` state,
 * and empty the `selectedFiles` state.
 * FIXME: calling channelRoomActions from the meetings-files reducer?? should be handled through middleware
 */
const deleteSelected = () => (dispatch, getState) => {
  getState()[STORE_NAME].selectedFiles.forEach(
    channelRoomActions.removeFileFromRoom
  )

  dispatch({ type: REMOVE_SELECTED })
}

const deselectAll = () => ({ type: REMOVE_SELECTED })

export const actions = {
  updateReadFile,
  diff,
  put,
  clean,
  deleteSelected,
  downloadSelected,
  select,
  selectAll,
  deselectAll,
}

/**
 * Do a descending sort on the given files based on their time key.
 * @param files
 * @return
 */
const getSortedFileKeys = (files) =>
  _.keys(files)
    .sort((a, b) => files[a].time - files[b].time)
    .map((key) => files[key].id)

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [UPDATE_READ_FILE]: (state) => {
    let lastReadFile = null

    if (state.files.length) {
      lastReadFile = state.files[state.files.length - 1]
    }

    return { ...state, lastReadFile }
  },
  [FILES_DIFF]: (state, { added = {}, removed = [] }) => {
    /**
     * An array of all the old file minus the newly removed ones.
     * @type {Array<string>}
     */
    const files = _.without(state.files, ...removed)

    let { lastReadFile } = state

    /**
     * If there was a lastReadFile and it is removed after the files_diff,
     * we wan't to either grab the last (not added) remaining file or if there
     * were no files remaining, we set it back to null.
     */
    if (lastReadFile && !files.includes(lastReadFile)) {
      lastReadFile = files.length ? state.files[state.files.length - 1] : null
    }

    const filesById = { ..._.omit(state.filesById, ...removed), ...added }

    return {
      ...state,
      files: getSortedFileKeys(filesById),
      filesById,
      lastReadFile,
    }
  },
  [FILES_PUT]: (state, action) => ({
    ...state,
    files: getSortedFileKeys(action.files),
    filesById: action.files,
  }),
  [FILES_CLEAN]: () => INITIAL_STATE,
  [SELECT_FILE]: (state, action) => {
    const index = state.selectedFiles.indexOf(action.payload)

    if (index >= 0) {
      const selectedFiles = [...state.selectedFiles]
      selectedFiles.splice(index, 1)

      return {
        ...state,
        selectedFiles,
        selectingAll: state.selectingAll && !_.isEmpty(selectedFiles),
      }
    }

    return {
      ...state,
      selectedFiles: [...state.selectedFiles, action.payload],
      selectingAll: state.selectedFiles.length + 1 === state.files.length,
    }
  },
  [SELECT_ALL_FILES]: (state) => ({
    ...state,
    selectedFiles: !state.selectingAll ? state.files : [],
    selectingAll: !state.selectingAll,
  }),
  [REMOVE_SELECTED]: (state) => ({
    ...state,
    selectedFiles: [],
    selectingAll: false,
  }),
  'sm-web/RESET': () => INITIAL_STATE,
}

const selectFile = (state, id) => _.get(state, `filesById[${id}]`)
const selectFiles = (state) => _.map(state.files, (id) => selectFile(state, id))

export const selectors = {
  selectFiles,
  selectFile,
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = INITIAL_STATE, action = {}) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
