import _ from 'lodash'

import { MediaDeviceKind } from 'lib/rtc'

/**
 * Either return the device with the given deviceId or when that device is
 * not in the list return either the default or first device in the list.
 * When there is no device to return, null will be returned.
 * @param newDevices
 * @param [deviceId=null]
 * @param [kind=null]
 * @return
 */
export function getNewDevice(newDevices, deviceId = null, kind = null) {
  const devices = kind ? _.filter(newDevices, { kind }) : newDevices

  if (_.isEmpty(devices)) {
    return null
  }

  const oldDevice = _.find(devices, { deviceId })
  if (oldDevice) {
    return oldDevice
  }

  const defaultDevice = _.find(devices, { deviceId: 'default' })
  if (defaultDevice) {
    return defaultDevice
  }

  return _.first(devices) ?? null
}

/**
 * Determines if currently selected audio output device should be changed after
 * list of available devices has been changed.
 * @param newDevices
 * @param [outDeviceId=null]
 * @returns - ID of new audio output device to use, undefined
 * if audio output device should not be changed.
 */
export function getNewAudioOutputDevice(newDevices, outDeviceId = null) {
  const newDevice = getNewDevice(
    newDevices,
    outDeviceId,
    MediaDeviceKind.audiooutput
  )
  return newDevice ? newDevice.deviceId : ''
}

/**
 * Determines if currently selected audio input device should be changed after
 * list of available devices has been changed.
 * @param newDevices
 * @param [micDeviceId=null]
 * @returns - ID of new microphone device to use, undefined
 * if audio input device should not be changed.
 */
export function getNewAudioInputDevice(newDevices, micDeviceId = null) {
  const newDevice = getNewDevice(
    newDevices,
    micDeviceId,
    MediaDeviceKind.audioinput
  )
  return newDevice ? newDevice.deviceId : ''
}

/**
 * Determines if currently selected video input device should be changed after
 * list of available devices has been changed.
 * @param newDevices
 * @param [camDeviceId=null]
 * @returns - ID of new camera device to use, undefined
 * if video input device should not be changed.
 */
export function getNewVideoInputDevice(newDevices, camDeviceId = null) {
  const newDevice = getNewDevice(
    newDevices,
    camDeviceId,
    MediaDeviceKind.videoinput
  )
  return newDevice ? newDevice.deviceId : ''
}

/**
 * Compare 2 devices for sorting them where the default devices are prioritised.
 * @param a
 * @param b
 * @returns
 */
export function compareDevices(a, b) {
  if (a.deviceId === 'default') return -1
  if (b.deviceId === 'default') return 1
  return 0
}
