import { ClDatePicker } from '@enreach/core-component-library-react'
import { defineCustomElementClDatePicker } from '@enreach/core-component-library/dist/components/index'
import { useCallback, useState } from 'react'
import { format as formatFns } from 'date-fns/fp'

import { Icons } from 'constants/icons'
import { Icon, Field } from 'ui/revision'

import { type DatePickerProps } from './date-picker.types'

export const DatePicker = ({
  pickerProps = {},
  iconProps = {},
  value: valueProps = new Date(),
  format = formatFns('dd/MM/yyyy'),
  onChange,
  ...fieldProps
}: DatePickerProps) => {
  const [open, setOpen] = useState(false)

  const handleDateChange = useCallback(
    ({ startDate }) => {
      onChange?.(startDate as Date)
      setOpen(false)
    },
    [onChange]
  )

  const handleFieldClick = useCallback(() => {
    setOpen(true)
  }, [])

  const handleonHideContent = useCallback(() => {
    setOpen(false)
  }, [])

  /**
   * FIXME: ClField is not behaving as expected as it has an internal
   * state for showing / hiding the slot="content". This leads to desync state with
   * the open state of the ClDatePicker component leading to a bug where after
   * selecting a date in the picker, 2 clicks are needed on clfield to open it again.
   */
  return (
    <Field
      {...fieldProps}
      value={format(valueProps)}
      onClick={handleFieldClick}
      onClHideContent={handleonHideContent}
    >
      <span slot='leading'>
        <Icon {...iconProps} name={iconProps.name || Icons.genericCalendar} />
      </span>
      <span slot='content'>
        <ClDatePicker
          {...pickerProps}
          autoClose={false}
          month={valueProps.getMonth()}
          open={open}
          year={valueProps.getFullYear()}
          onClChange={(event) => handleDateChange(event.detail)}
        />
      </span>
    </Field>
  )
}

export default DatePicker

defineCustomElementClDatePicker()
