import { ACTION_TYPES } from './file-upload-queue.reducer'

import {
  openSuccessSnackbarIfSucces,
  openFailedSnackbar,
} from './file-upload-queue.utils'

export default (store) => (next) => (action) => {
  if (action.type === ACTION_TYPES.FILES_QUEUE_COMPLETE) {
    store.dispatch(openSuccessSnackbarIfSucces(action.id))
  }

  if (action.type === ACTION_TYPES.FILES_QUEUE_ERROR) {
    store.dispatch(openFailedSnackbar())
  }

  return next(action)
}
