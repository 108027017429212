import { Icons } from 'constants/icons'
import IconButton from '.'

/**
 * IconButton showing insert_emoticon icon
 */
export const Emoji = (props) => (
  <IconButton {...props} iconName={Icons.communicationTextEmoticon} />
)

/**
 * IconButton showing attachment icon
 */
export const File = (props) => (
  <IconButton {...props} iconName={Icons.actionFileImport} />
)

/**
 * IconButton showing view_module icon
 */
export const ViewModule = (props) => (
  <IconButton {...props} iconName={Icons.viewGrid} />
)

/**
 * Floating Button showing close icon
 */
export const Close = (props) => (
  <IconButton {...props} variant='primary' iconName={Icons.actionClose} />
)

/**
 * Floating Button showing done icon
 */
export const Done = (props) => (
  <IconButton {...props} variant='primary' iconName={Icons.actionCheckmark} />
)

/**
 * Floating Button showing send icon
 */
export const Send = (props) => (
  <IconButton {...props} variant='primary' iconName={Icons.actionSend} />
)

/**
 * Floating Button showing pin icon
 */
export const Pin = (props) => (
  <IconButton {...props} variant='primary' iconName={Icons.genericPin} />
)

/**
 * Floating Button showing unpin icon
 */
export const Unpin = (props) => (
  <IconButton {...props} variant='primary' iconName={Icons.actionClose} />
)
