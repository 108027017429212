import { Icon } from 'ui/revision'

const SpeechOpen = (props) => (
  <Icon {...props}>
    <svg viewBox='0 0 23 21'>
      <path
        d='M20.597 0c1.095 0 1.986.891 1.986 1.986v11.028c0 1.095-.89 1.986-1.986 1.986h-9.315l-5.387 5.387V15H1.986C.891 15 0 14.109 0 13.014V1.986C0 .891.891 0 1.986 0zm-.014 2H1.986L2 13.014 6.895 13h1v2.559L10.455 13h10.142l-.014-11zM5.793 5.959c.965 0 1.75.799 1.75 1.785 0 .986-.785 1.784-1.75 1.784-.968 0-1.75-.798-1.75-1.784s.782-1.785 1.75-1.785zm5.5 0c.965 0 1.75.799 1.75 1.785 0 .986-.785 1.784-1.75 1.784-.968 0-1.75-.798-1.75-1.784s.782-1.785 1.75-1.785zm5.5 0c.965 0 1.75.799 1.75 1.785 0 .986-.785 1.784-1.75 1.784-.968 0-1.75-.798-1.75-1.784s.782-1.785 1.75-1.785z'
        fill='#FFF'
        fillRule='evenodd'
      />
    </svg>
  </Icon>
)

export default SpeechOpen
