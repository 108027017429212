import type { Message } from 'app/state/api/channels'
import _ from 'lodash'
import { type Action, type AnyAction } from 'redux'

// ------------------------------------
// Constants
// ------------------------------------
const MESSAGE_ADD = 'sm-web/private-chat/SEND'
const CHAT_CLOSE = 'sm-web/private-chat/CHAT_CLOSE'
const CHAT_MINIMIZE = 'sm-web/private-chat/CHAT_MINIMIZE'
const CHAT_MAXIMIZE = 'sm-web/private-chat/CHAT_MAXIMIZE'
const CHAT_FOCUS = 'sm-web/private-chat/CHAT_FOCUS'
const CHAT_UNFOCUS = 'sm-web/private-chat/CHAT_UNFOCUS'
const CHAT_OPEN = 'sm-web/private-chat/CHAT_OPEN'
const SAVE_TEMP_CHATS = 'sm-web/private-chat/SAVE_TEMP_CHATS'
const USER_TYPING = 'sm-web/private-chat/USER_TYPING'
const CHAT_UNREAD_RESET = 'sm-web/private-chat/CHAT_UNREAD_RESET'

export const ACTION_TYPES = {
  MESSAGE_ADD,
  CHAT_CLOSE,
  CHAT_MINIMIZE,
  CHAT_MAXIMIZE,
  CHAT_FOCUS,
  CHAT_UNFOCUS,
  CHAT_OPEN,
  SAVE_TEMP_CHATS,
  USER_TYPING,
  CHAT_UNREAD_RESET,
}

export interface PrivateChat {
  id: string
  user: string
  participant: string
  messages: Message[]
  minimize: boolean
  totalMessages: number
  open: boolean
  typing: boolean
  focused: boolean
  unread: number
}

export const INITIAL_STATE: PrivateChat = {
  id: '',
  user: '',
  participant: '',
  messages: [],
  minimize: false,
  totalMessages: 0,
  open: false,
  typing: false,
  focused: false,
  unread: 0,
}

// ------------------------------------
// Actions
// ------------------------------------
export const actions = {}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [MESSAGE_ADD]: (state: PrivateChat, action: AnyAction) => {
    if (!state) return state
    const typedState = state
    return _.merge({}, state, {
      messages: _.concat([], typedState.messages, action.payload),
      totalMessages: typedState.totalMessages + 1,
      unread: typedState.open ? 0 : (typedState.unread || 0) + 1,
    })
  },
  [CHAT_CLOSE]: (state: PrivateChat) => _.merge(state, { open: false }),
  [CHAT_FOCUS]: (state: PrivateChat) => _.merge(state, { focused: true }),
  [CHAT_MINIMIZE]: (state: PrivateChat) => _.merge(state, { minimize: true }),
  [CHAT_MAXIMIZE]: (state: PrivateChat) => _.merge(state, { minimize: false }),
  [CHAT_UNFOCUS]: (state: PrivateChat) => _.merge(state, { focused: false }),
  [CHAT_OPEN]: (state: PrivateChat) =>
    _.merge(state, { open: true, unread: 0 }),
  [CHAT_UNREAD_RESET]: (state: PrivateChat) => _.merge(state, { unread: 0 }),
  [SAVE_TEMP_CHATS]: (state: PrivateChat, action: AnyAction) =>
    _.merge({}, state, { tempMessage: action.message }),
  [USER_TYPING]: (state: PrivateChat, action: AnyAction) =>
    _.merge({}, state, { typing: action.payload.typing }),
}

// ------------------------------------
// Reducer
// ------------------------------------
export const reducer = (
  state = INITIAL_STATE,
  action: Action<keyof typeof ACTION_HANDLERS>
) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
