import env from 'app/config/env'

export class BroadcastContext {
  /**
   * @type {{ broadcast: (event: string, payload: any) => Promise<void> }}
   */
  strategy

  /**
   * @type {string[]}
   */
  whitelistEvents

  /**
   * @type {string[]}
   */
  blacklistEvents

  /**
   *
   */
  heartbeatTimeout

  /**
   * @type {number | null}
   * @protected
   */
  _heartbeatInterval = null

  /**
   *
   * @param strategy
   * @param configuration
   * @constructs
   */
  constructor(strategy, configuration) {
    this.strategy = strategy

    this.blacklistEvents =
      (configuration && configuration.blacklist) || undefined
    this.whitelistEvents =
      (configuration && configuration.whitelist) || undefined
    this.heartbeatTimeout =
      (configuration && configuration.heartbeatTimeout) || 15
  }

  /**
   * Function sets and starts an interval for sending heartbeat signals to the parent top window.
   * @public
   * @return
   */
  startHeartbeatInterval() {
    if (this._heartbeatInterval) {
      this.stopHeartbeatInterval()
    }
    this._heartbeatInterval = window.setInterval(
      this._sendHeartbeat.bind(this),
      this.heartbeatTimeout * 1_000
    )
  }

  /**
   * Function stops the set interval for sending heartbeats
   * @public
   * @return
   */
  stopHeartbeatInterval() {
    if (!this._heartbeatInterval) {
      throw new Error('No interval set')
    }
    window.clearInterval(this._heartbeatInterval)
    this._heartbeatInterval = null
  }

  /**
   * Function to broadcast a heartbeat event.
   * @protected
   * @return
   */
  async _sendHeartbeat() {
    return this.broadcast('sm-web/HEARTBEAT', {
      timeout: this.heartbeatTimeout,
    })
  }

  /**
   * Set a new strategy used to broadcast events.
   * @public
   * @param strategy
   * @return
   */
  setStrategy(strategy) {
    this.strategy = strategy
  }

  /**
   * Convert the given payload to a string.
   * @protected
   * @param [payload=null]
   * @return
   */
  _getDataString(payload = null) {
    let payloadString = payload

    if (typeof payloadString === 'object') {
      if (payloadString instanceof ArrayBuffer) {
        payloadString = 'ArrayBuffer'
      } else if (payloadString instanceof FileList) {
        payloadString = `Files (${payloadString.length})`
      } else if (payloadString instanceof File) {
        payloadString = `File (${payloadString.type})`
      } else if (payloadString instanceof Blob) {
        payloadString = `Blob (${payloadString.type})`
      } else if (payloadString instanceof Date) {
        payloadString = `Date (${payloadString.toDateString()})`
      } else {
        payloadString = JSON.stringify(payloadString)
      }
    }

    return payloadString
  }

  /**
   * Broadcast a event to an external service.
   * @public
   * @param event
   * @param [payload=null]
   * @return
   */
  async broadcast(event, payload = null) {
    let isEventAllowed = true

    if (this.whitelistEvents && !this.whitelistEvents.includes(event)) {
      isEventAllowed = false
    }
    if (this.blacklistEvents && this.blacklistEvents.includes(event)) {
      isEventAllowed = false
    }

    if (isEventAllowed) {
      const payloadString = this._getDataString(payload)
      logger.debug(`BroadcastContext: ${event} - ${payloadString}`)

      try {
        await this.strategy.broadcast(event, payload)
      } catch (err) {
        throw new Error(
          `Failed broadcasting event: ${event} - ${payloadString}`
        )
      }
    }
  }
}

export default new BroadcastContext(
  { broadcast: Function.prototype },
  env('event_broadcaster') || {}
)
