import localStore from 'app/services/state/local-store'

import { ACTION_TYPES as DEVICES_ACTION_TYPES } from 'app/features/devices/devices.reducer'

import { actions, ACTION_TYPES } from './noise-cancellation.reducer'

const middleware = (store) => (next) => (action) => {
  const { dispatch } = store

  switch (action.type) {
    case ACTION_TYPES.INIT: {
      const noiseCancelled = localStore.getNoiseCancelled()
      if (noiseCancelled) {
        dispatch(actions.enableNoiseCancellation())
      }
    }
    case ACTION_TYPES.ENABLE_NOISE_CANCELLATION:
      localStore.setNoiseCancelled(true)
      break

    case ACTION_TYPES.DISABLE_NOISE_CANCELLATION:
      localStore.setNoiseCancelled(false)
      break

    case DEVICES_ACTION_TYPES.CHOOSE_DEVICES:
      {
        const { noiseCancellation } = action.payload

        if (
          typeof noiseCancellation !== 'undefined' &&
          noiseCancellation !== null
        ) {
          dispatch(
            noiseCancellation
              ? actions.enableNoiseCancellation()
              : actions.disableNoiseCancellation()
          )
        }
      }
      break
    default:
      break
  }

  return next(action)
}

export default middleware
