import regexCreator from 'emoji-regex'
import Linkify from 'linkify-react'
import _ from 'lodash'
import {
  cloneElement,
  type ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useId } from 'app/react/hooks/id-registry.hooks'
import { getUrlsFromText } from 'lib/index'
import { Link, Thumbnail, Typography } from 'ui/revision'
import { ChatItem } from 'ui/revision'

const emojiRegex = regexCreator()

const getImageFromUrl = (url: string) => {
  if (url && url.match(/\.(jpeg|jpg|gif|png)$/)) return url
  return ''
}

/**
 * get first url from the text and return its url
 */
const getUrlFromText = (text: string) => {
  const url = _.get(getUrlsFromText(text), '[0]')
  if (url) return encodeURI(url)
  return ''
}

const renderLink = ({ attributes, content }) => {
  const { href, ...props } = attributes
  return (
    <Link href={href} target='_blank' {...props}>
      {content}
    </Link>
  )
}

type Props = ChatItemProps & {
  avatar?: ReactElement
  text: string
  onReceiveImage?: (url: string) => Promise<{ data: Record<string, any> }>
}

export const ChatTextMessage: React.FC<Props> = ({
  avatar,
  text,
  onReceiveImage,
  ...other
}: Props) => {
  const { id } = useId()
  const [url] = useState(getUrlFromText(text))
  const [imgUrl, setImgUrl] = useState(getImageFromUrl(url))

  useEffect(() => {
    const fn = async (): Promise<void> => {
      if (!imgUrl && url && onReceiveImage) {
        const res = await onReceiveImage(url)

        const u = _.get(res?.data, 'og:image[0][1]') as string
        if (u) {
          setImgUrl(u)
        }
      }
    }
    fn().catch(console.error)
  }, [imgUrl, onReceiveImage, url])

  const containsEmoji = useMemo(() => {
    const foundEmojis = countIterable(text.matchAll(emojiRegex))
    const foundText = text.replace(emojiRegex, '').replace(/ /g, '')
    return foundEmojis === 1 && !foundText
  }, [text])

  const content = useMemo(() => {
    switch (true) {
      case containsEmoji:
        return (
          <Typography data-testid={id('emojiId')} slot='content' variant='h1'>
            {text}
          </Typography>
        )
      case !!imgUrl:
        return <Thumbnail slot='content' src={imgUrl} />
      default:
        return (
          <Typography slot='content'>
            <Linkify options={{ render: renderLink }}>{text}</Linkify>
          </Typography>
        )
    }
  }, [containsEmoji, id, text, imgUrl])

  avatar = useMemo(
    () => avatar && cloneElement(avatar, { slot: 'avatar' }),
    [avatar]
  )

  return (
    <ChatItem subtle={containsEmoji} {...other}>
      {avatar}
      {content}
    </ChatItem>
  )
}

export default ChatTextMessage

/**
 * simply counts the number of items in an iterable. stops at 2 because we only care if there is 0, 1, or 2+ items
 * @param iterable
 * @returns 0 1 or 2
 */
function countIterable(iterable): number {
  let count = 0
  for (const i of iterable) {
    count++
    if (count > 1) break
  }
  return count
}
