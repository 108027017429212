import { useTranslation } from 'react-i18next'

import env from 'app/config/env'
import { Link } from 'ui/revision'

import { type Imprint, type ImprintsProps } from './imprints.types'
import { filterImprints } from './imprints.utils'
import classNames from './imprints.module.scss'

const imprintLinks: Imprint[] = env('imprint_links', [])

export const Imprints = ({ ...other }: ImprintsProps) => {
  const { i18n } = useTranslation()
  const languageCode = i18n.language.split('-')[0]

  const imprints = filterImprints(imprintLinks, languageCode || i18n.language)
    .filter((imprint) => imprint && imprint.href && imprint.title)
    .map(({ href, title }, i, { length }) => (
      <>
        <Link href={href} key={i} target='_blank'>
          {title}
        </Link>
        {i + 1 < length && (
          <span key={100 + i} className={classNames.seperator}>
            &
          </span>
        )}
      </>
    ))

  return imprints && <span {...other}>{imprints}</span>
}

export default Imprints
