import type { SVGProps } from 'react'
const SvgEmoji16 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle
      cx={79.04}
      cy={96.28}
      r={48.66}
      fill='rgb(var(--color-brand-100))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.04 146.94c-27.93 0-50.66-22.72-50.66-50.66S51.1 45.62 79.04 45.62s50.66 22.72 50.66 50.66-22.72 50.66-50.66 50.66zm0-97.31c-25.73 0-46.66 20.93-46.66 46.66s20.93 46.66 46.66 46.66 46.66-20.93 46.66-46.66-20.93-46.66-46.66-46.66z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M69.83 120.06a1.82 1.82 0 0 1-1.3-3.1c2.63-2.67 6.46-4.2 10.52-4.2s7.89 1.53 10.51 4.2c.71.72.7 1.87-.02 2.58s-1.87.7-2.58-.02c-1.95-1.98-4.83-3.11-7.91-3.11s-5.97 1.14-7.91 3.11c-.36.36-.83.55-1.3.55z'
    />
    <circle cx={52.3} cy={91.3} r={11.99} fill='rgb(var(--color-neutral-50))' />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M52.3 104.54c-7.3 0-13.24-5.94-13.24-13.24S45 78.06 52.3 78.06 65.54 84 65.54 91.3s-5.94 13.24-13.24 13.24zm0-23.99c-5.92 0-10.74 4.82-10.74 10.74s4.82 10.74 10.74 10.74 10.74-4.82 10.74-10.74-4.82-10.74-10.74-10.74z'
    />
    <circle cx={52.3} cy={91.3} r={3.1} fill='rgb(var(--color-brand-700))' />
    <circle
      cx={105.78}
      cy={91.3}
      r={11.99}
      fill='rgb(var(--color-neutral-50))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M105.78 104.54c-7.3 0-13.25-5.94-13.25-13.24s5.94-13.24 13.25-13.24S119.02 84 119.02 91.3s-5.94 13.24-13.24 13.24zm0-23.99c-5.92 0-10.75 4.82-10.75 10.74s4.82 10.74 10.75 10.74 10.74-4.82 10.74-10.74-4.82-10.74-10.74-10.74z'
    />
    <circle cx={105.78} cy={91.3} r={3.1} fill='rgb(var(--color-brand-700))' />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M49.77 80.63c-2.16 0-4.23-.42-6.03-1.26a1.82 1.82 0 0 1-.88-2.42 1.82 1.82 0 0 1 2.42-.88c2.34 1.09 5.4 1.22 8.38.35 2.98-.87 5.49-2.61 6.89-4.79.54-.85 1.67-1.1 2.52-.55.85.54 1.1 1.67.55 2.52-1.86 2.91-5.12 5.22-8.94 6.33-1.63.47-3.3.71-4.91.71zm57.91 0c2.16 0 4.23-.42 6.03-1.26a1.82 1.82 0 0 0 .88-2.42 1.82 1.82 0 0 0-2.42-.88c-2.34 1.09-5.4 1.22-8.38.35-2.98-.87-5.49-2.61-6.89-4.79-.54-.85-1.67-1.1-2.52-.55-.85.54-1.1 1.67-.55 2.52 1.86 2.91 5.12 5.22 8.94 6.33 1.63.47 3.3.71 4.91.71z'
    />
  </svg>
)
export default SvgEmoji16
