import * as Sentry from '@sentry/browser'
import _ from 'lodash'
import { filter } from 'rxjs/operators'

import {
  getMe,
  audioMutedByHostPredicate,
  videoMutedByHostPredicate,
} from 'app/state/utils'
import { FEATURE_NAMES } from 'constants/features-map'

import ROOM_SERVICES from 'app/services/media/room-services'
import roomMediaManager from 'app/services/media/room-media-manager'
import roomOutput from 'app/services/media/room-output'

import {
  ACTION_TYPES as CHANNEL_ROOM_ACTION_TYPES,
  STORE_NAME as CHANNEL_ROOM_STORE_NAME,
  selectors as channelRoomSelectors,
} from 'app/state/api/channels/channel-room.reducer'
import { ACTION_TYPES as DEVICES_ACTION_TYPES } from 'app/features/devices/devices.reducer'
import {
  ACTION_TYPES as GUEST_SETUP_ACTION_TYPES,
  STORE_NAME as GUEST_SETUP_STORE_NAME,
  selectors as guestSetupSelectors,
} from 'app/features/guest-setup/guest-setup.reducer'
import { ACTION_TYPES as SETTINGS_ACTION_TYPES } from 'app/features/settings/settings.reducer'
import {
  STORE_NAME as FLAGS_STORE_NAME,
  selectors as flagsSelectors,
} from 'app/features/flags/flags.reducer'
import { ACTION_TYPES as CONFERENCE_ACTION_TYPES } from 'app/features/conference/conference.reducer'

import { ACTION_TYPES, STORE_NAME, actions, selectors } from './media.reducer'

function bindRoomAudioTrack(track) {
  roomOutput.bindAudioTrack(track).catch((err) => {
    // Nothing we can do, but we make sure to keep track of the amount of times it fails
    Sentry.captureException(err)
  })
}

function handleToggleMicrophone(store) {
  store.dispatch(actions.microphoneToggleMute())
}

function handleToggleVideo(store) {
  store.dispatch(actions.cameraToggleMute())
}

function handleStoreInit(store) {
  // As the store only get initialized upon load of the entire application (page load),
  // there is no need to store the subscription and later unsubscribe.
  roomMediaManager.trackManager.tracks$[ROOM_SERVICES.EXTERNAL_AUDIO]
    .pipe(filter(Boolean))
    .subscribe(bindRoomAudioTrack)

  const { [FLAGS_STORE_NAME]: flags } = store.getState()

  // When the silenced mic detection feature is enabled, we need to listen to the
  // emission of the silenced observable to update the media state.
  if (
    flagsSelectors.isFeatureAvailable(
      FEATURE_NAMES.SILENCED_MIC_DETECTION,
      flags
    )
  ) {
    // As the store only get initialized upon load of the entire application (page load),
    // there is no need to store the subscription and later unsubscribe.
    roomMediaManager.silenced$.subscribe((silenced) => {
      // Even when the silenced state goes to undetermined (e.g. due to change of audio track),
      // we want to update the awfully quiet state to false. This hides the alert while the
      // observer recalculates the audibility of the new device.
      store.dispatch(actions.setAwfullyQuiet(!!silenced))
    })
  }

  // As the store only get initialized upon load of the entire application (page load),
  // there is no need to store the subscription and later unsubscribe.
  roomMediaManager.talking$.subscribe((isTalking) => {
    // Even when the talking state goes to undetermined (e.g. due to change of audio track),
    // we want to consider it as a 'stop talking' event.
    if (isTalking) {
      store.dispatch(actions.startTalking())
    } else {
      store.dispatch(actions.stopTalking())
    }
  })

  if (!roomOutput.playing) {
    store.dispatch(actions.audioPlayError())
  }

  roomOutput.addListener('play', () => store.dispatch(actions.audioPlay()))
  roomOutput.addListener('playerror', () =>
    store.dispatch(actions.audioPlayError())
  )
}

function handleOutDeviceMissing() {
  roomOutput.bindAudioDevice()
}

function handleChooseDevicesAndInitialiseDeviceConstraints(store, action) {
  if (action.payload.outDevice !== undefined) {
    roomOutput.bindAudioDevice(action.payload.outDevice).catch(() => {})
  }

  if (action.payload.micDevice !== undefined) {
    const { [STORE_NAME]: media } = store.getState()
    if (selectors.getAcknowledgeAwfullyQuiet(media)) {
      store.dispatch(actions.resetAcknowledgeAwfullyQuiet())
    }
  }
}

function handleMemberUpdated(store, action) {
  const { [STORE_NAME]: media, [CHANNEL_ROOM_STORE_NAME]: channelRoom } =
    store.getState()

  if (action.memberId === channelRoomSelectors.getMemberId(channelRoom)) {
    const me = getMe(channelRoom)

    const prevMicrophoneExternallyMutedState =
      selectors.getMicrophoneExternallyMuted(media)
    const nextMicrophoneExternallyMutedState = _.isMatch(
      me,
      audioMutedByHostPredicate
    )

    if (
      prevMicrophoneExternallyMutedState !== nextMicrophoneExternallyMutedState
    ) {
      store.dispatch(
        actions.setMicrophoneExternallyMuted(nextMicrophoneExternallyMutedState)
      )
    }

    const prevCameraExternallyMutedState =
      selectors.getCameraExternallyMuted(media)
    const nextCameraExternallyMutedState = _.isMatch(
      me,
      videoMutedByHostPredicate
    )

    if (prevCameraExternallyMutedState !== nextCameraExternallyMutedState) {
      store.dispatch(
        actions.setCameraExternallyMuted(nextCameraExternallyMutedState)
      )
    }
  }
}

function handleMemberMetaUpdate(store, action) {
  handleMemberUpdated(store, action)
}

function handleMemberRightsUpdate(store, action) {
  handleMemberUpdated(store, action)
}

function handleGuestSetupMetaUpdate(store) {
  const { [GUEST_SETUP_STORE_NAME]: guestSetup, [STORE_NAME]: media } =
    store.getState()

  const guestSetupMeta = guestSetupSelectors.getMeta(guestSetup)

  const guestSetupIsAudioMuted = !!guestSetupMeta.isAudioMuted
  const mediaIsAudioMuted = selectors.getMicrophoneMuted(media)

  if (guestSetupIsAudioMuted !== mediaIsAudioMuted) {
    store.dispatch(actions.setMicrophoneMute(guestSetupIsAudioMuted))
  }

  const guestSetupIsVideoMuted = !!guestSetupMeta.isVideoMuted
  const mediaIsVideoMuted = selectors.getCameraMuted(media)

  if (guestSetupIsVideoMuted !== mediaIsVideoMuted) {
    store.dispatch(actions.setCameraMute(guestSetupIsVideoMuted))
  }
}

function handleMicrophoneMuted(store) {
  const { [STORE_NAME]: media } = store.getState()

  if (
    selectors.getMicrophoneMuted(media) ||
    selectors.getMicrophoneExternallyMuted(media)
  ) {
    store.dispatch(actions.stopTalking())
  }
}

export default (store) => (next) => (action) => {
  switch (action.type) {
    case CONFERENCE_ACTION_TYPES.TOGGLE_MICROPHONE:
      handleToggleMicrophone(store)
      break

    case CONFERENCE_ACTION_TYPES.TOGGLE_VIDEO:
      handleToggleVideo(store)
      break

    default:
  }

  const nextState = next(action)

  switch (action.type) {
    case 'sm-web/INIT':
      handleStoreInit(store)
      break

    case DEVICES_ACTION_TYPES.OUT_DEVICE_MISSING:
      handleOutDeviceMissing()
      break

    case SETTINGS_ACTION_TYPES.INITIALISE_DEVICE_CONSTRAINTS:
    case DEVICES_ACTION_TYPES.CHOOSE_DEVICES:
      handleChooseDevicesAndInitialiseDeviceConstraints(store, action)
      break

    case CHANNEL_ROOM_ACTION_TYPES.UPDATED_MEMBER_META:
      handleMemberMetaUpdate(store, action)
      break

    case CHANNEL_ROOM_ACTION_TYPES.UPDATED_MEMBER_RIGHTS:
      handleMemberRightsUpdate(store, action)
      break

    case GUEST_SETUP_ACTION_TYPES.SET_META:
    case GUEST_SETUP_ACTION_TYPES.UPDATE_META:
      handleGuestSetupMetaUpdate(store)
      break

    case ACTION_TYPES.SET_MICROPHONE_MUTE:
    case ACTION_TYPES.MICROPHONE_TOGGLE_MUTE:
    case ACTION_TYPES.SET_MICROPHONE_EXTERNALLY_MUTED:
      handleMicrophoneMuted(store)
      break

    default:
  }

  return nextState
}
