import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import EventContent from '../event-content'

export const RoomEventContent = ({ messageKey }) => {
  const { t } = useTranslation()
  return (
    <EventContent>
      <div className='event-message'>
        <p className='room-event-message'>{t(`Message.${messageKey}`)}</p>
      </div>
    </EventContent>
  )
}

RoomEventContent.propTypes = {
  messageKey: PropTypes.string.isRequired,
}

export default RoomEventContent
