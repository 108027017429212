import _ from 'lodash'
import dotProp from 'dot-prop-immutable'

import localStore from 'app/services/state/local-store'
import urlUtils from 'lib/url-utils'

import { getSocket } from '../socket'

import { handleMessage, createStateLoggingActionHandler } from './utils'

const channelConf = {
  id: 'Summa.User.Api.Avatar',
  conf: { version: '3.0' },
}

export const STORE_NAME = 'channel/avatar'

/**
 * channel instance
 * @param
 */
let channel = null

// ------------------------------------
// Channel commands
// ------------------------------------
const GET_AVATAR_UPLOAD_LINK = 'get_avatar_upload_link'
const GET_AVATAR_DOWNLOAD_LINK = 'get_avatar_download_link'

export const COMMANDS = {
  GET_AVATAR_UPLOAD_LINK,
  GET_AVATAR_DOWNLOAD_LINK,
}

// ------------------------------------
// Actions
// ------------------------------------
const JOINING = `sm-web/${STORE_NAME}/JOINING`
const JOINED = `sm-web/${STORE_NAME}/JOINED`
const JOINING_ERROR = `sm-web/${STORE_NAME}/JOINING_ERROR`
const JOINING_TIMEOUT = `sm-web/${STORE_NAME}/JOINING_TIMEOUT`
const ERROR = `sm-web/${STORE_NAME}/ERROR`
const CLOSING = `sm-web/${STORE_NAME}/CLOSING`
const CLOSED = `sm-web/${STORE_NAME}/CLOSED`
const CLOSING_ERROR = `sm-web/${STORE_NAME}/CLOSING_ERROR`
const CLOSING_TIMEOUT = `sm-web/${STORE_NAME}/CLOSING_TIMEOUT`
const LOG = `sm-web/${STORE_NAME}/LOG`
const SET_AVATAR_UPLOAD_LINK = `sm-web/${STORE_NAME}/SET_AVATAR_UPLOAD_LINK`
const SET_AVATAR_DOWNLOAD_LINK = `sm-web/${STORE_NAME}/SET_AVATAR_DOWNLOAD_LINK`

export const ACTION_TYPES = {
  JOINING,
  JOINED,
  JOINING_ERROR,
  JOINING_TIMEOUT,
  ERROR,
  CLOSING,
  CLOSED,
  CLOSING_ERROR,
  CLOSING_TIMEOUT,
  LOG,
  SET_AVATAR_UPLOAD_LINK,
  SET_AVATAR_DOWNLOAD_LINK,
}

/**
 *
 * @param payload
 */
const joined = (payload) => ({ type: JOINED, payload })
/**
 *
 * @param error
 */
const joiningError = (error) => ({ type: JOINING_ERROR, error })
/**
 *
 */
const joiningTimeout = () => ({ type: JOINING_TIMEOUT })
/**
 *
 * @param e
 */
const error = (e) => ({ type: ERROR, error: e })
/**
 *
 * @param payload
 */
const closed = (payload) => ({ type: CLOSED, payload })
/**
 *
 * @param e
 */
const closingError = (e) => ({ type: CLOSING_ERROR, error: e })
/**
 *
 * @param event
 */
const closingTimeout = () => ({ type: CLOSING_TIMEOUT })
/**
 *
 */
const log = (event, payload) => (dispatch) => {
  if (localStore.getDebugMode()) {
    dispatch({ type: LOG, event, payload })
  }
}

const setAvatarUploadLink = (payload) => ({
  type: SET_AVATAR_UPLOAD_LINK,
  payload,
})

const setAvatarDownloadLink = (payload) => ({
  type: SET_AVATAR_DOWNLOAD_LINK,
  payload,
})

const getAvatarDownloadLink = async () =>
  new Promise((resolve, reject) => {
    channel
      .push(GET_AVATAR_DOWNLOAD_LINK, {})
      .receive('ok', resolve)
      .receive('error', (e) => {
        reject(new Error(JSON.stringify(e)))
      })
      .receive('timeout', () => {
        reject(new Error('Timeout'))
      })
  })

const getAvatarUploadLink = async () =>
  new Promise((resolve, reject) => {
    channel
      .push(GET_AVATAR_UPLOAD_LINK, {})
      .receive('ok', resolve)
      .receive('error', (e) => {
        reject(new Error(JSON.stringify(e)))
      })
      .receive('timeout', () => {
        reject(new Error('Timeout'))
      })
  })

// ------------------------------------
// Channel events
// ------------------------------------

const channelEventActionMapping = {}

/**
 * exported action to join channel
 */
const join = () => async (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: JOINING })
    const socket = getSocket()
    channel = socket.channel(channelConf.id, {
      ...channelConf.conf,
      refresh: true,
    })
    channel.onMessage = handleMessage(
      dispatch,
      channelEventActionMapping,
      channelConf,
      log
    )
    channel.onError((event) => dispatch(error(event)))
    channel.onClose((event) => dispatch(closed(event)))
    channel
      .join()
      .receive('ok', async (payload) => {
        dispatch(joined(payload))

        const downloadPayload = await getAvatarDownloadLink()
        dispatch(setAvatarDownloadLink(downloadPayload.link))
        const uploadPayload = await getAvatarUploadLink()
        dispatch(setAvatarUploadLink(uploadPayload.link))

        resolve(payload)
      })
      .receive('error', (e) => {
        dispatch(joiningError(e))
        reject(new Error(JSON.stringify(e)))
      })
      .receive('timeout', () => {
        dispatch(joiningTimeout())
        reject(new Error('Timeout'))
      })
  })
/**
 * exported action to leave the channel
 */
const leave = () => async (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: CLOSING })
    if (channel) {
      channel
        .leave()
        .receive('ok', () => {
          dispatch(closed())
          resolve()
        })
        .receive('error', (e) => {
          dispatch(closingError(e))
          reject(new Error(JSON.stringify(e)))
        })
        .receive('timeout', () => {
          dispatch(closingTimeout())
          reject(new Error('Timeout'))
        })
    } else {
      resolve()
    }
  })

/**
 *
 */
export const actions = {
  join,
  leave,
  setAvatarUploadLink,
  closed,
}
// ------------------------------------
// Reducer
// ------------------------------------
const INITIAL_STATE = {
  uploadLink: '',
  uploadLinkData: {},
  downloadLink: '',
  downloadLinkData: {},
  '@@channel': {
    state: CLOSED,
    log: [],
  },
}
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [JOINING]: createStateLoggingActionHandler(JOINING, 'JOINING'),
  [JOINED]: (state, action) => ({
    ...state,
    user_id: action.payload.user_id,
    ...createStateLoggingActionHandler(
      JOINED,
      'JOINED',
      'payload'
    )(state, action),
  }),
  [JOINING_ERROR]: createStateLoggingActionHandler(
    JOINING_ERROR,
    'JOINING_ERROR',
    'error'
  ),
  [JOINING_TIMEOUT]: createStateLoggingActionHandler(
    JOINING_TIMEOUT,
    'JOINING_TIMEOUT'
  ),
  [CLOSING]: createStateLoggingActionHandler(CLOSING, 'CLOSING'),
  [CLOSED]: createStateLoggingActionHandler(CLOSED, 'CLOSED'),
  [CLOSING_ERROR]: createStateLoggingActionHandler(
    CLOSING_ERROR,
    'CLOSING_ERROR',
    'error'
  ),
  [CLOSING_TIMEOUT]: createStateLoggingActionHandler(
    CLOSING_TIMEOUT,
    'CLOSING_TIMEOUT'
  ),
  [ERROR]: createStateLoggingActionHandler(ERROR, 'ERROR', 'error'),
  [LOG]: (state, action) =>
    dotProp.set(state, '@@channel.log', (l) => [
      ...l,
      {
        ts: Date.now(),
        kind: 'event',
        msg: action.event,
        data: action.payload,
      },
    ]),
  [SET_AVATAR_UPLOAD_LINK]: (state, action) => ({
    ...state,
    uploadLink: urlUtils.composeUrlFromLinkData(action.payload),
    uploadLinkData: {
      protocol: action.payload.protocol,
      address: action.payload.address,
      port: action.payload.port,
      path: action.payload.path,
    },
  }),
  [SET_AVATAR_DOWNLOAD_LINK]: (state, action) => ({
    ...state,
    downloadLink: urlUtils.composeUrlFromLinkData(action.payload),
    downloadLinkData: {
      protocol: action.payload.protocol,
      address: action.payload.address,
      port: action.payload.port,
      path: action.payload.path,
    },
  }),
  'sm-web/RESET': () => INITIAL_STATE,
}

export default (state = INITIAL_STATE, action = {}) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export const selectors = {
  getChannelState(state) {
    if (!state || typeof state !== 'object') {
      const err = new Error('Given state should be an object')
      err.state = state
      throw err
    }
    return _.get(state, '@@channel.state')
  },
  isJoining(state) {
    const channelState = selectors.getChannelState(state)
    return channelState === JOINING
  },
  isJoiningError(state) {
    const channelState = selectors.getChannelState(state)
    return channelState === JOINING_ERROR
  },
  isJoiningTimeout(state) {
    const channelState = selectors.getChannelState(state)
    return channelState === JOINING_TIMEOUT
  },
  isJoined(state) {
    const channelState = selectors.getChannelState(state)
    return channelState === JOINED
  },
  isClosing(state) {
    const channelState = selectors.getChannelState(state)
    return channelState === CLOSING
  },
  isClosed(state) {
    const channelState = selectors.getChannelState(state)
    return channelState === CLOSED
  },

  getUploadLinkData(state) {
    return state.uploadLinkData
  },
  getUploadLink(state) {
    return state.uploadLink
  },
  getDownloadLinkData(state) {
    return state.downloadLinkData
  },
  getDownloadLink(state) {
    return state.downloadLink
  },

  getUploadProtocol(state) {
    const linkData = selectors.getUploadLinkData(state)
    return linkData.protocol
  },
  getUploadAddress(state) {
    const linkData = selectors.getUploadLinkData(state)
    return linkData.address
  },
  getUploadPort(state) {
    const linkData = selectors.getUploadLinkData(state)
    return linkData.port
  },
  getUploadPath(state) {
    const linkData = selectors.getUploadLinkData(state)
    return linkData.path
  },

  getDownloadProtocol(state) {
    const linkData = selectors.getDownloadLinkData(state)
    return linkData.protocol
  },
  getDownloadAddress(state) {
    const linkData = selectors.getDownloadLinkData(state)
    return linkData.address
  },
  getDownloadPort(state) {
    const linkData = selectors.getDownloadLinkData(state)
    return linkData.port
  },
  getDownloadPath(state) {
    const linkData = selectors.getDownloadLinkData(state)
    return linkData.path
  },

  didJoiningFail(state) {
    const isJoiningError = selectors.isJoiningError(state)
    const isJoiningTimeout = selectors.isJoiningTimeout(state)

    return isJoiningError || isJoiningTimeout
  },
}
