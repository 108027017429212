import { Socket } from 'phoenix'
import _ from 'lodash'

let socket: Socket

/**
 * Disconnect the currently connected socket.
 */
export const disconnect = (cb?, code?, reason?) => {
  socket?.disconnect(cb, code, reason)
  socket = null
}

export const connect = (url, opts) => {
  if (socket) {
    disconnect()
  }
  socket = new Socket(url, _.pickBy(opts, Boolean))
  return socket
}

export const getSocket = (): (() => Socket) => socket
