import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { actions, STORE_NAME } from '../snackbar-queue.reducer'

import Snackbar from './snackbar.component'

export const mapStateToProps = ({ [STORE_NAME]: snackbarQueue }, { id }) => {
  if (!snackbarQueue.snackbars[id]) return {}
  return {
    autoHideDuration: snackbarQueue.autoHideDuration,
    ...snackbarQueue.snackbars[id].props,
  }
}

export const bindDispatchToProps = (dispatch) => ({
  onClose: bindActionCreators(actions.closeSnackbar, dispatch),
  onExited: bindActionCreators(actions.dequeueSnackbar, dispatch),
})

export default connect(mapStateToProps, bindDispatchToProps)(Snackbar)
