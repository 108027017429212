import TASK_STATUS from './coligo-task-status'

export class Task {
  name = 'Task'

  status = TASK_STATUS.PENDING

  constructor() {
    this.run = this.baseRun.bind(this, this.run)
    this.cancel = this.baseCancel.bind(this, this.cancel)
  }

  /**
   * @return
   */
  run() {}

  /**
   * @return
   */
  cancel() {}

  /**
   * @return
   */
  async baseRun(originalRun) {
    const startTime = Date.now()
    try {
      logger.debug(`running task ${this.name}`)

      switch (this.status) {
        case TASK_STATUS.RUNNING:
          throw new Error(`${this.name} is already running`)
        case TASK_STATUS.CANCELED:
          throw new Error(`${this.name} was canceled before running`)
        case TASK_STATUS.COMPLETED:
          throw new Error(`${this.name} was already completed`)
        default:
      }

      this.status = TASK_STATUS.RUNNING

      await originalRun.call(this)
      logger.debug(`${this.name} took ${Math.round(Date.now() - startTime)} ms`)
    } catch (err) {
      logger.debug(
        `failed task ${this.name} took ${Math.round(Date.now() - startTime)} ms`
      )
      throw err
    } finally {
      if (this.status !== TASK_STATUS.CANCELED) {
        this.status = TASK_STATUS.FINISHED
      }
    }
  }

  /**
   * @return
   */
  baseCancel(originalCancel) {
    if (
      this.status !== TASK_STATUS.CANCELED &&
      this.status !== TASK_STATUS.FINISHED
    ) {
      logger.debug(`canceling task ${this.name}`)
      this.status = TASK_STATUS.CANCELED

      originalCancel.call(this)
    }
  }
}

export default Task
