export { default as peerConnectionConfig } from './peer-connection.config'

export { default as RecorderState } from './recorder-state.enum'
export { default as RTCDegradationPreference } from './rtc-degradation-preference.enum'
export { default as RTCIceConnectionState } from './rtc-ice-connection-state.enum'
export { default as RTCIceGatheringState } from './rtc-ice-gathering-state.enum'
export { default as RTCPeerConnectionState } from './rtc-peer-connection-state.enum'
export { default as RTCPriorityType } from './rtc-priority-type.enum'
export { default as RTCSignalingState } from './rtc-signaling-state.enum'

export * from './sender-utils'

export enum MediaDeviceKind {
  audiooutput = 'audiooutput',
  audioinput = 'audioinput',
  videoinput = 'videoinput',
}
