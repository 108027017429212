import ColigoAudioMixer from 'lib/rtc/coligo-audio-mixer'
import ColigoTrack from 'lib/rtc/coligo-track'

/**
 * @class
 */
class RoomAudioMixer {
  /**
   * Error messages thrown by {@link module:Media.AudioMedia|AudioMedia}.
   *
   * @property {string} UNDEFINED_TRACK Error when an undefined track is given.
   * @property {string} INSTANCEOF_COLIGO_TRACK Error when the given track is not an instance of {@link ColigoTrack}.
   *
   * @alias module:Media.AudioMedia#AudioMediaErrors
   */
  static Errors = {
    UNDEFINED_TRACK: 'No track is given',
    INSTANCEOF_COLIGO_TRACK: 'Given track is not instance of ColigoTrack',
  }

  /**
   * AudioMixer which provides a track to publish to the audioroom.
   *
   * @protected
   */
  _audioMixer = new ColigoAudioMixer()

  /**
   * @type {ColigoTrack[]}
   */
  _addedTracks = []

  /**
   * The currently published ColigoTrack.
   *
   * @protected
   */
  _localTrack = null

  async init() {
    await this._audioMixer._initConnection()
    if (this._localTrack) {
      this.replaceLocalTrack(this._localTrack)
    }
  }

  close() {
    this._audioMixer.close()
  }

  async getOutput() {
    return this._audioMixer.getOutput()
  }

  getTrack() {
    return this._audioMixer.track
  }

  /**
   * Get tracks added to the audio mixer.
   * @return
   */
  getAddedTracks() {
    return this._addedTracks
  }

  /**
   * Add a track to the audio mixer.
   * @param track
   * @return
   */
  addTrack(track) {
    if (track && track instanceof ColigoTrack) {
      this._audioMixer.addTrack(track)

      if (!this._addedTracks.includes(track)) {
        this._addedTracks.push(track)
      }
    } else if (!track) {
      throw new Error(RoomAudioMixer.Errors.UNDEFINED_TRACK)
    } else {
      throw new Error(RoomAudioMixer.Errors.INSTANCEOF_COLIGO_TRACK)
    }
  }

  /**
   * Remove a track from the audio mixer.
   * @param track
   * @return
   */
  removeTrack(track) {
    if (track && track instanceof ColigoTrack) {
      this._audioMixer.removeTrack(track)

      const index = this._addedTracks.indexOf(track)
      if (index >= 0) {
        this._addedTracks.splice(index, 1)
      }
    } else if (!track) {
      throw new Error(RoomAudioMixer.Errors.UNDEFINED_TRACK)
    } else {
      throw new Error(RoomAudioMixer.Errors.INSTANCEOF_COLIGO_TRACK)
    }
  }

  /**
   * Remove the localTrack from the audioMixer.
   * @return
   */
  removeLocalTrack() {
    if (this._localTrack) {
      this.removeTrack(this._localTrack)
      this._localTrack = null
    }
  }

  /**
   * Replace the given track and replace with the second given track.
   * @param oldTrack
   * @param newTrack
   * @return
   */
  replaceTrack(oldTrack, newTrack) {
    if (oldTrack && newTrack) {
      this._audioMixer.replaceTrack(oldTrack, newTrack)

      const index = this._addedTracks.indexOf(oldTrack)
      if (index >= 0) {
        this._addedTracks.splice(index, 1)
      }

      if (!this._addedTracks.includes(newTrack)) {
        this._addedTracks.push(newTrack)
      }
    }
  }

  /**
   * @param track
   * @return
   */
  replaceLocalTrack(track) {
    if (track && track instanceof ColigoTrack) {
      if (this._localTrack) {
        this.replaceTrack(this._localTrack, track)
      } else {
        this.addTrack(track)
      }

      this._localTrack = track
    } else if (!track) {
      throw new Error(RoomAudioMixer.Errors.UNDEFINED_TRACK)
    } else {
      throw new Error(RoomAudioMixer.Errors.INSTANCEOF_COLIGO_TRACK)
    }
  }
}

export default RoomAudioMixer
