import _ from 'lodash'
import { AnyAction } from 'redux'
export const STORE_NAME = 'feature/noise-cancellation'

const INIT = `sm-web/${STORE_NAME}/INIT`
const ENABLE_NOISE_CANCELLATION = `sm-web/${STORE_NAME}/ENABLE_NOISE_CANCELLATION`
const DISABLE_NOISE_CANCELLATION = `sm-web/${STORE_NAME}/DISABLE_NOISE_CANCELLATION`

export const ACTION_TYPES = {
  INIT,
  ENABLE_NOISE_CANCELLATION,
  DISABLE_NOISE_CANCELLATION,
}
const enableNoiseCancellation = () => ({
  type: ENABLE_NOISE_CANCELLATION,
})
const disableNoiseCancellation = () => ({
  type: DISABLE_NOISE_CANCELLATION,
})

export const actions = {
  enableNoiseCancellation,
  disableNoiseCancellation,
}

const INITIAL_STATE = {
  enabled: false,
}

type StateType = typeof INITIAL_STATE

const ACTION_HANDLERS = {
  [ENABLE_NOISE_CANCELLATION]: (state: StateType) => ({
    ...state,
    enabled: true,
  }),
  [DISABLE_NOISE_CANCELLATION]: (state: StateType) => ({
    ...state,
    enabled: false,
  }),
}

// ------------------------------------
// Reducer
// ------------------------------------
const reducer = (
  state: StateType = INITIAL_STATE,
  action: AnyAction
): StateType => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
export default reducer

export const selectors = {
  getNoiseCancellationEnabled(state: StateType): boolean {
    return _.get(state, 'enabled')
  },
}
