import { MediaStreamEffect } from '@enreach/person-segmentation'

class BackgroundEffectSingleton {
  async build(drawingStrategy, frameProcessingStrategy) {
    if (!this.mediaStreamEffect) {
      this.mediaStreamEffect = await MediaStreamEffect.build(
        undefined,
        undefined,
        drawingStrategy,
        frameProcessingStrategy
      )
    }

    return this.mediaStreamEffect
  }
}

export default new BackgroundEffectSingleton()
