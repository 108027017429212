import { Icon } from 'ui/revision'

const SidePanelClosedIcon = (props) => (
  <Icon {...props}>
    <svg>
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g>
          <polygon points='0 0 24 0 24 24 0 24' />
          <g
            stroke='#FFFFFF'
            strokeWidth='2'
            transform='translate(2.000000, 3.000000)'
          >
            <rect height='16.5714286' rx='1' width='18' x='1' y='1' />
            <path
              d='M13.1071429,2.14285714 L13.1071429,16.4285714'
              strokeLinecap='square'
            />
          </g>
        </g>
      </g>
    </svg>
  </Icon>
)

export default SidePanelClosedIcon
