import { Icon } from 'ui/revision'

const StickerIcon = (props) => (
  <Icon {...props}>
    <svg viewBox='0 0 20 20'>
      <path
        d='M9.99 0C15.521 0 20 4.48 20 10c0 .399-.029.79-.075 1.177-4.797.233-8.664 4.015-9.031 8.778-.298.026-.599.045-.904.045C4.47 20 0 15.521 0 10 0 4.48 4.47 0 9.99 0zm9.683 12.858c-.964 3.439-3.72 6.124-7.203 6.993.463-3.71 3.46-6.635 7.203-6.993zm-6.883-1.11H4.925c.721 1.837 2.503 3.151 4.601 3.151.188 0 .37-.022.553-.044.67-1.221 1.602-2.277 2.711-3.107zm.71-5.893c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm-7 0c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5c.831 0 1.5-.67 1.5-1.5s-.669-1.5-1.5-1.5z'
        fillRule='evenodd'
      />
    </svg>
  </Icon>
)

export default StickerIcon
