import Task from 'lib/tasks/coligo-task'

import {
  STORE_NAME as CHANNEL_SETTINGS_STORE_NAME,
  actions as channelSettingsActions,
  selectors as channelSettingsSelectors,
} from 'app/state/api/channels/channel-settings.reducer'

/**
 * @class
 * @classdesc JoinSettingsChannelTask joins the settings channel
 */
class JoinSettingsChannelTask extends Task {
  name = 'JoinSettingsChannelTask'

  store

  _unsubscribe

  cancelPromise

  constructor(store) {
    super()

    this.store = store
  }

  /**
   * isStateValid checks whether the settings has been received from the channel.
   * @return
   */
  isStateValid() {
    const currentState = this.store.getState()
    return channelSettingsSelectors.hasReceivedSettings(
      currentState[CHANNEL_SETTINGS_STORE_NAME]
    )
  }

  async run() {
    await new Promise(async (resolve, reject) => {
      this.cancelPromise = (err) => {
        reject(new Error(err))
      }

      try {
        await this.store.dispatch(channelSettingsActions.join())

        // After joining we wan't to resolve the promise when the store contains the settings from the channel.
        if (this.isStateValid()) {
          resolve()
        } else {
          this._unsubscribe = this.store.subscribe(() => {
            if (this.isStateValid()) {
              this._unsubscribe()
              resolve()
            }
          })
        }
      } catch (err) {
        reject(err)
      }
    })
  }

  cancel() {
    if (this._unsubscribe) {
      this._unsubscribe()
    }
    if (this.cancelPromise) {
      this.cancelPromise(
        `JoinSettingsChannelTask is canceled while is was joining the settings channel and waiting for the list of settings to be received`
      )
    }
  }
}

export default JoinSettingsChannelTask
