import _ from 'lodash'

import localStore from 'app/services/state/local-store'
import browserUtils from 'lib/browser-utils'
import { AnyAction } from 'redux'

export const STORE_NAME = 'feature/stickers'

const INIT = `sm-web/${STORE_NAME}/INIT`
const INITIALIZE_STICKERS = `sm-web/${STORE_NAME}/INITIALIZE_STICKERS`
const SET_CURRENT_CATEGORY = `sm-web/${STORE_NAME}/SET_CURRENT_CATEGORY`
const UPDATE_RECENTLY_USED = `sm-web/${STORE_NAME}/UPDATE_RECENTLY_USED`
const SET_PINNED = `sm-web/${STORE_NAME}/SET_PINNED`

export const ACTION_TYPES = {
  INIT,
  INITIALIZE_STICKERS,
  SET_CURRENT_CATEGORY,
  UPDATE_RECENTLY_USED,
  SET_PINNED,
}

const initializeStickers = ({
  stickersByCategory,
  categories,
}: {
  stickersByCategory: Record<string, string[]>
  categories: string[]
}): AnyAction => ({
  type: INITIALIZE_STICKERS,
  stickersByCategory,
  categories,
})

const setCurrentCategory = (categoryIndex) => ({
  type: SET_CURRENT_CATEGORY,
  categoryIndex,
})

const updateRecentlyUsed = (recentlyUsed) => ({
  type: UPDATE_RECENTLY_USED,
  recentlyUsed,
})

const setPinned = (payload) => ({
  type: SET_PINNED,
  payload,
})

export const actions = {
  initializeStickers,
  setCurrentCategory,
  updateRecentlyUsed,
  setPinned,
}

const INITIAL_STATE = {
  stickersByCategory: {},
  categories: [],
  currentCategory: 1,
  pinned: false,
}

const ACTION_HANDLERS = {
  [INITIALIZE_STICKERS]: (state, action) => {
    const recentlyUsed = localStore.getRecentlyUsedStickers()
    const recentlyUsedCount = recentlyUsed.length > 0 ? 0 : 1

    const currentCategory = action.categories.length > 1 ? recentlyUsedCount : 0

    return {
      ...state,
      stickersByCategory: {
        recently_used: recentlyUsed,
        ...action.stickersByCategory,
      },
      categories: action.categories,
      currentCategory,
    }
  },
  [SET_CURRENT_CATEGORY]: (state, action) => ({
    ...state,
    currentCategory: action.categoryIndex,
  }),

  [UPDATE_RECENTLY_USED]: (state, action) => ({
    ...state,
    stickersByCategory: {
      ...state.stickersByCategory,
      recently_used: action.recentlyUsed,
    },
  }),
  [SET_PINNED]: (state, action) => ({
    ...state,
    pinned: action.payload,
  }),
  'sm-web/RESET': () => INITIAL_STATE,
}

export default (state = INITIAL_STATE, action = {}) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export const selectors = {
  getStickersByCategory(state) {
    return _.get(state, 'stickersByCategory')
  },
  getCategories(state) {
    return _.get(state, 'categories')
  },
  getCategoryBySticker(state, sticker) {
    let allStickers = { ...state }
    if (_.has(state, 'stickersByCategory')) {
      allStickers = { ...selectors.getStickersByCategory(state) }
    }

    delete allStickers.recently_used

    return _.findKey(allStickers, (category) => category.includes(sticker))
  },
  getCurrentCategory(state) {
    return _.get(state, 'currentCategory')
  },
  getIndexOfCategory(state, category) {
    const categories = selectors.getCategories(state)
    return _.indexOf(categories, category)
  },
  getRecentlyUsed(state) {
    return _.get(state, 'stickersByCategory.recently_used')
  },
  getNextRecentlyUsed(state, sticker) {
    const isMobile = browserUtils.isMobile()
    const recentlyUsed = [
      sticker,
      ..._.pull([...selectors.getRecentlyUsed(state)], sticker),
    ]

    return _.slice(
      recentlyUsed,
      0,
      Math.min(recentlyUsed.length, isMobile ? 12 : 10)
    )
  },
  getPinnedState(state) {
    return _.get(state, 'pinned')
  },
}
