import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { makeStyles } from 'app/ui'
import FlagIcon from 'ui/flag-icon'
import Link from 'ui/link'
import { Table } from 'ui/revision'

const useStyles = makeStyles(() => ({
  root: {
    '--table-max-height': '100%',
  },
}))

type Props = {
  countries: Array<{
    defaultLanguage: string
    isoCode: string
    name: string
    number: string
  }>
  roomPin: string
}

const DialInCountriesTable = ({
  countries = [],
  roomPin = '',
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const suffix = roomPin ? `,,,${roomPin}#` : ''

  return (
    <Table className={classes.root} zebra>
      <table>
        <thead>
          <tr>
            <td>{t('DialInPage.CountriesTable.country', 'Country')}</td>
            <td>{t('DialInPage.CountriesTable.number', 'Nummer')}</td>
            <td>
              {t(
                'DialInPage.CountriesTable.defaultLanguage',
                'Standaard taal IVR'
              )}
            </td>
          </tr>
        </thead>
        <tbody>
          {countries.map((country, key) => (
            <tr key={key}>
              <td>
                <Grid container alignItems='center' spacing={1}>
                  {country.isoCode && (
                    <Grid item md={2} sm={3} xs={4}>
                      <FlagIcon
                        countryCode={country.isoCode}
                        title={country.name}
                      />
                    </Grid>
                  )}
                  <Grid item md={10} sm={9} xs={8}>
                    {country.name}
                  </Grid>
                </Grid>
              </td>
              <td>
                <Link to={`tel:${country.number}${suffix}`}>
                  {country.number}
                </Link>
              </td>
              <td>{country.defaultLanguage}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Table>
  )
}

export default DialInCountriesTable
