import _ from 'lodash'
import localStore from 'app/services/state/local-store'

export const STORE_NAME = 'feature/desktop-notification'

const CREATED_NOTIFICATION = `sm-web/${STORE_NAME}/CREATE_NOTIFICATION`
const PERMISSION_REQUESTED = `sm-web/${STORE_NAME}/PERMISSION_REQUESTED`
const DONT_REMIND_AGAIN = `sm-web/${STORE_NAME}/DONT_REMIND_AGAIN`

export const ACTION_TYPES = {
  CREATED_NOTIFICATION,
  PERMISSION_REQUESTED,
  DONT_REMIND_AGAIN,
}

const createdNotification = (notification) => ({
  type: CREATED_NOTIFICATION,
  payload: notification,
})
const setPermissionRequested = (requested) => ({
  type: PERMISSION_REQUESTED,
  payload: requested,
})
const setDontRemindAgain = (dontRemindAgain) => ({
  type: DONT_REMIND_AGAIN,
  payload: dontRemindAgain,
})

export const actions = {
  createdNotification,
  setPermissionRequested,
  setDontRemindAgain,
}

const INITIAL_STATE = {
  permissionRequested: false,
  dontRemindAgain: localStore.getDesktopNotificationsDontRemindAgain(),
}

const ACTION_HANDLERS = {
  [PERMISSION_REQUESTED]: (state, action) => ({
    ...state,
    permissionRequested: action.payload,
  }),
  [DONT_REMIND_AGAIN]: (state, action) => ({
    ...state,
    dontRemindAgain: action.payload,
  }),
  'sm-web/RESET': () => INITIAL_STATE,
}

export default (state = INITIAL_STATE, action = {}) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export const selectors = {
  getPermissionRequested(state) {
    return _.get(state, 'permissionRequested')
  },
  getDontRemindAgain(state) {
    return _.get(state, 'dontRemindAgain')
  },
}
