import cn from 'clsx'

import { ComponentType, SVGProps } from 'react'

import { useId } from 'app/react/hooks/id-registry.hooks'
import { makeStyles } from 'app/ui'
import stickers from 'ui/stickers'

const useStyles = makeStyles(() => ({
  root: {
    flex: '1 0 auto',
    filter: 'drop-shadow(0 8px 8px rgb(0, 0, 0, 0.4))',
    userSelect: 'none',
  },
}))

type Props = {
  className?: string
  src: string
} & SVGProps<SVGSVGElement>

const SVGSticker = ({ className, src, ...svgProps }: Props): JSX.Element => {
  const { id } = useId()
  const classes = useStyles()
  const Component = stickers[src] as ComponentType<SVGProps<SVGSVGElement>>

  return (
    <Component
      {...svgProps}
      className={cn(className, classes.root)}
      data-testid={id('Stickers.stickerId')}
    />
  )
}

export default SVGSticker
