import {
  SynchronousFrameProcessingStrategy,
  AsynchronousFrameProcessingStrategy,
} from '@enreach/person-segmentation'
import { BehaviorSubject } from 'rxjs'
import _ from 'lodash'

import env from 'app/config/env'
import coligoMediaDevices from 'lib/rtc/coligo-media-devices'
import coligoTrackFactory from 'lib/rtc/coligo-track-factory'
import ColigoTrackProcessor from 'lib/rtc/coligo-track-processor'
import NoiseCancellationProcessor from 'lib/rtc/processors/noise-cancellation-processor'
import BackgroundEffectProcessor from 'lib/rtc/processors/background-effect-processor'
import browserUtils from 'lib/browser-utils'

import ROOM_SERVICES from './room-services'
import ColigoTrack from 'lib/rtc/coligo-track'

class TrackManager {
  /**
   * @type {{ [key: string]: Object }}
   */
  localDefaultConstraints = {
    [ROOM_SERVICES.ROOM_AUDIO]: {
      autoGainControl: true,
      echoCancellation: true,
      noiseSuppression: false,
    },
    [ROOM_SERVICES.ROOM_VIDEO]: {
      frameRate: env('room_video_framerate', 15),
      ...(!browserUtils.isMobileTouchDevice()
        ? {
            width: env('room_video_width', 480),
            height: env('room_video_height', 960),
          }
        : {}),
    },
    [ROOM_SERVICES.ROOM_SCREEN]: browserUtils.isElectron()
      ? { mandatory: { chromeMediaSource: 'desktop' } }
      : {},
    [ROOM_SERVICES.DEVICE_PICKER_AUDIO]: {
      autoGainControl: true,
      echoCancellation: true,
      noiseSuppression: false,
    },
    [ROOM_SERVICES.DEVICE_PICKER_VIDEO]: {
      frameRate: env('room_video_framerate', 15),
      ...(!browserUtils.isMobileTouchDevice()
        ? {
            width: env('room_video_width', 480),
            height: env('room_video_height', 960),
          }
        : {}),
    },
  }

  /**
   * @type {{ [key: string]: import('rxjs').BehaviorSubject<import('lib/rtc/coligo-track').default | null> }}
   */
  source$ = {
    [ROOM_SERVICES.ROOM_AUDIO]: new BehaviorSubject(null),
    [ROOM_SERVICES.ROOM_VIDEO]: new BehaviorSubject(null),
    [ROOM_SERVICES.ROOM_SCREEN]: new BehaviorSubject(null),
    [ROOM_SERVICES.DEVICE_PICKER_AUDIO]: new BehaviorSubject(null),
    [ROOM_SERVICES.DEVICE_PICKER_VIDEO]: new BehaviorSubject(null),
    [ROOM_SERVICES.VIDEO_STREAMER_AUDIO]: new BehaviorSubject(null),
    [ROOM_SERVICES.SCREEN_SHARING_AUDIO]: new BehaviorSubject(null),
    [ROOM_SERVICES.EXTERNAL_AUDIO]: new BehaviorSubject(null),
  }

  /**
   * @type {{ [key: string]: ColigoTrackProcessor | NoiseCancellationProcessor | BackgroundEffectProcessor }}
   */
  processors = {
    [ROOM_SERVICES.ROOM_AUDIO]: new NoiseCancellationProcessor(false),
    [ROOM_SERVICES.ROOM_VIDEO]: new BackgroundEffectProcessor(false, {
      constraints: env('background_effects.resolution', {
        width: 640,
        height: 360,
      }),
      frameProcessingStrategy: env(
        'background_effects.asyncFrameProcessing',
        true
      )
        ? new AsynchronousFrameProcessingStrategy()
        : new SynchronousFrameProcessingStrategy(),
    }),
    [ROOM_SERVICES.ROOM_SCREEN]: new ColigoTrackProcessor(),
    [ROOM_SERVICES.DEVICE_PICKER_AUDIO]: new ColigoTrackProcessor(),
    [ROOM_SERVICES.DEVICE_PICKER_VIDEO]: new BackgroundEffectProcessor(false, {
      constraints: env('background_effects.resolution', {
        width: 640,
        height: 360,
      }),
      frameProcessingStrategy: env(
        'background_effects.asyncFrameProcessing',
        true
      )
        ? new AsynchronousFrameProcessingStrategy()
        : new SynchronousFrameProcessingStrategy(),
    }),
    [ROOM_SERVICES.VIDEO_STREAMER_AUDIO]: new ColigoTrackProcessor(),
    [ROOM_SERVICES.SCREEN_SHARING_AUDIO]: new ColigoTrackProcessor(),
    [ROOM_SERVICES.EXTERNAL_AUDIO]: new ColigoTrackProcessor(),
  }

  tracks$: Record<string, BehaviorSubject<ColigoTrack | null>> = {}

  constructor() {
    const services = [
      ROOM_SERVICES.ROOM_AUDIO,
      ROOM_SERVICES.ROOM_VIDEO,
      ROOM_SERVICES.ROOM_SCREEN,
      ROOM_SERVICES.DEVICE_PICKER_AUDIO,
      ROOM_SERVICES.DEVICE_PICKER_VIDEO,
      ROOM_SERVICES.VIDEO_STREAMER_AUDIO,
      ROOM_SERVICES.SCREEN_SHARING_AUDIO,
      ROOM_SERVICES.EXTERNAL_AUDIO,
    ]

    for (
      let i = 0, service = services[i];
      i < services.length;
      i += 1, service = services[i]
    ) {
      this.initService(service)
    }
  }

  initService(service) {
    this.tracks$[service] = this.processors[service].out$

    this.source$[service].subscribe(
      this.processors[service].setIn.bind(this.processors[service])
    )
  }

  enableBackgroundEffect() {
    this.processors[ROOM_SERVICES.ROOM_VIDEO].setEnabled(true)
    this.processors[ROOM_SERVICES.DEVICE_PICKER_VIDEO].setEnabled(true)
  }

  disableBackgroundEffect() {
    this.processors[ROOM_SERVICES.ROOM_VIDEO].setEnabled(false)
    this.processors[ROOM_SERVICES.DEVICE_PICKER_VIDEO].setEnabled(false)
  }

  getLocalDefaultConstraints(service, constraints = {}) {
    if (!service) return {}
    if (!constraints) return {}
    return _.merge(this.localDefaultConstraints[service], constraints)
  }

  getLocalAudioConstraints(constraints = {}) {
    if (!constraints) return {}
    return this.getLocalDefaultConstraints(
      ROOM_SERVICES.ROOM_AUDIO,
      constraints
    )
  }

  getLocalVideoConstraints(constraints = {}) {
    if (!constraints) return {}
    return this.getLocalDefaultConstraints(
      ROOM_SERVICES.ROOM_VIDEO,
      constraints
    )
  }

  getLocalScreenConstraints(constraints = {}) {
    if (!constraints) return {}
    return this.getLocalDefaultConstraints(
      ROOM_SERVICES.ROOM_SCREEN,
      constraints
    )
  }

  getDevicePickerAudioConstraints(constraints = {}) {
    if (!constraints) return {}
    return this.getLocalDefaultConstraints(
      ROOM_SERVICES.DEVICE_PICKER_AUDIO,
      constraints
    )
  }

  getDevicePickerVideoConstraints(constraints = {}) {
    if (!constraints) return {}
    return this.getLocalDefaultConstraints(
      ROOM_SERVICES.DEVICE_PICKER_VIDEO,
      constraints
    )
  }

  setLocalTrack(service, track) {
    if (!service) return
    if (!track) return
    this.source$[service].next(track)

    track.addEventListener('ended', () => {
      logger.debug(`${service} track ended`)
      this.stopLocalTrack(service)
    })
  }

  setVideoStreamerAudioTrack(track) {
    if (!track) return
    this.setLocalTrack(ROOM_SERVICES.VIDEO_STREAMER_AUDIO, track)
  }

  setScreenSharingAudioTrack(track) {
    if (!track) return
    this.setLocalTrack(ROOM_SERVICES.SCREEN_SHARING_AUDIO, track)
  }

  setLocalAudioTrack(track) {
    if (!track) return
    this.setLocalTrack(ROOM_SERVICES.ROOM_AUDIO, track)
  }

  setLocalVideoTrack(track) {
    if (!track) return
    this.setLocalTrack(ROOM_SERVICES.ROOM_VIDEO, track)
  }

  setLocalScreenTrack(track) {
    if (!track) return
    this.setLocalTrack(ROOM_SERVICES.ROOM_SCREEN, track)
  }

  setExternalAudioTrack(track) {
    if (!track) return
    this.setLocalTrack(ROOM_SERVICES.EXTERNAL_AUDIO, track)
  }

  async createAudioAndVideoTracks(constraints) {
    const stream = await coligoMediaDevices.getUserMedia(constraints)

    const tracks = { audio: null, video: null }
    const audioTracks = stream.getAudioTracks()
    if (audioTracks.length) {
      tracks.audio = coligoTrackFactory.createTrack(
        coligoTrackFactory.TYPES.LOCAL,
        {
          track: audioTracks[0],
          stream: new MediaStream(audioTracks),
          originalStream: stream,
        }
      )
    }

    const videoTracks = stream.getVideoTracks()
    if (videoTracks.length) {
      tracks.video = coligoTrackFactory.createTrack(
        coligoTrackFactory.TYPES.LOCAL,
        {
          track: videoTracks[0],
          stream: new MediaStream(videoTracks),
          originalStream: stream,
        }
      )
    }

    return tracks
  }

  async createLocalAudioAndVideoTracks(audioConstraints, videoConstraints) {
    if (!audioConstraints && !videoConstraints) return null

    let audioCon = false

    if (audioConstraints) {
      audioCon = this.getLocalAudioConstraints(audioConstraints)
      if (this.getLocalAudioSourceTrack()) {
        this.stopLocalAudioTrack()
      }
    }

    let videoCon = false

    if (videoConstraints) {
      videoCon = this.getLocalVideoConstraints(videoConstraints)
      if (this.getLocalVideoSourceTrack()) {
        this.stopLocalVideoTrack()
      }
    }

    const constraints = { audio: audioCon, video: videoCon }
    const tracks = await this.createAudioAndVideoTracks(constraints)

    if (tracks.audio) {
      this.setLocalTrack(ROOM_SERVICES.ROOM_AUDIO, tracks.audio)
    }

    if (tracks.video) {
      this.setLocalTrack(ROOM_SERVICES.ROOM_VIDEO, tracks.video)
    }

    return tracks
  }

  /**
   * Creates and sets new device picker audio and/or video track(s) with supplied `constraints`.
   * @param constraints Constraints to create the track(s) with.
   */
  async createDevicePickerAudioAndVideoTracks(
    audioConstraints,
    videoConstraints
  ) {
    if (!audioConstraints && !videoConstraints) return

    let audioCon = false
    let videoCon = false
    if (audioConstraints) {
      audioCon = this.getDevicePickerAudioConstraints(audioConstraints)
      if (this.getDevicePickerAudioSourceTrack()) {
        this.stopDevicePickerAudioTrack()
      }
    }
    if (videoConstraints) {
      videoCon = this.getDevicePickerVideoConstraints(videoConstraints)
      if (this.getDevicePickerVideoSourceTrack()) {
        this.stopDevicePickerVideoTrack()
      }
    }

    const constraints = { audio: audioCon, video: videoCon }
    const tracks = await this.createAudioAndVideoTracks(constraints)

    if (tracks.audio) {
      this.setLocalTrack(ROOM_SERVICES.DEVICE_PICKER_AUDIO, tracks.audio)
    }
    if (tracks.video) {
      this.setLocalTrack(ROOM_SERVICES.DEVICE_PICKER_VIDEO, tracks.video)
    }
  }

  async createLocalAudioTrack(constraints = {}) {
    if (!constraints) return null
    if (this.getLocalAudioSourceTrack()) {
      this.stopLocalAudioTrack()
    }
    const con = { audio: this.getLocalAudioConstraints(constraints) }
    const stream = await coligoMediaDevices.getUserMedia(con)
    const tracks = stream.getAudioTracks()

    if (!tracks.length) return null
    const track = coligoTrackFactory.createTrack(
      coligoTrackFactory.TYPES.LOCAL,
      {
        track: tracks[0],
        stream: new MediaStream(tracks),
        originalStream: stream,
      }
    )
    this.setLocalTrack(ROOM_SERVICES.ROOM_AUDIO, track)
    return tracks
  }

  async createLocalVideoTrack(constraints = {}) {
    if (!constraints) return null
    if (this.getLocalVideoSourceTrack()) {
      this.stopLocalVideoTrack()
    }
    const con = { video: this.getLocalVideoConstraints(constraints) }
    const stream = await coligoMediaDevices.getUserMedia(con)

    const tracks = stream.getVideoTracks()
    if (!tracks.length) return null
    const track = coligoTrackFactory.createTrack(
      coligoTrackFactory.TYPES.LOCAL,
      {
        track: tracks[0],
        stream: new MediaStream(tracks),
        originalStream: stream,
      }
    )
    this.setLocalTrack(ROOM_SERVICES.ROOM_VIDEO, track)
    return track
  }

  async createLocalScreenTrack(constraints = {}) {
    if (!constraints) return null
    if (this.getLocalScreenTrack()) {
      this.stopLocalScreenTrack()
    }
    const con = {
      /**
       * Enables audio option in browsers only.
       * NOTE: Electron breaks when this option is true.
       */
      audio: !browserUtils.isElectron(),
      video: this.getLocalScreenConstraints(constraints),
    }
    try {
      const stream = await (browserUtils.isElectron()
        ? coligoMediaDevices.getUserMedia(con)
        : coligoMediaDevices.getDisplayMedia(con))

      const tracks = { video: null, audio: null }

      const videoTracks = stream.getVideoTracks()
      if (!videoTracks.length) return null
      tracks.video = coligoTrackFactory.createTrack(
        coligoTrackFactory.TYPES.LOCAL,
        {
          track: videoTracks[0],
          stream: new MediaStream(videoTracks),
          originalStream: stream,
        }
      )

      const audioTracks = stream.getAudioTracks()
      // If we get an audio track
      if (audioTracks.length) {
        tracks.audio = coligoTrackFactory.createTrack(
          coligoTrackFactory.TYPES.LOCAL,
          {
            track: audioTracks[0],
            stream: new MediaStream(audioTracks),
            originalStream: stream,
          }
        )
      }

      this.setLocalScreenTrack(tracks.video)
      this.setScreenSharingAudioTrack(tracks.audio)
      return tracks
    } catch (err) {
      throw new Error(`${err.message}\nConstraints: ${JSON.stringify(con)}`)
    }
  }

  stopLocalTrack(service) {
    if (!service) return
    if (!this.source$[service]) return
    if (!this.source$[service].getValue()) return
    this.source$[service].getValue().stop()
    this.source$[service].next(null)
  }

  stopLocalAudioTrack() {
    this.stopLocalTrack(ROOM_SERVICES.ROOM_AUDIO)
  }

  stopLocalVideoTrack() {
    this.stopLocalTrack(ROOM_SERVICES.ROOM_VIDEO)
  }

  stopLocalScreenTrack() {
    this.stopLocalTrack(ROOM_SERVICES.ROOM_SCREEN)
  }

  stopDevicePickerAudioTrack() {
    this.stopLocalTrack(ROOM_SERVICES.DEVICE_PICKER_AUDIO)
  }

  stopDevicePickerVideoTrack() {
    this.stopLocalTrack(ROOM_SERVICES.DEVICE_PICKER_VIDEO)
  }

  stopVideoStreamerAudioTrack() {
    this.stopLocalTrack(ROOM_SERVICES.VIDEO_STREAMER_AUDIO)
  }

  stopScreenSharingAudioTrack() {
    this.stopLocalTrack(ROOM_SERVICES.SCREEN_SHARING_AUDIO)
  }

  getLocalSourceTrack(service) {
    if (!service) return null
    if (!this.source$[service]) return null
    return this.source$[service].getValue()
  }

  getLocalAudioSourceTrack() {
    return this.getLocalSourceTrack(ROOM_SERVICES.ROOM_AUDIO)
  }

  getLocalVideoSourceTrack() {
    return this.getLocalSourceTrack(ROOM_SERVICES.ROOM_VIDEO)
  }

  getLocalScreenSourceTrack() {
    return this.getLocalSourceTrack(ROOM_SERVICES.ROOM_SCREEN)
  }

  getDevicePickerAudioSourceTrack() {
    return this.getLocalSourceTrack(ROOM_SERVICES.DEVICE_PICKER_AUDIO)
  }

  getDevicePickerVideoSourceTrack() {
    return this.getLocalSourceTrack(ROOM_SERVICES.DEVICE_PICKER_VIDEO)
  }

  getVideoStreamerAudioSourceTrack() {
    return this.getLocalSourceTrack(ROOM_SERVICES.VIDEO_STREAMER_AUDIO)
  }

  getLocalTrack(service) {
    if (!service) return null
    if (!this.processors[service]) return null
    if (!this.processors[service]._outSubject) return null
    return this.processors[service]._outSubject.getValue()
  }

  getLocalAudioTrack() {
    return this.getLocalTrack(ROOM_SERVICES.ROOM_AUDIO)
  }

  getLocalVideoTrack() {
    return this.getLocalTrack(ROOM_SERVICES.ROOM_VIDEO)
  }

  getLocalScreenTrack() {
    return this.getLocalTrack(ROOM_SERVICES.ROOM_SCREEN)
  }

  getDevicePickerAudioTrack() {
    return this.getLocalTrack(ROOM_SERVICES.DEVICE_PICKER_AUDIO)
  }

  getDevicePickerVideoTrack() {
    return this.getLocalTrack(ROOM_SERVICES.DEVICE_PICKER_VIDEO)
  }

  getVideoStreamerAudioTrack() {
    return this.getLocalTrack(ROOM_SERVICES.VIDEO_STREAMER_AUDIO)
  }

  disableLocalTrack(service) {
    if (!service) return
    const localTrack = this.getLocalTrack(service)
    if (!localTrack) return
    localTrack.disable()
  }

  disableLocalAudioTrack() {
    this.disableLocalTrack(ROOM_SERVICES.ROOM_AUDIO)
  }

  disableDevicePickerAudioTrack() {
    this.disableLocalTrack(ROOM_SERVICES.DEVICE_PICKER_AUDIO)
  }

  enableLocalTrack(service) {
    if (!service) return
    const localTrack = this.getLocalTrack(service)
    if (!localTrack) return
    localTrack.enable()
  }

  enableLocalAudioTrack() {
    this.enableLocalTrack(ROOM_SERVICES.ROOM_AUDIO)
  }

  enableDevicePickerAudioTrack() {
    this.enableLocalTrack(ROOM_SERVICES.DEVICE_PICKER_AUDIO)
  }
}

export default new TrackManager()
