import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'

import env from 'app/config/env'
import { makeStyles } from 'app/ui'
import { Typography } from 'ui/revision'

import DialInCountriesTable from './dial-in-countries-table.component'
import DialInInstructionsTable from './dial-in-instructions-table.component'

const useStyles = makeStyles(
  {
    root: {
      height: '100%',
      rowGap: 'var(--spacing-1)',
    },
    body: {
      overflowX: 'hidden',
      overflowY: 'auto',
    },
  },
  { name: 'dial-in-info' }
)

type Props = {
  countries?: any[]
  product?: string
  meetingIdentifier?: string
}

const DialInInfo: React.FC<Props> = ({
  countries = Object.values(env('countries', {})),
  product = env('brand_config.longProductName', 'Meetings') as string,
  meetingIdentifier,
}: Props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Grid container direction='column' className={classes.root}>
      <Grid item>
        <Typography variant='h6'>
          {t('DialInPage.title', { product })}
        </Typography>
      </Grid>
      <Grid item>
        <DialInInstructionsTable meetingIdentifier={meetingIdentifier} />
      </Grid>
      <Grid item>
        <DialInCountriesTable
          countries={countries}
          roomPin={meetingIdentifier}
        />
      </Grid>
    </Grid>
  )
}

export default DialInInfo
