import { FEATURE_NAMES } from 'constants/features-map'

import * as LicenseUtils from 'lib/licenses-utils'
import localStore from 'app/services/state/local-store'

import ACTIONS from 'constants/shortcut-actions'
import commandsRegistry from 'app/services/commands/commands-registry'

import { ACTION_TYPES as CHANNEL_SETTINGS_ACTION_TYPES } from 'app/state/api/channels/channel-settings.reducer'
import {
  ACTION_TYPES as CHANNEL_ROOM_ACTION_TYPES,
  STORE_NAME as CHANNEL_ROOM_STORE_NAME,
} from 'app/state/api/channels/channel-room.reducer'
import { ACTION_TYPES as STICKER_ACTION_TYPES } from 'app/features/sticker-picker/stickers.reducer'

import {
  ACTION_TYPES as CONTENT_ACTION_TYPES,
  DIALOG_TYPES,
} from 'app/features/content/content.reducer'

import { ACTION_TYPES, STORE_NAME, actions, selectors } from './flags.reducer'
import { checkFeature } from './flags.utils'

function instantiateLicenses() {
  return actions.addLicenses(LicenseUtils.getLicenses())
}

/**
 *
 * @param state
 */
function instantiateFeatures(state) {
  return actions.addFeatures(
    Object.values(FEATURE_NAMES).map((name) => ({
      name,
      ...checkFeature(name, state),
    }))
  )
}

/**
 *
 * @param state
 */
function updateFeatures(state) {
  return actions.editFeatures(
    Object.values(FEATURE_NAMES).map((name) => ({
      name,
      payload: checkFeature(name, state),
    }))
  )
}

/**
 *
 * @param action
 */
function markFeatureRead(action) {
  return actions.setFeatureUnread(
    LicenseUtils.getFeatureName(action.props.selected)
  )
}

function initialiseAndUpdateFeatures(store) {
  const { [STORE_NAME]: flags } = store.getState()

  if (!Object.keys(selectors.getFeatures(flags)).length) {
    store.dispatch(instantiateLicenses())

    // NOTE: Retrieve new state from the store after instantiating the licenses.
    store.dispatch(instantiateFeatures(store.getState()))
  } else {
    store.dispatch(updateFeatures(store.getState()))
  }

  // Initialize stickers in case somebody already has set a sticker while joining
  store.dispatch({ type: STICKER_ACTION_TYPES.INIT })
}

export default (store) => (next) => (action) => {
  if (action.type === 'sm-web/INIT') {
    initialiseAndUpdateFeatures(store)
  }

  const nextState = next(action)

  switch (action.type) {
    case CHANNEL_ROOM_ACTION_TYPES.UPDATED_MEMBER_RIGHTS:
      // eslint-disable-next-line no-case-declarations
      const { [CHANNEL_ROOM_STORE_NAME]: channelRoom } = store.getState()
      if (
        channelRoom.member_id === action.memberId &&
        Object.hasOwn(action.rights, 'room_moderator')
      ) {
        // Update the feature store when the local members rights has a room_moderator update
        initialiseAndUpdateFeatures(store)
      }
      break
    case CHANNEL_ROOM_ACTION_TYPES.JOINED:
    case CHANNEL_SETTINGS_ACTION_TYPES.SETTINGS_SET:
      initialiseAndUpdateFeatures(store)
      break

    case CHANNEL_ROOM_ACTION_TYPES.UPDATED_ROOM_META:
    case CHANNEL_SETTINGS_ACTION_TYPES.SETTINGS_UPDATE:
      store.dispatch(updateFeatures(store.getState()))
      break

    case ACTION_TYPES.SET_FEATURE_UNREAD:
      if (!action.payload) {
        localStore.addReadFeature(action.name)
      }
      break

    case CONTENT_ACTION_TYPES.SHOW_DIALOG:
      if (action.payload === DIALOG_TYPES.LICENSES && action.props.selected) {
        store.dispatch(markFeatureRead(action))
      }
      break

    case ACTION_TYPES.ADD_FEATURES:
    case ACTION_TYPES.EDIT_FEATURES:
      {
        const { [STORE_NAME]: flags } = store.getState()
        const features = selectors.getFeatures(flags)
        Object.keys(ACTIONS)
          .filter((name) => ACTIONS[name].feature)
          .map((name) => {
            const feature = features[ACTIONS[name].feature]
            return [
              name,
              feature.enabled && feature.supported && feature.licensed,
            ]
          })
          .forEach(([name, available]) => {
            if (available) commandsRegistry.enableCommand(name)
            else commandsRegistry.disableCommand(name)
          })
      }
      break

    default:
  }

  return nextState
}
