export const STORE_NAME = 'feature/video-streamer'

const SET_VIDEO_OBJECT = `sm-web/${STORE_NAME}/SET_VIDEO_OBJECT`
const START_VIDEO = `sm-web/${STORE_NAME}/START_VIDEO`
const PAUSE_VIDEO = `sm-web/${STORE_NAME}/PAUSE_VIDEO`
const RESUME_VIDEO = `sm-web/${STORE_NAME}/RESUME_VIDEO`
const STOP_VIDEO = `sm-web/${STORE_NAME}/STOP_VIDEO`
const PLAYBACK_ERROR = `sm-web/${STORE_NAME}/PLAYBACK_ERROR`

export const ACTION_TYPES = {
  SET_VIDEO_OBJECT,
  START_VIDEO,
  PAUSE_VIDEO,
  RESUME_VIDEO,
  STOP_VIDEO,
  PLAYBACK_ERROR,
}

const setVideoObject = (payload) => ({ type: SET_VIDEO_OBJECT, payload })
const startVideo = (video, audio) => ({
  type: START_VIDEO,
  payload: { video, audio },
})
const pauseVideo = (payload) => ({ type: PAUSE_VIDEO, payload })
const resumeVideo = (payload) => ({ type: RESUME_VIDEO, payload })
const stopVideo = (payload) => ({ type: STOP_VIDEO, payload })
const setPlaybackError = (payload) => ({ type: PLAYBACK_ERROR, payload })

export const actions = {
  setVideoObject,
  startVideo,
  pauseVideo,
  resumeVideo,
  stopVideo,
  setPlaybackError,
}

const INITIAL_STATE = {
  playbackError: false,
  video: null,
  videoTrackId: null,
  audioTrackId: null,
}

const ACTION_HANDLERS = {
  [SET_VIDEO_OBJECT]: (state, action) => ({
    ...state,
    playbackError: false,
    video: action.payload,
  }),
  [START_VIDEO]: (state, action) => ({
    ...state,
    videoTrackId: action.payload.video ? action.payload.video.id : null,
    audioTrackId: action.payload.audio ? action.payload.audio.id : null,
  }),
  [STOP_VIDEO]: (state) => ({
    ...state,
    videoTrackId: null,
    audioTrackId: null,
    video: INITIAL_STATE.video,
  }),
  [PLAYBACK_ERROR]: (state, action) => ({
    ...state,
    playbackError: action.payload,
  }),
  'sm-web/RESET': () => INITIAL_STATE,
}

export default (state = INITIAL_STATE, action = {}) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export const selectors = {
  getPlaybackError(state) {
    return state.playbackError
  },
  getVideo(state) {
    return state.video
  },
  getVideoTrackId(state) {
    return state.videoTrackId
  },
  getAudioTrackId(state) {
    return state.audioTrackId
  },
}
