/**
 * @class
 */
class ColigoTaskQueue {
  /**
   * @type {Function[]}
   */
  taskQueue = []

  /**
   *
   */
  inProgress = false

  /**
   * Gets the number of tasks contained in the `taskQueue`
   * @return
   */
  get count() {
    return this.taskQueue.length
  }

  /**
   * Returns the task at the beginning of the `taskQueue` without removing it.
   * @return
   */
  peek() {
    return this.taskQueue[0]
  }

  /**
   * Adds a task to the end of the `taskQueue`.
   * @param task
   * @return
   */
  enqueue(task) {
    /**
     * If the given task is a function push it in the `taskQueue`, else throw error.
     */
    if (typeof task === 'function') {
      this.taskQueue.push(task)
    } else {
      throw new Error('Given task is not a function')
    }

    /**
     * If nothing is in progress, meaning that there is no get task process is running,
     * we execute the first task in the `taskQueue`.
     */
    if (!this.inProgress) {
      this.executeFirstTaskInQueue()
    }
  }

  /**
   * Removes and returns the task at the beginning of the `taskQueue`.
   * @return
   */
  dequeue() {
    return this.taskQueue.splice(0, 1)[0]
  }

  /**
   * @return
   */
  executeFirstTaskInQueue() {
    /**
     * Check if there are tasks in the `taskQueue`, otherwise it will try to execute
     * an undefined.
     * If there are no tasks in the `taskQueue` set `inProgress` to false.
     */
    if (this.count) {
      /**
       * Set `inProgress` to true as after this tasks are running.
       */
      this.inProgress = true

      /**
       * This is the task in the beginning of the queue.
       *
       */
      const task = this.dequeue()

      /**
       * If this task is not a function throw an error, but if it is a function execute it.
       * After the task is done and resolved execute the next in the queue.
       */
      if (typeof task === 'function') {
        task()
          .then(this.executeFirstTaskInQueue.bind(this))
          .catch((err) => {
            this.executeFirstTaskInQueue()
            throw err
          })
      } else {
        throw new Error('Dequeued task is not a function')
      }
    } else {
      this.inProgress = false
    }
  }
}

export default ColigoTaskQueue
