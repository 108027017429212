import _ from 'lodash'
import GraphemeSplitter from 'grapheme-splitter'

const splitter = new GraphemeSplitter()

/**
 * Returns the initials from the given userName.
 * @param userName
 * @returns
 */
function getUserInitials(userName) {
  if (typeof userName !== 'string' || !userName.trim()) return ''

  const chars = userName
    .trim()
    .split(' ')
    .map((s) => splitter.splitGraphemes(s))

  let initials

  // Create the initials based on the number of words
  if (chars.length > 1) {
    initials = chars[0][0] + _.last(chars)[0]
  } else {
    initials = chars[0][0] + (chars[0][1] || '')
  }

  return initials.toUpperCase()
}

export default getUserInitials
