export const STORE_NAME = 'feature/conference'

/**
 * Collection of all actions
 *
 */
const INIT = `sm-web/${STORE_NAME}/INIT`
const ROOM_LINK_COPY = `sm-web/${STORE_NAME}/ROOM_LINK_COPY`
const ROOM_LINK_COPIED = `sm-web/${STORE_NAME}/ROOM_LINK_COPIED`
const END_MEETING = `sm-web/${STORE_NAME}/END_MEETING`
const LOGOUT = `sm-web/${STORE_NAME}/LOGOUT`
const LOGOUT_TIMER_EXPIRED = `sm-web/${STORE_NAME}/LOGOUT_TIMER_EXPIRED`
const SHOW_INVITE_DIALOG = `sm-web/${STORE_NAME}/SHOW_INVITE_DIALOG`
const TOGGLE_MICROPHONE = `sm-web/${STORE_NAME}/TOGGLE_MICROPHONE`
const TOGGLE_PUSH_TO_TALK = `sm-web/${STORE_NAME}/TOGGLE_PUSH_TO_TALK`
const TOGGLE_PUSH_TO_TALK_START = `sm-web/${STORE_NAME}/TOGGLE_PUSH_TO_TALK_START`
const TOGGLE_PUSH_TO_TALK_STOP = `sm-web/${STORE_NAME}/TOGGLE_PUSH_TO_TALK_STOP`
const TOGGLE_RAISE_HAND = `sm-web/${STORE_NAME}/TOGGLE_RAISE_HAND`
const TOGGLE_ROOM_AUDIO = `sm-web/${STORE_NAME}/TOGGLE_ROOM_AUDIO`
const TOGGLE_ROOM_VIDEO = `sm-web/${STORE_NAME}/TOGGLE_ROOM_VIDEO`
const TOGGLE_SCREENSHARE = `sm-web/${STORE_NAME}/TOGGLE_SCREENSHARE`
const TOGGLE_VIDEO = `sm-web/${STORE_NAME}/TOGGLE_VIDEO`

const GUM_APPROVED = `sm-web/${STORE_NAME}/GUM_APPROVED`
const GUM_FAILED = `sm-web/${STORE_NAME}/GUM_FAILED`

export const ACTION_TYPES = {
  INIT,
  ROOM_LINK_COPY,
  ROOM_LINK_COPIED,
  END_MEETING,
  LOGOUT_TIMER_EXPIRED,
  LOGOUT,
  SHOW_INVITE_DIALOG,
  TOGGLE_MICROPHONE,
  TOGGLE_PUSH_TO_TALK,
  TOGGLE_PUSH_TO_TALK_START,
  TOGGLE_PUSH_TO_TALK_STOP,
  TOGGLE_RAISE_HAND,
  TOGGLE_ROOM_AUDIO,
  TOGGLE_ROOM_VIDEO,
  TOGGLE_SCREENSHARE,
  TOGGLE_VIDEO,
  GUM_APPROVED,
  GUM_FAILED,
}

function logoutTimerExpired() {
  return { type: LOGOUT_TIMER_EXPIRED }
}
function roomLinkCopy() {
  return { type: ROOM_LINK_COPY }
}
function roomLinkCopied() {
  return { type: ROOM_LINK_COPIED }
}
function endMeeting() {
  return { type: END_MEETING }
}
function logout() {
  return { type: LOGOUT }
}
function showDialogInvite() {
  return { type: SHOW_INVITE_DIALOG }
}
function toggleMicrophone() {
  return { type: TOGGLE_MICROPHONE }
}
function toggleVideo() {
  return { type: TOGGLE_VIDEO }
}
function toggleRaiseHand() {
  return { type: TOGGLE_RAISE_HAND }
}
function toggleRoomAudio() {
  return { type: TOGGLE_ROOM_AUDIO }
}
function toggleRoomVideo() {
  return { type: TOGGLE_ROOM_VIDEO }
}
function togglePtt() {
  return { type: TOGGLE_PUSH_TO_TALK }
}
function pttStart() {
  return { type: TOGGLE_PUSH_TO_TALK_START }
}
function pttStop() {
  return { type: TOGGLE_PUSH_TO_TALK_STOP }
}

function toggleScreenshare() {
  return { type: TOGGLE_SCREENSHARE }
}

function gumApproved(payload) {
  return { type: GUM_APPROVED, payload }
}

function gumFailed(err) {
  return {
    type: GUM_FAILED,
    payload: { name: err.name, stack: err.stack, message: err.message },
  }
}

export const actions = {
  roomLinkCopy,
  roomLinkCopied,
  endMeeting,
  logout,
  logoutTimerExpired,
  pttStart,
  pttStop,
  showDialogInvite,
  toggleMicrophone,
  togglePtt,
  toggleRaiseHand,
  toggleRoomAudio,
  toggleRoomVideo,
  toggleScreenshare,
  toggleVideo,
  gumApproved,
  gumFailed,
}

export const commands = {
  [END_MEETING]: endMeeting,
  [LOGOUT]: logout,
  [ROOM_LINK_COPY]: roomLinkCopy,
  [SHOW_INVITE_DIALOG]: showDialogInvite,
  [TOGGLE_MICROPHONE]: toggleMicrophone,
  [TOGGLE_PUSH_TO_TALK_START]: pttStart,
  [TOGGLE_PUSH_TO_TALK_STOP]: pttStop,
  [TOGGLE_RAISE_HAND]: toggleRaiseHand,
  [TOGGLE_ROOM_AUDIO]: toggleRoomAudio,
  [TOGGLE_ROOM_VIDEO]: toggleRoomVideo,
  [TOGGLE_SCREENSHARE]: toggleScreenshare,
  [TOGGLE_VIDEO]: toggleVideo,
}

/**
 * Initial side-panel state
 *
 * @property {string|null} view
 */
const INITIAL_STATE = {}

/**
 * Action handler
 * @type {{[p: string]: *}}
 */
const ACTION_HANDLERS = {
  'sm-web/RESET': () => INITIAL_STATE,
}

/**
 * Exporting the reducer
 * @param state
 * @param action
 */
export default (state = INITIAL_STATE, action = {}) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export const selectors = {}
