import type { SVGProps } from 'react'
const SvgReaction3Lunch = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <path
      fill='rgb(var(--color-brand-200))'
      d='m125.83 108.6-51.38 9.75.31 9.91 51.53-10.37 1.86-3.56.31-6.19-2.63.46z'
    />
    <path
      fill='rgb(var(--color-brand-100))'
      d='m33.01 107.13.29 9.99 41.46 11.14v-10.68l-41.75-10.45z'
    />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M32.21 109.25h8.42v9.9h-8.42z'
    />
    <path
      fill='rgb(var(--color-brand-500))'
      d='M75.3 132.29c-.83 0-1.65-.1-2.45-.3l-37.91-9.65a5.594 5.594 0 0 1-4.22-5.42v-9.4c0-1.1.9-2 2-2s2 .9 2 2v9.4c0 .73.49 1.37 1.2 1.55l37.91 9.65c.87.22 1.79.24 2.66.06l47.71-9.83c.74-.15 1.27-.81 1.27-1.56v-9.26c0-1.1.9-2 2-2s2 .9 2 2v9.26c0 2.64-1.88 4.95-4.47 5.48l-47.71 9.83c-.66.13-1.33.2-2 .2z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='m82.82 96.1-49.81 9.93c-.46.09-.48.75-.02.86l40.4 9.88c1.13.29 2.31.31 3.45.08l50.21-10.01c.5-.1.51-.8.02-.92l-39.74-9.71c-1.48-.38-3.03-.41-4.52-.1z'
    />
    <path
      fill='rgb(var(--color-brand-500))'
      d='M75.3 118.5c-.76 0-1.53-.09-2.27-.28l-40.39-9.87c-.89-.22-1.5-1.01-1.48-1.93.02-.92.66-1.68 1.56-1.86l49.8-9.93c1.71-.35 3.5-.31 5.19.12l39.72 9.71c.9.22 1.52 1.03 1.5 1.96-.02.93-.67 1.71-1.58 1.89l-50.21 10.01c-.6.12-1.22.19-1.84.19zm-37.32-11.94 35.77 8.74c.92.23 1.88.26 2.79.07l45.68-9.11-35.24-8.61c-1.27-.32-2.6-.35-3.86-.09l-45.13 9zm-4.67.93z'
    />
    <path
      fill='rgb(var(--color-brand-50))'
      d='M127.21 97.29 87.48 78.43c-1.48-.38-3.03-.41-4.52-.1L33.15 97.41c-.46.09-.48.75-.02.86l39.56 21.07c.89.47 1.88.71 2.88.68h.03c.71-.02 1.41-.17 2.06-.45l4.28-1.85c.82-.35 1.31-1.17 1.31-2.07v-.02a8.7 8.7 0 0 1 8.7-8.7c2.04 0 3.92.71 5.4 1.89 1.13.9 2.68 1.09 4.01.52l25.82-11.14c.5-.1.51-.8.02-.92z'
    />
    <path
      fill='rgb(var(--color-brand-500))'
      d='M75.45 121.53c-1.23 0-2.42-.3-3.48-.86L32.52 99.66a1.92 1.92 0 0 1-1.24-1.85c.02-.86.58-1.59 1.4-1.82l49.73-19.05c.08-.03.15-.05.23-.07 1.7-.35 3.5-.31 5.19.12.09.02.19.06.27.1l39.64 18.81c.8.28 1.33 1.03 1.31 1.9-.02.86-.58 1.59-1.38 1.85l-25.73 11.1c-1.85.8-3.97.52-5.54-.72a7.11 7.11 0 0 0-4.47-1.56c-3.97 0-7.2 3.23-7.2 7.2 0 1.52-.87 2.88-2.22 3.46l-4.28 1.85c-.83.36-1.71.55-2.62.57h-.18zM35.79 98l37.59 20.02c.66.35 1.39.54 2.14.51.55-.01 1.06-.12 1.54-.33l4.28-1.84c.25-.11.41-.37.4-.69 0-5.64 4.58-10.22 10.2-10.22 2.33 0 4.52.77 6.34 2.21.7.56 1.64.67 2.48.32l23.81-10.27-37.61-17.85a8.565 8.565 0 0 0-3.6-.08L35.79 98zm-2.36.88zm93.41-.13z'
    />
    <path
      fill='rgb(var(--color-brand-100))'
      d='M62.15 87.11c.8 3.71-7.16 8.57-17.77 10.85-10.61 2.28-19.86 1.11-20.66-2.6l-1.5-6.96 38.43-8.25 1.5 6.96z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M34.15 101.12c-1.42 0-2.77-.08-4.02-.24-6.17-.78-7.95-3.21-8.35-5.11l-1.5-6.96c-.23-1.08.46-2.14 1.54-2.38l38.43-8.25a2.012 2.012 0 0 1 2.38 1.54l1.5 6.96c1.22 5.7-8.79 10.97-19.3 13.22-3.73.8-7.39 1.21-10.66 1.21zm-9.54-11.2 1.08 5.01c.12.56 1.54 1.55 4.94 1.98 3.68.46 8.42.14 13.34-.92 11.39-2.45 16.54-7.05 16.23-8.47l-1.08-5.01-34.52 7.41z'
    />
    <ellipse
      cx={41.24}
      cy={83.31}
      fill='rgb(var(--color-neutral-50))'
      rx={19.65}
      ry={6.88}
      transform='rotate(-12.12 41.214 83.295)'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M32.44 93.2c-1.42 0-2.77-.08-4.02-.23-6.17-.78-7.95-3.21-8.35-5.11-.41-1.9.22-4.85 5.52-8.09 3.54-2.16 8.44-3.99 13.79-5.13 5.35-1.15 10.56-1.5 14.68-.98 6.16.78 7.95 3.21 8.35 5.11.41 1.9-.22 4.85-5.52 8.09-3.54 2.16-8.44 3.99-13.79 5.13-3.73.8-7.39 1.21-10.66 1.21zm17.57-15.78c-2.98 0-6.34.38-9.8 1.12-4.92 1.06-9.37 2.7-12.54 4.64-2.93 1.79-3.81 3.27-3.69 3.84.12.56 1.54 1.55 4.94 1.98 3.68.46 8.42.14 13.34-.92s9.37-2.7 12.54-4.64c2.93-1.79 3.81-3.27 3.69-3.84-.12-.56-1.54-1.55-4.94-1.98-1.09-.14-2.28-.21-3.54-.21z'
    />
    <path
      fill='rgb(var(--color-brand-100))'
      d='M140.08 93.5c-.43 3.77-9.51 5.85-20.3 4.63-10.78-1.22-19.18-5.27-18.75-9.04l.8-7.08 39.05 4.41-.8 7.08z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M125.84 99.98c-2 0-4.09-.12-6.22-.36-5.38-.61-10.37-1.92-14.06-3.7-5.33-2.57-6.21-5.22-6.01-7l.8-7.08c.04-.4.24-.76.56-1 .31-.25.71-.37 1.1-.32l39.05 4.41c.4.04.76.24 1 .56.25.31.36.71.32 1.1l-.8 7.08c-.2 1.77-1.65 4.16-7.42 5.48-2.41.55-5.26.83-8.31.83zm-22.68-16.31-.63 5.59c-.09.82.99 2.35 4.33 3.96 3.39 1.64 8.05 2.85 13.1 3.42 5.06.57 9.86.42 13.53-.41 3.62-.83 5.02-2.07 5.11-2.89l.63-5.59-36.07-4.08z'
    />
    <ellipse
      cx={121.47}
      cy={83.24}
      fill='rgb(var(--color-neutral-50))'
      rx={6.88}
      ry={19.65}
      transform='rotate(-83.55 121.47 83.24)'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M126.75 91.93c-2 0-4.09-.12-6.22-.36-5.38-.61-10.37-1.92-14.06-3.7-5.33-2.57-6.21-5.22-6.01-7 .2-1.77 1.65-4.16 7.42-5.48 3.99-.91 9.16-1.08 14.54-.47 5.38.61 10.37 1.92 14.06 3.7 5.33 2.57 6.21 5.22 6.01 7-.2 1.77-1.65 4.16-7.42 5.48-2.41.55-5.26.83-8.31.83zm-10.57-14.37c-2.82 0-5.43.25-7.63.76-3.62.83-5.02 2.07-5.11 2.89-.09.82.99 2.35 4.33 3.96 3.39 1.64 8.05 2.85 13.1 3.42 5.06.57 9.86.42 13.53-.41 3.62-.83 5.02-2.07 5.11-2.89.09-.82-.99-2.35-4.33-3.96-3.39-1.64-8.05-2.85-13.1-3.42-2.03-.23-4.01-.34-5.9-.34z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M110.42 95.86c.43 3.77-7.96 7.83-18.74 9.06-10.78 1.23-19.87-.83-20.3-4.6l-.81-7.08 39.05-4.46.81 7.08z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M85.57 106.53c-3.02 0-5.82-.27-8.2-.81-5.63-1.28-7.04-3.56-7.24-5.26l-.81-7.08c-.08-.69.41-1.31 1.1-1.38l39.05-4.46c.33-.03.66.06.92.26.26.21.43.51.46.84l.81 7.08c.6 5.24-9.78 9.3-19.84 10.45-2.14.24-4.24.37-6.25.37zM71.95 94.34l.67 5.83c.14 1.2 2.17 2.39 5.3 3.1 3.7.84 8.54.98 13.62.4 11.33-1.29 17.91-5.34 17.64-7.68l-.67-5.84-36.56 4.17z'
    />
    <ellipse
      cx={89.99}
      cy={90.16}
      fill='rgb(var(--color-brand-200))'
      rx={19.65}
      ry={8.29}
      transform='rotate(-6.51 90.038 90.235)'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M85.63 99.96c-9.17 0-15.89-2.88-16.41-7.42-.62-5.4 7.85-10.49 19.69-11.85 11.83-1.35 21.23 1.7 21.85 7.11.62 5.4-7.85 10.49-19.68 11.85-1.88.21-3.71.32-5.44.32zm8.6-17.08c-1.59 0-3.27.09-5.03.29-10.77 1.23-17.86 5.77-17.48 9.08.38 3.31 8.31 6.14 19.08 4.91 10.77-1.23 17.86-5.77 17.48-9.08-.32-2.77-5.92-5.2-14.05-5.2z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M124.7 68.8H41.91c-9.74 2.18-15.94 4.9-15.74 7.39.24 3.04 9.98 4.89 23.83 4.99-1.59 1.71-2.28 3.2-1.86 4.36.77 2.12 5.11 2.72 11.62 2.01 1.7 4.32 6.2 7.25 11.26 6.91 6.21-.42 10.91-5.58 10.51-11.54 0-.1-.03-.2-.04-.3 1.1-.35 2.21-.71 3.34-1.09 1.04 1.12 2.63 2.23 4.55 3.07 3.92 1.73 7.72 1.71 8.56-.02 15.26 6.09 29.57 5.64 32.62-1.26 1.77-4.01-.65-9.35-5.88-14.53z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M70.22 95.98c-4.87 0-9.25-2.64-11.37-6.83-7.16.65-11.12-.37-12.11-3.1-.38-1.04-.25-2.18.37-3.42-10.62-.26-22.05-1.64-22.43-6.33-.1-1.26-.41-5.1 16.91-8.97.11-.02.22-.04.33-.04h82.78c.4 0 .78.16 1.06.43 5.9 5.85 8.15 11.75 6.19 16.2-3.31 7.52-17.63 8.52-33.54 2.44-2.03 1.63-6.11 1.15-9.61-.4-1.67-.73-3.17-1.69-4.34-2.73l-1.38.45c-.01 2.98-1.13 5.82-3.19 8.08-2.25 2.47-5.35 3.95-8.74 4.18-.31.02-.61.03-.91.03zm-10.45-9.93c.61 0 1.17.37 1.39.95 1.52 3.86 5.44 6.26 9.76 5.96 2.61-.17 5-1.31 6.73-3.21 1.71-1.87 2.56-4.27 2.39-6.74l-.03-.26a1.51 1.51 0 0 1 1.04-1.58c1.09-.34 2.2-.7 3.31-1.08.56-.19 1.17-.03 1.58.4.95 1.02 2.4 1.99 4.06 2.72 3.72 1.64 6.35 1.23 6.61.7a1.5 1.5 0 0 1 1.91-.74c14.29 5.7 28.06 5.49 30.69-.47 1.39-3.15-.48-7.66-5.13-12.42h-82c-11.81 2.66-14.37 5.22-14.41 5.77.26 1.08 6.24 3.49 22.34 3.61a1.506 1.506 0 0 1 1.09 2.53c-1.37 1.47-1.69 2.44-1.54 2.83.26.71 2.69 1.84 10.05 1.04h.16z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='m124.41 56.86-51.37 9.75.31 9.9 51.53-10.37 1.86-3.56.24-6.56a.714.714 0 0 0-1.22-.53l-1.34 1.36z'
    />
    <path
      fill='rgb(var(--color-brand-100))'
      d='m31.6 56.49.28 9.99 41.47 11.14V66.94L31.6 56.49z'
    />
    <path fill='rgb(var(--color-neutral-50))' d='M30.8 57.5h8.92v9.9H30.8z' />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M73.89 79.44c-.83 0-1.65-.1-2.46-.31l-37.91-9.65a5.585 5.585 0 0 1-4.21-5.42v-8.29c0-1.1.9-2 2-2s2 .9 2 2v8.29c0 .73.49 1.37 1.2 1.54l37.91 9.65c.87.22 1.79.24 2.66.06l47.71-9.83c.74-.15 1.27-.81 1.27-1.56v-8.16c0-1.1.9-2 2-2s2 .9 2 2v8.16c0 2.64-1.88 4.95-4.47 5.48l-47.71 9.83c-.66.14-1.33.2-2 .2z'
    />
    <path
      fill='rgb(var(--color-brand-50))'
      d='M81.41 44.35 31.6 54.28c-.46.09-.48.75-.02.86l40.4 9.88c1.13.29 2.31.31 3.45.08l50.21-10.01c.5-.1.51-.8.02-.92l-39.74-9.71c-1.48-.38-3.03-.41-4.52-.1z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M73.89 67.01c-.79 0-1.57-.1-2.34-.29l-40.39-9.87a2.18 2.18 0 0 1-1.67-2.18c.02-1.04.75-1.9 1.76-2.1l49.81-9.93a12 12 0 0 1 5.3.12l39.72 9.71c1.02.25 1.72 1.16 1.69 2.21a2.202 2.202 0 0 1-1.79 2.13L75.77 66.82c-.62.13-1.25.19-1.89.19zM37.73 54.85l34.67 8.48c.88.22 1.81.25 2.68.07l44.57-8.89-34.14-8.34a8.483 8.483 0 0 0-3.75-.09h-.01l-44.02 8.78z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M79.9 57.68c-2.44 0-4.73-.58-6.44-1.65-.7-.44-.92-1.36-.49-2.07.44-.7 1.36-.92 2.07-.49 1.23.76 3 1.2 4.86 1.2 1.72 0 3.34-.36 4.56-1.03a1.499 1.499 0 0 1 1.42 2.64c-1.65.89-3.78 1.39-5.99 1.39z'
    />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='m99.66 28.43-19.7-7.35v14.7l19.7-7.35z'
    />
    <path
      fill='rgb(var(--color-brand-500))'
      d='M79.96 37.03a1.244 1.244 0 0 1-1.25-1.25V21.07c0-.41.2-.79.54-1.03.34-.23.77-.29 1.15-.14l19.7 7.35c.49.18.81.65.81 1.17s-.32.99-.81 1.17l-19.7 7.35c-.14.05-.29.08-.44.08zm1.25-14.15v11.1l14.87-5.55-14.87-5.55z'
    />
    <path
      fill='rgb(var(--color-brand-500))'
      d='M80 54.99c-.69 0-1.25-.56-1.25-1.25V20.12a1.25 1.25 0 0 1 2.5 0v33.62c0 .69-.56 1.25-1.25 1.25z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M62.46 106.83c-.85 0-1.75-.17-2.65-.52-2.97-1.16-4.62-3.83-3.74-6.07s3.89-3.09 6.86-1.94c1.36.53 2.5 1.41 3.21 2.48.77 1.16.97 2.47.53 3.59-.61 1.57-2.26 2.45-4.21 2.45zm-2.17-7.03c-1.13 0-2.06.42-2.35 1.17-.45 1.16.74 2.75 2.61 3.48 1.87.73 3.82.36 4.27-.8.27-.69-.09-1.39-.33-1.75-.48-.72-1.31-1.35-2.27-1.73-.65-.25-1.31-.37-1.92-.37z'
    />
    <ellipse
      cx={94.46}
      cy={139.01}
      fill='rgb(var(--color-brand-200))'
      rx={5.89}
      ry={3.35}
    />
    <ellipse
      cx={109.44}
      cy={132.75}
      fill='rgb(var(--color-brand-200))'
      rx={2.79}
      ry={1.94}
    />
  </svg>
)
export default SvgReaction3Lunch
