import { Paper as MuiPaper } from '@mui/material'
import clsx from 'clsx'

import { makeStyles } from 'app/ui'

const useStyles = makeStyles((theme) => ({
  border: theme.shape.borderRadius,
  padding: theme.spacing(4),
}))

type Props = React.ComponentPropsWithRef<typeof MuiPaper> & {
  className?: string
  padding?: boolean
  roundedCorners?: boolean
}

export const Paper: React.FC<Props> = ({
  className,
  padding,
  roundedCorners,
  ...other
}: Props) => {
  const classes = useStyles()
  return (
    <MuiPaper
      {...other}
      className={clsx(
        roundedCorners && classes.border,
        padding && classes.padding,
        className
      )}
    />
  )
}

export default Paper
