import type { SVGProps } from 'react'
const SvgReaction4Bye = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle cx={82.17} cy={78.48} r={62} fill='rgb(var(--color-brand-50))' />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='m113.36 70.52 7.88-10.46.77-4.61-1.93-3.97-4.49-1.33v-2.79l-.56-6.7-6.7-1.4-6.42 5.3-2.98-6.07-4.56-.91-20.09 23.16-8.65 14.23-3.35-3.63 5.58-18.42-.84-3.91-4.74-2.23-5.3 2.51L46.69 78.8l-1.71 12.62 5.86 12.56-10.6 16.47-1.6 2.15a61.999 61.999 0 0 0 23.72 14.6l12.49-15.08 9.77-.84 8.37-4.74 29.58-39.07-2.83-5.24-6.38-1.73z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M64.15 137.73a1.996 1.996 0 0 1-1.59-3.2l9.6-12.72a5.045 5.045 0 0 1 4.83-1.95c6.43 1.05 12.85-1.5 16.79-6.67l25.53-33.56c1.38-1.81 1.02-4.4-.79-5.78s-4.4-1.02-5.78.79l-10.86 14.27c-.67.88-1.92 1.05-2.8.38a1.995 1.995 0 0 1-.38-2.8l10.86-14.27c2.71-3.57 7.82-4.26 11.39-1.55 3.57 2.71 4.26 7.82 1.55 11.39l-25.53 33.56a21.462 21.462 0 0 1-20.62 8.2c-.37-.06-.75.1-1 .42l-9.6 12.71c-.39.52-.99.79-1.6.79zm17.67-64.62c-.42 0-.85-.13-1.21-.41a1.995 1.995 0 0 1-.38-2.8l18.28-24.03c.67-.88.95-1.96.8-3.05-.15-1.09-.71-2.06-1.59-2.73s-1.96-.96-3.05-.8c-1.09.15-2.06.71-2.73 1.59L75.9 61.96c-.67.88-1.92 1.05-2.8.38a1.995 1.995 0 0 1-.38-2.8l16.04-21.08a8.037 8.037 0 0 1 5.37-3.13 8.08 8.08 0 0 1 6.01 1.58c1.73 1.31 2.84 3.22 3.13 5.37s-.27 4.29-1.58 6.01L83.41 72.32c-.39.52-.99.79-1.59.79zm-42.97 49.18a2.004 2.004 0 0 1-1.58-3.23l11.39-14.75c.28-.37.29-.89 0-1.28a27.13 27.13 0 0 1-3.85-24.86l9.36-27.39c1.45-4.24 6.08-6.51 10.32-5.06a8.09 8.09 0 0 1 4.67 4.11c.96 1.95 1.1 4.15.39 6.21l-5.56 16.27c8.96 4.12 13.53 14.47 10.28 23.99l-.08.23c-.36 1.04-1.5 1.6-2.54 1.24-1.04-.36-1.6-1.5-1.24-2.54l.08-.23c2.76-8.08-1.57-16.9-9.65-19.66a2.012 2.012 0 0 1-1.25-2.54l6.17-18.07c.74-2.15-.42-4.5-2.57-5.24-2.15-.73-4.5.42-5.24 2.57l-9.36 27.39a23.112 23.112 0 0 0 3.29 21.2c1.33 1.8 1.31 4.3-.04 6.09l-11.4 14.76c-.39.51-.99.78-1.58.78z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M88.99 84.18c-.42 0-.85-.13-1.21-.41a1.995 1.995 0 0 1-.38-2.8l25.47-33.48c.67-.88.95-1.96.8-3.05s-.71-2.06-1.59-2.73-1.96-.95-3.05-.8c-1.09.15-2.06.71-2.73 1.59L82.32 74.02c-.67.88-1.92 1.05-2.8.38s-1.05-1.92-.38-2.8l23.98-31.52a8.037 8.037 0 0 1 5.37-3.13 8.08 8.08 0 0 1 6.01 1.58c3.57 2.71 4.26 7.82 1.55 11.39L90.58 83.4c-.39.52-.99.79-1.59.79z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M101.47 88.06c-.42 0-.85-.13-1.21-.41a1.995 1.995 0 0 1-.38-2.8l19.78-25.99c.67-.88.95-1.96.8-3.05a4.108 4.108 0 0 0-1.59-2.73c-.88-.67-1.96-.95-3.05-.8-1.09.15-2.06.71-2.73 1.59L91.82 81.83c-.67.88-1.92 1.05-2.8.38a1.995 1.995 0 0 1-.38-2.8l21.27-27.96a8.037 8.037 0 0 1 5.37-3.13 8.08 8.08 0 0 1 6.01 1.58 8.037 8.037 0 0 1 3.13 5.37 8.08 8.08 0 0 1-1.58 6.01l-19.78 25.99c-.39.52-.99.79-1.59.79z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M44.12 48.88c-.12 0-.25-.01-.37-.04-.95-.2-1.55-1.13-1.34-2.08 2.52-11.72 14.1-19.2 25.83-16.68.95.2 1.55 1.13 1.34 2.08-.2.95-1.14 1.54-2.08 1.34a18.15 18.15 0 0 0-13.73 2.51 18.139 18.139 0 0 0-7.94 11.49c-.18.82-.9 1.38-1.71 1.38zm81.24 51.78c-.61 0-1.21-.32-1.53-.89-.47-.84-.18-1.91.67-2.38 5.82-3.28 9.38-9.46 9.3-16.14-.01-.97.76-1.76 1.73-1.77h.02c.96 0 1.74.77 1.75 1.73a21.75 21.75 0 0 1-11.08 19.23c-.27.15-.57.23-.86.23z'
    />
  </svg>
)
export default SvgReaction4Bye
