import _ from 'lodash'

import * as LicenseUtils from 'lib/licenses-utils'
import { LICENSED_FEATURES } from 'constants/features-map'

import { checkFeature, filterDisabledLicensedFeatureFlag } from './flags.utils'

export const STORE_NAME = 'feature/flags'

const ADD_LICENSES = `sm-web/${STORE_NAME}/ADD_LICENSES`
const ADD_FEATURES = `sm-web/${STORE_NAME}/ADD_FEATURES`
const EDIT_FEATURES = `sm-web/${STORE_NAME}/EDIT_FEATURES`
const SET_FEATURE_UNREAD = `sm-web/${STORE_NAME}/SET_FEATURE_UNREAD`

export const ACTION_TYPES = {
  ADD_LICENSES,
  ADD_FEATURES,
  EDIT_FEATURES,
  SET_FEATURE_UNREAD,
}

type LicenseFromConfig = {
  id: number
  name: string
  iconPath: string
  features: Array<string | [string, boolean | number]>
  visible: boolean
}

type License = Omit<LicenseFromConfig, 'features'> & {
  features: Record<string, boolean | number>
}

type FeatureState = {
  licensed: boolean
  supported: boolean
  enabled: boolean
  unread: boolean
}

/**
 * this function is called with the data from the config.js
 */
const addLicenses = (licenses: LicenseFromConfig[]) => {
  /**
   * the license from config contains array with features
   * which we map to a map of feature name and either true or the value
   * associated with the feature (in which case the feature entry must be an array)
   */
  const mappedLicenses: Array<License> = licenses.map((lic) => ({
    ...lic,
    features: lic.features.reduce(
      (acc, f) => ({
        ...acc,
        [Array.isArray(f) ? f[0] : f]: Array.isArray(f) ? f[1] : true,
      }),
      {}
    ),
  }))
  /**
   * create a reverse map of feature to licenses
   * such that per feature we can easily check for which licenses it is enabled
   *
   */
  const features = LICENSED_FEATURES.map(([id, name]) => ({
    id,
    name,
    licenses: mappedLicenses.reduce((acc, lic) => {
      if (lic.features[id] !== undefined) {
        return {
          ...acc,
          [lic.id]: lic.features[id],
        }
      }
      return acc
    }, {}),
  })).filter(filterDisabledLicensedFeatureFlag)

  return {
    type: ADD_LICENSES,
    payload: {
      licenses: mappedLicenses,
      features,
    },
  }
}

const addFeatures = (features: Array<{ name: string } & FeatureState>) => ({
  type: ADD_FEATURES,
  payload: features.map(
    ({
      name,
      licensed = true,
      supported = true,
      enabled = true,
      unread = true,
    }) => ({
      name,
      payload: { licensed, supported, enabled, unread },
    })
  ),
})

const editFeatures = (
  features: Array<{ name: string; payload: FeatureState }>
) => ({
  type: EDIT_FEATURES,
  payload: features.map(({ name, payload }) => ({
    name,
    payload,
  })),
})

const setFeatureUnread = (name: string, payload: boolean = false) => ({
  type: SET_FEATURE_UNREAD,
  name,
  payload,
})

export const actions = {
  addLicenses,
  addFeatures,
  editFeatures,
  setFeatureUnread,
}

const INITIAL_STATE = {
  licenses: [] as Array<License>,
  licensesById: {} as Record<number, License>,
  features: {} as Record<string, FeatureState>,
  licenseFeatureValues: {},
}

export type FlagsState = typeof INITIAL_STATE

const ACTION_HANDLERS = {
  [ACTION_TYPES.ADD_LICENSES]: (
    state,
    { payload: { licenses, features } }
  ) => ({
    ...state,
    licenses: _.map(licenses, 'id'),
    licensesById: licenses.reduce(
      (acc, lic) => ({ ...acc, [lic.id]: lic }),
      {}
    ),
    licenseFeatureValues: features,
  }),
  [ACTION_TYPES.ADD_FEATURES]: (state, action) => ({
    ...state,
    features: action.payload.reduce(
      (acc, feat) => ({ ...acc, [feat.name]: feat.payload }),
      state.features
    ),
  }),
  [ACTION_TYPES.EDIT_FEATURES]: (state, action) => ({
    ...state,
    features: action.payload.reduce(
      (acc, feat) => ({
        ...acc,
        [feat.name]: { ...acc[feat.name], ...feat.payload },
      }),
      state.features
    ),
  }),
  [ACTION_TYPES.SET_FEATURE_UNREAD]: (state, action) => ({
    ...state,
    features: {
      ...state.features,
      [action.name]: { ...state.features[action.name], unread: action.payload },
    },
  }),
}

export default (state = INITIAL_STATE, action = {}) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export const selectors = {
  equalsFeature(f, id) {
    if (Array.isArray(f)) {
      return f[0] === id
    }
    return f === id
  },

  getFeatures(state: FlagsState) {
    return state.features
  },
  getFeature(name, state: FlagsState) {
    const feature = selectors.getFeatures(state)[name]

    if (!feature) {
      throw new Error(`There is no feature with the name: "${name}"`)
    }

    return feature
  },
  getFeaturesFailingKey(featureName: string, state: FlagsState) {
    const flagKeys = ['enabled', 'licensed', 'supported']
    const flags = checkFeature(featureName, state)

    return flagKeys.filter((key) => !flags[key])
  },
  getLicenses(state: FlagsState) {
    return state.licenses
  },
  getLicensesById(state: FlagsState) {
    return state.licensesById
  },
  getLicense(licenseId: number, state: FlagsState) {
    const license = _.chain(state.licensesById)
      .filter((lic) => licenseId >= lic.id)
      .sortBy('id')
      .last()
      .value()

    if (!license) {
      throw new Error(
        `No valid license found for licenseId: ${licenseId}. Licenses loaded: \n${JSON.stringify(
          state.licensesById
        )}`
      )
    }

    return license
  },
  getLicenseFeatures(licenseId: number, state: FlagsState) {
    const license = selectors.getLicense(licenseId, state)
    return license.features
  },

  getFeatureLicenseValue(
    featureName: string,
    licenseId: number,
    state: FlagsState
  ) {
    const feature = _.find(state.licenseFeatureValues, { name: featureName })
    if (feature && feature.licenses[licenseId]) {
      return feature.licenses[licenseId]
    }
    return null
  },
  getRequiredLicense(featureName: string, state: FlagsState) {
    const featureId = LicenseUtils.getFeatureId(featureName)
    const licenses = selectors.getLicenses(state)
    const requiredLicenses = licenses
      .map((id) => selectors.getLicense(id, state))
      .filter((l) =>
        Object.keys(l.features)
          .filter((f) => l.features[f])
          .some((f) => selectors.equalsFeature(f, featureId))
      )
    return requiredLicenses.length ? requiredLicenses[0] : null
  },
  getNextLicenses(currentLicenseId: number, state: FlagsState) {
    const currentLicense = this.getLicense(currentLicenseId, state)
    const indexOfCurrentLicense = state.licenses.indexOf(currentLicense.id)
    if (indexOfCurrentLicense < state.licenses.length - 1) {
      return state.licenses.slice(indexOfCurrentLicense + 1)
    }
    return []
  },
  getNextLicense(currentLicenseId: number, state: FlagsState) {
    const nextLicenses = selectors.getNextLicenses(currentLicenseId, state)
    return nextLicenses.length ? nextLicenses[0] : null
  },

  getNextVisibleLicense(currentLicenseId: number, state: FlagsState) {
    const nextLicenses = selectors
      .getNextLicenses(currentLicenseId, state)
      .filter((id) => selectors.getLicense(id, state).visible)
    return nextLicenses.length ? nextLicenses[0] : null
  },

  isLicenseUpgradeable(licenseId: string, state: FlagsState): boolean {
    const nextLicenseId = selectors.getNextVisibleLicense(licenseId, state)
    if (!nextLicenseId) return false
    const nextLicense = selectors.getLicense(nextLicenseId, state)
    return nextLicense && nextLicense.visible
  },
  /**
   * Function whichs returns whether the given feature is available based on the given flags state.
   */
  isFeatureAvailable(
    featureName: string,
    state: typeof INITIAL_STATE
  ): boolean {
    const feature = selectors.getFeature(featureName, state)

    return feature.enabled && feature.supported && feature.licensed
  },
}
