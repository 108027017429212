import { Icon } from 'ui/revision'

const BackgroundEffectIcon = (props) => (
  <Icon {...props}>
    <svg viewBox='0 0 19 19'>
      <path
        d='M45.407 384.6c1.54 0 2.9.37 4.003.85 1.02.453 1.661 1.473 1.661 2.577v1.53H39.742v-1.52c0-1.114.643-2.134 1.662-2.577 1.105-.49 2.464-.86 4.003-.86zm-5.907-3.1c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm12 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-6.093-3.83c1.566 0 2.832 1.265 2.832 2.832s-1.266 2.832-2.832 2.832c-1.567 0-2.832-1.265-2.832-2.832s1.265-2.832 2.832-2.832zM36.5 382c.28 0 .5.221.5.5 0 .28-.22.5-.5.5s-.5-.22-.5-.5c0-.279.22-.5.5-.5zm18 0c.28 0 .5.221.5.5 0 .28-.22.5-.5.5s-.5-.22-.5-.5c0-.279.22-.5.5-.5zm-15-4.5c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm12 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm3 .5c.28 0 .5.22.5.5s-.22.5-.5.5-.5-.22-.5-.5.22-.5.5-.5zm-18 0c.28 0 .5.22.5.5s-.22.5-.5.5-.5-.22-.5-.5.22-.5.5-.5zm3-4.5c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm4 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm4 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm4 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-8-2.5c.28 0 .5.22.5.5s-.22.5-.5.5-.5-.22-.5-.5.22-.5.5-.5zm4 0c.28 0 .5.22.5.5s-.22.5-.5.5-.5-.22-.5-.5.22-.5.5-.5z'
        transform='translate(-36 -371)'
      />
    </svg>
  </Icon>
)

export default BackgroundEffectIcon
