import type { SVGProps } from 'react'
const SvgAgile7Down = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle cx={82.17} cy={78.48} r={62} fill='rgb(var(--color-brand-50))' />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='m83.01 115.97 23.37-46.32-14.15.21-1.2-29.23-2.51-1.68H75.96l-2.24 1.68v29.3H58.67l11.71 24.84 12.63 21.2z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M82.87 119.41c-1.17 0-2.23-.65-2.76-1.7L57.72 73.24c-.49-.98-.44-2.12.13-3.05.57-.93 1.57-1.49 2.67-1.49h11.5V55.47c0-1.1.9-2 2-2s2 .9 2 2v13.9c0 1.84-1.5 3.33-3.33 3.33H61.94l20.95 41.6 20.95-41.6H93.09c-1.84 0-3.33-1.5-3.33-3.33V44.39c0-1.68-1.37-3.04-3.04-3.04h-7.63c-1.68 0-3.04 1.37-3.04 3.04v2.38c0 1.1-.9 2-2 2s-2-.9-2-2v-2.38c0-3.88 3.16-7.04 7.04-7.04h7.63c3.88 0 7.04 3.16 7.04 7.04V68.7h11.5c1.1 0 2.09.56 2.67 1.49.57.93.62 2.07.13 3.05L85.67 117.7a3.079 3.079 0 0 1-2.76 1.7z'
    />
  </svg>
)
export default SvgAgile7Down
