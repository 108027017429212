import NoiseCancellationFactory from '@enreach/noise-suppression'
import { BehaviorSubject, combineLatest } from 'rxjs'
import { filter } from 'rxjs/operators'

import ColigoTrackProcessor from 'lib/rtc/coligo-track-processor'
import coligoTrackFactory from 'lib/rtc/coligo-track-factory'

class NoiseCancellationProcessor extends ColigoTrackProcessor {
  /**
   *
   * @private
   */
  _context = new AudioContext()

  /**
   * @type {BehaviorSubject<NoiseCancellationFactory|null>}
   * @private
   */
  _noiseCancellationFactorySubject = new BehaviorSubject(null)

  /**
   * Returns true when the required parts of the processor has been initialised
   * for processing.
   *
   * @public
   */
  get initialised() {
    return Boolean(
      this._noiseCancellationFactorySubject &&
        this._noiseCancellationFactorySubject.value
    )
  }

  /**
   * @param [enabled=false]
   */
  constructor(enabled = false) {
    super(enabled, [])
  }

  _apply() {
    this._unsubscribeProcessingSubscriptions()

    this._processingSubscriptions.push(
      // When the effect is applied we connect the source (in$)
      // to the currently initialised MediaStreamEffect.
      combineLatest([this.in$, this._noiseCancellationFactorySubject])
        .pipe(filter((values) => !values.some((v) => !v)))
        .subscribe(this._connectSourceToEffectStream.bind(this))
    )

    this._processingSubscriptions.push(
      // When the effect is applied we make sure that there is no
      // output as long as there is no input or effect.
      combineLatest([this.in$, this._noiseCancellationFactorySubject])
        .pipe(filter((values) => values.some((v) => !v)))
        .subscribe(this._disconnectSourceFromEffectStream.bind(this))
    )

    if (!this.initialised) {
      this._noiseCancellationFactorySubject.next(
        new NoiseCancellationFactory(
          this._context,
          '/noise-cancellation/rnnoise.wasm',
          '/noise-cancellation/ns-processor.js'
        )
      )
    }
  }

  /**
   * @public
   * @returns {Promise<void>}
   */
  async resumeContextIfSuspended() {
    await this._context.resume()
  }

  /**
   * @param param0
   * @returns {Promise<void>}
   * @private
   */
  async _connectSourceToEffectStream([track, nsFactory]) {
    try {
      await this.resumeContextIfSuspended()

      const node = await nsFactory.createNoiseSuppressionNode()
      const source = this._context.createMediaStreamSource(track.stream)
      const effectDestination = this._context.createMediaStreamDestination()
      const gainNode = this._context.createGain()

      gainNode.gain.value = 1.3 // 130% volume

      source.connect(node)
      node.connect(gainNode)
      gainNode.connect(effectDestination)

      const effectStream = effectDestination.stream
      const effectTracks = effectStream.getAudioTracks()

      const trackOutput = coligoTrackFactory.createTrack(
        coligoTrackFactory.TYPES.BASE,
        {
          track: effectTracks[0],
          stream: effectStream,
          // TODO: Check if original stream can be the stream from the source track.
          originalStream: effectStream,
        }
      )

      this._outSubject.next(trackOutput)
    } catch (e) {
      // Rethrow the exception for error handling by the caller
      this._runningSubject.error(e)
    }
  }

  /**
   * @private
   */
  _disconnectSourceFromEffectStream() {
    this._outSubject.next(null)

    this._stopEffect()
  }

  /**
   * @private
   */
  _stopEffect() {
    if (this._context) {
      this._context.suspend()
    }
  }

  dispose() {
    super.dispose()

    this._stopEffect()

    this._noiseCancellationFactorySubject.complete()
  }
}

export default NoiseCancellationProcessor
