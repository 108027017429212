import { Typography } from 'ui/revision'

import nl2br from 'lib/newLineToBreak'

const FormTitle = ({ text, ...other }): JSX.Element => (
  <Typography
    gutterBottom
    style={{ textAlign: 'center' }}
    variant='h4'
    {...other}
  >
    {nl2br(text)}
  </Typography>
)

export default FormTitle
