import Task from 'lib/tasks/coligo-task'

import { DISCONNECT_REASONS } from 'constants/constants'

import { getMe, isHost as isHostFn } from 'app/state/utils'
import { getOnlineGuests } from 'app/state/utils/member-filters'
import roomMediaCommands from 'app/services/media/room-media-commands'

import { tryParseErrorMessage } from 'app/state/api/channels/utils'
import {
  STORE_NAME as CHANNEL_ROOM_STORE_NAME,
  actions as channelRoomActions,
  selectors as channelRoomSelectors,
} from 'app/state/api/channels/channel-room.reducer'
import { actions as waitingRoomActions } from 'app/features/waiting-room/waiting-room.reducer'
import {
  STORE_NAME as CONTENT_STORE_NAME,
  actions as contentActions,
  selectors as contentSelectors,
} from 'app/features/content/content.reducer'

/**
 * @class
 * @classdesc EndMeetingTask ends the meeting by kicking all guests, removing files and all messages from the meeting.
 */
class EndMeetingTask extends Task {
  /**
   * Function returns all guests who are in the room and online.
   * @param state
   * @returns {Object[]}
   */
  static getMeetingGuests(state) {
    const members = channelRoomSelectors.getMembers(
      state[CHANNEL_ROOM_STORE_NAME]
    )
    return getOnlineGuests(members)
  }

  name = 'EndMeetingTask'

  store

  cancelPromise

  _unsubscribe

  constructor(store) {
    super()
    this.store = store
  }

  async kickGuest(member) {
    try {
      await channelRoomActions.kickMember(
        member.member_id,
        DISCONNECT_REASONS.MEETING_ENDED
      )
    } catch (err) {
      const parsedMessage = tryParseErrorMessage(err)

      // When the guest already left -- we ignore the exception
      if (
        parsedMessage.reason !== 'Error while kicking member from Room false'
      ) {
        throw err
      }
    }
  }

  /**
   * Function kicks all guests who are in the room.
   * @returns {Promise<void>}
   */
  async kickAllGuests() {
    /**
     * Kick all guests who are in the room.
     */
    await Promise.all(
      EndMeetingTask.getMeetingGuests(this.store.getState()).map(
        this.kickGuest.bind(this)
      )
    )

    /**
     * When after kicking guests there are still guests in the room, we wait for them to go offline.
     * We wait for max 5 seconds for guests to leave the meeting.
     */
    if (EndMeetingTask.getMeetingGuests(this.store.getState()).length) {
      await new Promise((resolve, reject) => {
        const timeout = window.setTimeout(() => {
          reject(new Error('Not all guests left the meeting after 10 seconds'))
        }, 10_000)

        this._unsubscribe = this.store.subscribe(() => {
          const newState = this.store.getState()
          if (!EndMeetingTask.getMeetingGuests(newState).length) {
            this._unsubscribe()
            window.clearTimeout(timeout)
            resolve()
          }
        })
      })
    }
  }

  /**
   * Function cleans all events, messages and files from the meeting.
   * @returns {Promise<void>}
   */
  async cleanMeeting() {
    await channelRoomActions.removeAllFiles()
    // Remove all groupchat-messages
    this.store.dispatch(channelRoomActions.cleanMessages())
    // Remove all waitingroom-messages
    this.store.dispatch(waitingRoomActions.resetWaitingRoom())
  }

  /**
   * Function stops recording the content of the app if it was recording.
   * @returns {Promise<void>}
   */
  async stopRecording() {
    const { [CONTENT_STORE_NAME]: content } = this.store.getState()
    if (content && contentSelectors.isRecording(content)) {
      this.store.dispatch(contentActions.stopRecording())
    }
  }

  async run() {
    const currentState = this.store.getState()

    if (!channelRoomSelectors.isJoined(currentState[CHANNEL_ROOM_STORE_NAME])) {
      throw new Error('No room joined')
    }

    const me = getMe(currentState[CHANNEL_ROOM_STORE_NAME])
    if (!me || !isHostFn(me)) {
      throw new Error('Only host can end a meeting')
    }

    await new Promise((resolve, reject) => {
      this.cancelPromise = (err) => {
        reject(new Error(err))
      }

      this.store
        .dispatch(channelRoomActions.updateRoomMeta({ meetingEnded: true }))
        .then(async () =>
          Promise.all([
            this.stopRecording(),
            this.kickAllGuests(),
            roomMediaCommands.stopSendScreen(),
            this.cleanMeeting(),
          ])
        )
        .then(() => this.store.dispatch(channelRoomActions.resetRoomMeta()))
        .then(resolve)
        .catch(reject)
    })
  }

  cancel() {
    if (this._unsubscribe) {
      this._unsubscribe()
    }

    if (this.cancelPromise) {
      this.cancelPromise('EndMeetingTask got canceled while ending the meeting')
    }
  }
}

export default EndMeetingTask
