import { Icon, Tooltip } from 'ui/revision'
import { Icons } from 'constants/icons'

type Props = {
  icon?: Icons
  placement?: 'left' | 'right' | 'top' | 'bottom'
  text: string
}

const HelpAdornment: React.FC<Props> = ({
  icon = Icons.genericInfoAlt,
  placement = 'left',
  text,
}: Props) => (
  <Tooltip id={`tooltip-${placement}`} label={text} position={placement}>
    <Icon name={icon} />
  </Tooltip>
)

export default HelpAdornment
