import PropTypes from 'prop-types'
import cn from 'clsx'

import { withTheme } from 'app/ui'

import { ChatMessageTimestamp } from 'ui/chat/chat-message'

const SystemEvent = ({ children, className, icon, timestamp, theme }) => (
  <div className='message'>
    <div
      className={cn('system-event', className)}
      style={{ color: theme.palette.text.primary }}
    >
      {icon}
      {children}
      {!!timestamp && <ChatMessageTimestamp timestamp={timestamp} />}
    </div>
  </div>
)

SystemEvent.propTypes = {
  theme: PropTypes.objectOf(PropTypes.any).isRequired,
  timestamp: PropTypes.number.isRequired,

  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.node,
}

SystemEvent.defaultProps = {
  children: null,
  className: '',
  icon: null,
}

export default withTheme(SystemEvent)
