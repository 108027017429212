export default {
  BackgroundEffect: {
    menuButton: 'backgroundEffectMenuButton',
    backButton: 'backgroundEffectBackButton',
    rememberCheckbox: 'backgroundEffectRememberCheckbox',
  },
  ChatInput: {
    emojiPicker: 'chatInputEmojiPicker',
    fileUpload: 'chatInputFileUpload',
    send: 'chatInputSend',
    groupchatTabFile: 'groupchatTabFile',
  },
  DesktopSourcesList: {
    cancel: 'desktopSourcesListCancel',
    item: 'desktopSourcesListItem',
    submit: 'desktopSourcesListSubmit',
  },
  DevicePicker: {
    audioTest: 'devicePickerAudioTest',
    avatarButton: 'devicePickerAvatarButton',
    flipCamera: 'devicePickerFlipCamera',
    muteAudio: 'devicePickerMuteAudio',
    muteVideo: 'devicePickerMuteVideo',
    revert: 'devicePickerRevert',
    submit: 'devicePickerSubmit',
    capturedAvatar: 'capturedAvatar',
    expandAudioDevices: 'expandAudioDevices',
  },
  Dialogs: {
    Alert: {
      ok: 'alertDialogOk',
    },
    ChangeRoom: {
      cancel: 'changeRoomDialogCancel',
      submit: 'changeRoomDialogSubmit',
      roomName: 'roomName',
    },
    ClearMeeting: {
      cancel: 'clearMeetingDialogSubmit',
      submit: 'clearMeetingDialogCancel',
    },
    Confirm: {
      cancel: 'confirmDialogCancel',
      ok: 'confirmDialogOk',
    },
    EndMeeting: {
      cancel: 'endMeetingDialogCancel',
      submit: 'endMeetingDialogSubmit',
    },
    Error: {
      submit: 'errorDialogSubmit',
    },
    FileUpload: {
      cancel: 'fileUploadDialogCancel',
      submit: 'fileUploadDialogSubmit',
    },
    Hotkeys: {
      cancel: 'hotkeysDialogCancel',
    },
    InvitationForm: {
      cancel: 'invitationFormCancel',
      calendarTitle: 'calendarTitle',
      submit: 'invitationFormSubmit',
      back: 'invitationFormSubmitBack',
    },
    KickReason: {
      addReason: 'kickReasonFormAddReason',
      cancel: 'kickReasonFormCancel',
      removeReason: 'kickReasonFormRemoveReason',
      submit: 'kickReasonFormSubmit',
      reason: 'kickReason',
    },
    License: {
      row: 'licenseModalRow',
      submit: 'licenseModalSubmit',
    },
    LogoutForm: {
      cancel: 'logoutFormCancel',
      submit: 'logoutFormSubmit',
    },
    Share: {
      calendarInvite: 'shareDialogCalendarInvite',
      cancel: 'shareDialogCancel',
      copyInvite: 'shareDialogCopyInvite',
      shareInvite: 'shareDialogShareInvite',
      smsInvite: 'shareDialogSMSInvite',
    },
    ShareSourceSelect: {
      input: 'shareSourceSelectDialogInput',
      broadcast: 'shareSourceSelectDialogBroadcastVideo',
      cancel: 'shareSourceSelectDialogCancel',
      shareScreen: 'shareSourceSelectDialogShareScreen',
      upgrade: 'shareSourceSelectDialogUpgradeIcon',
    },
    Stickers: {
      Dialog: {
        cancel: 'stickersDialogCancel',
        submit: 'stickersDialogSubmit',
      },
      Drawer: {
        cancel: 'stickersDrawerCancel',
        submit: 'stickersDrawerSubmit',
      },
    },
    Shortcuts: {
      cancel: 'dialogShortcutsCancel',
    },
    NoAudio: {
      button: 'dialogNoAudioButton',
    },
    SMSInvite: {
      downloadIcs: 'dialogSMSInviteDownloadIcs',
      sendAgain: 'dialogSMSInviteSendAgain',
      submit: 'dialogSMSInviteSubmit',
      cancel: 'dialogSMSInviteCancel',
      back: 'dialogSMSInviteBack',
    },
    Notifications: {
      cancel: 'dialogNotificationsCancel',
      submit: 'dialogNotificationsSubmit',
    },
    close: 'dialogClose',
  },
  DisconnectForms: {
    Closed: {
      submit: 'closedFormSubmit',
    },
    Declined: {
      submit: 'declinedFormSubmit',
    },
    Expired: {
      submit: 'expiredFormSubmit',
    },
    Kicked: {
      submit: 'kickedFormSubmit',
    },
    MeetingEnded: {
      join: 'meetingEndedFormJoin',
      submit: 'meetingEndedFormSubmit',
    },
  },
  FileUploadQueue: {
    cancelFile: 'fileUploadQueueCancelFile',
    delete: 'fileUploadQueueDelete',
    remove: 'fileUploadQueueRemove',
    removeFailed: 'fileUploadQueueRemoveFailed',
    uploadSection: 'fileUploadQueueUploadSection',
  },
  Footer: {
    activeSpeaker: 'footerActiveSpeaker',
    fullscreen: 'footerFullscreen',
    gridSwitch: 'footerGridSwitch',
    gridToggle: 'footerGridToggle',
    slideLeft: 'footerSlideLeft',
    slideRight: 'footerSlideRight',
    stopScreenShare: 'footerStopScreenShare',
    toggle: 'footerToggle',
    ownScreensharePreviewVideo: 'ownScreensharePreviewVideo',
    hostScreensharePreviewVideo: 'hostScreensharePreviewVideo',
  },
  GridView: {
    calendarInvite: 'gridViewEmptyCalendarInvite',
    shareInvite: 'gridViewEmptyShareInvite',
    floatingVideoStream: 'floating-video-stream',
  },
  GuestSetup: {
    AvtestForm: {
      flipCamera: 'avtestFormFlipCamera',
      muteAudio: 'avtestFormMuteAudio',
      muteVideo: 'avtestFormMuteVideo',
      submit: 'avtestFormSubmit',
    },
    SetupForm: {
      submit: 'setupFormSubmit',
    },
    WaitingRoom: {
      muteAudio: 'waitingRoomMuteAudio',
      muteAudioLabel: 'waitingRoomMuteAudioLabel',
      muteVideo: 'waitingRoomMuteVideo',
      muteVideoLabel: 'waitingRoomMuteVideoLabel',
    },
  },
  HostSetup: {
    DeviceSetup: {
      submit: 'hostSetupDeviceSetupSubmit',
    },
    PageInvites: {
      copyCode: 'pageInvitesCopyCode',
      copyDialIn: 'pageInvitesCopyDialIn',
      copyName: 'pageInvitesCopyName',
      submit: 'pageInvitesSubmit',
    },
    PageRoomCode: {
      changeCode: 'pageRoomCodeChangeCode',
      submit: 'pageRoomCodeSubmit',
      roomCodeValue: 'roomCodeValue',
    },
    PageRoomName: {
      submit: 'pageRoomNameSubmit',
      settingsroomName: 'settingsroomName',
    },
  },
  LanguageDropDown: {
    toggle: 'languageDropDownToggle',
  },
  Login: {
    guestBackground: 'loginGuestBackground',
    guestBackgroundButton: 'loginGuestBackgroundButton',
    guestSubmit: 'guestSubmit',
    userBackground: 'loginUserBackground',
    userBackgroundButton: 'loginUserBackgroundButton',
    userClear: 'userClear',
    userSubmit: 'userSubmit',
    guestRoomCode: 'guestRoomCode',
    hostUserName: 'hostUserName',
    hostPassword: 'hostPassword',
    guestUserName: 'guestUserName',
  },
  MeetingFiles: {
    delete: 'meetingFilesDelete',
    deleteAll: 'meetingFilesDeleteAll',
    download: 'meetingFilesDownload',
    downloadAll: 'meetingFilesDownloadAll',
    selectAll: 'selectAll',
    meetingsFileType: 'meetingsFileType',
  },
  PrivateChat: {
    input: 'privateChatInput',
    close: 'privateChatClose',
    send: 'privateChatSend',
  },
  GroupChat: {
    send: 'groupChatSend',
    input: 'groupChatInput',
  },
  SidePanel: {
    toggle: 'sidePanelToggle',
    Tabs: {
      waitingroom: 'sidePanelTabsWaitingroom',
      chat: 'sidePanelTabsChat',
      users: 'sidePanelTabsUsers',
      files: 'sidePanelTabsFiles',
    },
  },
  RoomMessages: {
    acceptScreenShare: 'roomMessagesAcceptScreenShare',
    declineScreenShare: 'roomMessagesDeclineScreenShare',
    downloadFile: 'downloadFile',
  },
  SettingsDrawer: {
    DevicePicker: {
      avatar: 'settingsDrawerDevicesAvatar',
      apply: 'settingsDrawerDevicesApply',
      revert: 'settingsDrawerDevicesRevert',
    },
    Tabs: {
      devices: 'settingsDrawerDevicesTab',
      settings: 'settingsDrawerSettingsTab',
    },
    calendarInvite: 'settingsDrawerSettingsCalendarInvite',
    smsInvite: 'settingsDrawerSettingsSMSInvite',
    changeRoom: 'settingsDrawerSettingsChangeRoom',
    copyDialin: 'settingsDrawerCopyDialin',
    copyDialinUpgradeIcon: 'settingsDrawerCopyDialinUpgradeIcon',
    copyLink: 'settingsDrawerCopyLink',
    copyName: 'settingsDrawerCopyName',
    copyNameUpgradeIcon: 'settingsDrawerCopyNameUpgradeIcon',
    debugMode: 'settingsDrawerSettingsDebugMode',
    expand: 'settingsDrawerListExpand',
    passwordReset: 'settingsDrawerSettingsPasswordReset',
    playNotifications: 'settingsDrawerSettingsPlayNotifications',
    presentationMode: 'settingsDrawerSettingsPresentationMode',
    renewCode: 'settingsDrawerSettingsCodeRenew',
    saveFrames: 'settingsDrawerSettingsSaveFrames',
    screenRecorder: 'settingsDrawerSettingsScreenRecorder',
    showHotkeys: 'settingsDrawerSettingsShowHotkeys',
    showLicenses: 'settingsDrawerSettingsShowLicenses',
    stickers: 'settingsDrawerSettingsStickers',
    toggle: 'settingsDrawerToggle',
    toggleHotkeys: 'settingsDrawerSettingsToggleHotkeys',
    settingsUsername: 'settingsUserName',
    settingsRoomName: 'settingsRoomName',
    desktopNotifications: 'settingsDrawerSettingsDesktopNotifications',
    videoQuality: 'settingsDrawerSettingsVideoQuality',
  },
  SignupPage: {
    checkTermsOfServices: 'signupPageCheckTermsOfServices',
    closeDialog: 'signupPageCloseDialog',
    moreInfo: 'signupPageMoreInfo',
    signup: 'signupPageSignup',
  },
  Snackbar: {
    close: 'snackbarClose',
  },
  Stickers: {
    hideSticker: 'stickersHideSticker',
    nextCategory: 'stickersNextCategory',
    pinSticker: 'stickersPinSticker',
    previousCategory: 'stickersPreviousCategory',
    unpinSticker: 'stickersUnpinSticker',
    stickerId: 'stickerId',
  },
  TabMenu: {
    Chat: 'tabMenuGroupChat',
    Files: 'tabMenuFiles',
    Users: 'tabMenuUserList',
    WaitingRoom: 'tabMenuWaitingRoom',
  },
  UserList: {
    Entry: {
      cam: 'userListEntryCam',
      edit: 'userListEntryEdit',
      kick: 'userListEntryKick',
      mic: 'userListEntryMic',
      unread: 'userListEntryUnread',
      nameInput: 'userListEntryNameInput',
      granthost: 'userListEntryGrantHost',
    },
    endMeeting: 'userListEndMeeting',
    muteAllAudio: 'muteAllAudio',
    muteAllVideo: 'muteAllVideo',
    raiseHand: 'userListRaiseHand',
    recordMeeting: 'userListRecordMeeting',
    recordMeetingUpgradeIcon: 'userListRecordMeetingUpgradeIcon',
    stopRecordingMeeting: 'userListStopRecordingMeeting',
  },
  UserMenu: {
    endMeeting: 'userMenuEndMeeting',
    flipCamera: 'userMenuFlipCamera',
    main: 'userMenuMain',
    muteCam: 'userMenuMuteCam',
    muteMic: 'userMenuMuteMic',
    raiseHand: 'userMenuRaiseHand',
    screenShare: 'userMenuScreenShare',
    toggle: 'userMenuToggle',
    toggleSticker: 'userMenuToggleSticker',
  },
  VideoStreamer: {
    muteAudio: 'videoStreamerMuteAudio',
    muteMic: 'videoStreamerMuteMic',
    stop: 'videoStreamerStopBroadcast',
    videoStreamIds: 'video-stream-video',
  },
  WaitingRoom: {
    acceptMember: 'waitingRoomAcceptMember',
    autoAccept: 'waitingRoomAutoAccept',
    declineMember: 'waitingRoomDeclineMember',
    moreParticipants: 'waitingRoomMoreParticipants',
    chatsend: 'waitingRoomChatSend',
    input: 'waitingRoomInput',
  },
  SetupLayout: {
    back: 'setupLayoutBack',
  },
  audioRoom: 'audioRoom',
  logout: 'localStreamLogout',
  noPermissionSnackbarClose: 'noPermissionSnackbarClose',
  passwordFieldToggle: 'passwordFieldToggle',
  logoId: 'logo',
  emojiId: 'onlyEmoji',
  emptyImage: 'emptyImage',
  userCounterId: 'userCounter',
  localVideoStream: 'localVideoStream',
  mainVideoStream: 'mainVideoStream',
}
