import _ from 'lodash'

import { phoneTypePredicate } from './member-filters'

/**
 * Check whether the given member is dialed in.
 *
 * @param member
 * @param member.meta
 * @param member.meta.type
 * @return
 */
function isDialIn(member) {
  return _.isMatch(member, phoneTypePredicate)
}

export default isDialIn
