import * as Sentry from '@sentry/browser'

import ColigoListener from 'lib/rtc/coligo-listener'

import browserUtils from 'lib/browser-utils'
import createDummyAudio from 'lib/create-dummy-audio'

export class RoomOutput extends ColigoListener {
  /**
   * @type {HTMLAudioElement | null}
   */
  audioElement = null

  /**
   * @type {MediaDeviceInfo | null}
   */
  audioDevice = null

  /**
   * @type {ColigoTrack | null}
   */
  audioTrack = null

  /**
   * @protected
   *
   */
  _errorChecked = false

  /**
   *
   */
  get playing() {
    if (!this.audioElement) {
      return false
    }

    return !!(
      this.audioElement.currentTime > 0 &&
      !this.audioElement.paused &&
      !this.audioElement.ended &&
      this.audioElement.readyState > 2
    )
  }

  /**
   * Initialize the output with a playing audio element.
   * @returns {Promise<void>}
   */
  async initialize() {
    if (!this.audioTrack) {
      const audioTrack = createDummyAudio()

      this.audioTrack = audioTrack
    }

    if (!this.audioElement) {
      const audioElement = document.createElement('audio')

      await this.bindAudioElement(audioElement)
    } else {
      await this.bindAudioMix()
    }
  }

  /**
   * Bind the given audio element and output the bound audio track
   * over it.
   * @param element
   * @returns {Promise<void>}
   */
  async bindAudioElement(element) {
    if (!element) {
      throw new Error(`Cannot bind audio element: ${element}`)
    }

    this.audioElement = element

    this.audioElement.onplay = () => {
      this.emit('play')

      this._errorChecked = true
    }

    this.audioElement.onpause = () => {
      if (!this._errorChecked) {
        this._errorChecked = true

        if (!this.playing) {
          this.emit('playerror')
        }
      }
    }

    this.audioElement.onprogress = () => {
      if (!this._errorChecked) {
        this._errorChecked = true

        if (!this.playing) {
          this.emit('playerror')
        }
      }
    }

    this.audioElement.autoplay = true

    await this.bindAudioMix()
  }

  /**
   * Unbind the currently set audio element and remove all set variables.
   * @returns {Promise<void>}
   */
  async unbindAudioElement() {
    if (!this.audioElement) {
      return
    }

    this.audioElement.onplay = Function.prototype
    this.audioElement.onpause = Function.prototype
    this.audioElement.onprogress = Function.prototype
    this.audioElement.srcObject = null

    this.audioElement = null
  }

  /**
   * Bind the given audio track to the already set audio element.
   * @param track
   * @returns {Promise<void>}
   */
  async bindAudioTrack(track) {
    if (!track) {
      return
    }

    this.audioTrack = track

    await this.bindAudioMix()
  }

  /**
   * Bind the given device to the audio element. When null is given
   * the audio element will output the audio over the default device.
   * @param [device=null]
   * @returns {Promise<void>}
   */
  async bindAudioDevice(device = null) {
    if (device && !browserUtils.isSinkIdSupported()) {
      throw new Error(
        'Cannot select audio output device. This browser does not support setSinkId.'
      )
    }

    /**
     * Always set device, we might be setting it back to `null` to reselect
     * the default, and even in that case we need to call `bindAudioMix` in
     * order to update the sink ID to the empty string.
     */
    this.audioDevice = device

    await this.bindAudioMix()
  }

  /**
   * Called when the audio mix device can be bound to an element and track.
   * @returns {Promise<void>}
   */
  async bindAudioMix() {
    if (!this.audioElement) {
      return
    }

    if (this.audioTrack) {
      this.audioTrack.attach(this.audioElement)
    }

    /**
     * In usual operation, the output device is undefined, and so is the element
     * sink ID. In this case, don't throw an error, we're being called as a side
     * effect of just binding the audio element, not choosing an output device.
     */
    const shouldSetSinkId =
      this.audioDevice?.deviceId !== this.audioElement.sinkId

    if (shouldSetSinkId && !browserUtils.isSinkIdSupported()) {
      throw new Error(
        'Cannot select audio output device. This browser does not support setSinkId.'
      )
    }

    const newSinkId = this.audioDevice?.deviceId || ''
    const oldSinkId = this.audioElement.sinkId
    if (newSinkId === oldSinkId) {
      return
    }

    // Take the existing stream and temporarily unbind it while we change the sink ID.
    const existingAudioElement = this.audioElement
    const existingStream = this.audioTrack?.stream

    if (browserUtils.isChromium()) {
      existingAudioElement.srcObject = null
    }

    if (shouldSetSinkId) {
      await existingAudioElement.setSinkId(newSinkId)
    }

    if (browserUtils.isChromium()) {
      existingAudioElement.srcObject = existingStream
    }
  }
}

const roomOutput = new RoomOutput()

/**
 * @returns {Promise<void>}
 */
export async function initializeRoomOutput() {
  try {
    await roomOutput.initialize()
  } catch (err) {
    // Nothing we can do, but we make sure to keep track of the amount of times it fails
    Sentry.captureException(err)

    throw err
  }
}

/**
 * @returns {Promise<void>}
 */
export async function terminateRoomOutput() {
  try {
    await roomOutput.unbindAudioElement()
  } catch (err) {
    // Nothing we can do, but we make sure to keep track of the amount of times it fails
    Sentry.captureException(err)

    throw err
  }
}

export default roomOutput
