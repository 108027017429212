import {
  STORE_NAME as FLAGS_STORE_NAME,
  selectors as flagsSelectors,
} from 'app/features/flags/flags.reducer'
import { FEATURE_NAMES } from 'constants/features-map'

export function areBackgroundEffectsSupported(store) {
  const { [FLAGS_STORE_NAME]: flags } = store.getState()

  return !flagsSelectors.getFeaturesFailingKey(
    FEATURE_NAMES.BACKGROUND_EFFECTS,
    flags
  ).length
}
