import type { SVGProps } from 'react'
const SvgAgile8Weckingball = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle cx={82.17} cy={78.48} r={62} fill='rgb(var(--color-brand-50))' />
    <path
      fill='rgb(var(--color-brand-200))'
      d='m107.17 79.15 2.78-2.78c.92-.92.92-2.42 0-3.35l-4.54-4.54-3.82-3.82c-.92-.92-2.42-.92-3.35 0l-2.46 2.46c-.87.87-2.18 1.18-3.33.73-8.9-3.44-19.45-1.34-26.32 6.32-8.16 9.08-8.05 23.09.23 32.05 9.23 9.98 24.82 10.21 34.34.69 6.64-6.64 8.53-16.22 5.69-24.54-.38-1.13-.07-2.38.77-3.22z'
    />
    <path
      fill='rgb(var(--color-brand-500))'
      d='M83.89 115.89h-.52c-7.08-.14-13.64-3.08-18.46-8.3-8.95-9.68-9.06-24.94-.25-34.74 7.24-8.05 18.44-10.74 28.53-6.84.4.15.87.04 1.2-.28l2.46-2.46a4.37 4.37 0 0 1 6.17 0l8.35 8.35a4.37 4.37 0 0 1 0 6.17l-2.78 2.78c-.31.31-.42.77-.29 1.16 3.2 9.4.84 19.59-6.17 26.6-4.89 4.89-11.35 7.56-18.24 7.56zm-.08-47.65c-6.05 0-11.93 2.54-16.19 7.28-7.44 8.28-7.35 21.17.21 29.35 4.08 4.41 9.62 6.9 15.6 7.02 5.96.11 11.62-2.15 15.85-6.39 5.92-5.92 7.92-14.54 5.22-22.48-.62-1.83-.13-3.9 1.25-5.28l2.78-2.78c.14-.14.14-.38 0-.52l-8.35-8.35c-.18-.18-.34-.18-.52 0l-2.46 2.46c-1.45 1.45-3.59 1.91-5.47 1.18-2.6-1-5.28-1.49-7.93-1.49z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M111.5 64.72a2.004 2.004 0 0 1-1.41-3.42l12.92-12.92c.78-.78 2.05-.78 2.83 0s.78 2.05 0 2.83l-12.92 12.92c-.39.39-.9.59-1.41.59z'
    />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M73.75 103.05c-.51 0-1.02-.2-1.41-.59-6.76-6.75-6.76-17.75 0-24.5.78-.78 2.05-.78 2.83 0s.78 2.05 0 2.83c-5.2 5.2-5.2 13.65 0 18.85a2.004 2.004 0 0 1-1.41 3.42z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M63.1 67.07c-.51 0-1.02-.2-1.41-.59L47.53 52.32c-.78-.78-.78-2.05 0-2.83s2.05-.78 2.83 0l14.16 14.16a2.004 2.004 0 0 1-1.41 3.42zm-8.08 8.08c-.51 0-1.02-.2-1.41-.59l-3.36-3.36c-.78-.78-.78-2.05 0-2.83s2.05-.78 2.83 0l3.36 3.36a2.004 2.004 0 0 1-1.41 3.42zm-8.51-8.5c-.51 0-1.02-.2-1.41-.59l-9.23-9.23c-.78-.78-.78-2.05 0-2.83s2.05-.78 2.83 0l9.23 9.23a2.004 2.004 0 0 1-1.41 3.42zm-9.93 6.74c-.51 0-1.02-.2-1.41-.59l-2.82-2.82c-.78-.78-.78-2.05 0-2.83.78-.78 2.05-.78 2.83 0L38 69.97a2.004 2.004 0 0 1-1.41 3.42zm14.38 14.38c-.51 0-1.02-.2-1.41-.59l-9.23-9.23c-.78-.78-.78-2.05 0-2.83s2.05-.78 2.83 0l9.23 9.23a2.004 2.004 0 0 1-1.41 3.42z'
    />
  </svg>
)
export default SvgAgile8Weckingball
