import * as Sentry from '@sentry/browser'
import { useCallback, useState } from 'react'
import { ErrorBoundary, useErrorBoundary } from 'react-error-boundary'

import env from 'app/config/env'
import App from 'app/features/app'
import BlockerForm from 'app/features/app/blocker-form'
import NoConfigForm from 'app/features/app/no-config-form'
import Provider from 'app/features/provider'
import browserUtils from 'lib/browser-utils'
import useGlobalCCLFix from 'lib/ccl/global-style-fix'
import { useTranslation } from 'react-i18next'
import ErrorDialog from 'ui/error-dialog'
import { Button, Typography } from 'ui/revision'

const isSentryEnabled = !!env('sentry_config.dsn', false)
const noConfigFound = !env('api')

let reason: 'browser' | 'version'
if (!browserUtils.isSupportedBrowser()) {
  reason = 'browser'
} else if (!browserUtils.isSupportedVersion()) {
  reason = 'version'
}

const InnerRoot = (): JSX.Element => {
  if (noConfigFound) {
    return <NoConfigForm />
  }

  if (reason && window.location.pathname !== '/signup') {
    return <BlockerForm reason={reason} />
  }

  return <App />
}

export const Root = (): JSX.Element => {
  useGlobalCCLFix()

  const [eventId, setEventId] = useState<string | undefined>(undefined)

  const ErrorFallback = ({ error }): JSX.Element => {
    const { resetBoundary } = useErrorBoundary()
    const { t } = useTranslation()

    const backToHome = useCallback(() => {
      resetBoundary()
    }, [])

    const reportFeedback = useCallback(() => {
      Sentry.showReportDialog({ eventId })
      resetBoundary()
    }, [resetBoundary])

    return (
      <ErrorDialog
        open
        onHide={backToHome}
        title={t('Errors.generic.title', 'Something went wrong')}
        subtitle={error.message}
        buttonText={t('Errors.tryAgain', 'Try again')}
      >
        <Typography>{t('Errors.generic.description')}</Typography>
        <Button onClick={reportFeedback}>
          {t('Errors.generic.reportFeedback')}
        </Button>
      </ErrorDialog>
    )
  }

  const logError = useCallback(
    (error: Error, info: { componentStack: string }) => {
      // Do something with the error, e.g. log to an external API
      if (isSentryEnabled) {
        Sentry.withScope((scope) => {
          scope.setExtras({ componentStack: info.componentStack })
          const eventId = Sentry.captureException(error)
          setEventId(eventId)
        })
      }
    },
    []
  )

  return (
    <Provider>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
        <InnerRoot />
      </ErrorBoundary>
    </Provider>
  )
}

export default Root
