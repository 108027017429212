import { push } from '@lagunovsky/redux-react-router'

import Task from 'lib/tasks/coligo-task'
import CustomTask from 'lib/tasks/coligo-custom-task'
import SerialTask from 'lib/tasks/coligo-serial-task'
import ParallelTask from 'lib/tasks/coligo-parallel-task'
import OptionalTask from 'lib/tasks/coligo-optional-task'

import URLUtils from 'lib/url-utils'
import * as authUtils from 'lib/auth-utils'
import {
  STORE_NAME as CHANNEL_CONFERENCE_STORE_NAME,
  selectors as channelConferenceSelectors,
} from 'app/state/api/channels/channel-conference.reducer'
import { actions as socketActions } from 'app/state/api/socket/socket.reducer'

import JoinAuthChannelTask from 'app/tasks/join-auth-channel-task'
import JoinAvatarChannelTask from 'app/tasks/join-avatar-channel-task'
import JoinSettingsChannelTask from 'app/tasks/join-settings-channel-task'
import JoinConferenceChannelTask from 'app/tasks/join-conference-channel-task'
import RefreshOrGenerateTokenTask from 'app/tasks/refresh-or-generate-token-task'

class LoginAsUserTask extends Task {
  name = 'LoginAsUserTask'

  task

  store

  context

  /**
   *
   * @param store
   * @param context
   * @param [context.redirectToMyRoom=false]
   * @param context.apiUrl
   * @param context.apiUrlFallback
   * @param context.auth
   * @param context.auth.localToken
   * @param context.auth.sessionToken
   * @param context.auth.username
   * @param context.auth.password
   */
  constructor(store, context) {
    super()

    this.store = store
    this.context = context
  }

  cancel() {
    if (this.task) {
      this.task.cancel()
    }
  }

  async run() {
    const socketOptions = { domain: 'user' }
    if (this.context.auth.username && this.context.auth.password) {
      socketOptions.username = this.context.auth.username
      socketOptions.password = this.context.auth.password
    } else if (this.context.auth.sessionToken) {
      socketOptions.token = this.context.auth.sessionToken.token
    } else if (this.context.auth.localToken) {
      if (authUtils.isTokenExpired(this.context.auth.localToken)) {
        throw new Error(`${this.name} is canceled due to an expired token`)
      }
      socketOptions.token = this.context.auth.localToken.token
    } else {
      throw new Error('No valid arguments given for authentication')
    }

    const joinChannelsTasks = [
      new JoinAvatarChannelTask(this.store),
      new JoinSettingsChannelTask(this.store),
      new JoinConferenceChannelTask(this.store),
    ]

    if (
      !socketOptions.token ||
      (this.context.auth.localToken &&
        socketOptions.token === this.context.auth.localToken.token)
    ) {
      joinChannelsTasks.push(
        new OptionalTask(
          new RefreshOrGenerateTokenTask(this.store, {
            token: socketOptions.token,
          })
        )
      )
    }

    this.task = new SerialTask('LoginAsUserTaskSerialTask', [
      new CustomTask('ConnectSocketTask', () =>
        this.store.dispatch(
          socketActions.connect(
            this.context.apiUrl,
            socketOptions,
            this.context.apiUrlFallback
          )
        )
      ),
      new JoinAuthChannelTask(this.store),
      new ParallelTask('JoinChannelsParallelTask', joinChannelsTasks),
      new CustomTask('RedirectToMyMeetingTask', () => {
        if (this.context.redirectToMyRoom) {
          const conference = channelConferenceSelectors.getRoomConference(
            this.store.getState()[CHANNEL_CONFERENCE_STORE_NAME]
          )
          this.store.dispatch(
            push(
              URLUtils.getNewMeetingPathname(conference.name || conference.code)
            )
          )
        }
      }),
    ])
    await this.task.run()
  }
}

export default LoginAsUserTask
