import { BRAND_LIBRARY_FLAGS, BRAND_LIBRARY_LOGOS } from 'constants/constants'

export const CUSTOM_ICONS = {
  customBackgroundBlurHigh: 'BackgroundBlurHigh',
  customBackgroundBlurLow: 'BackgroundBlurLow',
  customBackgroundEffect: 'BackgroundEffect',
  customBackgroundImageSelected: 'BackgroundImageSelected',
  customFourSuares: 'FourSuares',
  customGears: 'Gears',
  customPin: 'Pin',
  customSidePanelClosed: 'SidePanelClosed',
  customSidePanelOpen: 'SidePanelOpen',
  customSpeechFilled: 'SpeechFilled',
  customSpeechOpen: 'SpeechOpen',
  customSticker: 'Sticker',
  customUnpin: 'Unpin',
  customWarning: 'Warning',
  customWrench: 'Wrench',
} as const

export const MUI_ICONS = {
  sms: 'sms', // TODO: Replace when EDS has it
  smsFailed: 'sms_failed', // TODO: Replace when EDS has it
} as const

/**
 * the Icons const contains all the icons that can be displayed by the Icon component
 * TODO: https://jira.voiceworks.com/browse/SUM-4730
 */
export const Icons = {
  actionAdd: 'action--add',
  actionArchive: 'action--archive',
  actionBackspace: 'action--backspace',
  actionBlock: 'action--block',
  actionBookmarkAltFilled: 'action--bookmark--alt--filled',
  actionBookmarkAltOutline: 'action--bookmark--alt--outline',
  actionBookmarkFilled: 'action--bookmark--filled',
  actionBookmarkOutline: 'action--bookmark--outline',
  actionCheckmark: 'action--checkmark',
  actionCloseDialog: 'action--close--dialog',
  actionClose: 'action--close',
  actionCopyAll: 'action--copy--all',
  actionCopyLink: 'action--copy--link',
  actionCopy: 'action--copy',
  actionDelete: 'action--delete',
  actionDownload: 'action--download',
  actionEditAlt: 'action--edit--alt',
  actionEdit: 'action--edit',
  actionFavouriteFilled: 'action--favourite--filled',
  actionFavouriteOutline: 'action--favourite--outline',
  actionFileImport: 'action--file--import',
  actionFilter: 'action--filter',
  actionFindReplace: 'action--find--replace',
  actionLeave: 'action--leave',
  actionLike: 'action--like',
  actionLockLock: 'action--lock--lock',
  actionLockUnlock: 'action--lock--unlock',
  actionMigrate: 'action--migrate',
  actionMinimize: 'action--minimize',
  actionOpen: 'action--open',
  actionRefresh: 'action--refresh',
  actionRemoveAlt: 'action--remove--alt',
  actionRemove: 'action--remove',
  actionReplyAll: 'action--reply--all',
  actionReplyForward: 'action--reply--forward',
  actionReply: 'action--reply',
  actionSave: 'action--save',
  actionSearch: 'action--search',
  actionSend: 'action--send',
  actionSettingsAlt: 'action--settings--alt',
  actionSettings: 'action--settings',
  actionShareAlt: 'action--share--alt',
  actionShare: 'action--share',
  actionStarFilled: 'action--star--filled',
  actionStarOutline: 'action--star--outline',
  actionTune: 'action--tune',
  communicationAvEarphones: 'communication--av--earphones',
  communicationAvHeadphonesBluetooth:
    'communication--av--headphones--bluetooth',
  communicationAvHeadphonesMic: 'communication--av--headphones--mic',
  communicationAvHeadphones: 'communication--av--headphones',
  communicationAvMicOff: 'communication--av--mic--off',
  communicationAvMicOn: 'communication--av--mic--on',
  communicationAvMic: 'communication--av--mic',
  communicationAvMusic: 'communication--av--music',
  communicationAvPlayerBackwardsAlt:
    'communication--av--player--backwards--alt',
  communicationAvPlayerBackwards: 'communication--av--player--backwards',
  communicationAvPlayerBeginning: 'communication--av--player--beginning',
  communicationAvPlayerEnd: 'communication--av--player--end',
  communicationAvPlayerForwardAlt: 'communication--av--player--forward--alt',
  communicationAvPlayerForward: 'communication--av--player--forward',
  communicationAvPlayerHold: 'communication--av--player--hold',
  communicationAvPlayerNext: 'communication--av--player--next',
  communicationAvPlayerPauseAlt: 'communication--av--player--pause--alt',
  communicationAvPlayerPause: 'communication--av--player--pause',
  communicationAvPlayerPlayAlt: 'communication--av--player--play--alt',
  communicationAvPlayerPlay: 'communication--av--player--play',
  communicationAvPlayerPrevious: 'communication--av--player--previous',
  communicationAvRaiseHand: 'communication--av--raise--hand',
  communicationAvRecord: 'communication--av--record',
  communicationAvShareScreenOff: 'communication--av--share--screen--off',
  communicationAvShareScreen: 'communication--av--share--screen',
  communicationAvSpeak: 'communication--av--speak',
  communicationAvVideoOff: 'communication--av--video--off',
  communicationAvVideo: 'communication--av--video',
  communicationAvVoice: 'communication--av--voice',
  communicationAvVolumeDown: 'communication--av--volume--down',
  communicationAvVolumeOff: 'communication--av--volume--off',
  communicationAvVolumeUp: 'communication--av--volume--up',
  communicationCallCallBusy: 'communication--call--call--busy',
  communicationCallCallEnd: 'communication--call--call--end',
  communicationCallCallList: 'communication--call--call--list',
  communicationCallCallOff: 'communication--call--call--off',
  communicationCallCallTune: 'communication--call--call--tune',
  communicationCallConferenceOnHold:
    'communication--call--conference--on--hold',
  communicationCallControlledDeviceRinging:
    'communication--call--controlled--device--ringing',
  communicationCallDialpad: 'communication--call--dialpad',
  communicationCallForwardBusy: 'communication--call--forward--busy',
  communicationCallForwardCall: 'communication--call--forward--call',
  communicationCallForwardNoAnswer: 'communication--call--forward--no--answer',
  communicationCallForwardToVoicemail:
    'communication--call--forward--to--voicemail',
  communicationCallForwardUnconditional:
    'communication--call--forward--unconditional',
  communicationCallIncoming: 'communication--call--incoming',
  communicationCallLoop: 'communication--call--loop',
  communicationCallMerge: 'communication--call--merge',
  communicationCallMissed: 'communication--call--missed',
  communicationCallNewCall: 'communication--call--new--call',
  communicationCallOutgoing: 'communication--call--outgoing',
  communicationCallPhonebookAlt: 'communication--call--phonebook--alt',
  communicationCallPhonebookColleagues:
    'communication--call--phonebook--colleagues',
  communicationCallPhonebook: 'communication--call--phonebook',
  communicationCallRepeat: 'communication--call--repeat',
  communicationCallSpeedDial: 'communication--call--speed--dial',
  communicationCallSplit: 'communication--call--split',
  communicationCallSwapDiagonal: 'communication--call--swap--diagonal',
  communicationCallSwap: 'communication--call--swap',
  communicationCallVoicemail: 'communication--call--voicemail',
  communicationCallVoip: 'communication--call--voip',
  communicationCallWaiting: 'communication--call--waiting',
  communicationChannel: 'communication--channel',
  communicationConnectionBluetoothOn:
    'communication--connection--bluetooth--on',
  communicationConnectionBluetooth: 'communication--connection--bluetooth',
  communicationConnectionCloudOff: 'communication--connection--cloud--off',
  communicationConnectionCloudUpload:
    'communication--connection--cloud--upload',
  communicationConnectionCloud: 'communication--connection--cloud',
  communicationConnectionWifiOff: 'communication--connection--wifi--off',
  communicationConnectionWifi: 'communication--connection--wifi',
  communicationDeviceDeskphone: 'communication--device--deskphone',
  communicationDeviceDesktop: 'communication--device--desktop',
  communicationDeviceDevices: 'communication--device--devices',
  communicationDeviceIntercom: 'communication--device--intercom',
  communicationDeviceKeyboard: 'communication--device--keyboard',
  communicationDeviceMobile: 'communication--device--mobile',
  communicationDeviceMouse: 'communication--device--mouse',
  communicationDevicePc: 'communication--device--pc',
  communicationDevicePhone: 'communication--device--phone',
  communicationDevicePrinter: 'communication--device--printer',
  communicationDeviceSpeakerJabra: 'communication--device--speaker--jabra',
  communicationDeviceSpeaker: 'communication--device--speaker',
  communicationDeviceTablet: 'communication--device--tablet',
  communicationDialog: 'communication--dialog',
  communicationGoogle: 'communication--google',
  communicationGroups: 'communication--groups',
  communicationMicrosoft: 'communication--microsoft',
  communicationStatusBlfBusy: 'communication--status--blf--busy',
  communicationStatusBlfIdle: 'communication--status--blf--idle',
  communicationStatusBlfOffline: 'communication--status--blf--offline',
  communicationStatusPresenceAvailableAlt:
    'communication--status--presence--available--alt',
  communicationStatusPresenceAvailable:
    'communication--status--presence--available',
  communicationStatusPresenceDnd: 'communication--status--presence--dnd',
  communicationStatusPresenceFocussed:
    'communication--status--presence--focussed',
  communicationStatusPresenceInvisible:
    'communication--status--presence--invisible',
  communicationTextChatAlt: 'communication--text--chat--alt',
  communicationTextChat: 'communication--text--chat',
  communicationTextEmailAlt: 'communication--text--email--alt',
  communicationTextEmail: 'communication--text--email',
  communicationTextEmoticon: 'communication--text--emoticon',
  communicationTextGif: 'communication--text--gif',
  communicationTextQuote: 'communication--text--quote',
  communicationTextStickerAlt: 'communication--text--sticker--alt',
  communicationTextSticker: 'communication--text--sticker',
  communicationTextStylingAlignmentCenter:
    'communication--text--styling--alignment--center',
  communicationTextStylingAlignmentLeft:
    'communication--text--styling--alignment--left',
  communicationTextStylingAlignmentRight:
    'communication--text--styling--alignment--right',
  communicationTextStylingBold: 'communication--text--styling--bold',
  communicationTextStylingBottom: 'communication--text--styling--bottom',
  communicationTextStylingBullets: 'communication--text--styling--bullets',
  communicationTextStylingCase: 'communication--text--styling--case',
  communicationTextStylingCode: 'communication--text--styling--code',
  communicationTextStylingCodeblock: 'communication--text--styling--codeblock',
  communicationTextStylingColor: 'communication--text--styling--color',
  communicationTextStylingHighlight: 'communication--text--styling--highlight',
  communicationTextStylingItalic: 'communication--text--styling--italic',
  communicationTextStylingLink: 'communication--text--styling--link',
  communicationTextStylingNumbers: 'communication--text--styling--numbers',
  communicationTextStylingParagraph: 'communication--text--styling--paragraph',
  communicationTextStylingStrikethrough:
    'communication--text--styling--strikethrough',
  communicationTextStylingTable: 'communication--text--styling--table',
  communicationTextStylingTop: 'communication--text--styling--top',
  communicationTextStylingUnderline: 'communication--text--styling--underline',
  communicationTextTextMessage: 'communication--text--text--message',
  communicationTextTypography: 'communication--text--typography',
  editorAddNew: 'editor--add--new',
  editorColorPicker: 'editor--color--picker',
  editorCut: 'editor--cut',
  editorDrag: 'editor--drag',
  editorDraw: 'editor--draw',
  editorDrop: 'editor--drop',
  editorRedo: 'editor--redo',
  editorSwapHorizontal: 'editor--swap--horizontal',
  editorSwapVertical: 'editor--swap--vertical',
  editorUndo: 'editor--undo',
  fileExeXls: 'file--exe--xls',
  fileFolder: 'file--folder',
  fileHtmlCss: 'file--html--css',
  fileMp3: 'file--mp3',
  fileMp4Gif: 'file--mp4--gif',
  filePdf: 'file--pdf',
  filePhotoAdd: 'file--photo--add',
  filePictureAdd: 'file--picture--add',
  filePicture: 'file--picture',
  filePictures: 'file--pictures',
  fileTxtDoc: 'file--txt--doc',
  fileZip: 'file--zip',
  genericActionsButtons: 'generic--actions--buttons',
  genericActivity: 'generic--activity',
  genericAlert: 'generic--alert',
  genericAttachment: 'generic--attachment',
  genericBasket: 'generic--basket',
  genericBranchAlt: 'generic--branch--alt',
  genericBranch: 'generic--branch',
  genericBriefcase: 'generic--briefcase',
  genericCalendar: 'generic--calendar',
  genericCameraChange: 'generic--camera--change',
  genericCamera: 'generic--camera',
  genericCart: 'generic--cart',
  genericCatalog: 'generic--catalog',
  genericChartAlt: 'generic--chart--alt',
  genericChart: 'generic--chart',
  genericChecklist: 'generic--checklist',
  genericCostBilling: 'generic--cost--billing',
  genericCostDollar: 'generic--cost--dollar',
  genericCostEuro: 'generic--cost--euro',
  genericCostPound: 'generic--cost--pound',
  genericCostPrice: 'generic--cost--price',
  genericDocument: 'generic--document',
  genericEffect: 'generic--effect',
  genericGame: 'generic--game',
  genericHierarchy: 'generic--hierarchy',
  genericHome: 'generic--home',
  genericInfoAlt: 'generic--info--alt',
  genericInfo: 'generic--info',
  genericLayers: 'generic--layers',
  genericLightbulb: 'generic--lightbulb',
  genericLocation: 'generic--location',
  genericLogo: 'generic--logo',
  genericNotebook: 'generic--notebook',
  genericOffice: 'generic--office',
  genericPin: 'generic--pin',
  genericPresentation: 'generic--presentation',
  genericProduct: 'generic--product',
  genericRecent: 'generic--recent',
  genericRoom: 'generic--room',
  genericSimCard: 'generic--sim--card',
  genericTime: 'generic--time',
  genericUserAddUser: 'generic--user--add--user',
  genericUserUserAlt: 'generic--user--user--alt',
  genericUserUserGroup: 'generic--user--user--group',
  genericUserUser: 'generic--user--user',
  genericWorld: 'generic--world',
  microArrowDown: 'micro--arrow--down',
  microArrowLeft: 'micro--arrow--left',
  microArrowRight: 'micro--arrow--right',
  microArrowUp: 'micro--arrow--up',
  microCheckmark: 'micro--checkmark',
  microChevronDown: 'micro--chevron--down',
  microChevronLeft: 'micro--chevron--left',
  microChevronRight: 'micro--chevron--right',
  microChevronUp: 'micro--chevron--up',
  microClear: 'micro--clear',
  microClose: 'micro--close',
  microEdit: 'micro--edit',
  microFavouriteFilled: 'micro--favourite--filled',
  microFavourite: 'micro--favourite',
  microFocus: 'micro--focus',
  microForward: 'micro--forward',
  microLock: 'micro--lock',
  microMore: 'micro--more',
  microMute: 'micro--mute',
  microPhone: 'micro--phone',
  microQuestionMark: 'micro--question--mark',
  microUnlock: 'micro--unlock',
  microUnmute: 'micro--unmute',
  navigationArrowDown: 'navigation--arrow--down',
  navigationArrowLeft: 'navigation--arrow--left',
  navigationArrowRight: 'navigation--arrow--right',
  navigationArrowUp: 'navigation--arrow--up',
  navigationChevronDown: 'navigation--chevron--down',
  navigationChevronLeft: 'navigation--chevron--left',
  navigationChevronRight: 'navigation--chevron--right',
  navigationChevronUpDown: 'navigation--chevron--up--down',
  navigationChevronUp: 'navigation--chevron--up',
  navigationCurrent: 'navigation--current',
  navigationMenu: 'navigation--menu',
  navigationMoreHorizontal: 'navigation--more--horizontal',
  navigationMoreVertical: 'navigation--more--vertical',
  navigationNext: 'navigation--next',
  navigationReturn: 'navigation--return',
  navigationScrollDown: 'navigation--scroll--down',
  navigationScrollUp: 'navigation--scroll--up',
  navigationSortDown: 'navigation--sort--down',
  navigationSortLeft: 'navigation--sort--left',
  navigationSortRight: 'navigation--sort--right',
  navigationSortUpDown: 'navigation--sort--up--down',
  navigationSortUp: 'navigation--sort--up',
  notificationError: 'notification--error',
  notificationSuccess: 'notification--success',
  notificationWarning: 'notification--warning',
  socialsAppStore: 'socials--app--store',
  socialsBehance: 'socials--behance',
  socialsDribble: 'socials--dribble',
  socialsDropbox: 'socials--dropbox',
  socialsFacebook: 'socials--facebook',
  socialsGooglePlay: 'socials--google--play',
  socialsGoogle: 'socials--google',
  socialsInstagram: 'socials--instagram',
  socialsLinkedin: 'socials--linkedin',
  socialsMicrosoft: 'socials--microsoft',
  socialsPinterest: 'socials--pinterest',
  socialsSnapchat: 'socials--snapchat',
  socialsSpotify: 'socials--spotify',
  socialsTwitter: 'socials--twitter',
  socialsVimeo: 'socials--vimeo',
  socialsWhatsapp: 'socials--whatsapp',
  socialsYoutube: 'socials--youtube',
  viewDark: 'view--dark',
  viewDensityLarge: 'view--density--large',
  viewDensityMedium: 'view--density--medium',
  viewDensitySmall: 'view--density--small',
  viewExpand: 'view--expand',
  viewFullScreenAlt: 'view--full--screen--alt',
  viewFullScreen: 'view--full--screen',
  viewGrid: 'view--grid',
  viewIncognito: 'view--incognito',
  viewLight: 'view--light',
  viewList: 'view--list',
  viewMode: 'view--mode',
  viewNewCard: 'view--new--card',
  viewReduceAlt: 'view--reduce--alt',
  viewReduce: 'view--reduce',
  viewTile: 'view--tile',
  viewTiles: 'view--tiles',
  viewVisibilityOff: 'view--visibility--off',
  viewVisibilityOn: 'view--visibility--on',
  viewWebsite: 'view--website',

  ...BRAND_LIBRARY_FLAGS,
  ...BRAND_LIBRARY_LOGOS,
  ...MUI_ICONS,
  ...CUSTOM_ICONS,
} as const

export type IconsConst = typeof Icons
export type IconsKey = keyof IconsConst
export type Icons = IconsConst[IconsKey]
