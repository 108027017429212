import { Icons } from 'constants/icons'
/*
 * TODO: https://jira.voiceworks.com/browse/SUM-4730
 */
export const snackBarIcons = {
  autorenew: Icons.actionFindReplace,
  avShare: Icons.communicationAvShareScreen,
  backgroundEffect: Icons.customBackgroundEffect,
  checkCircle: Icons.communicationStatusBlfIdle,
  close: Icons.actionClose,
  copy: Icons.actionCopy,
  error: Icons.notificationError,
  fileUpload: Icons.communicationConnectionCloudUpload,
  games: Icons.genericGame,
  genericUser: Icons.genericUserUser,
  grid: Icons.viewGrid,
  hand: Icons.communicationAvRaiseHand,
  message: Icons.communicationTextTextMessage,
  mic: Icons.communicationAvMic,
  micOff: Icons.communicationAvMicOff,
  sms: Icons.sms, // TODO: Replace when EDS has it
  smsFailed: Icons.smsFailed, // TODO: Replace when EDS has it
  videoCam: Icons.communicationAvVideo,
  videoCamOff: Icons.communicationAvVideoOff,
} as const

export type SnackBarIconsConst = typeof snackBarIcons
export type SnackBarIconsKey = keyof SnackBarIconsConst
export type SnackBarIcons = SnackBarIconsConst[SnackBarIconsKey]
