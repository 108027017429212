import localStore from 'app/services/state/local-store'

import * as Socket from './socket'
import { AnyAction, Dispatch } from 'redux'

// ------------------------------------
// Constants
// ------------------------------------
export const STORE_NAME = 'socket'

const CONNECTING = `sm-web/${STORE_NAME}/CONNECTING`
const CONNECT_ERROR = `sm-web/${STORE_NAME}/CONNECT_ERROR`
const CONNECT_CLOSED = `sm-web/${STORE_NAME}/CONNECT_CLOSED`
const CONNECTED = `sm-web/${STORE_NAME}/CONNECTED`
const LOG = `sm-web/${STORE_NAME}/LOG`

export const ACTION_TYPES = {
  CONNECTING,
  CONNECT_ERROR,
  CONNECT_CLOSED,
  CONNECTED,
  LOG,
}

const configDefaults = {
  app_name: import.meta.env.VITE_API_APP_NAME,
  app_ver: import.meta.env.VITE_API_APP_VERSION,
  device_id: 'meetings-web',
}
// ------------------------------------
// Actions
// ------------------------------------

const connected = (opts) => {
  logger.debug('Socket connection success ', opts)
  return { type: CONNECTED }
}
const connecting = (opts) => {
  logger.debug('Socket connecting', opts)
  return { type: CONNECTING }
}
const error = (event) => {
  logger.debug('Socket connection error ', event)
  return { type: CONNECT_ERROR, event }
}

const closed = (event): AnyAction => {
  logger.debug('Socket connection closed ', event)
  return { type: CONNECT_CLOSED, event }
}

const log = (kind, msg, data) => (dispatch) => {
  logger.debug(`${kind}:${msg}: `, data)

  if (localStore.getDebugMode()) {
    let serializableData
    try {
      serializableData = JSON.stringify(data)
    } catch (e) {
      serializableData = `unserializable data: ${
        (data && data.constructor.name) || 'null'
      }`
    }
    dispatch({ type: LOG, payload: { kind, msg, data: serializableData } })
  }
}

/**
 * @param conf
 * @param conf.domain
 * @param conf.token
 * @param conf.username
 * @param conf.password
 */
const getAsSocketParams = (conf) => {
  if (typeof conf !== 'object') {
    throw new TypeError(
      `"object" is not typeof object, instead "${typeof conf}"`
    )
  }
  const { domain, token, username, password } = conf
  switch (true) {
    case !!token && domain === 'user':
      return { ...configDefaults, token, domain }
    case !token && domain === 'guest':
      return { ...configDefaults, domain }
    case !token && domain === 'user':
      return { ...configDefaults, domain, username, password }
    default:
      throw new Error('Invalid login param combination', {
        domain,
        token,
        username,
        password,
      })
  }
}

const connect =
  (
    api: string,
    conf: { domain: string; token: string; username: string; password: string },
    apiFallback?: string
  ) =>
  async (dispatch: Dispatch<AnyAction>): Promise<unknown> => {
    if (typeof api !== 'string') {
      throw new TypeError(`"api" is not typeof string, instead "${typeof api}"`)
    }

    return new Promise((resolve, reject) => {
      const opts = {
        params: getAsSocketParams(conf),
        logger: (kind, msg, data) => dispatch(log(kind, msg, data)),
      }
      dispatch(connecting(opts))
      const socket = Socket.connect(api, opts)

      socket.onOpen((options) => {
        dispatch(connected(options))
        resolve(void 0)
      })

      socket.onError((event) => {
        // NOTE: Disconnect the socket to prevent it from unwantedly reconnecting.
        socket.disconnect()
        logger.warn(`Could not connect to "${api}"`, event)

        if (apiFallback) {
          logger.warn(`Falling back to "${apiFallback}"`)
          resolve(connect(apiFallback, conf)(dispatch))
        } else {
          dispatch(error(event))
          reject(new Error('Failed to connect'))
        }
      })

      socket.onClose((event) => dispatch(closed(event)))

      socket.connect()
    })
  }

/**
 * disconnect socket
 */
const disconnect =
  () =>
  async (dispatch: Dispatch<AnyAction>): Promise<void> =>
    new Promise((resolve) => {
      Socket.disconnect(() => {
        dispatch(closed('disconnected'))
        resolve()
      })
    })

export const actions = {
  connected,
  connecting,
  error,
  closed,
  connect,
  disconnect,
}

// ------------------------------------
// Reducer
// ------------------------------------
const INITIAL_STATE = {
  state: null,
  log: [],
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [CONNECTING]: (state) => ({
    state: CONNECTING,
    log: [
      ...state.log,
      { ts: Date.now(), kind: 'state', msg: 'CONNECTING', data: {} },
    ],
  }),
  [CONNECTED]: (state, action) => ({
    state: CONNECTED,
    log: [
      ...state.log,
      { ts: Date.now(), kind: 'state', msg: 'CONNECTED', data: action.event },
    ],
  }),
  [CONNECT_ERROR]: (state, action) => ({
    state: CONNECT_ERROR,
    log: [
      ...state.log,
      {
        ts: Date.now(),
        kind: 'state',
        msg: 'CONNECT_ERROR',
        data: action.event,
      },
    ],
  }),
  [CONNECT_CLOSED]: (state, action) => ({
    state: CONNECT_CLOSED,
    log: [
      ...state.log,
      {
        ts: Date.now(),
        kind: 'state',
        msg: 'CONNECT_CLOSED',
        data: action.event,
      },
    ],
  }),
  [LOG]: (state, action) => ({
    ...state,
    log: [...state.log, { ts: Date.now(), ...action.payload }],
  }),
  'sm-web/RESET': () => INITIAL_STATE,
}

export default (state = INITIAL_STATE, action = {}) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export const selectors = {
  getState(state) {
    return state.state
  },
  isConnecting(state) {
    return selectors.getState(state) === CONNECTING
  },
  isConnectError(state) {
    return selectors.getState(state) === CONNECT_ERROR
  },
  isConnectClosed(state) {
    return selectors.getState(state) === CONNECT_CLOSED
  },
  isConnected(state) {
    return selectors.getState(state) === CONNECTED
  },
}
