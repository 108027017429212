import {
  ROUTER_ON_LOCATION_CHANGED,
  push,
  replace,
} from '@lagunovsky/redux-react-router'
import _ from 'lodash'

import { ACTION_TYPES as ROOM_ACTION_TYPES } from 'app/features/room/room.reducer'
import {
  ACTION_TYPES as CHANNEL_AUTH_ACTION_TYPES,
  CHANNEL_ERROR_CODES as CHANNEL_AUTH_ERROR_CODES,
} from 'app/state/api/channels/channel-auth.reducer'
import { ACTION_TYPES as CHANNEL_CONFERENCE_ACTION_TYPES } from 'app/state/api/channels/channel-conference.reducer'
import {
  STORE_NAME as CHANNEL_ROOM_STORE_NAME,
  selectors as channelRoomSelectors,
} from 'app/state/api/channels/channel-room.reducer'
import { ACTION_TYPES as CHANNEL_SETTINGS_ACTION_TYPES } from 'app/state/api/channels/channel-settings.reducer'
import { ACTION_TYPES as SOCKET_ACTION_TYPES } from 'app/state/api/socket/socket.reducer'
import LogoutTask from 'app/tasks/logout-task'
import { AUTHENTICATION_ERRORS } from 'constants/constants'
import browserUtils from 'lib/browser-utils'
import urlUtils from 'lib/url-utils'

import { ACTION_TYPES, STORE_NAME, actions, selectors } from './auth.reducer'

const middleware = (store) => (next) => (action) => {
  const nextState = next(action)
  const { dispatch, getState } = store

  switch (action.type) {
    case ACTION_TYPES.SET_AUTHENTICATION_ERROR:
      if (action.payload) {
        const { router } = getState()

        let from

        // When the user tried to go to a route needing authentication, failed to login,
        // we reroute them to the login with the original route as next destination.
        if (router.location.pathname !== '/') {
          from = router.location

          // Filter the token parameter from the query string to avoid the invalid token
          // from being part of the query string after the user re-authenticates himself.
          if (from?.search) {
            const filteredParams = urlUtils.getFilteredUrlParams(from, [
              'token',
            ])

            from.search = urlUtils.stringifySearchParams(filteredParams)
          }
        }

        store.dispatch(replace('/', { from }))
      }
      break

    case ROUTER_ON_LOCATION_CHANGED:
      {
        const { [CHANNEL_ROOM_STORE_NAME]: channelRoom, [STORE_NAME]: auth } =
          getState()

        if (
          action.payload.location.pathname === '/' &&
          action.payload.action !== 'REPLACE' &&
          selectors.isAuthenticated(auth) &&
          channelRoomSelectors.isJoined(channelRoom)
        ) {
          // When the user was logged in and goes to the root page, we have to log him out.
          new LogoutTask(window.store).run().then(() => {
            if (browserUtils.isElectron()) {
              window.close()
            } else {
              store.dispatch(push('/'))
            }
          })
        }
      }
      break

    case CHANNEL_AUTH_ACTION_TYPES.JOINED:
      if (_.has(action, 'payload.user_id')) {
        // When the user joins the auth channel and get's a user_id he is logged in as a user.
        store.dispatch(actions.login(action.payload.user_id, 'user'))
      } else {
        store.dispatch(actions.login(null, 'guest'))
      }
      break

    case SOCKET_ACTION_TYPES.CONNECT_ERROR:
    case CHANNEL_AUTH_ACTION_TYPES.JOINING_ERROR:
    case CHANNEL_AUTH_ACTION_TYPES.JOINING_TIMEOUT:
    case CHANNEL_CONFERENCE_ACTION_TYPES.JOINING_ERROR:
    case CHANNEL_CONFERENCE_ACTION_TYPES.JOINING_TIMEOUT:
    case CHANNEL_SETTINGS_ACTION_TYPES.JOINING_ERROR:
    case CHANNEL_SETTINGS_ACTION_TYPES.JOINING_TIMEOUT:
      switch (action.error && action.error.code) {
        case CHANNEL_AUTH_ERROR_CODES.INVALID_CREDENTIALS:
          dispatch(
            actions.setAuthenticationError({
              type: AUTHENTICATION_ERRORS.INVALID_CREDENTIALS,
              description: action.error.description,
              code: action.error.code,
            })
          )
          break
        case CHANNEL_AUTH_ERROR_CODES.INVALID_TOKEN:
          dispatch(
            actions.setAuthenticationError({
              type: AUTHENTICATION_ERRORS.INVALID_TOKEN,
              description: action.error.description,
              code: action.error.code,
            })
          )
          break

        default:
          dispatch(
            actions.setAuthenticationError({
              type: AUTHENTICATION_ERRORS.CONNECTION_REFUSED,
              description: 'timeout hit',
            })
          )
          break
      }
      break

    case ROOM_ACTION_TYPES.JOINING_MEETING_ERROR:
      {
        const { [STORE_NAME]: auth } = getState()
        if (selectors.getAuthenticatedUser(auth).type === 'guest') {
          dispatch(
            actions.setAuthenticationError({
              type: AUTHENTICATION_ERRORS.INVALID_CODE,
            })
          )
        }
      }
      break

    case SOCKET_ACTION_TYPES.CONNECT_CLOSED:
      store.dispatch(actions.setIsAuth(false))
      break
    default:
  }

  return nextState
}
export default middleware
