import type { SVGProps } from 'react'
const SvgReaction5Clapping = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle cx={82.17} cy={78.48} r={62} fill='rgb(var(--color-brand-50))' />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='m126.45 121.6-16.69-17.79 2.11-12.22-5.27-11.49-12.87-16.98-6.98-6.98-5.08 1.33-2.46 5.09-13.87-16.82-5.38-2.16-4.74 2.79-.84 2.51-4.74-3.35-3.35-.84-5.3 5.02 2.23 6.14-4.47.84-3.91 3.91 8.09 14.23-6.14 2.79-1.67 5.3 30.98 39.63 7.26 3.07 5.58 1.12 5.02-1.12 11.55 13.43c12.01-2.72 22.68-8.91 30.93-17.47z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M92.16 138.17c-.57 0-1.14-.24-1.53-.71l-7.62-9.07a.287.287 0 0 0-.28-.11c-7.62 1.49-15.31-1.39-20.08-7.52l-26.97-34.7c-2.64-3.39-2.02-8.3 1.37-10.93s8.29-2.02 10.93 1.37l10.4 13.38c.68.87.52 2.13-.35 2.81-.87.68-2.13.52-2.81-.35l-10.4-13.38a3.799 3.799 0 0 0-5.32-.67 3.799 3.799 0 0 0-.67 5.32l26.97 34.7c3.83 4.93 10.02 7.24 16.15 6.05 1.52-.3 3.1.26 4.1 1.46l7.62 9.07c.71.85.6 2.11-.24 2.82-.38.31-.83.47-1.29.47zM67.44 87.8c-.6 0-1.19-.27-1.58-.77l-24.41-31.4c-1.28-1.64-1.84-3.68-1.58-5.75s1.31-3.91 2.95-5.18a7.768 7.768 0 0 1 5.75-1.58c2.06.26 3.91 1.31 5.18 2.95l22.98 29.56c.68.87.52 2.13-.35 2.81-.87.67-2.13.52-2.81-.35L50.59 48.53c-.62-.8-1.52-1.31-2.52-1.43-1.01-.13-2 .15-2.8.77-.8.62-1.31 1.52-1.44 2.52s.15 2 .77 2.8l24.41 31.4c.68.87.52 2.13-.35 2.81-.37.28-.8.42-1.23.42z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M55.68 91.58c-.6 0-1.19-.27-1.58-.77L35.15 66.43c-2.64-3.39-2.02-8.3 1.37-10.93s8.29-2.02 10.93 1.37l20.38 26.22c.68.87.52 2.13-.35 2.81-.87.68-2.13.52-2.81-.35L44.29 59.33c-.62-.8-1.52-1.31-2.52-1.43-1.01-.13-2 .15-2.8.77a3.799 3.799 0 0 0-.67 5.32l18.95 24.38c.68.87.52 2.13-.35 2.81-.37.28-.8.42-1.23.42zM74.1 77.26c-.6 0-1.19-.27-1.58-.77L55 53.95a7.713 7.713 0 0 1-1.58-5.75 7.725 7.725 0 0 1 2.95-5.18 7.768 7.768 0 0 1 5.75-1.58c2.06.26 3.91 1.31 5.18 2.95l15.73 20.23c.68.87.52 2.13-.35 2.81-.87.67-2.13.52-2.81-.35L64.14 46.85c-.62-.8-1.52-1.31-2.52-1.43-1.01-.13-2 .15-2.8.77a3.79 3.79 0 0 0-1.44 2.52c-.12 1 .15 2 .77 2.8l17.52 22.54c.68.87.52 2.13-.35 2.81-.37.28-.8.42-1.23.42z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M121.89 119.26c-.56 0-1.12-.23-1.51-.69l-11.11-12.84c-.86-1-1.11-2.4-.65-3.66 2.62-7.1 1.42-14.9-3.22-20.87L88.61 59.6c-.62-.8-1.52-1.31-2.52-1.43-1.01-.13-2 .15-2.8.77-.8.62-1.31 1.52-1.43 2.52s.15 2 .77 2.8l11.08 14.25c.68.87.52 2.13-.35 2.81-.87.68-2.13.52-2.81-.35L79.47 66.72c-1.28-1.64-1.84-3.68-1.58-5.75s1.31-3.91 2.95-5.18a7.768 7.768 0 0 1 5.75-1.58c2.06.26 3.91 1.31 5.18 2.95l16.79 21.6c5.46 7.02 6.9 16.18 3.88 24.53l10.96 12.68c.72.84.63 2.1-.2 2.82-.38.33-.84.49-1.31.49z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M99.02 53.13c-.6 0-1.19-.27-1.58-.77L84.65 35.9c-.62-.8-1.52-1.31-2.52-1.43-1-.13-2 .15-2.8.77-.8.62-1.31 1.52-1.43 2.52s.15 2 .77 2.8l4.04 5.19c.68.87.52 2.13-.35 2.81-.87.68-2.13.52-2.81-.35l-4.04-5.19c-1.28-1.64-1.84-3.68-1.58-5.75s1.31-3.91 2.95-5.18a7.768 7.768 0 0 1 5.75-1.58c2.06.26 3.91 1.31 5.18 2.95l12.79 16.46c.68.87.52 2.13-.35 2.81-.37.28-.8.42-1.23.42z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M127.7 90.96a1.9 1.9 0 0 1-.77-.16c-1.02-.42-1.5-1.6-1.08-2.62 3.01-7.22 1.9-15.54-2.89-21.71l-16.79-21.6a3.799 3.799 0 0 0-5.32-.67c-.8.62-1.31 1.52-1.43 2.52s.15 2 .77 2.8l11.08 14.25c.68.87.52 2.13-.35 2.81-.87.67-2.13.52-2.81-.35L97.03 51.98c-1.28-1.64-1.84-3.68-1.58-5.75s1.31-3.91 2.95-5.18c3.39-2.63 8.29-2.02 10.93 1.37l16.79 21.6c5.68 7.31 6.99 17.15 3.43 25.7-.32.77-1.06 1.23-1.85 1.23zM83.8 18.52c-1.1 0-2-.9-2-2V8.66c0-1.1.9-2 2-2s2 .9 2 2v7.86c0 1.1-.9 2-2 2zm12.61 5.54A2.004 2.004 0 0 1 95 20.64l5.19-5.19c.78-.78 2.05-.78 2.83 0s.78 2.05 0 2.83l-5.19 5.19c-.39.39-.9.59-1.41.59zm-24.84 0c-.51 0-1.02-.2-1.41-.59l-5.19-5.19c-.78-.78-.78-2.05 0-2.83s2.05-.78 2.83 0l5.19 5.19a2.004 2.004 0 0 1-1.41 3.42z'
    />
  </svg>
)
export default SvgReaction5Clapping
