import Home from 'ui/revision/views/home'
import DialInInfo from 'ui/dial-in-info'
import { RoutePaths } from 'constants/route-paths'
import { useMatch } from 'react-router-dom'

type PropTypes = {} & React.ComponentPropsWithoutRef<typeof DialInInfo>

const DialInPage = (props: PropTypes): JSX.Element => {
  /**
   * FIXME: https://github.com/remix-run/react-router/issues/10594
   * workaround for issue with react-router where optional route param
   * doesnt work with useMatch hook
   */
  const pattern = RoutePaths.DIALIN.replace(':meetingIdentifier?', '*')
  const match = useMatch(pattern)
  const { '*': meetingIdentifier } = match.params

  return (
    <Home>
      <DialInInfo meetingIdentifier={meetingIdentifier} {...props} />
    </Home>
  )
}

export default DialInPage
