import type { SVGProps } from 'react'
const SvgEmoji9 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle
      cx={79.46}
      cy={96.28}
      r={48.66}
      fill='rgb(var(--color-brand-100))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.46 146.94c-27.93 0-50.66-22.72-50.66-50.66s22.72-50.66 50.66-50.66 50.66 22.72 50.66 50.66-22.72 50.66-50.66 50.66zm0-97.31c-25.73 0-46.66 20.93-46.66 46.66s20.93 46.66 46.66 46.66 46.66-20.93 46.66-46.66-20.93-46.66-46.66-46.66z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M90.85 94.21a1.82 1.82 0 0 1-1.3-3.1c3.32-3.38 8.18-5.32 13.32-5.32s9.99 1.94 13.32 5.32c.71.72.7 1.87-.02 2.58s-1.87.7-2.58-.02c-2.64-2.69-6.55-4.23-10.72-4.23s-8.07 1.54-10.72 4.23c-.36.36-.83.55-1.3.55zm-49.27 0a1.82 1.82 0 0 1-1.3-3.1c3.32-3.38 8.18-5.32 13.32-5.32s9.99 1.94 13.32 5.32c.71.72.7 1.87-.02 2.58s-1.87.7-2.58-.02c-2.64-2.69-6.55-4.23-10.72-4.23s-8.07 1.54-10.72 4.23c-.36.36-.83.55-1.3.55zm42.44 20.15a7.667 7.667 0 0 0 2.65-6.87c-.57-4.18-4.44-7.12-8.62-6.55-1.12.15-2.16.54-3.1 1.15-.85.55-1.09 1.67-.55 2.52.55.85 1.68 1.09 2.52.55a3.93 3.93 0 0 1 1.62-.6 4.01 4.01 0 0 1 4.51 3.43 4.01 4.01 0 0 1-3.43 4.51c-.94.13-1.6.95-1.57 1.87-.03.92.63 1.74 1.57 1.87 2.19.3 3.72 2.32 3.43 4.51-.3 2.19-2.33 3.73-4.51 3.43-.59-.08-1.13-.28-1.62-.6-.85-.54-1.98-.3-2.52.55-.55.85-.3 1.98.55 2.52.94.61 1.99.99 3.1 1.15.35.05.7.07 1.04.07 3.76 0 7.05-2.79 7.58-6.62.37-2.69-.72-5.24-2.65-6.87z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='m134.53 130.16-14.41-14.41a7.707 7.707 0 1 1 10.9-10.9l3.5 3.5 3.5-3.5a7.707 7.707 0 1 1 10.9 10.9l-14.41 14.41z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M134.53 131.66c-.4 0-.78-.16-1.06-.44l-14.41-14.41c-3.59-3.59-3.59-9.43 0-13.02s9.43-3.59 13.02 0l2.44 2.44 2.44-2.44c3.59-3.59 9.43-3.59 13.02 0s3.59 9.43 0 13.02l-14.41 14.41c-.28.28-.66.44-1.06.44zm-8.96-27.57c-1.66 0-3.22.65-4.39 1.82a6.217 6.217 0 0 0 0 8.78l13.35 13.35 13.35-13.35a6.217 6.217 0 0 0 0-8.78c-1.17-1.17-2.73-1.82-4.39-1.82s-3.22.65-4.39 1.82l-3.5 3.5c-.59.59-1.54.59-2.12 0l-3.5-3.5a6.173 6.173 0 0 0-4.39-1.82z'
    />
  </svg>
)
export default SvgEmoji9
