import type { SVGProps } from 'react'
const SvgEmoji2 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle
      cx={78.99}
      cy={96.28}
      r={48.66}
      fill='rgb(var(--color-brand-100))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M78.99 146.94c-27.93 0-50.66-22.72-50.66-50.66s22.72-50.66 50.66-50.66 50.66 22.72 50.66 50.66-22.72 50.66-50.66 50.66zm0-97.31c-25.73 0-46.66 20.93-46.66 46.66s20.93 46.66 46.66 46.66 46.66-20.93 46.66-46.66-20.93-46.66-46.66-46.66z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M78.99 111.89c-5.15 0-10.07-.98-14.58-2.76-1.34-.53-2.81.43-2.82 1.87v.13c0 8.3 7.79 15.04 17.41 15.04s17.41-6.73 17.41-15.04V111c-.01-1.43-1.49-2.39-2.82-1.87a39.713 39.713 0 0 1-14.58 2.76z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M78.99 127.16c-10.15 0-18.41-7.19-18.41-16.04v-.13c0-1 .51-1.93 1.34-2.49.84-.57 1.91-.68 2.85-.31 4.53 1.78 9.31 2.69 14.22 2.69s9.69-.9 14.22-2.69c.94-.37 2.01-.25 2.85.31a3.02 3.02 0 0 1 1.34 2.48v.14c0 8.84-8.26 16.04-18.41 16.04zm-15.35-17.18c-.21 0-.42.06-.6.19-.29.19-.45.5-.46.84v.12c0 7.74 7.36 14.04 16.41 14.04s16.41-6.3 16.41-14.04V111c0-.33-.17-.63-.46-.83-.3-.2-.66-.24-1-.11-4.76 1.88-9.79 2.83-14.95 2.83s-10.19-.95-14.95-2.83c-.13-.05-.26-.08-.4-.08z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M79.03 126.16c3.72 0 7.17-1.01 10-2.73-1.81-3.72-5.61-6.29-10-6.29s-8.2 2.57-10 6.29c2.83 1.72 6.28 2.73 10 2.73z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.03 127.16c-3.78 0-7.42-1-10.52-2.88a.996.996 0 0 1-.38-1.29c2.05-4.23 6.23-6.85 10.9-6.85s8.85 2.62 10.9 6.85c.23.46.06 1.02-.38 1.29-3.1 1.88-6.74 2.88-10.52 2.88zm-8.66-4.12c2.6 1.39 5.57 2.12 8.66 2.12s6.06-.73 8.66-2.12c-1.83-3.05-5.07-4.9-8.66-4.9s-6.83 1.86-8.66 4.9z'
    />
    <circle
      cx={120.03}
      cy={58.64}
      r={4.66}
      fill='rgb(var(--color-neutral-50))'
    />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M24.07 117.24c-2.55 0-4.98-1.08-6.68-2.98-3.32-3.68-3.02-9.38.67-12.69 2.77-2.5 10.84-5.34 15.14-5.34 1.48 0 2.49.33 3.1 1.01 1.22 1.36.9 4.37-.96 8.96-1.17 2.9-3.26 6.93-5.26 8.73a8.961 8.961 0 0 1-6.01 2.31z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M33.2 97.48c1.02 0 1.8.18 2.17.6 1.83 2.03-2.95 13.07-6.13 15.93a7.718 7.718 0 0 1-5.17 1.99c-2.12 0-4.23-.86-5.75-2.56a7.745 7.745 0 0 1 .57-10.93c2.53-2.28 10.31-5.02 14.31-5.02m0-2.5c-4.62 0-12.98 2.96-15.98 5.66-2.03 1.83-3.23 4.34-3.37 7.07s.78 5.35 2.61 7.39c1.94 2.15 4.71 3.39 7.61 3.39 2.53 0 4.96-.93 6.85-2.63 2.17-1.95 4.36-6.17 5.59-9.19 3.09-7.64 1.31-9.61.73-10.26-.86-.96-2.18-1.42-4.03-1.42z'
    />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M133.9 117.24c-2.22 0-4.36-.82-6.01-2.31-2.01-1.81-4.09-5.83-5.26-8.73-1.86-4.59-2.18-7.6-.96-8.96.61-.68 1.63-1.01 3.1-1.01 4.3 0 12.37 2.84 15.14 5.34 3.68 3.32 3.98 9.01.67 12.69a9.01 9.01 0 0 1-6.68 2.98z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M124.77 97.48c4 0 11.78 2.74 14.31 5.02 3.18 2.86 3.43 7.75.57 10.93a7.715 7.715 0 0 1-5.75 2.56 7.7 7.7 0 0 1-5.17-1.99c-3.18-2.86-7.96-13.89-6.13-15.93.37-.42 1.15-.6 2.17-.6m0-2.5c-1.85 0-3.17.47-4.03 1.42-.58.65-2.36 2.63.73 10.26 1.22 3.02 3.42 7.24 5.59 9.19 1.88 1.7 4.32 2.63 6.85 2.63 2.9 0 5.67-1.24 7.61-3.39 1.83-2.03 2.76-4.66 2.61-7.39s-1.34-5.24-3.37-7.07c-3-2.7-11.36-5.66-15.98-5.66zm-33.16-.76a1.82 1.82 0 0 1-1.3-3.1c3.32-3.38 8.18-5.32 13.32-5.32s9.99 1.94 13.32 5.32c.71.72.7 1.87-.02 2.58s-1.87.7-2.58-.02c-2.64-2.69-6.55-4.23-10.72-4.23s-8.07 1.54-10.72 4.23c-.36.36-.83.55-1.3.55zm-49.28 0a1.82 1.82 0 0 1-1.3-3.1c3.32-3.38 8.18-5.32 13.32-5.32s9.99 1.94 13.32 5.32c.71.72.7 1.87-.02 2.58s-1.87.7-2.58-.02c-2.64-2.69-6.55-4.23-10.72-4.23s-8.07 1.54-10.72 4.23c-.36.36-.83.55-1.3.55z'
    />
  </svg>
)
export default SvgEmoji2
