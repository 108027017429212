import env from 'app/config/env'
/**
 * peer connection configuration
 * @TODO this configuration object should be provided through signaling
 * @type {{iceServers: [null,null]}}
 */
export default {
  iceServers: env('ice_servers'),
  sdpSemantics: 'plan-b',
}
