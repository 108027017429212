import type { SVGProps } from 'react'
const SvgAgile4Check = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle cx={82.17} cy={78.48} r={62} fill='rgb(var(--color-brand-50))' />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M93.55 63.92 75.69 81.41 60.25 68.57 48.71 80.1l26.42 27.91 40-39.63.93-3.9-11.53-10.61-10.98 10.05z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='m75.51 111.3-.05-.05-1.5-1.43-25.27-25.27c-1.14-1.14-1.76-2.65-1.76-4.25s.63-3.12 1.76-4.25l7.53-7.53c2.35-2.34 6.16-2.34 8.5 0l10.75 10.75 25.08-25.08c2.35-2.34 6.16-2.34 8.5 0l7.53 7.53c1.14 1.14 1.76 2.65 1.76 4.25s-.63 3.12-1.76 4.25l-9.95 9.95c-.81.81-2.12.81-2.93 0a2.07 2.07 0 0 1 0-2.93l9.95-9.95c.35-.35.55-.82.55-1.32s-.19-.97-.55-1.32l-7.53-7.53c-.73-.73-1.91-.73-2.64 0L75.47 85.13 61.78 71.44c-.73-.73-1.91-.73-2.64 0l-7.53 7.53c-.35.35-.55.82-.55 1.32s.19.97.55 1.32l23.85 23.85 21.19-21.2a2.07 2.07 0 0 1 2.93 0c.81.81.81 2.12 0 2.93l-24.09 24.09z'
    />
  </svg>
)
export default SvgAgile4Check
