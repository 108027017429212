import { useEffect } from 'react'
import PropTypes from 'prop-types'

const Favicon = ({ href }) => {
  useEffect(() => {
    let element = document.getElementById('favicon')
    if (!element) {
      const link = document.createElement('link')
      link.setAttribute('id', 'favicon')
      link.setAttribute('rel', 'shortcut icon')
      link.setAttribute('type', 'image/png')
      document.head.appendChild(link)

      element = link
    }
    element.href = href
  }, [href])
  return null
}

Favicon.propTypes = {
  href: PropTypes.string.isRequired,
}

export default Favicon
