import dotProp from 'dot-prop-immutable'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

/**
 * handler for all messages on the channel. this function must always return the payload
 * @see https://hexdocs.pm/phoenix/js/#channel
 * NOTE: this function should return a function that has dispatch in its scope!
 * NOTE: this is not an actioncreator / thunk!
 */
export const handleMessage =
  (dispatch, channelEventActionMapping, channelConf, log) =>
  (event, payload) => {
    dispatch(log(event, payload))
    const actionCreator = channelEventActionMapping[event]
    if (actionCreator) {
      dispatch(actionCreator(payload))
    } else {
      logger.debug(
        `${channelConf.id} received event ${event} but no actioncreator found in "channelEventActionMapping"`,
        payload
      )
    }
    return payload
  }
/**
 * helper function that creates a function that logs a state change for phoenix channel handling reducers
 * @param stateConst
 * @param stateMsg
 * @param actionPropAsLogData
 * @param mergeActionPayload
 */
export const createStateLoggingActionHandler =
  (stateConst, stateMsg, actionPropAsLogData = false) =>
  (state, action) => {
    let returnState = dotProp.set(state, '@@channel.state', stateConst)
    returnState = dotProp.set(returnState, '@@channel.log', (log) => [
      ...log,
      {
        ts: Date.now(),
        kind: 'state',
        msg: stateMsg,
        data: actionPropAsLogData ? action[actionPropAsLogData] : {},
      },
    ])
    return returnState
  }

/**
 * properly format the chat message to send
 * @param content
 * @return
 */
export const formatChatMessageForSending = (content) => {
  const localId = uuid()
  const newContent = {
    type: 'message',
    data: { local_id: localId, message_type: 'text', message_data: {} },
  }

  /**
   * NOTE: for now we send with chat also the client events, we will have in the future here only pure chat messages.
   *
   */
  const isEvent = _.has(content, 'content.type')

  /**
   * if it is an event it has a different format
   */
  if (isEvent) {
    newContent.data.message_data = content.content
    newContent.data.message_data.text = ''
  } else {
    // the chat messages have the content the actual message
    newContent.data.message_data.text = content
  }

  return newContent
}

/**
 * Try to parse the given errors message.
 * @param error
 * @return
 */
export const tryParseErrorMessage = (error) => {
  let parsedMessage
  try {
    const matches = error.message.match(/^".+?": (.+$)/)
    if (matches && matches.length >= 2) {
      parsedMessage = JSON.parse(matches[1])
    } else {
      parsedMessage = JSON.parse(error.message)
    }
  } catch {
    parsedMessage = {}
  }
  return parsedMessage
}
