import { useEffect, useState } from 'react'
import type { Middleware } from 'redux'
import _ from 'lodash'

import type {
  RootFeatureKeys,
  RootReducers,
} from 'app/services/state/redux-store'

import { useStoreContext } from 'app/features/provider/store-provider.component'
import type { StoreProviderCommands } from 'app/features/provider/store-provider.component'

interface FeatureProps {
  initialPayload?: any
}

interface FeatureHOCOptions<N extends RootFeatureKeys> {
  middleware?: Middleware | Middleware[]
  reducer?: RootReducers<N>
  commands?: StoreProviderCommands
  removeOnUnmount?: boolean
}

function FeatureHOC<N extends RootFeatureKeys>(
  name: N,
  options: FeatureHOCOptions<N> = {}
) {
  if (!name) throw new Error('A Feature requires a name')

  return <P extends object>(
    Component: React.ComponentType<P>
  ): React.FC<P & FeatureProps> => {
    const Feature = ({ initialPayload, ...other }: P & FeatureProps) => {
      const [loaded, setLoaded] = useState(false)
      const {
        loadMiddleware,
        loadReducer,
        loadCommands,
        unloadMiddleware,
        unloadReducer,
        unloadCommands,
      } = useStoreContext()

      useEffect(() => {
        logger.debug(`Feature loading: ${name}`, options)

        if (options.middleware) {
          const middlewares = Array.isArray(options.middleware)
            ? options.middleware
            : [options.middleware]

          loadMiddleware(...middlewares)
        }

        if (options.commands) {
          loadCommands(options.commands)
        }

        if (options.reducer) {
          loadReducer(name, options.reducer, initialPayload)
        }

        setLoaded(true)

        return () => {
          if (_.get(options, 'removeOnUnmount', false)) {
            // NOTE: Middleware has to be removed first, otherwise it might result in actions acted upon in middleware without state of the feature being there due to removal of the reducer.
            if (options.middleware) {
              const middlewares = Array.isArray(options.middleware)
                ? options.middleware
                : [options.middleware]
              unloadMiddleware(...middlewares)
            }

            if (options.reducer) {
              unloadReducer(name)
            }

            if (options.commands) {
              unloadCommands(options.commands)
            }
          }
        }
      }, [])

      return loaded ? <Component {...(other as P)} /> : null
    }

    Feature.displayName = `Feature(${Component.displayName})`

    return Feature
  }
}

export default FeatureHOC
