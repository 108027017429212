import type { SVGProps } from 'react'
const SvgAgileCard8 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <rect
      width={94.33}
      height={126.14}
      x={33.78}
      y={17.68}
      fill='rgb(var(--color-neutral-50))'
      rx={8.63}
      ry={8.63}
    />
    <path
      fill='rgb(var(--color-brand-500))'
      d='M119.48 145.82H42.41c-5.86 0-10.63-4.77-10.63-10.63V26.31c0-5.86 4.77-10.63 10.63-10.63h77.07c5.86 0 10.62 4.77 10.62 10.63V135.2c0 5.86-4.77 10.63-10.62 10.63zM42.41 19.68c-3.65 0-6.63 2.97-6.63 6.63V135.2c0 3.65 2.97 6.63 6.63 6.63h77.07c3.65 0 6.62-2.97 6.62-6.63V26.31c0-3.65-2.97-6.63-6.62-6.63H42.41z'
    />
    <rect
      width={79.02}
      height={110.67}
      x={41.43}
      y={25.42}
      fill='rgb(var(--color-brand-100))'
      rx={2.78}
      ry={2.78}
    />
    <circle cx={50.06} cy={34.43} r={5.57} fill='rgb(var(--color-brand-200))' />
    <circle
      cx={111.83}
      cy={127.08}
      r={5.57}
      fill='rgb(var(--color-brand-200))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M80.03 103.67c-3.87 0-6.91-1.08-9.04-3.21-2.13-2.13-3.21-5.2-3.21-9.11v-3.97c0-2.4.41-4.46 1.21-6.14a8.641 8.641 0 0 1 2.48-3.09 8.548 8.548 0 0 1-2.25-2.85c-.79-1.6-1.18-3.62-1.18-6.02v-1.86c0-3.91 1.06-6.97 3.14-9.1 2.09-2.14 5.07-3.22 8.86-3.22s6.77 1.08 8.86 3.22c2.08 2.13 3.14 5.19 3.14 9.1v1.86c0 3.94-1.16 6.91-3.46 8.86 1.08.85 1.92 1.89 2.5 3.11.8 1.68 1.21 3.74 1.21 6.14v3.97c0 3.91-1.08 6.97-3.21 9.11s-5.17 3.21-9.04 3.21zm0-20.8c-1.34 0-2.72.45-2.72 3.81v4.29c0 3.35 1.37 3.81 2.72 3.81s2.72-.45 2.72-3.81v-4.29c0-3.35-1.37-3.81-2.72-3.81zm0-18.88c-1.28 0-2.46.39-2.46 3.23v3.52c0 2.84 1.18 3.23 2.46 3.23s2.46-.39 2.46-3.23v-3.52c0-2.84-1.18-3.23-2.46-3.23z'
    />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M80.03 56.34c3.46 0 6.11.95 7.97 2.85 1.86 1.9 2.78 4.64 2.78 8.22v1.86c0 4.27-1.45 7.21-4.35 8.83v.13c1.62.85 2.79 2.04 3.52 3.55.73 1.51 1.09 3.38 1.09 5.6v3.97c0 3.58-.95 6.33-2.85 8.22s-4.62 2.85-8.16 2.85-6.26-.95-8.16-2.85c-1.9-1.9-2.85-4.64-2.85-8.22v-3.97c0-2.22.36-4.08 1.09-5.6.73-1.51 1.9-2.7 3.52-3.55v-.13c-1.49-.81-2.59-1.93-3.3-3.36-.7-1.43-1.06-3.25-1.06-5.47v-1.86c0-3.58.93-6.33 2.78-8.22s4.51-2.85 7.97-2.85m0 18.88c2.47 0 3.71-1.49 3.71-4.48v-3.52c0-2.99-1.24-4.48-3.71-4.48s-3.71 1.49-3.71 4.48v3.52c0 2.99 1.24 4.48 3.71 4.48m0 20.8c2.65 0 3.97-1.69 3.97-5.06v-4.29c0-3.37-1.32-5.06-3.97-5.06s-3.97 1.69-3.97 5.06v4.29c0 3.37 1.32 5.06 3.97 5.06m0-42.18c-4.14 0-7.42 1.21-9.76 3.6-2.32 2.37-3.5 5.73-3.5 9.97v1.86c0 2.59.44 4.81 1.31 6.58.41.84.93 1.6 1.54 2.28-.73.76-1.32 1.62-1.78 2.58-.89 1.85-1.33 4.09-1.33 6.68v3.97c0 4.25 1.2 7.62 3.58 9.99 2.37 2.38 5.71 3.58 9.93 3.58s7.55-1.2 9.93-3.58 3.58-5.74 3.58-9.99v-3.97c0-2.59-.45-4.83-1.33-6.68a9.764 9.764 0 0 0-1.82-2.62c1.61-1.82 2.89-4.6 2.89-8.81v-1.86c0-4.24-1.18-7.6-3.5-9.97-2.33-2.39-5.62-3.6-9.76-3.6zm0 18.88c-.7 0-1.21 0-1.21-1.98v-3.52c0-1.98.51-1.98 1.21-1.98s1.21 0 1.21 1.98v3.52c0 1.98-.51 1.98-1.21 1.98zm0 20.8c-.72 0-1.47 0-1.47-2.56v-4.29c0-2.56.75-2.56 1.47-2.56s1.47 0 1.47 2.56v4.29c0 2.56-.75 2.56-1.47 2.56z'
    />
  </svg>
)
export default SvgAgileCard8
