import type { SVGProps } from 'react'
const SvgEmoji8 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle cx={79.4} cy={96.28} r={48.66} fill='rgb(var(--color-brand-100))' />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.4 146.94c-27.93 0-50.66-22.72-50.66-50.66S51.46 45.62 79.4 45.62s50.66 22.72 50.66 50.66-22.72 50.66-50.66 50.66zm0-97.31c-25.73 0-46.66 20.93-46.66 46.66s20.93 46.66 46.66 46.66 46.66-20.93 46.66-46.66-20.93-46.66-46.66-46.66z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M79.4 111.89c-5.15 0-10.07-.98-14.58-2.76-1.34-.53-2.81.43-2.82 1.87v.13c0 8.3 7.79 15.04 17.41 15.04s17.41-6.73 17.41-15.04V111c-.01-1.43-1.49-2.39-2.82-1.87a39.713 39.713 0 0 1-14.58 2.76z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.4 127.16c-10.15 0-18.41-7.19-18.41-16.04v-.15c.01-.98.51-1.91 1.34-2.46.84-.57 1.91-.69 2.85-.31 4.53 1.78 9.31 2.69 14.22 2.69s9.69-.9 14.22-2.69c.94-.37 2.01-.25 2.85.31a3.02 3.02 0 0 1 1.34 2.48v.14c0 8.84-8.26 16.04-18.4 16.04zm-15.35-17.18c-.21 0-.42.06-.6.19-.29.19-.45.5-.46.84v.12c0 7.74 7.36 14.04 16.41 14.04s16.41-6.3 16.41-14.04v-.1c0-.35-.17-.66-.46-.85-.3-.2-.67-.24-1-.11-4.76 1.88-9.79 2.83-14.95 2.83s-10.19-.95-14.95-2.83c-.13-.05-.26-.08-.4-.08z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M79.4 126.16c3.72 0 7.17-1.01 10-2.73-1.81-3.72-5.61-6.29-10-6.29s-8.2 2.57-10 6.29c2.83 1.72 6.28 2.73 10 2.73z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.4 127.16c-3.79 0-7.42-1-10.52-2.88a.996.996 0 0 1-.38-1.29c2.05-4.23 6.23-6.85 10.9-6.85s8.85 2.62 10.9 6.85c.23.46.06 1.02-.38 1.29-3.1 1.88-6.74 2.88-10.52 2.88zm-8.66-4.12c2.6 1.39 5.57 2.12 8.66 2.12s6.06-.73 8.66-2.12c-1.83-3.05-5.07-4.9-8.66-4.9s-6.83 1.86-8.66 4.9z'
    />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='m103.83 70.92 3.89 7.88c.2.41.59.69 1.04.75l8.69 1.26c1.13.16 1.58 1.55.76 2.35l-6.29 6.13c-.32.32-.47.77-.4 1.22l1.49 8.66c.19 1.12-.99 1.98-2 1.45l-7.78-4.09c-.4-.21-.88-.21-1.28 0l-7.78 4.09c-1.01.53-2.19-.33-2-1.45l1.49-8.66c.08-.45-.07-.9-.4-1.22l-6.29-6.13c-.82-.8-.37-2.18.76-2.35l8.69-1.26c.45-.07.84-.35 1.04-.75l3.89-7.88c.5-1.02 1.96-1.02 2.47 0z'
    />
    <path
      fill='rgb(var(--color-brand-500))'
      d='M93.54 102.28c-.59 0-1.18-.19-1.69-.55a2.881 2.881 0 0 1-1.15-2.81l1.47-8.6-6.24-6.09c-.79-.77-1.07-1.9-.73-2.95a2.86 2.86 0 0 1 2.32-1.96l8.63-1.25 3.86-7.82c.49-.99 1.48-1.6 2.58-1.6s2.09.61 2.58 1.6l3.86 7.82 8.63 1.25c1.09.16 1.98.91 2.32 1.96.34 1.05.06 2.18-.73 2.95l-6.24 6.09 1.47 8.59c.19 1.09-.25 2.17-1.15 2.81s-2.05.73-3.03.22l-7.72-4.06-7.72 4.06c-.42.22-.88.33-1.34.33zm9.06-7.41c.46 0 .92.11 1.34.33l7.55 3.97-1.44-8.41c-.16-.94.15-1.89.83-2.55l6.11-5.96-8.44-1.23c-.94-.14-1.75-.73-2.17-1.57l-3.78-7.65-3.78 7.65a2.86 2.86 0 0 1-2.16 1.57l-8.45 1.23 6.11 5.96c.68.66.99 1.61.83 2.55l-1.44 8.41 7.55-3.97c.42-.22.88-.33 1.34-.33zm-.11-23.29z'
    />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='m57.41 70.92 3.89 7.88c.2.41.59.69 1.04.75l8.69 1.26c1.13.16 1.58 1.55.76 2.35l-6.29 6.13c-.32.32-.47.77-.4 1.22l1.49 8.66c.19 1.12-.99 1.98-2 1.45l-7.78-4.09c-.4-.21-.88-.21-1.28 0l-7.78 4.09c-1.01.53-2.19-.33-2-1.45l1.49-8.66c.08-.45-.07-.9-.4-1.22l-6.29-6.13c-.82-.8-.37-2.18.76-2.35L50 79.55c.45-.07.84-.35 1.04-.75l3.89-7.88c.5-1.02 1.96-1.02 2.47 0z'
    />
    <path
      fill='rgb(var(--color-brand-500))'
      d='M47.11 102.28c-.59 0-1.18-.19-1.69-.55a2.855 2.855 0 0 1-1.14-2.81l1.47-8.59-6.25-6.09c-.79-.77-1.07-1.9-.73-2.95a2.86 2.86 0 0 1 2.32-1.96l8.63-1.25 3.86-7.82c.49-.99 1.48-1.6 2.58-1.6s2.09.61 2.58 1.6l3.86 7.82 8.63 1.25c1.09.16 1.98.91 2.32 1.96.34 1.05.06 2.18-.73 2.95l-6.25 6.09 1.47 8.59a2.84 2.84 0 0 1-1.14 2.81c-.89.65-2.05.73-3.03.22l-7.72-4.06-7.72 4.06c-.42.22-.88.33-1.34.33zm9.06-7.41c.46 0 .92.11 1.34.33l7.55 3.97-1.44-8.41c-.16-.94.15-1.89.83-2.55l6.11-5.96-8.45-1.23c-.94-.14-1.75-.73-2.17-1.57l-3.78-7.65-3.78 7.65a2.86 2.86 0 0 1-2.16 1.57l-8.45 1.23 6.11 5.96c.68.66.99 1.61.83 2.54l-1.44 8.41 7.55-3.97c.42-.22.88-.33 1.34-.33z'
    />
  </svg>
)
export default SvgEmoji8
