import env from 'app/config/env'
import SnackbarQueue from 'app/features/snackbar-queue'
import { makeStyles } from 'app/ui'
import Favicon from 'ui/favicon'

import Routes from './app.routes'

const envBrandConfig = env('brand_config') as { favico: string }

const useStyles = makeStyles(
  {
    '@global': {
      '.dragging *': {
        pointerEvents: 'none',

        '&.drop-target': {
          pointerEvents: 'all',
        },
      },
      '#root': {
        position: 'fixed',
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
      },
    },
  },
  { name: 'App' }
)

type Props = {
  brandConfig?: { favico: string }
}

const App: React.FC<Props> = ({ brandConfig = envBrandConfig }: Props) => {
  useStyles()

  return (
    <>
      <Favicon href={brandConfig?.favico} />
      <Routes />
      <SnackbarQueue />
    </>
  )
}

export default App
