import _ from 'lodash'

import coligoMediaDevices from 'lib/rtc/coligo-media-devices'
import { groupDevicesByKind } from 'lib/rtc/devices'

import {
  ACTION_TYPES as SETTINGS_ACTION_TYPES,
  STORE_NAME as SETTINGS_STORE_NAME,
  selectors as settingsSelectors,
} from 'app/features/settings/settings.reducer'
import { ACTION_TYPES as CONFERENCE_ACTION_TYPES } from 'app/features/conference/conference.reducer'

import { ACTION_TYPES, STORE_NAME, actions, selectors } from './devices.reducer'

const noLabelPredicate = { label: '' }

/**
 * Returns true when the given list of devices only contain devices
 * with labels.
 *
 * @param devices List of available devices
 * @returns
 */
function hasDevicesWithLabels(devices) {
  return !_.isEmpty(devices) && _.isEmpty(_.filter(devices, noLabelPredicate))
}

/**
 * Returns true when there was a device previously selected and
 * it is now missing from the given list of devices.
 *
 * @param devices List of available devices
 * @param selectedDeviceLabel Label from the currently selected device.
 * @returns
 */
function isOldDeviceMissing(devices, selectedDeviceLabel) {
  if (!selectedDeviceLabel) {
    return false
  }

  return !_.some(devices, { label: selectedDeviceLabel })
}

/**
 * Returns true when a device was selected but it is no longer available but
 * there are alternative devices available.
 *
 * @param devices List of available devices
 * @param selectedDeviceLabel Label from the currently selected device.
 * @returns
 */
function needToChangeDevices(devices, selectedDeviceLabel) {
  if (
    isOldDeviceMissing(devices, selectedDeviceLabel) &&
    hasDevicesWithLabels(devices)
  ) {
    return true
  }

  return false
}

function handleUpdateDeviceListPreUpdate(store, action) {
  const { [STORE_NAME]: devices } = store.getState()

  const { camDevices, micDevices } = groupDevicesByKind(action.payload)

  const camPermission = _.chain(camDevices)
    .filter(noLabelPredicate)
    .isEmpty()
    .value()

  if (camPermission !== selectors.getPermissionsForCam(devices)) {
    store.dispatch(actions.setCamPermissions(camPermission))
  }

  const micPermission = _.chain(micDevices)
    .filter(noLabelPredicate)
    .isEmpty()
    .value()

  if (micPermission !== selectors.getPermissionsForMic(devices)) {
    store.dispatch(actions.setMicPermissions(micPermission))
  }
}

function handleStoreInit(store) {
  coligoMediaDevices.addListener('devicelistchanged', (devices) =>
    store.dispatch(actions.updateDeviceList(devices))
  )

  coligoMediaDevices.enumerateDevices().then((devices) => {
    store.dispatch(actions.updateDeviceList(devices))
  })
}

function handleUpdateDeviceList(store, action) {
  const { [SETTINGS_STORE_NAME]: settings } = store.getState()
  const { camDevices, micDevices, outDevices } = groupDevicesByKind(
    action.payload
  )

  const selectedCamDeviceLabel =
    settingsSelectors.getSelectedCamDeviceLabel(settings)

  if (needToChangeDevices(camDevices, selectedCamDeviceLabel)) {
    const alternativeDevice = camDevices[0]
    store.dispatch(
      actions.chooseDevices({
        camDevice: {
          deviceId: alternativeDevice.deviceId,
          label: alternativeDevice.label,
        },
      })
    )
  } else if (isOldDeviceMissing(camDevices, selectedCamDeviceLabel)) {
    store.dispatch(actions.camDeviceMissing())
  }

  const selectedMicDeviceLabel =
    settingsSelectors.getSelectedMicDeviceLabel(settings)

  if (needToChangeDevices(micDevices, selectedMicDeviceLabel)) {
    const alternativeDevice = micDevices[0]
    store.dispatch(
      actions.chooseDevices({
        micDevice: {
          deviceId: alternativeDevice.deviceId,
          label: alternativeDevice.label,
        },
      })
    )
  } else if (isOldDeviceMissing(micDevices, selectedMicDeviceLabel)) {
    store.dispatch(actions.micDeviceMissing())
  }

  const selectedOutDeviceLabel =
    settingsSelectors.getSelectedOutDeviceLabel(settings)

  if (needToChangeDevices(outDevices, selectedOutDeviceLabel)) {
    const alternativeDevice = outDevices[0]
    store.dispatch(
      actions.chooseDevices({
        outDevice: {
          deviceId: alternativeDevice.deviceId,
          label: alternativeDevice.label,
        },
      })
    )
  } else if (isOldDeviceMissing(outDevices, selectedOutDeviceLabel)) {
    store.dispatch(actions.outDeviceMissing())
  }
}

function handleSetCurrentCamDevice(store, action) {
  const { [STORE_NAME]: devices } = store.getState()

  const hasVideo = !!action.payload

  if (devices.hasVideo !== hasVideo) {
    store.dispatch(actions.setHasVideo(hasVideo))
  }
}

function handleSetCurrentMicDevice(store, action) {
  const { [STORE_NAME]: devices } = store.getState()

  const hasAudio = !!action.payload

  if (devices.hasAudio !== hasAudio) {
    store.dispatch(actions.setHasAudio(hasAudio))
  }
}

function handleUpdateSelectedDevices(store) {
  const { [STORE_NAME]: devices, [SETTINGS_STORE_NAME]: settings } =
    store.getState()

  const hasAudio = Boolean(
    settingsSelectors.getSelectedMicDeviceId(settings) ||
      settingsSelectors.getSelectedMicDeviceLabel(settings)
  )

  if (devices.hasAudio !== hasAudio) {
    store.dispatch(actions.setHasAudio(hasAudio))
  }

  const hasVideo = Boolean(
    settingsSelectors.getSelectedCamDeviceId(settings) ||
      settingsSelectors.getSelectedCamDeviceLabel(settings)
  )

  if (devices.hasVideo !== hasVideo) {
    store.dispatch(actions.setHasVideo(hasVideo))
  }
}

function handleConferenceGumFailed(store, action) {
  store.dispatch(actions.gumError(action.payload))
}

function handleConferenceGumApproved(store) {
  const { [STORE_NAME]: devices } = store.getState()

  if (selectors.getGumError(devices)) {
    store.dispatch(actions.resetGumError())
  }
}

export default (store) => (next) => (action) => {
  if (action.type === ACTION_TYPES.UPDATE_DEVICE_LIST) {
    handleUpdateDeviceListPreUpdate(store, action)
  }

  const nextState = next(action)

  switch (action.type) {
    case 'sm-web/INIT':
      handleStoreInit(store)
      break

    case ACTION_TYPES.UPDATE_DEVICE_LIST:
      handleUpdateDeviceList(store, action)
      break

    case SETTINGS_ACTION_TYPES.SET_CURRENT_CAM_DEVICE:
      handleSetCurrentCamDevice(store, action)
      break

    case SETTINGS_ACTION_TYPES.SET_CURRENT_MIC_DEVICE:
      handleSetCurrentMicDevice(store, action)
      break

    case SETTINGS_ACTION_TYPES.INITIALISE_DEVICE_CONSTRAINTS:
    case SETTINGS_ACTION_TYPES.UPDATE_SELECTED_DEVICES:
      handleUpdateSelectedDevices(store)
      break

    case CONFERENCE_ACTION_TYPES.GUM_FAILED:
      handleConferenceGumFailed(store, action)
      break

    case CONFERENCE_ACTION_TYPES.GUM_APPROVED:
      handleConferenceGumApproved(store)
      break

    default:
  }

  return nextState
}
