import PropTypes from 'prop-types'
import { UserName } from 'ui/user'

import EventContent from '../event-content'

export interface Props {
  children?: React.ReactNode
  memberId: string
}

const UserEventContent = ({ children, memberId }: Props) => (
  <EventContent>
    <UserName maxLength={18} memberId={memberId} />
    <p className='event-message'>{children}</p>
  </EventContent>
)

UserEventContent.propTypes = {
  memberId: PropTypes.string.isRequired,

  children: PropTypes.node,
}

UserEventContent.defaultProps = {
  children: null,
}

export default UserEventContent
