class AbstractStorage {
  data = {}

  storage: Storage

  constructor(storage: Storage) {
    this.storage = storage
    this.initData()
  }

  initData() {
    /**
     * All enumerable keys from the Storage, keys are considered to be keys from all data entries in the Storage object.
     * @type {string[]}
     */
    const keys = Object.keys(this.storage)

    keys.forEach((k) => {
      this.data[k] = this.getItem(k)
    })
  }

  /**
   * @public
   * @param key
   * @returns
   */
  getItem(key) {
    return this.data[key] || this.storage.getItem(key)
  }

  /**
   * @public
   * @param key
   * @param value
   * @returns
   */
  setItem(key, value) {
    this.data[key] = value.toString()
    this.storage.setItem(key, value)
  }

  /**
   * @public
   * @param key
   * @returns
   */
  removeItem(key) {
    this.storage.removeItem(key)
    delete this.data[key]
  }

  /**
   * @public
   * @returns
   */
  clear() {
    this.storage.clear()
    this.data = {}
  }

  /**
   * @public
   * @param index - An integer representing the number of the key you want to get the name of. This is a zero-based index.
   * @returns - A DOMString containing the name of the key. If the index does not exist, null is returned.
   */
  key(index) {
    return this.storage.key(index)
  }
}

export default AbstractStorage
