import localStore from 'app/services/state/local-store'
import { type AnyAction } from 'redux'

export const STORE_NAME = 'feature/login'

const INIT = `sm-web/${STORE_NAME}/INIT`
const SET_EMAIL = `sm-web/${STORE_NAME}/SET_EMAIL`

export const ACTION_TYPES = {
  INIT,
  SET_EMAIL,
}

const setEmail = (payload: string) => ({ type: SET_EMAIL, payload })

export const actions = {
  setEmail,
}

export interface LoginState {
  email: string
}

export const INITIAL_STATE: LoginState = {
  email: localStore.getEmail() || '',
}

export default (state = INITIAL_STATE, action: AnyAction) => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (action.type) {
    case SET_EMAIL:
      return { ...state, email: action.payload }
    default:
      return state
  }
}

export const selectors = {
  getEmail(state: LoginState) {
    return state.email
  },
}
