import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useLanguages } from 'app/features/provider/i18n-provider.component'
import { BRAND_LIBRARY_FLAGS } from 'constants/constants'

import LanguageDropdown from './language-dropdown.component'

type flagname = keyof typeof BRAND_LIBRARY_FLAGS

const LanguageDropdownContainer = (props) => {
  const { i18n } = useTranslation()
  const languages = useLanguages()

  const options = useMemo(() => {
    if (!languages) {
      logger.debug(
        'LanguageDropdownContainer:: caught error on retrieving language options'
      )
      return [
        {
          value: 'en',
          flag: BRAND_LIBRARY_FLAGS.en,
          label: 'English',
        },
      ]
    }
    return Object.keys(languages).map((id) => ({
      value: id,
      flag: BRAND_LIBRARY_FLAGS[id] || '', // TODO: what should we show with unknown flag identifier?
      label: languages[id].nativeName.toUpperCase().split(',')[0],
    }))
  }, [languages])

  const handleSelect = useCallback(
    (evt) => {
      i18n.changeLanguage(evt.value)
    },
    [i18n]
  )

  return (
    <LanguageDropdown
      {...props}
      options={options}
      value={i18n.language}
      onChange={handleSelect}
    />
  )
}

export default LanguageDropdownContainer
