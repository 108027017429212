import cn from 'clsx'
import { useEffect, useRef, useState } from 'react'

import { makeStyles } from 'app/ui'
import { List } from 'ui/revision'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      overflowY: 'scroll',
      overflowX: 'hidden',
      padding: theme.spacing(1),
    },
  }),
  { name: 'MessagesWrapper' }
)

export function getIsAtBottom(element: HTMLElement): boolean {
  if (!element || typeof element !== 'object') return false

  return (
    element.clientHeight >= Math.floor(element.scrollHeight - element.scrollTop)
  )
}

type Props = {
  autoAnchor: boolean
  className?: string
  children?: React.ReactNode
  rowCount: number
}

const MessagesWrapper: React.FC<Props> = ({
  autoAnchor = false,
  className,
  children,
  rowCount = 0,
}: Props) => {
  const classes = useStyles()
  const [atBottom, setAtBottom] = useState(true)
  const list = useRef<HTMLDivElement>()
  useEffect(() => {
    if (atBottom && autoAnchor) scrollToBottom()
  }, [rowCount, atBottom, autoAnchor])

  const handleScroll = (e) => {
    setAtBottom(getIsAtBottom(e.target))
  }

  const scrollToBottom = () => {
    list.current?.scrollIntoView()
  }

  return (
    <List className={cn(className, classes.root)} onScroll={handleScroll}>
      {children}
      <div ref={list}></div>
    </List>
  )
}

export default MessagesWrapper
