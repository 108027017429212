import PropTypes from 'prop-types'
import { isValidElement } from 'react'

import {
  STORE_NAME as FLAGS_STORE_NAME,
  selectors as flagsSelectors,
} from 'app/features/flags/flags.reducer'
import { useSelector } from 'app/services/state/redux-store'

export const useFeatures = () =>
  useSelector((state) => flagsSelectors.getFeatures(state[FLAGS_STORE_NAME]))

/**
 * @param featureName
 * @param key
 * @returns {boolean | { enabled: boolean, licensed: boolean, supported: boolean }}
 */
export const useFeature = (featureName, key = '') => {
  return useSelector((state) => {
    const feature = flagsSelectors.getFeature(
      featureName,
      state[FLAGS_STORE_NAME]
    )
    return key ? feature[key] : feature
  })
}

export const useRequiredLicense = (featureName) => {
  return useSelector((state) =>
    flagsSelectors.getRequiredLicense(featureName, state[FLAGS_STORE_NAME])
  )
}

const flagKeys = ['enabled', 'licensed', 'supported']

const FeatureFlag = ({
  alternativeViewPriority,
  alternativeViews,
  children,
  feature,
}) => {
  const flags = useFeature(feature)
  const failedKeys = flagKeys
    .filter((k) => !flags[k])
    .sort(
      (a, b) =>
        alternativeViewPriority.indexOf(a) - alternativeViewPriority.indexOf(b)
    )
  if (failedKeys.length) {
    // If there is only one element passed instead of one per flag, return this one element
    if (alternativeViews && isValidElement(alternativeViews)) {
      return alternativeViews
    }
    return alternativeViews[failedKeys[0]] || null
  }
  return children
}

FeatureFlag.propTypes = {
  feature: PropTypes.string.isRequired,

  alternativeViewPriority: PropTypes.arrayOf(PropTypes.oneOf(flagKeys)),
  alternativeViews: PropTypes.oneOfType([
    PropTypes.shape({
      enabled: PropTypes.node,
      licensed: PropTypes.node,
      supported: PropTypes.node,
    }),
    PropTypes.node,
  ]),
  children: PropTypes.node,
}

FeatureFlag.defaultProps = {
  alternativeViewPriority: flagKeys,
  alternativeViews: {
    enabled: null,
    licensed: null,
    supported: null,
  },
  children: null,
}

export default FeatureFlag
