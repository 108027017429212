import { createContext, useContext } from 'react'

export type Props = {
  authenticateGuest: () => Promise<void>
  authenticateUser: (params: {
    localToken?: string
    sessionToken?: string
    username?: string
    password?: string
    redirectToMyRoom?: boolean
  }) => Promise<void>
  authenticating: boolean
  authenticationError: {
    type: string
    description?: string
    code?: string
  }
  auth: { member_id: string; type: string }
  isAuth: boolean
  isHost: boolean
  logout: () => Promise<void>
}

export const AuthContext = createContext<Props>(null)

export const useAuthContext = (): Props => useContext(AuthContext)

export const AuthConsumer = AuthContext.Consumer
