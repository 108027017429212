import ColigoTrack from './coligo-track'
import LocalColigoTrack from './local-coligo-track'
import ExternalColigoTrack from './external-coligo-track'

/**
 * @class
 */
class ColigoTrackFactory {
  TYPES = { BASE: 'base', LOCAL: 'local', EXTERNAL: 'external' }

  TRACK_CLASSES = {
    base: ColigoTrack,
    local: LocalColigoTrack,
    external: ExternalColigoTrack,
  }

  /**
   * @param type
   * @return
   */
  _validateType(type) {
    if (!Object.values(this.TYPES).includes(type)) {
      throw new Error(`Given track type: ${type} is not recognized.`)
    }
  }

  /**
   *
   * @param type
   * @param options
   * @return
   */
  createTrack(type, options) {
    this._validateType(type)
    return new this.TRACK_CLASSES[type](options)
  }
}

export default new ColigoTrackFactory()
