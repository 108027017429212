import { Icon } from 'ui/revision'

const SpeechFilled = (props) => (
  <Icon {...props}>
    <svg viewBox='0 0 23 21'>
      <g fill='none' fillRule='evenodd'>
        <path
          d='M20.597 0H1.987C.891 0 0 .891 0 1.986v11.028C0 14.109.891 15 1.987 15h3.909v5.387L11.283 15h9.314c1.095 0 1.986-.891 1.986-1.986V1.986C22.583.891 21.692 0 20.597 0'
          fill='#FFF'
        />
        <path
          d='M5.803 6.192c.966 0 1.75.8 1.75 1.785 0 .986-.784 1.784-1.75 1.784-.967 0-1.75-.798-1.75-1.783 0-.986.783-1.785 1.75-1.785zm5.5 0c.966 0 1.75.8 1.75 1.785 0 .986-.784 1.784-1.75 1.784-.967 0-1.75-.798-1.75-1.783 0-.986.783-1.785 1.75-1.785zm5.5 0c.966 0 1.75.8 1.75 1.785 0 .986-.784 1.784-1.75 1.784-.967 0-1.75-.798-1.75-1.783 0-.986.783-1.785 1.75-1.785z'
          fill='#26C6DA'
        />
      </g>
    </svg>
  </Icon>
)

export default SpeechFilled
