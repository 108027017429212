import { isMobile } from 'react-device-detect'
import _ from 'lodash'

import { TABS } from 'constants/constants'

export const STORE_NAME = 'feature/side-panel'

/**
 * Collection of all actions
 *
 */
const ADD_UNREAD = `sm-web/${STORE_NAME}/ADD_UNREAD`
const CHANGE_TAB_MENU = `sm-web/${STORE_NAME}/CHANGE_TAB_MENU`
const SIDE_PANEL_CLOSE = `sm-web/${STORE_NAME}/SIDE_PANEL_CLOSE`
const SIDE_PANEL_OPEN = `sm-web/${STORE_NAME}/SIDE_PANEL_OPEN`
const SIDE_PANEL_TOGGLE = `sm-web/${STORE_NAME}/SIDE_PANEL_TOGGLE`
const INIT = `sm-web/${STORE_NAME}/INIT`
const RESET_ALL_COUNTS = `sm-web/${STORE_NAME}/RESET_ALL_COUNTS`
const SAVE_TEMP_MESSAGE = `sm-web/${STORE_NAME}/SAVE_TEMP_MESSAGE`
const SET_VIEW_ROOM = `sm-web/${STORE_NAME}/SET_VIEW_ROOM`
const SET_VIEW_SETTINGS = `sm-web/${STORE_NAME}/SET_VIEW_SETTINGS`
const SET_UNREAD = `sm-web/${STORE_NAME}/SET_UNREAD`
const TABS_NEXT = `sm-web/${STORE_NAME}/TABS_NEXT`
const TABS_PREVIOUS = `sm-web/${STORE_NAME}/TABS_PREVIOUS`

export const ACTION_TYPES = {
  ADD_UNREAD,
  CHANGE_TAB_MENU,
  SIDE_PANEL_CLOSE,
  SIDE_PANEL_OPEN,
  SIDE_PANEL_TOGGLE,
  INIT,
  RESET_ALL_COUNTS,
  SAVE_TEMP_MESSAGE,
  SET_UNREAD,
  TABS_NEXT,
  TABS_PREVIOUS,
  SET_VIEW_ROOM,
  SET_VIEW_SETTINGS,
}

const addUnread = (payload) => ({ type: ADD_UNREAD, payload })
const closeDrawer = () => ({ type: SIDE_PANEL_CLOSE })
const openDrawer = () => ({ type: SIDE_PANEL_OPEN })
const resetAllCounts = () => ({ type: RESET_ALL_COUNTS })
const saveTempMessage = (payload) => ({ type: SAVE_TEMP_MESSAGE, payload })
const setUnread = (tab, payload = 0) => ({ type: SET_UNREAD, payload, tab })
const tabMenuChange = (payload) => ({ type: CHANGE_TAB_MENU, payload })
const tabsNext = () => ({ type: TABS_NEXT })
const tabsPrevious = () => ({ type: TABS_PREVIOUS })
const toggleSidePanel = () => ({ type: SIDE_PANEL_TOGGLE })
const setViewRoom = () => ({
  type: SET_VIEW_ROOM,
  payload: PANEL_VIEWS.VIEW_ROOM,
})
const setViewSettings = () => ({
  type: SET_VIEW_SETTINGS,
  payload: PANEL_VIEWS.VIEW_SETTINGS,
})

export const actions = {
  addUnread,
  resetAllCounts,
  saveTempMessage,
  setUnread,
  tabMenuChange,
  toggleSidePanel,
  openDrawer,
  closeDrawer,
  tabsNext,
  tabsPrevious,
  setViewRoom,
  setViewSettings,
}

export const commands = {
  [SIDE_PANEL_TOGGLE]: toggleSidePanel,
  [TABS_NEXT]: tabsNext,
  [TABS_PREVIOUS]: tabsPrevious,
}

export const PANEL_VIEWS = {
  VIEW_ROOM: 'room',
  VIEW_SETTINGS: 'settings',
}

const INITIAL_STATE = {
  /**
   * which view to show in the sidepanel
   * possible values: PANEL_VIEWS
   */
  view: PANEL_VIEWS.VIEW_ROOM,
  /**
   * panel variant
   * possible values: 'temporary','persistent'
   */
  variant: 'persistent',
  anchor: 'right',
  open: !isMobile,
  tabMenu: TABS.LOBBY,
  tempMessage: null,
  unreadCounts: {
    [TABS.LOBBY]: 0,
    [TABS.USER]: 0,
    [TABS.GROUP]: 0,
    [TABS.FILE]: 0,
  },
}

/**
 * Action handler
 * @type {{[p: string]: *}}
 */
const ACTION_HANDLERS = {
  [ADD_UNREAD]: (state, action) => ({
    ...state,
    unreadCounts: {
      ...state.unreadCounts,
      [TABS[action.payload]]: state.unreadCounts[TABS[action.payload]] + 1,
    },
  }),
  [SET_UNREAD]: (state, action) => ({
    ...state,
    unreadCounts: { ...state.unreadCounts, [TABS[action.tab]]: action.payload },
  }),
  [CHANGE_TAB_MENU]: (state, action) => {
    const unreadCounts = { ...state.unreadCounts }
    if (action.payload !== TABS.USER) unreadCounts[TABS[action.payload]] = 0

    return { ...state, tabMenu: action.payload, unreadCounts }
  },
  [SAVE_TEMP_MESSAGE]: (state, action) => ({
    ...state,
    tempMessage: action.payload,
  }),
  [RESET_ALL_COUNTS]: (state) => ({
    ...state,
    unreadCounts: INITIAL_STATE.unreadCounts,
  }),
  [SIDE_PANEL_TOGGLE]: (state) => ({
    ...state,
    open: !state.open,
  }),
  [SIDE_PANEL_OPEN]: (state) => ({
    ...state,
    open: true,
  }),
  [SIDE_PANEL_CLOSE]: (state) => ({
    ...state,
    open: false,
  }),
  [SET_VIEW_ROOM]: (state, action) => ({
    ...state,
    view: action.payload,
  }),
  [SET_VIEW_SETTINGS]: (state, action) => ({
    ...state,
    view: action.payload,
  }),
  'sm-web/RESET': () => INITIAL_STATE,
}

/**
 * Exporting the reducer
 * @param state
 * @param action
 */
export default (state = INITIAL_STATE, action = {}) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export const selectors = {
  getOpen(state) {
    return _.get(state, 'open', false)
  },
  getTabMenu(state) {
    return _.get(state, 'tabMenu', TABS.GROUP)
  },
  getUnreadCounts(state) {
    return _.get(state, 'unreadCounts', {})
  },
  getTempMessage(state) {
    return _.get(state, 'tempMessage', null)
  },
}
