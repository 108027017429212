import { ClDivider } from '@enreach/core-component-library-react'
import { defineCustomElementClDivider } from '@enreach/core-component-library/dist/components/index'

export const Divider = (props) => (
  <ClDivider
    color={props.color || 'rgb(var(--color-neutral-300))'}
    {...props}
  />
)

export default Divider

defineCustomElementClDivider()
