import { cloneElement, useMemo, type ReactElement } from 'react'

import { makeStyles } from 'app/ui'
import { ChatItem, Typography } from 'ui/revision'
import SVGSticker from 'ui/svg-sticker'

const useStyles = makeStyles({
  sticker: {
    width: '100px',
    maxHeight: '100px',
  },
})

type Props = {
  avatar: ReactElement
  sticker: string
} & Partial<ChatItemProps>

export const ChatStickerMessage: React.FC<Props> = ({
  avatar,
  sticker,
  ...other
}: Props) => {
  const classes = useStyles()

  avatar = useMemo(() => {
    return cloneElement(avatar, { slot: 'avatar' })
  }, [avatar])

  return (
    <ChatItem {...other} rightAlign subtle>
      {avatar}
      <Typography slot='content'>
        <SVGSticker className={classes.sticker} src={sticker} />
      </Typography>
    </ChatItem>
  )
}

export default ChatStickerMessage
