export const STORE_NAME = 'feature/footer'

/**
 * Collection of all actions
 *
 */
const OPEN_FOOTER = `sm-web/${STORE_NAME}/OPEN_FOOTER`
const CLOSE_FOOTER = `sm-web/${STORE_NAME}/CLOSE_FOOTER`
const TOGGLE_FOOTER = `sm-web/${STORE_NAME}/TOGGLE_FOOTER`
const DISABLE_PINNING = `sm-web/${STORE_NAME}/DISABLE_PINNING`
const SHOW_ALL = `sm-web/${STORE_NAME}/SHOW_ALL`

export const ACTION_TYPES = {
  OPEN_FOOTER,
  CLOSE_FOOTER,
  TOGGLE_FOOTER,
  DISABLE_PINNING,
  SHOW_ALL,
}

const openFooter = () => ({ type: OPEN_FOOTER })

const toggleFooter = () => ({ type: TOGGLE_FOOTER })

const closeFooter = () => ({ type: CLOSE_FOOTER })

const disablePinning = (disabled) => ({
  type: DISABLE_PINNING,
  payload: disabled,
})

const setShowAll = (payload) => ({
  type: SHOW_ALL,
  payload,
})

/**
 * Exporting all actions
 */
export const actions = {
  closeFooter,
  disablePinning,
  openFooter,
  toggleFooter,
  setShowAll,
}

/**
 * commands available for shortcutting etc
 */
export const commands = {
  [TOGGLE_FOOTER]: actions.toggleFooter,
}

/**
 * Action handler
 */
const ACTION_HANDLERS = {
  [SHOW_ALL]: (state, { payload: showAll }) => ({ ...state, showAll }),
  [OPEN_FOOTER]: (state) => ({ ...state, open: true }),
  [CLOSE_FOOTER]: (state) => ({ ...state, open: false }),
  [TOGGLE_FOOTER]: (state) => ({ ...state, open: !state.open }),
  [DISABLE_PINNING]: (state, { payload: isPinningDisabled }) => ({
    ...state,
    isPinningDisabled,
  }),
}

/**
 * Initial snackbar state
 */
export const INITIAL_STATE = {
  open: false,
  isPinningDisabled: false,
  showAll: false,
}

/**
 * Exporting the reducer
 */
export default (state = INITIAL_STATE, action = {}) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
