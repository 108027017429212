import { Icon } from 'ui/revision'

const Wrench = (props) => (
  <Icon {...props}>
    <svg viewBox='0 0 25 23'>
      <path
        d='M3.06 4.05a5.2 5.2 0 017.1 6.35l9.33 8.18a2.28 2.28 0 11-3 3.43l-9.5-8.33a5.22 5.22 0 01-6.77-6.4l3.14 2.75c.26.23.64.2.86-.05l2.03-2.31a.62.62 0 00-.05-.87zm14.9 15.16a1.01 1.01 0 100 2.02 1.01 1.01 0 000-2.02zM20.35 0c.14 0 .25.1.27.23l.2 1.44c.34.13.64.31.92.53l1.35-.55c.13-.03.27 0 .34.13l1.08 1.88c.06.1.04.26-.07.34l-1.14.9a3.2 3.2 0 010 1.06l1.14.89c.1.08.13.22.07.34l-1.09 1.88a.27.27 0 01-.33.12l-1.35-.54c-.28.22-.59.4-.92.53l-.2 1.44a.26.26 0 01-.27.23H18.2a.28.28 0 01-.27-.23l-.2-1.44a3.97 3.97 0 01-.92-.53l-1.35.54c-.12.04-.27 0-.33-.12l-1.09-1.88a.28.28 0 01.07-.34l1.15-.9a3.13 3.13 0 010-1.06L14.12 4c-.11-.08-.13-.23-.07-.34l1.08-1.88c.07-.12.21-.17.34-.12l1.35.54c.27-.21.58-.4.91-.53l.2-1.44A.27.27 0 0118.2 0zm-1.08 3.39c-1.12 0-2.04.92-2.04 2.03a2.04 2.04 0 004.07 0c0-1.11-.91-2.03-2.03-2.03z'
        fillRule='evenodd'
      />
    </svg>
  </Icon>
)

export default Wrench
