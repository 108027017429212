import _ from 'lodash'

import { USER_STATUS } from 'constants/constants'

import isDialIn from './is-dial-in'
import validateMember from './validate-member'

/**
 * Get the member's room joining status.
 * In case the member is of type 'user' / 'guest' the meta.status property is returned
 * In case the member is a type 'phone' the state is derived from the member.rights.audio_send value
 * where true equals the user being admitted and false the user not (yet) admitted.
 * @param member
 * @param member.meta
 * @param member.meta.status
 * @param member.meta.type
 * @param member.rights
 * @param member.rights.audio_send
 * @returns value from the enum @see USER_STATUS
 */
function getMemberRoomState(member) {
  validateMember(member)

  if (isDialIn(member)) {
    return _.get(member, 'rights.audio_send')
      ? USER_STATUS.IN_ROOM
      : USER_STATUS.WAITING
  }

  return member.meta.status
}

export default getMemberRoomState
