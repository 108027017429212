import { FEATURE_NAMES, LICENSED_FEATURES } from 'constants/features-map'

import env from 'app/config/env'

import { selectors as flagsSelectors } from 'app/features/flags/flags.reducer'
import { selectors as channelRoomSelectors } from 'app/state/api/channels/channel-room.reducer'

type License = {
  id: number
  name: string
  features: Array<[string, ...any]>
}

/**
 *
 */
const defaultLicense: License = {
  id: 4,
  name: 'Basic',
  features: LICENSED_FEATURES.map((f) => [f[0], true]),
}

/**
 * Get the name of a feature by id.
 * @param id Feature id
 * @return Feature name
 */
export function getFeatureName(id): FEATURE_NAMES {
  const featureList = LICENSED_FEATURES.find((f) => f[0] === id)
  if (!featureList) {
    throw new Error(`There is no licensed feature with the id '${id}'`)
  }
  return featureList[1]
}

/**
 * Get the id of a feature by name.
 * @param name Feature name
 * @return Feature id
 */
export function getFeatureId(name): string {
  const featureList = LICENSED_FEATURES.find((f) => f[1] === name)
  if (!featureList) {
    throw new Error(`There is no licensed feature with the name '${name}'`)
  }
  return featureList[0]
}

/**
 * @return
 */
export function getLicenses(): License[] {
  return env('licenses', [defaultLicense])
}

/**
 * Check whether the given feature name is a licensed feature.
 * @param name Feature name
 * @return
 */
export function getIsLicensedFeature(name): boolean {
  return !!LICENSED_FEATURES.find((f) => f[1] === name)
}

/**
 * Get the current license from the state
 * @param channelRoom
 * @param flags feature/flags reducer state slice
 * @return Current license
 */
export function getCurrentLicense(channelRoom, flags) {
  const licenseId = +channelRoomSelectors.getLicenseId(channelRoom)

  return flagsSelectors.getLicense(licenseId, flags)
}
