/* eslint-disable no-console */
import { v4 as uuid } from 'uuid'
import dotProp from 'dot-prop-immutable'
import _ from 'lodash'

import env from 'app/config/env'
import ROOM_SERVICES from 'app/services/media/room-services'
import {
  CHAT_COMMAND_TYPES,
  CHAT_CONTENT_TYPES,
  DEFAULT_MAX_GUESTS,
  USER_STATUS,
  CHAT_HISTORY_LIMIT,
  MESSAGE_DATA_TYPE,
  SLOW_LINK_RESET_TIMEOUT,
} from 'constants/constants'
import { parseLinkDataAndGetFile } from 'lib/files'
import localStore from 'app/services/state/local-store'
import {
  getMe,
  getUser,
  getHosts,
  getHostId,
  isHost,
  isDialIn,
  getMemberRoomState,
  rejectMemberId,
  rejectOfflineMembers,
  audioMutedByHostPredicate,
} from 'app/state/utils'

import { getSocket } from '../socket'

import {
  handleMessage,
  createStateLoggingActionHandler,
  formatChatMessageForSending,
  tryParseErrorMessage,
} from './utils'

const channelConf = {
  id: 'Summa.Meeting.Api.Room',
  conf: { version: '3.2' },
}

export const STORE_NAME = 'channel/room'

/**
 * An array of function call which were done before channel was present.
 * @type {Array<Function>}
 */
let awaitingCalls = []

/**
 * channel instance
 * @param
 */
let channel = null

// ------------------------------------
// Actions
// ------------------------------------
const CHAT_STARTED = 'sm-web/channel/room/CHAT_STARTED'
const CLOSED = 'sm-web/channel/room/CLOSED'
const CLOSING = 'sm-web/channel/room/CLOSING'
const CLOSING_ERROR = 'sm-web/channel/room/CLOSING_ERROR'
const CLOSING_TIMEOUT = 'sm-web/channel/room/CLOSING_TIMEOUT'
const DISCONNECT = 'sm-web/channel/room/DISCONNECT'
const END_MEETING = 'sm-web/channel/room/END_MEETING'
const ERROR = 'sm-web/channel/room/ERROR'
const FILE_SET = 'sm-web/channel/room/FILE_SET'
const FILE_UPDATE = 'sm-web/channel/room/FILE_UPDATE'
const FILES_STARTED = 'sm-web/channel/room/FILES_STARTED'
const JOINED = 'sm-web/channel/room/JOINED'
const JOINING = 'sm-web/channel/room/JOINING'
const JOINING_ERROR = 'sm-web/channel/room/JOINING_ERROR'
const JOINING_TIMEOUT = 'sm-web/channel/room/JOINING_TIMEOUT'
const LOG = 'sm-web/channel/room/LOG'
const LOGOUT = 'sm-web/channel/room/LOGOUT'
const MEMBER_CREATE = 'sm-web/channel/room/MEMBER_CREATE'
const MEMBER_DELETE = 'sm-web/channel/room/MEMBER_DELETE'
const UPDATED_MEMBER_META = 'sm-web/channel/room/UPDATED_MEMBER_META'
const UPDATED_MEMBER_RIGHTS = 'sm-web/channel/room/UPDATED_MEMBER_RIGHTS'
const UPDATED_MEMBER_STATE = 'sm-web/channel/room/UPDATED_MEMBER_STATE'
const MESSAGE_CREATE = 'sm-web/channel/room/MESSAGE_CREATE'
const GUEST_MESSAGE_CREATE = 'sm-web/channel/room/GUEST_MESSAGE_CREATE'
const PRIVATE_MESSAGE_CREATE = 'sm-web/channel/room/PRIVATE_MESSAGE_CREATE'
const ROOM_SCREENSHARE_ACCEPT = 'sm-web/channel/room/ROOM_SCREENSHARE_ACCEPT'
const ROOM_SET = 'sm-web/channel/room/ROOM_SET'
const ROOM_UPDATE = 'sm-web/channel/room/ROOM_UPDATE'
const UPDATE_ROOM_META = 'sm-web/channel/room/UPDATE_ROOM_META'
const UPDATED_ROOM_META = 'sm-web/channel/room/UPDATED_ROOM_META'
const ROOM_SCREENSHARE_REJECT = 'sm-web/channel/room/ROOM_SCREENSHARE_REJECT'
const ROOM_FILES_REMOVE_MESSAGES =
  'sm-web/channel/room/ROOM_FILES_REMOVE_MESSAGES'
const ROOM_CLEAN_MESSAGES = 'sm-web/channel/room/ROOM_CLEAN_MESSAGES'
const REMOVE_SS_REQUEST_MESSAGE =
  'sm-web/channel/room/REMOVE_SS_REQUEST_MESSAGE'
const ROOM_MESSAGE_HISTORY = 'sm-web/channel/room/ROOM_MESSAGE_HISTORY'
const MEMBER_KICKED = 'sm-web/channel/room/MEMBER_KICKED'
const SLOW_LINK = 'sm-web/channel/room/SLOW_LINK'
const CLEAR_SLOW_LINK = 'sm-web/channel/room/CLEAR_SLOW_LINK'
const MESSAGE_COMMAND = 'sm-web/channel/room/MESSAGE_COMMAND'
const GRANT_HOST = 'sm-web/channel/room/GRANT_HOST'
const REVOKE_HOST = 'sm-web/channel/room/REVOKE_HOST'

export const DISCONNECT_EVENTS = ['kick_user']

export const ACTION_TYPES = {
  CHAT_STARTED,
  CLOSED,
  CLOSING,
  CLOSING_ERROR,
  CLOSING_TIMEOUT,
  DISCONNECT,
  END_MEETING,
  ERROR,
  FILE_SET,
  FILE_UPDATE,
  FILES_STARTED,
  JOINED,
  JOINING,
  JOINING_ERROR,
  JOINING_TIMEOUT,
  LOG,
  LOGOUT,
  MEMBER_CREATE,
  MEMBER_DELETE,
  UPDATED_MEMBER_META,
  UPDATED_MEMBER_RIGHTS,
  UPDATED_MEMBER_STATE,
  MESSAGE_CREATE,
  GUEST_MESSAGE_CREATE,
  PRIVATE_MESSAGE_CREATE,
  ROOM_SCREENSHARE_ACCEPT,
  ROOM_SET,
  ROOM_UPDATE,
  UPDATE_ROOM_META,
  UPDATED_ROOM_META,
  ROOM_SCREENSHARE_REJECT,
  ROOM_FILES_REMOVE_MESSAGES,
  ROOM_CLEAN_MESSAGES,
  REMOVE_SS_REQUEST_MESSAGE,
  ROOM_MESSAGE_HISTORY,
  MEMBER_KICKED,
  SLOW_LINK,
  CLEAR_SLOW_LINK,
  MESSAGE_COMMAND,
  GRANT_HOST,
  REVOKE_HOST,
}

/**
 * Whenever the user marked debug mode as true we store the logs in the store.
 * @param event
 * @param payload
 */
const log = (event, payload) => (dispatch) => {
  if (localStore.getDebugMode()) {
    dispatch({ type: LOG, event, payload })
  }
}

/**
 * Room meta has been updated
 * @param payload
 */
const updatedMeta = (payload) => ({ type: UPDATED_ROOM_META, payload })
/**
 * @param started
 */
const startedFiles = (started = true) => ({
  type: FILES_STARTED,
  payload: started,
})
/**
 * @param started
 */
const startedChat = (started = true) => ({
  type: CHAT_STARTED,
  payload: started,
})

/**
 * @param payload
 */
const joined = (payload) => ({ type: JOINED, payload })
/**
 * @param error
 */
const joiningError = (error) => ({ type: JOINING_ERROR, error })
/**
 * @param event
 */
const joiningTimeout = () => ({ type: JOINING_TIMEOUT })
/**
 * @param e
 */
const error = (e) => ({ type: ERROR, error: e })
/**
 * @param payload
 */
const closed = (payload) => ({ type: CLOSED, payload })
/**
 * @param e
 */
const closingError = (e) => ({ type: CLOSING_ERROR, error: e })
/**
 * @param event
 */
const closingTimeout = () => ({ type: CLOSING_TIMEOUT })
/**
 * @param type
 */
const disconnect = (disconnectType) => ({
  type: DISCONNECT,
  disconnectType,
})
/**
 * @param payload
 */
const createMember = (payload) => ({ type: MEMBER_CREATE, payload })
/**
 * @param payload
 */
const deleteMember = (payload) => ({ type: MEMBER_DELETE, payload })
/**
 * @param payload
 */
const updatedMemberRights = (memberId, rights) => ({
  type: UPDATED_MEMBER_RIGHTS,
  memberId,
  rights,
})
/**
 * @param payload
 */
const updatedMemberStates = (memberId, states) => ({
  type: UPDATED_MEMBER_STATE,
  memberId,
  states,
})
/**
 * @param payload
 */
const updatedMemberMeta = (memberId, meta) => ({
  type: UPDATED_MEMBER_META,
  memberId,
  meta,
})
const kickedMember = (payload) => ({ type: MEMBER_KICKED, payload })

/**
 * replace the request message with an accepted version
 * @param memberId
 */
const acceptScreenShareUser = (memberId) => ({
  type: ROOM_SCREENSHARE_ACCEPT,
  memberId,
})

/**
 * replace the request message with an rejected version
 * @param memberId
 */
const rejectScreenShareUser = (memberId) => ({
  type: ROOM_SCREENSHARE_REJECT,
  memberId,
})

/**
 * Clean messages, at meeting end for ex
 */
const cleanMessages = () => ({
  type: ROOM_CLEAN_MESSAGES,
})

/**
 * permanently removes all files.
 */
const removeAllFiles = async () =>
  new Promise((resolve, reject) => {
    channel
      .push('file:remove_all', {})
      .receive('ok', resolve)
      .receive('error', (e) => {
        reject(new Error(`"file:remove_all": ${JSON.stringify(e)}`))
      })
      .receive('timeout', () => {
        reject(new Error('"file:remove_all": Timeout'))
      })
  })

/**
 * this function will replace the whole room meta with the given data
 * NOTE: use updateRoomMeta if you supply only the keys for the changed props
 * @param]
 * @return
 */
const setRoomMeta = async (meta = {}) =>
  new Promise((resolve, reject) => {
    channel
      .push('room:set_meta', { meta })
      .receive('ok', resolve)
      .receive('error', (e) => {
        reject(new Error(`"room:set_meta": ${JSON.stringify(e)}`))
      })
      .receive('timeout', () => {
        reject(new Error('"room:set_meta": Timeout'))
      })
  })

/**
 * Call to kick a user from the room
 * @param memberId string uuid for member
 * @param [reason=''] string containing the reason for kicking
 * @returns
 */
const kickMember = async (memberId, reason = '') =>
  new Promise((resolve, reject) => {
    channel
      .push('room:kick_member', { member_id: memberId, reason })
      .receive('ok', resolve)
      .receive('error', (e) => {
        reject(new Error(`"room:kick_member": ${JSON.stringify(e)}`))
      })
      .receive('timeout', () => {
        reject(new Error('"room:kick_member": Timeout'))
      })
  })

/**
 * this function takes into account that setting the room meta will replace the entire
 * meta collection instead of merging the keys. therefor it will spread the props
 * of the current room meta into the new room meta data.
 * @param meta
 */
const updateRoomMeta = (meta) => async (dispatch, getState) =>
  setRoomMeta({
    ..._.get(getState()[STORE_NAME], 'meeting_room.meta', {}),
    ...meta,
  })

/**
 * Generic call to set member meta  if memberId not equals current user id and  current user is not super user,
 * then surely an error will occur
 * @param memberId {UUID} string uuid for member
 * @param meta hash containing meta properties
 * @returns
 * TODO: Remove the curry function and remove all dispatches of updateMemberMeta.
 */
const updateMemberMeta = async (memberId, meta) =>
  new Promise((resolve, reject) => {
    channel
      .push('room:set_member_meta', { member_id: memberId, meta })
      .receive('ok', () => {
        resolve({ memberId, meta })
      })
      .receive('error', (e) => {
        reject(new Error(`"room:set_member_meta": ${JSON.stringify(e)}`))
      })
      .receive('timeout', () => {
        reject(new Error('"room:set_member_meta": Timeout'))
      })
  })

const setMemberRights = async (memberId, rights) => {
  return new Promise((resolve, reject) => {
    channel
      .push('room:set_member_rights', { member_id: memberId, rights })
      .receive('ok', () => {
        resolve({ memberId, rights })
      })
      .receive('error', (e) => {
        reject(new Error(`"room:set_member_rights": ${JSON.stringify(e)}`))
      })
      .receive('timeout', () => {
        reject(new Error('"room:set_member_rights": Timeout'))
      })
  })
}

const updateMemberRights = (memberId, rights) => async (dispatch, getState) => {
  const member = getUser(getState()[STORE_NAME], memberId)
  return setMemberRights(memberId, { ...member.rights, ...rights })
}

const resetRoomMeta = () => async (dispatch, getState) => {
  const roomState = getState()[STORE_NAME]

  const licenseId = selectors.getLicenseId(roomState)
  const owners = selectors.getOwners(roomState)

  return setRoomMeta({ licenseId, owners })
}

/**
 * exported action to leave the channel
 */
const leave = () => async (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: CLOSING })
    if (channel) {
      channel
        .leave()
        .receive('ok', resolve)
        .receive('error', (e) => {
          dispatch(closingError(e))
          reject(new Error(JSON.stringify(e)))
        })
        .receive('timeout', () => {
          dispatch(closingTimeout())
          reject(new Error('Timeout'))
        })
    } else {
      resolve()
    }
  })

const setReadyToLeave = () => async (dispatch, getState) => {
  const me = getState()[STORE_NAME].member_id
  return updateMemberMeta(me, { prepared_to_leave: true })
}

/**
 * Get the saved by host messages
 */
const getChatHistory = () => async (dispatch, getState) => {
  if (env('chat_history')) {
    return new Promise((resolve, reject) => {
      const { [STORE_NAME]: channelRoom } = getState()
      // for now only host can get the history
      const amIHost = isHost(getMe(channelRoom))
      if (amIHost) {
        channel
          .push('chat:history', { limit: CHAT_HISTORY_LIMIT })
          .receive('ok', (response) => {
            resolve(response)
            dispatch({ type: ROOM_MESSAGE_HISTORY, payload: response.result })
          })
          .receive('error', (e) => {
            reject(new Error(`"chat:history": ${JSON.stringify(e)}`))
          })
          .receive('timeout', () => {
            reject(new Error('"chat:history": Timeout'))
          })
      } else {
        resolve()
      }
    })
  }

  return Promise.resolve()
}

/**
 * @return
 */
const startChat = () => async (dispatch) =>
  new Promise((resolve, reject) => {
    return channel
      .push('chat:start', {})
      .receive('ok', () => {
        // let see if we have some saved messages
        dispatch(getChatHistory())
        resolve()
      })
      .receive('error', (e) => {
        /**
         * If the service is already started it doesn't matter as
         * we wan't it to be started.
         */
        if (e.reason !== 'Already Started') {
          reject(new Error(`"chat:start": ${JSON.stringify(e)}`))
        } else {
          resolve(true)
        }
      })
      .receive('timeout', () => {
        reject(new Error('"chat:start": Timeout'))
      })
  }).then(() => {
    dispatch(startedChat())
  })
/**
 * @param payload
 */
const createMessage = (payload) => (dispatch, getState) => {
  const channelRoom = getState()[STORE_NAME]
  const me = getMe(channelRoom)

  const isInWaitingRoom = getMemberRoomState(me) === USER_STATUS.WAITING

  const isGlobalMessage = payload.to === '*'

  const contentType = _.get(payload, MESSAGE_DATA_TYPE)

  const isSystemMessage =
    contentType && contentType !== CHAT_COMMAND_TYPES.USER_TYPING

  const isSystemCommand =
    contentType && Object.values(CHAT_COMMAND_TYPES).includes(contentType)

  let type = MESSAGE_CREATE

  if (isSystemCommand) {
    type = MESSAGE_COMMAND
  } else if (!isGlobalMessage && !isSystemMessage) {
    type = PRIVATE_MESSAGE_CREATE
  }

  if (
    isInWaitingRoom &&
    contentType !== CHAT_CONTENT_TYPES.ROOM_MUTE_VIDEO &&
    contentType !== CHAT_CONTENT_TYPES.ROOM_MUTE_AUDIO
  ) {
    type = GUEST_MESSAGE_CREATE
  }

  dispatch({ type, payload: { ...payload, ts: Date.now() } })
}

const messageCreate = (payload) => ({
  type: MESSAGE_CREATE,
  payload: { ...payload, ts: Date.now() },
})

const createLocalSystemMessage = (content, to, from) => {
  const chatContent = formatChatMessageForSending(content)
  return messageCreate({ content: chatContent, from, to })
}

/**
 * send a message to a participant
 */
const sendChatMessage =
  (content, to, from, formatter = formatChatMessageForSending) =>
  async (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const { [STORE_NAME]: channelRoom } = getState()

      /**
       * The user object to who the message will be send to.
       *
       */
      const user = getUser(channelRoom, to)

      /**
       * Is the user NOT in the list or is it marked as offline.
       *
       */
      const isOffline = user === undefined || _.get(user, 'meta.offline', false)
      const isDiailInUser = isDialIn(user)

      /**
       * Message for the room or system message.
       *
       */
      const isBroadcast = to === '*'

      if (isDiailInUser || (isOffline && !isBroadcast)) resolve()
      else {
        logger.debug(`Sending message to "${to}": ${content}`)

        const chatContent = formatter(content)
        const message = { to, content: chatContent }

        if (isHost(getMe(channelRoom))) {
          message.history = Boolean(env('chat_history'))
        }

        channel
          .push('chat:send', message)
          .receive('ok', resolve)
          .receive('error', (e) => {
            reject(new Error(`"chat:send": ${JSON.stringify(e)}`))
          })
          .receive('timeout', () => {
            reject(new Error('"chat:send": timeout'))
          })
      }
    })

/**
 * send directed message to the user that must be kicked, denied...
 * @param reason
 * @param to
 * @param messageType
 */
const sendUserSysMessage = (to, reason, messageType, from) =>
  sendChatMessage(
    { content: { type: messageType, userId: to, reason } },
    to,
    from
  )

/**
 * broadcast message to all connected clients
 * @param reason
 * @param messageType
 * @param props hash of extra props for the message content
 */
const broadcastUserSysMessage = (from, messageType, props = {}) =>
  sendChatMessage({ content: { type: messageType, ...props } }, '*', from)

const setMemberRaisedHand = async (memberId, isHandRaised) =>
  updateMemberMeta(memberId, { isHandRaised })
const raiseMemberHand = async (memberId) => setMemberRaisedHand(memberId, true)
const lowerMemberHand = async (memberId) => setMemberRaisedHand(memberId, false)

/**
 * @return
 */
const sendAudioStop = async () =>
  new Promise((resolve, reject) => {
    channel
      .push('audio:stop', {})
      .receive('ok', resolve)
      .receive('error', (e) => {
        // NOTE: If the service wasn't started it doesn't matter as we wanted it to be stopped.
        if (e.reason === 'Not Started') {
          resolve()
        } else {
          reject(new Error(`"audio:stop": ${JSON.stringify(e)}`))
        }
      })
      .receive('timeout', () => {
        reject(new Error('"audio:stop": Timeout'))
      })
  })

/**
 * starts audio service on api
 * @returns
 */
const startAudio = async () => {
  const command = async () =>
    new Promise((resolve, reject) => {
      channel
        .push('audio:start', env('active_speaker.audiostartopts', {}))
        .receive('ok', resolve)
        .receive('error', (e) => {
          reject(new Error(`"audio:start": ${JSON.stringify(e)}`))
        })
        .receive('timeout', () => {
          reject(new Error('"audio:start": Timeout'))
        })
    })
  return sendAudioStop()
    .then(async () => command())
    .catch(async (err) => {
      const parsedMessage = tryParseErrorMessage(err)
      if (parsedMessage.reason === 'Already started') {
        return Promise.resolve()
      }
      throw err
    })
}

/**
 * send audio sdp offer
 * @param sdp
 * @return
 */
const sendAudioOffer = async (offer) => {
  const command = async () =>
    new Promise((resolve, reject) => {
      channel
        .push('audio:configure', { sdp: offer.sdp, trickle: true })
        .receive('ok', ({ sdp }) => {
          if (!sdp) {
            reject(
              new TypeError(
                `Expected remote session description as string, got: "${sdp}"`
              )
            )
          }
          resolve({ type: 'answer', sdp })
        })
        .receive('error', (e) => {
          reject(new Error(`"audio:configure": ${JSON.stringify(e)}`))
        })
        .receive('timeout', () => {
          reject(new Error('"audio:configure": Timeout'))
        })
    })
  return command().catch(async (err) => {
    const parsedMessage = tryParseErrorMessage(err)
    if (parsedMessage.reason === 'Not Started') {
      return startAudio().then(command)
    }
    throw err
  })
}

/**
 * Audio mute a single member in the audioroom
 * @param memberId
 * @param mute
 * @return
 */
const sendAudioMuteMember = async (memberId, mute) =>
  new Promise((resolve, reject) => {
    /**
     * Either `audio:mute` or `audio:unmute` based on whether the user should be muted or not.
     *
     */
    const cmd = `audio:${mute ? 'mute' : 'unmute'}`
    channel
      .push(cmd, { member: memberId })
      .receive('ok', resolve)
      .receive('error', (e) => {
        reject(new Error(`"${cmd}": ${JSON.stringify(e)}`))
      })
      .receive('timeout', () => {
        reject(new Error(`"${cmd}": Timeout`))
      })
  })

const startVideoService = async (type) => {
  const command = async () =>
    new Promise((resolve, reject) => {
      channel
        .push(`${type}:start`, {
          videocodec: env('room_video_codecs') || 'h264',
          bitrate: env('room_video_bitrates').h || 512000,
        })
        .receive('ok', resolve)
        .receive('error', (e) => {
          reject(new Error(`"${type}:start": ${JSON.stringify(e)}`))
        })
        .receive('timeout', () => {
          reject(new Error(`"${type}:start": Timeout`))
        })
    })
  return command().catch(async (err) => {
    const parsedMessage = tryParseErrorMessage(err)
    if (parsedMessage.reason === 'Already started') {
      return Promise.resolve()
    }
    throw err
  })
}

const startVideo = () => {
  startVideoService('video')
  startVideoService('thumbnails')
}
const startThumbnailVideo = async () => startVideoService('thumbnails')
const startScreenVideo = async () => startVideoService('screens')

const sendStop = async (type) =>
  new Promise((resolve, reject) => {
    channel
      .push(`${type}:send_stop`, {})
      .receive('ok', resolve)
      .receive('error', (e) => {
        reject(new Error(`"${type}:send_stop": ${JSON.stringify(e)}`))
      })
      .receive('timeout', () => {
        reject(new Error(`"${type}:send_stop": Timeout`))
      })
  }).catch(async (err) => {
    const parsedMessage = tryParseErrorMessage(err)
    if (parsedMessage.reason === 'Service Not Started') {
      return startVideoService(type)
    }
    if (parsedMessage.reason !== 'Not Sending') {
      throw err
    }
  })

const sendVideoStop = async () => sendStop('video')
const sendThumbnailVideoStop = async () => sendStop('thumbnails')
const sendScreenVideoStop = async () => sendStop('screens')

const stopVideoService = async (type) =>
  new Promise((resolve, reject) => {
    channel
      .push(`${type}:stop`, {})
      .receive('ok', resolve)
      .receive('error', (e) => {
        reject(new Error(`"${type}:stop": ${JSON.stringify(e)}`))
      })
      .receive('timeout', () => {
        reject(new Error(`"${type}:stop": Timeout`))
      })
  })
const videoStop = async () => stopVideoService('video')
const thumbnailVideoStop = async () => stopVideoService('thumbnails')
const screenVideoStop = async () => stopVideoService('screens')

/**
 * send sdp offer
 * @param type
 * @param offer
 * @return
 */
const sendOffer = async (type, offer) => {
  const command = async () =>
    new Promise((resolve, reject) => {
      channel
        .push(`${type}:send_start`, { sdp: offer.sdp, trickle: true })
        .receive('ok', ({ sdp }) => {
          resolve({ type: 'answer', sdp })
        })
        .receive('error', (e) => {
          reject(new Error(`"${type}:send_start": ${JSON.stringify(e)}`))
        })
        .receive('timeout', () => {
          reject(new Error(`"${type}:send_start": Timeout`))
        })
    })
  return command().catch(async (err) => {
    const parsedMessage = tryParseErrorMessage(err)
    if (parsedMessage.reason === 'Service Not Started') {
      return startVideoService(type).then(command)
    }
    if (parsedMessage.reason === 'Already Sending') {
      return sendStop(type).then(command)
    }
    throw err
  })
}

const sendVideoOffer = async (offer) => sendOffer('video', offer)
const sendThumbnailVideoOffer = async (offer) => sendOffer('thumbnails', offer)
const sendScreenVideoOffer = async (offer) => sendOffer('screens', offer)

/**
 * user no longer viewing given member's hd feed
 * @param type
 * @param memberId
 */
const sendMemberReceiveStop = async (type, memberId) => {
  const command = async () =>
    new Promise((resolve, reject) => {
      channel
        .push(`${type}:receive_stop`, { member_id: memberId })
        .receive('ok', resolve)
        .receive('error', (e) => {
          reject(new Error(`"${type}:receive_stop": ${JSON.stringify(e)}`))
        })
        .receive('timeout', () => {
          reject(new Error(`"${type}:receive_stop": Timeout`))
        })
    })
  return command().catch(async (err) => {
    const parsedMessage = tryParseErrorMessage(err)
    if (parsedMessage.reason === 'Not Receiving') {
      return Promise.resolve()
    }
    throw err
  })
}

const sendMemberVideoReceiveStop = async (memberId) =>
  sendMemberReceiveStop('video', memberId)
const sendMemberThumbnailVideoReceiveStop = async (memberId) =>
  sendMemberReceiveStop('thumbnails', memberId)
const sendMemberScreenVideoReceiveStop = async (memberId) =>
  sendMemberReceiveStop('screens', memberId)

/**
 * request sdp offer for a specific member
 * @param type
 * @param memberId
 * @returns {Promise<{type: string, sdp: string}>}
 */
const requestOffer = async (type, memberId) => {
  const command = async () =>
    new Promise((resolve, reject) => {
      channel
        .push(`${type}:receive_init`, { member_id: memberId })
        .receive('ok', (result) => {
          resolve({ type: 'offer', sdp: result.sdp })
        })
        .receive('error', (e) => {
          reject(new Error(`"${type}:receive_init": ${JSON.stringify(e)}`))
        })
        .receive('timeout', () => {
          reject(new Error(`"${type}:receive_init": Timeout`))
        })
    })
  return command().catch(async (err) => {
    const parsedMessage = tryParseErrorMessage(err)
    if (parsedMessage.reason === 'Service Not Started') {
      return startVideoService(type).then(command)
    }
    if (parsedMessage.reason === 'Already Receiving') {
      return sendMemberReceiveStop(type, memberId).then(command)
    }
    throw err
  })
}

const requestVideoOfferForMember = async (memberId) =>
  requestOffer('video', memberId)
const requestThumbnailVideoOfferForMember = async (memberId) =>
  requestOffer('thumbnails', memberId)
const requestScreenVideoOfferForMember = async (memberId) =>
  requestOffer('screens', memberId)

/**
 * send answer sdp for requested offer
 * @param type
 * @param memberId
 * @param answer
 * @returns {Promise<{type: string, sdp: string}>}
 */
const sendAnswerForMember = async (type, memberId, answer) => {
  const command = async () =>
    new Promise((resolve, reject) => {
      const response = { member_id: memberId, sdp: answer.sdp, trickle: true }
      channel
        .push(`${type}:receive_start`, response)
        .receive('ok', resolve)
        .receive('error', (e) => {
          reject(new Error(`"${type}:receive_start": ${JSON.stringify(e)}`))
        })
        .receive('timeout', () => {
          reject(new Error(`"${type}:receive_start": Timeout`))
        })
    })

  return command().catch(async (err) => {
    const parsedMessage = tryParseErrorMessage(err)
    if (parsedMessage.reason === 'Not Initialized') {
      return requestOffer(type, memberId).then(command)
    }
    throw err
  })
}

const sendVideoAnswerForMember = async (answer, memberId) =>
  sendAnswerForMember('video', memberId, answer)
const sendThumbnailVideoAnswerForMember = async (answer, memberId) =>
  sendAnswerForMember('thumbnails', memberId, answer)
const sendScreenVideoAnswerForMember = async (answer, memberId) =>
  sendAnswerForMember('screens', memberId, answer)

/**
 * fetch upload link where file can be POST'ed to
 * @promise {id: string, link: string} | {error}
 */
const getUploadLink = async () =>
  new Promise((resolve, reject) => {
    channel
      .push('file:get_upload_link', {})
      .receive('ok', (result) => {
        resolve({ fileId: result.id, link: result.link })
      })
      .receive('error', (e) => {
        reject(new Error(`"file:get_upload_link": ${JSON.stringify(e)}`))
      })
      .receive('timeout', () => {
        reject(new Error('"file:get_upload_link": Timeout'))
      })
  })

/**
 * fetch download link for file where file get be GET'ted from
 * @param fileId string id of file
 * @promise {link: string} | {error}
 */
const getDownloadLink = async (id) =>
  new Promise((resolve, reject) => {
    channel
      .push('file:get_download_link', { id })
      .receive('ok', (result) => {
        resolve(result.link)
      })
      .receive('error', (e) => {
        reject(new Error(`"file:get_download_link": ${JSON.stringify(e)}`))
      })
      .receive('timeout', () => {
        reject(new Error('"file:get_download_link": Timeout'))
      })
  })

/**
 * download file
 */
const downloadFile = async (fileId) =>
  getDownloadLink(fileId).then(parseLinkDataAndGetFile)

/**
 * add previously uploaded file to the room file list.
 * @param fileId string id of file
 * @promise {link: string} | {error}
 */
const addFile = async (fileId) =>
  new Promise((resolve, reject) => {
    channel
      .push('file:add', { id: fileId })
      .receive('ok', resolve)
      .receive('error', (e) => {
        reject(new Error(`"file:add": ${JSON.stringify(e)}`))
      })
      .receive('timeout', () => {
        reject(new Error('"file:add": Timeout'))
      })
  })

/**
 * @return
 */
const startFiles = () => async (dispatch) =>
  new Promise((resolve, reject) => {
    channel
      .push('file:start', {})
      .receive('ok', () => {
        dispatch(startedFiles(true))
        resolve()
      })
      .receive('error', (e) => {
        /**
         * If the service is already started it doesn't matter as
         * we wan't it to be started.
         */
        if (e.reason !== 'Already Started') {
          reject(new Error(`"file:start": ${JSON.stringify(e)}`))
        } else {
          resolve()
        }
      })
      .receive('timeout', () => {
        reject(new Error('"file:start": Timeout'))
      })
  })

/**
 * removes previously added file from the room file list.
 * @param
 * @param file.id
 */
const removeFileFromRoom = async (id) =>
  new Promise((resolve, reject) => {
    channel
      .push('file:remove', { id })
      .receive('ok', resolve)
      .receive('error', (e) => {
        reject(new Error(`"file:remove": ${JSON.stringify(e)}`))
      })
      .receive('timeout', () => {
        reject(new Error('"file:remove": Timeout'))
      })
  })

const servicesConfig = {
  file: startFiles,
  chat: startChat,
  audio: () => startAudio,
  video: () => startVideo,
  screens: () => startScreenVideo,
}

const forcePinMember = (pinnedMember = null) => updateRoomMeta({ pinnedMember })

const forceUnpinMember = () => updateRoomMeta({ pinnedMember: null })

/**
 * Set the muted meta states based on the given `muted` parameter and whether it defers from the old
 * value or not.
 * @param memberId
 * @param muted - Is the member going to be muted or not.
 * @param powerMute - Is the mute executed by the host to forcefully mute a user.
 * @return
 */
const muteAudio = async (memberId, muted, powerMute = false) => {
  if (powerMute) {
    return updateMemberMeta(memberId, { isAudioMutedByHost: muted })
  }
  return updateMemberMeta(memberId, { isAudioMuted: muted })
}

/**
 * @param memberId
 * @param muted - Is the member going to be muted or not.
 * @param [signal=true] - Broadcast a message
 * @returns {import('redux-thunk').ThunkAction}
 */
const powermuteAudio =
  (memberId, muted, signal = true) =>
  async (dispatch, getState) => {
    const { [STORE_NAME]: channelRoom } = getState()

    const member = getUser(channelRoom, memberId)

    try {
      await muteAudio(memberId, muted, true)

      const isAudioMutedByHost = _.isMatch(member, audioMutedByHostPredicate)

      /**
       * If the old `isAudioMutedByHost` meta defers from the given `muted` we send
       * the `audio:mute` or `audio:unmute` command, to forcefully mute the member.
       */
      if (
        isAudioMutedByHost !== muted &&
        _.has(member, 'states.audio_inroom_id')
      ) {
        await sendAudioMuteMember(memberId, muted)
      }

      if (signal) {
        const hostMemberId = getHostId(channelRoom)
        await dispatch(
          broadcastUserSysMessage(hostMemberId, CHAT_CONTENT_TYPES.AUDIO_MUTE, {
            userId: memberId,
            status: muted,
          })
        )
      }
    } catch (err) {
      if (err.reason !== 'Member Not Started') {
        throw err
      }
    }
  }

/**
 * add user to list of users with video muted
 * @param memberId
 * @param muted boolean muted yes/no
 * @return
 */
const muteVideo = async (memberId, muted) => {
  return updateMemberMeta(memberId, { isVideoMuted: muted })
}

/**
 * add user to list of users with video muted by host
 * @param memberId
 * @param muted
 * @param [signal=true] - Broadcast a message
 * @returns {import('redux-thunk').ThunkAction}
 */
const powermuteVideo =
  (memberId, muted, signal = true) =>
  async (dispatch, getState) => {
    const { [STORE_NAME]: channelRoom } = getState()
    const hostMemberId = getHostId(channelRoom)

    await dispatch(updateMemberRights(memberId, { video_send: !muted }))

    if (signal) {
      await dispatch(
        broadcastUserSysMessage(hostMemberId, CHAT_CONTENT_TYPES.VIDEO_HIDDEN, {
          userId: memberId,
          status: muted,
        })
      )
    }
  }

/**
 * Set the room meta isVideoMuted value to true
 * @param muted
 * @return
 */
const muteRoomVideo = (muted) => async (dispatch, getState) => {
  const channelRoom = getState()[STORE_NAME]
  const pinnedMember = muted ? channelRoom.member_id : null

  const wasRoomVideoMuted = _.get(
    channelRoom,
    'meeting_room.meta.isVideoMuted',
    false
  )
  const isLocalUserHost = isHost(getMe(channelRoom))

  /**
   * If the `isAudioMuted` meta of the room has changed and the local user
   * is host.
   */
  if (wasRoomVideoMuted !== muted && isLocalUserHost) {
    dispatch(
      broadcastUserSysMessage(
        channelRoom.member_id,
        muted
          ? CHAT_CONTENT_TYPES.ROOM_MUTE_VIDEO
          : CHAT_CONTENT_TYPES.ROOM_UNMUTE_VIDEO
      )
    )

    dispatch(updateRoomMeta({ isVideoMuted: muted, pinnedMember }))

    /**
     * Filter the members based on whether they are guests, they are part of the audio room and
     * if they are not being unmuted while the room audio is still being muted.
     */
    const onlineMembers = rejectMemberId(
      rejectOfflineMembers(channelRoom.meeting_room.members),
      channelRoom.member_id
    )

    await Promise.all(
      onlineMembers.map((member) =>
        dispatch(powermuteVideo(member.member_id, muted, false))
      )
    )
  }
}

/**
 * Mute everybody in the audio room and set the room meta `isAudioMuted` to the given `muted` value.
 * @param muted - Is the member going to be muted or not.
 */
const muteRoomAudio = (muted) => async (dispatch, getState) => {
  const { [STORE_NAME]: channelRoom } = getState()

  const isLocalUserHost = isHost(getMe(channelRoom))

  const wasRoomAudioMuted = _.isMatch(channelRoom, {
    meeting_room: { meta: { isAudioMuted: true } },
  })

  /**
   * If the `isAudioMuted` meta of the room has changed and the local user
   * is host.
   */
  if (wasRoomAudioMuted !== muted && isLocalUserHost) {
    dispatch(
      broadcastUserSysMessage(
        channelRoom.member_id,
        muted
          ? CHAT_CONTENT_TYPES.ROOM_MUTE_AUDIO
          : CHAT_CONTENT_TYPES.ROOM_UNMUTE_AUDIO
      )
    )

    dispatch(updateRoomMeta({ isAudioMuted: muted }))

    /**
     * Filter the members based on whether they are guests, they are part of the audio room and
     * if they are not being unmuted while the room audio is still being muted.
     */
    await Promise.all(
      rejectMemberId(
        rejectOfflineMembers(channelRoom.meeting_room.members),
        channelRoom.member_id
      )
        .filter((member) => !!member.states.audio_inroom_id)
        .map(async (member) => {
          try {
            await dispatch(powermuteAudio(member.member_id, muted, false))
          } catch (e) {
            /**
             * If we try to mute a guest who hasn't been part of the audio room, we simply
             * ignore it and go on.
             */
            if (e.reason !== 'Member Not Started') {
              throw e
            }
          }
        })
    )
  }
}

/**
 * Send ICE candidate for requested type
 * @param type
 * @param candidate
 * @param [memberId=null]
 */
const sendIceCandidate = async (type, candidate, memberId = null) => {
  const payload = { candidate }

  if (memberId) payload.member_id = memberId

  const command = async () =>
    new Promise((resolve, reject) => {
      channel
        .push(`${type}:trickle`, payload)
        .receive('ok', resolve)
        .receive('error', (e) => {
          reject(new Error(`"${type}:trickle": ${JSON.stringify(e)}`))
        })
        .receive('timeout', () => {
          reject(new Error(`"${type}:trickle": Timeout`))
        })
    })
  return command().catch(async (err) => {
    const parsedMessage = tryParseErrorMessage(err)
    if (parsedMessage.reason === 'Service Not Started') {
      return startVideoService(type).then(command)
    }
    if (parsedMessage.reason === 'Not Started') {
      return startAudio().then(command)
    }
    throw err
  })
}

const sendAudioIceCandidate = async (candidate) =>
  sendIceCandidate('audio', candidate)
const sendVideoIceCandidate = async (candidate) =>
  sendIceCandidate('video', candidate)
const sendThumbnailsIceCandidate = async (candidate) =>
  sendIceCandidate('thumbnails', candidate)
const sendScreensIceCandidate = async (candidate) =>
  sendIceCandidate('screens', candidate)

const sendVideoMemberIceCandidate = async (candidate, memberId) =>
  sendIceCandidate('video', candidate, memberId)
const sendThumbnailsMemberIceCandidate = async (candidate, memberId) =>
  sendIceCandidate('thumbnails', candidate, memberId)
const sendScreensMemberIceCandidate = async (candidate, memberId) =>
  sendIceCandidate('screens', candidate, memberId)

const startServices = (services) => async (dispatch) => {
  return Promise.all(
    services.map((service) => dispatch(servicesConfig[service]()))
  )
}

const startServicesIfNeeded = (rights) => {
  const assignedRights = _.pickBy(rights, Boolean) // pick the ones that have true as value
  const listOfRights = _.uniq(
    _.map(_.keys(assignedRights), (right) => right.split('_')[0])
  )
  return startServices(
    listOfRights.filter((service) => !!servicesConfig[service])
  )
}

/**
 * @param payload
 */
const setFiles = (payload) => ({ type: FILE_SET, payload })
/**
 * @param payload
 */
const onLogout = () => ({ type: LOGOUT })

const getExtensionForFileName = (filename) =>
  filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2) || ''

const getOnlyFileName = (filename) =>
  filename.substring(0, filename.lastIndexOf('.')) || filename
/**
 * @param payload
 */
const updateFiles = (payload) => (dispatch, getState) => {
  const filesAdded = _.get(payload, 'added', [])
  const filesRemoved = _.get(payload, 'removed', [])

  const meId = getState()[STORE_NAME].member_id

  dispatch({ type: FILE_UPDATE, payload })

  if (_.size(filesRemoved) > 0) {
    dispatch({ type: ROOM_FILES_REMOVE_MESSAGES, fileIds: filesRemoved })
  }

  if (_.size(filesAdded) > 0) {
    const files = _.keyBy(
      _.map(filesAdded, (file) => ({
        id: file.path,
        ...file.value,
        ext: getExtensionForFileName(file.value.name),
        name: getOnlyFileName(file.value.name),
      })),
      'id'
    )

    _.keys(files).forEach((fileId) => {
      const content = { content: { type: CHAT_CONTENT_TYPES.NEW_FILE, fileId } }
      dispatch(createLocalSystemMessage(content, '*', meId))
    })
  }
}
/**
 * @param payload
 */
const setRoom = (payload) => {
  // TODO: this is a fix to compensate for inconsistent API behaviour, all members data should contain member_id property
  const members = { ...payload.meeting_room.members }

  _.each(members, (member, memberId) => {
    members[memberId] = { member_id: memberId, ...member }
  })

  const messages = _.map(payload.messages, (message) => ({
    ...message,
    ts: Date.now(),
  }))

  return {
    type: ROOM_SET,
    payload: {
      ...payload,
      meeting_room: { ...payload.meeting_room, members },
      messages,
    },
  }
}

/**
 * @param payload A partial state of the room
 */
const updateRoom = (payload) => {
  return {
    type: ROOM_UPDATE,
    payload,
  }
}

const slowLinkTimeouts = {
  EXTERNAL_VIDEO: {},
  EXTERNAL_THUMBNAIL: {},
  EXTERNAL_SCREEN: {},
  VIDEO: false,
  THUMBNAIL: false,
  SCREEN: false,
}

const setSlowLink =
  ({ uplink, memberId, lost }, service) =>
  (dispatch) => {
    dispatch({
      type: SLOW_LINK,
      payload: { uplink, memberId, lost },
      service,
    })

    let slowLinkPath = `[${service}]`
    if (uplink) slowLinkPath += `[${memberId}]`

    const timeout = _.get(slowLinkTimeouts, slowLinkPath)
    if (timeout) clearTimeout(timeout)

    const newTimeout = setTimeout(() => {
      dispatch({
        type: CLEAR_SLOW_LINK,
        payload: { uplink, memberId },
        service,
      })
      _.set(slowLinkTimeouts, slowLinkPath, null)
    }, SLOW_LINK_RESET_TIMEOUT)

    _.set(slowLinkTimeouts, slowLinkPath, newTimeout)
  }

const setVideoSlowLink = ({ uplink, sender: memberId, lost }) => {
  const slowLinkService = uplink
    ? ROOM_SERVICES.EXTERNAL_VIDEO
    : ROOM_SERVICES.ROOM_VIDEO
  return setSlowLink({ uplink, memberId, lost }, slowLinkService)
}

const setThumbnailSlowLink = ({ uplink, sender: memberId, lost }) => {
  const slowLinkService = uplink
    ? ROOM_SERVICES.EXTERNAL_THUMBNAIL
    : ROOM_SERVICES.ROOM_THUMBNAIL
  return setSlowLink({ uplink, memberId, lost }, slowLinkService)
}

const setScreenSlowLink = ({ uplink, sender: memberId, lost }) => {
  const slowLinkService = uplink
    ? ROOM_SERVICES.EXTERNAL_SCREEN
    : ROOM_SERVICES.ROOM_SCREEN
  return setSlowLink({ uplink, memberId, lost }, slowLinkService)
}

/**
 * Start sharing the given board with people in the meeting.
 * @throws {Error} The local user must be a host.
 * @param board
 * @param type
 * @param id
 * @param title
 * @param links
 */
const startSharingBoard = (board) => async (dispatch, getState) => {
  const { [STORE_NAME]: channelRoom } = getState()

  if (!isHost(getMe(channelRoom))) {
    throw new Error('User is not allowed to share a board')
  }

  await dispatch(updateRoomMeta({ board }))
}

/**
 * Stop sharing the already shared board with the people in the meeting.
 * @throws {Error} The local user must be a host.
 */
const stopSharingBoard = () => async (dispatch, getState) => {
  const { [STORE_NAME]: channelRoom } = getState()

  if (!isHost(getMe(channelRoom))) {
    throw new Error('User is not allowed to stop sharing a board')
  }

  await dispatch(updateRoomMeta({ board: null }))
}

/**
 * this function monkeypatches the api data from the room:info
 * event to data that is deep mergeable with the root state of channel room
 * @param conference record data
 * @returns map with allowed values
 */
function patchRoomInfoData(payload) {
  if (payload.code && payload.meta && payload.name) {
    const ret = {
      meeting_code: payload.code,
      meeting_meta: payload.meta,
      meeting_name: payload.name,
    }

    logger.info(
      'ChannelRoomReducer: monkeypatched incoming data from event room:info',
      payload,
      ret
    )
    return ret
  }
  return payload
}

// ------------------------------------
// Channel events
// ------------------------------------
const EVENT_CHAT_MESSAGE = 'chat:message'
const EVENT_FILE_DIFF = 'file:diff'
const EVENT_FILE_LIST = 'file:list'
const EVENT_MEMBER_JOIN = 'room:member_join'
const EVENT_MEMBER_LEAVE = 'room:member_leave'
const EVENT_MEMBER_META = 'room:member_meta'
const EVENT_MEMBER_RIGHTS = 'room:member_rights'
const EVENT_MEMBER_STATE = 'room:member_state'
const EVENT_MEMBER_KICKED = 'room:kicked'
const EVENT_META = 'room:meta'
const EVENT_ROOM_INFO = 'room:info'
const EVENT_VIDEO_SLOW_LINK = 'video:slow_link'
const EVENT_THUMBNAIL_SLOW_LINK = 'thumbnails:slow_link'
const EVENT_SCREEN_SLOW_LINK = 'screens:slow_link'

const channelEventActionMapping = {
  [EVENT_CHAT_MESSAGE]: (payload) => createMessage(payload),
  [EVENT_FILE_DIFF]: (payload) => updateFiles(payload),
  [EVENT_FILE_LIST]: (payload) => setFiles(payload),
  [EVENT_MEMBER_JOIN]: (payload) => createMember(payload),
  [EVENT_MEMBER_LEAVE]: (payload) => deleteMember(payload),
  [EVENT_MEMBER_META]: ({ member_id: memberId, joins }) =>
    updatedMemberMeta(memberId, joins),
  [EVENT_MEMBER_RIGHTS]: ({ member_id: memberId, joins }) =>
    updatedMemberRights(memberId, joins),
  [EVENT_MEMBER_STATE]: ({ member_id: memberId, joins }) =>
    updatedMemberStates(memberId, joins),
  [EVENT_META]: (payload) => updatedMeta(payload),
  [EVENT_ROOM_INFO]: (payload) => updateRoom(patchRoomInfoData(payload)),
  [EVENT_MEMBER_KICKED]: (payload) => kickedMember(payload),
  [EVENT_VIDEO_SLOW_LINK]: (payload) => setVideoSlowLink(payload),
  [EVENT_THUMBNAIL_SLOW_LINK]: (payload) => setThumbnailSlowLink(payload),
  [EVENT_SCREEN_SLOW_LINK]: (payload) => setScreenSlowLink(payload),
}

/**
 * exported action to join channel
 */
const join =
  ({
    meeting_id: meetingId,
    invitation_id: invitationId,
    meeting_code: meetingCode,
    meeting_name: meetingName,
    meta,
    username,
  }) =>
  async (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: JOINING })

      const socket = getSocket()

      channel = socket.channel(
        channelConf.id,
        _.pickBy(
          {
            meeting_id: meetingId,
            invitation_id: invitationId,
            meeting_code: meetingCode,
            meeting_name: meetingName,
            meta,
            username,
            ...channelConf.conf,
          },
          Boolean
        )
      )
      channel.onMessage = handleMessage(
        dispatch,
        channelEventActionMapping,
        channelConf,
        log
      )
      channel.onError((errorEvent) => {
        if (errorEvent) {
          dispatch(error(errorEvent))
        }
      })
      channel.onClose((event) => dispatch(closed(event)))
      channel
        .join()
        .receive('ok', (payload) => {
          dispatch(setRoom(payload))
          dispatch(joined(payload))

          if (isHost(getMe(payload))) {
            const pinnedMember = _.get(
              payload,
              'meeting_room.meta.pinnedMember'
            )

            /**
             * Check if there was a member pinned who doesn't exist anymore.
             * If this is the case we remove the pinned member.
             */
            if (
              pinnedMember &&
              !_.has(payload, `meeting_room.members[${pinnedMember}]`)
            ) {
              dispatch(updateRoomMeta({ pinnedMember: false }))
            }
          }

          awaitingCalls.forEach((call) => call())
          awaitingCalls = []

          resolve(payload)
        })
        .receive('error', (e) => {
          /**
           * destroy the channel if we get an error on join
           * this is needed to be able to join again with the same channel
           * without creating duplicate event listeners
           */
          channel.leave()
          channel = null
          dispatch(joiningError(e))
          reject(new Error(e))
        })
        .receive('timeout', () => {
          dispatch(joiningTimeout())
          reject(new Error('Timeout'))
        })
    })
  }

const revokeHost = (memberId) => ({
  type: ACTION_TYPES.REVOKE_HOST,
  payload: {
    memberId,
  },
})

/**
 * export actions that can be called for creating a new state in the reducer
 */
export const actions = {
  acceptScreenShareUser,
  addFile,
  broadcastUserSysMessage,
  cleanMessages,
  closed,
  createLocalSystemMessage,
  createMessage,
  disconnect,
  downloadFile,
  forcePinMember,
  forceUnpinMember,
  getChatHistory,
  getDownloadLink,
  getUploadLink,
  join,
  kickMember,
  leave,
  lowerMemberHand,
  messageCreate,
  muteAudio,
  muteRoomAudio,
  muteRoomVideo,
  muteVideo,
  onLogout,
  powermuteAudio,
  powermuteVideo,
  raiseMemberHand,
  rejectScreenShareUser,
  removeAllFiles,
  removeFileFromRoom,
  requestScreenVideoOfferForMember,
  requestThumbnailVideoOfferForMember,
  requestVideoOfferForMember,
  resetRoomMeta,
  revokeHost,
  screenVideoStop,
  sendAudioIceCandidate,
  sendAudioMuteMember,
  sendAudioOffer,
  sendAudioStop,
  sendChatMessage,
  sendMemberScreenVideoReceiveStop,
  sendMemberThumbnailVideoReceiveStop,
  sendMemberVideoReceiveStop,
  sendScreensIceCandidate,
  sendScreensMemberIceCandidate,
  sendScreenVideoAnswerForMember,
  sendScreenVideoOffer,
  sendScreenVideoStop,
  sendThumbnailsIceCandidate,
  sendThumbnailsMemberIceCandidate,
  sendThumbnailVideoAnswerForMember,
  sendThumbnailVideoOffer,
  sendThumbnailVideoStop,
  sendUserSysMessage,
  sendVideoAnswerForMember,
  sendVideoIceCandidate,
  sendVideoMemberIceCandidate,
  sendVideoOffer,
  sendVideoStop,
  setMemberRaisedHand,
  setMemberRights,
  setReadyToLeave,
  setRoomMeta,
  startAudio,
  startChat,
  startFiles,
  startScreenVideo,
  startServices,
  startServicesIfNeeded,
  startSharingBoard,
  startThumbnailVideo,
  startVideo,
  stopSharingBoard,
  thumbnailVideoStop,
  updateMemberMeta,
  updateMemberRights,
  updateRoom,
  updateRoomMeta,
  videoStop,
}

// ------------------------------------
// Reducer
// ------------------------------------
const INITIAL_STATE = {
  '@@channel': {
    state: null,
    log: [],
    chatStarted: false,
    filesStarted: false,
    audioStarted: false,
    videoStarted: false,
    screenVideoStarted: false,
    thumbnailVideoStarted: false,
  },
  messages: [],
  slowLinks: {
    [ROOM_SERVICES.EXTERNAL_VIDEO]: {},
    [ROOM_SERVICES.EXTERNAL_THUMBNAIL]: {},
    [ROOM_SERVICES.EXTERNAL_SCREEN]: {},
    [ROOM_SERVICES.ROOM_VIDEO]: false,
    [ROOM_SERVICES.ROOM_THUMBNAIL]: false,
    [ROOM_SERVICES.ROOM_SCREEN]: false,
  },
  meeting_code: '',
  meeting_id: '',
  meeting_meta: {},
  meeting_name: '',
  member_id: '',
  meeting_room: {
    meta: {
      /**
       * @type {Array<string>}
       */
      owners: [],
    },
    members: {},
  },
}

export type ChannelRoomState = typeof INITIAL_STATE

/**
 * Replace the screenshare request message from a specific user with the given type.
 * @param messages
 * @param type
 * @param memberId
 * @return
 */
const replaceRequestSSMessage = (messages, type, memberId) =>
  messages.map((item) => {
    if (
      _.get(item, MESSAGE_DATA_TYPE) === CHAT_CONTENT_TYPES.REQUEST_SS &&
      item.content.data.message_data.userId === memberId
    ) {
      return _.merge({}, item, {
        content: {
          data: {
            local_id: uuid(),
            message_data: { ...item.content.data.message_data, type },
          },
        },
        ts: Date.now(),
      })
    }
    return item
  })

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [JOINING]: createStateLoggingActionHandler(JOINING, 'JOINING'),
  [JOINED]: createStateLoggingActionHandler(JOINED, 'JOINED', 'payload'),
  [JOINING_ERROR]: createStateLoggingActionHandler(
    JOINING_ERROR,
    'JOINING_ERROR',
    'error'
  ),
  [JOINING_TIMEOUT]: createStateLoggingActionHandler(
    JOINING_TIMEOUT,
    'JOINING_TIMEOUT'
  ),
  [CLOSING]: createStateLoggingActionHandler(CLOSING, 'CLOSING'),
  [CLOSED]: (state, action) => {
    const newState = createStateLoggingActionHandler(CLOSED, 'CLOSED')(
      state,
      action
    )
    newState['@@channel'].chatStarted = false
    newState['@@channel'].filesStarted = false
    newState['@@channel'].audioStarted = false
    newState['@@channel'].videoStarted = false
    newState['@@channel'].screenVideoStarted = false
    newState['@@channel'].thumbnailVideoStarted = false
    newState.messages = INITIAL_STATE.messages
    newState.slowLinks = INITIAL_STATE.slowLinks
    return newState
  },
  [LOGOUT]: createStateLoggingActionHandler(LOGOUT, 'LOGOUT'),
  [CLOSING_ERROR]: createStateLoggingActionHandler(
    CLOSING_ERROR,
    'CLOSING_ERROR',
    'error'
  ),
  [CLOSING_TIMEOUT]: createStateLoggingActionHandler(
    CLOSING_TIMEOUT,
    'CLOSING_TIMEOUT'
  ),
  [ERROR]: createStateLoggingActionHandler(ERROR, 'ERROR', 'error'),
  [LOG]: (state, action) =>
    dotProp.set(state, '@@channel.log', (l) => [
      ...l,
      {
        ts: Date.now(),
        kind: 'event',
        msg: action.event,
        data: action.payload,
      },
    ]),
  [ROOM_SCREENSHARE_ACCEPT]: (state, action) => ({
    ...state,
    messages: replaceRequestSSMessage(
      state.messages,
      CHAT_CONTENT_TYPES.REQUEST_SS_ACCEPTED,
      action.memberId
    ),
  }),
  [ROOM_SCREENSHARE_REJECT]: (state, action) => ({
    ...state,
    messages: replaceRequestSSMessage(
      state.messages,
      CHAT_CONTENT_TYPES.REQUEST_SS_REJECTED,
      action.memberId
    ),
  }),
  [MESSAGE_CREATE]: (state, action) => ({
    ...state,
    messages: [...state.messages, action.payload],
  }),
  [MEMBER_CREATE]: (state, action) =>
    dotProp.set(state, 'meeting_room.members', (list) => ({
      ...list,
      [action.payload.member_id]: action.payload,
    })),
  [MEMBER_DELETE]: (state, action) =>
    dotProp.set(
      state,
      `meeting_room.members.${action.payload.member_id}.meta`,
      (meta) => ({ ...meta, offline: true })
    ),
  [UPDATED_MEMBER_RIGHTS]: (state, action) =>
    dotProp.set(
      state,
      `meeting_room.members.${action.memberId}.rights`,
      (rights) => ({ ...rights, ...action.rights })
    ),
  [UPDATED_MEMBER_STATE]: (state, action) =>
    dotProp.set(
      state,
      `meeting_room.members.${action.memberId}.states`,
      (states) => ({ ...states, ...action.states })
    ),
  [UPDATED_MEMBER_META]: (state, action) =>
    dotProp.set(
      state,
      `meeting_room.members.${action.memberId}.meta`,
      (meta) => ({ ...meta, ...action.meta })
    ),
  [ROOM_UPDATE]: (state, action) => _.merge({}, state, action.payload),
  [UPDATE_ROOM_META]: (state, action) =>
    dotProp.set(state, 'meeting_room.meta', action.payload),
  [UPDATED_ROOM_META]: (state, action) =>
    dotProp.set(state, 'meeting_room.meta', action.payload),
  [ROOM_SET]: (state, action) => ({ ...state, ...action.payload }),
  [FILES_STARTED]: (state, action) =>
    dotProp.set(state, '@@channel.filesStarted', action.payload),
  [CHAT_STARTED]: (state, action) =>
    dotProp.set(state, '@@channel.chatStarted', action.payload),
  [ROOM_FILES_REMOVE_MESSAGES]: (state, action) => {
    // replace group chat message with unexistent (invisible) type and add deleted file message
    let deleteMessage = ''
    const messages = state.messages.map((message) => {
      if (
        _.get(message, MESSAGE_DATA_TYPE) === 'new_file' &&
        _.includes(
          action.fileIds,
          _.get(message, 'content.data.message_data.fileId')
        )
      ) {
        deleteMessage = {
          ...message,
          content: {
            data: {
              message_data: {
                ...message.content.data.message_data,
                type: 'deleted_file',
                userId: _.get(
                  getHosts(_.get(state, 'meeting_room.members', {})),
                  '[0].member_id',
                  undefined
                ),
              },
            },
          },
          ts: Date.now(),
        }
        return {
          ...message,
          content: {
            data: {
              message_data: {
                ...message.content.data.message_data,
                type: 'invisible',
                userId: _.get(
                  getHosts(_.get(state, 'meeting_room.members', {})),
                  '[0].member_id',
                  undefined
                ),
              },
            },
          },
          ts: Date.now(),
        }
      }
      return message
    })
    if (deleteMessage) messages.push(deleteMessage)
    return { ...state, messages }
  },
  [REMOVE_SS_REQUEST_MESSAGE]: (state, action) => {
    const messages = _.reject(state.messages, {
      content: {
        data: {
          message_data: {
            type: CHAT_CONTENT_TYPES.REQUEST_SS,
            userId: action.payload,
          },
        },
      },
    })
    return { ...state, messages }
  },
  [ROOM_CLEAN_MESSAGES]: (state) => dotProp.set(state, 'messages', []),
  [ROOM_MESSAGE_HISTORY]: (state, action) => {
    // we want to add the history messages to existing ones
    // if in the future we have meetings with multiple registered users
    // or if, not existing scenario, we would call this other than at join
    const messages = _.concat(state.messages, action.payload)
    return { ...state, messages }
  },
  [SLOW_LINK]: (state, action) => {
    const slowLinksValue = { ...state.slowLinks }

    if (action.payload.uplink) {
      slowLinksValue[action.service][action.payload.memberId] = true
    } else {
      slowLinksValue[action.service] = true
    }

    return {
      ...state,
      slowLinks: slowLinksValue,
    }
  },
  [CLEAR_SLOW_LINK]: (state, action) => {
    const slowLinksValue = { ...state.slowLinks }

    if (action.payload.uplink) {
      delete slowLinksValue[action.service][action.payload.memberId]
    } else {
      slowLinksValue[action.service] = false
    }

    return {
      ...state,
      slowLinks: slowLinksValue,
    }
  },
  'sm-web/RESET': () => INITIAL_STATE,
}

export default (state = INITIAL_STATE, action = {}) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export const selectors = {
  getChannelState(state: ChannelRoomState) {
    if (!state || typeof state !== 'object') {
      const err = new Error('Given state should be an object')
      err.state = state
      throw err
    }
    return _.get(state, '@@channel.state')
  },
  isJoining(state: ChannelRoomState) {
    const channelState = selectors.getChannelState(state)
    return channelState === JOINING
  },
  isJoiningError(state: ChannelRoomState) {
    const channelState = selectors.getChannelState(state)
    return channelState === JOINING_ERROR
  },
  isJoiningTimeout(state: ChannelRoomState) {
    const channelState = selectors.getChannelState(state)
    return channelState === JOINING_TIMEOUT
  },
  isJoined(state: ChannelRoomState) {
    const channelState = selectors.getChannelState(state)
    return channelState === JOINED
  },
  isClosing(state: ChannelRoomState) {
    const channelState = selectors.getChannelState(state)
    return channelState === CLOSING
  },
  isClosed(state: ChannelRoomState) {
    const channelState = selectors.getChannelState(state)
    return channelState === CLOSED
  },
  isError(state: ChannelRoomState) {
    const channelState = selectors.getChannelState(state)
    return channelState === ERROR
  },

  getAutoAccept(state: ChannelRoomState) {
    return _.get(state, 'meeting_room.meta.autoAccept')
  },
  getLicenseId(state: ChannelRoomState): string {
    return _.get(state, 'meeting_room.meta.licenseId', DEFAULT_MAX_GUESTS)
  },
  getRoomLimitGuests(state: ChannelRoomState) {
    return _.get(state, 'meeting_room.meta.roomLimitGuests', DEFAULT_MAX_GUESTS)
  },
  getIframeURL(state: ChannelRoomState) {
    return _.get(state, 'meeting_room.meta.iframeUrl')
  },
  getOwners(state: ChannelRoomState) {
    return _.get(state, 'meeting_room.meta.owners')
  },
  getMeetingId(state: ChannelRoomState) {
    return _.get(state, 'meeting_id')
  },
  getMemberId(state: ChannelRoomState) {
    return _.get(state, 'member_id')
  },
  getMeetingCode(state: ChannelRoomState) {
    return _.get(state, 'meeting_code')
  },
  getMeetingName(state: ChannelRoomState) {
    return _.get(state, 'meeting_name')
  },
  getSlowLink(state: ChannelRoomState) {
    return _.get(state, 'slowLinks')
  },
  getVideoSlowLink(state: ChannelRoomState) {
    const slowLinks = selectors.getSlowLink(state)
    return _.get(slowLinks, ROOM_SERVICES.ROOM_VIDEO)
  },
  /**
   * @param state
   * @param [memberId=undefined]
   * @return
   */
  getExternalVideoSlowLink(state: ChannelRoomState, memberId = undefined) {
    const slowLinks = selectors.getSlowLink(state)
    const externalSlowLinks = _.get(slowLinks, ROOM_SERVICES.EXTERNAL_VIDEO)
    if (memberId) {
      return externalSlowLinks[memberId]
    }
    return externalSlowLinks
  },
  getThumbnailSlowLink(state) {
    const slowLinks = selectors.getSlowLink(state)
    return _.get(slowLinks, ROOM_SERVICES.ROOM_THUMBNAIL)
  },
  getExternalThumbnailSlowLink(state: ChannelRoomState, memberId = undefined) {
    const slowLinks = selectors.getSlowLink(state)
    const externalSlowLinks = _.get(slowLinks, ROOM_SERVICES.EXTERNAL_THUMBNAIL)
    if (memberId) {
      return externalSlowLinks[memberId]
    }
    return externalSlowLinks
  },
  getScreenSlowLink(state) {
    const slowLinks = selectors.getSlowLink(state)
    return _.get(slowLinks, ROOM_SERVICES.ROOM_SCREEN)
  },
  getExternalScreenSlowLink(state: ChannelRoomState, memberId = undefined) {
    const slowLinks = selectors.getSlowLink(state)
    const externalSlowLinks = _.get(slowLinks, ROOM_SERVICES.EXTERNAL_SCREEN)
    if (memberId) {
      return externalSlowLinks[memberId]
    }
    return externalSlowLinks
  },

  getMembers(state: ChannelRoomState) {
    return _.get(state, 'meeting_room.members', {})
  },

  getMeta(state: ChannelRoomState) {
    return _.get(state, 'meeting_room.meta', {})
  },
}

export const predicates = {
  meetingEnded: { meeting_room: { meta: { meetingEnded: true } } },
}
