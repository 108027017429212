import { ElementType } from 'react'
import { useTranslation } from 'react-i18next'

import {
  STORE_NAME as SETTINGS_STORE_NAME,
  selectors as settingsSelectors,
} from 'app/features/settings/settings.reducer'
import commandsRegistry from 'app/services/commands/commands-registry'
import Schema, { Action } from 'app/services/commands/schema'
import { useSelector } from 'app/services/state/redux-store'
import { KEY_SYMBOLS } from 'constants/keys'

type Props<T extends ElementType> = {
  action?: Action
  actionState?: boolean | string | number
  position?: 'top' | 'bottom' | 'left' | 'right'
} & React.ComponentPropsWithoutRef<T>

export function useTooltip<T extends ElementType>(
  { action, actionState, position = 'top', ...other }: Props<T>,
  event = 'onClick'
): {
  tooltipProps: Record<string, any>
  otherProps: React.ComponentPropsWithoutRef<T>
} {
  const { t } = useTranslation()

  const showShortcut = useSelector(({ [SETTINGS_STORE_NAME]: settings }) => {
    return settingsSelectors.getHotkeysEnabled(settings)
  })

  let tooltipProps = undefined
  let otherProps = other

  if (action && showShortcut) {
    const commandEventHandler = (e) => {
      commandsRegistry.get(action.actionType)(e)
    }

    let options
    if (
      actionState !== null &&
      typeof actionState !== 'undefined' &&
      typeof actionState.toString === 'function'
    ) {
      options = { context: actionState.toString() }
    }

    const label = t(action.label, options) as string

    const keyBinding = Schema.resolveByAction(action)
    const shortcut = keyBinding.keys
      .map(
        (key, index) =>
          `${KEY_SYMBOLS[key] || key.toUpperCase()}${
            index < keyBinding.keys.length - 1 ? '+' : ''
          }`
      )
      .join('')
    const tooltip = `${label}\n(${shortcut})`

    tooltipProps = {
      label: tooltip,
      position,
    }
    otherProps = { ...other, [event]: commandEventHandler }
  }
  return { tooltipProps, otherProps }
}
export default useTooltip
