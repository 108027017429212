import _ from 'lodash'

import BackgroundEffectImages, {
  defaultImage,
} from 'resources/images/background-effect-images'
import { BACKGROUND_EFFECT_TYPES } from 'constants/constants'
import env from 'app/config/env'
import localStore from 'app/services/state/local-store'

export const STORE_NAME = 'feature/background-effect'

const START_BLUR = `sm-web/${STORE_NAME}/START_BLUR`
const START_REPLACE = `sm-web/${STORE_NAME}/START_REPLACE`
const STOP_EFFECT = `sm-web/${STORE_NAME}/STOP_EFFECT`
const SET_LOADING = `sm-web/${STORE_NAME}/SET_LOADING`
const SET_SHOULD_BE_ENABLED = `sm-web/${STORE_NAME}/SET_SHOULD_BE_ENABLED`
const SET_REMEMBER_EFFECT = `sm-web/${STORE_NAME}/SET_REMEMBER_EFFECT`
const SET_BLUR_VALUE = `sm-web/${STORE_NAME}/SET_BLUR_VALUE`
const SET_BACKGROUND_IMAGE = `sm-web/${STORE_NAME}/SET_BACKGROUND_IMAGE`
const SET_ASYNC_PROCESSING = `sm-web/${STORE_NAME}/SET_ASYNC_PROCESSING`

export const ACTION_TYPES = {
  START_BLUR,
  START_REPLACE,
  STOP_EFFECT,
  SET_LOADING,
  SET_SHOULD_BE_ENABLED,
  SET_REMEMBER_EFFECT,
  SET_BLUR_VALUE,
  SET_BACKGROUND_IMAGE,
  SET_ASYNC_PROCESSING,
}

const startBlur = (showLoadingNotification) => ({
  type: START_BLUR,
  showLoadingNotification,
})
const startReplace = (showLoadingNotification) => ({
  type: START_REPLACE,
  showLoadingNotification,
})
const stopEffect = () => ({ type: STOP_EFFECT })
const setLoading = (loading) => ({ type: SET_LOADING, payload: loading })
const setShouldBeEnabled = (shouldBeEnabled) => ({
  type: SET_SHOULD_BE_ENABLED,
  payload: shouldBeEnabled,
})
const setBlurValue = (value) => ({ type: SET_BLUR_VALUE, payload: value })
const setBackgroundImage = (imageName) => ({
  type: SET_BACKGROUND_IMAGE,
  payload: imageName,
})
const setRememberEffect = (remember) => ({
  type: SET_REMEMBER_EFFECT,
  payload: remember,
})
const setAsyncProcessing = (async) => ({
  type: SET_ASYNC_PROCESSING,
  payload: async,
})

export const actions = {
  startBlur,
  startReplace,
  stopEffect,
  setLoading,
  setShouldBeEnabled,
  setBlurValue,
  setBackgroundImage,
  setRememberEffect,
  setAsyncProcessing,
}

/**
 * Returns initial background blur config based on local store and config.
 * @returns
 */
const getInitialBlurConfig = () => {
  const storedConfig = localStore.getBackgroundBlurConfig()
  const configEdgeBlur = env('background_effects.blurEffectEdgeBlur', 12)

  return storedConfig
    ? { ...storedConfig, edgeBlurAmount: configEdgeBlur }
    : {
        backgroundBlurAmount: 45,
        edgeBlurAmount: configEdgeBlur,
        flipHorizontal: false,
      }
}

/**
 * Returns initial background replace config based on local store.
 * @returns
 */
const getInitialReplaceConfig = () => {
  const storedConfig = localStore.getBackgroundReplaceConfig()
  const configMaskBlur = env('background_effects.replaceEffectEdgeBlur', 12)

  if (storedConfig) {
    const { backgroundImageName } = storedConfig

    if (!BackgroundEffectImages[backgroundImageName]) {
      // If the stored background image isn't available, use default image
      storedConfig.backgroundImageName = defaultImage
    }
  }

  return storedConfig
    ? {
        ...storedConfig,
        maskBlurAmount: configMaskBlur,
      }
    : {
        maskBlurAmount: configMaskBlur,
        flipHorizontal: false,
        backgroundImageName: defaultImage,
        cropMode: 'FILL',
      }
}

function getInitalState() {
  return {
    shouldBeEnabled: false,
    activeEffect: localStore.getActiveBackgroundEffect(),
    loading: false,
    rememberConfig: !!localStore.getActiveBackgroundEffect(),
    blurConfig: getInitialBlurConfig(),
    replaceConfig: getInitialReplaceConfig(),
    isAsync: env('background_effects.asyncFrameProcessing', true),
  }
}

const ACTION_HANDLERS = {
  [START_BLUR]: (state) => ({
    ...state,
    activeEffect: BACKGROUND_EFFECT_TYPES.BLUR,
  }),
  [START_REPLACE]: (state) => ({
    ...state,
    activeEffect: BACKGROUND_EFFECT_TYPES.REPLACE,
  }),
  [STOP_EFFECT]: (state) => ({
    ...state,
    activeEffect: null,
  }),
  [SET_SHOULD_BE_ENABLED]: (state, action) => ({
    ...state,
    shouldBeEnabled: action.payload,
  }),
  [SET_LOADING]: (state, action) => ({
    ...state,
    loading: action.payload,
  }),
  [SET_BLUR_VALUE]: (state, action) => ({
    ...state,
    blurConfig: {
      ...state.blurConfig,
      backgroundBlurAmount: action.payload,
    },
  }),
  [SET_BACKGROUND_IMAGE]: (state, action) => ({
    ...state,
    replaceConfig: {
      ...state.replaceConfig,
      backgroundImageName: action.payload,
    },
  }),
  [SET_REMEMBER_EFFECT]: (state, action) => ({
    ...state,
    rememberConfig: action.payload,
  }),
  [SET_ASYNC_PROCESSING]: (state, action) => ({
    ...state,
    isAsync: action.payload,
  }),
  'sm-web/RESET': () => getInitalState(),
}

export default (state = getInitalState(), action = {}) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export const selectors = {
  /**
   * @param state
   * @returns
   */
  getShouldBeEnabled(state) {
    return _.get(state, 'shouldBeEnabled')
  },
  /**
   * @param state
   * @returns
   */
  getLoadingState(state) {
    return _.get(state, 'loading')
  },
  getBlurConfig(state) {
    return _.get(state, 'blurConfig')
  },
  getReplaceConfig(state) {
    return _.get(state, 'replaceConfig')
  },
  /**
   * @param state
   * @returns
   */
  getRememberConfig(state) {
    return _.get(state, 'rememberConfig')
  },
  getActiveEffect(state) {
    return _.get(state, 'activeEffect')
  },
  getIsAsync(state) {
    return _.get(state, 'isAsync')
  },
}
