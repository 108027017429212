import Task from 'lib/tasks/coligo-task'
import CustomTask from 'lib/tasks/coligo-custom-task'
import SerialTask from 'lib/tasks/coligo-serial-task'

import { actions as socketActions } from 'app/state/api/socket/socket.reducer'

import JoinAuthChannelTask from 'app/tasks/join-auth-channel-task'

class LoginAsGuestTask extends Task {
  name = 'LoginAsGuestTask'

  store

  options

  task

  /**
   *
   * @param store
   * @param options
   * @param options.apiUrl
   * @param options.apiUrlFallback
   */
  constructor(store, options) {
    super()

    this.store = store
    this.options = options
  }

  cancel() {
    if (this.task) {
      this.task.cancel()
    }
  }

  async run() {
    this.task = new SerialTask('LoginAsGuestTaskSerialTask', [
      new CustomTask('ConnectSocketTask', () =>
        this.store.dispatch(
          socketActions.connect(
            this.options.apiUrl,
            { domain: 'guest' },
            this.options.apiUrlFallback
          )
        )
      ),
      new JoinAuthChannelTask(this.store),
    ])
    await this.task.run()
  }
}

export default LoginAsGuestTask
