import env from 'app/config/env'

import coligoTrackFactory from './coligo-track-factory'

/**
 * @class
 */
class ColigoAudioMixer {
  static passIceCandidates(from, to) {
    from.addEventListener('icecandidate', (e) =>
      to.addIceCandidate(e.candidate || null)
    )
  }

  constructor(tracks) {
    if (tracks) {
      this._tracks.push(...tracks)
    }
  }

  _track = null

  _tracks = []

  _streamDestination = null

  _audioContext = null

  _sendingConnection = null

  _receivingConnection = null

  _sender = null

  _sendingConnectionEstablished = false

  _receivingConnectionEstablished = false

  get track() {
    return this._track
  }

  _initConnection() {
    this._closeConnections()

    this._sendingConnection = new RTCPeerConnection(
      env('connection_config', {})
    )
    this._receivingConnection = new RTCPeerConnection(
      env('connection_config', {})
    )

    ColigoAudioMixer.passIceCandidates(
      this._sendingConnection,
      this._receivingConnection
    )
    ColigoAudioMixer.passIceCandidates(
      this._receivingConnection,
      this._sendingConnection
    )

    // Create a dummy audio stream to add it the RTCRtpSender.
    const { stream: audioStream } =
      new AudioContext().createMediaStreamDestination()
    // Get the audio track from the dummy stream.
    this._sender = this._sendingConnection.addTrack(
      audioStream.getAudioTracks()[0],
      audioStream
    )

    return new Promise((resolve, reject) => {
      const finalise = () => {
        const mixedTrack = this._createNewMixedTrack()
        if (mixedTrack) {
          this._replaceSendingTrack(mixedTrack)
        }
        resolve()
      }

      this._receivingConnection.addEventListener('track', (event) => {
        this._track = coligoTrackFactory.createTrack(
          coligoTrackFactory.TYPES.LOCAL,
          {
            track: event.track,
            stream: event.streams[0],
          }
        )
      })

      const _handleReceivingIceConnectionStateChange = (event) => {
        if (event.target.iceConnectionState === 'connected') {
          this._receivingConnectionEstablished = true

          if (this._sendingConnectionEstablished) {
            this._receivingConnection.removeEventListener(
              'iceconnectionstatechange',
              _handleReceivingIceConnectionStateChange
            )

            finalise()
          }
        } else if (
          event.target.iceConnectionState === 'disconnected' &&
          !this._receivingConnectionEstablished
        ) {
          this._receivingConnection.removeEventListener(
            'iceconnectionstatechange',
            _handleReceivingIceConnectionStateChange
          )

          reject(new Error('Receiving connection disconnected'))
        }
      }

      const _handleSendingIceConnectionStateChange = (event) => {
        if (event.target.iceConnectionState === 'connected') {
          this._sendingConnectionEstablished = true

          if (this._receivingConnectionEstablished) {
            this._sendingConnection.removeEventListener(
              'iceconnectionstatechange',
              _handleSendingIceConnectionStateChange
            )

            finalise()
          }
        } else if (
          event.target.iceConnectionState === 'disconnected' &&
          !this._sendingConnectionEstablished
        ) {
          this._sendingConnection.removeEventListener(
            'iceconnectionstatechange',
            _handleSendingIceConnectionStateChange
          )

          reject(new Error('Sending connection disconnected'))
        }
      }

      this._receivingConnection.addEventListener(
        'iceconnectionstatechange',
        _handleReceivingIceConnectionStateChange
      )
      this._sendingConnection.addEventListener(
        'iceconnectionstatechange',
        _handleSendingIceConnectionStateChange
      )

      // Start negotiating between the sending and receiving connections.
      this._sendingConnection
        .createOffer({ offerToReceiveAudio: 0, offerToReceiveVideo: 0 })
        .then(async (offer) => {
          await this._sendingConnection.setLocalDescription(offer)
          await this._receivingConnection.setRemoteDescription(offer)

          const answer = await this._receivingConnection.createAnswer({
            offerToReceiveAudio: 1,
            offerToReceiveVideo: 0,
          })
          await this._receivingConnection.setLocalDescription(answer)
          await this._sendingConnection.setRemoteDescription(answer)
        })
        .catch(reject)
    }).catch((err) => {
      this._closeConnections()

      this._receivingConnectionEstablished = false
      this._sendingConnectionEstablished = false

      return Promise.reject(err)
    })
  }

  _closeConnections() {
    if (this._sendingConnection) {
      this._sendingConnection.close()
      // NOTE: It has to be set to null for proper garbage collecting
      this._sendingConnection = null
    }
    if (this._receivingConnection) {
      this._receivingConnection.close()
      // NOTE: It has to be set to null for proper garbage collecting
      this._receivingConnection = null
    }
  }

  close() {
    this._closeConnections()

    if (this._audioContext) {
      this._audioContext.close()
      this._audioContext = null
    }
  }

  _createNewMixedTrack() {
    // NOTE: This is very important for Safari, as they limit the browser to have a maximum of 6 AudioContext instances
    if (this._audioContext) {
      this._audioContext.close()
    }
    // Create a new AudioContext
    this._audioContext = new AudioContext()
    // Create a destination stream for the local and remote audio tracks.
    this._streamDestination = this._audioContext.createMediaStreamDestination()
    const t = this._tracks.filter((track) => {
      if (track.track) {
        return track.track.readyState === 'live'
      }
      return track.readyState === 'live'
    })
    t.forEach((track) => {
      if (track) {
        this._connectTrack(track)
      }
    })
    // Get an array of all the audio track
    const tracks = this._streamDestination.stream.getAudioTracks()
    // Replace the track on the sender by a concatenated audio track when there is one available.
    if (tracks.length) return tracks[0]
    return null
  }

  _replaceSendingTrack(track) {
    if (this._sender) {
      this._sender.replaceTrack(track)
    }
  }

  _connectTrack(track) {
    const audioSource = this._audioContext.createMediaStreamSource(
      new MediaStream([track.track ? track.track : track])
    )
    audioSource.connect(this._streamDestination)
  }

  async getOutput() {
    if (!this._track) {
      await this._initConnection()
    }

    return this._track
  }

  addTrack(track) {
    if (Array.isArray(track)) {
      track.filter(Boolean).forEach((t) => this._addTrack(t))
    } else {
      this._addTrack(track)
    }

    if (this._receivingConnectionEstablished) {
      const mixedTrack = this._createNewMixedTrack()
      if (mixedTrack) {
        this._replaceSendingTrack(mixedTrack)
      }
    }
  }

  removeTrack(track) {
    this._removeTrack(track)

    if (this._receivingConnectionEstablished) {
      const mixedTrack = this._createNewMixedTrack()
      if (mixedTrack) {
        this._replaceSendingTrack(mixedTrack)
      }
    }
  }

  replaceTrack(oldTrack, newTrack) {
    this._removeTrack(oldTrack)
    this._addTrack(newTrack)

    if (this._receivingConnectionEstablished) {
      const mixedTrack = this._createNewMixedTrack()
      if (mixedTrack) {
        this._replaceSendingTrack(mixedTrack)
      }
    }
  }

  _addTrack(track) {
    this._tracks.push(track)
  }

  _removeTrack(track) {
    const index = this._tracks.indexOf(track)

    if (index >= 0) {
      this._tracks.splice(index, 1)
    }
  }
}

export default ColigoAudioMixer
