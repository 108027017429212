import type { SVGProps } from 'react'
const SvgEmoji24 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <path
      fill='rgb(var(--color-brand-200))'
      d='M78.36 143.6c-15.96 0-29.03-8.63-34.1-22.53-1.62-4.45-3.94-8.86-7.07-13.48a51.716 51.716 0 0 1-5.33-10.12.566.566 0 0 0-.44-.33l-8.62-1.27c-4.46-.66-8.59-2.61-11.93-5.63-.57-.51-.74-1.34-.41-2.04l16.95-36.41c.29-.62.91-1.01 1.59-1.01.07 0 .14 0 .21.01 0 0 .45.05.6.07l4.35.64a2.501 2.501 0 0 0 2.47-1.11c7.18-11.05 18.05-18.75 30.59-21.69.31-.07.61-.16.88-.26 2.22-.82 4.28-2.02 6.14-3.58 3.67-3.06 7.86-5.3 12.44-6.66.17-.05.33-.07.5-.07.55 0 1.09.26 1.42.73.44.61.44 1.42 0 2.03-1 1.42-1.91 2.91-2.72 4.46 4.7-1.56 9.59-2.35 14.55-2.35 1.44 0 2.9.07 4.34.2a1.757 1.757 0 0 1 .79 3.22 53.102 53.102 0 0 0-6.96 5.37c8.69 3.94 16.27 10.47 21.53 18.59a2.48 2.48 0 0 0 2.1 1.13c.12 0 .25 0 .37-.03l4.12-.61c1.12-.17 2.27-.25 3.42-.25.41 0 .81.01 1.22.03.61.03 1.16.38 1.45.92l17.17 31.73c.29.54.28 1.2-.04 1.73a22.67 22.67 0 0 1-16.21 10.84l-8.43 1.25c-.2.03-.37.16-.44.34-1.3 3.33-2.95 6.53-4.89 9.49-2.9 4.42-5.49 9.44-8.15 15.77-5.41 12.89-18.23 20.89-33.46 20.89z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M87.18 19.87a41.434 41.434 0 0 0-3.65 6.29c-.33.71.22 1.47.92 1.47.12 0 .24-.02.36-.07 5.11-1.91 10.4-2.83 15.63-2.83 1.4 0 2.79.07 4.18.2a55.16 55.16 0 0 0-9.15 7.42c9.54 3.7 17.64 10.39 23.19 18.96a4.24 4.24 0 0 0 3.56 1.93c.21 0 .42-.02.63-.05l4.12-.61c1.06-.16 2.12-.23 3.16-.23.38 0 .75 0 1.13.03l17.17 31.73a20.975 20.975 0 0 1-14.96 10.01l-8.43 1.25c-.82.12-1.51.66-1.81 1.43a49.233 49.233 0 0 1-4.72 9.16c-3.31 5.04-5.96 10.48-8.3 16.06-5.29 12.62-17.56 19.82-31.85 19.82s-27.55-7.93-32.46-21.38c-1.8-4.93-4.33-9.54-7.27-13.86a48.991 48.991 0 0 1-5.14-9.78c-.3-.77-1-1.31-1.81-1.43l-8.62-1.28c-4.26-.63-8.04-2.51-11.01-5.2L29 52.52c.19.02.37.04.56.07l4.35.64c.21.03.42.05.63.05 1.43 0 2.78-.72 3.57-1.94 6.76-10.4 17.26-18.07 29.52-20.94.37-.09.74-.2 1.09-.33h.03c2.41-.9 4.65-2.22 6.62-3.87 3.54-2.95 7.57-5.07 11.82-6.33m0-3.5c-.33 0-.67.05-1 .15-4.81 1.43-9.2 3.79-13.06 6.99-1.71 1.43-3.6 2.53-5.6 3.27l-.06.02c-.19.07-.41.14-.65.19C53.84 30.03 42.59 38 35.16 49.43c-.14.22-.38.34-.64.34h-.11l-4.35-.64-.49-.06-.15-.02c-.14-.02-.28-.03-.43-.03a3.5 3.5 0 0 0-3.17 2.02L8.87 87.46c-.65 1.39-.31 3.04.82 4.07 3.6 3.26 8.04 5.35 12.85 6.06l7.94 1.18c1.4 3.44 3.16 6.74 5.24 9.8 3.05 4.5 5.3 8.78 6.88 13.1 5.33 14.6 19.03 23.68 35.75 23.68s29.39-8.42 35.08-21.96c2.61-6.23 5.16-11.16 8-15.49 1.88-2.87 3.49-5.94 4.8-9.15l7.76-1.15c7.3-1.08 13.66-5.34 17.45-11.68a3.5 3.5 0 0 0 .07-3.46l-17.17-31.73a3.502 3.502 0 0 0-2.9-1.83c-.43-.02-.87-.03-1.31-.03-1.23 0-2.46.09-3.67.27l-4.12.61h-.12c-.26 0-.49-.12-.63-.33-4.95-7.64-11.87-13.91-19.85-18.05 1.52-1.26 3.12-2.42 4.77-3.49a3.492 3.492 0 0 0 1.5-3.77 3.493 3.493 0 0 0-3.07-2.65c-1.49-.14-3.01-.21-4.5-.21-3.65 0-7.27.41-10.8 1.23l.4-.58a3.5 3.5 0 0 0-.02-4.06 3.512 3.512 0 0 0-2.84-1.46z'
    />
    <path
      fill='rgb(var(--color-brand-50))'
      d='M105.27 106.71c0 14.38-11.66 26.04-26.04 26.04s-26.04-11.66-26.04-26.04l.02-6.65c0-3.37-1.33-6.61-3.71-8.99l-3.32-3.32c-6.67-6.67-6.67-17.49 0-24.16 6.67-6.67 17.49-6.67 24.16 0l6.71 6.7a3.108 3.108 0 0 0 4.39 0l6.7-6.7c6.67-6.67 17.49-6.67 24.16 0 6.67 6.67 6.67 17.49 0 24.16l-3.32 3.32a12.673 12.673 0 0 0-3.71 8.99l.02 6.65z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M79.23 134c-15.05 0-27.29-12.24-27.29-27.29l.02-6.65c0-3.02-1.21-5.97-3.35-8.1l-3.32-3.32c-3.46-3.46-5.37-8.07-5.37-12.97s1.91-9.5 5.37-12.97c3.46-3.46 8.07-5.37 12.97-5.37s9.5 1.91 12.97 5.37l6.71 6.71c.72.72 1.9.72 2.62 0l6.71-6.7c3.46-3.46 8.07-5.37 12.97-5.37s9.5 1.91 12.97 5.37 5.37 8.07 5.37 12.97-1.91 9.5-5.37 12.97l-3.32 3.33a11.36 11.36 0 0 0-3.35 8.1l.02 6.65c0 15.05-12.24 27.29-27.29 27.29zM58.25 59.83c-4.23 0-8.21 1.65-11.2 4.64s-4.64 6.97-4.64 11.2 1.65 8.21 4.64 11.2l3.32 3.32c2.6 2.6 4.09 6.2 4.08 9.88l-.02 6.65c0 13.67 11.12 24.79 24.79 24.79s24.79-11.12 24.79-24.79l-.02-6.65c0-3.73 1.44-7.24 4.08-9.88l3.32-3.33c2.99-2.99 4.64-6.97 4.64-11.2s-1.65-8.21-4.64-11.2-6.97-4.64-11.2-4.64-8.21 1.65-11.2 4.64l-6.71 6.7c-1.7 1.7-4.46 1.7-6.15 0l-6.71-6.71a15.742 15.742 0 0 0-11.2-4.64z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M84.16 89.1c-.69.59-1.52 1.52-2.01 2.37-.53.91.14 2.05 1.2 2.03.98-.02 2.19-.29 3.05-.61 1.04-.37 2.32-1.57 2.74-2.75.34-.96-.25-1.96-1.25-2.12-1.23-.2-2.9.34-3.73 1.07zm-9.87 0c.69.59 1.52 1.52 2.01 2.37.53.91-.14 2.05-1.2 2.03-.98-.02-2.19-.29-3.05-.61-1.04-.37-2.32-1.57-2.74-2.75-.34-.96.25-1.96 1.25-2.12 1.23-.2 2.9.34 3.73 1.07z'
    />
    <circle
      cx={101.81}
      cy={79.05}
      r={6.28}
      fill='rgb(var(--color-brand-600))'
    />
    <circle
      cx={101.05}
      cy={77.39}
      r={2.77}
      fill='rgb(var(--color-neutral-50))'
    />
    <circle cx={56.5} cy={79.05} r={6.28} fill='rgb(var(--color-brand-600))' />
    <circle
      cx={55.75}
      cy={77.39}
      r={2.77}
      fill='rgb(var(--color-neutral-50))'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M79.61 107.52a43.646 43.646 0 0 1-15.97-3.14c-1.46-.59-3.08.45-3.11 2.03v.14c-.07 9.11 8.42 16.56 18.97 16.64 10.54.08 19.15-7.24 19.21-16.35v-.14c0-1.57-1.61-2.64-3.08-2.07a43.689 43.689 0 0 1-16.02 2.91z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.66 124.18h-.17c-11.08-.08-20.03-8-19.96-17.64v-.15c.02-1.06.56-2.04 1.45-2.63.9-.6 2.04-.71 3.04-.31 4.97 2 10.22 3.03 15.61 3.07 5.36.02 10.65-.92 15.65-2.84 1.01-.39 2.14-.25 3.03.35.88.6 1.4 1.59 1.41 2.65v.14c-.07 9.6-9.05 17.35-20.05 17.35zm-16.88-18.97c-.25 0-.49.07-.71.22-.34.23-.54.59-.55 1l-1 .12h1c-.06 8.55 8 15.56 17.98 15.64h.16c9.9 0 17.98-6.86 18.05-15.36v-.13c0-.4-.2-.77-.54-1a1.27 1.27 0 0 0-1.18-.14c-5.23 2.01-10.71 3.03-16.38 2.97a44.291 44.291 0 0 1-16.34-3.22c-.16-.06-.32-.09-.48-.09z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M79.49 123.18c4.08.03 7.87-1.05 10.99-2.92-1.95-4.1-6.1-6.94-10.92-6.98-4.82-.04-9.01 2.75-11.02 6.82 3.09 1.91 6.86 3.05 10.95 3.08z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.66 124.18h-.17c-4.13-.03-8.1-1.15-11.46-3.23a.993.993 0 0 1-.37-1.29c2.27-4.58 6.85-7.42 11.93-7.37 5.09.04 9.62 2.93 11.81 7.55.22.47.05 1.02-.39 1.29-3.35 2-7.27 3.06-11.34 3.06zm-.16-2c3.45 0 6.75-.77 9.65-2.31a11.097 11.097 0 0 0-9.59-5.58h-.09c-3.97 0-7.56 2.06-9.59 5.44 2.87 1.58 6.18 2.43 9.61 2.46z'
    />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M27.3 68.93a3.19 3.19 0 0 0-2.68 3.49l1.24 11.87c.09.9-.57 1.73-1.46 1.81-.9.09-1.71-.56-1.81-1.46l-1.24-11.87c-.26-2.44.88-4.7 2.77-6.01l4.25-5.55c1.11-1.45.84-3.54-.62-4.65l-.39-.3c-.15.25-.3.49-.48.72l-8.52 11.14c-.75.98-1.08 2.2-.91 3.43l.44 3.34a1.645 1.645 0 0 1-3.26.44l-.44-3.34a7.89 7.89 0 0 1 1.56-5.86l8.52-11.14c.62-.81.89-1.81.75-2.82s-.65-1.91-1.46-2.53l-.79-.6L11 64.41a8.712 8.712 0 0 0-1.73 6.48l3.78 28.48c.59 4.45 4.69 7.59 9.14 7 7.24-.96 12.35-7.64 11.39-14.88l-2.63-19.8c-.11-.86-.56-1.62-1.25-2.14s-1.55-.74-2.41-.6z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='m29.05 65.73 1.94-2.53c2.22-2.89 1.66-7.05-1.23-9.27l-1.32-1.01-.09.12c.02-.44 0-.89-.05-1.34a7.057 7.057 0 0 0-2.73-4.71l-1.36-1.04c-.54-.42-1.22-.6-1.9-.51s-1.28.44-1.7.99L8.4 62.38a11.99 11.99 0 0 0-2.38 8.92L9.8 99.78c.83 6.25 6.59 10.66 12.84 9.83 9.04-1.2 15.42-9.53 14.22-18.57l-2.63-19.8a6.435 6.435 0 0 0-2.55-4.34c-.79-.59-1.69-.98-2.63-1.16zm4.55 25.73c.96 7.24-4.15 13.92-11.39 14.88-4.45.59-8.55-2.55-9.14-7L9.29 70.86c-.31-2.32.31-4.62 1.73-6.48l11.77-15.37.79.6c.81.62 1.33 1.52 1.46 2.53s-.13 2.01-.75 2.82L15.77 66.1a7.847 7.847 0 0 0-1.56 5.86l.44 3.34a1.645 1.645 0 0 0 3.26-.44l-.44-3.34c-.16-1.23.16-2.45.91-3.43l8.52-11.14c.18-.23.33-.47.48-.72l.39.3c1.45 1.11 1.73 3.2.62 4.65l-4.25 5.55a6.461 6.461 0 0 0-2.77 6.01l1.24 11.87c.09.9.9 1.56 1.81 1.46.9-.08 1.56-.9 1.46-1.81l-1.24-11.87c-.18-1.69 1-3.22 2.68-3.49.85-.14 1.71.08 2.41.6.69.52 1.14 1.28 1.25 2.14l2.63 19.8z'
    />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M132.54 68.93a3.19 3.19 0 0 1 2.68 3.49l-1.24 11.87c-.09.9.57 1.73 1.46 1.81.9.09 1.71-.56 1.81-1.46l1.24-11.87c.26-2.44-.88-4.7-2.77-6.01l-4.25-5.55a3.316 3.316 0 0 1 .62-4.65l.39-.3c.15.25.3.49.48.72l8.52 11.14c.75.98 1.08 2.2.91 3.43l-.44 3.34a1.645 1.645 0 0 0 3.26.44l.44-3.34a7.89 7.89 0 0 0-1.56-5.86l-8.52-11.14c-.62-.81-.89-1.81-.75-2.82s.65-1.91 1.46-2.53l.79-.6 11.77 15.37a8.712 8.712 0 0 1 1.73 6.48l-3.78 28.48c-.59 4.45-4.69 7.59-9.14 7-7.24-.96-12.35-7.64-11.39-14.88l2.63-19.8c.11-.86.56-1.62 1.25-2.14s1.55-.74 2.41-.6z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M128.16 66.9a6.45 6.45 0 0 0-2.55 4.34l-2.63 19.8c-1.2 9.04 5.18 17.37 14.22 18.57 6.25.83 12.01-3.58 12.84-9.83l3.78-28.48c.42-3.19-.42-6.36-2.38-8.92l-12.21-15.95c-.42-.54-1.02-.89-1.7-.99s-1.35.09-1.9.51l-1.36 1.04a7.057 7.057 0 0 0-2.73 4.71c-.06.45-.07.89-.05 1.34l-.09-.12-1.32 1.01c-2.89 2.22-3.45 6.37-1.23 9.27l1.94 2.53c-.94.18-1.84.57-2.63 1.16zm.72 4.77c.11-.86.56-1.62 1.25-2.14s1.55-.74 2.41-.6a3.19 3.19 0 0 1 2.68 3.49l-1.24 11.87c-.09.9.57 1.73 1.46 1.81.9.09 1.71-.56 1.81-1.46l1.24-11.87c.26-2.44-.88-4.7-2.77-6.01l-4.25-5.55a3.316 3.316 0 0 1 .62-4.65l.39-.3c.15.25.3.49.48.72l8.52 11.14c.75.98 1.08 2.2.91 3.43l-.44 3.34a1.645 1.645 0 0 0 3.26.44l.44-3.34a7.89 7.89 0 0 0-1.56-5.86l-8.52-11.14c-.62-.81-.89-1.81-.75-2.82s.65-1.91 1.46-2.53l.79-.6 11.77 15.37a8.712 8.712 0 0 1 1.73 6.48l-3.78 28.48c-.59 4.45-4.69 7.59-9.14 7-7.24-.96-12.35-7.64-11.39-14.88l2.63-19.8z'
    />
  </svg>
)
export default SvgEmoji24
