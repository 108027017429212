import localStore from 'app/services/state/local-store'
import sessionStore from 'app/services/state/session-store'

type Token = {
  token: string
  /**
   * The time in milliseconds until the token expires.
   */
  expires?: number
  expireDate?: number
}

/**
 * Function that returns a Date in milliseconds representing the expire date of the token.
 * @param token
 * @return The current date in milliseconds plus the given expires time from the token.
 */
export function getTokenExpireDate(token: Token): number {
  if (!token) throw new TypeError('"token" is not given')

  // NOTE: Just checking whether the given value is parsable to a number
  // eslint-disable-next-line
  if (+token.expires != token.expires) {
    throw new TypeError(
      `"token.expires" is not given as a number, instead "${typeof token.expires}"`
    )
  }

  return Date.now() + token.expires * 1000
}

/**
 * isTokenExpired checks whether the given token's expiredate is in the past.
 * NOTE: When the given token has no expire date we consider it as valid.
 * @param token
 * @return
 */
export function isTokenExpired(token: Token): boolean {
  // Guard clause: When there is no token given we consider it as not expired.
  if (!token?.expireDate) return false

  return token.expireDate < Date.now()
}

/**
 * Function that detects whether the given token is considered valid.
 * The token is considered valid when it is not null and when it is not expired.
 * @param token
 * @return
 */
export function isTokenValid(token: Token): boolean {
  // Guard clause: When there is no token given we consider it as invalid.
  if (!token?.token || !token?.expireDate) return false

  return !isTokenExpired(token)
}

/**
 * Function that returns the auth token in the session storage.
 * When there is no token defined in the session, we return null.
 * NOTE: As session tokens come from the url, we do not need to check whether the token is valid.
 * @return
 */
export function getSessionToken() {
  return sessionStore.getAuthToken() ?? null
}

/**
 * Function that returns the auth token in the local storage.
 * When there is either no token or no valid token in the local storage, we return null.
 * @return
 */
export function getLocalToken() {
  const localToken = localStore.getAuthToken()

  // When the local token is not valid we return null.
  if (!isTokenValid(localToken)) return null

  return localToken
}

/**
 * Function that returns the prioritised token. The auth token in the session has the highest priority.
 * The auth token in the local storage has the second highest and therefore the lowest priority.
 * @return
 */
export function getToken() {
  const sessionToken = getSessionToken()

  // When there is a token defined in the session we prioritise that over the local stored token.
  if (sessionToken) return sessionToken

  return getLocalToken()
}

/**
 * Function to remove the token. When there is a token defined in the session, we remove it from the session.
 * When there is no token defined in the session, we remove the token from the local storage.
 * @returns
 */
export function removeToken() {
  const sessionToken = getSessionToken()

  if (sessionToken) {
    // When there is a token defined in the session we remove it from the session.
    sessionStore.removeAuthToken()
  } else {
    // When there is no token defined in the session we remove the token from the local storage.
    localStore.removeAuthToken()
  }
}
