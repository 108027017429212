import PropTypes from 'prop-types'
import { Typography } from 'ui/revision'
import cn from 'clsx'
import moment from 'lib/moment'
import { withStyles } from 'app/ui'

const styles = {
  root: {
    margin: 0,
    fontSize: '0.65rem',
    textAlign: 'right',
  },
}

const ChatMessageTimestamp = ({ className, classes, timestamp }) => (
  <Typography
    className={cn(className, classes.root)}
    type='disabled'
    variant='microcopy'
  >
    {moment(timestamp).format('HH:mm')}
  </Typography>
)

ChatMessageTimestamp.propTypes = {
  classes: PropTypes.shape({ root: PropTypes.string }).isRequired,
  timestamp: PropTypes.number.isRequired,

  className: PropTypes.string,
}

ChatMessageTimestamp.defaultProps = {
  className: '',
}

export default withStyles(styles, { name: 'ChatmessageTimestamp' })(
  ChatMessageTimestamp
)
