import {
  FEATURE_PREDICATES,
  LICENSE_PREDICATES,
  BROWSER_PREDICATES,
} from 'constants/feature-predicates'
import { FEATURE_NAMES } from 'constants/features-map'
import env from 'app/config/env'
import localStore from 'app/services/state/local-store'

/**
 * @param predicate
 * @param state
 * @returns
 */
export function checkPredicate(predicate, state) {
  return typeof predicate === 'function' ? predicate(state) : !!predicate
}

/**
 * @param predicates
 * @param name
 * @param state
 * @returns
 */
export function checkPredicates(predicates, name, state) {
  if (Array.isArray(predicates[name])) {
    // True when not a single predicate returned false.
    return !predicates[name].some(
      (predicate) => !checkPredicate(predicate, state)
    )
  }
  return checkPredicate(predicates[name], state)
}

/**
 *
 * @param name
 * @param state
 * @returns {{ enabled: boolean, licensed: boolean, supported: boolean, unread: boolean }}
 */
export function checkFeature(name, state) {
  let enabled = true
  if (name in FEATURE_PREDICATES) {
    enabled = checkPredicates(FEATURE_PREDICATES, name, state)
  }

  let licensed = true
  if (name in LICENSE_PREDICATES) {
    licensed = checkPredicates(LICENSE_PREDICATES, name, state)
  }

  let supported = true
  if (name in BROWSER_PREDICATES) {
    supported = checkPredicates(BROWSER_PREDICATES, name, state)
  }

  const unread = !localStore.getReadFeature(name)

  return { enabled, licensed, supported, unread }
}

/**
 * Filter function that removes a disabled feature.
 */
export function filterDisabledLicensedFeatureFlag(flag) {
  const disableableFeaturesList = {
    [FEATURE_NAMES.DIAL_IN]: env('dialin.enabled', true),
  }

  return Object.keys(disableableFeaturesList).reduce(
    (acc, key) => (flag.name === key ? disableableFeaturesList[key] : acc),
    true
  )
}
