import type { SVGProps } from 'react'
const SvgEmoji12 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle
      cx={78.76}
      cy={96.28}
      r={48.66}
      fill='rgb(var(--color-brand-100))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M78.76 146.94c-27.93 0-50.66-22.72-50.66-50.66s22.72-50.66 50.66-50.66 50.66 22.72 50.66 50.66-22.72 50.66-50.66 50.66zm0-97.31c-25.73 0-46.66 20.93-46.66 46.66s20.93 46.66 46.66 46.66 46.66-20.93 46.66-46.66-20.93-46.66-46.66-46.66z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M91.55 117.87H65.98c-1.08 0-1.96-.88-1.96-1.96s.88-1.96 1.96-1.96h25.57c1.08 0 1.96.88 1.96 1.96s-.88 1.96-1.96 1.96zM54.88 92.2l6.45-6.45c.46-.46.46-1.22 0-1.68l-1.85-1.85c-.46-.46-1.22-.46-1.68 0l-6.45 6.45-6.45-6.45c-.46-.46-1.22-.46-1.68 0l-1.85 1.85c-.46.46-.46 1.22 0 1.68l6.45 6.45-6.45 6.45c-.46.46-.46 1.22 0 1.68l1.85 1.85c.46.46 1.22.46 1.68 0l6.45-6.45 6.45 6.45c.46.46 1.22.46 1.68 0l1.85-1.85c.46-.46.46-1.22 0-1.68l-6.45-6.45zm55.45 0 6.45-6.45c.46-.46.46-1.22 0-1.68l-1.85-1.85c-.46-.46-1.22-.46-1.68 0l-6.45 6.45-6.45-6.45c-.46-.46-1.22-.46-1.68 0l-1.85 1.85c-.46.46-.46 1.22 0 1.68l6.45 6.45-6.45 6.45c-.46.46-.46 1.22 0 1.68l1.85 1.85c.46.46 1.22.46 1.68 0l6.45-6.45 6.45 6.45c.46.46 1.22.46 1.68 0l1.85-1.85c.46-.46.46-1.22 0-1.68l-6.45-6.45z'
    />
  </svg>
)
export default SvgEmoji12
