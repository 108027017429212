import * as reaction from './reaction'
import * as emoji from './emoji'
import * as agile from './agile'
import * as holacracy from './holacracy'
const all = { ...reaction, ...emoji, ...agile, ...holacracy }
export * as reaction from './reaction'
export * as emoji from './emoji'
export * as agile from './agile'
export * as holacracy from './holacracy'
export const categories = ['reaction', 'emoji', 'agile', 'holacracy']
export default all
