import _ from 'lodash'
import { detect } from 'detect-browser'

import ColigoListener from './coligo-listener'
import ColigoTaskQueue from './coligo-task-queue'

const AVAILABLE_DEVICES_POLL_INTERVAL_TIME = 3000

/**
 * @class
 */
class ColigoMediaDevices extends ColigoListener {
  /**
   *
   * @param info
   * @return
   */
  static mediaDeviceInfoToJSON(info) {
    return JSON.stringify({
      kind: info.kind,
      deviceId: info.deviceId,
      groupId: info.groupId,
      label: info.label,
      facing: info.facing,
    })
  }

  /**
   *
   * @param devices
   * @return
   */
  static mediaDeviceListToString(devices) {
    return devices.map(ColigoMediaDevices.mediaDeviceInfoToJSON).sort().join('')
  }

  /**
   * @type {MediaDeviceInfo[]}
   */
  availableDevices = []

  /**
   *
   */
  availableDevicesPollTimer = undefined

  /**
   *
   */
  gumQueue = new ColigoTaskQueue()

  /**
   * @constructs
   */
  constructor() {
    super()

    this.init()
  }

  /**
   *
   * @param newDevices
   * @return
   */
  _compareAvailableMediaDevices(newDevices) {
    if (newDevices.length !== this.availableDevices.length) {
      return true
    }

    return (
      ColigoMediaDevices.mediaDeviceListToString(newDevices) !==
      ColigoMediaDevices.mediaDeviceListToString(this.availableDevices)
    )
  }

  /**
   * @return
   */
  init() {
    const browser = detect()

    /**
     * True if this is a browser and the browser is Firefox.
     *
     */
    const isFirefox = browser && browser.name === 'firefox'

    /**
     * As Firefox has the issue that whenever getUserMedia is being called multiple times at once,
     * it will never resolve them, we replace the getUserMedia with a task queue containing
     * the requested getUserMedia's.
     * If it is not Firefox we simply replace getUserMedia with the default `navigator.mediaDevices.getUserMedia`.
     */
    if (isFirefox) {
      this.getUserMedia = this.getUserMediaQueued
      this.getDisplayMedia = this.getDisplayMediaQueued
    } else {
      this.getUserMedia = this.getNavigatorUserMedia
      this.getDisplayMedia = this.getNavigatorDisplayMedia
    }

    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices.enumerateDevices().then((ds) => {
        this.availableDevices = ds.splice(0)
      })
    }

    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      this.enumerateDevices().then((ds) => {
        this.availableDevices = ds.splice(0)

        if (
          navigator.mediaDevices &&
          'ondevicechange' in navigator.mediaDevices
        ) {
          navigator.mediaDevices.ondevicechange = _.debounce(
            this.enumerateDevices.bind(this),
            1000
          )
        } else {
          // Periodically poll enumerateDevices() method to check if
          // list of media devices has changed.
          this.availableDevicesPollTimer = window.setInterval(
            this.enumerateDevices.bind(this),
            AVAILABLE_DEVICES_POLL_INTERVAL_TIME
          )
        }
      })
    }
  }

  /**
   *
   * @param devices
   * @return
   */
  handleDeviceListChanged(devices) {
    this.availableDevices = devices.slice(0)
    this.emit('devicelistchanged', devices)
  }

  /**
   * @return
   */
  async enumerateDevices() {
    const dev = await navigator.mediaDevices.enumerateDevices()

    if (
      this.availableDevices !== undefined &&
      this._compareAvailableMediaDevices(dev)
    ) {
      this.handleDeviceListChanged(dev)
    }

    return dev
  }

  /**
   * Check whether the `navigator.mediaDevices.getUserMedia` exists,
   * if it exists return the promise of that function.
   * If it doesn't exists return a promise where the old and deprecated
   * `navigator.getUserMedia` is executed and called.
   * @param constraints
   * @return
   */
  async getNavigatorUserMedia(constraints) {
    logger.debug('getNavigatorUserMedia', constraints)
    if (navigator.mediaDevices.getUserMedia) {
      const gum = await navigator.mediaDevices.getUserMedia(constraints)
      this.enumerateDevices()
      return gum
    }

    return new Promise((res, rej) =>
      navigator.getUserMedia(constraints, res, rej)
    )
  }

  async getNavigatorDisplayMedia(constraints) {
    logger.debug('getNavigatorDisplayMedia', constraints)
    if ('getDisplayMedia' in window.navigator.mediaDevices) {
      return navigator.mediaDevices.getDisplayMedia(constraints)
    }

    throw new Error('getDisplayMedia is not support for the current browser')
  }

  /**
   *
   * @param constraints
   * @return
   */
  async getUserMediaQueued(constraints) {
    return new Promise((resolve, reject) => {
      this.gumQueue.enqueue(async () => {
        return this.getNavigatorUserMedia(constraints)
          .then(resolve)
          .catch(reject)
      })
    })
  }

  /**
   *
   * @param constraints
   * @return
   */
  async getDisplayMediaQueued(constraints) {
    return new Promise((resolve, reject) => {
      this.gumQueue.enqueue(async () => {
        return this.getNavigatorDisplayMedia(constraints)
          .then(resolve)
          .catch(reject)
      })
    })
  }
}

export default new ColigoMediaDevices()
