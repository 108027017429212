import { connect } from 'react-redux'
import _ from 'lodash'

import asFeature from 'ui/feature'

import reducer, { STORE_NAME } from './snackbar-queue.reducer'
import middleware from './snackbar-queue.middleware'

import SnackbarQueue from './snackbar-queue.component'

export const mapStateToProps = ({ [STORE_NAME]: snackbarQueue }) => ({
  snackbarIndices: _.get(snackbarQueue, 'snackbarIndices'),
})

export default asFeature(STORE_NAME, {
  middleware,
  reducer,
})(connect(mapStateToProps)(SnackbarQueue))
