import _ from 'lodash'

import ShortcutListener from 'app/services/commands/keyboard-shortcut-listener'
import ROOM_SERVICES from 'app/services/media/room-services'
import roomMediaManager from 'app/services/media/room-media-manager'

import {
  ACTION_TYPES as CHANNEL_ROOM_ACTION_TYPES,
  STORE_NAME as CHANNEL_ROOM_STORE_NAME,
} from 'app/state/api/channels/channel-room.reducer'
import { ACTION_TYPES as CHANNEL_SETTINGS_ACTION_TYPES } from 'app/state/api/channels/channel-settings.reducer'

import localStore from 'app/services/state/local-store'
import { getMe, isHost, isInRoom } from 'app/state/utils'
import { groupDevicesByKind } from 'lib/rtc/devices'

import { ACTION_TYPES as GUEST_SETUP_ACTION_TYPES } from 'app/features/guest-setup/guest-setup.reducer'
import { ACTION_TYPES as GRID_VIEW_ACTION_TYPES } from 'app/features/grid-view/grid-view.reducer'
import {
  ACTION_TYPES as DEVICES_ACTION_TYPES,
  STORE_NAME as DEVICES_STORE_NAME,
  selectors as devicesSelectors,
} from 'app/features/devices/devices.reducer'
import { ACTION_TYPES as LOGIN_ACTION_TYPES } from 'app/features/login/login.reducer'
import {
  STORE_NAME as FLAGS_STORE_NAME,
  selectors as flagsSelectors,
} from 'app/features/flags/flags.reducer'
import { FEATURE_NAMES } from 'constants/features-map'

import { isPermissionGranted } from 'app/features/desktop-notifications/desktop-notification.utils'
import {
  ACTION_TYPES,
  STORE_NAME,
  actions,
  selectors,
} from './settings.reducer'

function updateMemberMetaInLocalStore(meta) {
  // Check if each setting is present in the payload, if so store it in localStore
  if (_.has(meta, 'userName')) {
    localStore.setUserName(meta.userName)
  }
  if (_.has(meta, 'avatarImage')) {
    localStore.setAvatarImage(meta.avatarImage)
  }
}

function handleStoreInit(store) {
  roomMediaManager.trackManager.source$[ROOM_SERVICES.ROOM_VIDEO].subscribe(
    (track) => {
      if (track) {
        const trackSettings = track.getSettings()
        store.dispatch(actions._setCurrentCamDevice(trackSettings.deviceId))

        const { [STORE_NAME]: settings, [DEVICES_STORE_NAME]: devices } =
          store.getState()

        // When the browser starts a camera which has not been manually selected
        // by the user, we will update the selected device with the device which is
        // used by the browser to receive the video feed.
        if (!selectors.getSelectedCamDeviceLabel(settings)) {
          const camDevice = {
            deviceId: _.find(devicesSelectors.getCamDevices(devices), {
              label: track.label,
            })?.deviceId,
            label: track.label,
          }

          store.dispatch(actions.updateSelectedDevices({ camDevice }))
        }
      } else {
        store.dispatch(actions._setCurrentCamDevice(''))
      }
    }
  )

  roomMediaManager.trackManager.source$[ROOM_SERVICES.ROOM_AUDIO].subscribe(
    (track) => {
      if (track) {
        const trackSettings = track.getSettings()
        store.dispatch(actions._setCurrentMicDevice(trackSettings.deviceId))

        const { [STORE_NAME]: settings, [DEVICES_STORE_NAME]: devices } =
          store.getState()

        // When the browser starts a microphone which has not been manually selected
        // by the user, we will update the selected device with the device which is
        // used by the browser to receive the audio feed.
        if (!selectors.getSelectedMicDeviceLabel(settings)) {
          const micDevice = {
            deviceId: _.find(devicesSelectors.getMicDevices(devices), {
              label: track.label,
            })?.deviceId,
            label: track.label,
          }

          store.dispatch(actions.updateSelectedDevices({ micDevice }))
        }
      } else {
        store.dispatch(actions._setCurrentMicDevice(''))
      }
    }
  )

  roomMediaManager.trackManager.source$[ROOM_SERVICES.ROOM_SCREEN].subscribe(
    (track) => {
      if (track) {
        const settings = track.getSettings()
        store.dispatch(actions._setCurrentScreenDevice(settings.deviceId))
      } else {
        store.dispatch(actions._setCurrentScreenDevice(''))
      }
    }
  )

  const { [FLAGS_STORE_NAME]: flags } = store.getState()

  // Turn off desktop notifications if notifications permission hasn't been granted yet
  if (
    !flagsSelectors.getFeaturesFailingKey(
      FEATURE_NAMES.DESKTOP_NOTIFICATIONS,
      flags
    ).length &&
    !isPermissionGranted()
  ) {
    store.dispatch(actions.setDesktopNotifications(false))
  }
}

function handleChooseDevices(store, action) {
  store.dispatch(
    actions.updateSelectedDevices({
      camDevice: action.payload.camDevice,
      micDevice: action.payload.micDevice,
      outDevice: action.payload.outDevice,
    })
  )
}

function getMissingSelectedDeviceIds(settingsState, newDevices) {
  const { camDevices, micDevices, outDevices } = groupDevicesByKind(newDevices)
  const payload = {}

  const selectedCamDeviceLabel =
    selectors.getSelectedCamDeviceLabel(settingsState)
  const selectedCamDeviceId = selectors.getSelectedCamDeviceId(settingsState)
  if (selectedCamDeviceLabel && !selectedCamDeviceId) {
    const deviceId = _.chain(camDevices)
      .find({ label: selectedCamDeviceLabel })
      .get('deviceId')
      .value()

    if (deviceId) {
      payload.camDevice = { deviceId }
    }
  }

  const selectedMicDeviceLabel =
    selectors.getSelectedMicDeviceLabel(settingsState)
  const selectedMicDeviceId = selectors.getSelectedMicDeviceId(settingsState)
  if (selectedMicDeviceLabel && !selectedMicDeviceId) {
    const deviceId = _.chain(micDevices)
      .find({ label: selectedMicDeviceLabel })
      .get('deviceId')
      .value()

    if (deviceId) {
      payload.micDevice = { deviceId }
    }
  }

  const selectedOutDeviceLabel =
    selectors.getSelectedOutDeviceLabel(settingsState)
  const selectedOutDeviceId = selectors.getSelectedOutDeviceId(settingsState)
  if (selectedOutDeviceLabel && !selectedOutDeviceId) {
    const deviceId = _.chain(outDevices)
      .find({ label: selectedOutDeviceLabel })
      .get('deviceId')
      .value()

    if (deviceId) {
      payload.outDevice = { deviceId }
    }
  }

  return payload
}

function handleUpdateDeviceListPreUpdate(store, action) {
  const { [STORE_NAME]: settings } = store.getState()

  if (!selectors.getDeviceConstraintsInitialised(settings)) {
    store.dispatch(actions.initialiseDeviceConstraints(action.payload))
  } else {
    const payload = getMissingSelectedDeviceIds(settings, action.payload)

    if (!_.isEmpty(payload)) {
      store.dispatch(actions.updateSelectedDevices(payload))
    }
  }
}

function handleSetDebugMode(store, action) {
  localStore.setDebugMode(action.payload)
}

function handleToggleAndSetFacingMode(store) {
  const { [STORE_NAME]: settings } = store.getState()
  const facingMode = selectors.getFacingMode(settings)

  if (facingMode) {
    localStore.setFacingMode(facingMode)
  }
}

function handleUpdateSelectedDevices(store, action) {
  if (action.payload?.camDevice?.label !== undefined) {
    localStore.setCamDeviceLabel(action.payload.camDevice.label)
  }

  if (action.payload?.micDevice?.label !== undefined) {
    localStore.setMicDeviceLabel(action.payload.micDevice.label)
  }

  if (action.payload?.outDevice?.label !== undefined) {
    localStore.setOutDeviceLabel(action.payload.outDevice.label)
  }
}

function handleCamDeviceMissing(store) {
  store.dispatch(
    actions.updateSelectedDevices({ camDevice: { deviceId: '', label: '' } })
  )
}

function handleMicDeviceMissing(store) {
  store.dispatch(
    actions.updateSelectedDevices({ micDevice: { deviceId: '', label: '' } })
  )
}

function handleSettingsChannelSettingsUpdate(store, action) {
  if (action.payload.email) {
    localStore.setEmail(action.payload.email)
  }
}

function handleSetEmail(store, action) {
  localStore.setEmail(action.payload)
}

function handleJoiningTheRoomChannel(store) {
  const { [CHANNEL_ROOM_STORE_NAME]: channelRoom } = store.getState()

  if (!isHost(getMe(channelRoom))) {
    const presentationMode = _.get(
      channelRoom,
      'meeting_room.meta.presentationMode'
    )
    if (presentationMode) {
      store.dispatch(actions.setPlayNotifications(false))
    }
  }
}

function handleUpdateRoomMeta(store, action) {
  if (_.has(action.payload, 'presentationMode')) {
    store.dispatch(
      actions.setPlayNotifications(!action.payload.presentationMode)
    )
  }
}

function handleUpdateMemberMeta(store, action) {
  const { [CHANNEL_ROOM_STORE_NAME]: channelRoom } = store.getState()

  if (action.memberId !== channelRoom.member_id) return

  const me = getMe(channelRoom)

  if (!isInRoom(me)) return

  updateMemberMetaInLocalStore(action.meta)
}

function handleUpdateGuestSetupMeta(store, action) {
  updateMemberMetaInLocalStore(action.payload)
}

function handleSetGridSize(store, action) {
  localStore.setGridSize(action.payload)
}

function handleTogglingHotkeys(store) {
  const { [STORE_NAME]: settings } = store.getState()

  const hotkeysEnabled = selectors.getHotkeysEnabled(settings)

  localStore.setHotkeysEnabled(hotkeysEnabled)

  if (hotkeysEnabled) {
    ShortcutListener.enable()
  } else {
    ShortcutListener.disable()
  }
}

export default (store) => (next) => (action) => {
  switch (action.type) {
    case 'sm-web/INIT':
      handleStoreInit(store)
      break

    case DEVICES_ACTION_TYPES.CHOOSE_DEVICES:
      handleChooseDevices(store, action)
      break

    case DEVICES_ACTION_TYPES.UPDATE_DEVICE_LIST:
      handleUpdateDeviceListPreUpdate(store, action)
      break

    default:
  }

  const result = next(action)

  switch (action.type) {
    case ACTION_TYPES.SET_DEBUG_MODE:
      handleSetDebugMode(store, action)
      break

    case ACTION_TYPES.TOGGLE_FACING_MODE:
    case ACTION_TYPES.SET_FACING_MODE:
      handleToggleAndSetFacingMode(store)
      break

    case ACTION_TYPES.UPDATE_SELECTED_DEVICES:
      handleUpdateSelectedDevices(store, action)
      break

    case DEVICES_ACTION_TYPES.CAM_DEVICE_MISSING:
      handleCamDeviceMissing(store)
      break

    case DEVICES_ACTION_TYPES.MIC_DEVICE_MISSING:
      handleMicDeviceMissing(store)
      break

    case CHANNEL_SETTINGS_ACTION_TYPES.SETTINGS_SET:
      handleSettingsChannelSettingsUpdate(store, action)
      break

    case LOGIN_ACTION_TYPES.SET_EMAIL:
      handleSetEmail(store, action)
      break

    case CHANNEL_ROOM_ACTION_TYPES.JOINED:
      handleJoiningTheRoomChannel(store)
      break

    case CHANNEL_ROOM_ACTION_TYPES.UPDATED_ROOM_META:
      handleUpdateRoomMeta(store, action)
      break

    case GUEST_SETUP_ACTION_TYPES.UPDATE_META:
      handleUpdateGuestSetupMeta(store, action)
      break

    case CHANNEL_ROOM_ACTION_TYPES.UPDATED_MEMBER_META:
      handleUpdateMemberMeta(store, action)
      break

    case GRID_VIEW_ACTION_TYPES.SET_GRID_SIZE:
      handleSetGridSize(store, action)
      break

    case ACTION_TYPES.TOGGLE_HOTKEYS_ENABLED:
      handleTogglingHotkeys(store)
      break

    default:
  }

  return result
}
