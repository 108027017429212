import { createContext, Component, useContext, type ReactNode } from 'react'
import { Provider } from 'react-redux'
import type { Reducer, AnyAction, Middleware } from 'redux'
import type { History } from 'history'

import type {
  AppThunk,
  RootFeatureKeys,
  RootStore,
  RootReducers,
  AppThunkDispatch,
} from 'app/services/state/redux-store'
import commandsRegistry from 'app/services/commands/commands-registry'

import { commands as settingsCommands } from 'app/features/settings/settings.reducer'
import {
  createStore,
  createMiddlewareRegistry,
  createReducerRegistry,
} from 'app/services/state/redux-store'

export type StoreProviderCommand<R = void> = (
  payload: any
) => AnyAction | AppThunk<R>

export type StoreProviderCommands = Record<string, StoreProviderCommand>

export interface IStoreContext {
  loadMiddleware: (...middlewares: Middleware[]) => void
  loadReducer: (
    name: RootFeatureKeys,
    reducer: Reducer,
    initPayload?: {}
  ) => void
  loadCommands: (commands: StoreProviderCommands) => void

  unloadCommands: (commands: StoreProviderCommands) => void
  unloadReducer: (name: RootFeatureKeys) => void
  unloadMiddleware: (...middlewares: Middleware[]) => void
}

/**
 * private store context
 */
const StoreContext = createContext<IStoreContext>({
  loadMiddleware: (...middlewares) => {},
  loadReducer: (name, reducer, initPayload) => {},
  loadCommands: (commands) => {},

  unloadMiddleware: (...middlewares) => {},
  unloadReducer: (name) => {},
  unloadCommands: (commands) => {},
})

/**
 * getter for storecontext
 */
export const useStoreContext = () => useContext(StoreContext)

export interface StoreProviderProps {
  history: History
  children: ReactNode
  store: RootStore
}

export interface StoreProviderState {
  store: RootStore
}

class StoreProvider extends Component<
  Omit<StoreProviderProps, 'store'>,
  StoreProviderState
> {
  mountedReducers: RootFeatureKeys[] = []

  reducerRegistry

  middlewareRegistry

  constructor(props: StoreProviderProps) {
    super(props)

    this.reducerRegistry = createReducerRegistry(props.history)

    this.middlewareRegistry = createMiddlewareRegistry(props.history)

    let { store } = props

    if (store) {
      store.replaceReducer(this.reducerRegistry.combineReducers())
    } else {
      store = createStore(this.reducerRegistry, this.middlewareRegistry)
    }

    store.dispatch({ type: 'sm-web/INIT' })

    this.loadInitialCommands()

    window.store = store

    this.state = { store }
  }

  handleLoadReducer = (
    name: RootFeatureKeys,
    reducer: RootReducers,
    initPayload: object = {}
  ) => {
    logger.debug(`StoreProvider.handleLoadReducer '${name}'`, reducer)
    this.reducerRegistry.register(name, reducer)

    if (!this.mountedReducers.includes(name)) {
      this.state.store.replaceReducer(this.reducerRegistry.combineReducers())

      this.state.store.dispatch({
        type: `sm-web/${name}/INIT`,
        payload: initPayload,
      })

      this.mountedReducers.push(name)
    }
  }

  handleLoadMiddleware = (...middlewares: Middleware[]) => {
    logger.debug('StoreProvider.handleLoadMiddleware', middlewares)
    this.middlewareRegistry.register(...middlewares)
  }

  handleLoadCommands = (commands: StoreProviderCommands) => {
    Object.keys(commands).forEach((command) => {
      commandsRegistry.add(command, (payload) => {
        const dispatch = this.state.store.dispatch as AppThunkDispatch

        // @ts-expect-error - dispatch accepts both functions and thunks but when its one or the other it throws.
        dispatch(commands[command](payload))
      })
    })
  }

  handleUnloadReducer = (name: RootFeatureKeys) => {
    const reducerIndex = this.mountedReducers.indexOf(name)

    if (reducerIndex === -1) return

    logger.debug('StoreProvider.handleUnloadReducer', name)

    this.reducerRegistry.unregister(name)

    this.state.store.replaceReducer(this.reducerRegistry.combineReducers())

    this.mountedReducers.splice(reducerIndex, 1)
  }

  handleUnloadMiddleware = (...middlewares: Middleware[]) => {
    logger.debug('StoreProvider.handleUnloadMiddleware', middlewares)

    this.middlewareRegistry.unregister(...middlewares)
  }

  handleUnloadCommands = (commands: StoreProviderCommands) => {
    Object.keys(commands).forEach((actionType) => {
      commandsRegistry.remove(actionType)
    })
  }

  loadInitialCommands() {
    this.handleLoadCommands(settingsCommands)
  }

  render() {
    return (
      <Provider store={this.state.store}>
        <StoreContext.Provider
          value={{
            loadMiddleware: this.handleLoadMiddleware,
            loadReducer: this.handleLoadReducer,
            loadCommands: this.handleLoadCommands,

            unloadMiddleware: this.handleUnloadMiddleware,
            unloadReducer: this.handleUnloadReducer,
            unloadCommands: this.handleUnloadCommands,
          }}
        >
          {this.props.children}
        </StoreContext.Provider>
      </Provider>
    )
  }
}

export default StoreProvider
