import { createRoot } from 'react-dom/client'

import './app/config/logger'
import './app/config/sentry'
import './app/config/ccl'
import './lib/polyfills'
import './styles.scss'

import Root from 'app/features/root'
import analytics from 'app/services/analytics'
import { initialize as initializeBroadcaster } from 'app/services/events'
import urlUtils from 'lib/url-utils'

import reportWebVitals from './reportWebVitals'

/**
 * Render the app
 */
const container = document.getElementById('root')
const root = createRoot(container!)
root.render(<Root />)

/**
 * Initialize analytics
 */
analytics.page().catch(void 0)

/**
 * Initialize broadcaster
 */
initializeBroadcaster(urlUtils.getUrlParams().i)

/**
 * Initialize web vitals
 */
// eslint-disable-next-line no-console
if (import.meta.env.MODE === 'development') reportWebVitals(logger.log)
