import type { SVGProps } from 'react'
const SvgReaction8ThumbUp = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle cx={82.17} cy={78.48} r={62} fill='rgb(var(--color-brand-50))' />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M70.35 124.13h37.4c3.37 0 6.1-2.73 6.1-6.1v-6.3h1.39c2.98 0 5.39-2.41 5.39-5.39 0-3.5-2.84-6.34-6.34-6.34h1.41c3.5 0 6.34-2.84 6.34-6.34s-2.84-6.34-6.34-6.34h.53c3.4 0 6.16-2.76 6.16-6.16 0-3.4-2.76-6.16-6.16-6.16H91.22l1.43-7.54c1-5.28-.06-10.75-2.96-15.27l-2.66-4.14a5.141 5.141 0 0 0-5.94-2.1l-2.6.86c-.8.26-1.3 1.05-1.21 1.88l1 8.92c.39 3.47-.27 6.97-1.89 10.07l-7.08 13.19h-15.2v43.43l16.23-.16z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='m44.39 115.18-.12-5.54-.53-23.96-.08-3.59c-.03-1.21-1-2.18-2.2-2.21L22.6 79.4v47.28l8.7-.52 6.33-.55 4.82-.3a2.261 2.261 0 0 0 2.12-2.31l-.17-7.82z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M107.75 126.13h-37.4c-1.1 0-2-.9-2-2s.9-2 2-2h37.4c2.26 0 4.1-1.84 4.1-4.1v-6.3c0-1.1.9-2 2-2h1.39c1.87 0 3.39-1.52 3.39-3.39 0-2.39-1.95-4.34-4.34-4.34h-2.58c-1.1 0-2-.9-2-2s.9-2 2-2h3.99c2.39 0 4.34-1.95 4.34-4.34s-1.95-4.34-4.34-4.34h-6.29c-1.1 0-2-.9-2-2s.9-2 2-2h6.81c2.3 0 4.16-1.87 4.16-4.16S118.51 77 116.22 77H91.21c-.6 0-1.16-.27-1.54-.72-.38-.46-.54-1.06-.42-1.65l1.43-7.54c.91-4.8-.04-9.7-2.68-13.81l-2.66-4.14a3.147 3.147 0 0 0-3.63-1.29l-2.42.8.98 8.74c.44 3.88-.29 7.76-2.1 11.22l-7.09 13.21a2 2 0 0 1-1.76 1.05H63.9c-1.1 0-2-.9-2-2s.9-2 2-2h4.22l6.52-12.14c1.43-2.73 2.01-5.81 1.66-8.9l-1-8.92c-.2-1.77.88-3.45 2.57-4l2.6-.86c3.09-1.02 6.49.18 8.25 2.92l2.66 4.14c3.2 4.97 4.35 10.91 3.25 16.72L93.65 73h22.59c4.5 0 8.16 3.66 8.16 8.16 0 2.57-1.19 4.87-3.05 6.36a8.32 8.32 0 0 1 2.7 6.14c0 2.89-1.47 5.44-3.71 6.93 1.43 1.5 2.3 3.52 2.3 5.75 0 3.87-2.99 7.06-6.78 7.37v4.32c0 4.47-3.63 8.1-8.1 8.1z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M82.13 34.94c-1.1 0-2-.9-2-2v-8.27c0-1.1.9-2 2-2s2 .9 2 2v8.27c0 1.1-.9 2-2 2zm12.61 5.53a2.004 2.004 0 0 1-1.41-3.42l5.19-5.19c.78-.78 2.05-.78 2.83 0s.78 2.05 0 2.83l-5.19 5.19c-.39.39-.9.59-1.41.59zm-24.84 0c-.51 0-1.02-.2-1.41-.59l-5.19-5.19c-.78-.78-.78-2.05 0-2.83s2.05-.78 2.83 0l5.19 5.19a2.004 2.004 0 0 1-1.41 3.42z'
    />
    <path
      fill='rgb(var(--color-brand-100))'
      d='M35.77 95.1c-.54 0-.99-.43-1-.98a.997.997 0 0 1 .98-1.02l3-.07c.57-.03 1.01.42 1.02.98a.997.997 0 0 1-.98 1.02l-3 .07h-.02zm-7.6.19c-.54 0-.99-.43-1-.98a.997.997 0 0 1 .98-1.02l3-.07h.02c.54 0 .99.43 1 .98a.997.997 0 0 1-.98 1.02l-3 .07h-.02z'
    />
    <path
      fill='rgb(var(--color-brand-500))'
      d='m44.26 109.28-.13-3.51H22.59v20.78l12.41-.77 8.98-1.2c.38-.42.6-.97.59-1.57l-.3-13.73z'
    />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M60.58 124.6a3.07 3.07 0 0 0 3.22-3.13l-.45-20.26c-.1-4.38-3.73-7.86-8.11-7.76l-10.74.24-.42 30.88'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M60.73 126.1h-11.7c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h11.55c.49.03.93-.13 1.24-.44.31-.31.48-.72.47-1.16l-.45-20.26a6.38 6.38 0 0 0-1.99-4.51 6.38 6.38 0 0 0-4.45-1.79h-.15l-9.14-.1c-.83 0-1.49-.69-1.48-1.52 0-.82.68-1.48 1.5-1.48h.02l9.09.1c2.47-.07 4.86.87 6.68 2.62a9.352 9.352 0 0 1 2.91 6.61l.45 20.26c.03 1.26-.48 2.49-1.38 3.37-.85.83-2 1.29-3.18 1.29z'
    />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='m60.53 79.37 2.03.65.29 6.46-.85 3.06-5.11 3.64-13.1.09-.65-13.9h17.39z'
    />
    <circle
      cx={54.19}
      cy={115.22}
      r={3.32}
      fill='rgb(var(--color-brand-200))'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M45.27 94.97a1.501 1.501 0 0 1-.05-3l9.95-.32a6.39 6.39 0 0 0 4.53-1.99 6.366 6.366 0 0 0 1.78-4.59l-.08-3.72c0-.44-.2-.84-.52-1.13s-.75-.42-1.19-.39H48.03c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h11.46c1.23-.09 2.46.32 3.38 1.14.94.84 1.5 2.04 1.53 3.31l.08 3.72c.06 2.52-.87 4.91-2.62 6.73a9.352 9.352 0 0 1-6.61 2.91l-9.94.32h-.05z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M24.69 128.56c-1.04 0-2.05-.4-2.82-1.12a4.115 4.115 0 0 1-1.29-2.99V81.57c0-1.12.44-2.16 1.24-2.94.8-.78 1.83-1.19 2.97-1.16l16.7.43c2.28.06 4.1 1.89 4.15 4.17l.73 33.08.17 7.82c.05 2.29-1.7 4.21-4 4.35l-4.82.3-6.28.55-6.52.39h-.24zm0-47.1-.11.11v42.88l.11.11 6.47-.39 6.27-.55 4.87-.3c.14 0 .25-.13.25-.27l-.91-40.91c0-.14-.12-.25-.25-.26l-16.7-.43z'
    />
  </svg>
)
export default SvgReaction8ThumbUp
