import * as Sentry from '@sentry/browser'

import Task from 'lib/tasks/coligo-task'

import * as authUtils from 'lib/auth-utils'
import { getMe, isHost as isHostFn } from 'app/state/utils'

import {
  STORE_NAME as SOCKET_STORE_NAME,
  actions as socketActions,
  selectors as socketSelectors,
} from 'app/state/api/socket/socket.reducer'
import {
  STORE_NAME as CHANNEL_ROOM_STORE_NAME,
  selectors as channelRoomSelectors,
} from 'app/state/api/channels/channel-room.reducer'
import { actions as authActions } from 'app/features/auth/auth.reducer'

import EndMeetingTask from './end-meeting-task'

class LogoutTask extends Task {
  name = 'LogoutTask'

  store

  constructor(store) {
    super()

    this.store = store
  }

  async run() {
    authUtils.removeToken()

    const currentState = this.store.getState()
    if (channelRoomSelectors.isJoined(currentState[CHANNEL_ROOM_STORE_NAME])) {
      const me = getMe(currentState[CHANNEL_ROOM_STORE_NAME])
      if (me && isHostFn(me)) {
        try {
          await new EndMeetingTask(this.store).run()
        } catch (err) {
          Sentry.captureException(err)
        }
      }
    }

    if (socketSelectors.isConnected(currentState[SOCKET_STORE_NAME])) {
      // Disconnect the socket if it wasnt done so already.
      await this.store.dispatch(socketActions.disconnect())
    }

    Sentry.setUser(null)
    this.store.dispatch(authActions.logout())
    this.store.dispatch({ type: 'sm-web/RESET' })
  }
}

export default LogoutTask
