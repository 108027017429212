/* eslint-disable max-classes-per-file */
import { BehaviorSubject } from 'rxjs'

import Listener from 'lib/rtc/coligo-listener'
import ExternalColigoTrack from 'lib/rtc/external-coligo-track'

export const ConnectionEvents = {
  CONNECTION_CLOSED: 'connectionClosed',
  CONNECTION_ESTABLISHED: 'connectionEstablished',
  REMOTE_TRACK_ADDED: 'remoteTrackAdded',
  REMOTE_TRACK_REMOVED: 'remoteTrackRemoved',
}

export const ConnectionErrors = {
  GENERAL: 'connection.generalError',
  CONNECTION_CLOSED: 'connection.connectionClosedError',
  CONNECTION_DROPPED: 'connection.connectionDroppedError',
  NO_SENDER: 'connection.noSenderError',
  NO_SENDER_FOUND: 'connection.noSenderFoundError',
}

const CONNECTION_ERROR_MESSAGES = {
  [ConnectionErrors.GENERAL]: 'Generic getUserMedia error',
  [ConnectionErrors.CONNECTION_CLOSED]: 'Connection is closed',
  [ConnectionErrors.CONNECTION_DROPPED]: 'User lost connection',
  [ConnectionErrors.NO_SENDER]: 'There is no RTCRtpSender for: ',
  [ConnectionErrors.NO_SENDER_FOUND]: 'No RTCRtpSender found on the connection',
}

export class ConnectionError extends Error {
  /**
   *
   * @param error
   * @param [trackOrKind=undefined]
   * @returns
   */
  static getMessage(error, trackOrKind = undefined) {
    if (typeof error === 'object' && typeof error.name !== 'undefined') {
      return (
        error.message || CONNECTION_ERROR_MESSAGES[ConnectionErrors.GENERAL]
      )
    }

    if (typeof error === 'string') {
      if (CONNECTION_ERROR_MESSAGES[error]) {
        let message = CONNECTION_ERROR_MESSAGES[error]

        if (error === ConnectionErrors.NO_SENDER) {
          message += trackOrKind || ''
        }

        return message
      }

      // this is some generic error that do not fit any of our
      // pre-defined errors, so don't give it any specific name, just
      // store message
      return error
    }

    throw new Error('Invalid arguments')
  }

  /**
   *
   */
  errorName

  /**
   *
   */
  options

  /**
   *
   * @param errorOrErrorName
   * @param options
   * @param [trackOrKind=undefined]
   */
  constructor(errorOrErrorName, options, trackOrKind = undefined) {
    super(ConnectionError.getMessage(errorOrErrorName, trackOrKind))

    this.options = options
    this.errorName =
      typeof errorOrErrorName === 'string'
        ? errorOrErrorName
        : ConnectionErrors.GENERAL
  }
}

class Connection extends Listener {
  static isMDNSCandidate(iceCandidate) {
    if (!iceCandidate) return false
    if (!iceCandidate.candidate) return false
    return iceCandidate.candidate.indexOf('.local') >= 0
  }

  /**
   *
   */
  _signaling

  /**
   *
   */
  _options

  /**
   *
   */
  id

  remoteTracks$ = new BehaviorSubject<ExternalColigoTrack[]>([])

  /**
   * @type {LocalColigoTrack[]}
   */
  localTracks = []

  /**
   *
   */
  remoteOffer = null

  constructor(_signaling, _options) {
    super()

    this._signaling = _signaling
    this._options = _options
    this.id = this._options.id

    this._rtcPeerConnection = new RTCPeerConnection(
      this._options.pcConfiguration
    )
  }

  async init() {
    this._rtcPeerConnection.oniceconnectionstatechange = (event) => {
      if (!event) return
      if (event.target.iceConnectionState === 'closed') {
        this.emit(ConnectionEvents.CONNECTION_CLOSED)
      } else if (event.target.iceConnectionState === 'connected') {
        this.emit(ConnectionEvents.CONNECTION_ESTABLISHED)
      }
    }

    this._rtcPeerConnection.ontrack = (event) => {
      const stream = event.streams[0]

      this._remoteTrackAdded(stream, event.track)
      stream.onremovetrack = (evt) => {
        this._remoteTrackRemoved(stream, evt.track)
      }
    }

    if (this._options.transceivers && this._options.transceivers.length) {
      this._options.transceivers.forEach((transceiver) => {
        const init = { direction: transceiver.direction }
        if (transceiver.sendEncodings && transceiver.sendEncodings.length) {
          init.sendEncodings = transceiver.sendEncodings
        }
        this._rtcPeerConnection.addTransceiver(transceiver.trackOrKind, init)
      })
    }

    if (this.remoteOffer) {
      await this._respondRenegotiation(this.remoteOffer)
    } else {
      await this._initiateRenegotiation()
    }

    if (this._rtcPeerConnection) {
      this._rtcPeerConnection.onicecandidate = (e) => {
        if (!e) return
        if (e.candidate) {
          if (
            this._options.disableMDNSCandidates &&
            Connection.isMDNSCandidate(e.candidate)
          ) {
            return
          }
          this._signaling.sendCandidate(e.candidate)
        } else {
          // NOTE: No candidate means end-of-candidates.
          this._signaling.sendCandidate({ completed: true })
        }
      }
    }
  }

  /**
   * Set limit the maximum bitrate of the sender. If there is not sender throw an error.
   * @param maxBitrate
   * @return
   */
  setMaxBitrate(maxBitrate) {
    const senders = this._rtcPeerConnection.getSenders()
    // Check if there are any senders on which the encodings can be set.
    if (senders.length) {
      // Update the first encoding on the sender with the given maxbitrate.
      const param = senders[0].getParameters()
      param.encodings[0].maxBitrate = maxBitrate
      senders[0].setParameters(param)
    } else {
      throw new ConnectionError(ConnectionErrors.NO_SENDER_FOUND, this._options)
    }
  }

  setRemoteOffer(description) {
    if (!this._rtcPeerConnection) {
      throw new ConnectionError(
        ConnectionErrors.CONNECTION_CLOSED,
        this._options
      )
    }
    this.remoteOffer = description
  }

  /**
   * Publishes the given track over the current connection over a new sendonly transceiver.
   * @param track The track to be published
   * @param sendEncodings
   * @return
   */
  async publishTrack(track, sendEncodings) {
    if (!this._rtcPeerConnection) {
      throw new ConnectionError(
        ConnectionErrors.CONNECTION_CLOSED,
        this._options
      )
    }
    const init = { direction: 'sendonly' }
    if (sendEncodings && sendEncodings.length) {
      init.sendEncodings = sendEncodings
    }
    this._rtcPeerConnection.addTransceiver(track.track, init)
  }

  /**
   * When both the oldTrack and track arguments are given, look for the sender of the old track
   * and replace the oldTrack with the track.
   * When only the oldTrack is given, it will be removed from the sender publishing the oldTrack.
   * When only the new track is given, it will look for an available transceiver with a receiver
   * receiving the same kind of track and add the track to the sender of the found transceiver.
   * @param oldTrack Either the track to be removed or null if nothing should be replaced.
   * @param track Either the track to be added or null if the old track should be removed.
   * @return
   */
  async replaceTrack(oldTrack, track) {
    if (!this._rtcPeerConnection) {
      throw new ConnectionError(
        ConnectionErrors.CONNECTION_CLOSED,
        this._options
      )
    }
    if (oldTrack && track) {
      const transceivers = this._rtcPeerConnection.getTransceivers()
      const transceiver = transceivers.find(
        (t) => t.sender && t.sender.track === oldTrack.getTrack()
      )
      if (!transceiver) {
        throw new ConnectionError(
          ConnectionErrors.NO_SENDER,
          this._options,
          oldTrack.kind
        )
      }
      await transceiver.sender.replaceTrack(track.getTrack())
      const indexOfLocalTrack = this.localTracks.indexOf(oldTrack)
      if (indexOfLocalTrack >= 0) {
        this.localTracks[indexOfLocalTrack] = track
      }
    } else if (oldTrack) {
      this.removeTrack(oldTrack)
    } else if (track) {
      const mediaType = track.kind

      const transceiver = this._rtcPeerConnection
        .getTransceivers()
        .find((t) => t.receiver?.track?.kind === mediaType)

      if (!transceiver) {
        this.publishTrack(track)
      } else {
        // If the client starts with audio/video muted setting, the transceiver direction
        // will be set to 'recvonly'. Use addStream here so that a MSID is generated for the stream.
        await transceiver.sender.replaceTrack(track.getTrack())
        this.localTracks.push(track)
      }
    } else {
      throw new ConnectionError(
        ConnectionErrors.CONNECTION_CLOSED,
        this._options
      )
    }
  }

  /**
   * Looks for the sender publishing the given track and remove the track from it.
   * If no sender is found, an exception will be thrown.
   * @param track Old track which should be removed.
   * @return
   */
  async removeTrack(track) {
    if (!this._rtcPeerConnection) {
      throw new ConnectionError(
        ConnectionErrors.CONNECTION_CLOSED,
        this._options
      )
    }
    const transceivers = this._rtcPeerConnection.getTransceivers()
    const transceiver = transceivers.find((t) => {
      return t.sender && t.sender.track === track.getTrack()
    })
    if (!transceiver) {
      throw new Error(`Could not find RTCRtpSender for ${track.kind}`)
    }
    await transceiver.sender.replaceTrack(null)
    const indexOfLocalTrack = this.localTracks.indexOf(track)
    this.localTracks.splice(indexOfLocalTrack)
  }

  /**
   * @private
   * @param offerOptions
   * @return
   */
  async _createOffer(offerOptions) {
    if (!this._rtcPeerConnection) {
      throw new ConnectionError(
        ConnectionErrors.CONNECTION_CLOSED,
        this._options
      )
    }
    const offer = await this._rtcPeerConnection.createOffer(offerOptions)
    await this._rtcPeerConnection.setLocalDescription(offer)
    return offer
  }

  /**
   * @private
   * @param offerOptions
   * @return
   */
  async _createAnswer(answerOptions) {
    if (!this._rtcPeerConnection) {
      throw new ConnectionError(
        ConnectionErrors.CONNECTION_CLOSED,
        this._options
      )
    }
    const answer = await this._rtcPeerConnection.createAnswer(answerOptions)
    await this._rtcPeerConnection.setLocalDescription(answer)
    return answer
  }

  /**
   * @private
   * @return
   */
  async _initiateRenegotiation() {
    if (!this._rtcPeerConnection) {
      throw new ConnectionError(
        ConnectionErrors.CONNECTION_CLOSED,
        this._options
      )
    }
    if (this._rtcPeerConnection.signalingState !== 'have-local-offer') {
      await this._createOffer()
    }
    if (this._rtcPeerConnection.localDescription) {
      const answer = await this._signaling.sendOffer(
        this._rtcPeerConnection.localDescription
      )
      await this._rtcPeerConnection.setRemoteDescription(answer)
    }
  }

  /**
   * @private
   * @return
   */
  async _respondRenegotiation(remoteDescription) {
    if (!this._rtcPeerConnection) {
      throw new ConnectionError(
        ConnectionErrors.CONNECTION_CLOSED,
        this._options
      )
    }
    await this._rtcPeerConnection.setRemoteDescription(remoteDescription)
    const answer = await this._createAnswer()
    await this._signaling.sendAnswer(answer)
  }

  /**
   * @private
   * @param stream
   * @param track
   * @return
   */
  _remoteTrackAdded(stream, track) {
    const remoteTrack = new ExternalColigoTrack({
      stream,
      track,
    })
    this.remoteTracks$.next([...this.remoteTracks$.getValue(), remoteTrack])
    this.emit(ConnectionEvents.REMOTE_TRACK_ADDED, remoteTrack)
  }

  /**
   * @private
   * @param stream
   * @param track
   * @return
   */
  _remoteTrackRemoved(stream, track) {
    const remoteTracks = this.remoteTracks$.getValue()
    const trackIndex = remoteTracks.findIndex((t) => {
      if (t.getTrack().id !== track.id) return false
      if (t.getStream().id !== stream.id) return false
      return true
    })
    if (remoteTracks[trackIndex]) {
      const clonedRemoteTracks = [...remoteTracks]
      clonedRemoteTracks.splice(trackIndex)[0].stop()
      this.remoteTracks$.next(clonedRemoteTracks)
    }
  }

  /**
   * @public
   * @return
   */
  close() {
    if (!this._rtcPeerConnection) {
      throw new ConnectionError(
        ConnectionErrors.CONNECTION_CLOSED,
        this._options
      )
    }
    this._rtcPeerConnection.close()
    this.remoteTracks$.getValue().forEach((track) => {
      this._remoteTrackRemoved(track.getStream(), track.getTrack())
    })
    this._rtcPeerConnection = undefined
  }
}

export default Connection
