import { Fragment } from 'react'

// NOTE: Prevent creating a new string every time `newLineToBreak` is being executed
const newline = '\n'

/**
 * @param str
 * @return
 */
export default (str) => {
  if (+str === str) {
    return [str]
  }

  return str.split(newline).map((l, i, arr) => (
    <Fragment key={i}>
      {l}
      {i + 1 < arr.length && <br />}
    </Fragment>
  ))
}
