import type { SVGProps } from 'react'
const SvgEmoji20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle
      cx={79.32}
      cy={96.28}
      r={48.66}
      fill='rgb(var(--color-brand-100))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.32 146.94c-27.93 0-50.66-22.72-50.66-50.66s22.72-50.66 50.66-50.66 50.66 22.72 50.66 50.66-22.72 50.66-50.66 50.66zm0-97.31c-25.73 0-46.66 20.93-46.66 46.66s20.93 46.66 46.66 46.66 46.66-20.93 46.66-46.66-20.93-46.66-46.66-46.66z'
    />
    <circle
      cx={52.89}
      cy={91.3}
      r={11.99}
      fill='rgb(var(--color-neutral-50))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M52.89 104.54c-7.3 0-13.25-5.94-13.25-13.24s5.94-13.24 13.25-13.24S66.13 84 66.13 91.3s-5.94 13.24-13.24 13.24zm0-23.99c-5.92 0-10.75 4.82-10.75 10.74s4.82 10.74 10.75 10.74 10.74-4.82 10.74-10.74-4.82-10.74-10.74-10.74z'
    />
    <circle cx={52.89} cy={91.3} r={3.1} fill='rgb(var(--color-brand-700))' />
    <circle
      cx={106.38}
      cy={91.3}
      r={11.99}
      fill='rgb(var(--color-neutral-50))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M106.38 104.54c-7.3 0-13.24-5.94-13.24-13.24s5.94-13.24 13.24-13.24S119.62 84 119.62 91.3s-5.94 13.24-13.24 13.24zm0-23.99c-5.92 0-10.74 4.82-10.74 10.74s4.82 10.74 10.74 10.74 10.74-4.82 10.74-10.74-4.82-10.74-10.74-10.74z'
    />
    <circle cx={106.38} cy={91.3} r={3.1} fill='rgb(var(--color-brand-700))' />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M64.55 121.89c-.48 0-.96-.19-1.32-.57-.7-.73-.67-1.88.06-2.58 4.01-3.82 9.85-6.01 16.04-6.01s12.03 2.19 16.04 6.01c.73.7.76 1.85.06 2.58s-1.85.76-2.58.06c-3.33-3.18-8.26-5-13.52-5s-10.19 1.82-13.52 5c-.35.34-.81.5-1.26.5zm25.58-33.6V77.62c0-1.08.88-1.96 1.96-1.96s1.96.88 1.96 1.96v4.63l19.13-8.5c.99-.44 2.15 0 2.59 1 .44.99 0 2.15-1 2.59L90.12 88.29zm-20.81 0L44.67 77.34c-.99-.44-1.44-1.6-1-2.59.44-.99 1.6-1.43 2.59-1l19.13 8.5v-4.63c0-1.08.88-1.96 1.96-1.96s1.96.88 1.96 1.96v10.67z'
    />
  </svg>
)
export default SvgEmoji20
