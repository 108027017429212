import { LogoVariants, type LogoProps } from './logo.types'

export const Logo = ({
  small = false,
  inverse = false,
  ...other
}: LogoProps) => {
  let variant = LogoVariants.Large

  if (small) {
    variant = inverse ? LogoVariants.SmallInversed : LogoVariants.Small
  } else if (inverse) {
    variant = LogoVariants.LargeInversed
  }

  return (
    // Note: The Icon component is not used due to it's square shape.
    (<svg {...other}>
      <use href={`#${variant}`} />
    </svg>)
  );
}
export default Logo
