import type {
  CSSProperties,
  ElementType,
  HTMLAttributes,
  ReactElement,
} from 'react'

import { type LoaderProps, type VideoProps } from 'ui/revision'

export type VideoStreamProps = {
  LoaderComponent?: ElementType
  LoaderProps?: LoaderProps
  VideoProps?: Omit<VideoProps, 'srcObject'>
  objectFit?: CSSProperties['objectFit']
  srcObject?: VideoProps['srcObject']
  thumbnail?: ReactElement
} & HTMLAttributes<HTMLDivElement>

export enum VideoStreamState {
  Idle,
  Loading,
  Loaded,
}
