import Grid from '@mui/material/Grid'
import cn from 'clsx'
import { useTranslation } from 'react-i18next'

import { makeStyles } from 'app/ui'
import { FormInfo } from 'ui/form'
import LanguageDropdown from 'ui/language-dropdown'
import Logo from 'ui/logo'
import Paper from 'ui/paper'

const useStyles = makeStyles((theme) => ({
  root: theme.background,
  paper: {
    width: 500,
    height: 360,
    padding: theme.spacing(4, 8),
    textAlign: 'center',
  },
  languageDropdown: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}))

type BlockerFormProps = React.ComponentPropsWithoutRef<typeof FormInfo>

const BlockerForm: React.FC<BlockerFormProps> = ({ action, className }) => {
  const { t } = useTranslation()
  const classes = useStyles({})
  return (
    <Grid
      container
      alignItems='center'
      className={cn(classes.root, className)}
      justifyContent='center'
    >
      <LanguageDropdown className={classes.languageDropdown} />
      <Grid
        container
        alignItems='center'
        className={classes.paper}
        component={Paper}
        justifyContent='center'
      >
        <FormInfo isActive title={t('BlockerForm.title')} {...other}>
          {action}
        </FormInfo>
      </Grid>
      <Logo placement='bottom' />
    </Grid>
  )
}

export default BlockerForm
