const START_RIGHTS = {
  user: {
    chat_send: true,
    chat_receive: true,
    audio_send: true,
    audio_receive: true,
    video_send: true,
    video_receive: true,
    file_send: true,
    file_receive: true,
    screens_send: false,
    screens_receive: true,
  },
  guest: {
    chat_send: true,
    chat_receive: true,
    audio_send: true,
    audio_receive: true,
    video_send: true,
    video_receive: true,
    file_send: true,
    file_receive: true,
    screens_send: false,
    screens_receive: true,
  },
  phone: {
    chat_send: false,
    chat_receive: false,
    audio_send: true,
    audio_receive: true,
    video_send: false,
    video_receive: false,
    file_send: false,
    file_receive: false,
    screens_send: false,
    screens_receive: false,
  },
}

const EVENT_TYPES = {
  MESSAGE: 'message',
  WAITING: 'user_waiting',
  JOINED: 'user_joined',
  DENIED: 'user_denied',
  LEFT: 'user_left',
} as const

type EventTypes = typeof EVENT_TYPES
type EventTypesKeys = keyof EventTypes
export type WaitingRoomEvents = EventTypes[EventTypesKeys]

export { START_RIGHTS, EVENT_TYPES }
