import { CssBaseline } from '@mui/material'
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
  createTheme,
  Theme,
} from '@mui/material/styles'

import theme from 'app/ui/theme-default'

declare module '@mui/styles' {
  interface DefaultTheme extends Theme {}
}

const defaultTheme = createTheme(theme)

export interface Props {
  children: React.ReactNode
}

const ThemeProvider: React.FC<Props> = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={defaultTheme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </StyledEngineProvider>
  )
}

export default ThemeProvider
