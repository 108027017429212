import { withStyles as withStylesWithoutDefault } from '@mui/styles'

import defaultTheme from './theme-default'

export function withStyles(
  stylesOrCreator,
  options = {}
): ReturnType<typeof withStylesWithoutDefault> {
  return withStylesWithoutDefault(stylesOrCreator, {
    defaultTheme,
    index: 1,
    ...options,
  })
}

export default withStyles
