import { useCallback } from 'react'

import { STORE_NAME as MEETING_FILES_STORE_NAME } from 'app/features/meeting-files/meeting-files.reducer'
import { useSelector } from 'app/services/state/redux-store'
import { actions as channelRoomActions } from 'app/state/api/channels/channel-room.reducer'
import { parseLinkDataAndGetFile } from 'lib/files'

import RoomFileEvent from './room-file-event.component'

export interface Props {
  fileId: string
}

const RoomFileEventContainer = ({ fileId, ...other }: Props) => {
  const fileProps = useSelector(
    ({ [MEETING_FILES_STORE_NAME]: meetingFiles }) => {
      const { ext, name, size, fileType } = meetingFiles.filesById[fileId]
      return {
        currentFileExtension: ext,
        currentFileName: name,
        currentFileType: fileType,
        currentFileSize: size,
      }
    }
  )

  /**
   * Get a download link of the file with the current fileId, after parse the link and get the file.
   */
  const handleDownloadFile = useCallback(async () => {
    // TODO: Add error handling
    const fileLink = await channelRoomActions.getDownloadLink(fileId)
    parseLinkDataAndGetFile(fileLink)
  }, [fileId])

  return (
    <RoomFileEvent {...other} {...fileProps} onDownload={handleDownloadFile} />
  )
}

export default RoomFileEventContainer
