import { useCallback, useEffect, useMemo, useState } from 'react'
import { useStore } from 'react-redux'

import {
  STORE_NAME as SETTINGS_STORE_NAME,
  selectors as settingsSelectors,
} from 'app/features/settings/settings.reducer'
import localStore from 'app/services/state/local-store'
import { useDispatch, useSelector } from 'app/services/state/redux-store'
import { STORE_NAME as CHANNEL_ROOM_STORE_NAME } from 'app/state/api/channels/channel-room.reducer'
import { getMe, isHost as isHostFn } from 'app/state/utils'
import LoginAsGuestTask from 'app/tasks/login-as-guest-task'
import LoginAsUserTask from 'app/tasks/login-as-user-task'
import LogoutTask from 'app/tasks/logout-task'
import browserUtils from 'lib/browser-utils'

import { AuthContext } from './auth.context'
import { STORE_NAME, actions, selectors } from './auth.reducer'

const AuthProviderContainer = (props): JSX.Element => {
  const store = useStore()
  const dispatch = useDispatch()

  const [authenticationTask, setAuthenticationTask] =
    useState<typeof LoginAsGuestTask>(null)

  const apiUrl = useSelector(({ [SETTINGS_STORE_NAME]: settingsStore }) =>
    settingsSelectors.getApiUrl(settingsStore)
  )
  const apiUrlFallback = useSelector(
    ({ [SETTINGS_STORE_NAME]: settingsStore }) =>
      settingsSelectors.getApiUrlFallback(settingsStore)
  )
  const auth = useSelector(({ [STORE_NAME]: authStore }) =>
    selectors.getAuthenticatedUser(authStore)
  )
  const authenticating = useSelector(({ [STORE_NAME]: authStore }) =>
    selectors.isAuthenticating(authStore)
  )
  const authenticationError = useSelector(({ [STORE_NAME]: authStore }) =>
    selectors.getAuthenticationError(authStore)
  )
  const isAuth = useSelector(({ [STORE_NAME]: authStore }) =>
    selectors.isAuthenticated(authStore)
  )
  const isHost = useSelector(
    ({ [CHANNEL_ROOM_STORE_NAME]: channelRoomStore }) => {
      const me = getMe(channelRoomStore)
      return !!me && isHostFn(me)
    }
  )

  const onStartAuthenticating = useCallback(
    (payload) => dispatch(actions.startAuthenticating(payload)),
    [dispatch]
  )
  const onStopAuthenticating = useCallback(
    () => dispatch(actions.stopAuthenticating()),
    [dispatch]
  )

  useEffect(
    () => () => {
      if (authenticationTask) {
        authenticationTask.cancel()
      }
    },
    [authenticationTask]
  )

  const authenticateGuest = useCallback(async () => {
    onStartAuthenticating('guest')

    try {
      const task = new LoginAsGuestTask(store, {
        apiUrl,
        apiUrlFallback,
      })
      setAuthenticationTask(task)
      await task.run()
      onStopAuthenticating()
    } catch (err) {
      onStopAuthenticating()
      throw err
    }
  }, [
    apiUrl,
    apiUrlFallback,
    onStartAuthenticating,
    onStopAuthenticating,
    store,
  ])

  const authenticateUser = useCallback(
    async ({
      localToken,
      sessionToken,
      username,
      password,
      redirectToMyRoom = false,
    }) => {
      onStartAuthenticating('user')

      try {
        const task = new LoginAsUserTask(store, {
          apiUrl,
          apiUrlFallback,
          redirectToMyRoom,
          setToken(newToken) {
            localStore.setAuthToken(newToken)
          },
          auth: {
            localToken,
            sessionToken,
            username,
            password,
          },
        })
        setAuthenticationTask(task)
        await task.run()
        onStopAuthenticating()
      } catch (err) {
        onStopAuthenticating()
        throw err
      }
    },
    [apiUrl, apiUrlFallback, onStartAuthenticating, onStopAuthenticating, store]
  )

  const handleLogout = useCallback(async () => {
    await new LogoutTask(store).run()
    if (browserUtils.isElectron()) {
      window.close()
    }
  }, [store])

  const value = useMemo(() => {
    return {
      authenticateGuest,
      authenticateUser,
      authenticating,
      authenticationError,
      auth,
      isAuth,
      isHost,
      logout: handleLogout,
    }
  }, [
    auth,
    authenticateGuest,
    authenticateUser,
    authenticating,
    authenticationError,
    handleLogout,
    isAuth,
    isHost,
  ])
  return <AuthContext.Provider {...props} value={value} />
}

export default AuthProviderContainer
