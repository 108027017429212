import type { SVGProps } from 'react'
const SvgEmoji19 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle
      cx={79.08}
      cy={94.42}
      r={48.66}
      fill='rgb(var(--color-brand-100))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.08 145.08c-27.93 0-50.66-22.72-50.66-50.66s22.72-50.66 50.66-50.66 50.66 22.72 50.66 50.66-22.72 50.66-50.66 50.66zm0-97.31c-25.73 0-46.66 20.93-46.66 46.66s20.93 46.66 46.66 46.66 46.66-20.93 46.66-46.66-20.93-46.66-46.66-46.66z'
    />
    <circle
      cx={52.29}
      cy={97.44}
      r={13.21}
      fill='rgb(var(--color-brand-200))'
    />
    <circle
      cx={105.87}
      cy={97.44}
      r={13.21}
      fill='rgb(var(--color-brand-200))'
    />
    <circle
      cx={52.56}
      cy={91.3}
      r={11.99}
      fill='rgb(var(--color-neutral-50))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M52.56 104.54c-7.3 0-13.24-5.94-13.24-13.24s5.94-13.24 13.24-13.24S65.81 84 65.81 91.3s-5.94 13.24-13.25 13.24zm0-23.99c-5.92 0-10.74 4.82-10.74 10.74s4.82 10.74 10.74 10.74 10.75-4.82 10.75-10.74-4.82-10.74-10.75-10.74z'
    />
    <circle cx={52.56} cy={91.3} r={3.1} fill='rgb(var(--color-brand-700))' />
    <circle
      cx={106.04}
      cy={91.3}
      r={11.99}
      fill='rgb(var(--color-neutral-50))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M106.04 104.54c-7.3 0-13.24-5.94-13.24-13.24s5.94-13.24 13.24-13.24S119.28 84 119.28 91.3s-5.94 13.24-13.24 13.24zm0-23.99c-5.92 0-10.74 4.82-10.74 10.74s4.82 10.74 10.74 10.74 10.74-4.82 10.74-10.74-4.82-10.74-10.74-10.74z'
    />
    <circle cx={106.04} cy={91.3} r={3.1} fill='rgb(var(--color-brand-700))' />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M79.08 122.23c5.53 0 10.82 1.05 15.67 2.96 1.43.56 3.02-.47 3.03-2.01v-.14c0-8.93-8.37-16.16-18.71-16.16s-18.71 7.24-18.71 16.16v.14c.02 1.54 1.6 2.57 3.03 2.01a42.688 42.688 0 0 1 15.67-2.96z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M95.57 126.35c-.4 0-.8-.07-1.18-.22-4.88-1.92-10.03-2.89-15.31-2.89s-10.43.97-15.31 2.89a3.215 3.215 0 0 1-4.4-2.93v-.15c0-9.46 8.84-17.16 19.71-17.16s19.71 7.7 19.71 17.16v.15a3.203 3.203 0 0 1-1.4 2.6c-.54.37-1.17.55-1.81.55zm-16.49-5.12c5.53 0 10.93 1.02 16.04 3.03.38.15.8.1 1.14-.13.33-.22.52-.57.52-.96l1-.13h-1c0-8.36-7.94-15.16-17.71-15.16s-17.71 6.8-17.71 15.16v.14c0 .38.19.73.52.95.34.23.76.28 1.14.13a43.648 43.648 0 0 1 16.04-3.03z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M79.08 116.22c-4.72 0-8.81 2.76-10.75 6.76.03.02.05.03.08.05 3.41-.88 6.98-1.35 10.67-1.35s7.26.48 10.67 1.35c.03-.02.05-.03.08-.05-1.94-4-6.02-6.76-10.75-6.76z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M68.41 124.03a.98.98 0 0 1-.52-.15c-.44-.27-.69-.88-.46-1.34 2.19-4.52 6.66-7.32 11.65-7.32s9.46 2.81 11.65 7.32a1 1 0 0 1-.37 1.28s-.08.05-.09.05c-.23.13-.52.18-.77.12a42.019 42.019 0 0 0-20.84 0c-.08.02-.17.03-.25.03zm10.67-6.81c-3.48 0-6.66 1.62-8.71 4.33a43.666 43.666 0 0 1 17.42 0 10.87 10.87 0 0 0-8.71-4.33z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M64.34 64.76c-.17-.42-.48-.75-.9-.94-1.24-.54-2.69-.81-4.19-.78-1.12.02-2.25.21-3.35.56-2.61.82-4.81 2.46-6.06 4.5-.24.39-.31.84-.2 1.29a1.707 1.707 0 0 0 1.65 1.3c.57 0 1.13-.29 1.45-.81.82-1.35 2.34-2.45 4.18-3.03 1.83-.58 3.71-.54 5.16.09.86.37 1.86-.01 2.24-.87.18-.42.19-.88.03-1.3zm29.47 0c.17-.42.48-.75.9-.94 1.24-.54 2.69-.81 4.19-.78 1.12.02 2.25.21 3.35.56 2.61.82 4.81 2.46 6.06 4.5.24.39.31.84.2 1.29a1.707 1.707 0 0 1-1.65 1.3c-.57 0-1.13-.29-1.45-.81-.82-1.35-2.34-2.45-4.18-3.03-1.83-.58-3.71-.54-5.16.09-.86.37-1.86-.01-2.24-.87-.18-.42-.19-.88-.03-1.3z'
    />
  </svg>
)
export default SvgEmoji19
