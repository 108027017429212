import _ from 'lodash'

import env from 'app/config/env'
import { DEFAULT_MAX_GUESTS, USER_STATUS } from 'constants/constants'
import { FEATURE_NAMES } from 'constants/features-map'
import { phoneTypePredicate } from 'app/state/utils'
import FeatureUtils from 'lib/feature-utils'

import { selectors as chanSettingsSelectors } from 'app/state/api/channels/channel-settings.reducer'
import { selectors as chanConferenceSelectors } from 'app/state/api/channels/channel-conference.reducer'
import { selectors as flagsSelectors } from 'app/features/flags/flags.reducer'

import { getOnlineGuests } from './member-filters'
import getMe from './getMe'
import isHost from './is-host'
import getMemberRoomState from './get-member-room-state'

/**
 * Get the amount of in room guests.
 * @param channelRoom
 * @return
 */
function getOnlineInRoomGuests(channelRoom) {
  const roomMembersWithoutHost = getOnlineGuests(channelRoom)
  return _.size(roomMembersWithoutHost)
}

/**
 * Check if the room is full with online guests.
 * @param channelRoom
 * @param flags
 * @return
 */
function isRoomFull(channelRoom, flags) {
  const maxGuests =
    +FeatureUtils.getMaxGuests(channelRoom, flags) || DEFAULT_MAX_GUESTS

  const onlineGuests = _.chain(getOnlineGuests(channelRoom))
    .reject(phoneTypePredicate)
    .size()
    .value()
  return onlineGuests >= maxGuests
}

/**
 * Check if the member/meta's status is in-conferenceroom
 * @param member
 * @param member.meta
 * @param member.meta.status
 * @param member.status
 * @return
 */
function isInRoom(member) {
  return getMemberRoomState(member) === USER_STATUS.IN_ROOM
}

/**
 * checks if current user is host, else returns false
 * and checks if the current user is alone in the meeting
 * @param channelRoom
 * @return
 */
function isHostAlone(channelRoom) {
  const me = getMe(channelRoom)
  return isHost(me) && !getOnlineGuests(channelRoom).length
}

/**
 * scenarios which require the host setup to be shown:
 * | PRO license  | has roomname  | setup finished  | show setup?
 * | FALSE        | TRUE          | TRUE            |	FALSE       |	// basic user who had pro license before  and already finsished setup wizard
 * | FALSE        | FALSE         | TRUE            |	FALSE       |	// basic user who already finsished setup wizard
 * | FALSE        | TRUE          | FALSE           |	FALSE       |	// basic users who had pro license and already set roomname
 * | FALSE        | FALSE         | FALSE           |	TRUE        |	// basic user who has not finished wizard
 * | TRUE         | TRUE          | TRUE            |	FALSE       |	// pro user who already set roomname through wizard
 * | TRUE         | FALSE         | TRUE            |	FALSE       |	// pro user who finsihed setup wizard when basic license, but now upgraded and has no roomname set
 * | TRUE         | TRUE          | FALSE           |	FALSE       |	// pro users who already set roomname
 * | TRUE         | FALSE         | FALSE           |	TRUE        |	// pro user never set roomname until now
 */
function shouldShowHostSetup(
  channelConference,
  channelSettings,
  features,
  meetingId
) {
  if (!chanConferenceSelectors.isJoined(channelConference)) return false
  if (!chanSettingsSelectors.isJoined(channelSettings)) return false
  if (!chanSettingsSelectors.hasReceivedSettings(channelSettings)) return false

  const isHostSetupCompleted =
    chanSettingsSelectors.isHostSetupCompleted(channelSettings)
  const conference = chanConferenceSelectors.getConference(
    channelConference,
    meetingId
  )
  const roomName = conference.name
  const canChangeRoomName = flagsSelectors.getFeature(
    FEATURE_NAMES.EDIT_CONFERENCE_NAME,
    features
  ).licensed

  return (
    !env('no_host_setup') &&
    !isHostSetupCompleted &&
    (!canChangeRoomName || !roomName || !!env('dev'))
  )
}

export {
  getOnlineInRoomGuests,
  isRoomFull,
  isInRoom,
  isHostAlone,
  shouldShowHostSetup,
}
