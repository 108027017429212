import { type SVGProps } from 'react'

export enum LogoVariants {
  Large = 'logo--large',
  Small = 'logo--small',
  LargeInversed = 'logo--large--inversed',
  SmallInversed = 'logo--small--inversed',
}

export type LogoProps = {
  small?: boolean
  inverse?: boolean
} & SVGProps<SVGSVGElement>
