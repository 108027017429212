import { SESSION_STORAGE_TYPES } from 'constants/constants'

import URLUtils from 'lib/url-utils'

import AbstractStorage from './storage'

class SessionStore extends AbstractStorage {
  initData() {
    super.initData()

    const { token } = URLUtils.getUrlParams()
    if (token) {
      /**
       * When there is an authentication token in the url, we store it in the session storage.
       * This is needed because the authentication token itself is prohibited to be persistent in the url and gets removed after a redirect or location change.
       * This solves the issue that, users who are logged in via an token from the url, are not able to stay logged in throughout the same page session.
       */
      this.setAuthToken({ token })
    }
  }

  /**
   * @public
   * @returns
   */
  getAuthToken() {
    const authTokenString = this.getItem(SESSION_STORAGE_TYPES.AUTH_TOKEN)
    if (!authTokenString) return null
    try {
      return JSON.parse(authTokenString)
    } catch (err) {
      this.removeAuthToken()
      return null
    }
  }

  /**
   * @public
   * @param [token=null]
   * @param token.token
   * @param token.expires
   * @param token.expireDate
   * @returns
   */
  setAuthToken(token = null) {
    if (token === null) {
      this.removeAuthToken()
    } else {
      this.setItem(SESSION_STORAGE_TYPES.AUTH_TOKEN, JSON.stringify(token))
    }
  }

  /**
   * @public
   * @returns
   */
  removeAuthToken() {
    this.removeItem(SESSION_STORAGE_TYPES.AUTH_TOKEN)
  }
}

export default new SessionStore(window.sessionStorage)
