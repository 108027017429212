import { Fade as MuiFade } from '@mui/material'

type Props = React.ComponentProps<typeof MuiFade>

export const Fade: React.FC<Props> = ({ children, ...other }) => {
  /**
   * @see https://mui.com/components/fade/#main-content
   * wrap children in a span to avoid error:
   * Uncaught TypeError: Cannot read properties of null (reading 'scrollTop')
   *
   */
  return (
    <MuiFade {...other}>
      <span>{children}</span>
    </MuiFade>
  )
}

export default Fade
