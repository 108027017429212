import { fromEvent, zip } from 'rxjs'
import { filter, distinctUntilChanged, pluck, switchMap } from 'rxjs/operators'

import env from 'app/config/env'
import AudioObserver from 'lib/rtc/audio-observer'

import { debounceTimeAfterFirst } from 'lib/rxjs/pipes/debounce-time-after'

import { STORE_NAME as MEDIA_STORE_NAME } from 'app/features/media/media.reducer'

import ROOM_SERVICES from './room-services'
import roomConnectionManager from './room-connection-manager'
import roomTrackManager from './room-track-manager'
import { shouldMemberEnableAudio } from './room-media-commands'

class RoomMediaManager {
  _trackManager = roomTrackManager

  _connectionManager = roomConnectionManager

  audioObserver = new AudioObserver()

  /**
   * @type {import('rxjs').Subscription[]}
   */
  _subscriptions = []

  /**
   * @type {import('rxjs').Observable<Boolean>}
   */
  talking$ = this.audioObserver.talking$.pipe(
    filter((val) => val !== null),
    debounceTimeAfterFirst(env('active_speaker.senddelay', 1000)),
    distinctUntilChanged()
  )

  get silenced$() {
    return this.audioObserver.silenced$
  }

  get trackManager() {
    return this._trackManager
  }

  get connectionManager() {
    return this._connectionManager
  }

  /**
   * @type {import('services/media/room-audio-mixer').default | null}
   */
  audioMixer = null

  /**
   * @constructs
   * @param [audioMixer=null]
   */
  constructor(audioMixer = null) {
    this.audioMixer = audioMixer

    this.initialiseSubscriptions()

    this.audioObserver.init(this.trackManager.tracks$[ROOM_SERVICES.ROOM_AUDIO])
  }

  initialiseSubscriptions() {
    this._subscriptions = [
      ...this._subscriptions,
      this.trackManager.source$[ROOM_SERVICES.ROOM_SCREEN]
        .pipe(
          filter(Boolean),
          pluck('track'),
          switchMap((track) => fromEvent(track, 'ended'))
        )
        .subscribe(async () =>
          this.connectionManager.closePublishingScreenConnection()
        ),
      this.trackManager.source$[ROOM_SERVICES.SCREEN_SHARING_AUDIO]
        .pipe(
          filter(Boolean),
          pluck('track'),
          switchMap((track) => fromEvent(track, 'ended'))
        )
        .subscribe(async () =>
          this.connectionManager.closePublishingScreenConnection()
        ),
    ]
    this._subscriptions.push(
      this.trackManager.tracks$[ROOM_SERVICES.ROOM_SCREEN]
        .pipe(filter(Boolean))
        .subscribe(this._handleScreenStart.bind(this))
    )

    this._subscriptions.push(
      this.trackManager.tracks$[ROOM_SERVICES.ROOM_VIDEO]
        .pipe(filter(Boolean))
        .subscribe(this._handleVideoStart.bind(this))
    )

    this._subscriptions.push(
      this.trackManager.tracks$[ROOM_SERVICES.ROOM_AUDIO]
        .pipe(filter(Boolean))
        .subscribe((track) => {
          const { store } = window
          const { [MEDIA_STORE_NAME]: media } = store.getState()

          if (
            this.connectionManager.connections[ROOM_SERVICES.ROOM_AUDIO] &&
            shouldMemberEnableAudio(media)
          ) {
            if (this.audioMixer) {
              this.audioMixer.replaceLocalTrack(track)
            } else {
              this.connectionManager.publishAudioTrack(track)
            }
          }
        })
    )
  }

  /**
   * @returns {import('services/media/room-audio-mixer').default | null}
   */
  getAudioMixer() {
    return this.audioMixer
  }

  /**
   * Get tracks added to the audio mixer. Returns empty array if audio mixer is not set.
   * @return
   */
  getAudioMixerTracks() {
    if (this.audioMixer) {
      return this.audioMixer.getAddedTracks()
    }
    return []
  }

  /**
   *
   * @param audioMixer
   */
  setAudioMixer(audioMixer = null) {
    if (this.audioMixer) {
      this.closeAudioMixer()
    }
    this.audioMixer = audioMixer
  }

  closeAudioMixer() {
    if (this.audioMixer) {
      this.audioMixer.close()
      this.audioMixer = null
    }
  }

  _handleVideoStart(track) {
    if (this.connectionManager.connections[ROOM_SERVICES.ROOM_VIDEO]) {
      this.connectionManager.publishVideoTrack(track)
      this.connectionManager.publishThumbnailTrack(track)
    }
  }

  _handleScreenStart(track) {
    if (this.connectionManager.connections[ROOM_SERVICES.ROOM_SCREEN]) {
      this.connectionManager.publishScreenTrack(track)
    }
  }

  dispose() {
    this._subscriptions.forEach((sub) => {
      sub.unsubscribe()
    })
    this._subscriptions = []
  }
}

const roomMediaManager = new RoomMediaManager()
window.roomMediaManager = roomMediaManager
export default roomMediaManager
