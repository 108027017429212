import { ClPanel } from '@enreach/core-component-library-react'
import { defineCustomElementClPanel } from '@enreach/core-component-library/dist/components/index'

type PanelProps = React.ComponentProps<typeof ClPanel>

export const Panel = ({ children, ...other }: PanelProps) => {
  return (
    <ClPanel {...other}>
      {/**
       * @NOTE: monkeypatch: ClPanel will not work properly without a div that remains as the root reference
       */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
        }}
      >
        {children}
      </div>
    </ClPanel>
  )
}

export default Panel

defineCustomElementClPanel()
