import cn from 'clsx'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useSelector } from 'app/services/state/redux-store'
import { STORE_NAME as CHANNEL_ROOM_STORE_NAME } from 'app/state/api/channels/channel-room.reducer'
import { getMemberUserName, getUser } from 'app/state/utils'
import { makeStyles } from 'app/ui'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    margin: 0,
    marginBottom: -theme.spacing(0.5),
  },
}))

export interface Props {
  children?: React.ReactNode
  className?: string
  maxLength?: number
  memberId: string
  separator?: string
  tag?: React.ElementType
  userName?: string
  wrapperProps?: Record<string, any>
  wrapperTag?: React.ElementType
}

const UserName = ({
  memberId,
  children,
  className,
  maxLength = 0,
  separator = ' ',
  tag: Tag = 'span',
  userName: userNameProps,
  wrapperProps = {},
  wrapperTag: WrapperTag,
  ...other
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()

  const userName = useSelector(({ [CHANNEL_ROOM_STORE_NAME]: channelRoom }) => {
    const member = getUser(channelRoom, memberId)
    const memberUserName = member ? getMemberUserName(member) : undefined
    return userNameProps || memberUserName
  })

  let resUserName: React.ReactNode = maxLength
    ? _.truncate(userName, { length: maxLength, separator })
    : userName

  if (WrapperTag) {
    resUserName = <WrapperTag {...wrapperProps}>{resUserName}</WrapperTag>
  }

  return (
    <Tag className={cn(classes.root, className)} {...other}>
      {resUserName || t('WaitingRoom.anonymous', 'Anonymous')}
      {children}
    </Tag>
  )
}

export default UserName
