import { applyMiddleware, compose } from 'redux'
import type { Dispatch, Middleware } from 'redux'

/**
 * A registry for Redux middleware, allowing features to register their
 * middleware without needing to create additional inter-feature dependencies.
 */
export class MiddlewareRegistry<S = any, D extends Dispatch = Dispatch> {
  static create<S = any, D extends Dispatch = Dispatch>(
    middlewares: Array<Middleware<{}, S, D>>
  ): MiddlewareRegistry<S, D> {
    return new MiddlewareRegistry<S, D>(middlewares)
  }

  /**
   * The set of registered middleware.
   */
  private readonly _elements: Set<Middleware<{}, S, D>>

  /**
   * The array of all registered middleware.
   */
  private _elementsArray: Array<Middleware<{}, S, D>>

  constructor(elements: Array<Middleware<{}, S, D>>) {
    this._elementsArray = elements
    this._elements = new Set(elements)
  }

  /**
   * A middleware for Redux to execute all incoming actions and pass them to all
   * registered _elements.
   */
  middleware: Middleware<{}, S, D> = (store) => (next) => (action) => {
    if (typeof action === 'undefined') {
      throw new TypeError(
        'Invalid action exception, expects function or object, received "undefined"'
      )
    }

    let elements: Array<ReturnType<Middleware<{}, S, D>>> = []

    if (action.type !== undefined) {
      elements = this._elementsArray.map((e) => e(store))
    }

    return compose<Middleware<{}, S, D>>(...elements)(next)(action)
  }

  /**
   * Applies the MiddlewareRegistry.middleware as middleware into a store enhancer.
   * (@link http://redux.js.org/docs/api/applyMiddleware.html).
   * @param additional - Any additional middleware that need to
   * be included (such as middleware from third-party modules).
   */
  applyMiddleware(...additional: Array<Middleware<{}, S, D>>) {
    // XXX The explicit definition of the local variable middlewares is to
    // satisfy flow.
    return applyMiddleware(this.middleware, ...additional)
  }

  /**
   * Remove a middleware from the registry.
   * The method can be invoked both before and after {@link #applyMiddleware}.
   * @param middlewares - An array of Redux middleware.
   */
  unregister(...middlewares: Array<Middleware<{}, S, D>>) {
    middlewares.forEach((middleware) => this._elements.delete(middleware))
    this._elementsArray = Array.from(this._elements)
  }

  /**
   * Adds a middleware to the registry.
   * The method can be invoked both before and after {@link #applyMiddleware()}.
   * @param middlewares - An array of Redux middleware.
   */
  register(...middlewares: Array<Middleware<{}, S, D>>) {
    middlewares.forEach((middleware) => this._elements.add(middleware))
    this._elementsArray = Array.from(this._elements)
  }
}

export default MiddlewareRegistry
