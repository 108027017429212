import _ from 'lodash'

import {
  MAINTENANCE_DATE_FORMATS,
  MAINTENANCE_DATE_DISPLAY_FORMAT,
} from 'constants/constants'

import env from 'app/config/env'
import moment from 'lib/moment'

import { ACTION_TYPES, actions } from './app.reducer'

/**
 * Check if the maintenance message should be shown
 * @param info
 * @param info.enabled
 * @param info.start
 * @param info.end
 * @param info.displayFormat
 * @param info.hideMessageAfter
 * @return
 */
function shouldShowMaintenanceMessage(info) {
  const { enabled, start, end, hideMessageAfter } = info

  if (!enabled) {
    return false
  }

  const startMoment = moment(start, MAINTENANCE_DATE_FORMATS, true)

  if (!startMoment.isValid()) {
    throw new Error(`Invalid start date is given: "${start}"`)
  }

  let endMoment = moment(end, MAINTENANCE_DATE_FORMATS, true)

  if (!endMoment.isValid()) {
    throw new Error(`Invalid end date is given: "${end}"`)
  }

  if (Array.isArray(hideMessageAfter) && _.size(hideMessageAfter) === 2) {
    try {
      endMoment = endMoment.add(hideMessageAfter[0], hideMessageAfter[1])
    } catch (err) {
      throw new Error(
        `Invalid hideMessageAfter values given "${hideMessageAfter[0]}", "${hideMessageAfter[1]}"`
      )
    }
  }

  return !moment().isAfter(endMoment)
}

/**
 *
 * @param info
 * @param info.enabled
 * @param info.start
 * @param info.end
 * @param info.displayFormat
 * @param info.hideMessageAfter
 * @returns
 */
function setMaintenanceInfo(info) {
  const show = shouldShowMaintenanceMessage(info)

  const payload = { show, info }

  payload.info.displayFormat =
    payload.info.displayFormat || MAINTENANCE_DATE_DISPLAY_FORMAT

  return actions.setMaintenance(payload)
}

export default (store) => (next) => (action) => {
  const nextState = next(action)

  if (action.type === ACTION_TYPES.INIT) {
    const maintenance = env('maintenance')

    if (maintenance) {
      store.dispatch(setMaintenanceInfo(maintenance))
    }
  }

  return nextState
}
