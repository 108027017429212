import _ from 'lodash'

import { hostPredicate } from './member-filters'
import validateMember from './validate-member'

/**
 * Check whether the given member is a moderator of the room.
 *
 * @param member
 * @param member.rights
 * @param member.rights.room_moderator
 * @return
 */
function isHost(member) {
  validateMember(member)
  return _.isMatch(member, hostPredicate)
}

export default isHost
