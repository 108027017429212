import { makeStyles } from 'app/ui'

/**
 * TODO: Remove this file when the following issues are fixed:
 * - text alignment in list item secondary text is inherited from dialog when it should be left aligned
 */
export default makeStyles({
  '@global': {
    'cl-list-item::part(body)': {
      textAlign: 'left',
    },
    '.list-item-like': {
      minHeight: 'calc(var(--spacing-1) * 7)',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 var(--spacing-2)',
    },
  },
})
