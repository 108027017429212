import _ from 'lodash'
import MediaType from 'media-type'

import downloadBlob from 'lib/download-blob'
import urlUtils from 'lib/url-utils'
import { Icons } from 'constants/icons'

/**
 * xhr currently used for uploading
 *
 */
let xhr = null

/**
 * @param file
 * @param url
 * @param onProgress function callback for progress has 2 params: file, progress percentage
 * @return
 */
export const uploadFile = async (file, url, onProgress) =>
  new Promise((resolve, reject) => {
    const formdata = new FormData()
    formdata.append('file', file.file, file.file.name)
    xhr = new XMLHttpRequest()
    xhr.upload.onprogress = onProgress
    xhr.upload.onabort = reject
    xhr.onerror = reject
    xhr.upload.onerror = reject
    xhr.onreadystatechange = (e) => {
      if (e.currentTarget.readyState === 4) {
        if (e.currentTarget.status !== 200) {
          reject(e.currentTarget)
        } else {
          resolve(e)
        }
      }
    }

    xhr.open('POST', url, true)
    xhr.send(formdata)
  })

/**
 * calculate progress from a ProgressEvent
 * @param  event
 * @param  event.lengthComputable
 * @param  event.loaded
 * @param  event.total
 * @returns returns progress percentage or false if no progress could be calculated
 */
export const calculateProgress = ({ lengthComputable, loaded, total }) => {
  if (!lengthComputable) {
    throw new Error('Event is not a length computable ProgressEvent')
  }
  return loaded / total
}

/**
 * abort the xhr based upload
 * @return true if request was aborted, false if exception was caught when aborting
 */
export const abortUpload = () => {
  if (xhr) {
    try {
      xhr.abort()
      return true
    } catch (e) {
      return false
    }
  }

  return true
}

/**
 * @param url
 */
export const getFile = (url) => {
  const x = new XMLHttpRequest()
  x.onreadystatechange = () => {
    if (x.readyState === 4 && x.status === 200) {
      const fileName = x.getResponseHeader('Content-Disposition')
      const savedFileName = _.split(fileName, '=')[1]
        .replace(/["']/g, '')
        .replace(/[+]/g, ' ')
      downloadBlob(new Blob([x.response]), savedFileName)
    }
  }
  // TODO exception handling? what if file does not exist? what happens?
  x.open('GET', url, true)
  x.responseType = 'blob'
  x.send(null)
}

/**
 * @param linkData
 */
export const parseLinkDataAndGetFile = (linkData) => {
  getFile(urlUtils.composeUrlFromLinkData(linkData))
}

/**
 * @param linkData
 */
export const parseLinkDataAndGetFileInElectron = (linkData) => {
  const url = urlUtils.composeUrlFromLinkData(linkData)
  window.screenSharing.sendIPCEvent('downloadFile', url)
}

export const getExtensionForFileName = (filename) =>
  filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2) || ''

export const getOnlyFileName = (filename) =>
  filename.substring(0, filename.lastIndexOf('.')) || filename

/**
 * @see https://tools.ietf.org/html/rfc6838 for mediatype string specs
 * @param mediaType mediatype string, typically formatted as type/subtype
 */
export const getMediaType = (mediaType) =>
  MediaType.fromString(mediaType).type || ''

/**
 * @param mediaType
 */
export function getIconForMediaType(mediaType) {
  switch (getMediaType(mediaType)) {
    case 'image':
      return Icons.filePicture
    case 'text':
      return Icons.fileTxtDoc
    case 'application':
    case 'data':
      return Icons.fileFolder
    case 'audio':
      return Icons.fileMp3
    case 'video':
      return Icons.fileMp4Gif
    default:
      return Icons.genericDocument
  }
}

const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']

/**
 * @param bytes
 * @returns Depending on bytes size, the function will returns a match from the sizes array
 */
export function convertFileSize(bytes) {
  const i = Number(Math.floor(Math.log(bytes) / Math.log(1000)))

  if (!bytes) return '0 Byte'

  const multiplier = 10 ** Math.min(Math.max(i - 1, 0), 1)

  return `${Math.round((bytes / 1000 ** i) * multiplier) / multiplier} ${
    sizes[i]
  }`
}

/**
 * @param files Array of files to add to the FileList
 *
 * @see https://stackoverflow.com/a/52079109
 */
export function createFileList(files = []): FileList {
  const fakeEvent = new ClipboardEvent('')
  const list = fakeEvent.clipboardData || new DataTransfer()

  files.forEach((file) => {
    list.items.add(file)
  })

  return list.files
}
