import { Typography } from 'ui/revision'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'

import { useId } from 'app/react/hooks/id-registry.hooks'
import { makeStyles } from 'app/ui'
import { Close } from 'ui/icon-button'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 40,
    padding: theme.spacing(0, 1),
    transition: 'all 0.3s ease-out',
    flex: '0 1 auto',
    backgroundColor: theme.palette.background.default,
    borderTopLeftRadius: 'var(--border-radius-small)',
    borderTopRightRadius: 'var(--border-radius-small)',
  },
  userstatus: {
    marginRight: 'auto',
  },
  username: {
    display: 'inline-block',
    float: 'left',
    overflow: 'hidden',
    maxWidth: 150,
    paddingRight: 4,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  close: {
    maxHeight: 40,
    marginRight: -theme.spacing(1),

    transform: 'scale(0.6)',
  },
}))

type Props = {
  offline: boolean
  typing: boolean
  userName: string
  onClose: () => void
}

const PrivateChatHeader: React.FC<Props> = ({
  offline,
  typing,
  userName,
  onClose,
}: Props) => {
  let statusKey = ''
  const classes = useStyles()
  const { id } = useId()
  const { t } = useTranslation()

  if (offline) {
    statusKey = 'RoomPage.Chat.offline'
  } else if (typing) {
    statusKey = 'RoomPage.Chat.typing'
  }

  return (
    <Grid
      container
      alignItems='center'
      className={classes.root}
      justifyContent='flex-end'
    >
      <Typography className={classes.userstatus}>
        <Typography className={classes.username}>{userName}</Typography>
        {t(statusKey)}
      </Typography>
      <Close
        className={classes.close}
        color='secondary'
        data-testid={id('PrivateChat.close')}
        size='small'
        onClick={onClose}
      />
    </Grid>
  )
}

export default PrivateChatHeader
