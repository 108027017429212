import _ from 'lodash'

import { memberPredicate } from './member-filters'

/**
 * Check whether the given member is valid and if not throw an error.
 *
 * @param member
 * @param member.meta
 * @param member.rights
 * @param member.states
 * @return
 */
function validateMember(member) {
  if (!member || !_.isMatch(member, memberPredicate)) {
    throw new Error('param member is not a valid member', member)
  }
  return true
}

export default validateMember
