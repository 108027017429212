import PropTypes from 'prop-types'
import { Grid } from '@mui/material'

import { withTheme } from 'app/ui'

import { ChatMessageTimestamp } from 'ui/chat/chat-message'

const SystemActionEvent = ({
  actionIcon,
  children,
  className,
  timestamp,
  theme,
}) => (
  <Grid
    container
    className={className}
    gap={theme.spacing(1)}
    justifyContent='space-between'
  >
    {actionIcon}
    {children}
    <ChatMessageTimestamp timestamp={timestamp} />
  </Grid>
)

SystemActionEvent.propTypes = {
  theme: PropTypes.objectOf(PropTypes.any).isRequired,
  timestamp: PropTypes.number.isRequired,

  actionIcon: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
}

SystemActionEvent.defaultProps = {
  actionIcon: null,
  children: null,
  className: '',
}

export default withTheme(SystemActionEvent)
