import _ from 'lodash'

import { useId } from 'app/react/hooks/id-registry.hooks'
import { Icons } from 'constants/icons'
import { convertFileSize, getIconForMediaType } from 'lib/files'
import { IconButton } from 'ui/icon-button'
import { Icon, ListItem } from 'ui/revision'

interface Props {
  currentFileExtension: string
  currentFileName: string
  currentFileType: string
  currentFileSize: number
  onDownload: () => void
}

const RoomFileEvent: React.FC<Props> = ({
  currentFileExtension,
  currentFileName,
  currentFileType,
  currentFileSize,
  onDownload,
}: Props) => {
  const { id } = useId()

  return (
    <ListItem
      primaryText={_.truncate(currentFileName, {
        length: 18,
        separator: ' ',
      })}
      secondaryText={`${currentFileExtension.toUpperCase()} ${convertFileSize(
        currentFileSize
      )}`}
    >
      <Icon name={getIconForMediaType(currentFileType)} slot='leading' />
      <IconButton
        aria-label='download'
        data-testid={id('RoomMessages.downloadFile')}
        iconName={Icons.actionDownload}
        slot='trailing'
        variant='tertiary'
        onClick={onDownload}
      />
    </ListItem>
  )
}

export default RoomFileEvent
