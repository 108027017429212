import {
  DEFAULT_MAX_GUESTS,
  DEFAULT_MAX_GRID_MEMBERS,
} from 'constants/constants'

export enum FEATURE_NAMES {
  // Non Licensed
  SCREENSHARE = 'SCREENSHARE',
  DEVICES_SELECT_FIELDS = 'DEVICES_SELECT_FIELDS',
  LANGUAGE_DROPDOWN_FLAGS = 'LANGUAGE_DROPDOWN_FLAGS',
  AUDIO_OUTPUT_INDICATOR = 'AUDIO_OUTPUT_INDICATOR',
  ICS_INVITES = 'ICS_INVITES',
  ROOM_INVITING = 'ROOM_INVITING',
  END_MEETING_TO_SIGNUP = 'END_MEETING_TO_SIGNUP',
  WEBSHARE = 'WEBSHARE',
  ACTIVE_SPEAKER = 'ACTIVE_SPEAKER',
  SMS_INVITES = 'SMS_INVITES',
  MIRO = 'MIRO',
  BACKGROUND_EFFECTS = 'BACKGROUND_EFFECTS',
  NOISE_CANCELLATION = 'NOISE_CANCELLATION',
  DESKTOP_NOTIFICATIONS = 'DESKTOP_NOTIFICATIONS',
  AUDIO_GROUP_SELECTION = 'AUDIO_GROUP_SELECTION',
  SILENCED_MIC_DETECTION = 'SILENCED_MIC_DETECTION',
  GRANT_HOST = 'GRANT_HOST',
  IDEANOTE = 'IDEANOTE',

  // Licensed
  MAX_GUESTS = 'MAX_GUESTS',
  VIDEO_BROADCAST = 'VIDEO_BROADCAST',
  SESSION_RECORDING = 'SESSION_RECORDING',
  EDIT_CONFERENCE_NAME = 'EDIT_CONFERENCE_NAME',
  EDIT_CONFERENCE_CODE = 'EDIT_CONFERENCE_CODE',
  DIAL_IN = 'DIAL_IN',
  MAX_GRID = 'MAX_GRID',
}

export const LICENSED_FEATURES: Array<[string, FEATURE_NAMES, ...any]> = [
  ['FE1', FEATURE_NAMES.MAX_GUESTS, DEFAULT_MAX_GUESTS],
  ['FE2', FEATURE_NAMES.EDIT_CONFERENCE_NAME],
  ['FE3', FEATURE_NAMES.SESSION_RECORDING],
  ['FE4', FEATURE_NAMES.DIAL_IN],
  ['FE5', FEATURE_NAMES.VIDEO_BROADCAST],
  ['FE6', FEATURE_NAMES.MAX_GRID, DEFAULT_MAX_GRID_MEMBERS],
]
