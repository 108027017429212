import { FEATURE_NAMES } from 'constants/features-map'
import * as LicenseUtils from 'lib/licenses-utils'

import { selectors as flagSelectors } from 'app/features/flags/flags.reducer'

/**
 * Get the max amount of guests from a license
 * @param channelRoom
 * @param flags
 * @return Feature value
 */
function getMaxGuests(channelRoom, flags) {
  const license = LicenseUtils.getCurrentLicense(channelRoom, flags)
  return flagSelectors.getFeatureLicenseValue(
    FEATURE_NAMES.MAX_GUESTS,
    license.id,
    flags
  )
}

/**
 * Get the max grid size from a license
 * @param channelRoom
 * @param flags
 * @return Feature value
 */
function getMaxGridSize(channelRoom, flags) {
  const license = LicenseUtils.getCurrentLicense(channelRoom, flags)
  return flagSelectors.getFeatureLicenseValue(
    FEATURE_NAMES.MAX_GRID,
    license.id,
    flags
  )
}

export default {
  getMaxGuests,
  getMaxGridSize,
}
