import { v4 as uuid } from 'uuid'
import _ from 'lodash'
import dotProp from 'dot-prop-immutable'

export const STORE_NAME = 'feature/grid-view'

const INIT = `sm-web/${STORE_NAME}/INIT`
const ADD = `sm-web/${STORE_NAME}/ADD`
const REMOVE = `sm-web/${STORE_NAME}/REMOVE`
const REPLACE = `sm-web/${STORE_NAME}/REPLACE`
const SWAP = `sm-web/${STORE_NAME}/SWAP`
const PIN = `sm-web/${STORE_NAME}/PIN`
const UNPIN = `sm-web/${STORE_NAME}/UNPIN`
const UPDATE_PINNED_DATA = `sm-web/${STORE_NAME}/UPDATE_PINNED_DATA`
const UPDATE_DATA = `sm-web/${STORE_NAME}/UPDATE_DATA`
const SET_GRID_SIZE = `sm-web/${STORE_NAME}/SET_GRID_SIZE`
const SET_GRID_SIZE_MAX = `sm-web/${STORE_NAME}/SET_GRID_SIZE_MAX`
const SET_GRID_PREVIEW = `sm-web/${STORE_NAME}/SET_GRID_PREVIEW`

export const ACTION_TYPES = {
  INIT,
  ADD,
  REMOVE,
  REPLACE,
  SWAP,
  PIN,
  UNPIN,
  UPDATE_PINNED_DATA,
  UPDATE_DATA,
  SET_GRID_SIZE,
  SET_GRID_SIZE_MAX,
  SET_GRID_PREVIEW,
}

const add = (entry) => ({
  type: ADD,
  payload: { id: uuid(), entry },
})

const remove = (id) => ({
  type: REMOVE,
  payload: { id },
})

const replace = (oldId, entry) => ({
  type: REPLACE,
  payload: { oldId, newId: uuid(), entry },
})

const swap = (idA, idB) => ({
  type: SWAP,
  payload: { idA, idB },
})

const pin = (entry) => ({
  type: PIN,
  payload: { entry },
})

const unpin = () => ({
  type: UNPIN,
})

const updatePinnedData = (data) => ({
  type: UPDATE_PINNED_DATA,
  payload: { data },
})

const updateData = (id, data) => ({
  type: UPDATE_DATA,
  payload: { id, data },
})

const setGridSize = (payload) => ({
  type: SET_GRID_SIZE,
  payload,
})
const setGridSizeMax = (payload) => ({
  type: SET_GRID_SIZE_MAX,
  payload,
})
const setGridPreview = (size) => ({
  type: SET_GRID_PREVIEW,
  size,
})

export const actions = {
  add,
  remove,
  replace,
  swap,
  pin,
  unpin,
  updateData,
  updatePinnedData,
  setGridSize,
  setGridSizeMax,
  setGridPreview,
}

type GridViewEntry = {
  id: string
  type: 'member' | 'stream'
  data: any
}

type GridViewState = {
  entriesById: { [key: string]: GridViewEntry }
  entries: string[]
  pinnedEntry: GridViewEntry
  gridSize: number
  gridSizeMax: number
  previewSize: number | null
}

export const INITIAL_STATE: GridViewState = {
  entries: [],
  entriesById: {},
  pinnedEntry: null,
  gridSize: 1,
  gridSizeMax: 24,
  previewSize: null,
}

/**
 * Action handler
 * @type {{ [key: string]: (state: GridViewState, action: { payload: Object, type: string }) => void }}
 */
const ACTION_HANDLERS = {
  [ADD]: (state, action) => {
    const nextState = JSON.parse(JSON.stringify(state))

    nextState.entries.push(action.payload.id)
    nextState.entriesById[action.payload.id] = action.payload.entry

    return nextState
  },
  [REMOVE]: (state, action) => {
    const nextState = JSON.parse(JSON.stringify(state))

    delete nextState.entriesById[action.payload.id]
    nextState.entries = nextState.entries.filter(
      (id) => id !== action.payload.id
    )

    return nextState
  },
  [REPLACE]: (state, action) => {
    const nextState = JSON.parse(JSON.stringify(state))

    const oldIndex = state.entries.indexOf(action.payload.oldId)

    if (oldIndex >= 0) {
      nextState.entries[oldIndex] = action.payload.newId

      delete nextState.entriesById[action.payload.oldId]
      nextState.entriesById[action.payload.newId] = action.payload.entry
    }

    return nextState
  },
  [SWAP]: (state, action) => {
    const entries = [...state.entries]

    const indexA = entries.indexOf(action.payload.idA)
    const indexB = entries.indexOf(action.payload.idB)

    if (indexA >= 0 && indexB >= 0) {
      const entryA = entries[indexA]
      entries[indexA] = entries[indexB]
      entries[indexB] = entryA
    }

    return { ...state, entries }
  },
  [PIN]: (state, { payload: { entry } }) =>
    dotProp.set(state, 'pinnedEntry', entry),
  [UNPIN]: (state) => dotProp.set(state, 'pinnedEntry', null),
  [UPDATE_DATA]: (state, action) => {
    if (!state.entriesById[action.payload.id]) return state
    return dotProp.set(
      state,
      `entriesById.${action.payload.id}.data`,
      (data) => ({ ...data, ...action.payload.data })
    )
  },
  [UPDATE_PINNED_DATA]: (state, action) => {
    if (!state.pinnedEntry) return state

    return dotProp.set(state, 'pinnedEntry.data', (data) => ({
      ...data,
      ...action.payload.data,
    }))
  },
  [SET_GRID_SIZE]: (state, action) => ({
    ...state,
    gridSize: action.payload,
  }),
  [SET_GRID_SIZE_MAX]: (state, action) => ({
    ...state,
    gridSizeMax: action.payload,
  }),
  [SET_GRID_PREVIEW]: (state, action) => ({
    ...state,
    previewSize: action.size,
  }),
  'sm-web/RESET': () => INITIAL_STATE,
}

export default (state = INITIAL_STATE, action = {}) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export const selectors = {
  /**
   * @param state
   * @return
   */
  getEntries(state) {
    return state.entries
  },
  /**
   * @param state
   * @return
   */
  getEntriesById(state) {
    return state.entriesById
  },
  /**
   * @param state
   * @return
   */
  getPinnedEntry(state) {
    return state.pinnedEntry
  },
  /**
   * @param state
   * @return
   */
  getGridSize(state) {
    return state.gridSize
  },
  /**
   * @param state
   * @return
   */
  getGridSizeMax(state) {
    return state.gridSizeMax
  },
  /**
   * Get the visible entries displayed in the grid.
   * NOTE: When there is a pinned entry, this will be the only entry returned.
   * @param state
   * @return
   */
  getVisibleEntries(state) {
    const pinnedEntry = selectors.getPinnedEntry(state)
    if (pinnedEntry) return [pinnedEntry]
    const entries = selectors.getEntries(state)
    const entriesById = selectors.getEntriesById(state)
    return entries.map((id) => entriesById[id])
  },

  /**
   * @param gridView State of the gridview
   * @param predicate A predicate to match against each entry object in the gridview
   * @return
   */
  getEntryId(state, predicate) {
    const entriesById = selectors.getEntriesById(state)
    return _.findKey(entriesById, predicate)
  },

  /**
   * @param state
   * @return
   */
  isGridFull(state) {
    const gridSize = selectors.getGridSize(state)
    const entries = selectors.getEntries(state)
    return entries.length >= gridSize
  },
  isGridSizeMaxed(state) {
    const gridSize = selectors.getGridSize(state)
    const gridSizeMax = selectors.getGridSizeMax(state)
    return gridSize >= gridSizeMax
  },
  /**
   * @param state
   * @return
   */
  isDragAvailable(state) {
    const pinnedEntry = selectors.getPinnedEntry(state)
    const entries = selectors.getEntries(state)
    if (pinnedEntry) return false
    if (entries.length < 2) return false
    return true
  },
}
