import type { SVGProps } from 'react'
const SvgAgileCard13 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <rect
      width={94.33}
      height={126.14}
      x={33.78}
      y={17.68}
      fill='rgb(var(--color-neutral-50))'
      rx={8.63}
      ry={8.63}
    />
    <path
      fill='rgb(var(--color-brand-500))'
      d='M119.48 145.82H42.41c-5.86 0-10.62-4.77-10.62-10.63V26.31c0-5.86 4.77-10.63 10.62-10.63h77.07c5.86 0 10.63 4.77 10.63 10.63V135.2c0 5.86-4.77 10.63-10.63 10.63zM42.41 19.68c-3.65 0-6.62 2.97-6.62 6.63V135.2c0 3.65 2.97 6.63 6.62 6.63h77.07c3.65 0 6.63-2.97 6.63-6.63V26.31c0-3.65-2.97-6.63-6.63-6.63H42.41z'
    />
    <rect
      width={79.02}
      height={110.67}
      x={41.43}
      y={25.42}
      fill='rgb(var(--color-brand-100))'
      rx={2.78}
      ry={2.78}
    />
    <circle cx={50.06} cy={34.43} r={5.57} fill='rgb(var(--color-brand-200))' />
    <circle
      cx={111.83}
      cy={127.08}
      r={5.57}
      fill='rgb(var(--color-brand-200))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M65.15 103.03c-.69 0-1.25-.56-1.25-1.25V68.85h-6.11c-.69 0-1.25-.56-1.25-1.25v-4.99c0-.69.56-1.25 1.25-1.25 1.88 0 3.4-.16 4.49-.46 1-.28 1.76-.72 2.33-1.34.61-.67 1.17-1.68 1.67-3.01.18-.49.65-.82 1.17-.82h4.74c.69 0 1.25.56 1.25 1.25v44.8c0 .69-.56 1.25-1.25 1.25h-7.04z'
    />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M72.19 56.98v44.8h-7.04V67.6h-7.36v-4.99c2 0 3.62-.17 4.83-.51s2.19-.91 2.91-1.7c.72-.79 1.36-1.93 1.92-3.42h4.74m0-2.5h-4.74c-1.04 0-1.98.65-2.34 1.63-.55 1.47-1.07 2.23-1.42 2.6-.41.44-.98.76-1.75.98-.99.28-2.39.42-4.16.42a2.5 2.5 0 0 0-2.5 2.5v4.99a2.5 2.5 0 0 0 2.5 2.5h4.86v31.68a2.5 2.5 0 0 0 2.5 2.5h7.04a2.5 2.5 0 0 0 2.5-2.5v-44.8a2.5 2.5 0 0 0-2.5-2.5z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M90.87 103.67c-3.8 0-6.75-1.1-8.76-3.28-1.98-2.15-2.99-5.21-2.99-9.1v-3.65c0-.69.56-1.25 1.25-1.25h6.66c.69 0 1.25.56 1.25 1.25v3.97c0 2.78 1.15 3.17 2.4 3.17 1.08 0 1.52-.38 1.76-.67.24-.29.64-1.08.64-3.14v-3.65c0-2.17-.44-3.14-.8-3.58-.47-.57-1.33-.86-2.56-.86h-2.37c-.69 0-1.25-.56-1.25-1.25v-6.4c0-.69.56-1.25 1.25-1.25h2.56c1.05 0 1.81-.24 2.27-.71.46-.47.7-1.43.7-2.78v-3.26c0-1.2-.22-2.08-.65-2.57-.4-.45-.92-.66-1.62-.66-1.04 0-2.14.22-2.14 2.91v2.94c0 .69-.56 1.25-1.25 1.25h-6.66c-.69 0-1.25-.56-1.25-1.25v-2.37c0-3.89 1-6.95 2.99-9.1 2.01-2.18 4.89-3.28 8.57-3.28s6.61 1.09 8.6 3.25c1.96 2.13 2.96 5.18 2.96 9.07v1.6c0 2.63-.46 4.81-1.37 6.46a7.838 7.838 0 0 1-2.31 2.61c1.07.74 1.91 1.69 2.51 2.82.9 1.71 1.36 3.89 1.36 6.47v3.97c0 3.89-1.01 6.95-2.99 9.08-2.01 2.15-4.95 3.25-8.75 3.25z'
    />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M90.88 56.34c3.37 0 5.93.95 7.68 2.85 1.75 1.9 2.62 4.64 2.62 8.22v1.6c0 2.43-.41 4.38-1.22 5.86-.81 1.47-2.07 2.53-3.78 3.17v.13c1.83.68 3.16 1.79 3.97 3.33s1.22 3.5 1.22 5.89v3.97c0 3.58-.89 6.33-2.66 8.22-1.77 1.9-4.38 2.85-7.84 2.85s-6.07-.96-7.84-2.88-2.66-4.67-2.66-8.26v-3.65h6.66v3.97c0 2.94 1.22 4.42 3.65 4.42 1.19 0 2.1-.37 2.72-1.12.62-.75.93-2.06.93-3.94v-3.65c0-2.05-.36-3.51-1.09-4.38-.73-.87-1.9-1.31-3.52-1.31h-2.37v-6.4h2.56c1.41 0 2.46-.36 3.17-1.09.7-.72 1.06-1.94 1.06-3.65v-3.26c0-1.54-.32-2.67-.96-3.39-.64-.72-1.49-1.09-2.56-1.09-2.26 0-3.39 1.39-3.39 4.16v2.94h-6.66v-2.37c0-3.58.89-6.34 2.66-8.26s4.32-2.88 7.65-2.88m0-2.5c-4.04 0-7.23 1.24-9.49 3.69-2.2 2.39-3.32 5.74-3.32 9.95v2.37a2.5 2.5 0 0 0 2.5 2.5h6.66a2.5 2.5 0 0 0 2.5-2.5v-2.94c0-1.66.34-1.66.89-1.66.47 0 .6.15.69.24.03.04.33.42.33 1.74v3.26c0 1.53-.35 1.9-.35 1.91-.2.21-.7.33-1.37.33h-2.56a2.5 2.5 0 0 0-2.5 2.5v6.4a2.5 2.5 0 0 0 2.5 2.5h2.37c.8 0 1.38.15 1.6.41.08.1.51.72.51 2.79v3.65c0 1.85-.35 2.34-.36 2.34-.04.05-.18.21-.79.21-.69 0-1.15 0-1.15-1.92v-3.97a2.5 2.5 0 0 0-2.5-2.5h-6.66a2.5 2.5 0 0 0-2.5 2.5v3.65c0 4.22 1.12 7.56 3.32 9.95 2.25 2.45 5.51 3.69 9.68 3.69s7.41-1.23 9.67-3.64c2.21-2.37 3.33-5.71 3.33-9.93v-3.97c0-2.79-.51-5.16-1.5-7.05-.45-.85-1.01-1.6-1.68-2.26.57-.59 1.06-1.25 1.47-1.99 1.01-1.84 1.53-4.22 1.53-7.06v-1.6c0-4.21-1.11-7.55-3.29-9.92-2.24-2.43-5.44-3.65-9.52-3.65z'
    />
  </svg>
)
export default SvgAgileCard13
