import Button from 'ui/button'

const FormSubmit = (props) => (
  <Button
    color='primary'
    size='large'
    type='submit'
    variant='primary'
    {...props}
  />
)

export default FormSubmit
