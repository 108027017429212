import { Suspense, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import env from 'app/config/env'
import browserUtils from 'lib/browser-utils'
import I18nProvider from 'app/features/provider/i18n-provider.component'
import ThemeProvider from 'app/features/provider/theme-provider.component'
import Loader from 'ui/loader'

import BlockerFormAction from './blocker-form-action.component'
import BlockerForm from './blocker-form.component'

const DEFAULT_PRODUCT_NAME = 'Meetings'

const getRequiredBrowser = (reason: string): string | void => {
  switch (reason) {
    case 'browser':
      if (browserUtils.getOSName() === 'iOS') {
        return 'Safari'
      }
      return 'Google Chrome'

    case 'version':
      return browserUtils.getBrowserName()
    default:
      break
  }
}

type Props = {
  reason: 'browser' | 'version'
  longProductName?: string
  shortProductName?: string
}

const envLongProductName: string =
  env('brand_config.longProductName') || DEFAULT_PRODUCT_NAME

const envShortProductName: string =
  env('brand_config.shortProductName') || DEFAULT_PRODUCT_NAME

const BlockerFormContainer = ({
  reason,
  longProductName = envLongProductName,
  shortProductName = envShortProductName,
}: Props): JSX.Element => {
  const { t } = useTranslation()

  const requiredBrowser = getRequiredBrowser(reason)

  const action = useMemo((): JSX.Element | null => {
    return requiredBrowser === 'Google Chrome' ? (
      <BlockerFormAction
        action='install'
        browser={requiredBrowser}
        url='https://www.google.com/chrome/browser/desktop/index.html'
      />
    ) : null
  }, [t])

  const description = useMemo(() => {
    return t(`BlockerForm.${reason}Desc`, {
      browser: requiredBrowser,
      longProductName,
      shortProductName,
    })
  }, [t])

  return (
    <Suspense fallback={<Loader />}>
      <I18nProvider>
        <ThemeProvider>
          <BlockerForm action={action} description={description} />
        </ThemeProvider>
      </I18nProvider>
    </Suspense>
  )
}

export default BlockerFormContainer
