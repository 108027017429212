import { Box, ButtonBase, type ButtonBaseProps, Grid } from '@mui/material'
import { alpha } from '@mui/material/styles'
import cn from 'clsx'
import { cloneElement } from 'react'
import { Typography } from 'ui/revision'

import { makeStyles } from 'app/ui'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      ...theme.typography.button,
      border: '1px solid currentColor',
      borderRadius: theme.shape.borderRadius,
      boxSizing: 'border-box',
      height: '180px',
      width: '180px',
      transition: theme.transitions.create(
        ['background-color', 'box-shadow', 'border'],
        { duration: theme.transitions.duration.short }
      ),
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: alpha(
          theme.palette.text.primary,
          theme.palette.action.hoverOpacity
        ),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
        '&$disabled': {
          backgroundColor: 'transparent',
        },
      },
      '&$disabled': {
        color: theme.palette.action.disabledContrast,
      },
    },
    iconWrapper: {
      fontSize: theme.spacing(8),
      lineHeight: 1,
    },
    disabled: {},
    overlayComponent: {
      position: 'absolute',
      right: theme.spacing(),
      top: '50%',
      transform: 'translate(50%, -50%)',
    },
  }),
  { name: 'BigButton' }
)

type Props = ButtonBaseProps & {
  Icon: React.ReactNode
  label: string
  overlayComponent: React.ReactElement
  overlayPosition: 'top' | 'bottom'
}

const BigButton = ({
  className,
  disabled,
  Icon,
  label,
  overlayComponent,
  overlayPosition,
  ...baseButtonProps
}: Props) => {
  const classes = useStyles()
  const classNames = cn(classes.root, disabled && classes.disabled, className)

  return (
    <Box component='div' position='relative'>
      <ButtonBase
        className={classNames}
        disabled={disabled}
        {...baseButtonProps}
      >
        <Grid
          container
          alignItems='center'
          direction='column'
          justifyContent='center'
          spacing={2}
        >
          <Grid item className={classes.iconWrapper}>
            {Icon}
          </Grid>
          <Grid item>
            <Typography>{label}</Typography>
          </Grid>
        </Grid>
      </ButtonBase>
      {overlayComponent &&
        cloneElement(overlayComponent, {
          className: cn(overlayComponent.className, classes.overlayComponent),
        })}
    </Box>
  )
}

export default BigButton
