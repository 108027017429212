import type { SVGProps } from 'react'
const SvgEmoji13 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle
      cx={78.86}
      cy={96.28}
      r={48.66}
      fill='rgb(var(--color-brand-100))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M78.86 146.94c-27.93 0-50.66-22.72-50.66-50.66s22.72-50.66 50.66-50.66 50.66 22.72 50.66 50.66-22.72 50.66-50.66 50.66zm0-97.31c-25.73 0-46.66 20.93-46.66 46.66s20.93 46.66 46.66 46.66 46.66-20.93 46.66-46.66-20.93-46.66-46.66-46.66z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M94.73 119.73h-32.1c-1.08 0-1.96-.88-1.96-1.96s.88-1.96 1.96-1.96h32.1c1.08 0 1.96.88 1.96 1.96s-.88 1.96-1.96 1.96z'
    />
    <rect
      width={21.03}
      height={4.95}
      x={41.49}
      y={91.77}
      fill='rgb(var(--color-brand-600))'
      rx={1.51}
      ry={1.51}
    />
    <rect
      width={21.03}
      height={4.95}
      x={95.2}
      y={91.77}
      fill='rgb(var(--color-brand-600))'
      rx={1.51}
      ry={1.51}
    />
  </svg>
)
export default SvgEmoji13
