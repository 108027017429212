import { BehaviorSubject, combineLatest } from 'rxjs'
import { distinctUntilChanged, map } from 'rxjs/operators'

class ColigoTrackProcessor {
  /**
   * @type {BehaviorSubject<Boolean>}
   * @protected
   */
  _enabledSubject

  /**
   * Enabled state from the processor.
   * @type {import('rxjs').Observable<Boolean>}
   * @public
   */
  enabled$

  /**
   * @type {BehaviorSubject<import('lib/rtc/coligo-track').default | null>}
   * @protected
   */
  _inSubject = new BehaviorSubject(null)

  /**
   * Input source of the processor. When disabled, the processor will bypass the
   * processing and directly output the input source.
   * @type {import('rxjs').Observable<import('lib/rtc/coligo-track').default | null>}
   * @public
   */
  in$ = this._inSubject.asObservable()

  /**
   * @type {BehaviorSubject<import('lib/rtc/coligo-track').default | null>}
   * @protected
   */
  _outSubject = new BehaviorSubject(null)

  /**
   * Output of the processor. This is the result of the processing
   * done by the processor.
   * @type {import('rxjs').Observable<import('lib/rtc/coligo-track').default | null>}
   * @public
   */
  out$ = this._outSubject.asObservable()

  /**
   * @type {BehaviorSubject<Boolean>}
   * @protected
   */
  _runningSubject = new BehaviorSubject(false)

  /**
   * Boolean determining whether the processor is actually running his
   * process.
   * True when the processor; is enabled, has an input and an ouput.
   * @type {import('rxjs').Observable<Boolean>}
   * @public
   */
  running$ = this._runningSubject.asObservable()

  /**
   * Subscriptions needed for the processor to work while being applied/enabled.
   * All those subscriptions will be unsubscribed upon the enabled getting updated.
   * @type {import('rxjs').Subscription[]}
   * @protected
   */
  _processingSubscriptions = []

  /**
   * All subscriptions needed to be active all of the time for the processor
   * to work. Those subscriptions only get unsubscribed upon disposing this processor.
   * @type {import('rxjs').Subscription[]}
   * @protected
   */
  _coreSubscriptions = []

  /**
   * @param [enabled=false]
   */
  constructor(enabled = false) {
    this._enabledSubject = new BehaviorSubject(enabled)
    this.enabled$ = this._enabledSubject.asObservable()

    this._coreSubscriptions.push(
      // When the processor is enabled state changes and there is an input track,
      // we unsubscribe all processing subscriptions and bypass the process strategy.
      combineLatest([this.enabled$, this.in$])
        .pipe(
          map(([enabledState, inTrack]) => Boolean(enabledState && inTrack)),
          distinctUntilChanged()
        )
        .subscribe((val) => {
          this._unsubscribeProcessingSubscriptions()

          if (val) {
            this._apply()
          } else {
            this._bypass()
          }
        })
    )

    this._coreSubscriptions.push(
      // When the processor; is enabled, has an input and an output, we
      // consider the processor to be 'running'.
      combineLatest([this.enabled$, this.in$, this.out$])
        .pipe(
          map(([enabledState, inTrack, outTrack]) =>
            Boolean(enabledState && inTrack && outTrack)
          ),
          distinctUntilChanged()
        )
        .subscribe(this._runningSubject)
    )
  }

  /**
   * Set the input source.
   * @param val
   * @returns
   * @public
   */
  setIn(val) {
    this._inSubject.next(val)
  }

  /**
   * Set the enabled state of the processor.
   * When disabled, the processor will bypass the processing and directly
   * output the given input.
   * @param val
   * @returns
   * @public
   */
  setEnabled(val) {
    this._enabledSubject.next(val)
  }

  /**
   * @protected
   */
  _apply() {}

  /**
   * @protected
   */
  _bypass() {
    this._processingSubscriptions.push(this.in$.subscribe(this._outSubject))
  }

  /**
   * @protected
   */
  _unsubscribeProcessingSubscriptions() {
    this._processingSubscriptions.forEach((sub) => sub.unsubscribe())
    this._processingSubscriptions = []
  }

  /**
   * @protected
   */
  _unsubscribeCoreSubscriptions() {
    this._coreSubscriptions.forEach((sub) => sub.unsubscribe())
    this._coreSubscriptions = []
  }

  /**
   * @protected
   */
  dispose() {
    this._unsubscribeProcessingSubscriptions()
    this._unsubscribeCoreSubscriptions()

    this._enabledSubject.complete()
    this._inSubject.complete()
    this._outSubject.complete()
    this._runningSubject.complete()
  }
}

export default ColigoTrackProcessor
