import _ from 'lodash'

import { USER_STATUS } from 'constants/constants'

import {
  getUser,
  isRoomFull,
  getWaitingMembers,
  isDialIn,
  getMemberRoomState,
  wizardReadyPredicate,
} from 'app/state/utils'

import {
  STORE_NAME as CHANNEL_ROOM_STORE_NAME,
  ACTION_TYPES as CHANNEL_ROOM_ACTION_TYPES,
  actions as channelRoomActions,
} from 'app/state/api/channels/channel-room.reducer'
import { STORE_NAME as FLAGS_STORE_NAME } from 'app/features/flags/flags.reducer'

import {
  ACTION_TYPES,
  STORE_NAME,
  actions,
  selectors,
} from './waiting-room.reducer'
import { autoApproveWaitingGuests, putGuestInRoom } from './waiting-room.utils'
import { EVENT_TYPES } from './waiting-room.constants'

export default (store) => (next) => (action) => {
  const { dispatch, getState } = store

  switch (action.type) {
    case CHANNEL_ROOM_ACTION_TYPES.JOINED:
    case ACTION_TYPES.INIT:
      {
        const {
          [CHANNEL_ROOM_STORE_NAME]: channelRoom,
          [STORE_NAME]: waitingRoom,
        } = getState()

        // For each waiting guest we want to add them to the waitingRoom.
        const waitingMemberIds = getWaitingMembers(channelRoom).map(
          (member) => member.member_id
        )
        waitingMemberIds.forEach((memberId) => {
          dispatch(actions.addEvent(memberId, EVENT_TYPES.WAITING))
        })

        const presentationMode = _.get(
          channelRoom,
          'meeting_room.meta.presentationMode'
        )

        // When auto accept is set to true, we approve every waiting guest.
        if (waitingRoom.autoAccept) {
          autoApproveWaitingGuests(waitingMemberIds, store)
          // When it is false but presentationMode is set true, enable auto accept.
        } else if (presentationMode) {
          dispatch(actions.setAutoAccept(true))
        }
      }
      break

    case ACTION_TYPES.SET_AUTO_ACCEPT:
      // When auto accept is set to true, we approve every waiting guest.
      if (action.payload) {
        const { [STORE_NAME]: waitingRoom } = getState()

        const waitingEvents = selectors
          .getWaitingEvents(waitingRoom)
          .map((event) => event.memberId)

        autoApproveWaitingGuests(waitingEvents, store)
      }
      /* We store autoAccept in the room meta so a guest client knows
      if it should display the waiting room in guest setup */
      dispatch(
        channelRoomActions.updateRoomMeta({ autoAccept: action.payload })
      )
      break

    case CHANNEL_ROOM_ACTION_TYPES.UPDATED_MEMBER_META:
      if (_.isMatch(action, wizardReadyPredicate)) {
        const {
          [CHANNEL_ROOM_STORE_NAME]: channelRoom,
          [FLAGS_STORE_NAME]: flags,
          [STORE_NAME]: waitingRoom,
        } = getState()

        dispatch(actions.addEvent(action.memberId, EVENT_TYPES.WAITING))

        if (waitingRoom.autoAccept && !isRoomFull(channelRoom, flags)) {
          const member = getUser(channelRoom, action.memberId)

          dispatch(putGuestInRoom(action.memberId, member.meta.type))
        }
      }
      break

    case CHANNEL_ROOM_ACTION_TYPES.MEMBER_DELETE:
      {
        const {
          [CHANNEL_ROOM_STORE_NAME]: channelRoom,
          [FLAGS_STORE_NAME]: flags,
          [STORE_NAME]: waitingRoom,
        } = getState()

        switch (
          getMemberRoomState(getUser(channelRoom, action.payload.member_id))
        ) {
          case USER_STATUS.IN_ROOM:
            /**
             * When the user was in the room, autoAccept is true and the room was full,
             * we put the first waiting guest in the room.
             */
            if (waitingRoom.autoAccept && isRoomFull(channelRoom, flags)) {
              const waitingGuests = selectors
                .getWaitingEvents(waitingRoom)
                .map((e) => getUser(channelRoom, e.memberId))

              if (waitingGuests.length) {
                dispatch(
                  putGuestInRoom(
                    waitingGuests[0].member_id,
                    waitingGuests[0].meta.type
                  )
                )
              }
            }
            break
          case USER_STATUS.WAITING:
          case USER_STATUS.SETUP:
          default: {
            /**
             * only dispatch an action is the member was actually visible in the waiting room
             * because if so we want to dispatch an action to update the message
             */
            const waitingMemberIds = selectors
              .getWaitingEvents(waitingRoom)
              .map((e) => e.memberId)
            if (waitingMemberIds.includes(action.payload.member_id)) {
              const member = getUser(channelRoom, action.payload.member_id)
              if (action.payload.reason === 'kicked') {
                dispatch(
                  actions.deniedGuest(action.payload.member_id, {
                    ...member.meta,
                    modified: Date.now(),
                  })
                )
              } else {
                dispatch(
                  actions.leftGuest(action.payload.member_id, {
                    ...member.meta,
                    modified: Date.now(),
                  })
                )
              }
            }
            break
          }
        }
      }
      break

    case CHANNEL_ROOM_ACTION_TYPES.UPDATED_ROOM_META:
      if (_.has(action.payload, 'presentationMode')) {
        dispatch(actions.setAutoAccept(action.payload.presentationMode))
      }
      break

    default:
  }

  const nextState = next(action)

  switch (action.type) {
    case CHANNEL_ROOM_ACTION_TYPES.MEMBER_CREATE:
      {
        const { payload: member } = action
        const {
          [CHANNEL_ROOM_STORE_NAME]: channelRoom,
          [FLAGS_STORE_NAME]: flags,
          [STORE_NAME]: waitingRoom,
        } = getState()

        const isDialinUser = isDialIn(member)
        const isGuestWaiting =
          isDialinUser || _.isMatch(member, wizardReadyPredicate)

        if (isGuestWaiting) {
          dispatch(actions.addEvent(member.member_id, EVENT_TYPES.WAITING))

          /**
           * We approve the waiting guest when auto accept is on and there is room left in the room.
           * NOTE: If the waiting guest is dialing in, we disregard the room full rule.
           */
          if (
            waitingRoom.autoAccept &&
            (isDialinUser || !isRoomFull(channelRoom, flags))
          ) {
            dispatch(putGuestInRoom(member.member_id, member.meta.type))
          }
        }
      }
      break

    case CHANNEL_ROOM_ACTION_TYPES.UPDATED_MEMBER_META:
      {
        const { [CHANNEL_ROOM_STORE_NAME]: channelRoom } = getState()
        const member = getUser(channelRoom, action.memberId)

        if (action.meta.status === USER_STATUS.IN_ROOM) {
          dispatch(actions.joinedGuest(action.memberId, member.meta))
        }
      }
      break

    case CHANNEL_ROOM_ACTION_TYPES.CLOSED:
      dispatch(actions.reset())
      break

    default:
  }

  return nextState
}
