import type { SVGProps } from 'react'
const SvgEmoji11 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle
      cx={79.45}
      cy={94.42}
      r={48.66}
      fill='rgb(var(--color-brand-100))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.45 145.08c-27.93 0-50.66-22.72-50.66-50.66s22.72-50.66 50.66-50.66 50.66 22.72 50.66 50.66-22.72 50.66-50.66 50.66zm0-97.31c-25.73 0-46.66 20.93-46.66 46.66s20.93 46.66 46.66 46.66 46.66-20.93 46.66-46.66-20.93-46.66-46.66-46.66z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.45 120.69c-4.32 0-8.4-1.63-11.2-4.48-.71-.72-.7-1.87.02-2.58s1.87-.7 2.58.02c2.12 2.15 5.25 3.39 8.6 3.39s6.48-1.23 8.6-3.39a1.82 1.82 0 0 1 2.58-.02c.72.71.73 1.86.02 2.58-2.8 2.85-6.88 4.48-11.2 4.48z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M57.98 106c-1.55 0-3.14-.19-4.71-.58-4.41-1.08-8.23-3.95-10.46-7.88-2.6-4.57-3.24-9.61-1.84-14.35-.39-.29-.71-.67-.93-1.12-.56-1.13-.43-2.45.37-3.64.3-.45.77-.76 1.32-.86C51 75.81 64.75 74.8 79.45 74.8s28.45 1.01 37.72 2.77c.53.1.99.4 1.29.83.82 1.19.96 2.58.36 3.71-.34.65-.88 1.15-1.52 1.44.6 2.2.76 4.51.49 6.78-1.09 8.92-8.67 15.65-17.65 15.65-3.17 0-6.26-.82-9.21-2.45-3.69-2.04-6.51-5.53-7.74-9.58-1.32-4.35-1.13-8.78.53-12.7a308.224 308.224 0 0 0-9.26.01c.64 1.52 1.07 3.11 1.27 4.75.62 5.06-.97 10.14-4.35 13.96-3.38 3.81-8.26 6-13.4 6z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.45 76.3c14.56 0 28.27 1 37.44 2.75.14.03.26.1.34.21 1.12 1.61.04 3.09-1.26 3.09-.23 0-.46-.04-.69-.14a16.2 16.2 0 0 1 1.03 7.95c-1.01 8.27-8.04 14.34-16.16 14.34-2.77 0-5.66-.7-8.48-2.26-3.38-1.86-5.91-5.01-7.03-8.7-1.55-5.09-.78-9.77 1.38-13.49.06-.1-.01-.23-.13-.23-2.12-.04-4.26-.07-6.43-.07-2.41 0-4.8.03-7.14.08-.12 0-.19.13-.13.23 1.06 1.84 1.79 3.92 2.06 6.16 1.17 9.61-6.27 18.28-16.26 18.28-1.4 0-2.86-.17-4.35-.54-4.01-.98-7.47-3.57-9.51-7.16-2.83-4.97-2.94-10.12-1.27-14.46-1.26-.06-2.26-1.48-1.19-3.07.08-.12.21-.2.36-.23 9.17-1.74 22.88-2.75 37.44-2.75m0-3c-14.79 0-28.64 1.02-38 2.8-.94.18-1.75.71-2.28 1.49-1.1 1.62-1.27 3.55-.47 5.15.16.33.36.63.59.91-1.19 4.87-.44 9.98 2.21 14.64 2.44 4.28 6.6 7.41 11.41 8.59 1.69.41 3.39.62 5.06.62 5.57 0 10.86-2.37 14.52-6.5 3.66-4.14 5.38-9.65 4.72-15.14-.13-1.06-.34-2.09-.64-3.1.96 0 1.92-.01 2.87-.01h2.16c-1.08 3.7-1.05 7.71.15 11.66 1.34 4.42 4.42 8.23 8.45 10.45 3.17 1.75 6.51 2.63 9.93 2.63 9.73 0 17.96-7.3 19.14-16.97.25-2.09.16-4.22-.27-6.28.46-.39.85-.87 1.14-1.42.86-1.64.69-3.61-.46-5.27a3.489 3.489 0 0 0-2.24-1.45c-9.37-1.78-23.22-2.8-38.01-2.8z'
    />
  </svg>
)
export default SvgEmoji11
