import type { SVGProps } from 'react'
const SvgAgileCard00Questionmark = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <rect
      width={94.33}
      height={126.14}
      x={33.78}
      y={17.68}
      fill='rgb(var(--color-neutral-50))'
      rx={8.63}
      ry={8.63}
    />
    <path
      fill='rgb(var(--color-brand-500))'
      d='M119.48 145.82H42.41c-5.86 0-10.63-4.77-10.63-10.63V26.31c0-5.86 4.77-10.63 10.63-10.63h77.07c5.86 0 10.63 4.77 10.63 10.63V135.2c0 5.86-4.77 10.63-10.63 10.63zM42.41 19.68c-3.65 0-6.63 2.97-6.63 6.63V135.2c0 3.65 2.97 6.63 6.63 6.63h77.07c3.65 0 6.63-2.97 6.63-6.63V26.31c0-3.65-2.97-6.63-6.63-6.63H42.41z'
    />
    <rect
      width={79.02}
      height={110.67}
      x={41.43}
      y={25.42}
      fill='rgb(var(--color-brand-100))'
      rx={2.78}
      ry={2.78}
    />
    <circle cx={50.06} cy={34.43} r={5.57} fill='rgb(var(--color-brand-200))' />
    <circle
      cx={111.83}
      cy={127.08}
      r={5.57}
      fill='rgb(var(--color-brand-200))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.09 103.03c-1.65 0-3-1.35-3-3v-3.28c0-1.65 1.35-3 3-3h3.28c1.65 0 3 1.35 3 3v3.28c0 1.65-1.35 3-3 3h-3.28zm-1.43-11.84c-.69 0-1.25-.56-1.25-1.25v-2.69c0-1.71.27-3.36.8-4.92.53-1.53 1.5-3.31 2.88-5.27 1.46-2.07 2.46-3.86 2.98-5.33.51-1.44.76-2.98.76-4.58s-.33-2.23-.6-2.55c-.22-.26-.64-.61-1.67-.61-1.15 0-1.54.46-1.67.61-.27.32-.6 1.03-.6 2.55v4.86c0 .69-.56 1.25-1.25 1.25h-6.66c-.69 0-1.25-.56-1.25-1.25v-4.42c0-3.93.99-7.02 2.95-9.19 1.99-2.21 4.88-3.33 8.61-3.33s6.69 1.12 8.7 3.32c1.98 2.17 2.98 5.27 2.98 9.2 0 2.11-.38 4.17-1.14 6.12-.74 1.91-2.01 4.07-3.78 6.41-1.3 1.74-2.21 3.2-2.7 4.33-.46 1.05-.69 2.08-.69 3.05v2.43c0 .69-.56 1.25-1.25 1.25h-6.14z'
    />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M81.69 56.34c3.41 0 6.01.97 7.78 2.91 1.77 1.94 2.66 4.73 2.66 8.35 0 1.96-.35 3.85-1.06 5.66-.7 1.81-1.91 3.85-3.62 6.11-1.37 1.83-2.32 3.36-2.85 4.58s-.8 2.4-.8 3.55v2.43h-6.14v-2.69c0-1.58.25-3.08.74-4.51.49-1.43 1.4-3.08 2.72-4.96 1.54-2.18 2.58-4.05 3.14-5.63.55-1.58.83-3.24.83-4.99 0-1.54-.3-2.66-.9-3.36s-1.47-1.06-2.62-1.06-2.03.35-2.62 1.06-.9 1.82-.9 3.36v4.86h-6.66v-4.42c0-3.63.87-6.41 2.62-8.35 1.75-1.94 4.31-2.91 7.68-2.91m.68 38.66c.97 0 1.75.78 1.75 1.75v3.28c0 .97-.78 1.75-1.75 1.75h-3.28c-.97 0-1.75-.78-1.75-1.75v-3.28c0-.97.78-1.75 1.75-1.75h3.28m-.68-41.16c-4.09 0-7.3 1.26-9.54 3.74-2.17 2.41-3.27 5.78-3.27 10.03v4.42a2.5 2.5 0 0 0 2.5 2.5h6.66a2.5 2.5 0 0 0 2.5-2.5v-4.86c0-1.37.3-1.74.3-1.74.13-.15.5-.17.72-.17s.59.02.72.17c0 0 .3.37.3 1.74 0 1.46-.23 2.86-.69 4.16-.48 1.35-1.42 3.04-2.82 5.02-1.48 2.09-2.47 3.92-3.04 5.59-.58 1.69-.87 3.48-.87 5.32v2.69a2.5 2.5 0 0 0 2.5 2.5h6.14a2.5 2.5 0 0 0 2.5-2.5v-2.43c0-.8.2-1.66.59-2.55.46-1.04 1.32-2.41 2.56-4.09 1.85-2.46 3.15-4.65 3.94-6.7.81-2.1 1.23-4.31 1.23-6.57 0-4.25-1.11-7.63-3.31-10.04-2.26-2.47-5.5-3.73-9.62-3.73zm.68 38.66h-3.28a4.26 4.26 0 0 0-4.25 4.25v3.28a4.26 4.26 0 0 0 4.25 4.25h3.28a4.26 4.26 0 0 0 4.25-4.25v-3.28a4.26 4.26 0 0 0-4.25-4.25z'
    />
  </svg>
)
export default SvgAgileCard00Questionmark
