import type { SVGProps } from 'react'
const SvgEmoji5 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle cx={79.4} cy={96.28} r={48.66} fill='rgb(var(--color-brand-100))' />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.4 146.94c-27.93 0-50.66-22.72-50.66-50.66S51.46 45.62 79.4 45.62s50.66 22.72 50.66 50.66-22.72 50.66-50.66 50.66zm0-97.31c-25.73 0-46.66 20.93-46.66 46.66s20.93 46.66 46.66 46.66 46.66-20.93 46.66-46.66-20.93-46.66-46.66-46.66z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M90.85 94.21a1.82 1.82 0 0 1-1.3-3.1c3.32-3.38 8.18-5.32 13.32-5.32s9.99 1.94 13.32 5.32c.71.72.7 1.87-.02 2.58s-1.87.7-2.58-.02c-2.64-2.69-6.55-4.23-10.72-4.23s-8.07 1.54-10.72 4.23c-.36.36-.83.55-1.3.55z'
    />
    <circle
      cx={52.65}
      cy={91.3}
      r={11.99}
      fill='rgb(var(--color-neutral-50))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M52.65 104.54c-7.3 0-13.24-5.94-13.24-13.24s5.94-13.25 13.24-13.25 13.24 5.94 13.24 13.25-5.94 13.24-13.24 13.24zm0-23.99c-5.92 0-10.74 4.82-10.74 10.75s4.82 10.74 10.74 10.74 10.74-4.82 10.74-10.74-4.82-10.75-10.74-10.75z'
    />
    <circle cx={52.65} cy={91.3} r={3.1} fill='rgb(var(--color-brand-700))' />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M146.97 82.78c-1.41 4.46-4.9 8-9.33 9.48-.6.2-.6 1.04 0 1.24 4.43 1.48 7.92 5.02 9.33 9.48.19.6 1.04.6 1.23 0 1.41-4.46 4.9-8 9.33-9.48.6-.2.6-1.04 0-1.24a14.695 14.695 0 0 1-9.33-9.48c-.19-.6-1.04-.6-1.23 0z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M147.59 104.93c-.94 0-1.76-.6-2.05-1.5-1.27-4-4.4-7.18-8.38-8.51-.88-.29-1.48-1.12-1.48-2.04s.59-1.75 1.48-2.04c3.98-1.33 7.11-4.51 8.38-8.51.29-.9 1.11-1.5 2.05-1.5.94 0 1.76.6 2.05 1.5 1.27 4 4.4 7.18 8.38 8.51.88.3 1.48 1.12 1.48 2.04s-.59 1.75-1.48 2.04a13.212 13.212 0 0 0-8.38 8.51c-.28.89-1.11 1.5-2.04 1.5zm-7.51-12.05c3.28 1.61 5.94 4.29 7.51 7.59 1.57-3.3 4.23-5.98 7.51-7.59a16.258 16.258 0 0 1-7.51-7.59c-1.57 3.3-4.23 5.98-7.51 7.59zm6.7-9.64z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.39 120.69c-4.32 0-8.4-1.63-11.2-4.48-.71-.72-.7-1.87.02-2.58s1.87-.7 2.58.02c2.12 2.15 5.25 3.39 8.6 3.39s6.48-1.23 8.6-3.39a1.82 1.82 0 0 1 2.58-.02c.72.71.73 1.86.02 2.58-2.8 2.85-6.88 4.48-11.2 4.48z'
    />
  </svg>
)
export default SvgEmoji5
