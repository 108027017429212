import type { SVGProps } from 'react'
const SvgAgileCard40 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <rect
      width={94.33}
      height={126.14}
      x={33.78}
      y={17.68}
      fill='rgb(var(--color-neutral-50))'
      rx={8.63}
      ry={8.63}
    />
    <path
      fill='rgb(var(--color-brand-500))'
      d='M119.48 145.82H42.41c-5.86 0-10.62-4.77-10.62-10.63V26.31c0-5.86 4.77-10.63 10.62-10.63h77.07c5.86 0 10.63 4.77 10.63 10.63V135.2c0 5.86-4.77 10.63-10.63 10.63zM42.41 19.68c-3.65 0-6.62 2.97-6.62 6.63V135.2c0 3.65 2.97 6.63 6.62 6.63h77.07c3.65 0 6.63-2.97 6.63-6.63V26.31c0-3.65-2.97-6.63-6.63-6.63H42.41z'
    />
    <rect
      width={79.02}
      height={110.67}
      x={41.43}
      y={25.42}
      fill='rgb(var(--color-brand-100))'
      rx={2.78}
      ry={2.78}
    />
    <circle cx={50.06} cy={34.43} r={5.57} fill='rgb(var(--color-brand-200))' />
    <circle
      cx={111.83}
      cy={127.08}
      r={5.57}
      fill='rgb(var(--color-brand-200))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M69.14 103.03c-.69 0-1.25-.56-1.25-1.25V94.9H55.7c-.69 0-1.25-.56-1.25-1.25v-6.4c0-.17.03-.33.1-.49l12.8-30.27c.2-.46.65-.76 1.15-.76h7.68c.69 0 1.25.56 1.25 1.25V86h2.08c.69 0 1.25.56 1.25 1.25v6.4c0 .69-.56 1.25-1.25 1.25h-2.08v6.88c0 .69-.56 1.25-1.25 1.25h-7.04zM67.89 86v-9.99L63.94 86h3.95z'
    />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M76.18 56.98v30.27h3.33v6.4h-3.33v8.13h-7.04v-8.13H55.7v-6.4l12.8-30.27h7.68M62.1 87.25h7.04V69.78h-.13L62.1 87.25m14.08-32.77H68.5c-1 0-1.91.6-2.3 1.53L53.4 86.28c-.13.31-.2.64-.2.97v6.4a2.5 2.5 0 0 0 2.5 2.5h10.94v5.63a2.5 2.5 0 0 0 2.5 2.5h7.04a2.5 2.5 0 0 0 2.5-2.5v-5.63h.83a2.5 2.5 0 0 0 2.5-2.5v-6.4a2.5 2.5 0 0 0-2.5-2.5h-.83V56.98a2.5 2.5 0 0 0-2.5-2.5zm-10.4 30.27.86-2.18v2.18h-.86z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M94.14 103.67c-3.8 0-6.78-1.12-8.85-3.34-2.05-2.19-3.09-5.28-3.09-9.17V67.61c0-3.89 1.04-6.98 3.09-9.17 2.07-2.22 5.05-3.34 8.85-3.34s6.78 1.12 8.85 3.34c2.05 2.19 3.09 5.28 3.09 9.17v23.55c0 3.89-1.04 6.98-3.09 9.17-2.07 2.22-5.05 3.34-8.85 3.34zm0-39.68c-1.25 0-2.4.38-2.4 3.17v24.45c0 2.78 1.15 3.17 2.4 3.17s2.4-.38 2.4-3.17V67.16c0-2.78-1.15-3.17-2.4-3.17z'
    />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M94.14 56.34c3.46 0 6.1.98 7.94 2.94 1.83 1.96 2.75 4.74 2.75 8.32v23.55c0 3.58-.92 6.36-2.75 8.32s-4.48 2.94-7.94 2.94-6.1-.98-7.94-2.94c-1.83-1.96-2.75-4.74-2.75-8.32V67.6c0-3.58.92-6.36 2.75-8.32s4.48-2.94 7.94-2.94m0 39.68c2.43 0 3.65-1.47 3.65-4.42V67.15c0-2.94-1.22-4.42-3.65-4.42s-3.65 1.47-3.65 4.42V91.6c0 2.94 1.22 4.42 3.65 4.42m0-42.18c-4.16 0-7.44 1.26-9.76 3.74-2.27 2.43-3.43 5.81-3.43 10.03v23.55c0 4.22 1.15 7.6 3.43 10.03 2.32 2.48 5.6 3.74 9.76 3.74s7.45-1.26 9.76-3.74c2.27-2.43 3.43-5.81 3.43-10.03V67.61c0-4.22-1.15-7.59-3.43-10.03-2.32-2.48-5.6-3.74-9.76-3.74zm0 39.68c-.69 0-1.15 0-1.15-1.92V67.15c0-1.92.46-1.92 1.15-1.92s1.15 0 1.15 1.92V91.6c0 1.92-.46 1.92-1.15 1.92z'
    />
  </svg>
)
export default SvgAgileCard40
