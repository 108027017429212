import { useContext } from 'react'

import { IdContext } from '../../features/provider/id-provider.component'

function useIdContext() {
  return useContext(IdContext)
}

function useId() {
  const idContext = useIdContext()
  return {
    id: idContext.getId,
  }
}

export { useIdContext, useId }
