import cn from 'clsx'
import type { ForwardedRef, Ref } from 'react'
import { forwardRef, useCallback, useState } from 'react'

import { useVideoSize } from 'lib/react/hooks/element-hooks'
import useForkRef from 'lib/react/hooks/use-fork-ref.hook'
import { Fade, Loader, Video } from 'ui/revision'

import { type VideoStreamProps, VideoStreamState } from './video-stream.types'

import styles from './video-stream.module.scss'

const duration = 300

export const VideoStream = forwardRef(function VideoStream(
  {
    LoaderComponent = Loader,
    LoaderProps = {},
    VideoProps = {},
    className,
    objectFit,
    srcObject = null,
    thumbnail = undefined,
    ...other
  }: VideoStreamProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const [state, setState] = useState(VideoStreamState.Idle)

  const videoRef = useForkRef<HTMLVideoElement>(
    // Little hack to prevent passing an undefined ref to the fork method
    ...([VideoProps.ref].filter(Boolean) as Array<Ref<HTMLVideoElement>>)
  )

  const size = useVideoSize(videoRef)

  const isPortrait = size.width < size.height

  const handleVideoEmptied = useCallback(
    (e) => {
      setState(VideoStreamState.Idle)

      if (VideoProps.onEmptiedCapture) {
        VideoProps.onEmptiedCapture(e)
      }
    },
    // We don't need to re-run when the VideoProps value itself changes.
    // Instead only when the callback value changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [VideoProps.onEmptiedCapture]
  )

  const handleVideoLoadStart = useCallback(
    (e) => {
      setState(VideoStreamState.Loading)

      if (VideoProps.onLoadStartCapture) {
        VideoProps.onLoadStartCapture(e)
      }
    },
    // We don't need to re-run when the VideoProps value itself changes.
    // Instead only when the callback value changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [VideoProps.onLoadStartCapture]
  )

  const handleVideoLoadedData = useCallback(
    (e) => {
      setState(VideoStreamState.Loaded)

      if (VideoProps.onLoadedDataCapture) {
        VideoProps.onLoadedDataCapture(e)
      }
    },
    // We don't need to re-run when the VideoProps value itself changes.
    // Instead only when the callback value changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [VideoProps.onLoadedDataCapture]
  )

  if (LoaderComponent === Loader) {
    LoaderProps.indeterminate ??= true
    LoaderProps.thickness ??= 3.6
  }

  return (
    <div className={cn(styles.root, className)} ref={ref} {...other}>
      <Fade in={state === VideoStreamState.Idle} timeout={duration}>
        <div className={styles.thumbnail}>{thumbnail}</div>
      </Fade>

      <Fade in={state === VideoStreamState.Loading} timeout={duration}>
        <div className={styles.loader}>
          <LoaderComponent {...LoaderProps} />
        </div>
      </Fade>

      <Fade in={state === VideoStreamState.Loaded} timeout={duration}>
        <Video
          {...VideoProps}
          autoPlay
          muted
          playsInline
          className={cn(
            styles.video,
            styles[`video--${objectFit || (isPortrait ? 'contain' : 'cover')}`],
            VideoProps.className
          )}
          ref={videoRef}
          srcObject={srcObject}
          onEmptiedCapture={handleVideoEmptied}
          onLoadStartCapture={handleVideoLoadStart}
          // Using the event callback in the capture phase, fixes an issue where
          // on build and deployed versions, the loadeddata event wont fire.
          onLoadedDataCapture={handleVideoLoadedData}
        />
      </Fade>
    </div>
  )
})

export default VideoStream
