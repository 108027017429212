import ClickAwayListener from '@mui/material/ClickAwayListener'
import { EditorState, Modifier } from 'draft-js'
import { Picker } from 'emoji-mart'
import { useCallback, useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'

import { useId } from 'app/react/hooks/id-registry.hooks'
import { makeStyles } from 'app/ui'
import { FONTS } from 'constants/constants'
import { Emoji } from 'ui/icon-button'

import 'emoji-mart/css/emoji-mart.css'

const useStyles = makeStyles((theme) => ({
  picker: {
    position: 'fixed',
    // 12px is the padding of the IconButton component from MUI.
    // It is not available in the theme as a variable.
    transform: 'translateY(calc(-100% - 12px))',
    zIndex: theme.zIndex.tooltip,

    '& > .emoji-mart': {
      border: '1px solid rgba(var(--color-neutral-100), var(--opacity-3))',
      borderRadius: 'var(--border-radius-interactive-small)',
      fontFamily: `${FONTS.emojiFont}, var(--font-family-ui)`,

      '& .emoji-mart-emoji': {
        fontFamily: `${FONTS.emojiFont}, var(--font-family-ui)`,
      },

      '@supports (backdrop-filter: none)': {
        backdropFilter: 'blur(8px)',
        backgroundColor: 'rgba(var(--color-neutral-900), 0.8)',
      },

      '@supports not (backdrop-filter: none)': {
        backgroundColor: 'rgb(var(--color-neutral-900))',
      },

      '& > .emoji-mart-bar': {
        // Styles the icons at the top
        '& > .emoji-mart-anchors > .emoji-mart-anchor > .emoji-mart-anchor-icon > svg':
          {
            fill: 'rgb(var(--color-neutral-300))',
          },
        // Styles the name of the emoji at the bottom
        '& > .emoji-mart-preview > .emoji-mart-preview-data > .emoji-mart-preview-name':
          {
            color: 'rgb(var(--color-neutral-50))',
          },
      },
      // Styles the category labels in the emojis overview
      '& > .emoji-mart-scroll > .emoji-mart-category > .emoji-mart-category-label > span':
        {
          color: 'white',
          padding: 'var(--spacing-1)',
          backgroundColor: 'rgba(var(--color-neutral-900), var(--opacity-0))',

          '&::before': {
            background: 'rgba(var(--color-neutral-900), 0.9)',
            content: '""',
            filter: 'blur(2px)',
            inset: 0,
            position: 'absolute',
            zIndex: -1,
          },
        },
    },
  },
}))

function addEmoji(editorState, emoji) {
  const contentState = editorState.getCurrentContent()
  const contentStateWithEntity = contentState.createEntity(
    'emoji',
    'IMMUTABLE',
    { emojiUnicode: emoji }
  )

  // in case text is selected it is removed and then the emoji is added
  const afterRemovalContentState = Modifier.removeRange(
    contentState,
    editorState.getSelection(),
    'backward'
  )

  // deciding on the position to insert emoji
  const targetSelection = afterRemovalContentState.getSelectionAfter()

  let emojiAddedContent = Modifier.insertText(
    afterRemovalContentState,
    targetSelection,
    emoji,
    null,
    contentStateWithEntity.getLastCreatedEntityKey()
  )

  const blockKey = targetSelection.getAnchorKey()
  const blockSize = contentState.getBlockForKey(blockKey).getLength()

  // If the emoji is inserted at the end, a space is appended right after for
  // a smooth writing experience.
  if (targetSelection.getAnchorOffset() === blockSize) {
    emojiAddedContent = Modifier.insertText(
      emojiAddedContent,
      emojiAddedContent.getSelectionAfter(),
      ' '
    )
  }

  return EditorState.forceSelection(
    EditorState.push(editorState, emojiAddedContent, 'insert-emoji'),
    emojiAddedContent.getSelectionAfter()
  )
}

type Props = {
  disabled?: boolean
  store: {
    getEditorState: () => EditorState
    setEditorState: (editorState: EditorState) => void
  }
  width?: number
}
const ChatInputEmojiPicker: React.FC<Props> = ({
  disabled,
  store,
  width,
}: Props) => {
  const [open, setOpen] = useState(false)
  const [position, setPosition] = useState({ left: 0, top: 0 })
  const { id } = useId()
  const { t } = useTranslation()
  const classes = useStyles()

  // Width is clamped based on the remaining horizontal space between the
  // left position of the picker and the right end of the window.
  const clampedWidth =
    typeof width !== 'undefined'
      ? Math.min(width, window.innerWidth - position.left)
      : undefined

  useEffect(() => {
    if (disabled && open) setOpen(false)
  }, [disabled, open])

  const handleTogglePopover = useCallback(
    (e) => {
      const { left, top } = e.target.getBoundingClientRect()
      setPosition({ left, top })

      setOpen(!open)
    },
    [open]
  )

  const handleSelect = useCallback(
    (emoji) => {
      store.setEditorState(addEmoji(store.getEditorState(), emoji.native))
    },
    [store]
  )

  const i18n = useMemo(
    () => ({
      search: t('EmojiPicker.search'),
      notfound: t('EmojiPicker.notfound'),
      skintext: t('EmojiPicker.skintext'),
      categories: {
        search: t('EmojiPicker.Categories.search'),
        recent: t('EmojiPicker.Categories.recent'),
        people: t('EmojiPicker.Categories.people'),
        nature: t('EmojiPicker.Categories.nature'),
        foods: t('EmojiPicker.Categories.foods'),
        activity: t('EmojiPicker.Categories.activity'),
        places: t('EmojiPicker.Categories.places'),
        objects: t('EmojiPicker.Categories.objects'),
        symbols: t('EmojiPicker.Categories.symbols'),
        flags: t('EmojiPicker.Categories.flags'),
        custom: t('EmojiPicker.Categories.custom'),
      },
    }),
    [t]
  )

  return (
    <>
      <Emoji
        data-testid={id('ChatInput.emojiPicker')}
        disabled={disabled}
        variant='tertiary'
        onClick={handleTogglePopover}
      />
        
      {open &&
        ReactDOM.createPortal(
          <div className={classes.picker} style={position}>
            <ClickAwayListener onClickAway={handleTogglePopover}>
              <Picker
                native
                emojiSize={20}
                i18n={i18n}
                set='emojione'
                sheetSize={64}
                style={{ width: clampedWidth }}
                onClick={handleSelect}
              />
            </ClickAwayListener>
          </div>,

          document.body
        )}
    </>
  )
}

export default ChatInputEmojiPicker
