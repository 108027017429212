import { groupDevicesByKind, compareDevices } from 'lib/rtc/devices'

export const STORE_NAME = 'feature/devices'

const ACKNOWLEDGE_NO_DEVICE = `sm-web/${STORE_NAME}/ACKNOWLEDGE_NO_DEVICE`
const CAM_PERMISSIONS = `sm-web/${STORE_NAME}/CAM_PERMISSIONS`
const MIC_PERMISSIONS = `sm-web/${STORE_NAME}/MIC_PERMISSIONS`
const SET_HAS_AUDIO = `sm-web/${STORE_NAME}/SET_HAS_AUDIO`
const SET_HAS_VIDEO = `sm-web/${STORE_NAME}/SET_HAS_VIDEO`
const UPDATE_DEVICE_LIST = `sm-web/${STORE_NAME}/UPDATE_DEVICE_LIST`

const CHOOSE_DEVICES = `sm-web/${STORE_NAME}/CHOOSE_DEVICES`
const CAM_DEVICE_MISSING = `sm-web/${STORE_NAME}/CAM_DEVICE_MISSING`
const MIC_DEVICE_MISSING = `sm-web/${STORE_NAME}/MIC_DEVICE_MISSING`
const OUT_DEVICE_MISSING = `sm-web/${STORE_NAME}/OUT_DEVICE_MISSING`

const GUM_ERROR = `sm-web/${STORE_NAME}/GUM_ERROR`
const RESET_GUM_ERROR = `sm-web/${STORE_NAME}/RESET_GUM_ERROR`

export const ACTION_TYPES = {
  ACKNOWLEDGE_NO_DEVICE,
  CAM_PERMISSIONS,
  MIC_PERMISSIONS,
  SET_HAS_AUDIO,
  SET_HAS_VIDEO,
  UPDATE_DEVICE_LIST,

  CHOOSE_DEVICES,
  CAM_DEVICE_MISSING,
  MIC_DEVICE_MISSING,
  OUT_DEVICE_MISSING,

  GUM_ERROR,
  RESET_GUM_ERROR,
}

// ------------------------------------
// Actions
// ------------------------------------
const acknowledgeNoDevices = () => ({ type: ACKNOWLEDGE_NO_DEVICE })

const setMicPermissions = (payload = false) => ({
  type: MIC_PERMISSIONS,
  payload,
})

const setCamPermissions = (payload = false) => ({
  type: CAM_PERMISSIONS,
  payload,
})

const setHasAudio = (payload) => ({
  type: SET_HAS_AUDIO,
  payload,
})
const setHasVideo = (payload) => ({
  type: SET_HAS_VIDEO,
  payload,
})

const updateDeviceList = (payload) => ({
  type: UPDATE_DEVICE_LIST,
  payload: payload.sort(compareDevices),
})

const chooseDevices = (payload) => ({
  type: CHOOSE_DEVICES,
  payload,
})

const camDeviceMissing = () => ({
  type: CAM_DEVICE_MISSING,
})

const micDeviceMissing = () => ({
  type: MIC_DEVICE_MISSING,
})

const outDeviceMissing = () => ({
  type: OUT_DEVICE_MISSING,
})

const gumError = (err) => ({
  type: GUM_ERROR,
  payload: { name: err.name, stack: err.stack, message: err.message },
})

const resetGumError = () => ({
  type: RESET_GUM_ERROR,
})

/**
 * export actions that can be called for creating a new state in the reducer
 */
export const actions = {
  acknowledgeNoDevices,
  setCamPermissions,
  setMicPermissions,
  setHasAudio,
  setHasVideo,
  updateDeviceList,

  chooseDevices,
  camDeviceMissing,
  micDeviceMissing,
  outDeviceMissing,

  gumError,
  resetGumError,
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [UPDATE_DEVICE_LIST]: (state, action) => ({
    ...state,
    devices: action.payload,
    ...groupDevicesByKind(action.payload),
  }),
  [ACKNOWLEDGE_NO_DEVICE]: (state) => ({
    ...state,
    acknowledgeNoDevice: true,
  }),
  [CAM_PERMISSIONS]: (state, action) => ({
    ...state,
    permissionsForCam: action.payload,
  }),
  [MIC_PERMISSIONS]: (state, action) => ({
    ...state,
    permissionsForMic: action.payload,
  }),
  [SET_HAS_AUDIO]: (state, action) => ({
    ...state,
    hasAudio: action.payload,
  }),
  [SET_HAS_VIDEO]: (state, action) => ({
    ...state,
    hasVideo: action.payload,
  }),
  [GUM_ERROR]: (state, action) => ({
    ...state,
    gumError: action.payload,
  }),
  [RESET_GUM_ERROR]: (state) => ({
    ...state,
    gumError: undefined,
  }),
}

// ------------------------------------
// Reducer
// ------------------------------------
const INITIAL_STATE = {
  devices: [] as MediaDeviceInfo[],
  camDevices: [] as MediaDeviceInfo[],
  micDevices: [] as MediaDeviceInfo[],
  outDevices: [] as MediaDeviceInfo[],

  permissionsForCam: false,
  permissionsForMic: false,

  gumError: undefined,

  hasAudio: false,
  hasVideo: false,

  acknowledgeNoDevice: false,

  mic2output: false,
}

type StateType = typeof INITIAL_STATE

export default (state = INITIAL_STATE, action = {}) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export const selectors = {
  getDevices(state: StateType): MediaDeviceInfo[] {
    return state.devices
  },
  getCamDevices(state: StateType): MediaDeviceInfo[] {
    return state.camDevices
  },
  getMicDevices(state: StateType): MediaDeviceInfo[] {
    return state.micDevices
  },
  getOutDevices(state: StateType): MediaDeviceInfo[] {
    return state.outDevices
  },
  getPermissionsForCam(state: StateType): boolean {
    return state.permissionsForCam
  },
  getPermissionsForMic(state: StateType): boolean {
    return state.permissionsForMic
  },
  getAcknowledgeNoDevice(state: StateType): boolean {
    return state.acknowledgeNoDevice
  },
  hasAudio(state: StateType): boolean {
    return state.hasAudio
  },
  hasVideo(state: StateType): boolean {
    return state.hasVideo
  },
  getGumError(state: StateType): string | undefined {
    return state.gumError
  },
}
