import {
  enqueueBackgroundEffectErrorSnackbar,
  findAndCloseBackgroundEffectLoadingSnackbar,
} from 'app/features/snackbar-queue/snackbar-queue.utils'
import localStore from 'app/services/state/local-store'
import { actions } from './background-effect.reducer'

/**
 * @implements {Observer}
 */
class BackgroundEffectObserver {
  /**
   * @param dispatch Dispatch function
   * @param rememberEffect Boolean indicating whether to store this effect in local store.
   * @param effectType One of `BACKGROUND_EFFECT_TYPES`, the background effect to remember in local store.
   */
  constructor(dispatch, rememberEffect, effectType) {
    this.dispatch = dispatch
    this.rememberEffect = rememberEffect
    this.effectType = effectType
  }

  /**
   * Takes the next value from the observable and handles it.
   * @param value Boolean indicating whether a background processor is running.
   */
  next(value) {
    if (!value) return

    this.dispatch(
      this._handleEffectSuccess(this.rememberEffect, this.effectType)
    )
    this.complete()
  }

  /**
   * Handles an error from the observable.
   */
  error() {
    this.dispatch(this._handleEffectError())
  }

  /**
   * Handles the completion of the observable.
   */
  complete() {
    this.dispatch(actions.setLoading(false))
  }

  /**
   * Handles successfully starting a background effect.
   * @param rememberEffect Boolean indicating whether to store this effect in local store.
   * @param effectType One of `BACKGROUND_EFFECT_TYPES`, the background effect to remember in local store.
   * @returns Action creator
   */
  _handleEffectSuccess = (rememberEffect, effectType) => (dispatch) => {
    dispatch(actions.setShouldBeEnabled(true))
    // Close any open background effect loading snackbars
    dispatch(findAndCloseBackgroundEffectLoadingSnackbar())

    if (rememberEffect) {
      // Store background effect type in local store
      localStore.setActiveBackgroundEffect(effectType)
    }
  }

  /**
   * Handles errors when starting a background effect.
   * @returns Action creator
   */
  _handleEffectError = () => (dispatch) => {
    // Close any open background effect loading snackbars
    dispatch(findAndCloseBackgroundEffectLoadingSnackbar())
    // Enqueue error snackbar
    dispatch(enqueueBackgroundEffectErrorSnackbar())
    // Clear remembered effect on failure
    dispatch(actions.setRememberEffect(false))
  }
}

export default BackgroundEffectObserver
