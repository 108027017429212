/* eslint-disable no-param-reassign */
import ColigoListener from './coligo-listener'

type Props = {
  stream?: MediaStream | null
  originalStream?: MediaStream | null
  track: MediaStreamTrack
  label?: string
}
class ColigoTrack extends ColigoListener {
  /**
   * Indicating whether our track is being stopped or not.
   */
  stopped: boolean = false

  /**
   * The track which we are abusing.
   */
  track: MediaStreamTrack

  /**
   * The stream on which we have the track, this will be attached on the containers
   */
  stream: MediaStream

  /**
   * The original stream from which the track is received
   */
  originalStream: MediaStream

  /**
   * All containers to which our track is being attached to.
   */
  attachedContainers: HTMLElement[] = []

  private _label: string = ''

  /**
   *
   * @constructs
   */
  constructor(options: Props) {
    super()

    const { originalStream, stream, track, label } = options

    if (!(track instanceof MediaStreamTrack)) {
      throw new Error('Given track is not an instance of MediaStreamTrack')
    }

    if (stream && !(stream instanceof MediaStream)) {
      throw new Error('Given stream is not an instance of MediaStream')
    }

    this.id = track.id
    this.track = track
    this.stream = stream || new MediaStream([track])
    this.originalStream = originalStream
    this._label = label
  }

  get kind(): 'audio' | 'video' {
    return this.track.kind as 'audio' | 'video'
  }

  get label(): string {
    return this._label || this.track.label
  }

  get readyState(): 'live' | 'ended' {
    return this.track.readyState
  }

  addEventListener(event: string, cb: Function): void {
    this.track.addEventListener(event, cb)
  }

  removeEventListener(event: string, cb: Function): void {
    this.track.removeEventListener(event, cb)
  }

  getSettings(): MediaTrackSettings {
    return this.track.getSettings()
  }

  getConstraints(): MediaTrackSettings {
    return this.track.getConstraints()
  }

  getTrack(): MediaStreamTrack {
    return this.track
  }

  getStream(): MediaStream {
    return this.stream
  }

  /**
   * Check whether the current track is active or not.
   */
  isActive(): boolean {
    if (typeof this.readyState !== 'undefined') {
      return this.readyState === 'live'
    }

    return true
  }

  /**
   * Set the enabled property of the current track to the given value.
   */
  setEnabled(enabled: boolean): void {
    this.track.enabled = enabled
  }

  /**
   * Set the enabled property of the current track to false.
   */
  disable(): void {
    this.setEnabled(false)
  }

  /**
   * Set the enabled property of the current track to true.
   */
  enable(): void {
    this.setEnabled(true)
  }

  /**
   * Stop the current track and detach it from all containers to which it has been attached to.
   */
  stop(): void {
    if (this.stream) {
      this.detachAll()
      this.stream.getTracks().forEach((track) => {
        this.stream.removeTrack(track)
      })
    }

    this.track.enabled = false
    this.track.stop()

    this.stopped = true
  }

  /**
   */
  addAttachedContainer(container: HTMLElement): void {
    this.attachedContainers.push(container)
  }

  /**
   * Either adds a track to the already attached MediaStream or creates a new MediaStream
   * containing our track to the given container.
   */
  attach(container: HTMLElement): void {
    if (container && container instanceof HTMLElement) {
      if (!this.attachedContainers.includes(container)) {
        this.addAttachedContainer(container)
      }

      container.srcObject = this.stream
    } else if (!container) {
      throw new Error('There is no container given')
    } else {
      throw new Error('Given container has to be an instance of HTMLElement')
    }
  }

  /**
   * Removes our track from the given container and if index is being given
   * we remove it from attachedContainers.
   */
  detach(container: HTMLElement): void {
    if (container && container instanceof HTMLElement) {
      const index = this.attachedContainers.indexOf(container)
      if (index >= 0) {
        if (container.srcObject) {
          container.srcObject = null
        }
        this.attachedContainers.splice(index, 1)
      }
    } else if (!container) {
      throw new Error('There is no container given')
    } else {
      throw new Error('Given container has to be an instance of HTMLElement')
    }
  }

  /**
   * Detaches all containers to which we are being attached.
   */
  detachAll(): void {
    /**
     * NOTE: This spread is needed because the detach function splices the entry out of attachedContainers
     * @type {HTMLElement[]}
     */
    const items: HTMLElement[] = [...this.attachedContainers]
    items.forEach(this.detach.bind(this))
  }

  /**
   * Check whether the current track's aspect ratio is portrait.
   */
  isPortrait(): boolean {
    if (this.track.kind === 'video' && this.track.getSettings) {
      const settings = this.track.getSettings()
      return settings.height > settings.width
    }
    return false
  }
}

export default ColigoTrack
