import { replace } from '@lagunovsky/redux-react-router'

import URLUtils from 'lib/url-utils'
import { isStringMeetingCode } from 'lib/string-utils'

import {
  STORE_NAME as CHANNEL_AVATAR_STORE_NAME,
  actions as channelAvatarActions,
  selectors as channelAvatarSelectors,
} from 'app/state/api/channels/channel-avatar.reducer'
import {
  STORE_NAME as CHANNEL_AUTH_STORE_NAME,
  actions as channelAuthActions,
  selectors as channelAuthSelectors,
} from 'app/state/api/channels/channel-auth.reducer'
import {
  STORE_NAME as CHANNEL_SETTINGS_STORE_NAME,
  actions as channelSettingsActions,
  selectors as channelSettingsSelectors,
} from 'app/state/api/channels/channel-settings.reducer'
import {
  ACTION_TYPES as CHANNEL_CONFERENCE_ACTION_TYPES,
  STORE_NAME as CHANNEL_CONFERENCE_STORE_NAME,
  actions as channelConferenceActions,
  selectors as channelConferenceSelectors,
} from 'app/state/api/channels/channel-conference.reducer'
import {
  STORE_NAME as CHANNEL_ROOM_STORE_NAME,
  actions as channelRoomActions,
  selectors as channelRoomSelectors,
} from 'app/state/api/channels/channel-room.reducer'
import { ACTION_TYPES as SOCKET_ACTION_TYPES } from 'app/state/api/socket/socket.reducer'

const middleware = (store) => (next) => (action) => {
  const nextState = next(action)

  switch (action.type) {
    case CHANNEL_CONFERENCE_ACTION_TYPES.UPDATE_CONFERENCE:
      {
        const { [CHANNEL_ROOM_STORE_NAME]: channelRoom } = store.getState()

        if (action.payload.id === channelRoom.meeting_id) {
          // When the conference which we are currently joined is updated we update the url.
          const meetingIdentifier = URLUtils.getMeetingIdentifier(
            window.location.pathname
          )
          // Is the identifier in the url (/meeting/<identifier>) a code (6 digits).
          const isIdentifierCode = isStringMeetingCode(meetingIdentifier)

          if (isIdentifierCode && action.payload.code !== meetingIdentifier) {
            // When the identifier in the url was a code, replace the identifier with the new code.
            // To ensure that the url is keeps up-to-date with the code of the conference, we update it with the new code.
            store.dispatch(
              replace(URLUtils.getNewMeetingPathname(action.payload.code))
            )
          } else if (
            !isIdentifierCode &&
            action.payload.name !== meetingIdentifier
          ) {
            // When the identifier in the url was a name, replace the identifier with the new name.
            if (action.payload.name) {
              // NOTE: This checks whether the name is not set to something empty, to prevent the user being redirected to /meeting/null
              store.dispatch(
                replace(URLUtils.getNewMeetingPathname(action.payload.name))
              )
            } else {
              // When the name was set to something empty, we set the code of the conference in the url.
              store.dispatch(
                replace(URLUtils.getNewMeetingPathname(action.payload.code))
              )
            }
          }
        }
      }
      break

    case SOCKET_ACTION_TYPES.CONNECT_CLOSED:
      {
        const {
          [CHANNEL_AVATAR_STORE_NAME]: channelAvatar,
          [CHANNEL_AUTH_STORE_NAME]: channelAuth,
          [CHANNEL_SETTINGS_STORE_NAME]: channelSettings,
          [CHANNEL_CONFERENCE_STORE_NAME]: channelConference,
          [CHANNEL_ROOM_STORE_NAME]: channelRoom,
        } = store.getState()

        // TODO: Move this to channel specific middleware
        if (channelAvatarSelectors.isJoined(channelAvatar)) {
          store.dispatch(channelAvatarActions.closed())
        }
        // TODO: Move this to channel specific middleware
        if (channelAuthSelectors.isJoined(channelAuth)) {
          store.dispatch(channelAuthActions.closed())
        }
        // TODO: Move this to channel specific middleware
        if (channelSettingsSelectors.isJoined(channelSettings)) {
          store.dispatch(channelSettingsActions.closed())
        }
        // TODO: Move this to channel specific middleware
        if (channelConferenceSelectors.isJoined(channelConference)) {
          store.dispatch(channelConferenceActions.closed())
        }
        // TODO: Move this to channel specific middleware
        if (channelRoomSelectors.isJoined(channelRoom)) {
          store.dispatch(channelRoomActions.closed())
        }
      }
      break

    default:
      break
  }

  return nextState
}
export default middleware
