import Task from './coligo-task'

export class CustomTask extends Task {
  name = 'CustomTask'

  func

  constructor(name, func) {
    super()

    this.name = name

    this.func = func
  }

  async run() {
    await this.func()
  }
}

export default CustomTask
