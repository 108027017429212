export type Action = {
  actionType: string
  /**
   * i18n label
   */
  label: string
  description: string
  feature: string
}

export type Keybinding = {
  keys: string[]
  action: Action
}

/**
 * The Schema is a collection of keybindings
 */
export class Schema {
  bindingByAction: Map<Action, Keybinding> = new Map()

  bindingsByKeys: Map<string[], Keybinding> = new Map()

  name: string | null = 'default'

  /**
   *
   * @constructor
   * @param [name=null] name of the schema
   * @param conf
   */
  constructor(name = null, conf = null) {
    this.name = name
    if (conf) {
      conf.forEach(this.add)
    }
  }

  /**
   * add key bindings to the schema
   * @param conf
   */
  add = (conf) => {
    this.bindingsByKeys.set(conf.keys, conf)
    this.bindingByAction.set(conf.action, conf)
    logger.debug(
      `services.commands.Schema.add(): Add keybinding "${conf.keys}"`,
      conf
    )
  }

  /**
   *
   * @param action
   * @returns
   */
  resolveByAction = (action: Action): Keybinding => {
    return this.bindingByAction.get(action)
  }

  /**
   * return the entries in the collection
   */
  entries = (): Keybinding[] => {
    return Array.from(this.bindingsByKeys.entries()).reduce(
      (acc, [, binding]) => [...acc, binding],
      []
    )
  }

  /**
   * clear the current binding
   */
  clear = (): void => {
    this.bindingsByKeys = new Map()
    this.bindingByAction = new Map()
  }

  /**
   * merge schema into current
   */
  merge = (schema: Schema): void => {
    if (!this.bindingByAction.size) {
      this.name = schema.name
    } else {
      this.name = 'custom'
    }
    schema.entries().forEach(this.add)
  }

  /**
   *
   * @param conf
   * @param conf.default
   * @returns
   */
  init(conf): Schema {
    if (conf.default && conf.default instanceof Schema) {
      this.merge(conf.default)
    }
    return this
  }
}

export default new Schema()
