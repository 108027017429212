import VoiceActivityDetection from '@enreach/voice-activity-detection'
import { bufferTime, distinctUntilChanged, filter, map } from 'rxjs/operators'
import { BehaviorSubject, fromEventPattern } from 'rxjs'

class AudioObserver {
  static BUFFER_TIME = 1_000

  /**
   * @type {import('rxjs').Subscription[]}
   * @private
   */
  _trackSubscriptions = []

  /**
   * @type {import('rxjs').Subscription[]}
   * @private
   */
  _speechSubscriptions = []

  /**
   *
   * @private
   */
  _speech = new VoiceActivityDetection()

  /**
   * @type {BehaviorSubject<boolean|null>}
   * @private
   */
  _talkingSubject = new BehaviorSubject(null)

  /**
   * BehaviorSubject emitting the talking state of the user in the form of a boolean
   * or null. Null means that the state is yet undetermined.
   * @type {import('rxjs').Observable<boolean|null>}
   */
  talking$ = this._talkingSubject.asObservable()

  /**
   * @type {BehaviorSubject<boolean|null>}
   * @private
   */
  _silencedSubject = new BehaviorSubject(null)

  /**
   * BehaviorSubject emitting the silenced state of the user in the form of a boolean
   * or null. Null means that the state is yet undetermined. True would mean that the
   * volume emitted by the microphone device is all empty.
   * @type {import('rxjs').Observable<boolean|null>}
   */
  silenced$ = this._silencedSubject.asObservable()

  /**
   * @type {import('rxjs').Observable<import('lib/rtc/coligo-track').default>}
   * @private
   */
  _track$

  get track$() {
    return this._track$
  }

  /**
   * @param track$
   */
  init(track$) {
    if (this._track$) {
      throw new Error('AudioObserver is already initialized')
    }

    this._track$ = track$

    this._initialiseSubscriptions()
  }

  async dispose() {
    this._unsubscribeSpeechSubscriptions()
    this._unsubscribeTrackSubscriptions()

    await this._speech.stop()
    this._speech = undefined
    this._track$ = undefined
    this._silencedSubject.complete()
    this._talkingSubject.complete()
  }

  /**
   * @private
   */
  _initialiseSubscriptions() {
    this._trackSubscriptions.push(
      this._track$.pipe(filter(Boolean)).subscribe((track) => {
        this._initialiseSpeechSubscriptions()

        this._speech.setSource(track.stream)
      })
    )

    this._trackSubscriptions.push(
      this._track$.pipe(filter((val) => !val)).subscribe(() => {
        this._unsubscribeSpeechSubscriptions()

        this._speech.setSource(null)
        this._talkingSubject.next(null)
        this._silencedSubject.next(null)
      })
    )
  }

  /**
   * @private
   */
  _initialiseSpeechSubscriptions() {
    this._initialiseTalkingSubscription()
    this._initialiseSilenceSubscription()
  }

  /**
   * @private
   */
  _initialiseTalkingSubscription() {
    this._speechSubscriptions.push(
      fromEventPattern(
        (handler) => {
          this._speech.addListener('speaking', handler)
          this._speech.addListener('stoppedSpeaking', handler)
        },
        (handler) => {
          this._speech.removeListener('speaking', handler)
          this._speech.removeListener('stoppedSpeaking', handler)
        }
      )
        .pipe(
          map(() => this._speech.speaking),
          distinctUntilChanged()
        )
        .subscribe(this._talkingSubject)
    )
  }

  /**
   * @private
   */
  _initialiseSilenceSubscription() {
    this._speechSubscriptions.push(
      fromEventPattern(
        (handler) => this._speech.addListener('volumeChange', handler),
        (handler) => this._speech.removeListener('volumeChange', handler)
      )
        .pipe(
          bufferTime(AudioObserver.BUFFER_TIME),
          filter((volumes) => !!volumes.length),
          map((volumes) => !volumes.some((volume) => volume !== -Infinity)),
          distinctUntilChanged()
        )
        .subscribe(this._silencedSubject)
    )
  }

  /**
   * @private
   */
  _unsubscribeSpeechSubscriptions() {
    this._speechSubscriptions.forEach((sub) => {
      sub.unsubscribe()
    })

    this._speechSubscriptions = []
  }

  /**
   * @private
   */
  _unsubscribeTrackSubscriptions() {
    this._trackSubscriptions.forEach((sub) => {
      sub.unsubscribe()
    })

    this._trackSubscriptions = []
  }
}

export default AudioObserver
