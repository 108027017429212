import _ from 'lodash'
import i18n from 'i18next'

import { actions as snackbarQueueActions } from 'app/features/snackbar-queue/snackbar-queue.reducer'
import { Icons } from 'constants/icons'

import { STORE_NAME } from './file-upload-queue.reducer'

export const openSuccessSnackbar = () =>
  snackbarQueueActions.enqueueSnackbar({
    message: i18n.t('Fileupload.uploadSuccess'),
    icon: Icons.communicationStatusBlfIdle,
    status: 'success',
    snackbarType: 'FILE_UPLOAD',
  })

export const openFailedSnackbar = () =>
  snackbarQueueActions.enqueueSnackbar({
    message: i18n.t('Fileupload.uploadFailed'),
    icon: Icons.notificationError,
    status: 'error',
    snackbarType: 'FILE_UPLOAD',
  })

export const openSuccessSnackbarIfSucces = (fileId) => (dispatch, getState) => {
  const { [STORE_NAME]: fileUploadQueueState } = getState()

  const { queueById } = fileUploadQueueState

  /**
   * Reject the just removed entry from the `queueById`.
   * @type {{ [key: string]: object }}
   */
  const filteredQueueById = _.reject(queueById, { id: fileId })

  /**
   * If without the just removed entry, the queue is empty we open the snackbar
   * to display that the fileupload was successful.
   */
  if (_.isEmpty(filteredQueueById)) {
    dispatch(openSuccessSnackbar())
  }
}
