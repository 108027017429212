import _ from 'lodash'

import {
  ACTION_TYPES as CHANNEL_ROOM_ACTION_TYPES,
  STORE_NAME as CHANNEL_ROOM_STORE_NAME,
  selectors as channelRoomSelectors,
} from 'app/state/api/channels/channel-room.reducer'

import { actions, STORE_NAME } from './video-streamer.reducer'

export default (store) => (next) => (action) => {
  const ret = next(action)

  const { dispatch, getState } = store

  if (action.type === CHANNEL_ROOM_ACTION_TYPES.UPDATED_MEMBER_STATE) {
    const { [CHANNEL_ROOM_STORE_NAME]: channelRoom } = getState()
    const { [STORE_NAME]: videoStreamer } = getState()

    const isUpdateOnMe =
      action.memberId === channelRoomSelectors.getMemberId(channelRoom)
    const stoppedScreenshare =
      _.has(action.states, 'screens_send') && !action.states.screens_send
    const myStreamerStateIsDirty = !!videoStreamer.video

    if (isUpdateOnMe && stoppedScreenshare && myStreamerStateIsDirty) {
      dispatch(actions.stopVideo())
    }
  }

  return ret
}
