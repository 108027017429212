import type { SVGProps } from 'react'
const SvgEmoji6 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M45.99 63.53c-.59 0-1.17 0-1.74-.02-2.08-.04-4.06-.15-5.89-.34-4.07-.41-16.44-1.65-17.36-10.68-.59-5.9 4.08-11.3 14.29-16.51 8.4-4.29 19.72-7.95 31.86-10.33C76.03 23.91 84.7 23 92.23 23c2.71 0 5.28.12 7.63.35 4.07.41 16.44 1.65 17.36 10.68.59 5.9-4.08 11.3-14.29 16.51-8.4 4.29-19.72 7.96-31.86 10.33-8.87 1.74-17.55 2.66-25.08 2.66zM28.71 51.32c.66.63 3.07 2.31 10.36 3.04 2.14.21 4.48.32 6.97.32 7.09 0 15.31-.88 23.78-2.53 11.53-2.26 22.2-5.7 30.04-9.7 7.22-3.68 9.17-6.36 9.65-7.25-.66-.63-3.08-2.31-10.36-3.04-1.67-.17-3.48-.27-5.37-.31-.53-.01-1.06-.02-1.6-.02-7.1 0-15.32.88-23.78 2.53-11.54 2.26-22.2 5.7-30.04 9.7-7.22 3.68-9.17 6.36-9.65 7.25z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M92.23 25c2.64 0 5.13.11 7.43.35 9.97 1 15.06 3.91 15.56 8.89.5 4.99-3.82 9.74-13.2 14.52-8.24 4.2-19.37 7.81-31.34 10.15-8.81 1.73-17.33 2.62-24.7 2.62-.57 0-1.14 0-1.7-.02-2.01-.04-3.93-.15-5.73-.33-9.97-1-15.06-3.91-15.56-8.89-.5-4.99 3.82-9.74 13.2-14.52 8.24-4.2 19.37-7.81 31.34-10.15C76.34 25.89 84.86 25 92.23 25M46.04 56.69c7.19 0 15.51-.88 24.16-2.57 11.71-2.29 22.57-5.8 30.57-9.88 9.21-4.7 10.97-8.3 10.87-9.3-.1-.99-2.5-3.78-12.29-4.76-1.74-.17-3.59-.28-5.53-.32-.54-.01-1.09-.02-1.64-.02-7.19 0-15.51.88-24.16 2.57-11.71 2.29-22.57 5.8-30.57 9.88-9.21 4.7-10.97 8.3-10.87 9.3.1.99 2.5 3.78 12.29 4.76 2.22.22 4.63.33 7.18.33M92.23 21c-7.66 0-16.47.93-25.47 2.69-12.32 2.41-23.82 6.15-32.39 10.51-4.05 2.07-16.39 8.36-15.37 18.49.71 7.08 7.15 11.27 19.15 12.47 1.89.19 3.93.31 6.06.35.58.01 1.18.02 1.78.02 7.66 0 16.47-.93 25.47-2.69 12.32-2.41 23.82-6.15 32.39-10.51 4.05-2.07 16.39-8.36 15.37-18.49-.71-7.07-7.15-11.27-19.15-12.47-2.42-.24-5.05-.37-7.83-.37zm-60.5 29.79c1.19-1.13 3.43-2.83 7.54-4.93 7.68-3.92 18.16-7.3 29.52-9.52 8.33-1.63 16.42-2.49 23.39-2.49.53 0 1.05 0 1.57.01 1.84.04 3.59.14 5.21.3 3.87.39 6.2 1.04 7.54 1.59-1.19 1.13-3.43 2.83-7.54 4.93-7.68 3.92-18.16 7.3-29.52 9.52-8.34 1.63-16.42 2.49-23.39 2.49a71.1 71.1 0 0 1-6.78-.31c-3.87-.39-6.2-1.04-7.54-1.59z'
    />
    <circle
      cx={79.08}
      cy={94.42}
      r={48.66}
      fill='rgb(var(--color-brand-100))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.08 145.08c-27.93 0-50.66-22.72-50.66-50.66s22.72-50.66 50.66-50.66 50.66 22.72 50.66 50.66-22.72 50.66-50.66 50.66zm0-97.31c-25.73 0-46.66 20.93-46.66 46.66s20.93 46.66 46.66 46.66 46.66-20.93 46.66-46.66-20.93-46.66-46.66-46.66z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M90.85 94.21a1.82 1.82 0 0 1-1.3-3.1c3.32-3.38 8.18-5.32 13.32-5.32s9.99 1.94 13.32 5.32c.71.72.7 1.87-.02 2.58s-1.87.7-2.58-.02c-2.64-2.69-6.55-4.23-10.72-4.23s-8.07 1.54-10.72 4.23c-.36.36-.83.55-1.3.55zm-49.27 0a1.82 1.82 0 0 1-1.3-3.1c3.32-3.38 8.18-5.32 13.32-5.32s9.99 1.94 13.32 5.32c.71.72.7 1.87-.02 2.58s-1.87.7-2.58-.02c-2.64-2.69-6.55-4.23-10.72-4.23s-8.07 1.54-10.72 4.23c-.36.36-.83.55-1.3.55zm35.89 26.48c-4.32 0-8.4-1.63-11.2-4.48-.71-.72-.7-1.87.02-2.58s1.87-.7 2.58.02c2.12 2.15 5.25 3.39 8.6 3.39s6.48-1.23 8.6-3.39a1.82 1.82 0 0 1 2.58-.02c.72.71.73 1.86.02 2.58-2.8 2.85-6.88 4.48-11.2 4.48z'
    />
  </svg>
)
export default SvgEmoji6
