import _ from 'lodash'

import broadcastContext from './broadcast-context'

export const EVENT_TYPES = {
  USER_STATE: 'sm-web/USER_STATE',
}

const MANDATORY_PAYLOAD = {
  [EVENT_TYPES.USER_STATE]: ['meetingId', 'userId', 'status'],
}

/**
 * Function checks whether the mandatory keys for the given event are present
 * in the given payload.
 * @param event
 * @param payload
 * @return
 */
function checkPayload(event, payload) {
  if (!MANDATORY_PAYLOAD[event]) {
    throw new Error(`Event doesn't exist '${event}'`)
  }
  const missingMandatoryKeys = MANDATORY_PAYLOAD[event].filter(
    (key) => !_.has(payload, key)
  )
  if (missingMandatoryKeys.length) {
    throw new Error(
      `Missing ${missingMandatoryKeys
        .map((k) => `'${k}'`)
        .join(', ')} for '${event}'`
    )
  }
}

const events = {
  /**
   * Broadcasts an event about an user's state being updated.
   * @param payload
   * @return
   */
  async userState(payload): Promise<void> {
    checkPayload(EVENT_TYPES.USER_STATE, payload)
    return broadcastContext.broadcast(EVENT_TYPES.USER_STATE, payload)
  },
}

export default events
