import { useMemo } from 'react'
import cn from 'clsx'

import FormSubmit from './form-submit'

const FormBase = ({
  FormSubmitProps,
  buttonText,
  button,
  children,
  classes,
  className,
  disabled,
  errorMessage,
  formRef,
  name,
  handleSubmit,
  ...other
}) => {
  return (
    <form
      className={cn('inputForm', className)}
      name={name}
      ref={formRef}
      onSubmit={handleSubmit}
    >
      {children}
      {errorMessage && <p className='error'>{errorMessage}</p>}
      {buttonText && (
        <FormSubmit
          className={classes?.buttonSubmit}
          disabled={disabled}
          {...FormSubmitProps}
        >
          {buttonText}
        </FormSubmit>
      )}
      {button}
    </form>
  )
}

export default FormBase
