import { Typography } from 'ui/revision'
import { useCallback, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { alpha } from '@mui/material/styles'
import cn from 'clsx'
import 'draft-js-emoji-plugin/lib/plugin.css'

import { makeStyles } from 'app/ui'
import { useId } from 'app/react/hooks/id-registry.hooks'
import { Send } from 'ui/icon-button'
import browserUtils from 'lib/browser-utils'

import createEmojiPickerPlugin from './chat-input-emoji-picker'
import createMobileEmojiPickerPlugin from './chat-input-mobile-emoji-picker'
import ChatInputToolbar from './chat-input-toolbar'
import ChatInputEditor from './chat-input-editor'
import ChatInputMobileEditor from './chat-input-mobile-editor'

import {
  checkStringValidity,
  checkStringLengthAndLimit,
} from './chat-input.utils'

import { useTypingState } from './chat-input.hooks'
import { FONTS } from 'constants/constants'

const useStyles = makeStyles(
  (theme) => {
    return {
      root: {
        flex: '0 0 auto',
        position: 'relative',
        fontFamily: `${FONTS.emojiFont}, "Roboto", sans-serif`,
        fontWeight: 100,
        backgroundColor: theme.palette.background.default,
      },

      editorRow1: {
        minHeight: 'calc(20 + var(--spacing-4))',
        height: 'calc(20 + var(--spacing-4))',
        maxHeight: 'calc(20 + var(--spacing-4))',
        maxWidth: '100%',
      },
      editorRow2: {
        minHeight: 'calc(40 + var(--spacing-4))',
        height: 'calc(40 + var(--spacing-4))',
        maxHeight: 'calc(40 + var(--spacing-4))',
        maxWidth: '100%',
      },
      editorRow3: {
        minHeight: 'calc(60 + var(--spacing-4))',
        height: 'calc(60 + var(--spacing-4))',
        maxHeight: 'calc(60 + var(--spacing-4))',
        maxWidth: '100%',
      },
      editorRow4: {
        minHeight: 'calc(80 + var(--spacing-4))',
        height: 'calc(80 + var(--spacing-4))',
        maxHeight: 'calc(80 + var(--spacing-4))',
        maxWidth: '100%',
      },
      editorRow5: {
        minHeight: 'calc(100 + var(--spacing-4))',
        height: 'calc(100 + var(--spacing-4))',
        maxHeight: 'calc(100 + var(--spacing-4))',
        maxWidth: '100%',
      },
      sendButton: {
        position: 'absolute',
        bottom: 0,
        right: theme.spacing(2),
        transform: 'translateY(50%)',
        zIndex: 2,
      },

      counter: {
        color: alpha(
          theme.palette.getContrastText(theme.palette.common.black),
          0.67
        ),
        position: 'absolute',
        zIndex: 4,
        right: theme.spacing(1.5),
        bottom: theme.spacing(1),
      },

      counterOverLimit: {
        color: theme.palette.error.main,
      },
    }
  },
  { name: 'ChatInput' }
)

type Props = {
  InputProps?: any
  SendButtonProps?: any
  autoFocus?: boolean
  characterLimit?: number
  disabled?: boolean
  inputRef?: React.RefObject<HTMLInputElement>
  placeholder?: string
  rows?: number
  tempMessage?: string
  onFilesSelected?: (files: File[]) => void
  onFocus?: () => void
  onSaveTempMessage?: (message: string) => void
  onSubmit?: (message: string) => void
  onTyping?: (typing: boolean) => void
}

const ChatInput: React.FC<Props> = ({
  InputProps,
  SendButtonProps,
  autoFocus,
  characterLimit = 999,
  disabled,
  inputRef,
  placeholder,
  rows = 1,
  tempMessage,
  onFilesSelected,
  onFocus,
  onSaveTempMessage,
  onSubmit,
  onTyping,
}: Props) => {
  const classes = useStyles()
  const isMobile = browserUtils.isMobileTouchDevice()
  const Input = isMobile ? ChatInputMobileEditor : ChatInputEditor

  const { id } = useId()
  const [emojiPickerPlugin] = useState(
    isMobile ? createMobileEmojiPickerPlugin() : createEmojiPickerPlugin()
  )
  const [inputMessage, onInputChange] = useState<string>(null)
  const [isTyping, setIsTyping] = useTypingState()
  const [messageIsSending, setMessageIsSending] = useState(false)
  const { isLimitReached, countSize } = checkStringLengthAndLimit(
    inputMessage,
    characterLimit
  )

  const sendDisabled =
    disabled ||
    !inputMessage ||
    isLimitReached ||
    checkStringValidity(inputMessage)

  const handleSubmit = useCallback(() => {
    if (!sendDisabled) {
      const chatMessage = inputMessage.trim()

      onSubmit(chatMessage)
      setMessageIsSending(true)
      setIsTyping(false)
    }
  }, [inputMessage, onSubmit, sendDisabled, setIsTyping, setMessageIsSending])

  useEffect(() => {
    if (inputMessage && inputMessage.length > 0) {
      setIsTyping(true)
    }
  }, [inputMessage, setIsTyping])

  useEffect(() => {
    if (onTyping) {
      onTyping(isTyping)
    }
  }, [isTyping, onTyping])

  useEffect(() => {
    if (messageIsSending) {
      setMessageIsSending(false)
    }
  }, [messageIsSending])

  return (
    <Grid container className={classes.root} direction='column'>
      <ChatInputToolbar
        disableFileUpload={!onFilesSelected}
        disabled={disabled}
        emojiPicker={emojiPickerPlugin.EmojiPicker}
        onFilesSelected={onFilesSelected}
      >
        <Send
          {...SendButtonProps}
          className={classes.sendButton}
          color='primary'
          data-testid={id('ChatInput.send')}
          disabled={sendDisabled}
          size='medium'
          onClick={handleSubmit}
        />
      </ChatInputToolbar>

      <Input
        {...InputProps}
        autoFocus={autoFocus}
        className={classes[`editorRow${rows}`]}
        countSize={countSize}
        disabled={disabled}
        emojiPickerPlugin={emojiPickerPlugin}
        inputRef={inputRef}
        messageIsSending={messageIsSending}
        placeholder={
          <Typography type='disabled' variant='microcopy'>
            {placeholder}
          </Typography>
        }
        tempMessage={tempMessage}
        onFocus={onFocus}
        onHandleSubmit={handleSubmit}
        onInputChange={onInputChange}
        onSaveTempMessage={onSaveTempMessage}
      />

      {!disabled && (
        <Typography
          className={cn(
            classes.counter,
            isLimitReached && classes.counterOverLimit
          )}
          variant='microcopy'
        >
          {`${countSize}/${characterLimit}`}
        </Typography>
      )}
    </Grid>
  )
}

export default ChatInput
