import PropTypes from 'prop-types'
import { Flipped } from 'react-flip-toolkit'

import FormTitle from '../form-title'
import FormDescription from '../form-description'

const FormInfo = ({
  Component = 'div',
  children = null,
  description = '',
  flipped = false,
  formDescriptionFlippedProps = {},
  formDescriptionProps = {},
  formTitleFlippedProps = {},
  formTitleProps = {},
  title = '',
  ...other
}) => {
  let titleEl = <FormTitle text={title} {...formTitleProps} />
  if (flipped) {
    titleEl = <Flipped {...formTitleFlippedProps}>{titleEl}</Flipped>
  }

  let descriptionEl = (
    <FormDescription text={description} {...formDescriptionProps} />
  )
  if (flipped) {
    descriptionEl = (
      <Flipped {...formDescriptionFlippedProps}>{descriptionEl}</Flipped>
    )
  }

  return (
    <Component {...other}>
      {titleEl}
      {descriptionEl}
      {children}
    </Component>
  )
}

FormInfo.propTypes = {
  Component: PropTypes.elementType,
  children: PropTypes.node,
  description: PropTypes.string,
  flipped: PropTypes.bool,
  formDescriptionFlippedProps: PropTypes.shape(Flipped.propTypes),
  formDescriptionProps: PropTypes.shape(FormDescription.propTypes),
  formTitleFlippedProps: PropTypes.shape(Flipped.propTypes),
  formTitleProps: PropTypes.shape(FormTitle.propTypes),
  title: PropTypes.string,
}

export default FormInfo
