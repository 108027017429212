import _ from 'lodash'

export const STORE_NAME = 'feature/used-media'

/**
 * Collection of all actions
 *
 */
const INIT = `sm-web/${STORE_NAME}/INIT`
const ADD = `sm-web/${STORE_NAME}/ADD`
const REMOVE = `sm-web/${STORE_NAME}/REMOVE`

export const ACTION_TYPES = {
  INIT,
  ADD,
  REMOVE,
}

const addEntry = (entry) => ({
  type: ADD,
  payload: entry,
})

const removeEntry = (entry) => ({
  type: REMOVE,
  payload: entry,
})

export const actions = {
  addEntry,
  removeEntry,
}

export const INITIAL_STATE = {
  entries: [],
}

const ACTION_HANDLERS = {
  [ADD]: (state, action) => {
    if (!_.find(state.entries, action.payload)) {
      return {
        ...state,
        entries: [...state.entries, action.payload],
      }
    }
    return state
  },
  [REMOVE]: (state, action) => {
    return { ...state, entries: _.reject(state.entries, action.payload) }
  },
}

export default (state = INITIAL_STATE, action = {}) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export const selectors = {
  getEntries(state) {
    return state.entries
  },
}
