import Grid from '@mui/material/Grid'
import cn from 'clsx'

import { makeStyles } from 'app/ui'
import Paper from 'ui/paper'
import { FormInfo } from 'ui/form'

const useStyles = makeStyles((theme) => ({
  root: theme.background,
  paper: {
    width: 500,
    height: 360,
    padding: theme.spacing(4, 8),
    textAlign: 'center',
  },
}))

type Props = {
  className?: string
}

const NoConfigForm = ({ className }: Props): JSX.Element => {
  const classes = useStyles()
  return (
    <Grid
      container
      alignItems='center'
      className={cn(classes.root, className)}
      justifyContent='center'
    >
      <Grid
        container
        alignItems='center'
        className={classes.paper}
        component={Paper}
        justifyContent='center'
      >
        <FormInfo
          description={'Configuration is missing.'}
          formDescriptionProps={{ color: 'textSecondary' }}
          formTitleProps={{ color: 'error' }}
          title={'😵'}
        />
      </Grid>
    </Grid>
  )
}

export default NoConfigForm
