import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  approveScreenShareRights,
  rejectScreenShareRights,
} from 'app/features/conference/conference.utils'
import { STORE_NAME as MEETING_FILES_STORE_NAME } from 'app/features/meeting-files/meeting-files.reducer'
import { useId } from 'app/react/hooks/id-registry.hooks'
import { useDispatch, useSelector } from 'app/services/state/redux-store'
import { STORE_NAME as CHANNEL_ROOM_STORE_NAME } from 'app/state/api/channels/channel-room.reducer'
import { getMe, getMemberUserName, getUser, isHost } from 'app/state/utils'
import { offlinePredicate } from 'app/state/utils/member-filters'
import { makeStyles } from 'app/ui'
import { CHAT_CONTENT_TYPES } from 'constants/constants'
import { Icons } from 'constants/icons'
import Avatar from 'ui/avatar'
import { Icon, IconButton, ListItem } from 'ui/revision'
import {
  RoomFileEvent,
  SystemMaintenanceEvent,
  SystemRoomEvent,
  SystemUserEvent,
} from 'ui/system-event'

const useStyles = makeStyles((theme) => ({
  iconAccept: {
    color: theme.palette.success.light,
  },
  gridWrapper: {
    marginLeft: theme.spacing(8) - 4,
  },
  approveButton: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  icon: {
    color: theme.palette.text.primary,
  },
  listitem: {
    '&::part(list-item-subtle)': {
      padding: '0px 0px 0px 0px',
    },
  },
}))

export interface RoomMessageProps {
  fileId?: string
  /**
   * memberId for 'from' user
   */
  from?: string
  status?: string
  timestamp?: number
  type?: string
  /**
   * memberId for 'about' user
   */
  userId?: string
}

export const RoomMessageContainer: React.FC<RoomMessageProps> = ({
  fileId,
  from,
  status,
  timestamp,
  type,
  userId,
}: RoomMessageProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { id } = useId()
  const { t } = useTranslation()

  /**
   * if from is not present, use userId which is the "about" member
   * TODO: this is a confusing hack to fix the issue where the "from" field is not present
   */
  const memberId = from || userId
  const baseProps = useMemo(() => ({ memberId }), [memberId])

  const aboutLocalUser = useSelector(
    ({ [CHANNEL_ROOM_STORE_NAME]: channelRoom }) =>
      userId === channelRoom.member_id
  )
  const filesById = useSelector(
    ({ [MEETING_FILES_STORE_NAME]: meetingFiles }) =>
      _.get(meetingFiles, 'filesById')
  )
  const fromLocalUser = useSelector(
    ({ [CHANNEL_ROOM_STORE_NAME]: channelRoom }) =>
      from === channelRoom.member_id
  )
  const localUserIsHost = useSelector(
    ({ [CHANNEL_ROOM_STORE_NAME]: channelRoom }) => isHost(getMe(channelRoom))
  )
  const senderOffline = useSelector(
    ({ [CHANNEL_ROOM_STORE_NAME]: channelRoom }) => {
      const fromUser = getUser(channelRoom, from)
      return !fromUser || _.isMatch(fromUser, offlinePredicate)
    }
  )
  const userName = useSelector(({ [CHANNEL_ROOM_STORE_NAME]: channelRoom }) => {
    const fromUser = getUser(channelRoom, from)
    return fromUser ? getMemberUserName(fromUser) : undefined
  })
  const userNameAbout = useSelector(
    ({ [CHANNEL_ROOM_STORE_NAME]: channelRoom }) =>
      userId ? getMemberUserName(getUser(channelRoom, userId)) : undefined
  )

  const handleAcceptRequest = useCallback(
    async () => dispatch(approveScreenShareRights(memberId)),
    [dispatch, memberId]
  )

  const handleRejectRequest = useCallback(
    async () => dispatch(rejectScreenShareRights(memberId)),
    [dispatch, memberId]
  )

  return useMemo(() => {
    switch (type) {
      case CHAT_CONTENT_TYPES.KICK_USER:
        return (
          <SystemUserEvent>
            {t('Message.userKicked', '{{user}} was kicked from the room', {
              user: userNameAbout,
            })}
          </SystemUserEvent>
        )
      case CHAT_CONTENT_TYPES.SS_REJECTED:
        /**
         * dont show rejected message if current user is host
         */
        return (
          !localUserIsHost && (
            <SystemUserEvent>
              {t('Message.ssRejected', 'Host rejected request')}
            </SystemUserEvent>
          )
        )
      case CHAT_CONTENT_TYPES.SS_ACCEPTED:
        return (
          <SystemUserEvent>
            {t('Message.ssRejected', 'Host accepted request')}
          </SystemUserEvent>
        )
      case CHAT_CONTENT_TYPES.USER_IN:
        return (
          <SystemUserEvent>
            {t('Message.joinedConvA', '{{user}} joined the conversation', {
              user: userNameAbout,
            })}
          </SystemUserEvent>
        )
      case CHAT_CONTENT_TYPES.USER_OUT:
        return (
          <SystemUserEvent>
            {t('Message.leftConvB', '{{user}} left the conversation', {
              user: userNameAbout,
            })}
          </SystemUserEvent>
        )
      case CHAT_CONTENT_TYPES.USER_SS:
        return (
          <SystemUserEvent>
            {fromLocalUser
              ? t(`Message.userSS.local`, 'You started screenshare')
              : t(
                  `Message.userSS.external`,
                  '{{user}} has started screenshare',
                  { user: userName }
                )}
          </SystemUserEvent>
        )
      case CHAT_CONTENT_TYPES.STOP_SS:
        return (
          <SystemUserEvent>
            {fromLocalUser
              ? t(`Message.userStopSS.local`, 'You stopped screenshare')
              : t(
                  `Message.userStopSS.external`,
                  '{{user}} stopped screenshare',
                  { user: userName }
                )}
          </SystemUserEvent>
        )
      case CHAT_CONTENT_TYPES.MAINTENANCE:
        return (
          <SystemMaintenanceEvent
            icon={
              <Icon className={classes.icon} name={Icons.actionSettingsAlt} />
            }
            {...baseProps}
          />
        )
      case CHAT_CONTENT_TYPES.H_STOP_SS: {
        if (aboutLocalUser && localUserIsHost) {
          return (
            <SystemUserEvent>
              {t('Message.userStopSS.local', 'You stopped screenshare')}
            </SystemUserEvent>
          )
        } else if (aboutLocalUser && !localUserIsHost) {
          return (
            <SystemUserEvent>
              {t('Message.hostStopMySS', 'Host stopped your screenshare')}
            </SystemUserEvent>
          )
        }
        return (
          <SystemUserEvent>
            {t(
              'Message.userStopSS.external',
              'Host stopped screenshare of {{user}}',
              { user: userNameAbout }
            )}
          </SystemUserEvent>
        )
      }
      case CHAT_CONTENT_TYPES.START_RECORDING:
        return (
          <SystemUserEvent>
            {fromLocalUser
              ? t('Message.userStartRecording.local', 'You started recording')
              : t(
                  'Message.userStartRecording.external',
                  '{{user}} started recording',
                  { user: userName }
                )}
          </SystemUserEvent>
        )
      case CHAT_CONTENT_TYPES.STOP_RECORDING:
        return (
          <SystemUserEvent>
            {fromLocalUser
              ? t('Message.userStopRecording.local', 'You stopped recording')
              : t(
                  'Message.userStopRecording.external',
                  '{{user}} stopped recording',
                  { user: userName }
                )}
          </SystemUserEvent>
        )
      case CHAT_CONTENT_TYPES.REQUEST_SS: {
        return (
          !senderOffline && (
            <ListItem
              className={classes.listitem}
              subtle
              primaryText={userName}
              secondaryText={t('Message.requestedSs', { user: userName })}
            >
              <Avatar slot='leading' memberId={memberId} />
              <IconButton
                small
                slot='trailing'
                data-testid={id('RoomMessages.acceptScreenShare')}
                id={`acceptSSRequestFrom${userName}`}
                variant='tertiary'
                onClick={handleAcceptRequest}
                iconName={Icons.actionCheckmark}
              />
              <IconButton
                small
                slot='trailing'
                data-testid={id('RoomMessages.declineScreenShare')}
                id={`denySSRequestFrom${userName}`}
                variant='tertiary'
                onClick={handleRejectRequest}
                iconName={Icons.actionClose}
              />
            </ListItem>
          )
        )
      }

      case CHAT_CONTENT_TYPES.REQUEST_SS_REJECTED:
        return (
          <SystemUserEvent>
            {t('Message.rejectedSs', 'You rejected {{user}} to screenshare', {
              user: userNameAbout,
            })}
          </SystemUserEvent>
        )
      case CHAT_CONTENT_TYPES.REQUEST_SS_ACCEPTED:
        return (
          <SystemUserEvent>
            {t('Message.acceptedSs', 'You accepted {{user}} to screenshare', {
              user: userNameAbout,
            })}
          </SystemUserEvent>
        )
      case CHAT_CONTENT_TYPES.ROOM_MUTE_VIDEO:
        return (
          !localUserIsHost && (
            <SystemRoomEvent>
              {t('Message.hostMutedVideo.external', 'Host disabled room video')}
            </SystemRoomEvent>
          )
        )
      case CHAT_CONTENT_TYPES.ROOM_UNMUTE_VIDEO:
        return (
          !localUserIsHost && (
            <SystemRoomEvent>
              {t('Message.hostMutedVideo.external', 'Host enabled room video')}
            </SystemRoomEvent>
          )
        )
      case CHAT_CONTENT_TYPES.ROOM_MUTE_AUDIO:
        return (
          !localUserIsHost && (
            <SystemRoomEvent>
              {t('Message.hostMutedAudio.external', 'Host disabled room audio')}
            </SystemRoomEvent>
          )
        )
      case CHAT_CONTENT_TYPES.ROOM_UNMUTE_AUDIO:
        return (
          !localUserIsHost && (
            <SystemRoomEvent>
              {t(
                'Message.hostUnmutedAudio.external',
                'Host enabled room audio'
              )}
            </SystemRoomEvent>
          )
        )
      case CHAT_CONTENT_TYPES.NEW_FILE:
        return (
          !!fileId && !!filesById[fileId] && <RoomFileEvent fileId={fileId} />
        )
      case CHAT_CONTENT_TYPES.VIDEO_HIDDEN:
        if (!localUserIsHost) {
          return (
            <SystemUserEvent>
              {status
                ? t(
                    'Message.hostHideVideo.external',
                    'Host disabled your video'
                  )
                : t(
                    'Message.hostUnhideVideo.external',
                    'Host enabled your video'
                  )}
            </SystemUserEvent>
          )
        }
        break
      case CHAT_CONTENT_TYPES.AUDIO_MUTE:
        if (!localUserIsHost) {
          return (
            <SystemUserEvent>
              {status
                ? t(
                    'Message.hostMuted.external',
                    'Host disabled your microphone'
                  )
                : t(
                    'Message.hostUnmuted.external',
                    'Host enabled your microphone'
                  )}
            </SystemUserEvent>
          )
        }
        break
      case CHAT_CONTENT_TYPES.DELETED_FILE:
        return (
          <SystemUserEvent>
            {t('Message.fileDeleted', '{{user}} deleted file', {
              user: userNameAbout,
            })}
          </SystemUserEvent>
        )
      case CHAT_CONTENT_TYPES.TOBEUPLOADED:
      default:
        return ''
    }
  }, [
    type,
    t,
    userNameAbout,
    localUserIsHost,
    fromLocalUser,
    userName,
    classes.icon,
    classes.listitem,
    baseProps,
    fileId,
    filesById,
    status,
    aboutLocalUser,
    senderOffline,
    memberId,
    id,
    handleAcceptRequest,
    handleRejectRequest,
  ])
}

export default RoomMessageContainer
