import type { SVGProps } from 'react'
const SvgEmoji18 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle
      cx={79.64}
      cy={94.42}
      r={48.66}
      fill='rgb(var(--color-brand-100))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.63 145.08c-27.93 0-50.66-22.72-50.66-50.66s22.72-50.66 50.66-50.66 50.66 22.72 50.66 50.66-22.72 50.66-50.66 50.66zm0-97.31c-25.73 0-46.66 20.93-46.66 46.66s20.93 46.66 46.66 46.66 46.66-20.93 46.66-46.66-20.93-46.66-46.66-46.66z'
    />
    <circle
      cx={52.89}
      cy={96.23}
      r={11.99}
      fill='rgb(var(--color-brand-200))'
    />
    <circle
      cx={106.38}
      cy={96.23}
      r={11.99}
      fill='rgb(var(--color-brand-200))'
    />
    <circle
      cx={52.89}
      cy={91.3}
      r={11.99}
      fill='rgb(var(--color-neutral-50))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M52.89 104.54c-7.3 0-13.25-5.94-13.25-13.24s5.94-13.24 13.25-13.24S66.13 84 66.13 91.3s-5.94 13.24-13.24 13.24zm0-23.99c-5.92 0-10.75 4.82-10.75 10.74s4.82 10.74 10.75 10.74 10.74-4.82 10.74-10.74-4.82-10.74-10.74-10.74z'
    />
    <circle cx={52.89} cy={91.3} r={3.1} fill='rgb(var(--color-brand-700))' />
    <circle
      cx={106.38}
      cy={91.3}
      r={11.99}
      fill='rgb(var(--color-neutral-50))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M106.38 104.54c-7.3 0-13.24-5.94-13.24-13.24s5.94-13.24 13.24-13.24S119.62 84 119.62 91.3s-5.94 13.24-13.24 13.24zm0-23.99c-5.92 0-10.74 4.82-10.74 10.74s4.82 10.74 10.74 10.74 10.74-4.82 10.74-10.74-4.82-10.74-10.74-10.74z'
    />
    <circle cx={106.38} cy={91.3} r={3.1} fill='rgb(var(--color-brand-700))' />
    <rect
      width={19.01}
      height={27.33}
      x={70.13}
      y={98.77}
      fill='rgb(var(--color-brand-400))'
      rx={5.8}
      ry={5.8}
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M83.34 127.11h-7.41c-3.75 0-6.8-3.05-6.8-6.8v-15.73c0-3.75 3.05-6.8 6.8-6.8h7.41c3.75 0 6.8 3.05 6.8 6.8v15.73c0 3.75-3.05 6.8-6.8 6.8zm-7.41-27.33c-2.65 0-4.8 2.15-4.8 4.8v15.73c0 2.65 2.15 4.8 4.8 4.8h7.41c2.65 0 4.8-2.15 4.8-4.8v-15.73c0-2.65-2.15-4.8-4.8-4.8h-7.41z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M79.9 127.63c.62 0 1.23-.04 1.83-.1 3.2-.46 5.82-2.66 6.89-5.61-2.2-2.49-5.41-4.05-8.99-4.05s-6.78 1.57-8.99 4.05c1.07 2.95 3.69 5.16 6.89 5.61.6.06 1.21.09 1.83.1h.54z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.9 128.88h-.54c-.66 0-1.32-.04-1.95-.1-3.68-.52-6.7-2.99-7.94-6.43-.16-.43-.06-.91.24-1.25 2.52-2.84 6.14-4.47 9.92-4.47s7.4 1.63 9.92 4.47c.3.34.4.82.24 1.25a9.842 9.842 0 0 1-7.89 6.43c-.69.07-1.34.1-1.99.11h-.01zm-7.79-6.69a7.334 7.334 0 0 0 5.6 4.1c.51.05 1.09.08 1.67.08h.52c.58 0 1.16-.04 1.72-.09 2.4-.34 4.48-1.89 5.54-4.1-2.01-1.96-4.71-3.07-7.52-3.07s-5.51 1.11-7.52 3.07z'
    />
  </svg>
)
export default SvgEmoji18
