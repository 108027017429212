import 'webrtc-adapter'

/* eslint-disable */
//Creates MediaStream object.
window.MediaStream = typeof MediaStream === 'function' ? MediaStream : null
//The RTCPeerConnection object.
window.RTCPeerConnection =
  typeof RTCPeerConnection === 'function' ? RTCPeerConnection : null
// Creates RTCSessionDescription object for Plugin Browsers
window.RTCSessionDescription =
  typeof RTCSessionDescription === 'function' ? RTCSessionDescription : null
// Creates RTCIceCandidate object for Plugin Browsers
window.RTCIceCandidate =
  typeof RTCIceCandidate === 'function' ? RTCIceCandidate : null
// Get UserMedia (only difference is the prefix).
// Code from Adam Barth.
window.getUserMedia = typeof getUserMedia === 'function' ? getUserMedia : null
// Set AudioContext globally as AudioContext instead of webkitAudioContext
window.AudioContext = window.AudioContext || window.webkitAudioContext || null

const captureStreamMethods = [
  'captureStream',
  'mozCaptureStream',
  'webkitCaptureStream',
]
captureStreamMethods.forEach((key) => {
  if (HTMLMediaElement.prototype[key]) {
    HTMLMediaElement.prototype.captureStream = HTMLMediaElement.prototype[key]
  }
  if (HTMLCanvasElement.prototype[key]) {
    HTMLCanvasElement.prototype.captureStream = HTMLCanvasElement.prototype[key]
  }
})
