import { Grid, useMediaQuery } from '@mui/material'
import clsx from 'clsx'
import { useWindowSize } from 'usehooks-ts'

import { makeStyles, useTheme } from 'app/ui'
import LanguageDropdown from 'ui/language-dropdown'
import Paper from 'ui/paper'
import { Logo, Pictogram } from 'ui/revision'
import Imprints from 'ui/revision/imprints'
import UserFeedback from 'ui/user-feedback'

const useStyles = makeStyles(
  () => ({
    '@global': {
      body: {
        backgroundColor: 'rgba(var(--color-brand-50)) !important',
      },
    },
    root: {
      height: '100vh',
      maxWidth: '688px',
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      overflowY: 'auto',
    },
    corner: {
      position: 'absolute',
      padding: 'var(--spacing-2)',
    },
    topRight: {
      top: '0',
      right: '0',
    },
    bottomRight: {
      bottom: '0',
      right: '0',
    },
    logo: {
      maxHeight: '4rem',
    },
    background: {
      position: 'absolute',
      zIndex: -1,
      width: '100%',
      height: '100%',
      transform: (props) => `scale(${props.scale})`,
      '& svg': {
        width: '100% !important',
        height: '100% !important',
        position: 'absolute',
        top: 0,
        left: 0,
        objectFit: 'contain',
      },
    },
  }),
  { name: 'home' }
)

const bgWidth = 1424
const bgHeight = 1024
const bgRatio = bgWidth / bgHeight

type Props = {
  children: React.ReactNode
  className?: string
}

const Home = ({ children }: Props): JSX.Element => {
  const win = useWindowSize()
  const scale = 1 + Math.abs(bgRatio - win.width / win.height)
  const classes = useStyles({ scale })
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <>
      {isMdUp && (
        <Pictogram
          className={classes.background}
          name='background--connecting'
        />
      )}
      <div className={clsx(classes.corner, classes.topRight)}>
        <LanguageDropdown fullWidth />
      </div>
      {isMdUp && (
        <div className={clsx(classes.corner, classes.bottomRight)}>
          <Imprints className={classes.links} />
        </div>
      )}
      <UserFeedback position='bottom' />

      <Paper padding className={clsx(classes.root)}>
        <Grid
          container
          direction='column'
          style={{ height: '100%' }}
          wrap='nowrap'
        >
          <Grid container justifyContent='center' style={{ flexGrow: 0 }}>
            <Logo />
          </Grid>

          <Grid container direction='column' style={{ flexGrow: 1 }}>
            {children}
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default Home
