import PropTypes from 'prop-types'

import EventIcon from '../event-icon'

const RoomEventIcon = ({ icon }) => {
  return <EventIcon>{icon}</EventIcon>
}

RoomEventIcon.propTypes = {
  icon: PropTypes.element,
}

RoomEventIcon.defaultProps = {
  icon: undefined,
}

export default RoomEventIcon
