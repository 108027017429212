import Grid from '@mui/material/Grid'
import { useEffect, useRef, useState } from 'react'

import { useId } from 'app/react/hooks/id-registry.hooks'
import { makeStyles } from 'app/ui'
import { UPLOAD_MIME_TYPES } from 'constants/constants'
import { File } from 'ui/icon-button'

const acceptedMimeTypes = UPLOAD_MIME_TYPES.join(', ')

const useStyles = makeStyles({
  root: {
    position: 'relative',
    backgroundColor: 'rgba(var(--color-brand-50))',
  },
  fileInput: {
    display: 'none',
  },
})

type Props = {
  children?: React.ReactNode
  disableFileUpload?: boolean
  disabled?: boolean
  emojiPicker?: React.ComponentType<{ disabled?: boolean; width: number }>
  onFilesSelected?: (files: FileList) => void
}

const ChatInputToolbar: React.FC<Props> = ({
  children,
  disableFileUpload,
  disabled,
  emojiPicker: EmojiPicker,
  onFilesSelected,
}: Props) => {
  const [width, setWidth] = useState(0)
  const container = useRef(null)
  const fileInput = useRef(null)
  const { id } = useId()
  const classes = useStyles()

  useEffect(() => {
    setWidth(container.current.scrollWidth)
  }, [])

  function handleFileChange(e) {
    if (e.target === fileInput.current && fileInput.current.files) {
      onFilesSelected(fileInput.current.files)
      fileInput.current.value = ''
    }
  }

  function handleOpenExplorer() {
    if (fileInput.current) fileInput.current.click()
  }

  return (
    <Grid container item className={classes.root} ref={container}>
      {EmojiPicker && <EmojiPicker disabled={disabled} width={width} />}
      {onFilesSelected && (
        <>
          <File
            data-testid={id('ChatInput.fileUpload')}
            disabled={disableFileUpload || disabled}
            variant='tertiary'
            onClick={handleOpenExplorer}
          />
          <input
            multiple
            accept={acceptedMimeTypes}
            className={classes.fileInput}
            data-testid={id('ChatInput.groupchatTabFile')}
            disabled={disableFileUpload || disabled}
            ref={fileInput}
            type='file'
            onChange={handleFileChange}
          />
        </>
      )}

      {children}
    </Grid>
  )
}

export default ChatInputToolbar
