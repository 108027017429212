import { Grid } from '@mui/material'

import { makeStyles } from 'app/ui'
import { Typography } from 'ui/revision'

const useStyles = makeStyles({
  root: {
    '--padding-size': 'calc(var(--spacing-1) + var(--spacing-half))',
    width: 'fit-content',
    margin: '0 auto',
    maxWidth: 'calc(100% - var(--spacing-2))',
    textAlign: 'center',
    '& cl-typography': {
      overflow: 'hidden',
      overflowWrap: 'break-word',
    },
  },
})

export interface Props {
  children: React.ReactNode
}

const SystemRoomEvent = ({ children }: Props) => {
  const classes = useStyles()
  return (
    <Grid container item className={classes.root}>
      <Typography type='disabled' variant='microcopy'>
        {children}
      </Typography>
    </Grid>
  )
}

export default SystemRoomEvent
