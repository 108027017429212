import type { SVGProps } from 'react'
const SvgEmoji21 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <path
      fill='rgb(var(--color-brand-200))'
      d='M46.69 72.62c-.26 0-.53 0-.79-.02-7.62-.4-13.76-6.69-13.97-14.33-.13-4.76 2-9.23 5.75-12.12v.1c0 11.36 8.88 20.68 20.06 21.39a14.788 14.788 0 0 1-11.05 4.97z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M36.24 50.08c1.53 9.2 8.5 16.58 17.47 18.73a13.015 13.015 0 0 1-7.02 2.06c-.23 0-.47 0-.7-.02-6.72-.35-12.13-5.9-12.31-12.63-.08-2.98.84-5.83 2.56-8.14m1.87-6.1c-.25 0-.51.07-.75.24-4.46 3.06-7.35 8.25-7.19 14.1.24 8.54 7.1 15.58 15.63 16.02.3.02.59.02.88.02 6.23 0 11.64-3.44 14.46-8.53-.67.07-1.35.11-2.04.11-10.87 0-19.68-8.81-19.68-19.68 0-.29 0-.59.02-.88.04-.81-.62-1.4-1.33-1.4z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M112.75 72.62c-4.27 0-8.28-1.85-11.05-4.97 11.18-.71 20.06-10.03 20.06-21.39v-.1c3.74 2.88 5.88 7.35 5.75 12.12-.21 7.64-6.35 13.93-13.97 14.33-.26.01-.53.02-.79.02z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M123.2 50.08c1.72 2.31 2.65 5.16 2.56 8.14-.19 6.73-5.6 12.28-12.31 12.63a13.015 13.015 0 0 1-7.72-2.04c8.97-2.15 15.94-9.53 17.47-18.73m-1.87-6.1c-.71 0-1.37.59-1.33 1.4.01.29.02.58.02.88 0 10.87-8.81 19.68-19.68 19.68-.69 0-1.37-.04-2.04-.11 2.82 5.09 8.23 8.53 14.46 8.53.29 0 .59 0 .88-.02 8.53-.44 15.39-7.49 15.63-16.02.16-5.86-2.72-11.05-7.19-14.1-.24-.16-.5-.24-.75-.24z'
    />
    <circle cx={79.4} cy={96.28} r={48.66} fill='rgb(var(--color-brand-100))' />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.4 146.94c-27.93 0-50.66-22.72-50.66-50.66S51.46 45.62 79.4 45.62s50.66 22.72 50.66 50.66-22.72 50.66-50.66 50.66zm0-97.31c-25.73 0-46.66 20.93-46.66 46.66s20.93 46.66 46.66 46.66 46.66-20.93 46.66-46.66-20.93-46.66-46.66-46.66z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M79.4 111.89c-5.15 0-10.07-.98-14.58-2.76-1.34-.53-2.81.43-2.82 1.87v.13c0 8.3 7.79 15.04 17.41 15.04s17.41-6.73 17.41-15.04V111c-.01-1.43-1.49-2.39-2.82-1.87a39.713 39.713 0 0 1-14.58 2.76z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.4 127.16c-10.15 0-18.41-7.19-18.41-16.04v-.13c0-1 .51-1.93 1.34-2.49.84-.57 1.91-.69 2.85-.31 4.53 1.78 9.31 2.69 14.22 2.69s9.69-.9 14.22-2.69c.94-.37 2.01-.25 2.85.31a3.02 3.02 0 0 1 1.34 2.48v.14c0 8.84-8.26 16.04-18.41 16.04zm-15.35-17.18c-.21 0-.42.06-.6.19-.29.19-.45.5-.46.84v.12c0 7.74 7.36 14.04 16.41 14.04s16.41-6.3 16.41-14.04V111c0-.33-.17-.63-.46-.83-.3-.2-.66-.24-1-.11-4.77 1.88-9.8 2.83-14.95 2.83s-10.19-.95-14.95-2.83c-.13-.05-.26-.08-.4-.08z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M79.4 126.16c3.72 0 7.17-1.01 10-2.73-1.81-3.72-5.61-6.29-10-6.29s-8.2 2.57-10 6.29c2.83 1.72 6.28 2.73 10 2.73z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.4 127.16c-3.78 0-7.42-1-10.52-2.88a.996.996 0 0 1-.38-1.29c2.05-4.23 6.23-6.85 10.9-6.85s8.85 2.62 10.9 6.85c.23.46.06 1.02-.38 1.29-3.1 1.88-6.74 2.88-10.52 2.88zm-8.66-4.12c2.6 1.39 5.57 2.12 8.66 2.12s6.06-.73 8.66-2.12c-1.83-3.05-5.07-4.9-8.66-4.9s-6.83 1.86-8.66 4.9z'
    />
    <circle
      cx={52.89}
      cy={91.3}
      r={11.99}
      fill='rgb(var(--color-neutral-50))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M52.89 104.54c-7.3 0-13.25-5.94-13.25-13.24s5.94-13.24 13.25-13.24S66.13 84 66.13 91.3s-5.94 13.24-13.24 13.24zm0-23.99c-5.92 0-10.75 4.82-10.75 10.74s4.82 10.74 10.75 10.74 10.74-4.82 10.74-10.74-4.82-10.74-10.74-10.74z'
    />
    <circle cx={52.89} cy={91.3} r={3.1} fill='rgb(var(--color-brand-700))' />
    <circle
      cx={106.38}
      cy={91.3}
      r={11.99}
      fill='rgb(var(--color-neutral-50))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M106.38 104.54c-7.3 0-13.24-5.94-13.24-13.24s5.94-13.24 13.24-13.24S119.62 84 119.62 91.3s-5.94 13.24-13.24 13.24zm0-23.99c-5.92 0-10.74 4.82-10.74 10.74s4.82 10.74 10.74 10.74 10.74-4.82 10.74-10.74-4.82-10.74-10.74-10.74z'
    />
    <circle cx={106.38} cy={91.3} r={3.1} fill='rgb(var(--color-brand-700))' />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M90.13 88.29V77.62c0-1.08.88-1.96 1.96-1.96s1.96.88 1.96 1.96v4.63l19.13-8.5c.99-.44 2.15 0 2.59 1 .44.99 0 2.15-1 2.59L90.12 88.29zm-20.81 0L44.67 77.34c-.99-.44-1.44-1.6-1-2.59.44-.99 1.6-1.43 2.59-1l19.13 8.5v-4.63c0-1.08.88-1.96 1.96-1.96s1.96.88 1.96 1.96v10.67z'
    />
  </svg>
)
export default SvgEmoji21
