import { Icon } from 'ui/revision'

const FourSquares = (props) => (
  <Icon {...props}>
    <svg viewBox='0 0 96 96'>
      <path
        d='M14 82C12 80 12 79 12 68 12 57 12 56 14 54 16 52 17 52 28 52 44 52 44 52 44 68 44 84 44 84 28 84 17 84 16 84 14 82Z
      M54 82C52 80 52 79 52 68 52 57 52 56 54 54 56 52 57 52 68 52 84 52 84 52 84 68 84 79 84 80 82 82 80 84 79 84 68 84 57 84 56 84 54 82Z
      M14 42C12 40 12 39 12 28 12 12 12 12 28 12 44 12 44 12 44 28 44 39 44 40 42 42 40 44 39 44 28 44 17 44 16 44 14 42Z
      M54 42C52 40 52 39 52 28 52 17 52 16 54 14 57 11 79 11 82 14 84 16 84 17 84 28 84 44 84 44 68 44 57 44 56 44 54 42Z'
      />
    </svg>
  </Icon>
)

export default FourSquares
