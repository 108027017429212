import { type HTMLAttributes } from 'react'
import { Box } from '@mui/material'
import { ClDialog } from '@enreach/core-component-library-react'
import { defineCustomElementClDialog } from '@enreach/core-component-library/dist/components/index'
import cn from 'clsx'

import { makeStyles } from 'app/ui'
import { Lightbox } from 'ui/revision'

import { type DialogProps } from './dialog.types'

const useStyles = makeStyles(
  () => ({
    lightbox: (props) => ({
      alignItems: props.fullscreen ? 'stretch' : 'center',
      display: 'inline-flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
    }),
    dialog: {
      // Header and subheader strings can have linebreaks that need to be shown
      whiteSpace: 'pre-wrap',
    },
  }),
  { name: 'Dialog' }
)

export const Dialog = ({
  backdropProps = {},
  direction = 'column',
  fullscreen = false,
  ...dialogProps
}: DialogProps) => {
  const classes = useStyles({ fullscreen })

  return (
    <Lightbox
      {...backdropProps}
      className={cn(classes.lightbox, backdropProps.className)}
      open={dialogProps.open}
    >
      <ClDialog
        {...dialogProps}
        className={cn(classes.dialog, dialogProps.className)}
        open={dialogProps.open}
        vertical={direction === 'column'}
      />
    </Lightbox>
  )
}

export const DialogContent = (props: HTMLAttributes<HTMLDivElement>) => (
  <div {...props} slot='content' />
)

export const DialogPictogram = (props: HTMLAttributes<HTMLDivElement>) => (
  <div {...props} slot='pictogram' />
)

export const DialogActions = (props: HTMLAttributes<HTMLDivElement>) => (
  <Box
    {...props}
    component='div'
    display='flex'
    columnGap='var(--size-2)px'
    justifyContent='center'
    slot='actions'
  />
)

export default Dialog

defineCustomElementClDialog()
