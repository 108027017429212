import { Link as RouterLink } from 'react-router-dom'

import { Link, Typography } from 'ui/revision'

type Props = React.ComponentPropsWithoutRef<typeof RouterLink> &
  React.ComponentPropsWithoutRef<typeof Link>

export const ColigoLink: React.FC<Props> = ({ children, ...other }: Props) => {
  return (
    <RouterLink {...other} style={{ color: 'inherit' }}>
      {children}
    </RouterLink>
  )
}

export default ColigoLink
