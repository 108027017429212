import ClickAwayListener from '@mui/material/ClickAwayListener'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import Slide from '@mui/material/Slide'
import { type TransitionProps } from '@mui/material/transitions/transition'
import useMediaQuery from '@mui/material/useMediaQuery'
import clsx from 'clsx'
import { KeyBindingUtil } from 'draft-js'
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type ReactElement,
} from 'react'

import { makeStyles } from 'app/ui'
import useTheme from 'app/ui/useTheme'
import MessagesWrapper from 'ui/messages-wrapper'

import PrivateChatHeader from './private-chat-header'
import PrivateChatInputContainer from './private-chat-input.container'
import { Divider } from 'ui/revision'

const CLOSE_DELAY = 500

const useStyles = makeStyles((theme) => ({
  messagesWrapper: {
    flex: '1 1',
    minHeight: 150,
    backgroundColor: theme.palette.background.default,
  },
  gridContainer: {
    position: 'relative',
    height: '100%',
    animation: 'fadeIn 200ms ease forwards alternate',
  },
}))

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref
) {
  return <Slide direction='up' ref={ref} {...props} />
})

export interface PrivateChatProps {
  chatId: string
  messages: ReactElement[]
  chatTempMessage: string
  focused?: boolean
  minimize?: boolean
  offline?: boolean
  typing?: boolean
  userName: string
  onClose?: () => void
  onFocus?: () => void
  onUnfocus?: () => void
}

export const PrivateChat: React.FC<PrivateChatProps> = ({
  messages = [],
  chatTempMessage = '',
  chatId,
  focused = false,
  minimize = false,
  offline = false,
  typing = false,
  userName = '',
  onClose,
  onFocus,
  onUnfocus,
}: PrivateChatProps) => {
  const [animateDown, setAnimateDown] = useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()

  const inputRef = useRef<HTMLInputElement>(null)

  let openDelay: ReturnType<typeof setTimeout> | null = null
  let closeDelay: ReturnType<typeof setTimeout> | null = null

  /**
   * Focus input used when chatcontainer's animation is finished for maximize
   */
  const focusInput = useCallback(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef])

  /**
   * Blur input field
   */
  const blurInput = useCallback(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.blur()
    }
  }, [inputRef])

  /**
   * When not already being focused we wan't to trigger `onFocus` function
   * and add the `keydown` event.
   */
  const focusChatIfNotFocused = useCallback(() => {
    if (!focused && onFocus) {
      onFocus()
    }
  }, [focused, onFocus])

  /**
   * Focusing the private-chat DOM with a certain delay (CLOSE_DELAY)
   * to make sure that the DOM leaves after the animate to be finished`
   */
  const focusChatWithOpenDelay = useCallback(() => {
    if (!openDelay) {
      openDelay = setTimeout(() => {
        focusInput()
        openDelay = null
      }, CLOSE_DELAY)
    }
  }, [openDelay, focusInput])

  /**
   * Closing the private-chat DOM with a certain delay (CLOSE_DELAY)
   * to make sure that the DOM leaves after the animate to be finished
   */
  const closeChatWithDelayIfNotClosing = useCallback(() => {
    blurInput()

    if (!animateDown && !minimize) {
      setAnimateDown(true)

      closeDelay = setTimeout(() => {
        onClose && onClose()
        setAnimateDown(false)
      }, CLOSE_DELAY)
    } else {
      onClose && onClose()
      setAnimateDown(false)
    }
  }, [blurInput, animateDown, minimize, setAnimateDown, onClose])

  useEffect(() => {
    focusChatIfNotFocused()
    return onUnfocus
  }, [])

  useEffect(() => {
    if (focused) {
      focusChatWithOpenDelay()
    }
    return () => {
      if (closeDelay) {
        clearTimeout(closeDelay)
      }
      if (openDelay) {
        clearTimeout(openDelay)
      }
    }
  }, [focused])

  /**
   * Handling clicking the close button of the private-chat
   */
  const handleClickClose = closeChatWithDelayIfNotClosing

  useEffect(() => {
    // When a user goes offline, animate the close event of the private-chat
    if (offline && onClose) onClose()
  }, [offline])

  const handleKeyDown = useCallback(
    (event) => {
      if (
        /^[a-zA-Z]$/.test(event.key) &&
        !KeyBindingUtil.hasCommandModifier(event)
      ) {
        focusInput()
      }
    },
    [focusInput]
  )

  const addKeydownEventListener = useCallback(() => {
    document.addEventListener('keydown', handleKeyDown, true)
  }, [handleKeyDown])

  const removeKeydownEventListener = useCallback(() => {
    document.removeEventListener('keydown', handleKeyDown, true)
  }, [handleKeyDown])

  const privateChatHeader = useMemo(
    () => (
      <PrivateChatHeader
        offline={offline}
        typing={typing}
        userName={userName}
        onClose={handleClickClose}
      />
    ),
    [handleClickClose, offline, typing, userName]
  )

  const privateChatMessageListContainer = useMemo(
    () => (
      <MessagesWrapper
        autoAnchor
        className={clsx(classes.messagesWrapper)}
        rowCount={messages.length}
      >
        {messages}
      </MessagesWrapper>
    ),
    [classes.messagesWrapper, messages]
  )

  const privateChatInputContainer = useMemo(
    () => (
      <PrivateChatInputContainer
        chatId={chatId}
        disabled={offline || animateDown}
        inputRef={inputRef}
        rows={3}
        tempMessage={chatTempMessage}
      />
    ),
    [animateDown, chatId, chatTempMessage, offline]
  )

  const chatWindow = useMemo(
    () => (
      <ClickAwayListener onClickAway={removeKeydownEventListener}>
        <Grid
          container
          className={classes.gridContainer}
          direction='column'
          onClick={addKeydownEventListener}
        >
          {privateChatHeader}
          <Divider spacing={0} />
          {privateChatMessageListContainer}
          {privateChatInputContainer}
        </Grid>
      </ClickAwayListener>
    ),
    [
      addKeydownEventListener,
      classes.gridContainer,
      privateChatHeader,
      privateChatInputContainer,
      privateChatMessageListContainer,
      removeKeydownEventListener,
    ]
  )

  return fullScreen ? (
    <Dialog
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      open={!animateDown}
    >
      {chatWindow}
    </Dialog>
  ) : (
    <ClickAwayListener onClickAway={onUnfocus}>
      <div className='chatFloater'>
        <div
          className={clsx('chatContainer', {
            'chatContainer--slideDown': animateDown,
          })}
        >
          {chatWindow}
        </div>
      </div>
    </ClickAwayListener>
  )
}

export default PrivateChat
