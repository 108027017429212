import dotProp from 'dot-prop-immutable'
import _ from 'lodash'

import localStore from 'app/services/state/local-store'
import { getSocket } from '../socket'

import { handleMessage, createStateLoggingActionHandler } from './utils'

const channelConf = {
  id: 'Summa.IAM.Api.Authenticate',
  conf: { version: '2.0' },
}

export const STORE_NAME = 'channel/auth'

export const CHANNEL_ERROR_CODES = {
  INVALID_CREDENTIALS: 'YFZ01005',
  INVALID_TOKEN: 'YFZ01007',
}

/**
 * channel instance
 * @param
 */
let channel = null

// ------------------------------------
// Channel events
// ------------------------------------
const channelEventActionMapping = {}

// ------------------------------------
// Actions
// ------------------------------------
const JOINING = 'sm-web/channel/auth/JOINING'
const JOINED = 'sm-web/channel/auth/JOINED'
const JOINING_ERROR = 'sm-web/channel/auth/JOINING_ERROR'
const JOINING_TIMEOUT = 'sm-web/channel/auth/JOINING_TIMEOUT'
const ERROR = 'sm-web/channel/auth/ERROR'
const CLOSING = 'sm-web/channel/auth/CLOSING'
const CLOSED = 'sm-web/channel/auth/CLOSED'
const CLOSING_ERROR = 'sm-web/channel/auth/CLOSING_ERROR'
const CLOSING_TIMEOUT = 'sm-web/channel/auth/CLOSING_TIMEOUT'
const LOG = 'sm-web/channel/auth/LOG'

export const ACTION_TYPES = {
  JOINING,
  JOINED,
  JOINING_ERROR,
  JOINING_TIMEOUT,
  ERROR,
  CLOSING,
  CLOSED,
  CLOSING_ERROR,
  CLOSING_TIMEOUT,
  LOG,
}

const joined = (payload) => ({ type: JOINED, payload })
const joiningError = (error) => ({ type: JOINING_ERROR, error })
const joiningTimeout = () => ({ type: JOINING_TIMEOUT })
const error = (e) => ({ type: ERROR, error: e })
const closed = (payload) => ({ type: CLOSED, payload })
const closingError = (e) => ({ type: CLOSING_ERROR, error: e })
const closingTimeout = () => ({ type: CLOSING_TIMEOUT })
const log = (event, payload) => (dispatch) => {
  if (localStore.getDebugMode()) {
    dispatch({ type: LOG, event, payload })
  }
}
/**
 * exported action to join channel
 */
const join =
  () =>
  async (dispatch): Promise<unknown> =>
    new Promise((resolve, reject) => {
      dispatch({ type: JOINING })
      const socket = getSocket()
      channel = socket.channel(channelConf.id, channelConf.conf)
      channel.onMessage = handleMessage(
        dispatch,
        channelEventActionMapping,
        channelConf,
        log
      )
      channel.onError((event) => dispatch(error(event)))
      channel.onClose((event) => dispatch(closed(event)))
      channel
        .join()
        .receive('ok', (payload) => {
          dispatch(joined(payload))
          resolve(payload)
        })
        .receive('error', (e) => {
          dispatch(joiningError(e))
          reject(new Error(JSON.stringify(e)))
        })
        .receive('timeout', () => {
          dispatch(joiningTimeout())
          reject(new Error('Timeout'))
        })
    })
/**
 * exported action to leave the channel
 */
const leave =
  () =>
  async (dispatch): Promise<void> =>
    new Promise((resolve, reject) => {
      dispatch({ type: CLOSING })
      if (channel) {
        channel
          .leave()
          .receive('ok', resolve)
          .receive('error', (e) => {
            dispatch(closingError(e))
            reject(new Error(JSON.stringify(e)))
          })
          .receive('timeout', () => {
            dispatch(closingTimeout())
            reject(new Error('Timeout'))
          })
      } else {
        resolve()
      }
    })

const generateToken = async (): Promise<{ token: string }> =>
  new Promise((resolve, reject) => {
    logger.debug(`generateToken: requesting token`)
    channel
      .push('generate_token', {})
      .receive('ok', resolve)
      .receive('error', (e) => {
        reject(new Error(`"generate_token": ${JSON.stringify(e)}`))
      })
      .receive('timeout', () => {
        reject(new Error('"generate_token": Timeout'))
      })
  })

const refreshToken = async (token): Promise<{ token: string }> =>
  new Promise((resolve, reject) => {
    logger.debug(`refreshToken: refreshing token`)
    channel
      .push('refresh_token', { token })
      .receive('ok', resolve)
      .receive('error', (e) => {
        reject(new Error(`"refresh_token": ${JSON.stringify(e)}`))
      })
      .receive('timeout', () => {
        reject(new Error('"refresh_token": Timeout'))
      })
  })

/**
 */
export const actions = {
  join,
  leave,
  generateToken,
  refreshToken,
  closed,
}
// ------------------------------------
// Reducer
// ------------------------------------
const INITIAL_STATE = {
  user_id: null,
  '@@channel': {
    state: CLOSED,
    log: [],
  },
}
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [JOINING]: createStateLoggingActionHandler(JOINING, 'JOINING'),
  [JOINED]: (state, action) => ({
    ...state,
    ...createStateLoggingActionHandler(
      JOINED,
      'JOINED',
      'payload'
    )(state, action),
    user_id: _.get(action, 'payload.user_id', null),
  }),
  [JOINING_ERROR]: createStateLoggingActionHandler(
    JOINING_ERROR,
    'JOINING_ERROR',
    'error'
  ),
  [JOINING_TIMEOUT]: createStateLoggingActionHandler(
    JOINING_TIMEOUT,
    'JOINING_TIMEOUT'
  ),
  [CLOSING]: createStateLoggingActionHandler(CLOSING, 'CLOSING'),
  [CLOSED]: createStateLoggingActionHandler(CLOSED, 'CLOSED'),
  [CLOSING_ERROR]: createStateLoggingActionHandler(
    CLOSING_ERROR,
    'CLOSING_ERROR',
    'error'
  ),
  [CLOSING_TIMEOUT]: createStateLoggingActionHandler(
    CLOSING_TIMEOUT,
    'CLOSING_TIMEOUT'
  ),
  [ERROR]: createStateLoggingActionHandler(ERROR, 'ERROR', 'error'),
  [LOG]: (state, action) =>
    dotProp.set(state, '@@channel.log', (l) => [
      ...l,
      {
        ts: Date.now(),
        kind: 'event',
        msg: action.event,
        data: action.payload,
      },
    ]),
  'sm-web/RESET': () => INITIAL_STATE,
}

export default (state = INITIAL_STATE, action = {}) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export const selectors = {
  getChannelState(state) {
    if (!state || typeof state !== 'object') {
      const err = new Error('Given state should be an object')
      err.state = state
      throw err
    }
    return _.get(state, '@@channel.state')
  },
  isJoining(state) {
    const channelState = selectors.getChannelState(state)
    return channelState === JOINING
  },
  isJoiningError(state) {
    const channelState = selectors.getChannelState(state)
    return channelState === JOINING_ERROR
  },
  isJoiningTimeout(state) {
    const channelState = selectors.getChannelState(state)
    return channelState === JOINING_TIMEOUT
  },
  isJoined(state) {
    const channelState = selectors.getChannelState(state)
    return channelState === JOINED
  },
  isClosing(state) {
    const channelState = selectors.getChannelState(state)
    return channelState === CLOSING
  },
  isClosed(state) {
    const channelState = selectors.getChannelState(state)
    return channelState === CLOSED
  },

  getUserId(state) {
    return state.user_id
  },
  isRegisteredUser(state) {
    return !!selectors.getUserId(state)
  },
}
