import type { SVGProps } from 'react'
const SvgEmoji17 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle
      cx={79.83}
      cy={96.28}
      r={48.66}
      fill='rgb(var(--color-brand-100))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.83 146.94c-27.93 0-50.66-22.72-50.66-50.66s22.72-50.66 50.66-50.66 50.66 22.72 50.66 50.66-22.72 50.66-50.66 50.66zm0-97.31c-25.73 0-46.66 20.93-46.66 46.66s20.93 46.66 46.66 46.66 46.66-20.93 46.66-46.66-20.93-46.66-46.66-46.66z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M70.61 119.1a1.82 1.82 0 0 1-1.3-3.1c2.63-2.67 6.46-4.2 10.52-4.2s7.89 1.53 10.51 4.2c.71.72.7 1.87-.02 2.58s-1.87.7-2.58-.02c-1.95-1.98-4.83-3.11-7.91-3.11s-5.97 1.14-7.91 3.11c-.36.36-.83.55-1.3.55zM52.04 96.27c-2.39 0-4.7-.7-6.55-2.01a1.824 1.824 0 0 1-.43-2.54c.58-.82 1.72-1.01 2.54-.43 1.62 1.15 3.8 1.59 5.98 1.2 2.18-.39 4.08-1.55 5.2-3.19.57-.83 1.71-1.04 2.54-.47.83.57 1.04 1.71.47 2.54-1.67 2.44-4.43 4.16-7.58 4.71-.73.13-1.46.19-2.18.19zm55.47 0c-.72 0-1.45-.06-2.18-.19-3.14-.56-5.9-2.28-7.58-4.71-.57-.83-.36-1.97.47-2.54.83-.57 1.97-.36 2.54.47 1.12 1.64 3.02 2.8 5.2 3.19 2.19.39 4.36-.05 5.98-1.2.82-.58 1.96-.39 2.54.43.58.82.39 1.96-.43 2.54-1.85 1.32-4.16 2.01-6.55 2.01z'
    />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M58.4 112.22c0 3.51-2.85 6.36-6.36 6.36s-6.36-2.85-6.36-6.36c0-2.82 4.1-8.85 5.72-11.11.32-.44.97-.44 1.28 0 1.62 2.26 5.72 8.29 5.72 11.11z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M52.03 119.58c-4.06 0-7.36-3.3-7.36-7.36 0-2.98 3.7-8.61 5.91-11.69.67-.94 2.24-.94 2.91 0 2.21 3.09 5.91 8.71 5.91 11.69 0 4.06-3.3 7.36-7.36 7.36zm0-17.65c-3.31 4.67-5.36 8.6-5.36 10.29 0 2.96 2.41 5.36 5.36 5.36s5.36-2.41 5.36-5.36c0-1.69-2.05-5.62-5.36-10.29z'
    />
  </svg>
)
export default SvgEmoji17
