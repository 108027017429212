import {
  STORE_NAME as SETTINGS_STORE_NAME,
  selectors as settingsSelectors,
} from 'app/features/settings/settings.reducer'
import {
  STORE_NAME as FLAGS_STORE_NAME,
  selectors as flagsSelectors,
} from 'app/features/flags/flags.reducer'
import {
  STORE_NAME as APP_STORE_NAME,
  selectors as appSelectors,
} from 'app/features/app/app.reducer'
import { FEATURE_NAMES } from 'constants/features-map'

/**
 * If permission has not been requested
 * @returns Whether permission hasn't been requested
 */
export const isPermissionDefault = () => Notification.permission === 'default'

/**
 * If permission has been granted
 * @returns Whether permission has been granted
 */
export const isPermissionGranted = () => Notification.permission === 'granted'

/**
 * If permission has been denied
 * @returns Whether permission has been denied
 */
export const isPermissionDenied = () => Notification.permission === 'denied'

/**
 * Requests notification permission
 * @returns {Promise<NotificationPermission>} Notification permission promise
 */
export async function requestPermission() {
  try {
    return await Notification.requestPermission()
  } catch {
    // If promise based requestPermission is unsupported, fallback to callback based version
    return new Promise(Notification.requestPermission)
  }
}

/**
 * Checks if notifications are enabled
 * @param settings
 * @param settings.desktopNotificationsEnabled
 * @returns Whether notifications are enabled
 */
export function areNotificationsEnabled(settings) {
  const permissionHasBeenRequested = !isPermissionDefault()
  // If we have already requested permission once, return the user's setting
  if (permissionHasBeenRequested) {
    return settingsSelectors.getDesktopNotificationsEnabled(settings)
  }
  // Otherwise return true to create notifications for the missed activity mechanism
  return true
}

/**
 * Whether a notification should be created, based on settings, flags and if the app has blur
 * @returns
 */
export const shouldCreateNotification = ({
  [SETTINGS_STORE_NAME]: settings,
  [FLAGS_STORE_NAME]: flags,
  [APP_STORE_NAME]: app,
}) =>
  !flagsSelectors.getFeaturesFailingKey(
    FEATURE_NAMES.DESKTOP_NOTIFICATIONS,
    flags
  ).length &&
  areNotificationsEnabled(settings) &&
  !appSelectors.getHasFocus(app)
