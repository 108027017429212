import type { SVGProps } from 'react'
const SvgAgileCard20 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <rect
      width={94.33}
      height={126.14}
      x={33.78}
      y={17.68}
      fill='rgb(var(--color-neutral-50))'
      rx={8.63}
      ry={8.63}
    />
    <path
      fill='rgb(var(--color-brand-500))'
      d='M119.48 145.82H42.4c-5.86 0-10.62-4.77-10.62-10.63V26.31c0-5.86 4.77-10.63 10.62-10.63h77.08c5.86 0 10.63 4.77 10.63 10.63V135.2c0 5.86-4.77 10.63-10.63 10.63zM42.4 19.68c-3.65 0-6.62 2.97-6.62 6.63V135.2c0 3.65 2.97 6.63 6.62 6.63h77.08c3.65 0 6.63-2.97 6.63-6.63V26.31c0-3.65-2.97-6.63-6.63-6.63H42.4z'
    />
    <rect
      width={79.02}
      height={110.67}
      x={41.43}
      y={25.42}
      fill='rgb(var(--color-brand-100))'
      rx={2.78}
      ry={2.78}
    />
    <circle cx={50.06} cy={34.43} r={5.57} fill='rgb(var(--color-brand-200))' />
    <circle
      cx={111.83}
      cy={127.08}
      r={5.57}
      fill='rgb(var(--color-brand-200))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M58.21 103.03c-.69 0-1.25-.56-1.25-1.25v-4.86c0-3.36.55-6.3 1.65-8.74 1.08-2.4 3.01-5.07 5.74-7.94 2.5-2.67 4.26-4.96 5.2-6.81.91-1.78 1.37-3.65 1.37-5.58 0-2.11-.4-2.91-.64-3.2-.24-.29-.68-.67-1.76-.67-1.25 0-2.4.38-2.4 3.17v4.8c0 .69-.56 1.25-1.25 1.25h-6.66c-.69 0-1.25-.56-1.25-1.25V67.6c0-3.93 1.02-7.03 3.02-9.2 2.03-2.2 4.97-3.31 8.73-3.31s6.7 1.12 8.73 3.31c2 2.17 3.02 5.27 3.02 9.2 0 2.91-.7 5.79-2.08 8.56-1.35 2.7-3.7 5.82-6.96 9.26-2 2.12-3.35 3.85-4.02 5.13-.58 1.11-.9 2.32-.94 3.58h12.12c.69 0 1.25.56 1.25 1.25v6.4c0 .69-.56 1.25-1.25 1.25H58.23z'
    />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M68.71 56.34c3.41 0 6.02.97 7.81 2.91 1.79 1.94 2.69 4.73 2.69 8.35 0 2.73-.65 5.4-1.95 8-1.3 2.6-3.55 5.59-6.75 8.96-2.09 2.22-3.5 4.02-4.22 5.41s-1.09 2.87-1.09 4.45v.96h13.38v6.4H58.23v-4.86c0-3.2.51-5.94 1.54-8.22 1.02-2.28 2.86-4.81 5.5-7.58 2.6-2.77 4.41-5.14 5.41-7.1s1.5-4.01 1.5-6.14c0-1.92-.31-3.25-.93-4-.62-.75-1.53-1.12-2.72-1.12-2.43 0-3.65 1.47-3.65 4.42v4.8h-6.66v-4.35c0-3.63.9-6.41 2.69-8.35 1.79-1.94 4.39-2.91 7.81-2.91m0-2.5c-4.12 0-7.37 1.25-9.65 3.72-2.22 2.41-3.35 5.79-3.35 10.05v4.35a2.5 2.5 0 0 0 2.5 2.5h6.66a2.5 2.5 0 0 0 2.5-2.5v-4.8c0-1.92.46-1.92 1.15-1.92.62 0 .75.16.79.22s.35.51.35 2.41c0 1.75-.4 3.39-1.23 5.01-.89 1.74-2.57 3.94-5.01 6.53-2.85 2.99-4.81 5.7-5.96 8.27-1.16 2.6-1.75 5.71-1.75 9.25v4.86a2.5 2.5 0 0 0 2.5 2.5h20.35a2.5 2.5 0 0 0 2.5-2.5v-6.4a2.5 2.5 0 0 0-2.5-2.5H67.86c.13-.6.34-1.18.64-1.75.42-.81 1.43-2.31 3.83-4.85 3.39-3.57 5.74-6.7 7.17-9.56 1.47-2.94 2.22-6.01 2.22-9.12 0-4.26-1.13-7.64-3.35-10.05-2.28-2.47-5.52-3.72-9.65-3.72z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M94.11 103.67c-3.8 0-6.78-1.12-8.85-3.34-2.05-2.19-3.09-5.28-3.09-9.17V67.61c0-3.89 1.04-6.98 3.09-9.17 2.07-2.22 5.05-3.34 8.85-3.34s6.78 1.12 8.85 3.34c2.05 2.19 3.09 5.28 3.09 9.17v23.55c0 3.89-1.04 6.98-3.09 9.17-2.07 2.22-5.05 3.34-8.85 3.34zm0-39.68c-1.25 0-2.4.38-2.4 3.17v24.45c0 2.78 1.15 3.17 2.4 3.17s2.4-.38 2.4-3.17V67.16c0-2.78-1.15-3.17-2.4-3.17z'
    />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M94.11 56.34c3.46 0 6.1.98 7.94 2.94 1.83 1.96 2.75 4.74 2.75 8.32v23.55c0 3.58-.92 6.36-2.75 8.32s-4.48 2.94-7.94 2.94-6.1-.98-7.94-2.94c-1.83-1.96-2.75-4.74-2.75-8.32V67.6c0-3.58.92-6.36 2.75-8.32s4.48-2.94 7.94-2.94m0 39.68c2.43 0 3.65-1.47 3.65-4.42V67.15c0-2.94-1.22-4.42-3.65-4.42s-3.65 1.47-3.65 4.42V91.6c0 2.94 1.22 4.42 3.65 4.42m0-42.18c-4.16 0-7.44 1.26-9.76 3.74-2.27 2.43-3.43 5.81-3.43 10.03v23.55c0 4.22 1.15 7.6 3.43 10.03 2.32 2.48 5.6 3.74 9.76 3.74s7.45-1.26 9.76-3.74c2.27-2.43 3.43-5.81 3.43-10.03V67.61c0-4.22-1.15-7.59-3.43-10.03-2.32-2.48-5.6-3.74-9.76-3.74zm0 39.68c-.69 0-1.15 0-1.15-1.92V67.15c0-1.92.46-1.92 1.15-1.92s1.15 0 1.15 1.92V91.6c0 1.92-.46 1.92-1.15 1.92z'
    />
  </svg>
)
export default SvgAgileCard20
