import ChatInputEmojiPicker from './chat-input-emoji-picker.component'

export default () => {
  const store = {
    getEditorState: undefined,
    setEditorState: undefined,
  }

  return {
    EmojiPicker: (props) => <ChatInputEmojiPicker store={store} {...props} />,
    initialize: ({ getEditorState, setEditorState }) => {
      store.getEditorState = getEditorState
      store.setEditorState = setEditorState
    },
  }
}
