/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'clsx'

import { withStyles } from 'app/ui'

const styles = () => ({
  notLoaded: {
    visibility: 'hidden',
  },
})

const Image = ({ classes, className, src, ...other }) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(
    () => () => {
      setLoaded(false)
    },
    [src]
  )

  return (
    <img
      className={cn(className, !loaded && classes.notLoaded)}
      src={src}
      onLoad={() => setLoaded(true)}
      {...other}
    />
  )
}

Image.propTypes = {
  classes: PropTypes.shape({
    notLoaded: PropTypes.string,
  }).isRequired,
  src: PropTypes.string.isRequired,

  className: PropTypes.string,
}

Image.defaultProps = {
  className: '',
}

export default withStyles(styles, { name: 'Image' })(Image)
