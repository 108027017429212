import { ACTION_TYPES as CHANNEL_AUTH_ACTION_TYPES } from 'app/state/api/channels/channel-auth.reducer'
import { ACTION_TYPES as CHANNEL_CONFERENCE_ACTION_TYPES } from 'app/state/api/channels/channel-conference.reducer'
import { ACTION_TYPES as CHANNEL_SETTINGS_ACTION_TYPES } from 'app/state/api/channels/channel-settings.reducer'

import { actions } from './socket.reducer'

export default (store) => (next) => (action) => {
  const nextState = next(action)

  switch (action.type) {
    case CHANNEL_SETTINGS_ACTION_TYPES.JOINING_ERROR:
    case CHANNEL_SETTINGS_ACTION_TYPES.JOINING_TIMEOUT:
    case CHANNEL_AUTH_ACTION_TYPES.JOINING_ERROR:
    case CHANNEL_AUTH_ACTION_TYPES.JOINING_TIMEOUT:
    case CHANNEL_CONFERENCE_ACTION_TYPES.JOINING_ERROR:
    case CHANNEL_CONFERENCE_ACTION_TYPES.JOINING_TIMEOUT:
      store.dispatch(actions.disconnect())
      break

    default:
      break
  }

  return nextState
}
