import type { SVGProps } from 'react'
const SvgReaction6HandUp = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle cx={82.17} cy={78.48} r={62} fill='rgb(var(--color-brand-50))' />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='m97.97 54.97.21-13.09-2.1-4.18-3.9-2.07-4.41 1.57-1.64-2.25-4.4-5.08-6.23 2.82-2.06 8.07-5.98-3.15-4.21 1.95-2.58 30.55 1.4 16.6-4.84-.96-6.35-18.17-2.98-2.66-5.15.99-2.8 5.15 9.08 29.91 6.06 11.2 12.14 6.69 1.14 19.55v.49c4.44 1.01 9.07 1.57 13.82 1.57s9.45-.56 13.92-1.59l1.2-19.51 7.4-6.43 3.97-8.77.87-49-5.38-2.57L98 54.96z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M96.65 138.65h-.03c-1.1-.02-1.99-.93-1.97-2.03l.26-15.93c.04-1.89 1.09-3.58 2.75-4.42 5.81-2.93 9.5-8.79 9.63-15.29l.85-42.16c.05-2.27-1.77-4.16-4.04-4.21h-.08c-2.24 0-4.08 1.8-4.12 4.04l-.36 17.93a2 2 0 1 1-4-.08l.36-17.93c.09-4.48 3.84-8.02 8.29-7.96 2.17.04 4.19.93 5.7 2.49a8.06 8.06 0 0 1 2.26 5.79l-.85 42.16a21.473 21.473 0 0 1-11.83 18.78c-.34.17-.55.52-.56.92l-.26 15.92a2.006 2.006 0 0 1-2 1.97zM72.84 76.03h-.04c-1.1-.02-1.98-.94-1.96-2.04l.61-30.19c.02-1.1-.39-2.15-1.15-2.94a4.146 4.146 0 0 0-2.89-1.27c-1.08-.03-2.15.38-2.94 1.15s-1.24 1.79-1.27 2.89l-.53 26.49c-.02 1.1-.91 2-2.04 1.96-1.1-.02-1.98-.94-1.96-2.04l.53-26.49c.04-2.17.93-4.19 2.49-5.7a8.092 8.092 0 0 1 5.79-2.26c2.17.04 4.19.93 5.7 2.49a8.06 8.06 0 0 1 2.26 5.79l-.61 30.19a1.998 1.998 0 0 1-2 1.96zm-5.73 63h-.06c-1.1-.03-1.97-.96-1.94-2.06l.51-16.57c0-.45-.3-.88-.75-1.03a27.136 27.136 0 0 1-17.77-17.81l-8.59-27.64c-1.33-4.28 1.07-8.84 5.35-10.17 4.28-1.33 8.84 1.07 10.17 5.35l5.1 16.42c9.67-1.95 19.46 3.71 22.45 13.33l.07.23c.33 1.05-.26 2.18-1.32 2.5-1.05.33-2.18-.26-2.5-1.32l-.07-.23a15.38 15.38 0 0 0-7.58-9.11 15.388 15.388 0 0 0-11.8-1.08c-1.05.33-2.17-.26-2.5-1.32l-5.66-18.23a4.133 4.133 0 0 0-5.16-2.72 4.12 4.12 0 0 0-2.71 5.16l8.59 27.64a23.11 23.11 0 0 0 15.15 15.18c2.13.67 3.59 2.7 3.55 4.94l-.51 16.59a2 2 0 0 1-2 1.94z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M85.15 80.76h-.04c-1.1-.02-1.98-.94-1.96-2.04l.84-42.06c.05-2.27-1.77-4.16-4.04-4.21-2.25-.05-4.16 1.77-4.21 4.04l-.79 39.59c-.02 1.11-.95 1.94-2.04 1.96-1.1-.02-1.98-.94-1.96-2.04l.79-39.59c.04-2.17.93-4.19 2.49-5.7a7.995 7.995 0 0 1 5.79-2.26c4.48.09 8.05 3.81 7.96 8.29l-.84 42.06a1.998 1.998 0 0 1-2 1.96z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M85.22 78.75h-.04c-1.1-.02-1.98-.94-1.96-2.04l.71-35.12c.04-2.17.93-4.19 2.49-5.7 1.57-1.5 3.58-2.31 5.79-2.26 2.17.04 4.19.93 5.7 2.49a8.06 8.06 0 0 1 2.26 5.79l-.65 32.65c-.02 1.1-.93 1.96-2.04 1.96-1.1-.02-1.98-.94-1.96-2.04l.65-32.65c.02-1.1-.39-2.15-1.15-2.94a4.146 4.146 0 0 0-2.89-1.27h-.08c-2.24 0-4.08 1.8-4.12 4.04l-.71 35.12a1.998 1.998 0 0 1-2 1.96z'
    />
  </svg>
)
export default SvgReaction6HandUp
