import { makeStyles as makeStylesWithoutDefault } from '@mui/styles'

import defaultTheme from './theme-default'

function makeStyles(
  stylesOrCreator,
  options = {}
): ReturnType<typeof makeStylesWithoutDefault<typeof defaultTheme>> {
  return makeStylesWithoutDefault(stylesOrCreator, {
    defaultTheme,
    ...options,
  })
}

export default makeStyles
