import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { Picker } from 'emoji-mart'
import { useTranslation } from 'react-i18next'

import 'emoji-mart/css/emoji-mart.css'

import { useId } from 'app/react/hooks/id-registry.hooks'

import { withStyles } from 'app/ui'
import { Emoji } from 'ui/icon-button'

const styles = {
  picker: {
    position: 'absolute',
    top: 0,
    transform: 'translateY(-100%)',
    zIndex: 2,
  },
}

function addEmoji(getChatInput, emoji) {
  const { chatInput, cursorPosition } = getChatInput

  const string = chatInput ? _.toArray(chatInput) : []
  const firstHalf = string.slice(0, cursorPosition.start)
  const secondHalf = string.slice(cursorPosition.end)

  const emojiArray =
    secondHalf.length === 0 || secondHalf[0] !== ' ' ? [emoji, ' '] : [emoji]

  const finalArray = firstHalf.concat(emojiArray, secondHalf)

  return finalArray.join('')
}

const pickerStyles = { width: '100%' }

const ChatInputEmojiPicker = ({ classes, disabled, store }) => {
  const [open, setOpen] = useState(false)
  const { id } = useId()
  const { t } = useTranslation()

  const ref = useRef(null)

  useEffect(() => {
    if (disabled && open) setOpen(false)
  }, [disabled, open])

  const handleClose = useCallback(
    (e) => {
      if (!ref.current.contains(e.target)) setOpen(false)
    },
    [setOpen]
  )

  const handleTogglePopover = useCallback(() => {
    setOpen(!open)
  }, [open])

  const handleSelect = useCallback(
    (emoji) => {
      store.setChatInput(addEmoji(store.getChatInput, emoji.native))
      setOpen(false)
    },
    [store]
  )

  const i18n = useMemo(
    () => ({
      search: t('EmojiPicker.search'),
      notfound: t('EmojiPicker.notfound'),
      skintext: t('EmojiPicker.skintext'),
      categories: {
        search: t('EmojiPicker.Categories.search'),
        recent: t('EmojiPicker.Categories.recent'),
        people: t('EmojiPicker.Categories.people'),
        nature: t('EmojiPicker.Categories.nature'),
        foods: t('EmojiPicker.Categories.foods'),
        activity: t('EmojiPicker.Categories.activity'),
        places: t('EmojiPicker.Categories.places'),
        objects: t('EmojiPicker.Categories.objects'),
        symbols: t('EmojiPicker.Categories.symbols'),
        flags: t('EmojiPicker.Categories.flags'),
        custom: t('EmojiPicker.Categories.custom'),
      },
    }),
    [t]
  )

  return (
    <>
      <Emoji
        ref={ref}
        data-testid={id('ChatInput.emojiPicker')}
        disabled={disabled}
        onClick={handleTogglePopover}
      />

      {open && (
        <div className={classes.picker}>
          <ClickAwayListener onClickAway={handleClose}>
            <Picker
              native
              emojiSize={20}
              i18n={i18n}
              set='emojione'
              sheetSize={64}
              style={pickerStyles}
              onClick={handleSelect}
            />
          </ClickAwayListener>
        </div>
      )}
    </>
  )
}

ChatInputEmojiPicker.propTypes = {
  classes: PropTypes.shape({
    picker: PropTypes.string,
  }).isRequired,

  store: PropTypes.shape({
    getChatInput: PropTypes.shape({
      chatInput: PropTypes.string,
      cursorPosition: PropTypes.shape({
        end: PropTypes.number,
        start: PropTypes.number,
      }),
    }),
    setChatInput: PropTypes.func,
  }).isRequired,

  disabled: PropTypes.bool,
}

ChatInputEmojiPicker.defaultProps = {
  disabled: false,
}

export default withStyles(styles, { name: 'ChatInputEmojiPicker' })(
  ChatInputEmojiPicker
)
