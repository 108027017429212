export const STORE_NAME = 'feature/media'

const ACKNOWLEDGE_AWFULLY_QUIET = `sm-web/${STORE_NAME}/ACKNOWLEDGE_AWFULLY_QUIET`
const RESET_ACKNOWLEDGE_AWFULLY_QUIET = `sm-web/${STORE_NAME}/RESET_ACKNOWLEDGE_AWFULLY_QUIET`
const AUDIO_PLAY = `sm-web/${STORE_NAME}/AUDIO_PLAY`
const AUDIO_PLAY_ERROR = `sm-web/${STORE_NAME}/AUDIO_PLAY_ERROR`
const SET_MICROPHONE_MUTE = `sm-web/${STORE_NAME}/SET_MICROPHONE_MUTE`
const MICROPHONE_TOGGLE_MUTE = `sm-web/${STORE_NAME}/MICROPHONE_TOGGLE_MUTE`
const SET_MICROPHONE_EXTERNALLY_MUTED = `sm-web/${STORE_NAME}/SET_MICROPHONE_EXTERNALLY_MUTED`
const SET_CAMERA_MUTE = `sm-web/${STORE_NAME}/SET_CAMERA_MUTE`
const CAMERA_TOGGLE_MUTE = `sm-web/${STORE_NAME}/CAMERA_TOGGLE_MUTE`
const SET_CAMERA_EXTERNALLY_MUTED = `sm-web/${STORE_NAME}/SET_CAMERA_EXTERNALLY_MUTED`
const START_TALKING = `sm-web/${STORE_NAME}/START_TALKING`
const STOP_TALKING = `sm-web/${STORE_NAME}/STOP_TALKING`
const SET_AWFULLY_QUIET = `sm-web/${STORE_NAME}/SET_AWFULLY_QUIET`

export const ACTION_TYPES = {
  ACKNOWLEDGE_AWFULLY_QUIET,
  RESET_ACKNOWLEDGE_AWFULLY_QUIET,
  AUDIO_PLAY,
  AUDIO_PLAY_ERROR,
  SET_MICROPHONE_MUTE,
  MICROPHONE_TOGGLE_MUTE,
  SET_MICROPHONE_EXTERNALLY_MUTED,
  SET_CAMERA_MUTE,
  CAMERA_TOGGLE_MUTE,
  SET_CAMERA_EXTERNALLY_MUTED,
  START_TALKING,
  STOP_TALKING,
  SET_AWFULLY_QUIET,
}

export const AUDIO_PLAYING_STATE = {
  IDLE: 0,
  PLAYING: 1,
  AUTOPLAY_FAILED: 2,
}

const acknowledgeAwfullyQuiet = () => ({ type: ACKNOWLEDGE_AWFULLY_QUIET })
const resetAcknowledgeAwfullyQuiet = () => ({
  type: RESET_ACKNOWLEDGE_AWFULLY_QUIET,
})

const audioPlay = () => ({ type: AUDIO_PLAY })
const audioPlayError = (payload) => ({ type: AUDIO_PLAY_ERROR, payload })

const setMicrophoneMute = (payload) => ({ type: SET_MICROPHONE_MUTE, payload })
const microphoneToggleMute = () => ({ type: MICROPHONE_TOGGLE_MUTE })
const setMicrophoneExternallyMuted = (payload) => ({
  type: SET_MICROPHONE_EXTERNALLY_MUTED,
  payload,
})

const setCameraMute = (payload) => ({ type: SET_CAMERA_MUTE, payload })
const cameraToggleMute = () => ({ type: CAMERA_TOGGLE_MUTE })
const setCameraExternallyMuted = (payload) => ({
  type: SET_CAMERA_EXTERNALLY_MUTED,
  payload,
})

const startTalking = () => ({ type: START_TALKING })
const stopTalking = () => ({ type: STOP_TALKING })

const setAwfullyQuiet = (payload) => ({ type: SET_AWFULLY_QUIET, payload })

export const actions = {
  acknowledgeAwfullyQuiet,
  resetAcknowledgeAwfullyQuiet,
  audioPlay,
  audioPlayError,
  setMicrophoneMute,
  microphoneToggleMute,
  setMicrophoneExternallyMuted,
  setCameraMute,
  cameraToggleMute,
  setCameraExternallyMuted,
  startTalking,
  stopTalking,
  setAwfullyQuiet,
}

export const INITIAL_STATE = {
  acknowledgeAwfullyQuiet: false,
  audioPlaybackState: AUDIO_PLAYING_STATE.IDLE,
  microphoneMuted: false,
  microphoneExternallyMuted: false,
  cameraMuted: false,
  cameraExternallyMuted: false,
  isTalking: false,
  awfullyQuiet: false,
}

type State = typeof INITIAL_STATE

const ACTION_HANDLERS = {
  [ACKNOWLEDGE_AWFULLY_QUIET]: (state: State) => ({
    ...state,
    acknowledgeAwfullyQuiet: true,
  }),
  [RESET_ACKNOWLEDGE_AWFULLY_QUIET]: (state: State) => ({
    ...state,
    acknowledgeAwfullyQuiet: false,
  }),
  [AUDIO_PLAY]: (state: State) => ({
    ...state,
    audioPlaybackState: AUDIO_PLAYING_STATE.PLAYING,
  }),
  [AUDIO_PLAY_ERROR]: (state: State) => ({
    ...state,
    audioPlaybackState: AUDIO_PLAYING_STATE.AUTOPLAY_FAILED,
  }),
  [SET_MICROPHONE_MUTE]: (state: State, action) => ({
    ...state,
    microphoneMuted: action.payload,
  }),
  [MICROPHONE_TOGGLE_MUTE]: (state: State) => ({
    ...state,
    microphoneMuted: !state.microphoneMuted,
  }),
  [SET_MICROPHONE_EXTERNALLY_MUTED]: (state: State, action) => ({
    ...state,
    microphoneExternallyMuted: action.payload,
  }),
  [SET_CAMERA_MUTE]: (state: State, action) => ({
    ...state,
    cameraMuted: action.payload,
  }),
  [CAMERA_TOGGLE_MUTE]: (state: State) => ({
    ...state,
    cameraMuted: !state.cameraMuted,
  }),
  [SET_CAMERA_EXTERNALLY_MUTED]: (state: State, action) => ({
    ...state,
    cameraExternallyMuted: action.payload,
  }),
  [START_TALKING]: (state: State) => ({
    ...state,
    isTalking: true,
  }),
  [STOP_TALKING]: (state: State) => ({
    ...state,
    isTalking: false,
  }),
  [SET_AWFULLY_QUIET]: (state: State, action) => ({
    ...state,
    awfullyQuiet: action.payload,
  }),
}

export default function mediaReducer(
  state: State = INITIAL_STATE,
  action: { type: string; payload?: any } = { type: '' }
) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export const commands = {
  [MICROPHONE_TOGGLE_MUTE]: microphoneToggleMute,
  [CAMERA_TOGGLE_MUTE]: cameraToggleMute,
}

export const selectors = {
  getAcknowledgeAwfullyQuiet(state: State): boolean {
    return state.acknowledgeAwfullyQuiet
  },
  getMicrophoneMuted(state: State): boolean {
    return state.microphoneMuted
  },
  getMicrophoneExternallyMuted(state: State): boolean {
    return state.microphoneExternallyMuted
  },
  getCameraMuted(state: State): boolean {
    return state.cameraMuted
  },
  getCameraExternallyMuted(state: State): boolean {
    return state.cameraExternallyMuted
  },
  getAudioPlaybackState(state: State): number {
    return state.audioPlaybackState
  },
  getIsTalking(state: State): boolean {
    return state.isTalking
  },
  getIsAwfullyQuiet(state: State): boolean {
    return state.awfullyQuiet
  },
}
