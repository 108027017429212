import type { SVGProps } from 'react'
const SvgAgile10Questions = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle cx={81.58} cy={78.48} r={62} fill='rgb(var(--color-brand-50))' />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='m123.6 87.79-4.79 2.72-4.05 14.88-7.61-3.06-22.2-16.93-9.74-2.08-5.75 9.42-19.2 13.21-4.91-13.83-6.55-3.22-3.38 3.53 5.27 22.2 6.97 3.21 17.93-8.69.28 29.04c5.31 1.48 10.9 2.29 16.68 2.29 5.39 0 10.62-.69 15.61-1.98l-.21-29.35 1.98-.65 11.86 8.08 6.62.8 2.95-2.91 6.39-22.36-4.15-4.31z'
    />
    <circle
      cx={81.5}
      cy={77.54}
      r={15.89}
      fill='rgb(var(--color-neutral-50))'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M81.5 95.43c-9.87 0-17.89-8.03-17.89-17.89s8.03-17.89 17.89-17.89 17.89 8.03 17.89 17.89-8.03 17.89-17.89 17.89zm0-31.78c-7.66 0-13.89 6.23-13.89 13.89s6.23 13.89 13.89 13.89 13.89-6.23 13.89-13.89-6.23-13.89-13.89-13.89z'
    />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='m70.73 91.58-20.66 14.37c-.55.37-1.3.08-1.47-.56l-3.44-13.27a4.819 4.819 0 0 0-6.55-3.22c-2.23.93-3.34 3.45-2.74 5.79l4.6 17.74a7.243 7.243 0 0 0 7.01 5.42c1.44 0 2.87-.43 4.09-1.26l11.86-8.08c.71-.49 1.69-.16 1.98.65l-.16 17.79'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M65.24 128.93h-.02c-1.1 0-1.99-.91-1.98-2.02l.14-15.97-10.7 7.29c-2.45 1.68-5.66 2.07-8.45 1.02a9.213 9.213 0 0 1-5.7-6.33l-4.6-17.74c-.87-3.34.81-6.84 3.9-8.13 1.84-.77 3.91-.71 5.68.17 1.77.88 3.07 2.48 3.57 4.4l3.07 11.83 19.43-13.51c.91-.63 2.15-.41 2.78.5.63.91.41 2.15-.5 2.78L51.2 107.59c-.83.56-1.84.67-2.73.32-.89-.34-1.57-1.1-1.81-2.03l-3.44-13.27c-.2-.79-.74-1.45-1.47-1.82-.74-.37-1.58-.39-2.36-.06-1.27.53-1.94 2.01-1.57 3.44l4.6 17.74a5.24 5.24 0 0 0 3.23 3.59c1.58.59 3.4.37 4.79-.58l11.86-8.08a3.32 3.32 0 0 1 2.9-.41 3.28 3.28 0 0 1 2.09 2.05l.12.33-.16 18.14c0 1.1-.9 1.98-2 1.98z'
    />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='m91.48 90.68 21.62 15.27c.55.37 1.3.08 1.47-.56l3.44-13.27c.73-2.8 3.77-4.38 6.55-3.22 2.23.93 3.34 3.45 2.74 5.79l-4.6 17.74a7.243 7.243 0 0 1-7.01 5.42c-1.44 0-2.87-.43-4.09-1.26l-11.86-8.08c-.71-.49-1.69-.16-1.98.65l.16 17.79'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M97.93 128.93c-1.1 0-1.99-.88-2-1.98l-.16-18.14.12-.33c.34-.98 1.11-1.72 2.09-2.05.99-.32 2.04-.17 2.9.41l11.86 8.08c1.4.96 3.21 1.18 4.8.58 1.59-.6 2.8-1.94 3.23-3.59l4.6-17.74c.37-1.43-.3-2.91-1.57-3.44-.78-.33-1.62-.31-2.36.06-.73.36-1.27 1.02-1.47 1.82l-3.44 13.27c-.24.93-.92 1.68-1.81 2.02-.89.34-1.9.23-2.7-.3L90.35 92.3a2.002 2.002 0 0 1 2.31-3.27l20.38 14.39 3.06-11.82c.5-1.91 1.8-3.52 3.57-4.4a6.85 6.85 0 0 1 5.69-.17c3.09 1.29 4.77 4.79 3.9 8.13l-4.6 17.74a9.213 9.213 0 0 1-5.7 6.33 9.35 9.35 0 0 1-8.46-1.03l-10.69-7.28.14 15.97c0 1.1-.88 2.01-1.98 2.02h-.02zM80.27 43.56c-1.64 0-2.97 1.36-2.97 3s1.33 3 2.97 3 3.04-1.33 3.04-3-1.36-3-3.04-3zm.46-19.64c-2.51 0-4.77.83-6.48 2.24a2.393 2.393 0 0 0-.21 3.49c.84.9 2.25 1.04 3.2.26.95-.77 2.1-1.21 3.52-1.21 1.88 0 3.28 1.05 3.28 2.62 0 1.99-2.06 3.28-4.71 3.28-.91 0-1.64.73-1.64 1.64v2.55c0 1.16.94 2.09 2.09 2.09h.91c1.16 0 2.09-.94 2.09-2.09 3.87-.8 6.42-3.7 6.42-7.36 0-4.54-3.84-7.5-8.48-7.5zm22.45 28.83c-1.64 0-2.97 1.36-2.97 3s1.33 3 2.97 3 3.04-1.33 3.04-3-1.36-3-3.04-3zm.45-19.63c-2.51 0-4.77.83-6.48 2.24a2.393 2.393 0 0 0-.21 3.49c.84.9 2.25 1.04 3.2.26.95-.77 2.1-1.21 3.52-1.21 1.88 0 3.28 1.05 3.28 2.62 0 1.99-2.06 3.28-4.71 3.28-.91 0-1.64.73-1.64 1.64v2.55c0 1.16.94 2.09 2.09 2.09h.91c1.16 0 2.09-.94 2.09-2.09 3.87-.8 6.42-3.7 6.42-7.36 0-4.54-3.84-7.5-8.48-7.5zM57.37 52.75c-1.64 0-2.97 1.36-2.97 3s1.33 3 2.97 3 3.04-1.33 3.04-3-1.36-3-3.04-3zm.46-19.63c-2.51 0-4.77.83-6.48 2.24a2.393 2.393 0 0 0-.21 3.49c.84.9 2.25 1.04 3.2.26.95-.77 2.1-1.21 3.52-1.21 1.88 0 3.28 1.05 3.28 2.62 0 1.99-2.06 3.28-4.71 3.28-.91 0-1.64.73-1.64 1.64v2.55c0 1.16.94 2.09 2.09 2.09h.91c1.16 0 2.09-.94 2.09-2.09 3.87-.8 6.42-3.7 6.42-7.36 0-4.54-3.84-7.5-8.48-7.5z'
    />
  </svg>
)
export default SvgAgile10Questions
