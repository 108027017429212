import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import Link from 'ui/link'
import { Divider, Typography } from 'ui/revision'

type Props = {
  meetingIdentifier?: string
}

const DialInInstructionsTable: React.FC<Props> = ({
  meetingIdentifier = '',
}: Props) => {
  const { t } = useTranslation()

  let meetingsLink = window.location.origin

  if (meetingIdentifier) {
    meetingsLink = `${meetingsLink}/meeting/${meetingIdentifier}`
  }

  return (
    <Grid container direction='column'>
      <Grid container wrap='nowrap'>
        <Grid item xs={3}>
          <Typography>
            {t('DialInPage.InstructionsTable.joinViaWeb', 'Join via Web')}
          </Typography>
        </Grid>
        <Grid item xs={9} style={{ textAlign: 'left' }}>
          <Typography>
            <Link
              to={meetingIdentifier ? `/meeting/${meetingIdentifier}` : '/'}
            >
              {meetingsLink}
            </Link>{' '}
          </Typography>
        </Grid>
      </Grid>
      <Divider spacing={1} />
      {!!meetingIdentifier && (
        <>
          <Grid container wrap='nowrap'>
            <Grid item xs={3}>
              <Typography>
                {t('DialInPage.InstructionsTable.meetingID', 'Meeting code:')}
              </Typography>
            </Grid>
            <Grid item xs={9} style={{ textAlign: 'left' }}>
              <Typography>{meetingIdentifier}</Typography>
            </Grid>
          </Grid>
          <Divider spacing={1} />
        </>
      )}
      <Grid container wrap='nowrap'>
        <Grid item xs={3}>
          <Typography>
            {t(
              'DialInPage.InstructionsTable.howToDialInTitle',
              'How to dial in:'
            )}
          </Typography>
        </Grid>
        <Grid item xs={9} style={{ textAlign: 'left' }}>
          <Typography>
            {t(
              'DialInPage.InstructionsTable.howToDialIn',
              'Dial in using your phone using the number closest to your location.'
            )}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DialInInstructionsTable
