import { useEffect, useState, createContext, useContext } from 'react'
import { I18nextProvider } from 'react-i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Locize from 'i18next-locize-backend'
import env from 'app/config/env'

export type LanguageOptions = Record<
  string,
  {
    name: string
    nativeName: string
    isReferenceLanguage: boolean
    translated: boolean
  }
>

export interface I18nConfigOptions {
  /**
   * config object for i18n next lib
   */
  i18n: Record<string, unknown>
  /**
   * config for locize backend integration
   */
  locize: {
    url: string
    backend: {
      projectId: string
      apiKey: string
    }
  }
}

export interface I18nProviderProps {
  children: React.ReactNode
}

/**
 * @returns config object for i18n next lib
 */
const config = env('i18n') as I18nConfigOptions

const LanguagesContext = createContext<LanguageOptions>({})

/**
 *
 * @returns returns languages object
 */
export const useLanguages = () => {
  const context = useContext(LanguagesContext)
  if (context === undefined) {
    throw new Error('useLanguages must be used within a I18nProvider')
  }
  return context
}

/**
 *
 * @param
 * @returns
 */
export const I18nProvider = ({ children }: I18nProviderProps) => {
  const [languages, setLanguages] = useState<LanguageOptions>({})
  const [init, setInit] = useState(true)

  useEffect(() => {
    async function register() {
      if (init) {
        setInit(false)
      } else {
        return void 0
      }

      await i18n
        .use(LanguageDetector)
        .use(Locize)
        .init({
          debug: import.meta.env.MODE === 'development',
          fallbackLng: 'en',
          ...(config.i18n || {}),
          backend: {
            referenceLng: 'en',
            ...(config.locize.backend || {}),
          },
          detection: {
            /**
             * detects language preference from url param
             */
            lookupQuerystring: 'lang',
          },
        })
      logger.debug(`i18n:getLanguages() detected language ${i18n.language}`)

      /**
       * fetch the supported languages from the backend
       */
      const response =
        await i18n.services.backendConnector.backend.getLanguages()
      setLanguages(response)
      /**
       * after fetching the languages we can check if the initially detected language is supported
       */
      logger.debug('i18n:getLanguages() api supported languages: ', response)

      // sometimes the language detected will be something like en-US
      const currLng = i18n.language && i18n.language.split('-')[0]
      if (!i18n.language || !(currLng in response)) {
        logger.debug(
          `i18n:getLanguages() current language not supported, falling back to "en"`
        )
        await i18n.changeLanguage('en')
      } else if (currLng !== i18n.language) {
        await i18n.changeLanguage(currLng)
      }
    }
    /**
     * TODO: add error handling for the following cases:
     * what if the api is not reachable?
     * what if the api returns an error?
     * perhapse we can fallback to a local file with the supported languages?
     */
    register()
  }, [])

  return (
    <I18nextProvider i18n={i18n}>
      <LanguagesContext.Provider value={languages}>
        {children}
      </LanguagesContext.Provider>
    </I18nextProvider>
  )
}

export default I18nProvider
