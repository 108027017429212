import Grid from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
import cn from 'clsx'
import { useMemo } from 'react'
import { Flipper } from 'react-flip-toolkit'

import { withStyles } from 'app/ui'
import useTheme from 'app/ui/useTheme'

import Snackbar from './snackbar'

const styles = (theme) => ({
  root: {
    zIndex: theme.zIndex.snackbar,
    position: 'fixed',
    bottom: 8,
    left: '50%',
    transform: 'translateX(-50%)',
    width: 'auto',
    flexDirection: 'column-reverse',

    [theme.breakpoints.up('sm')]: {
      top: 24,
      bottom: 'auto',
      left: 24,
      transform: 'unset',
    },
  },
})

const mapSnackbarIndicies = (id) => <Snackbar id={id} key={id} />

const SnackbarQueue = ({ className, classes, snackbarIndices }) => {
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('xs'))

  const usedSnackbarIndicies = useMemo(
    () => snackbarIndices.slice(0, isPhone ? 1 : 3),
    [snackbarIndices, isPhone]
  )

  return (
    <Grid
      container
      alignItems='flex-start'
      className={cn(classes.root, className)}
      component={Flipper}
      direction='column'
      flipKey={usedSnackbarIndicies.join('')}
      justifyContent='center'
      spacing={1}
    >
      {usedSnackbarIndicies.map(mapSnackbarIndicies)}
    </Grid>
  )
}

export default withStyles(styles, { name: 'SnackbarQueue' })(SnackbarQueue)
