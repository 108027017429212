enum ROOM_SERVICES {
  // LOCAL
  ROOM_AUDIO = 'audio',
  ROOM_VIDEO = 'video',
  ROOM_THUMBNAIL = 'thumbnail',
  ROOM_SCREEN = 'screen',
  DEVICE_PICKER_AUDIO = 'devicePickerAudio',
  DEVICE_PICKER_VIDEO = 'devicePickerVideo',
  VIDEO_STREAMER_AUDIO = 'videoStreamerAudio',
  SCREEN_SHARING_AUDIO = 'screenSharingAudio',
  // EXTERNAL
  EXTERNAL_AUDIO = 'externalAudio',
  EXTERNAL_VIDEO = 'externalVideo',
  EXTERNAL_THUMBNAIL = 'externalThumbnail',
  EXTERNAL_SCREEN = 'externalScreen',
}

export default ROOM_SERVICES
