import NOTIFICATION_TYPES from './notification-types'

export const FEATURE_NAME = 'feature/notification'

const CREATE_NOTIFICATION = `sm-web/${FEATURE_NAME}/CREATE_NOTIFICATION`
const CLOSE_NOTIFICATION = `sm-web/${FEATURE_NAME}/CLOSE_NOTIFICATION`

export const ACTION_TYPES = {
  CREATE_NOTIFICATION,
  CLOSE_NOTIFICATION,
}

/**
 * Creates a create notification action
 * @param type Type of notification to create
 * @param args Any arguments to add to the notification
 * @returns Create notification action
 */
const createNotification = (type, args) => ({
  type: CREATE_NOTIFICATION,
  payload: {
    type,
    ...args,
  },
})

/**
 * Creates a close notification action
 * @param type Type of notification to close
 * @returns Close notification action
 */
const closeNotification = (type) => ({
  type: CLOSE_NOTIFICATION,
  payload: {
    type,
  },
})

/**
 * Creates audio muted by host notification
 * @returns Create notification action
 */
const createAudioMutedByHostNotification = () =>
  createNotification(NOTIFICATION_TYPES.AUDIO_MUTED_BY_HOST)

/**
 * Creates audio unmuted notification
 * @returns Create notification action
 */
const createAudioUnmutedByHostNotification = () =>
  createNotification(NOTIFICATION_TYPES.AUDIO_UNMUTED_BY_HOST)

/**
 * Creates background effect loading notification
 * @returns Create notification action
 */
const createBackgroundEffectLoadingNotification = () =>
  createNotification(NOTIFICATION_TYPES.BACKGROUND_EFFECT_LOADING)

/**
 * Creates file uploaded notification
 * @param fileName Name of the file
 * @returns Create notification action
 */
const createFileUploadedNotification = (fileName) =>
  createNotification(NOTIFICATION_TYPES.FILE_UPLOADED, { fileName })

/**
 * Creates guest message notification
 * @param message Some message
 * @returns Create notification action
 */
const createGuestMessageNotification = (message) =>
  createNotification(NOTIFICATION_TYPES.GUEST_MESSAGE, { message })

/**
 * Creates raised hand notification for host
 * @param memberId ID of user that raised their hand
 * @param userName Name of user
 * @param avatarImage URL of user avatar
 * @returns Create notification action
 */
const createHostRaiseHandNotification = (memberId, userName, avatarImage) =>
  createNotification(NOTIFICATION_TYPES.HOST_RAISE_HAND, {
    memberId,
    userName,
    avatarImage,
  })

/**
 * Creates increase grid size notification
 * @returns Create notification action
 */
const createIncreaseGridSizeNotification = () =>
  createNotification(NOTIFICATION_TYPES.INCREASE_GRID_SIZE)

/**
 * Creates join room failed notification
 * @returns Create notification action
 */
const createJoinRoomFailedNotification = () =>
  createNotification(NOTIFICATION_TYPES.JOIN_ROOM_FAILED)

/**
 * Creates local raise hand notification
 * @returns Create notification action
 */
const createLocalRaiseHandNotification = () =>
  createNotification(NOTIFICATION_TYPES.LOCAL_RAISE_HAND)

/**
 * Creates private message notification
 * @param from ID of user that sent the message
 * @param userName Name of user that sent the message
 * @param avatar Avatar URL of the user that sent the message
 * @returns Create notification action
 */
const createPrivateMessageNotification = (from, userName, avatar) =>
  createNotification(NOTIFICATION_TYPES.PRIVATE_MESSAGE, {
    from,
    userName,
    avatar,
  })

/**
 * Creates local requesting screen share notification
 * @returns Create notification action
 */
const createRequestingScreenShareNotification = () =>
  createNotification(NOTIFICATION_TYPES.REQUESTING_SCREEN_SHARE)

/**
 * Closes local requesting screen share notification
 * @returns Close notification action
 */
const closeRequestingScreenShareNotification = () =>
  closeNotification(NOTIFICATION_TYPES.REQUESTING_SCREEN_SHARE)

/**
 * Creates room full notification
 * @returns Create notification action
 */
const createRoomFullNotification = () =>
  createNotification(NOTIFICATION_TYPES.ROOM_FULL)

/**
 * Closes room full notification
 * @returns Close notification action
 */
const closeRoomFullNotification = () =>
  closeNotification(NOTIFICATION_TYPES.ROOM_FULL)

/**
 * Creates room link notification
 * @returns Create notification action
 */
const createRoomLinkNotification = () =>
  createNotification(NOTIFICATION_TYPES.ROOM_LINK)

/**
 * Creates screen share request notification
 * @param from ID of user that requested screen share
 * @param userName Name of the user requesting screen share
 * @param avatar Avatar URL of the user requesting screen share
 * @returns Create notification action
 */
const createScreenShareRequestNotification = (from, userName, avatar) =>
  createNotification(NOTIFICATION_TYPES.SCREEN_SHARE_REQUEST, {
    from,
    userName,
    avatar,
  })

/**
 * Creates local screen share request approved notification
 * @returns Create notification action
 */
const createScreenShareRequestApprovedNotification = () =>
  createNotification(NOTIFICATION_TYPES.SCREEN_SHARE_REQUEST_APPROVED)

/**
 * Creates local screen share request rejected notification
 * @returns Create notification action
 */
const createScreenShareRequestRejectedNotification = () =>
  createNotification(NOTIFICATION_TYPES.SCREEN_SHARE_REQUEST_REJECTED)

/**
 * Creates screen share started notification
 * @param userName Name of the user that started screen sharing
 * @param avatar Avatar URL of the user that started screen sharing
 * @returns Create notification action
 */
const createScreenShareStartedNotification = (userName, avatar) =>
  createNotification(NOTIFICATION_TYPES.SCREEN_SHARE_STARTED, {
    userName,
    avatar,
  })

/**
 * Creates slow link notification
 * @returns Create notification action
 */
const createSlowLinkNotification = () =>
  createNotification(NOTIFICATION_TYPES.SLOW_LINK)

/**
 * Creates talking while muted notification
 * @param mutedBy
 * @returns Create notification action
 */
const createTalkingWhileMutedNotification = (mutedBy) =>
  createNotification(NOTIFICATION_TYPES.TALKING_WHILE_MUTED, { mutedBy })

/**
 * Closes local talking while muted notification
 * @returns Close notification action
 */
const closeTalkingWhileMutedNotification = () =>
  closeNotification(NOTIFICATION_TYPES.TALKING_WHILE_MUTED)

/**
 * Creates user joined meeting notification
 * @param userName Name of the user that joined the meeting
 * @param avatar Avatar URL of the user that joined the meeting
 * @returns Create notification action
 */
const createUserEntersRoomNotification = (userName, avatar) =>
  createNotification(NOTIFICATION_TYPES.USER_ENTERS_ROOM, { userName, avatar })

/**
 * Creates user joined waiting room notification
 * @param waitingGuests The online guests in the waiting room
 * @returns Create notification action
 */
const createUserEntersWaitingRoomNotification = (waitingGuests) =>
  createNotification(NOTIFICATION_TYPES.USER_ENTERS_WAITING_ROOM, {
    waitingGuests,
  })

/**
 * Creates user left waiting room notification
 * @returns Create notification action
 */
const createUserLeavesWaitingRoomNotification = (userName, avatar) =>
  createNotification(NOTIFICATION_TYPES.USER_LEAVES_WAITING_ROOM, {
    userName,
    avatar,
  })

/**
 * Creates user waiting room notification
 * @param userName Name of the user
 * @returns Create notification action
 */
const createUserWaitingRoomNotification = (userName) =>
  createNotification(NOTIFICATION_TYPES.USER_WAITING_ROOM, { userName })

/**
 * Creates video muted by host notification
 * @returns Create notification action
 */
const createVideoMutedByHostNotification = () =>
  createNotification(NOTIFICATION_TYPES.VIDEO_MUTED_BY_HOST)

/**
 * Creates video unmuted by host notification
 * @returns Create notification action
 */
const createVideoUnMutedByHostNotification = () =>
  createNotification(NOTIFICATION_TYPES.VIDEO_UNMUTED_BY_HOST)

/**
 * Creates made host by host notification
 * @returns Create notification action
 */
const createMadeHostByHostNotification = () =>
  createNotification(NOTIFICATION_TYPES.MADE_HOST_BY_HOST)

const createRevokedHostByHostNotification = () =>
  createNotification(NOTIFICATION_TYPES.REVOKED_HOST_BY_HOST)

export const actions = {
  closeRequestingScreenShareNotification,
  closeRoomFullNotification,
  closeTalkingWhileMutedNotification,
  createAudioMutedByHostNotification,
  createAudioUnmutedByHostNotification,
  createBackgroundEffectLoadingNotification,
  createFileUploadedNotification,
  createGuestMessageNotification,
  createHostRaiseHandNotification,
  createIncreaseGridSizeNotification,
  createJoinRoomFailedNotification,
  createLocalRaiseHandNotification,
  createPrivateMessageNotification,
  createRequestingScreenShareNotification,
  createRoomFullNotification,
  createRoomLinkNotification,
  createScreenShareRequestApprovedNotification,
  createScreenShareRequestNotification,
  createScreenShareRequestRejectedNotification,
  createScreenShareStartedNotification,
  createSlowLinkNotification,
  createTalkingWhileMutedNotification,
  createUserEntersRoomNotification,
  createUserEntersWaitingRoomNotification,
  createUserLeavesWaitingRoomNotification,
  createUserWaitingRoomNotification,
  createVideoMutedByHostNotification,
  createVideoUnMutedByHostNotification,
  createMadeHostByHostNotification,
  createRevokedHostByHostNotification,
}
