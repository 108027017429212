import { useMemo } from 'react'
import { ClChatItem } from '@enreach/core-component-library-react'
import { defineCustomElementClChatItem } from '@enreach/core-component-library/dist/components/index'
import _ from 'lodash'
import clsx from 'clsx'

import moment from 'lib/moment'
import { makeStyles } from 'app/ui'
import { type ChatItemProps } from './chat-item.types'

const useStyles = makeStyles({
  hideAvatar: {
    'grid-template-columns': '0 100%',
    '& .chat-item__avatar': {
      display: 'none',
    },
  },
})
/**
 * NOTE: this chatitem component is capable of hiding the avatar because when visible
 * in a small window the avatar takes up too much space and the text is not readable
 * @returns
 */
export const ChatItem: React.FC<ChatItemProps> = ({
  timestamp,
  className,
  hideAvatar,
  name,
  ...chatItemProps
}: ChatItemProps) => {
  const classes = useStyles()
  const formattedTime = useMemo(
    () => timestamp && moment(timestamp).format('HH:mm'),
    [timestamp]
  )

  name = useMemo(
    () => name && _.truncate(name, { length: 18, separator: ' ' }),
    [name]
  )

  return (
    <ClChatItem
      {...chatItemProps}
      className={clsx(className, { [classes.hideAvatar]: hideAvatar })}
      name={name}
      timestamp={formattedTime}
    />
  )
}

export default ChatItem

defineCustomElementClChatItem()
