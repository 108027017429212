const ANALYTICS_EVENT_TYPES = {
  BACKGROUND_EFFECT: {
    BLUR_STARTED: 'web:backgroundEffect_blurStarted',
    REPLACE_STARTED: 'web:backgroundEffect_replaceStarted',
    BLUR_STOPPED: 'web:backgroundEffect_blurStopped',
    REPLACE_STOPPED: 'web:backgroundEffect_replaceStopped',
    FORGOTTEN: 'web:backgroundEffect_forgotten',
    REMEMBERED: 'web:backgroundEffect_remembered',
  },
}

export default ANALYTICS_EVENT_TYPES
