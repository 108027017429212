import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'clsx'
import { useId } from 'app/react/hooks/id-registry.hooks'
import env from 'app/config/env'
import { withStyles } from 'app/ui'

import Image from 'ui/image'

const styles = (theme) => ({
  root: {
    position: 'absolute',
    maxWidth: theme.measurements.logo.width,
    width: 'auto',
    maxHeight: theme.measurements.logo.height,
    zIndex: 1,
    userSelect: 'none',
    left: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      left: theme.spacing(2),
      maxWidth: theme.spacing(24),
    },
    '&:not($action)': {
      pointerEvents: 'none',
    },
  },
  placementTop: {
    top: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(3) - 4,
    },
  },
  placementBottom: {
    bottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(4),
      maxWidth: theme.spacing(24),
    },
  },
  action: {},
})

const Logo = ({ className, classes, placement, onClick }) => {
  const { id } = useId()
  const [error, setError] = useState(false)

  const handleError = useCallback(() => setError(true), [])

  return (!error &&
  env('brand_config.logo.url', false) && (<Image
    alt='logo'
    className={cn(
      className,
      classes.root,
      onClick && classes.action,
      classes[`placement${placement.replace(/./, (s) => s.toUpperCase())}`]
    )}
    data-testid={id('logoId')}
    id='logo'
    src={env('brand_config.logo.url')}
    style={env('brand_config.logo.additionalStyles')}
    onError={handleError}
  />));
}

Logo.propTypes = {
  classes: PropTypes.shape({
    action: PropTypes.string,
    placementBottom: PropTypes.string,
    placementTop: PropTypes.string,
    root: PropTypes.string,
  }).isRequired,

  className: PropTypes.string,
  placement: PropTypes.oneOf(['top', 'bottom']),

  onClick: PropTypes.func,
}

Logo.defaultProps = {
  className: '',
  placement: 'top',

  onClick: undefined,
}

export default withStyles(styles, { name: 'Logo' })(Logo)
