import _ from 'lodash'
import i18n from 'i18next'
import { snackBarIcons } from './snackbar/snackbar-icons'
import { STORE_NAME, actions, selectors } from './snackbar-queue.reducer'

export const enqueueIncreaseGridSizeSnackbar = () =>
  actions.enqueueSnackbar(
    {
      icon: snackBarIcons.grid,
      message: i18n.t(
        'RoomPage.Notifications.increaseGridSize',
        'You can expand the number of videos from the footer. Additional hardware resources might be required.'
      ),
      status: 'info',
    },
    'increaseGridSize'
  )

/**
 * Enqueue a snackbar for receiving a message in the waiting room.
 * @param message Some message
 * @returns
 */
export const enqueueGuestMessageSnackbar = (message) =>
  actions.enqueueSnackbar({
    icon: snackBarIcons.message,
    message,
    status: 'info',
  })

/**
 * Enqueue a snackbar for room full while being in the waiting room.
 * @return
 */
export const enqueueFullRoomSnackbar = () =>
  actions.enqueueSnackbar({
    icon: snackBarIcons.hand,
    message: `${i18n.t('WaitingRoom.roomName')} ${i18n.t(
      'WaitingRoom.fullRoom'
    )}`,
    status: 'info',
    types: 'roomFull',
    autoHideDuration: 0,
  })

/**
 * Enqueue a snackbar with raise hand content.
 * @return
 */
export const enqueueLocalRaiseHandSnackbar = () =>
  actions.enqueueSnackbar({
    message: i18n.t(
      'RoomPage.Notifications.raiseHandRequest.local',
      'You raised your hand'
    ),
    icon: snackBarIcons.hand,
    status: 'success',
  })

/**
 * Enqueue a snackbar with raise hand content for a guest.
 * @param memberId
 * @param [userName=i18n.t('WaitingRoom.anonymous')]
 * @return
 */
export const enqueueHostRaiseHandSnackbar = (
  memberId,
  userName = i18n.t('WaitingRoom.anonymous')
) =>
  actions.enqueueSnackbar({
    message: i18n.t(
      'RoomPage.Notifications.raiseHandRequest.external',
      '{{user}} raised hand',
      {
        user: _.truncate(userName, { length: 18, separator: ' ' }),
      }
    ),
    icon: snackBarIcons.hand,
    status: 'success',
    memberId,
  })

/**
 * Enqueue a snackbar with room link copied text.
 * @return
 */
export const enqueueRoomLinkSnackbar = () =>
  actions.enqueueSnackbar({
    message: i18n.t(
      'RoomPage.Settings.room.roomLink.invite',
      'Your link has been copied, you can now send it to invite guests'
    ),
    icon: snackBarIcons.copy,
  })

/**
 * Enqueue a snackbar with invitation copied text.
 * @return
 */
export const enqueueInvitationSnackbar = () =>
  actions.enqueueSnackbar({
    message: i18n.t(
      'CopyText.title',
      'Your {{type}} has been copied, you can now send it to invite guests',
      { type: i18n.t(['CopyText.invitation', 'invitation']) }
    ),
    icon: snackBarIcons.copy,
  })

/**
 * Find a snackbar in the queue and close it.
 * @param store
 * @param predicate
 * @return
 */
export const findSnackbar = (store, predicate) => {
  const { [STORE_NAME]: snackbarQueue } = store.getState()

  const snackbars = selectors.getSnackbars(snackbarQueue)

  return _.find(Object.values(snackbars), predicate)
}

/**
 * Find a snackbar in the queue and close it.
 * @param predicate
 * @return
 */
export const findAndCloseSnackbar = (predicate) => (dispatch, getState) => {
  const snackbarEntry = findSnackbar({ dispatch, getState }, predicate)

  if (snackbarEntry) {
    dispatch(actions.closeSnackbar(snackbarEntry.id))
  }
}

/**
 * Find and close the screen share snackbar when it is in the queue.
 * @return
 */
export const findAndCloseScreenShareSnackbar = () =>
  findAndCloseSnackbar({ props: { type: 'screenShare', close: false } })

export const enqueueUserWaitingRoomSnackbar = (name) =>
  actions.enqueueSnackbar({
    message: i18n.t(
      name
        ? 'RoomPage.Notifications.namedGuestWaitingRoom'
        : 'RoomPage.Notifications.guestWaitingRoom',
      { name }
    ),
    icon: snackBarIcons.copy,
  })

export const enqueueFailedJoinRoom = () =>
  actions.enqueueSnackbar({
    message: i18n.t(
      'RoomPage.Notifications.failedJoinRoom',
      'The meeting you tried to join doesn’t exist'
    ),
    icon: snackBarIcons.error,
  })

export const enqueueHostMuteVideo = () =>
  actions.enqueueSnackbar({
    message: i18n.t(
      'RoomPage.Notifications.muteVideoByHost',
      'Your video is disabled by the host'
    ),
    types: 'muteVideoByHost',
    icon: snackBarIcons.videoCamOff,
  })

export const findAndCloseHostMuteVideo = () =>
  findAndCloseSnackbar({ props: { types: 'muteVideoByHost', close: false } })

export const enqueueHostUnmuteVideo = () =>
  actions.enqueueSnackbar({
    message: i18n.t(
      'RoomPage.Notifications.unmuteVideoByHost',
      'Your video is re-enabled by the host'
    ),
    types: 'unmuteVideoByHost',
    icon: snackBarIcons.videoCam,
  })

export const findAndCloseHostUnmuteVideo = () =>
  findAndCloseSnackbar({ props: { types: 'unmuteVideoByHost', close: false } })

export const enqueueHostMuteAudio = () =>
  actions.enqueueSnackbar({
    message: i18n.t(
      'RoomPage.Notifications.muteAudioByHost',
      'Your audio is muted by the host'
    ),
    types: 'muteAudioByHost',
    icon: snackBarIcons.micOff,
  })

export const findAndCloseHostMuteAudio = () =>
  findAndCloseSnackbar({ props: { types: 'muteAudioByHost', close: false } })

export const enqueueHostUnmuteAudio = () =>
  actions.enqueueSnackbar({
    message: i18n.t(
      'RoomPage.Notifications.unmuteAudioByHost',
      'Your audio is unmuted by the host'
    ),
    types: 'unmuteAudioByHost',
    icon: snackBarIcons.mic,
  })

export const findAndCloseHostUnmuteAudio = () =>
  findAndCloseSnackbar({ props: { types: 'unmuteAudioByHost', close: false } })

/**
 * find and close the full room snackbar message
 */
export const findAndCloseFullRoomSnackbar = () =>
  findAndCloseSnackbar({ props: { types: 'roomFull', close: false } })

/**
 * Enqueue a snackbar with background effect failed notification
 * @return
 */
export const enqueueBackgroundEffectErrorSnackbar = () =>
  actions.enqueueSnackbar({
    message: i18n.t(
      'BackgroundEffect.Error',
      'Failed to start video background effect. Please try again.'
    ),
    icon: snackBarIcons.error,
    status: 'error',
  })

/**
 * Enqueue a snackbar with background effect loading notification
 * @return
 */
export const enqueueBackgroundEffectLoadingSnackbar = () =>
  actions.enqueueSnackbar({
    message: i18n.t(
      'BackgroundEffect.Loading',
      'Loading video background effect, please wait...'
    ),
    icon: snackBarIcons.backGroundEffect,
    status: 'info',
    type: 'backgroundEffectLoading',
  })

/**
 * Find and close the background effect loading snackbar when it is in the queue.
 * @return
 */
export const findAndCloseBackgroundEffectLoadingSnackbar = () => {
  return findAndCloseSnackbar({ props: { type: 'backgroundEffectLoading' } })
}

export const enqueueScreenShareRequestSnackbar = () =>
  actions.enqueueSnackbar({
    message: i18n.t(
      'RoomPage.Notifications.screenShareRequest',
      'Your requesting rights for sharing your screen'
    ),
    icon: snackBarIcons.avShare,
    types: 'screenShareRequest',
  })

export const findAndCloseScreenShareRequestSnackbar = () =>
  findAndCloseSnackbar({ props: { types: 'screenShareRequest', close: false } })

export const enqueueScreenShareRequestApprovedSnackbar = () =>
  actions.enqueueSnackbar({
    message: i18n.t(
      'RoomPage.Notifications.screenShareRequestApproved',
      'Your screenshare request is approved by the host'
    ),
    icon: snackBarIcons.avShare,
    types: 'screenShareRequestApproved',
  })

export const findAndCloseScreenShareRequestApprovedSnackbar = () =>
  findAndCloseSnackbar({
    props: { types: 'screenShareRequestApproved', close: false },
  })

export const enqueueScreenShareRequestRejectedSnackbar = () =>
  actions.enqueueSnackbar({
    message: i18n.t(
      'RoomPage.Notifications.screenShareRequestRejected',
      'Your screenshare request was rejected by the host'
    ),
    icon: snackBarIcons.close,
    types: 'screenShareRequestRejected',
  })

export const findAndCloseScreenShareRequestRejectedSnackbar = () =>
  findAndCloseSnackbar({
    props: { types: 'screenShareRequestRejected', close: false },
  })

/**
 *
 * @param mutedBy
 * @returns
 */
export const enqueueTalkingWhileMutedSnackbar = (mutedBy = undefined) =>
  actions.enqueueSnackbar(
    {
      message: i18n.t(
        'RoomPage.Notifications.talkingWhileMuted',
        'The others cannot hear you',
        { context: mutedBy }
      ),
      icon: snackBarIcons.micOff,
      types: 'talkingWhileMuted',
    },
    'talkingWhileMuted'
  )

export const findAndCloseTalkingWhileMutedSnackbar = () =>
  findAndCloseSnackbar({
    props: { types: 'talkingWhileMuted', close: false },
  })

/**
 * Enqueue a snackbar when made host by a host
 */
export const enqueueMadeHostbyHostSnackbar = () =>
  actions.enqueueSnackbar({
    message: i18n.t(
      'RoomPage.Notifications.madeHost',
      'Moderator right has been granted'
    ),
    icon: snackBarIcons.genericUser,
    status: 'info',
    type: 'madeHostByHost',
    autoHide: true,
  })

export const enqueueModeratorRightsRevokedSnackbar = () =>
  actions.enqueueSnackbar({
    message: i18n.t(
      'RoomPage.Notifications.revokedHost',
      'Moderator right has been revoked'
    ),
    icon: snackBarIcons.genericUser,
    status: 'info',
    type: 'revokedHostByHost',
    autoHide: true,
  })
