import _ from 'lodash'

export class IdRegistry {
  _ids

  constructor({ json }) {
    if (typeof json === 'string') {
      this._ids = JSON.parse(json)
    } else {
      this._ids = json
    }
  }

  log(msg) {
    logger.debug('IdRegistry:', msg)
  }

  getIds() {
    return this._ids
  }

  getId(path) {
    return _.get(this._ids, path)
  }
}
