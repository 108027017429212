import { isStringRegex } from 'lib/string-utils'
import { type Imprint } from './imprints.types'

/**
 * Filter the given imprints based on the language key and languageCode.
 * @param imprints The imprints to filter.
 * @param languageCode The language code to filter on.
 * @return The filtered imprints.
 */
export function filterImprints(
  imprints: Imprint[],
  languageCode: string
): Imprint[] {
  return imprints.filter(({ language }) => {
    if (!language) return true
    if (!isStringRegex(language)) return language === languageCode
    return new RegExp(language).test(languageCode)
  })
}
