import {
  BackgroundBlurStrategy,
  BackgroundReplaceStrategy,
} from '@enreach/person-segmentation'
import _ from 'lodash'
import { filter, take } from 'rxjs/operators'

import browserUtils from 'lib/browser-utils'
import createDummyAudio from 'lib/create-dummy-audio'

import roomMediaManager from 'app/services/media/room-media-manager'
import RoomAudioMixer from 'app/services/media/room-audio-mixer'
import { ConnectionEvents } from 'app/services/media/connection'

import { STORE_NAME as CHANNEL_ROOM_STORE_NAME } from 'app/state/api/channels/channel-room.reducer'

import BackgroundEffectImages from 'resources/images/background-effect-images'

import {
  getMe,
  isHostAlone,
  inRoomPredicate,
  screenSendRightPredicate,
  videoSendRightPredicate,
} from 'app/state/utils'

import {
  AUDIO_PLAYING_STATE,
  STORE_NAME as MEDIA_STORE_NAME,
  selectors as mediaSelectors,
} from 'app/features/media/media.reducer'
import {
  STORE_NAME as SETTINGS_STORE_NAME,
  selectors as settingsSelectors,
} from 'app/features/settings/settings.reducer'
import {
  STORE_NAME as DEVICES_STORE_NAME,
  selectors as devicesSelectors,
} from 'app/features/devices/devices.reducer'

import ROOM_SERVICES from './room-services'

/**
 * Check whether the member should start audio
 */
export function shouldMemberStartAudio(member, media) {
  return (
    _.isMatch(member, inRoomPredicate) &&
    mediaSelectors.getAudioPlaybackState(media) === AUDIO_PLAYING_STATE.PLAYING
  )
}

/**
 * Check whether the member should start audio
 */
export function shouldMemberEnableAudio(media) {
  return (
    !mediaSelectors.getMicrophoneMuted(media) &&
    !mediaSelectors.getMicrophoneExternallyMuted(media)
  )
}

/**
 * Check whether the member should start video
 */
export function shouldMemberStartVideo(member, media) {
  return (
    _.isMatch(member, inRoomPredicate) &&
    !mediaSelectors.getCameraMuted(media) &&
    !mediaSelectors.getCameraExternallyMuted(media) &&
    mediaSelectors.getAudioPlaybackState(media) === AUDIO_PLAYING_STATE.PLAYING
  )
}

/**
 * Check whether the member should send video
 */
export function shouldMemberSendVideo(member, channelRoom, media) {
  const hasRights = _.isMatch(member, videoSendRightPredicate)
  return (
    shouldMemberStartVideo(member, media) &&
    !isHostAlone(channelRoom) &&
    hasRights &&
    mediaSelectors.getAudioPlaybackState(media) === AUDIO_PLAYING_STATE.PLAYING
  )
}

/**
 * Check whether the member should start screen capture
 */
export function shouldMemberStartScreen(member) {
  return _.isMatch(member, screenSendRightPredicate)
}

/**
 * Check whether the member should send screen capture
 */
export function shouldMemberSendScreen(member, channelRoom, media) {
  return (
    shouldMemberStartScreen(member) &&
    !isHostAlone(channelRoom) &&
    mediaSelectors.getAudioPlaybackState(media) === AUDIO_PLAYING_STATE.PLAYING
  )
}

const roomMediaCommands = {
  getAudioConstraints: (store, deviceId = null) => {
    const { [DEVICES_STORE_NAME]: devices, [SETTINGS_STORE_NAME]: settings } =
      store.getState()
    let micDeviceId = deviceId
    if (!micDeviceId) {
      micDeviceId = settingsSelectors.getSelectedMicDeviceId(
        settings,
        devicesSelectors.getMicDevices(devices)
      )
    }
    return micDeviceId ? { deviceId: { exact: micDeviceId } } : {}
  },
  getVideoConstraints: (store, deviceId = null, facingMode = null) => {
    const { [SETTINGS_STORE_NAME]: settings } = store.getState()
    if (browserUtils.isMobileTouchDevice()) {
      return {
        facingMode: facingMode || settingsSelectors.getFacingMode(settings),
      }
    }
    let camDeviceId = deviceId
    if (!camDeviceId) {
      camDeviceId = settingsSelectors.getSelectedCamDeviceId(settings)
    }
    return camDeviceId ? { deviceId: { exact: camDeviceId } } : {}
  },
  getScreenConstraints: (sourceId = null) => {
    if (sourceId) {
      return { mandatory: { chromeMediaSourceId: sourceId } }
    }
    return true
  },

  closeExternalVideoConnection: async (memberId) => {
    return roomMediaManager.connectionManager.closeReceivingVideoConnection(
      memberId
    )
  },
  closeExternalThumbnailConnection: async (memberId) => {
    return roomMediaManager.connectionManager.closeReceivingThumbnailConnection(
      memberId
    )
  },
  closeExternalScreenConnection: async (memberId) => {
    return roomMediaManager.connectionManager.closeReceivingScreenConnection(
      memberId
    )
  },

  sendVideoStop: async () => {
    return roomMediaManager.connectionManager.closePublishingVideoConnection()
  },
  sendThumbnailStop: async () => {
    return roomMediaManager.connectionManager.closePublishingThumbnailConnection()
  },
  sendScreenStop: async () => {
    return roomMediaManager.connectionManager.closePublishingScreenConnection()
  },

  closeVideoConnection: async () =>
    Promise.all([
      roomMediaCommands.sendThumbnailStop(),
      roomMediaCommands.sendVideoStop(),
    ]),
  closeScreenConnection: async () => roomMediaCommands.sendScreenStop(),
  closeAudioConnection: async () => {
    roomMediaManager.trackManager.stopLocalTrack(ROOM_SERVICES.EXTERNAL_AUDIO)
    return roomMediaManager.connectionManager.closeAudioConnection()
  },

  createNewAudio: async (constraints) => {
    return roomMediaManager.trackManager.createLocalAudioTrack(constraints)
  },
  createNewVideo: async (constraints) => {
    return roomMediaManager.trackManager.createLocalVideoTrack(constraints)
  },
  createNewScreen: async (constraints) => {
    return roomMediaManager.trackManager.createLocalScreenTrack(constraints)
  },
  createNewAudioAndVideo: async (camConstraints, micConstraints) => {
    const possibleErrors = [
      'NotAllowedError',
      'PermissionDeniedError',
      'SecurityError',
    ]
    const hasConstraints = camConstraints && micConstraints

    try {
      return await roomMediaManager.trackManager.createLocalAudioAndVideoTracks(
        micConstraints,
        camConstraints
      )
    } catch (error1) {
      if (!possibleErrors.includes(error1.name) || !hasConstraints) throw error1

      try {
        return await roomMediaCommands.createNewAudio(micConstraints)
      } catch (error2) {
        if (!possibleErrors.includes(error2.name) || !hasConstraints)
          throw error2
        return roomMediaCommands.createNewVideo(camConstraints)
      }
    }
  },

  setAudio: (track) => {
    roomMediaManager.trackManager.setLocalAudioTrack(track)
  },
  setVideo: (track) => {
    roomMediaManager.trackManager.setLocalVideoTrack(track)
  },
  setScreen: (track) => {
    roomMediaManager.trackManager.setLocalScreenTrack(track)
  },

  /**
   * Stop publishing/sending the audio track from the microphone.
   */
  unpublishAudio: () => {
    roomMediaManager.trackManager.tracks$[ROOM_SERVICES.ROOM_AUDIO]
      .pipe(take(1), filter(Boolean))
      .subscribe((localAudioTrack) => {
        const audioMixer = roomMediaManager.getAudioMixer()

        // If there is an audio mixer active, we remove the local track from it.
        // If there is no audio mixer active, we unpublish the audio track from
        // the audio connection.
        if (audioMixer) {
          audioMixer.removeTrack(localAudioTrack)
        } else {
          roomMediaManager.connectionManager.unpublishAudioTrack()
        }
      })
  },

  /**
   * Start publishing/sending the audio track from the microphone to the other
   * participants.
   */
  sendAudio: () => {
    roomMediaManager.trackManager.tracks$[ROOM_SERVICES.ROOM_AUDIO]
      .pipe(take(1), filter(Boolean))
      .subscribe((localAudioTrack) => {
        const audioMixer = roomMediaManager.getAudioMixer()
        if (audioMixer) {
          audioMixer.replaceLocalTrack(localAudioTrack)
        } else {
          roomMediaManager.connectionManager.publishAudioTrack(localAudioTrack)
        }
      })
  },
  sendVideo: () => {
    roomMediaManager.trackManager.tracks$[ROOM_SERVICES.ROOM_VIDEO]
      .pipe(filter(Boolean), take(1))
      .subscribe((localVideoTrack) => {
        roomMediaManager.connectionManager.publishVideoTrack(localVideoTrack)
        roomMediaManager.connectionManager.publishThumbnailTrack(
          localVideoTrack
        )
      })
  },
  sendScreen: () => {
    roomMediaManager.trackManager.tracks$[ROOM_SERVICES.ROOM_SCREEN]
      .pipe(filter(Boolean), take(1))
      .subscribe((localScreenTrack) => {
        roomMediaManager.connectionManager.publishScreenTrack(localScreenTrack)
      })
  },

  sendOrStopVideo: (store) => {
    const {
      [CHANNEL_ROOM_STORE_NAME]: channelRoom,
      [MEDIA_STORE_NAME]: media,
    } = store.getState()
    const me = getMe(channelRoom)

    // If the member should still send video, join the video and thumbnail room.
    // If the member in meantime is muted or something else, stop the just created track.
    if (
      roomMediaManager.trackManager.getLocalVideoSourceTrack() &&
      shouldMemberSendVideo(me, channelRoom, media)
    ) {
      roomMediaCommands.sendVideo()
    } else if (
      roomMediaManager.trackManager.getLocalVideoSourceTrack() &&
      !shouldMemberStartVideo(me, media)
    ) {
      roomMediaCommands.closeVideoConnection()
    }
  },
  sendOrStopScreen: (store) => {
    const {
      [CHANNEL_ROOM_STORE_NAME]: channelRoom,
      [MEDIA_STORE_NAME]: media,
    } = store.getState()
    const me = getMe(channelRoom)

    // If the member should still send screen, join the screen room.
    // If the member in meantime has no rights anymore, stop the just created track.
    if (
      roomMediaManager.trackManager.getLocalScreenSourceTrack() &&
      shouldMemberSendScreen(me, channelRoom, media)
    ) {
      roomMediaCommands.sendScreen()
    } else if (
      roomMediaManager.trackManager.getLocalScreenSourceTrack() &&
      !shouldMemberStartScreen(me)
    ) {
      roomMediaCommands.stopSendScreen()
    }
  },

  startAudioIfPossible: async (store, constraints = null) => {
    const {
      [CHANNEL_ROOM_STORE_NAME]: channelRoom,
      [MEDIA_STORE_NAME]: media,
    } = store.getState()
    const me = getMe(channelRoom)

    let usedConstraints = constraints
    if (!usedConstraints) {
      usedConstraints = roomMediaCommands.getAudioConstraints(store)
    }

    // If the member should start audio, create a audio track.
    if (shouldMemberStartAudio(me, media) && usedConstraints) {
      await roomMediaCommands.createNewAudio(usedConstraints)

      if (shouldMemberEnableAudio(media)) {
        roomMediaCommands.sendAudio()
      }
    }
  },
  startVideoIfPossible: async (store, constraints = null) => {
    const {
      [CHANNEL_ROOM_STORE_NAME]: channelRoom,
      [MEDIA_STORE_NAME]: media,
    } = store.getState()
    const me = getMe(channelRoom)

    let usedConstraints = constraints
    if (!usedConstraints) {
      usedConstraints = roomMediaCommands.getVideoConstraints(store)
    }

    // If the member should start video, create a video track.
    if (shouldMemberStartVideo(me, media) && usedConstraints) {
      await roomMediaCommands.createNewVideo(usedConstraints)
      roomMediaCommands.sendOrStopVideo(store)
    }
  },

  startScreenIfPossible: async (store, sourceId = null) => {
    const { [CHANNEL_ROOM_STORE_NAME]: channelRoom } = store.getState()
    const me = getMe(channelRoom)

    if (shouldMemberStartScreen(me)) {
      if (!sourceId && browserUtils.isElectron()) {
        throw new Error('No source given')
      }
      const constraints = roomMediaCommands.getScreenConstraints(sourceId)
      // Get screen and possible audio track
      const tracks = await roomMediaCommands.createNewScreen(constraints)
      roomMediaCommands.sendOrStopScreen(store)

      // If the user has enabled audio
      if (tracks.audio) {
        // Start mixer if it's not started already
        if (!roomMediaManager.getAudioMixer()) {
          await roomMediaCommands.startAudioMixer()
        }

        roomMediaCommands.addTrackToAudioMixer(tracks.audio)

        if (shouldMemberEnableAudio(store.getState()[MEDIA_STORE_NAME])) {
          roomMediaCommands.sendAudio()
        }

        await roomMediaCommands.sendMixedAudio()

        // When screensharing audio stops
        roomMediaManager.trackManager.tracks$[
          ROOM_SERVICES.SCREEN_SHARING_AUDIO
        ]
          .pipe(
            filter((val) => !val),
            take(1)
          )
          .subscribe(() => {
            // Stop mixer if there is at most one other track left besides the audio track
            if (
              roomMediaManager.getAudioMixer() &&
              _.without(roomMediaManager.getAudioMixerTracks(), tracks.audio)
                .length <= 1
            ) {
              roomMediaCommands.stopAudioMixer()

              if (
                roomMediaManager.trackManager.getLocalAudioSourceTrack() &&
                shouldMemberEnableAudio(store.getState()[MEDIA_STORE_NAME])
              ) {
                roomMediaCommands.sendAudio()
              }
            }
          })
      }
    }
  },

  stopAudioTrack: () => {
    roomMediaManager.trackManager.stopLocalAudioTrack()
  },
  stopVideoTrack: () => {
    roomMediaManager.trackManager.stopLocalVideoTrack()
  },
  stopScreenTrack: () => {
    roomMediaManager.trackManager.stopLocalScreenTrack()
    roomMediaManager.trackManager.stopScreenSharingAudioTrack()
  },

  stopSendAudio: async () => {
    roomMediaCommands.stopAudioTrack()
  },
  stopSendVideo: async () => {
    roomMediaCommands.stopVideoTrack()
    return roomMediaCommands.closeVideoConnection()
  },
  stopSendScreen: async () => {
    roomMediaCommands.stopScreenTrack()
    return roomMediaCommands.closeScreenConnection()
  },

  initializeRoomAudio: async () => {
    const localTrack = createDummyAudio()
    const connection =
      await roomMediaManager.connectionManager.initialiseAudioConnection(
        localTrack
      )

    if (connection.remoteTracks$.getValue().length) {
      roomMediaManager.trackManager.setExternalAudioTrack(
        connection.remoteTracks$.getValue()[0]
      )
    }

    connection.addListener(
      ConnectionEvents.REMOTE_TRACK_ADDED,
      (remoteTrack) => {
        roomMediaManager.trackManager.setExternalAudioTrack(remoteTrack)
      }
    )
  },

  startAudioMixer: async () => {
    const audioMixer = new RoomAudioMixer()
    roomMediaManager.setAudioMixer(new RoomAudioMixer())
    await audioMixer.init()
  },
  sendMixedAudio: async () => {
    const audioMixer = roomMediaManager.getAudioMixer()
    const track = await audioMixer.getOutput()
    roomMediaManager.connectionManager.publishAudioTrack(track)
  },
  addTrackToAudioMixer: (track) => {
    const audioMixer = roomMediaManager.getAudioMixer()
    audioMixer.addTrack(track)
  },
  stopAudioMixer: () => {
    const audioMixer = roomMediaManager.getAudioMixer()
    audioMixer.close()
    roomMediaManager.setAudioMixer(null)
  },

  setVideoStreamerAudio: (track) => {
    roomMediaManager.trackManager.setVideoStreamerAudioTrack(track)
  },
  stopVideoStreamerAudio: () => {
    const audioMixer = roomMediaManager.getAudioMixer()
    const track = roomMediaManager.trackManager.getVideoStreamerAudioTrack()

    if (audioMixer && track) {
      audioMixer.removeTrack(track)
    }
    roomMediaManager.trackManager.stopVideoStreamerAudioTrack()
  },
  setScreenSharingAudio: (track) => {
    roomMediaManager.trackManager.setScreenSharingAudioTrack(track)
  },
  stopScreenSharingAudio: () => {
    roomMediaManager.trackManager.stopScreenSharingAudioTrack()
  },
  /**
   * Request the feeds of video and/or audio based on the stored device ids in the store.
   * Publish the requested feeds when allowed and wanted.
   * @param store
   * @return
   */
  initialisePublishingStreams: async (store) => {
    const {
      [CHANNEL_ROOM_STORE_NAME]: channelRoom,
      [MEDIA_STORE_NAME]: media,
    } = store.getState()
    const me = getMe(channelRoom)

    let videoTrack = roomMediaManager.trackManager.getLocalVideoSourceTrack()
    let audioTrack = roomMediaManager.trackManager.getLocalAudioSourceTrack()

    const shouldStartVideo = shouldMemberStartVideo(me, media)
    const shouldStartAudio = shouldMemberStartAudio(me, media)

    // We only want to request new video feed when the local users video is not muted and there is no video track yet.
    const shouldRequestVideo = shouldStartVideo && !videoTrack
    // We only want to request new audio feed when the local users audio is not muted and there is no audio track yet.
    const shouldRequestAudio = shouldStartAudio && !audioTrack

    if (shouldRequestVideo && shouldRequestAudio) {
      // When both video and audio should start, we combine the camera and microphone request to the user.
      const tracks = await roomMediaCommands.createNewAudioAndVideo(
        roomMediaCommands.getVideoConstraints(store),
        roomMediaCommands.getAudioConstraints(store)
      )
      videoTrack = tracks.video
      audioTrack = tracks.audio
    } else if (shouldRequestVideo) {
      videoTrack = await roomMediaCommands.createNewVideo(
        roomMediaCommands.getVideoConstraints(store)
      )
    } else if (shouldRequestAudio) {
      audioTrack = await roomMediaCommands.createNewAudio(
        roomMediaCommands.getAudioConstraints(store)
      )
    }
  },
  initialisePublishingConnections: async (store) => {
    const {
      [CHANNEL_ROOM_STORE_NAME]: channelRoom,
      [MEDIA_STORE_NAME]: media,
    } = store.getState()
    const me = getMe(channelRoom)

    const videoTrack = roomMediaManager.trackManager.getLocalVideoTrack()
    const audioTrack = roomMediaManager.trackManager.getLocalAudioTrack()

    const shouldStartVideo = shouldMemberStartVideo(me, media)
    const shouldStartAudio = shouldMemberStartAudio(me, media)

    // TODO: Refactor how local video tracks are fetched to cater for the fact that the value may only come later.
    // See https://jira.voiceworks.com/browse/SUM-4429
    if (shouldStartVideo && !videoTrack) {
      roomMediaManager.trackManager.tracks$[ROOM_SERVICES.ROOM_VIDEO]
        .pipe(filter(Boolean), take(1))
        .subscribe(() => {
          roomMediaCommands.sendOrStopVideo(store)
        })
    }

    if (shouldStartVideo && videoTrack) {
      // When the local user is able to publish video and there is a video track, publish that video feed.
      roomMediaCommands.sendOrStopVideo(store)
    }
    if (shouldStartAudio && audioTrack && shouldMemberEnableAudio(media)) {
      // When the local user is able to publish audio and there is a audio track, publish that audio feed.
      roomMediaCommands.sendAudio()
    }
  },

  enableBackgroundBlur: (config) => {
    roomMediaManager.trackManager.processors[
      ROOM_SERVICES.ROOM_VIDEO
    ].setDrawingStrategy(new BackgroundBlurStrategy(config))

    roomMediaManager.trackManager.processors[
      ROOM_SERVICES.DEVICE_PICKER_VIDEO
    ].setDrawingStrategy(new BackgroundBlurStrategy(config))

    roomMediaManager.trackManager.enableBackgroundEffect()
  },

  enableBackgroundReplace: (config) => {
    const drawingConfig = roomMediaCommands._addImageToConfig(config, true)
    roomMediaManager.trackManager.processors[
      ROOM_SERVICES.ROOM_VIDEO
    ].setDrawingStrategy(new BackgroundReplaceStrategy(drawingConfig))
    roomMediaManager.trackManager.processors[
      ROOM_SERVICES.DEVICE_PICKER_VIDEO
    ].setDrawingStrategy(new BackgroundReplaceStrategy(drawingConfig))

    roomMediaManager.trackManager.enableBackgroundEffect()
  },

  setDrawingConfig: (config) => {
    let drawingConfig = config

    if (config.backgroundImageName) {
      drawingConfig = roomMediaCommands._addImageToConfig(config)
    }

    roomMediaManager.trackManager.processors[
      ROOM_SERVICES.ROOM_VIDEO
    ].setDrawingConfig(drawingConfig)
    roomMediaManager.trackManager.processors[
      ROOM_SERVICES.DEVICE_PICKER_VIDEO
    ].setDrawingConfig(drawingConfig)
  },

  setFrameProcessingStrategy: (strategy) => {
    roomMediaManager.trackManager.processors[
      ROOM_SERVICES.ROOM_VIDEO
    ].setFrameProcessingStrategy(strategy)
    roomMediaManager.trackManager.processors[
      ROOM_SERVICES.DEVICE_PICKER_VIDEO
    ].setFrameProcessingStrategy(strategy)
  },

  stopBackgroundEffect: () => {
    roomMediaManager.trackManager.disableBackgroundEffect()
  },

  // TODO: Investigate if firstDraw is still necessary
  _addImageToConfig: (config, firstDraw) => {
    const effectConfig = config

    const backgroundImage =
      BackgroundEffectImages[effectConfig.backgroundImageName]

    const imageSmall = new Image()
    const imageLarge = new Image()

    if (backgroundImage) {
      imageSmall.src = backgroundImage.small
      imageLarge.src = backgroundImage.large
    }

    effectConfig.backgroundImage = firstDraw ? imageLarge : imageSmall

    if (!firstDraw) {
      imageLarge.onload = () => {
        const largeImageConfig = {
          ...effectConfig,
          backgroundImage: imageLarge,
        }

        roomMediaManager.trackManager.processors[
          ROOM_SERVICES.ROOM_VIDEO
        ].setDrawingConfig(largeImageConfig)
        roomMediaManager.trackManager.processors[
          ROOM_SERVICES.DEVICE_PICKER_VIDEO
        ].setDrawingConfig(largeImageConfig)
      }
    }

    return effectConfig
  },

  enableNoiseCancellation: () => {
    roomMediaManager.trackManager.processors[
      ROOM_SERVICES.ROOM_AUDIO
    ].setEnabled(true)
  },
  disableNoiseCancellation: () => {
    roomMediaManager.trackManager.processors[
      ROOM_SERVICES.ROOM_AUDIO
    ].setEnabled(false)
  },
}

export default roomMediaCommands
