import { type HTMLAttributes } from 'react'
import { ClBadge } from '@enreach/core-component-library-react'
import { defineCustomElementClBadge } from '@enreach/core-component-library/dist/components/index'
import clsx from 'clsx'

import { makeStyles } from 'app/ui'

import { type BadgeProps } from './badge.types'

const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  badge: {
    top: 'calc(-1 * var(--spacing-half))',
    right: 'calc(-1 * var(--spacing-half))',
    position: 'absolute',
  },
  dot: {
    '& > cl-badge > *': {
      display: 'none',
    },
  },
})
/**
 * the Badge component is a wrapper around the core component library Badge component
 * that adds the ability to hide the badge when the value is 0 and to show a dot instead
 *
 * @param props
 */
export const Badge = ({
  children,
  invisible = false,
  rootProps = {} as HTMLAttributes<HTMLDivElement>,
  showZero = false,
  max,
  value,
  variant = 'value',
  ...badgeProps
}: BadgeProps): JSX.Element => {
  const classes = useStyles()

  if (value === undefined) {
    variant = 'dot'
  }

  const hideBadge =
    (!showZero && value === 0 && variant === 'value') || invisible

  return (
    <div
      {...rootProps}
      className={clsx(
        classes.root,
        rootProps.className,
        variant === 'dot' && classes.dot
      )}
    >
      {children}
      {!hideBadge && (
        <ClBadge
          {...badgeProps}
          className={clsx(classes.badge, badgeProps.className)}
          max={max}
          value={value}
        />
      )}
    </div>
  )
}
export default Badge

defineCustomElementClBadge()
