import dotProp from 'dot-prop-immutable'
import _ from 'lodash'

import { DEFAULT_MAX_GUESTS } from 'constants/constants'
import localStore from 'app/services/state/local-store'
import { getSocket } from '../socket'

import { handleMessage, createStateLoggingActionHandler } from './utils'

const channelConf = {
  id: 'Summa.User.Api.Settings',
  conf: { version: '2.0' },
}

export const STORE_NAME = 'channel/settings'
export const HOST_SETUP_COMPLETED = 'completed'
/**
 * channel instance
 * @param
 */
let channel = null

// ------------------------------------
// Channel events
// ------------------------------------
const EVENT_LIST = 'settings_list'
const EVENT_DIFF = 'settings_diff'

// ------------------------------------
// Actions
// ------------------------------------
const JOINING = `sm-web/${STORE_NAME}/JOINING`
const JOINED = `sm-web/${STORE_NAME}/JOINED`
const JOINING_ERROR = `sm-web/${STORE_NAME}/JOINING_ERROR`
const JOINING_TIMEOUT = `sm-web/${STORE_NAME}/JOINING_TIMEOUT`
const ERROR = `sm-web/${STORE_NAME}/ERROR`
const CLOSING = `sm-web/${STORE_NAME}/CLOSING`
const CLOSED = `sm-web/${STORE_NAME}/CLOSED`
const CLOSING_ERROR = `sm-web/${STORE_NAME}/CLOSING_ERROR`
const CLOSING_TIMEOUT = `sm-web/${STORE_NAME}/CLOSING_TIMEOUT`
const LOG = `sm-web/${STORE_NAME}/LOG`
const SETTINGS_SET = `sm-web/${STORE_NAME}/SETTINGS_SET`
const SETTINGS_UPDATE = `sm-web/${STORE_NAME}/SETTINGS_UPDATE`

export const ACTION_TYPES = {
  JOINING,
  JOINED,
  JOINING_ERROR,
  JOINING_TIMEOUT,
  ERROR,
  CLOSING,
  CLOSED,
  CLOSING_ERROR,
  CLOSING_TIMEOUT,
  LOG,
  SETTINGS_SET,
  SETTINGS_UPDATE,
}

/**
 * @param payload
 */
const joined = (payload) => ({ type: JOINED, payload })
/**
 * @param error
 */
const joiningError = (error) => ({ type: JOINING_ERROR, error })
/**
 * @param event
 */
const joiningTimeout = () => ({ type: JOINING_TIMEOUT })
/**
 * @param error
 */
const error = (e) => ({ type: ERROR, error: e })
/**
 * @param payload
 */
const closed = (payload) => ({ type: CLOSED, payload })
/**
 * @param error
 */
const closingError = (e) => ({ type: CLOSING_ERROR, error: e })
/**
 * @param event
 */
const closingTimeout = () => ({ type: CLOSING_TIMEOUT })
/**
 */
const log = (event, payload) => (dispatch) => {
  if (localStore.getDebugMode()) {
    dispatch({ type: LOG, event, payload })
  }
}
/**
 * @param payload
 */
const list = (payload) => ({ type: SETTINGS_SET, payload })
/**
 * @param payload
 */
const diff = (payload) => {
  const updates = {}
  if (payload.added) {
    updates.added = payload.added.map((added) => [
      `settings.${added.path.join('.')}`,
      added.value,
    ])
  }
  if (payload.updated) {
    updates.updated = payload.updated.map((updated) => [
      `settings.${updated.path.join('.')}`,
      updated.value,
    ])
  }
  if (payload.removed) {
    updates.removed = payload.removed.map(
      (removed) => `settings.${removed.join('.')}`
    )
  }
  return {
    type: SETTINGS_UPDATE,
    payload: updates,
  }
}

const channelEventActionMapping = {
  [EVENT_LIST]: (payload) => list(payload),
  [EVENT_DIFF]: (payload) => diff(payload),
}

/**
 * exported action to join channel
 */
const join = () => async (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: JOINING })
    const socket = getSocket()
    channel = socket.channel(channelConf.id, channelConf.conf)
    channel.onMessage = handleMessage(
      dispatch,
      channelEventActionMapping,
      channelConf,
      log
    )
    channel.onError((event) => dispatch(error(event)))
    channel.onClose((event) => dispatch(closed(event)))
    channel
      .join()
      .receive('ok', (payload) => {
        dispatch(joined(payload))
        resolve()
      })
      .receive('error', (e) => {
        dispatch(joiningError(e))
        reject(new Error(JSON.stringify(e)))
      })
      .receive('timeout', () => {
        dispatch(joiningTimeout())
        reject(new Error('Timeout'))
      })
  })

/**
 * exported action to leave the channel
 */
const leave = () => async (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: CLOSING })
    if (channel) {
      channel
        .leave()
        .receive('ok', resolve)
        .receive('error', (e) => {
          dispatch(closingError(e))
          reject(new Error(JSON.stringify(e)))
        })
        .receive('timeout', () => {
          dispatch(closingTimeout())
          reject(new Error('Timeout'))
        })
    } else {
      resolve()
    }
  })

const update = async (payload) =>
  new Promise((resolve, reject) => {
    channel
      .push('settings_update', payload)
      .receive('ok', (response) => {
        resolve(response)
      })
      .receive('error', (e) => {
        reject(new Error(`"settings_update": ${JSON.stringify(e)}`))
      })
      .receive('timeout', () => {
        reject(new Error('"settings_update": Timeout'))
      })
  })

const setHostSetupCompleted = async (completed) =>
  update({
    meetings_host_setup: completed ? 'completed' : null,
  })

/**
 */
export const actions = {
  join,
  leave,
  setHostSetupCompleted,
  closed,
}
// ------------------------------------
// Reducer
// ------------------------------------
const INITIAL_STATE = {
  settings: {},
  '@@channel': {
    state: CLOSED,
    log: [],
  },
}
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [JOINING]: createStateLoggingActionHandler(JOINING, 'JOINING'),
  [JOINED]: createStateLoggingActionHandler(JOINED, 'JOINED', 'payload'),
  [JOINING_ERROR]: createStateLoggingActionHandler(
    JOINING_ERROR,
    'JOINING_ERROR',
    'error'
  ),
  [JOINING_TIMEOUT]: createStateLoggingActionHandler(
    JOINING_TIMEOUT,
    'JOINING_TIMEOUT'
  ),
  [CLOSING]: createStateLoggingActionHandler(CLOSING, 'CLOSING'),
  [CLOSED]: createStateLoggingActionHandler(CLOSED, 'CLOSED'),
  [CLOSING_ERROR]: createStateLoggingActionHandler(
    CLOSING_ERROR,
    'CLOSING_ERROR',
    'error'
  ),
  [CLOSING_TIMEOUT]: createStateLoggingActionHandler(
    CLOSING_TIMEOUT,
    'CLOSING_TIMEOUT'
  ),
  [ERROR]: createStateLoggingActionHandler(ERROR, 'ERROR', 'error'),
  [LOG]: (state, action) =>
    dotProp.set(state, '@@channel.log', (l) => [
      ...l,
      {
        ts: Date.now(),
        kind: 'event',
        msg: action.event,
        data: action.payload,
      },
    ]),
  [SETTINGS_SET]: (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      ...action.payload,
    },
  }),
  [SETTINGS_UPDATE]: (state, { payload: { added, updated, removed } }) => {
    let newState = { ...state }
    if (added) {
      added.forEach(([path, value]) => {
        newState = dotProp.set(newState, path, value)
      })
    }
    if (updated) {
      updated.forEach(([path, value]) => {
        newState = dotProp.set(newState, path, value)
      })
    }
    if (removed) {
      removed.forEach((path) => {
        newState = dotProp.delete(newState, path)
      })
    }
    return newState
  },
  'sm-web/RESET': () => INITIAL_STATE,
}

export default (state = INITIAL_STATE, action = {}) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export const selectors = {
  getChannelState(state) {
    if (!state || typeof state !== 'object') {
      const err = new Error('Given state should be an object')
      err.state = state
      throw err
    }
    return _.get(state, '@@channel.state')
  },
  isJoining(state) {
    const channelState = selectors.getChannelState(state)
    return channelState === JOINING
  },
  isJoiningError(state) {
    const channelState = selectors.getChannelState(state)
    return channelState === JOINING_ERROR
  },
  isJoiningTimeout(state) {
    const channelState = selectors.getChannelState(state)
    return channelState === JOINING_TIMEOUT
  },
  isJoined(state) {
    const channelState = selectors.getChannelState(state)
    return channelState === JOINED
  },
  isClosing(state) {
    const channelState = selectors.getChannelState(state)
    return channelState === CLOSING
  },
  isClosed(state) {
    const channelState = selectors.getChannelState(state)
    return channelState === CLOSED
  },
  getSettings(state) {
    return _.get(state, 'settings', {})
  },
  getRoomLimitGuests(state) {
    const settings = selectors.getSettings(state)
    return _.get(settings, 'room_limit_guests', DEFAULT_MAX_GUESTS)
  },
  hasReceivedSettings(state) {
    return !_.isEmpty(selectors.getSettings(state))
  },
  isHostSetupCompleted(state) {
    const settings = selectors.getSettings(state)
    return _.get(settings, 'meetings_host_setup') === HOST_SETUP_COMPLETED
  },

  parseSettingsToUserMeta(state, options = {}) {
    const settings = selectors.getSettings(state)
    const meta = {}

    if (settings.displayname) {
      meta.userName = settings.displayname
    }

    if (settings.avatar && options.avatarDownloadUrl) {
      meta.avatarImage = `${options.avatarDownloadUrl}${settings.avatar}`
    }

    return meta
  },
}
