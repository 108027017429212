class IFrameStrategy {
  /**
   *
   */
  target

  /**
   *
   */
  origin

  /**
   * @param target
   * @param origin
   */
  constructor(target, origin) {
    this.target = target
    this.origin = origin
  }

  /**
   *
   * @param event
   * @param [payload=null]
   */
  broadcast(event, payload = null) {
    this.target.postMessage({ event, payload }, this.origin)
  }
}

export default IFrameStrategy
