import { useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'

import ResizeObserver from 'lib/ponyfills/resize-observer.ponyfill'

import { useEventRef } from './use-event.hook'

/**
 * Hook observing mutations in an element causing it to resize.
 * @param ref
 * @param cb
 * @return
 */
function useResize(ref, cb) {
  const cbRef = useRef(null)
  cbRef.current = cb

  /**
   *
   */
  const resizeObserver = useMemo(
    () =>
      new ResizeObserver(
        /**
         * @param entries
         * @param observer
         * @return
         */
        (entries, observer) => {
          if (!entries.length) return
          cbRef.current(entries[0], observer)
        }
      ),
    []
  )

  // When the resizeObserver changes, or hook is unmounted, disconnect all observed entries.
  useEffect(
    () => () => {
      resizeObserver.disconnect()
    },
    [resizeObserver]
  )

  // Observe the given refs element.
  useEffect(() => {
    const element = ref.current
    if (element) {
      resizeObserver.observe(element)
      return () => {
        resizeObserver.unobserve(element)
      }
    }

    return Function.prototype
  }, [ref, resizeObserver])
}

/**
 * Hook returning the fitting breakpoint based on the size of the given ref.
 * @param ref
 * @param breakpoints
 * @param options
 * @param options.breakValue
 * @return
 */
function useRefBreakpoint(ref, breakpoints, options = {}) {
  options.breakValue = options.breakValue || 'width'

  const [breakpoint, setBreakpoint] = useState(breakpoints[0])

  useResize(ref, (entry) => {
    const { [options.breakValue]: value } = entry.contentRect
    setBreakpoint(
      _.chain(breakpoints)
        .filter((bp) => bp < value)
        .sort()
        .last()
        .value()
    )
  })

  return breakpoint
}

/**
 * @param ref
 * @return
 */
function useVideoSize(ref) {
  const [width, setWidth] = useState(ref.current?.videoWidth ?? 0)
  const [height, setHeight] = useState(ref.current?.videoHeight ?? 0)

  useEventRef(ref, 'resize', (e) => {
    setWidth(e.target.videoWidth)
    setHeight(e.target.videoHeight)
  })

  return { width, height }
}

export { useResize, useRefBreakpoint, useVideoSize }
