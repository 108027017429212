import { ThemeOptions } from '@mui/material'

import { FONTS } from 'constants/constants'

const typographyConfig = {
  headers: {
    fontFamily: 'var(--font-family-brand)',
    fontWeight: 'var(--font-weight-700)',
  },
  other: {
    fontFamily: `${FONTS.emojiFont}, var(--font-family-ui)`,
    lineHeight: '1.4',
  },
}

/**
 *
 * Almost all of the values have `!important` applied to them, because it should
 * always overwrite any default values.
 */
const themeConfig: ThemeOptions = {
  palette: {
    primary: {
      light: 'rgb(var(--color-brand-400))',
      main: 'rgb(var(--color-brand-500))',
      dark: 'rgb(var(--color-brand-600))',
    },
    secondary: {
      light: 'rgb(var(--color-accent-400))',
      main: 'rgb(var(--color-accent-500))',
      dark: 'rgb(var(--color-accent-600))',
    },
    error: {
      light: 'rgb(var(--system-color-error-light))',
      main: 'rgb(var(--system-color-error-main))',
      dark: 'rgb(var(--system-color-error-main))',
    },
    warning: {
      light: 'rgb(var(--system-color-warning-light))',
      main: 'rgb(var(--system-color-warning-main))',
      dark: 'rgb(var(--system-color-warning-main))',
    },
    info: {
      light: 'rgb(var(--system-color-info-blue-light))',
      main: 'rgb(var(--system-color-info-blue-main))',
      dark: 'rgb(var(--system-color-info-blue-main))',
    },
    success: {
      light: 'rgb(var(--system-color-success-light))',
      main: 'rgb(var(--system-color-success-main))',
      dark: 'rgb(var(--system-color-success-main))',
    },
    background: {
      default: 'rgba(var(--color-neutral-100)) ',
      paper: 'rgba(var(--color-neutral-100))',
      canvas: {
        light: 'rgba(var(--color-neutral-700))',
        main: 'rgba(var(--color-neutral-main))',
        dark: 'rgba(var(--color-neutral-900))',
      },
      avatar: 'rgba(var(--color-neutral-700))',
      toolbar: 'rgba(var(--color-neutral-600))',
    },
  },

  measurements: {
    logo: {
      height: 38,
      width: 192,
    },
    videoStreamerPlayer: {
      width: 700,
    },
    drawer: {
      width: 320,
    },
    dialog: {
      notification: {
        minWidth: 320,
        maxWidth: 600,
        width: 'fit-content',
      },
    },
  },

  fontFamily: `${FONTS.emojiFont}, Roboto, sans-serif`,

  typography: {
    h1: typographyConfig.headers,
    h2: typographyConfig.headers,
    h3: typographyConfig.headers,
    h4: typographyConfig.headers,
    h5: typographyConfig.headers,
    h6: typographyConfig.headers,
    subtitle1: {
      ...typographyConfig.other,
      fontWeight: 'var(--font-weight-500)',
    },
    subtitle2: {
      ...typographyConfig.other,
      fontWeight: 'var(--font-weight-700)',
    },
    body1: {
      ...typographyConfig.other,
      fontWeight: 'var(--font-weight-400)',
    },
    body2: {
      ...typographyConfig.other,
      fontWeight: 'var(--font-weight-400)',
    },
    button: {
      ...typographyConfig.other,
      fontWeight: 'var(--font-weight-600)',
    },
    caption: {
      ...typographyConfig.other,
      fontWeight: 'var(--font-weight-500)',
    },
    overline: {
      ...typographyConfig.other,
      fontWeight: 'var(--font-weight-400)',
    },
  },

  shape: {
    borderRadius: 'var(--border-radius-interactive-small)',
  },

  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    privateChat: 1099,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },

  /**
   * global overrides on component props
   * @see https://material-ui.com/customization/globals/#default-props
   */
  props: {
    MuiLink: {
      color: 'textPrimary',
      underline: 'always',
    },
  },
}

export default themeConfig
