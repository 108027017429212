import { actions as contentActions } from 'app/features/content/content.reducer'
import {
  ACTION_TYPES as SETTINGS_ACTION_TYPES,
  actions as settingsActions,
} from 'app/features/settings/settings.reducer'
import localStore from 'app/services/state/local-store'
import {
  isPermissionDefault,
  requestPermission,
  shouldCreateNotification,
} from './desktop-notification.utils'
import {
  createFileUploadedNotification,
  createHostMuteAudioNotification,
  createHostMuteVideoNotification,
  createHostRaiseHandNotification,
  createHostUnmuteAudioNotification,
  createHostUnmuteVideoNotification,
  createLeftWaitingRoomNotification,
  createNotificationsEnabledNotification,
  createPrivateMessageNotification,
  createScreenShareRequestNotification,
  createScreenShareStartedNotification,
  createSlowLinkNotification,
  createUserJoinedMeetingNotification,
  createWaitingRoomNotification,
} from './desktop-notification-creators'
import {
  STORE_NAME as NOTIFICATION_STORE_NAME,
  ACTION_TYPES as DESKTOP_NOTIFICATION_ACTION_TYPES,
  actions as desktopNotificationActions,
  selectors as desktopNotificationSelectors,
} from './desktop-notification.reducer'

import { ACTION_TYPES as NOTIFICATION_ACTION_TYPES } from '../notifications/notification.creators'
import NOTIFICATION_TYPES from '../notifications/notification-types'

const createNotificationHandlers = {
  /* --- Notifications for all users --- */
  // Local user having a poor connection
  [NOTIFICATION_TYPES.SLOW_LINK]: createSlowLinkNotification,

  // Local user receives a private chat message
  [NOTIFICATION_TYPES.PRIVATE_MESSAGE]: createPrivateMessageNotification,

  // Someone starts screensharing
  [NOTIFICATION_TYPES.SCREEN_SHARE_STARTED]:
    createScreenShareStartedNotification,

  // A file was uploaded to the meeting
  [NOTIFICATION_TYPES.FILE_UPLOADED]: createFileUploadedNotification,

  /* --- Host only --- */
  // Guest raises hand
  [NOTIFICATION_TYPES.HOST_RAISE_HAND]: createHostRaiseHandNotification,

  // Auto accept is on and guest joins room
  [NOTIFICATION_TYPES.USER_ENTERS_ROOM]: createUserJoinedMeetingNotification,
  // Guest(s) entering waiting room
  [NOTIFICATION_TYPES.USER_ENTERS_WAITING_ROOM]: createWaitingRoomNotification,
  // Guest(s) leaving waiting room
  [NOTIFICATION_TYPES.USER_LEAVES_WAITING_ROOM]:
    createLeftWaitingRoomNotification,

  // Guest requests screen share
  [NOTIFICATION_TYPES.SCREEN_SHARE_REQUEST]:
    createScreenShareRequestNotification,

  /* --- Guest only --- */
  // Host muted local user's video
  [NOTIFICATION_TYPES.VIDEO_MUTED_BY_HOST]: createHostMuteVideoNotification,
  // Host unmuted local user's video
  [NOTIFICATION_TYPES.VIDEO_UNMUTED_BY_HOST]: createHostUnmuteVideoNotification,
  // Host muted local user's audio
  [NOTIFICATION_TYPES.AUDIO_MUTED_BY_HOST]: createHostMuteAudioNotification,
  // Host unmuted local user's audio
  [NOTIFICATION_TYPES.AUDIO_UNMUTED_BY_HOST]: createHostUnmuteAudioNotification,
}

export default (store) => (next) => (action) => {
  const { dispatch, getState } = store

  switch (action.type) {
    case NOTIFICATION_ACTION_TYPES.CREATE_NOTIFICATION:
      if (shouldCreateNotification(getState())) {
        const { type, ...rest } = action.payload

        const notificationHandler = createNotificationHandlers[type]

        if (notificationHandler) {
          dispatch(notificationHandler(...Object.values(rest)))
        }
      }
      break

    case SETTINGS_ACTION_TYPES.SET_DESKTOP_NOTIFICATIONS:
      localStore.setDesktopNotificationsEnabled(action.payload)

      // If notifications are turned on and we haven't requested permission yet
      if (action.payload && isPermissionDefault()) {
        requestPermission().then((permission) => {
          if (permission === 'granted') {
            dispatch(createNotificationsEnabledNotification())
          } else {
            dispatch(settingsActions.setDesktopNotifications(false))
          }
        })
      }
      break

    // Missed activity
    case DESKTOP_NOTIFICATION_ACTION_TYPES.CREATED_NOTIFICATION:
      {
        const { [NOTIFICATION_STORE_NAME]: notifications } = getState()

        const permissionRequested =
          desktopNotificationSelectors.getPermissionRequested(notifications)
        const dontRemindAgain =
          desktopNotificationSelectors.getDontRemindAgain(notifications)

        // If we haven't requested permission yet and the user wants to be reminded
        if (!permissionRequested && isPermissionDefault() && !dontRemindAgain) {
          dispatch(contentActions.showDialogNotificationPermission())
          dispatch(desktopNotificationActions.setPermissionRequested(true))
        }
      }
      break

    // Persist don't remind me preference in local store
    case DESKTOP_NOTIFICATION_ACTION_TYPES.DONT_REMIND_AGAIN:
      localStore.setDesktopNotificationsDontRemindAgain(action.payload)
      break

    default:
      break
  }

  return next(action)
}
