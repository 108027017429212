import { useCallback, useEffect, useRef } from 'react'
import { ClCheckbox } from '@enreach/core-component-library-react'
import { defineCustomElementClCheckbox } from '@enreach/core-component-library/dist/components/index'

import { type CheckboxProps } from './checkbox.types'

export const Checkbox = ({ onToggle, ...other }: CheckboxProps) => {
  const ref = useRef<HTMLClCheckboxElement>(null)
  const handleToggle = useCallback((event) => onToggle?.(event), [onToggle])

  /**
   * NOTE: This onToggle workaround via ref is required due to ClCheckbox's
   * onToggle prop not working properly.
   * See: https://jira.voiceworks.com/browse/CCL-636
   */
  useEffect(() => {
    ref.current?.addEventListener('toggle', handleToggle)
    const el = ref.current
    return () => el.removeEventListener('toggle', handleToggle)
  }, [handleToggle])

  return <ClCheckbox ref={ref} {...other} />
}

export default Checkbox

defineCustomElementClCheckbox()
