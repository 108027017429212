import type { SVGProps } from 'react'
const SvgEmoji7 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle
      cx={79.46}
      cy={96.28}
      r={48.66}
      fill='rgb(var(--color-brand-100))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.46 146.94c-27.93 0-50.66-22.72-50.66-50.66s22.72-50.66 50.66-50.66 50.66 22.72 50.66 50.66-22.72 50.66-50.66 50.66zm0-97.31c-25.73 0-46.66 20.93-46.66 46.66s20.93 46.66 46.66 46.66 46.66-20.93 46.66-46.66-20.93-46.66-46.66-46.66z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M79.4 111.89c-5.15 0-10.07-.98-14.58-2.76-1.34-.53-2.81.43-2.82 1.87v.13c0 8.3 7.79 15.04 17.41 15.04s17.41-6.73 17.41-15.04V111c-.01-1.43-1.49-2.39-2.82-1.87a39.713 39.713 0 0 1-14.58 2.76z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.4 127.16c-10.15 0-18.41-7.19-18.41-16.04v-.13c0-1 .51-1.93 1.34-2.49.84-.57 1.91-.69 2.85-.31 4.53 1.78 9.31 2.69 14.22 2.69s9.69-.9 14.22-2.69c.94-.37 2.01-.25 2.85.31a3.02 3.02 0 0 1 1.34 2.48v.14c0 8.84-8.26 16.04-18.41 16.04zm-15.35-17.18c-.21 0-.42.06-.6.19-.29.19-.45.5-.46.84v.12c0 7.74 7.36 14.04 16.41 14.04s16.41-6.3 16.41-14.04V111c0-.33-.17-.64-.46-.83-.3-.2-.66-.24-1-.11-4.77 1.88-9.8 2.83-14.95 2.83s-10.19-.95-14.95-2.83c-.13-.05-.26-.08-.4-.08z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M79.4 126.16c3.72 0 7.17-1.01 10-2.73-1.81-3.72-5.61-6.29-10-6.29s-8.2 2.57-10 6.29c2.83 1.72 6.28 2.73 10 2.73z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.4 127.16c-3.79 0-7.42-1-10.52-2.88a.996.996 0 0 1-.38-1.29c2.05-4.23 6.23-6.85 10.9-6.85s8.85 2.63 10.9 6.85c.23.46.06 1.02-.38 1.29-3.1 1.88-6.74 2.88-10.52 2.88zm-8.66-4.12c2.6 1.39 5.57 2.12 8.66 2.12s6.06-.73 8.66-2.12c-1.83-3.05-5.07-4.9-8.66-4.9s-6.83 1.86-8.66 4.91z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M55.9 104.47 42.49 91.06a7.17 7.17 0 0 1 10.14-10.14l3.26 3.26 3.26-3.26a7.17 7.17 0 0 1 10.14 10.14l-13.41 13.41z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M55.9 105.72c-.32 0-.64-.12-.88-.37L41.61 91.94c-3.28-3.28-3.28-8.63 0-11.91 3.29-3.28 8.63-3.28 11.91 0l2.38 2.38 2.38-2.38c3.29-3.28 8.63-3.28 11.91 0 3.28 3.28 3.28 8.63 0 11.91l-13.41 13.41c-.24.24-.56.37-.88.37zm-8.33-25.65c-1.52 0-3.03.58-4.19 1.73a5.933 5.933 0 0 0 0 8.38L55.9 102.7l12.52-12.52a5.933 5.933 0 0 0 0-8.38 5.933 5.933 0 0 0-8.38 0l-3.26 3.26c-.47.47-1.3.47-1.77 0l-3.26-3.26a5.93 5.93 0 0 0-4.19-1.73z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M103.4 104.47 89.99 91.06a7.17 7.17 0 0 1 10.14-10.14l3.26 3.26 3.26-3.26a7.17 7.17 0 0 1 10.14 10.14l-13.41 13.41z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M103.4 105.72c-.32 0-.64-.12-.88-.37L89.11 91.94c-3.28-3.28-3.28-8.63 0-11.91s8.63-3.28 11.91 0l2.38 2.38 2.38-2.38c3.29-3.28 8.63-3.28 11.91 0 3.28 3.28 3.28 8.63 0 11.91l-13.41 13.41c-.24.24-.56.37-.88.37zm-8.33-25.65c-1.52 0-3.03.58-4.19 1.73a5.933 5.933 0 0 0 0 8.38l12.52 12.52 12.52-12.52c2.31-2.31 2.31-6.07 0-8.38s-6.07-2.31-8.38 0l-3.26 3.26c-.49.49-1.28.49-1.77 0l-3.26-3.26a5.913 5.913 0 0 0-4.19-1.73z'
    />
  </svg>
)
export default SvgEmoji7
