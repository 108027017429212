import _ from 'lodash'

export const STORE_NAME = 'feature/content'

const INIT = `sm-web/${STORE_NAME}/INIT`
const SHOW_ALERT = `sm-web/${STORE_NAME}/SHOW_ALERT`
const HIDE_ALERT = `sm-web/${STORE_NAME}/HIDE_ALERT`

const SHOW_DIALOG = `sm-web/${STORE_NAME}/SHOW_DIALOG`
const HIDE_DIALOG = `sm-web/${STORE_NAME}/HIDE_DIALOG`
const UPDATE_DIALOG = `sm-web/${STORE_NAME}/UPDATE_DIALOG`
const EMPTY_CONFERENCE_CHECKED = `sm-web/${STORE_NAME}/EMPTY_CONFERENCE_CHECKED`
const DRAG_START = `sm-web/${STORE_NAME}/DRAG_START`
const DRAG_END = `sm-web/${STORE_NAME}/DRAG_END`
const START_RECORDING = `sm-web/${STORE_NAME}/START_RECORDING`
const STOP_RECORDING = `sm-web/${STORE_NAME}/STOP_RECORDING`
const SET_RECORDING_TIMESTAMP = `sm-web/${STORE_NAME}/SET_RECORDING_TIMESTAMP`
const SET_VIEW = `sm-web/${STORE_NAME}/SET_VIEW`
const SET_ACTIVE_SPEAKER_MODE = `sm-web/${STORE_NAME}/SET_ACTIVE_SPEAKER_MODE`
const SET_VIEW_ACTIVE_SPEAKER = `sm-web/${STORE_NAME}/SET_VIEW_ACTIVE_SPEAKER`
const SET_VIEW_GRID = `sm-web/${STORE_NAME}/SET_VIEW_GRID`
const TOGGLE_DIALOG_SHORTCUTS = `sm-web/${STORE_NAME}/TOGGLE_DIALOG_SHORTCUTS`
const TOGGLE_DIALOG_STICKERS = `sm-web/${STORE_NAME}/TOGGLE_DIALOG_STICKERS`
const TOGGLE_FULLSCREEN = `sm-web/${STORE_NAME}/TOGGLE_FULLSCREEN`
const TOGGLE_RECORDING = `sm-web/${STORE_NAME}/TOGGLE_RECORDING`

export const ACTION_TYPES = {
  INIT,
  DRAG_END,
  DRAG_START,
  EMPTY_CONFERENCE_CHECKED,
  HIDE_DIALOG,
  UPDATE_DIALOG,
  SET_ACTIVE_SPEAKER_MODE,
  SET_RECORDING_TIMESTAMP,
  SET_VIEW_ACTIVE_SPEAKER,
  SET_VIEW_GRID,
  SET_VIEW,
  SHOW_DIALOG,
  START_RECORDING,
  STOP_RECORDING,
  TOGGLE_DIALOG_SHORTCUTS,
  TOGGLE_DIALOG_STICKERS,
  TOGGLE_FULLSCREEN,
  TOGGLE_RECORDING,
}

export const DIALOG_TYPES = {
  END_MEETING: 'END_MEETING',
  KICK: 'KICK',
  UPLOAD_FORM: 'UPLOAD_FORM',
  LOGOUT: 'LOGOUT',
  CLEAR_MEETING: 'CLEAR_MEETING',
  INVITE: 'INVITE',
  SHARE_SOURCE: 'SHARE_SOURCE',
  LICENSES: 'LICENSES',
  CHANGE_ROOM: 'CHANGE_ROOM',
  STICKER_PICKER: 'STICKER_PICKER',
  SHORTCUTS: 'SHORTCUTS',
  NO_AUDIO: 'NO_AUDIO',
  NOTIFICATION_PERMISSION: 'NOTIFICATION_PERMISSION',
  GRANT_HOST: 'GRANT_HOST',
}

export const INVITE_DIALOG_VIEWS = {
  DEFAULT: 'DEFAULT',
  CALENDAR_INVITE: 'CALENDAR_INVITE',
  SMS_INVITE: 'SMS_INVITE',
}

export const VIEW_TYPES = {
  STREAMER: 'STREAMER',
  GRID: 'GRID',
  EMPTY: 'EMPTY',
  ACTIVE_SPEAKER: 'ACTIVE_SPEAKER',
  COLLAB_BOARD: 'COLLAB_BOARD',
}

export const ALERT_TYPES = {
  AWFULLY_QUIET: 'AWFULLY_QUIET',
  NO_PERMISSION: 'NO_PERMISSION',
}

const showAlert = (type, props = {}) => ({
  type: SHOW_ALERT,
  payload: {
    type,
    props,
  },
})

const hideAlert = (type = null) => ({
  type: HIDE_ALERT,
  payload: type,
})

const showAwfullyQuietAlert = () => showAlert(ALERT_TYPES.AWFULLY_QUIET)
const hideAwfullyQuietAlert = () => hideAlert(ALERT_TYPES.AWFULLY_QUIET)

const showNoPermissionAlert = () => showAlert(ALERT_TYPES.NO_PERMISSION)
const hideNoPermissionAlert = () => hideAlert(ALERT_TYPES.NO_PERMISSION)

const hideDialog = (type = null) => ({
  type: HIDE_DIALOG,
  payload: type,
})

const updateDialog = (props) => ({
  type: UPDATE_DIALOG,
  payload: { props },
})

const showDialogInvite = (
  view = INVITE_DIALOG_VIEWS.DEFAULT,
  props = undefined
) => ({
  type: SHOW_DIALOG,
  payload: {
    type: DIALOG_TYPES.INVITE,
    props: { view, ...props },
  },
})

const showDialogCalendarInvite = (props = undefined) =>
  showDialogInvite(INVITE_DIALOG_VIEWS.CALENDAR_INVITE, props)

const showDialogSMSInvite = (props = undefined) =>
  showDialogInvite(INVITE_DIALOG_VIEWS.SMS_INVITE, props)

const showDialogKick = (memberId) => ({
  type: SHOW_DIALOG,
  payload: {
    type: DIALOG_TYPES.KICK,
    props: { memberId },
  },
})

const showDialogGrantHost = (memberId) => ({
  type: SHOW_DIALOG,
  payload: {
    type: DIALOG_TYPES.GRANT_HOST,
    props: { memberId },
  },
})

const showDialogEndMeeting = () => ({
  type: SHOW_DIALOG,
  payload: {
    type: DIALOG_TYPES.END_MEETING,
  },
})

const showDialogFileUpload = () => ({
  type: SHOW_DIALOG,
  payload: {
    type: DIALOG_TYPES.UPLOAD_FORM,
  },
})

const showDialogLogout = () => ({
  type: SHOW_DIALOG,
  payload: {
    type: DIALOG_TYPES.LOGOUT,
  },
})

const showDialogClearMeeting = () => ({
  type: SHOW_DIALOG,
  payload: {
    type: DIALOG_TYPES.CLEAR_MEETING,
  },
})

const showDialogShareSourceSelect = () => ({
  type: SHOW_DIALOG,
  payload: {
    type: DIALOG_TYPES.SHARE_SOURCE,
  },
})

const showDialogLicenses = (selected) => ({
  type: SHOW_DIALOG,
  payload: {
    type: DIALOG_TYPES.LICENSES,
    props: { selected },
  },
})
const showDialogChangeRoom = () => ({
  type: SHOW_DIALOG,
  payload: {
    type: DIALOG_TYPES.CHANGE_ROOM,
  },
})

const showDialogStickerPicker = () => ({
  type: SHOW_DIALOG,
  payload: {
    type: DIALOG_TYPES.STICKER_PICKER,
  },
})

const showDialogShortcut = () => ({
  type: SHOW_DIALOG,
  payload: {
    type: DIALOG_TYPES.SHORTCUTS,
  },
})

const showDialogNoAudio = () => ({
  type: SHOW_DIALOG,
  payload: {
    type: DIALOG_TYPES.NO_AUDIO,
  },
})

const showDialogNotificationPermission = () => ({
  type: SHOW_DIALOG,
  payload: {
    type: DIALOG_TYPES.NOTIFICATION_PERMISSION,
  },
})

const emptyConferenceChecked = () => ({
  type: EMPTY_CONFERENCE_CHECKED,
})

const dragStart = (payload) => ({ type: DRAG_START, payload })
const dragEnd = () => ({ type: DRAG_END })

const startRecording = () => ({ type: START_RECORDING })
const stopRecording = () => ({ type: STOP_RECORDING })
const setRecordingTimestamp = (payload) => ({
  type: SET_RECORDING_TIMESTAMP,
  payload,
})

const setView = (view) => ({
  type: SET_VIEW,
  payload: view,
})

const setActiveSpeakerMode = (enable) => (dispatch) => {
  if (enable) {
    dispatch(setView(VIEW_TYPES.ACTIVE_SPEAKER))
  } else {
    dispatch(setView(VIEW_TYPES.GRID))
  }

  dispatch({
    type: SET_ACTIVE_SPEAKER_MODE,
    payload: enable,
  })
}

const setViewActiveSpeaker = () => ({ type: SET_VIEW_ACTIVE_SPEAKER })

const setViewGrid = () => ({ type: SET_VIEW_GRID })

const toggleDialogShortcuts = () => (dispatch, getState) => {
  const state = getState()[STORE_NAME]

  if (_.some(state.dialogs, { type: DIALOG_TYPES.SHORTCUTS })) {
    dispatch(hideDialog(DIALOG_TYPES.SHORTCUTS))
  } else {
    dispatch(showDialogShortcut())
  }
}

const toggleDialogStickers = () => (dispatch, getState) => {
  const state = getState()[STORE_NAME]

  if (_.some(state.dialogs, { type: DIALOG_TYPES.STICKER_PICKER })) {
    dispatch(hideDialog(DIALOG_TYPES.STICKER_PICKER))
  } else {
    dispatch(showDialogStickerPicker())
  }
}

const toggleFullscreen = () => ({ type: TOGGLE_FULLSCREEN })

const toggleRecording = () => ({ type: TOGGLE_RECORDING })

export const actions = {
  hideAlert,
  showAwfullyQuietAlert,
  hideAwfullyQuietAlert,
  showNoPermissionAlert,
  hideNoPermissionAlert,
  dragEnd,
  dragStart,
  updateDialog,
  emptyConferenceChecked,
  hideDialog,
  setActiveSpeakerMode,
  setRecordingTimestamp,
  setView,
  setViewActiveSpeaker,
  setViewGrid,
  showDialogCalendarInvite,
  showDialogChangeRoom,
  showDialogClearMeeting,
  showDialogEndMeeting,
  showDialogFileUpload,
  showDialogInvite,
  showDialogKick,
  showDialogGrantHost,
  showDialogLicenses,
  showDialogLogout,
  showDialogShareSourceSelect,
  showDialogShortcut,
  showDialogSMSInvite,
  showDialogStickerPicker,
  showDialogNoAudio,
  showDialogNotificationPermission,
  startRecording,
  stopRecording,
  toggleDialogShortcuts,
  toggleDialogStickers,
  toggleFullscreen,
  toggleRecording,
}

export const commands = {
  [ACTION_TYPES.SET_VIEW_ACTIVE_SPEAKER]: actions.setViewActiveSpeaker,
  [ACTION_TYPES.SET_VIEW_GRID]: actions.setViewGrid,
  [ACTION_TYPES.TOGGLE_DIALOG_SHORTCUTS]: actions.toggleDialogShortcuts,
  [ACTION_TYPES.TOGGLE_DIALOG_STICKERS]: actions.toggleDialogStickers,
  [ACTION_TYPES.TOGGLE_FULLSCREEN]: actions.toggleFullscreen,
  [ACTION_TYPES.TOGGLE_RECORDING]: actions.toggleRecording,
}

const INITIAL_STATE = {
  additionalProps: {},
  draggedItem: null,
  dialogs: [],
  alerts: [],
  isEmptyConferenceChecked: false,
  recording: false,
  recordingTimestamp: 0,
  view: null,
  activeSpeakerMode: false,
}

const ACTION_HANDLERS = {
  [HIDE_ALERT]: (state, action) => ({
    ...state,
    alerts: action.payload
      ? _.reject(state.alerts, { type: action.payload })
      : _.slice(state.alerts, 1),
  }),
  [SHOW_ALERT]: (state, action) => ({
    ...state,
    alerts: _.some(state.alerts, { type: action.payload.type })
      ? state.alerts
      : [
          ...state.alerts,
          {
            type: action.payload.type,
            props: action.payload.props || {},
          },
        ],
  }),
  [HIDE_DIALOG]: (state, action) => ({
    ...state,
    dialogs: action.payload
      ? _.reject(state.dialogs, { type: action.payload })
      : _.slice(state.dialogs, 1),
  }),
  [SHOW_DIALOG]: (state, action) => ({
    ...state,
    dialogs: [
      ...state.dialogs,
      {
        type: action.payload.type,
        props: action.payload.props || {},
      },
    ],
  }),
  [UPDATE_DIALOG]: (state, action) => ({
    ...state,
    dialogs: [
      ...state.dialogs.map((dialog, index) => {
        if (index > 0) {
          return dialog
        }
        return { ...dialog, props: action.payload.props }
      }),
    ],
  }),
  [EMPTY_CONFERENCE_CHECKED]: (state) => ({
    ...state,
    isEmptyConferenceChecked: true,
  }),
  [DRAG_START]: (state, action) => ({
    ...state,
    draggedItem: action.payload,
  }),
  [DRAG_END]: (state) => ({ ...state, draggedItem: null }),
  [START_RECORDING]: (state) => ({ ...state, recording: true }),
  [STOP_RECORDING]: (state) => ({
    ...state,
    recording: false,
    recordingTimestamp: 0,
  }),
  [SET_RECORDING_TIMESTAMP]: (state, action) => ({
    ...state,
    recordingTimestamp: action.payload,
  }),
  [SET_VIEW]: (state, action) => ({
    ...state,
    view: action.payload,
  }),
  [SET_ACTIVE_SPEAKER_MODE]: (state, action) => ({
    ...state,
    activeSpeakerMode: action.payload,
  }),
  'sm-web/RESET': () => INITIAL_STATE,
}

export default (state = INITIAL_STATE, action = {}) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export const selectors = {
  getView(state) {
    return state.view
  },
  getAlerts(state) {
    return state.alerts
  },
  getDialogs(state) {
    return state.dialogs
  },
  isRecording(state) {
    return !!state.recording
  },
  getCurrentAlert(state) {
    const alerts = selectors.getAlerts(state)
    return _.first(alerts)
  },
  getCurrentDialog(state) {
    const dialogs = selectors.getDialogs(state)
    return _.first(dialogs)
  },
  isShowingAlert(state, type) {
    const alerts = selectors.getAlerts(state)

    return _.some(alerts, { type })
  },
}
