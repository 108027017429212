import _ from 'lodash'

import env from 'app/config/env'
import localStore from 'app/services/state/local-store'
import { FACING_MODES } from 'constants/constants'
import { getNewDevice, groupDevicesByKind } from 'lib/rtc/devices'
import VIDEO_QUALITY_TYPES from 'lib/rtc/video-quality-types.enum'
import URLUtils from 'lib/url-utils'

export const STORE_NAME = 'feature/settings'

const SET_FACING_MODE = `sm-web/${STORE_NAME}/SET_FACING_MODE`
const SET_DEBUG_MODE = `sm-web/${STORE_NAME}/SET_DEBUG_MODE`
const TOGGLE_HOTKEYS_ENABLED = `sm-web/${STORE_NAME}/TOGGLE_HOTKEYS_ENABLED`
const SET_PLAY_NOTIFICATIONS = `sm-web/${STORE_NAME}/SET_PLAY_NOTIFICATIONS`
const TOGGLE_PLAY_NOTIFICATIONS = `sm-web/${STORE_NAME}/TOGGLE_PLAY_NOTIFICATIONS`
const SET_VIDEO_QUALITY = `sm-web/${STORE_NAME}/SET_VIDEO_QUALITY`
const TOGGLE_SCREEN_RECORDER = `sm-web/${STORE_NAME}/TOGGLE_SCREEN_RECORDER`
const SET_CURRENT_CAM_DEVICE = `sm-web/${STORE_NAME}/SET_CURRENT_CAM_DEVICE`
const SET_CURRENT_MIC_DEVICE = `sm-web/${STORE_NAME}/SET_CURRENT_MIC_DEVICE`
const SET_CURRENT_SCREEN_DEVICE = `sm-web/${STORE_NAME}/SET_CURRENT_SCREEN_DEVICE`
const UPDATE_SELECTED_DEVICES = `sm-web/${STORE_NAME}/UPDATE_SELECTED_DEVICES`
const INITIALISE_DEVICE_CONSTRAINTS = `sm-web/${STORE_NAME}/INITIALISE_DEVICE_CONSTRAINTS`
const TOGGLE_FACING_MODE = `sm-web/${STORE_NAME}/TOGGLE_FACING_MODE`
const SET_DESKTOP_NOTIFICATIONS = `sm-web/${STORE_NAME}/SET_DESKTOP_NOTIFICATIONS`

export const ACTION_TYPES = {
  SET_FACING_MODE,
  SET_DEBUG_MODE,
  TOGGLE_HOTKEYS_ENABLED,
  SET_PLAY_NOTIFICATIONS,
  SET_VIDEO_QUALITY,
  TOGGLE_PLAY_NOTIFICATIONS,
  TOGGLE_SCREEN_RECORDER,

  SET_CURRENT_CAM_DEVICE,
  SET_CURRENT_MIC_DEVICE,
  SET_CURRENT_SCREEN_DEVICE,
  UPDATE_SELECTED_DEVICES,
  INITIALISE_DEVICE_CONSTRAINTS,
  TOGGLE_FACING_MODE,
  SET_DESKTOP_NOTIFICATIONS,
}

const setFacingMode = (payload) => ({ type: SET_FACING_MODE, payload })

const toggleFacingMode = (payload) => ({ type: TOGGLE_FACING_MODE, payload })

const setDebugMode = (payload) => ({ type: SET_DEBUG_MODE, payload })

const toggleDebugMode = () => (dispatch, getState) => {
  dispatch(setDebugMode(!_.get(getState()[STORE_NAME], 'debugMode', false)))
}

const toggleHotkeysEnabled = () => ({ type: TOGGLE_HOTKEYS_ENABLED })

const setPlayNotifications = (payload) => ({
  type: SET_PLAY_NOTIFICATIONS,
  payload,
})

const togglePlayNotifications = () => ({ type: TOGGLE_PLAY_NOTIFICATIONS })

const toggleScreenRecorder = () => ({ type: TOGGLE_SCREEN_RECORDER })

const setReceivedVideoQuality = (payload) => ({
  type: SET_VIDEO_QUALITY,
  payload,
})

const setDesktopNotifications = (enabled) => ({
  type: SET_DESKTOP_NOTIFICATIONS,
  payload: enabled,
})

const _setCurrentCamDevice = (deviceId) => ({
  type: SET_CURRENT_CAM_DEVICE,
  payload: { deviceId },
})

const _setCurrentMicDevice = (deviceId) => ({
  type: SET_CURRENT_MIC_DEVICE,
  payload: { deviceId },
})

const _setCurrentScreenDevice = (deviceId) => ({
  type: SET_CURRENT_SCREEN_DEVICE,
  payload: { deviceId },
})

/**
 *
 * @param payload
 * @param payload.camDevice
 * @param payload.camDevice.deviceId
 * @param payload.camDevice.label
 * @param payload.micDevice
 * @param payload.micDevice.deviceId
 * @param payload.micDevice.label
 * @param payload.outDevice
 * @param payload.outDevice.deviceId
 * @param payload.outDevice.label
 * @return
 */
const updateSelectedDevices = (payload) => ({
  type: UPDATE_SELECTED_DEVICES,
  payload,
})

const initialiseDeviceConstraints = (availableDevices) => {
  const urlParams = URLUtils.getUrlParams()
  const { camDevices, micDevices, outDevices } =
    groupDevicesByKind(availableDevices)

  const camDevice = {
    deviceId: '',
    label: urlParams.videodev || localStore.getCamDeviceLabel() || '',
  }
  if (camDevice.label) {
    camDevice.deviceId = _.chain(camDevices)
      .find({ label: camDevice.label })
      .get('deviceId')
      .value()
  }

  const micDevice = {
    deviceId: '',
    label: urlParams.indev || localStore.getMicDeviceLabel() || '',
  }
  if (micDevice.label) {
    micDevice.deviceId = _.chain(micDevices)
      .find({ label: micDevice.label })
      .get('deviceId')
      .value()
  }

  const outDevice = {
    deviceId: '',
    label: urlParams.outdev || localStore.getOutDeviceLabel() || '',
  }
  if (outDevice.label) {
    outDevice.deviceId = _.chain(outDevices)
      .find({ label: outDevice.label })
      .get('deviceId')
      .value()
  } else {
    const defaultOutDevice = getNewDevice(outDevices)

    if (defaultOutDevice) {
      outDevice.deviceId = defaultOutDevice.deviceId
      outDevice.label = defaultOutDevice.label
    }
  }

  return {
    type: INITIALISE_DEVICE_CONSTRAINTS,
    payload: { camDevice, micDevice, outDevice },
  }
}

export const actions = {
  setFacingMode,
  setDebugMode,
  toggleDebugMode,
  toggleHotkeysEnabled,
  setPlayNotifications,
  togglePlayNotifications,
  toggleScreenRecorder,
  setReceivedVideoQuality,
  updateSelectedDevices,
  initialiseDeviceConstraints,

  _setCurrentCamDevice,
  _setCurrentMicDevice,
  _setCurrentScreenDevice,
  setDesktopNotifications,
}

export const commands = {
  [TOGGLE_FACING_MODE]: toggleFacingMode,
}

const { api, facingmode } = URLUtils.getUrlParams()

export const INITIAL_STATE = {
  api: api || env('api'),
  apiFallback: env('api_fallback'), // falls back to undefined if not passed

  facingMode: facingmode || localStore.getFacingMode() || FACING_MODES.USER,

  deviceConstraintsInitialised: false,

  camDevice: {
    currentDeviceId: '',
    selectedDeviceId: '',
    selectedDeviceLabel: '',
  },
  micDevice: {
    currentDeviceId: '',
    selectedDeviceId: '',
    selectedDeviceLabel: '',
  },
  outDevice: {
    selectedDeviceId: '',
    selectedDeviceLabel: '',
  },
  screenDevice: {
    currentDeviceId: '',
  },

  debugMode: localStore.getDebugMode(),
  hotkeysEnabled: localStore.getHotkeysEnabled(),
  playNotifications: false,
  screenRecorderEnabled: false,
  desktopNotificationsEnabled: localStore.getDesktopNotificationsEnabled(),

  videoQuality: VIDEO_QUALITY_TYPES.HIGH,
}

const ACTION_HANDLERS = {
  [SET_FACING_MODE]: (state, action) => ({
    ...state,
    facingMode: action.payload,
  }),
  [TOGGLE_FACING_MODE]: (state) => ({
    ...state,
    facingMode:
      state.facingMode === FACING_MODES.USER
        ? FACING_MODES.ENVIRONMENT
        : FACING_MODES.USER,
  }),
  [SET_DEBUG_MODE]: (state, action) => ({
    ...state,
    debugMode: action.payload,
  }),
  [TOGGLE_HOTKEYS_ENABLED]: (state) => ({
    ...state,
    hotkeysEnabled: !state.hotkeysEnabled,
  }),
  [SET_PLAY_NOTIFICATIONS]: (state, action) => ({
    ...state,
    playNotifications: action.payload,
  }),
  [TOGGLE_PLAY_NOTIFICATIONS]: (state) => ({
    ...state,
    playNotifications: !state.playNotifications,
  }),
  [TOGGLE_SCREEN_RECORDER]: (state) => ({
    ...state,
    screenRecorderEnabled: !state.screenRecorderEnabled,
  }),
  [SET_DESKTOP_NOTIFICATIONS]: (state, action) => ({
    ...state,
    desktopNotificationsEnabled: action.payload,
  }),
  [SET_VIDEO_QUALITY]: (state, action) => ({
    ...state,
    videoQuality: action.payload,
  }),

  [SET_CURRENT_CAM_DEVICE]: (state, action) => ({
    ...state,
    camDevice: {
      ...state.camDevice,
      currentDeviceId: action.payload.deviceId,
    },
  }),
  [SET_CURRENT_MIC_DEVICE]: (state, action) => ({
    ...state,
    micDevice: {
      ...state.micDevice,
      currentDeviceId: action.payload.deviceId,
    },
  }),
  [SET_CURRENT_SCREEN_DEVICE]: (state, action) => ({
    ...state,
    screenDevice: {
      ...state.screenDevice,
      currentDeviceId: action.payload.deviceId,
    },
  }),
  [UPDATE_SELECTED_DEVICES]: (state, action) => {
    const nextState = { ...state }

    if (action.payload.camDevice) {
      nextState.camDevice = { ...nextState.camDevice }
      if (typeof action.payload.camDevice.deviceId !== 'undefined') {
        nextState.camDevice.selectedDeviceId = action.payload.camDevice.deviceId
      }

      if (typeof action.payload.camDevice.label !== 'undefined') {
        nextState.camDevice.selectedDeviceLabel = action.payload.camDevice.label
      }
    }

    if (action.payload.micDevice) {
      nextState.micDevice = { ...nextState.micDevice }
      if (typeof action.payload.micDevice.deviceId !== 'undefined') {
        nextState.micDevice.selectedDeviceId = action.payload.micDevice.deviceId
      }

      if (typeof action.payload.micDevice.label !== 'undefined') {
        nextState.micDevice.selectedDeviceLabel = action.payload.micDevice.label
      }
    }

    if (action.payload.outDevice) {
      nextState.outDevice = { ...nextState.outDevice }
      if (typeof action.payload.outDevice.deviceId !== 'undefined') {
        nextState.outDevice.selectedDeviceId = action.payload.outDevice.deviceId
      }

      if (typeof action.payload.outDevice.label !== 'undefined') {
        nextState.outDevice.selectedDeviceLabel = action.payload.outDevice.label
      }
    }

    return nextState
  },
  [INITIALISE_DEVICE_CONSTRAINTS]: (state, action) => ({
    ...state,
    deviceConstraintsInitialised: true,
    camDevice: {
      currentDeviceId: '',
      selectedDeviceId: action.payload.camDevice.deviceId,
      selectedDeviceLabel: action.payload.camDevice.label,
    },
    micDevice: {
      currentDeviceId: '',
      selectedDeviceId: action.payload.micDevice.deviceId,
      selectedDeviceLabel: action.payload.micDevice.label,
    },
    outDevice: {
      selectedDeviceId: action.payload.outDevice.deviceId,
      selectedDeviceLabel: action.payload.outDevice.label,
    },
    screenDevice: {
      currentDeviceId: '',
    },
  }),
}

export default (state = INITIAL_STATE, action = {}) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export const selectors = {
  getDeviceConstraintsInitialised(state) {
    return _.get(state, 'deviceConstraintsInitialised', false)
  },
  getApiUrl(state): string {
    return _.get(state, 'api')
  },
  getApiUrlFallback(state): string {
    return _.get(state, 'apiFallback')
  },
  getVideoQuality(state): VIDEO_QUALITY_TYPES {
    return _.get(state, 'videoQuality')
  },
  getFacingMode(state): FACING_MODES {
    return _.get(state, 'facingMode')
  },
  getSelectedCamDevice(state) {
    return _.get(state, 'camDevice')
  },
  getCurrentCamDeviceId(state): string {
    return _.get(state, 'camDevice.currentDeviceId')
  },
  getSelectedCamDeviceLabel(state): string {
    return _.get(state, 'camDevice.selectedDeviceLabel')
  },
  getSelectedCamDeviceId(state): string {
    return _.get(state, 'camDevice.selectedDeviceId') as string
  },
  getSelectedMicDevice(state) {
    return _.get(state, 'micDevice')
  },
  getCurrentMicDeviceId(state): string {
    return _.get(state, 'micDevice.currentDeviceId')
  },
  getSelectedMicDeviceLabel(state): string {
    return _.get(state, 'micDevice.selectedDeviceLabel')
  },
  getSelectedMicDeviceId(state): string {
    return _.get(state, 'micDevice.selectedDeviceId')
  },
  getSelectedOutDevice(state) {
    return _.get(state, 'outDevice')
  },
  getCurrentOutDeviceId(state): string {
    return _.get(state, 'outDevice.currentDeviceId')
  },
  getSelectedOutDeviceLabel(state): string {
    return _.get(state, 'outDevice.selectedDeviceLabel')
  },
  getSelectedOutDeviceId(state): string {
    return _.get(state, 'outDevice.selectedDeviceId')
  },
  getCurrentScreenDeviceId(state): string {
    return _.get(state, 'screenDevice.currentDeviceId')
  },
  getHotkeysEnabled(state): boolean {
    return _.get(state, 'hotkeysEnabled')
  },
  getDesktopNotificationsEnabled(state): boolean {
    return _.get(state, 'desktopNotificationsEnabled')
  },
}
