import type { SVGProps } from 'react'
const SvgReaction7ThumbDown = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle cx={82.17} cy={78.48} r={62} fill='rgb(var(--color-brand-50))' />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M70.35 46.96h37.4c3.37 0 6.1 2.73 6.1 6.1v6.3h1.39c2.98 0 5.39 2.41 5.39 5.39 0 3.5-2.84 6.34-6.34 6.34h1.41c3.5 0 6.34 2.84 6.34 6.34s-2.84 6.34-6.34 6.34h.53c3.4 0 6.16 2.76 6.16 6.16 0 3.4-2.76 6.16-6.16 6.16H91.22l1.43 7.54c1 5.28-.06 10.75-2.96 15.27l-2.66 4.14a5.141 5.141 0 0 1-5.94 2.1l-2.6-.86c-.8-.26-1.3-1.05-1.21-1.88l1-8.92c.39-3.47-.27-6.97-1.89-10.07l-7.08-13.19h-15.2V46.8l16.23.16z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='m44.39 81.14-.12-5.54-.53-23.96-.08-3.59c-.03-1.21-1-2.18-2.2-2.21l-18.86-.48v47.28l8.7-.52 6.33-.55 4.82-.3a2.261 2.261 0 0 0 2.12-2.31l-.17-7.82z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M82.7 127.41c-.75 0-1.5-.12-2.23-.36l-2.6-.86a3.778 3.778 0 0 1-2.57-4l1-8.92c.35-3.08-.23-6.17-1.67-8.91l-6.51-12.12H63.9c-1.1 0-2-.9-2-2s.9-2 2-2h5.42c.74 0 1.41.41 1.76 1.05l7.08 13.19c1.82 3.48 2.55 7.36 2.11 11.24l-.98 8.74 2.42.8c1.36.45 2.85-.08 3.63-1.29l2.66-4.14c2.64-4.11 3.59-9.01 2.68-13.81l-1.43-7.54a1.985 1.985 0 0 1 1.96-2.37h25.01c2.3 0 4.16-1.87 4.16-4.16s-1.87-4.17-4.16-4.17h-6.81c-1.1 0-2-.9-2-2s.9-2 2-2h6.29c2.39 0 4.34-1.95 4.34-4.34s-1.95-4.34-4.34-4.34h-3.99c-1.1 0-2-.9-2-2s.9-2 2-2h2.58c2.39 0 4.34-1.95 4.34-4.34 0-1.87-1.52-3.39-3.39-3.39h-1.39c-1.1 0-2-.9-2-2v-6.3c0-2.26-1.84-4.1-4.1-4.1h-37.4c-1.1 0-2-.9-2-2s.9-2 2-2h37.4c4.47 0 8.1 3.63 8.1 8.1v4.32c3.79.31 6.78 3.5 6.78 7.37 0 2.23-.88 4.25-2.3 5.75a8.334 8.334 0 0 1 3.71 6.93c0 2.43-1.04 4.62-2.7 6.14a8.15 8.15 0 0 1 3.05 6.36c0 4.5-3.66 8.16-8.16 8.16H93.64l.98 5.17c1.1 5.81-.05 11.75-3.25 16.72l-2.66 4.14a7.135 7.135 0 0 1-6.01 3.28z'
    />
    <path
      fill='rgb(var(--color-brand-100))'
      d='M35.77 61.06c-.54 0-.99-.43-1-.98a.997.997 0 0 1 .98-1.02l3-.07h.02c.54 0 .99.43 1 .98a.997.997 0 0 1-.98 1.02l-3 .07h-.02zm-7.6.18c-.54 0-.99-.43-1-.98a.997.997 0 0 1 .98-1.02l3-.07h.02c.54 0 .99.43 1 .98a.997.997 0 0 1-.98 1.02l-3 .07h-.02z'
    />
    <path
      fill='rgb(var(--color-brand-500))'
      d='m44.26 75.23-.13-3.51H22.59V92.5L35 91.73l8.98-1.2c.38-.42.6-.97.59-1.57l-.3-13.73z'
    />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M60.58 90.55a3.07 3.07 0 0 0 3.22-3.13l-.45-20.26c-.1-4.38-3.73-7.86-8.11-7.76l-10.74.24-.42 30.88'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M60.73 92.05h-11.7c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h11.55c.5.02.93-.13 1.24-.44s.48-.72.47-1.15l-.45-20.26c-.08-3.5-2.95-6.3-6.44-6.3h-.14l-9.14-.1c-.83 0-1.49-.69-1.48-1.52 0-.82.68-1.48 1.5-1.48h.02l9.09.1c5.19-.1 9.48 4.03 9.6 9.23l.45 20.26c.03 1.26-.48 2.49-1.38 3.37-.85.83-2 1.29-3.18 1.29z'
    />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='m60.53 45.32 2.03.65.29 6.47-.85 3.05-5.11 3.65-13.1.09-.65-13.91h17.39z'
    />
    <circle cx={54.19} cy={81.18} r={3.32} fill='rgb(var(--color-brand-200))' />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M45.27 60.92a1.501 1.501 0 0 1-.05-3l9.95-.32a6.39 6.39 0 0 0 4.53-1.99 6.366 6.366 0 0 0 1.78-4.59l-.08-3.72c0-.44-.2-.84-.52-1.13-.33-.29-.75-.43-1.19-.39H48.03c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h11.46c1.23-.1 2.46.32 3.39 1.14.94.84 1.5 2.04 1.53 3.31l.08 3.72c.06 2.52-.87 4.91-2.62 6.73a9.352 9.352 0 0 1-6.61 2.91l-9.94.32h-.05z'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M24.7 94.51c-1.04 0-2.06-.4-2.82-1.12a4.115 4.115 0 0 1-1.29-2.99V47.52c0-1.12.44-2.16 1.24-2.94.8-.78 1.82-1.19 2.97-1.16l16.7.43c2.28.06 4.1 1.89 4.15 4.17l.73 33.08.17 7.82c.05 2.3-1.7 4.21-4 4.35l-4.82.3-6.28.55-6.52.39h-.24zm0-47.1-.11.11V90.4l.23 2.1-.12-2 6.47-.39 6.27-.55 4.87-.3c.14 0 .25-.13.25-.27l-.91-40.91c0-.14-.12-.25-.26-.26l-16.7-.43z'
    />
  </svg>
)
export default SvgReaction7ThumbDown
