import { TABS } from 'constants/constants'

import { ACTION_TYPES as CHANNEL_ROOM_ACTION_TYPES } from 'app/state/api/channels/channel-room.reducer'

import {
  ACTION_TYPES as RIGHT_BAR_ACTION_TYPES,
  STORE_NAME as RIGHT_BAR_STORE_NAME,
  selectors as sidePanelSelectors,
} from 'app/features/side-panel/side-panel.reducer'

import { STORE_NAME, actions } from './meeting-files.reducer'

export default ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    switch (action.type) {
      case CHANNEL_ROOM_ACTION_TYPES.FILE_SET:
        dispatch(actions.put(action.payload))
        break

      case CHANNEL_ROOM_ACTION_TYPES.FILE_UPDATE:
        {
          const {
            [STORE_NAME]: meetingFiles,
            [RIGHT_BAR_STORE_NAME]: sidePanel,
          } = getState()

          dispatch(actions.diff(action.payload))

          if (meetingFiles.files.length === 0) {
            dispatch(actions.deselectAll())
          }

          // If files are being updated and we're at the files tab, we update the last read
          if (sidePanelSelectors.getTabMenu(sidePanel) === TABS.FILE) {
            dispatch(actions.updateReadFile())
          }
        }
        break

      case RIGHT_BAR_ACTION_TYPES.CHANGE_TAB_MENU:
        {
          const { [RIGHT_BAR_STORE_NAME]: sidePanel } = getState()

          /**
           * If the local user is not going to the files tab but leaving it,
           * we wan't to update the last read file.
           * If the local user is going to the files tab and he's coming from another tab,
           * we wan't to update the last read file with a certain delay.
           */
          if (
            action.payload !== TABS.FILE &&
            sidePanelSelectors.getTabMenu(sidePanel) === TABS.FILE
          ) {
            dispatch(actions.updateReadFile())
          }
        }
        break

      default:
    }

    return next(action)
  }
