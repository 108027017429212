import _ from 'lodash'

import env from 'app/config/env'
import { USER_STATUS, RIGHT_REQUESTING_STATE } from 'constants/constants'

import {
  getMe,
  getUser,
  isHost,
  isRoomFull,
  getMemberRoomState,
  isDialIn,
  getMemberUserName,
  rejectOfflineMembers,
  getWaitingMembers,
} from 'app/state/utils'

import {
  STORE_NAME as CHANNEL_AUTH_STORE_NAME,
  selectors as channelAuthSelectors,
} from 'app/state/api/channels/channel-auth.reducer'
import {
  ACTION_TYPES as CHANNEL_ROOM_ACTION_TYPES,
  STORE_NAME as CHANNEL_ROOM_STORE_NAME,
} from 'app/state/api/channels/channel-room.reducer'
import { ACTION_TYPES as CHANNEL_CONFERENCE_ACTION_TYPES } from 'app/state/api/channels/channel-conference.reducer'
import { STORE_NAME as FLAGS_STORE_NAME } from 'app/features/flags/flags.reducer'
import {
  ACTION_TYPES as GRID_VIEW_ACTION_TYPES,
  STORE_NAME as GRID_VIEW_STORE_NAME,
  selectors as gridViewSelectors,
} from 'app/features/grid-view/grid-view.reducer'
import {
  ACTION_TYPES as MEDIA_ACTION_TYPES,
  STORE_NAME as MEDIA_STORE_NAME,
  selectors as mediaSelectors,
} from 'app/features/media/media.reducer'
import { ACTION_TYPES as ROOM_ACTION_TYPES } from 'app/features/room/room.reducer'
import { ACTION_TYPES as CONFERENCE_ACTION_TYPES } from 'app/features/conference/conference.reducer'
import { ACTION_TYPES as BACKGROUND_EFFECT_ACTION_TYPES } from 'app/features/background-effect/background-effect.reducer'
import {
  STORE_NAME as WAITING_ROOM_STORE_NAME,
  selectors as waitingRoomSelectors,
} from 'app/features/waiting-room/waiting-room.reducer'

import { actions as notificationActions } from './notification.creators'

function isMicrophoneMutedLocallyOrExternally(state) {
  const { [MEDIA_STORE_NAME]: media } = state

  return (
    mediaSelectors.getMicrophoneMuted(media) ||
    mediaSelectors.getMicrophoneExternallyMuted(media)
  )
}

function shouldShowTalkingWhileMutedNotification(state) {
  const { [MEDIA_STORE_NAME]: media } = state

  const isEitherLocallyOrExternallyMuted =
    isMicrophoneMutedLocallyOrExternally(state)

  const isTalking = mediaSelectors.getIsTalking(media)

  return isEitherLocallyOrExternallyMuted && isTalking
}

function getMutedBy(state) {
  const { [MEDIA_STORE_NAME]: media } = state

  const isLocallyMuted = mediaSelectors.getMicrophoneMuted(media)
  const isExternallyMuted = mediaSelectors.getMicrophoneExternallyMuted(media)

  if (isLocallyMuted && isExternallyMuted) {
    return 'both'
  }

  if (isExternallyMuted) {
    return 'host'
  }

  if (isLocallyMuted) {
    return 'self'
  }

  return ''
}

/**
 * Returns 'true' when the grid size of the grid view is full but can be increased.
 * @param state State of the store
 * @returns
 */
function shouldShowIncreaseGridSizeNotification(state) {
  const { [GRID_VIEW_STORE_NAME]: gridView } = state

  if (!gridView) return false

  const gridSizeMax = gridViewSelectors.getGridSizeMax(gridView)
  const isGridFull = gridViewSelectors.isGridFull(gridView)
  const isGridSizeMaxed = gridViewSelectors.isGridSizeMaxed(gridView)

  return gridSizeMax > 1 && isGridFull && !isGridSizeMaxed
}

function handleCameraExternallyMuted(store, action) {
  if (action.payload) {
    store.dispatch(notificationActions.createVideoMutedByHostNotification())
  } else {
    store.dispatch(notificationActions.createVideoUnMutedByHostNotification())
  }
}

function handleMicrophoneExternallyMuted(store, action) {
  if (action.payload) {
    store.dispatch(notificationActions.createAudioMutedByHostNotification())
  } else {
    store.dispatch(notificationActions.createAudioUnmutedByHostNotification())
  }

  if (!isMicrophoneMutedLocallyOrExternally(store.getState())) {
    store.dispatch(notificationActions.closeTalkingWhileMutedNotification())
  }
}

function handleMicrophoneLocallyMuted(store) {
  if (!isMicrophoneMutedLocallyOrExternally(store.getState())) {
    store.dispatch(notificationActions.closeTalkingWhileMutedNotification())
  }
}

function handleStartTalking(store) {
  if (shouldShowTalkingWhileMutedNotification(store.getState())) {
    const mutedBy = getMutedBy(store.getState())
    store.dispatch(
      notificationActions.createTalkingWhileMutedNotification(mutedBy)
    )
  }
}

const reducer = (store) => (next) => (action) => {
  const nextState = next(action)
  const { dispatch, getState } = store

  switch (action.type) {
    case GRID_VIEW_ACTION_TYPES.ADD:
      if (shouldShowIncreaseGridSizeNotification(getState())) {
        dispatch(notificationActions.createIncreaseGridSizeNotification())
      }
      break

    case MEDIA_ACTION_TYPES.SET_CAMERA_EXTERNALLY_MUTED:
      handleCameraExternallyMuted(store, action)
      break

    case MEDIA_ACTION_TYPES.SET_MICROPHONE_EXTERNALLY_MUTED:
      handleMicrophoneExternallyMuted(store, action)
      break

    case MEDIA_ACTION_TYPES.MICROPHONE_TOGGLE_MUTE:
    case MEDIA_ACTION_TYPES.SET_MICROPHONE_MUTE:
      handleMicrophoneLocallyMuted(store)
      break

    case MEDIA_ACTION_TYPES.START_TALKING:
      handleStartTalking(store)
      break

    case CHANNEL_ROOM_ACTION_TYPES.UPDATED_MEMBER_META:
      {
        const {
          [CHANNEL_ROOM_STORE_NAME]: channelRoom,
          [FLAGS_STORE_NAME]: flags,
        } = getState()

        const me = getMe(channelRoom)
        const member = getUser(channelRoom, action.memberId)

        // True when the local user is in the conference room.
        const isMeInRoom = getMemberRoomState(me) === USER_STATUS.IN_ROOM
        // True when the local user is in the waiting room.
        const isMeInWaitingRoom = getMemberRoomState(me) === USER_STATUS.WAITING
        // True when the meta of the local user is updated.
        const isUpdateOnMe = action.memberId === channelRoom.member_id
        // true if the maximum allowed nr of users for
        const isFull = isRoomFull(channelRoom, flags)
        // when in waiting room don't show room full notification when updating member is a dial in
        const isDialInMember = isDialIn(member)

        if (isMeInRoom) {
          if (isUpdateOnMe) {
            if (_.get(action, 'meta.isHandRaised')) {
              dispatch(notificationActions.createLocalRaiseHandNotification())
            }

            if (_.has(action, 'meta.requestingScreenShareRights')) {
              switch (_.get(action, 'meta.requestingScreenShareRights')) {
                case RIGHT_REQUESTING_STATE.REQUESTING:
                  dispatch(
                    notificationActions.createRequestingScreenShareNotification()
                  )
                  break

                case RIGHT_REQUESTING_STATE.CANCELED:
                  // When the local user is stops requesting screenshare rights, close the notification.
                  dispatch(
                    notificationActions.closeRequestingScreenShareNotification()
                  )
                  break

                case RIGHT_REQUESTING_STATE.APPROVED:
                  dispatch(
                    notificationActions.createScreenShareRequestApprovedNotification()
                  )
                  break

                case RIGHT_REQUESTING_STATE.REJECTED:
                  dispatch(
                    notificationActions.createScreenShareRequestRejectedNotification()
                  )
                  break

                default:
              }
            }
          } else if (_.get(action, 'meta.isHandRaised') && isHost(me)) {
            dispatch(
              notificationActions.createHostRaiseHandNotification(
                action.memberId,
                member.meta.userName,
                member.meta.avatarImage
              )
            )
          } else if (
            _.get(action, 'meta.requestingScreenShareRights') ===
              RIGHT_REQUESTING_STATE.REQUESTING &&
            isHost(me)
          ) {
            const user = getUser(channelRoom, action.memberId)
            const userName = getMemberUserName(user)

            dispatch(
              notificationActions.createScreenShareRequestNotification(
                action.memberId,
                userName,
                user.meta.avatarImage
              )
            )
          }
        } else if (
          isMeInWaitingRoom &&
          isFull &&
          !isUpdateOnMe &&
          !isDialInMember &&
          _.get(action, 'meta.status') === USER_STATUS.IN_ROOM
        ) {
          /**
           * show room full notification when I was waiting
           * and another just filled up the last conference spot
           * unless it was a dialin member
           */
          dispatch(notificationActions.createRoomFullNotification())
        }
      }
      break

    case CHANNEL_ROOM_ACTION_TYPES.UPDATED_MEMBER_STATE:
      {
        const { [CHANNEL_ROOM_STORE_NAME]: channelRoom } = getState()

        const me = getMe(channelRoom)
        const member = getUser(channelRoom, action.memberId)

        // True when the local user is in the conference room.
        const isMeInRoom = getMemberRoomState(me) === USER_STATUS.IN_ROOM
        // True when the meta of the local user is updated.
        const isUpdateOnMe = action.memberId === channelRoom.member_id

        if (
          isMeInRoom &&
          !isUpdateOnMe &&
          _.isMatch(action.states, { screens_send: true })
        ) {
          const userName = getMemberUserName(member)

          dispatch(
            notificationActions.createScreenShareStartedNotification(
              userName,
              member.meta.avatarImage
            )
          )
        }
      }
      break

    case CHANNEL_ROOM_ACTION_TYPES.JOINED:
      {
        const {
          [CHANNEL_ROOM_STORE_NAME]: channelRoom,
          [FLAGS_STORE_NAME]: flags,
        } = getState()

        const me = getMe(channelRoom)
        const isMeInWaitingRoom = getMemberRoomState(me) === USER_STATUS.WAITING
        const isFull = isRoomFull(channelRoom, flags)

        if (isMeInWaitingRoom && isFull) {
          dispatch(notificationActions.createRoomFullNotification())
        }
      }
      break

    case CHANNEL_ROOM_ACTION_TYPES.MEMBER_DELETE:
      {
        const {
          [CHANNEL_ROOM_STORE_NAME]: channelRoom,
          [FLAGS_STORE_NAME]: flags,
        } = getState()

        const me = getMe(channelRoom)
        const isMeInWaitingRoom = getMemberRoomState(me) === USER_STATUS.WAITING
        const isFull = isRoomFull(channelRoom, flags)

        if (isMeInWaitingRoom && !isFull) {
          dispatch(notificationActions.closeRoomFullNotification())
        }

        const user = getUser(channelRoom, action.payload.member_id)
        const memberState = getMemberRoomState(user)

        // If the deleted member was waiting and I am the host
        if (memberState === USER_STATUS.WAITING && isHost(me)) {
          const waitingGuests = rejectOfflineMembers(
            getWaitingMembers(channelRoom)
          )

          // If there is no one else still in the waiting room, create user left notification
          if (waitingGuests?.length === 0) {
            const userName = getMemberUserName(user)

            dispatch(
              notificationActions.createUserLeavesWaitingRoomNotification(
                userName,
                user.meta.avatarImage
              )
            )
            // If there are still others in the waiting room, update the waiting room notification
          } else if (waitingGuests?.length > 0) {
            dispatch(
              notificationActions.createUserEntersWaitingRoomNotification(
                waitingGuests
              )
            )
          }
        }
      }
      break

    case CHANNEL_ROOM_ACTION_TYPES.GUEST_MESSAGE_CREATE:
      // Only display the message when it is directed to a guest specifically.
      if (
        action.payload &&
        action.payload.to !== '*' &&
        _.has(action.payload, 'content.data.message_data.text')
      ) {
        const message = action.payload.content.data.message_data.text
        dispatch(notificationActions.createGuestMessageNotification(message))
      }
      break

    case CHANNEL_CONFERENCE_ACTION_TYPES.CONFERENCE_MEMBER:
      {
        const isNotificationEnabled = !!env('waiting_room_member_notification')
        if (
          isNotificationEnabled &&
          action.payload &&
          !action.payload.host_present
        ) {
          // When a guest joins the waitingroom while the host isn't present, show a notification.
          dispatch(
            notificationActions.createUserWaitingRoomNotification(
              action.payload.user_name
            )
          )
        }
      }
      break

    case ROOM_ACTION_TYPES.JOINING_MEETING_ERROR:
      {
        const { [CHANNEL_AUTH_STORE_NAME]: channelAuth } = getState()
        const errorMessage = /JoinRoomChannelTask error: (\w+)/.exec(
          action.payload.error
        )
        const isUnauthorizedError =
          errorMessage &&
          errorMessage.length > 1 &&
          errorMessage[1] === 'Unauthorized'

        if (
          isUnauthorizedError &&
          channelAuthSelectors.isRegisteredUser(channelAuth)
        ) {
          // When the registered user failed joining a room due to unauthorized, he will get a notification message.
          dispatch(notificationActions.createJoinRoomFailedNotification())
        }
      }
      break

    case CONFERENCE_ACTION_TYPES.ROOM_LINK_COPIED:
      dispatch(notificationActions.createRoomLinkNotification())
      break

    // User starts background effect
    case BACKGROUND_EFFECT_ACTION_TYPES.START_BLUR:
    case BACKGROUND_EFFECT_ACTION_TYPES.START_REPLACE:
      {
        const { showLoadingNotification } = action

        if (showLoadingNotification) {
          dispatch(
            notificationActions.createBackgroundEffectLoadingNotification()
          )
        }
      }
      break

    // User enters the (waiting) room
    case CHANNEL_ROOM_ACTION_TYPES.MEMBER_CREATE:
      {
        const { userName, name, avatarImage } = action.payload.meta

        const {
          [CHANNEL_ROOM_STORE_NAME]: channelRoom,
          [WAITING_ROOM_STORE_NAME]: waitingRoom,
        } = getState()

        const me = getMe(channelRoom)
        const autoAccept = waitingRoomSelectors.getAutoAccept(waitingRoom)

        const waitingGuests = rejectOfflineMembers(
          getWaitingMembers(channelRoom)
        )

        if (isHost(me) && waitingGuests.length) {
          if (autoAccept) {
            dispatch(
              notificationActions.createUserEntersRoomNotification(
                userName || name,
                avatarImage
              )
            )
          } else {
            dispatch(
              notificationActions.createUserEntersWaitingRoomNotification(
                waitingGuests
              )
            )
          }
        }
      }
      break

    // User has slow link
    case CHANNEL_ROOM_ACTION_TYPES.SLOW_LINK:
      {
        const { [CHANNEL_ROOM_STORE_NAME]: channelRoom } = getState()

        // True when the meta of the local user is updated.
        const isUpdateOnMe = action.payload.memberId === channelRoom.member_id

        if (isUpdateOnMe) {
          dispatch(notificationActions.createSlowLinkNotification())
        }
      }
      break

    // User recieves private message
    case CHANNEL_ROOM_ACTION_TYPES.PRIVATE_MESSAGE_CREATE:
      {
        const { [CHANNEL_ROOM_STORE_NAME]: channelRoom } = getState()
        const { from, to } = action.payload

        const user = getUser(channelRoom, from)
        const userName = getMemberUserName(user)

        // True when message was addressed to me
        const isMessageForMe = to === channelRoom.member_id

        if (isMessageForMe) {
          dispatch(
            notificationActions.createPrivateMessageNotification(
              from,
              userName,
              user.meta.avatarImage
            )
          )
        }
      }
      break

    // A file was shared
    case CHANNEL_ROOM_ACTION_TYPES.FILE_UPDATE:
      {
        const { added } = action.payload

        if (added) {
          added.forEach((file) =>
            dispatch(
              notificationActions.createFileUploadedNotification(
                file.value?.name
              )
            )
          )
        }
      }
      break

    case CHANNEL_ROOM_ACTION_TYPES.UPDATED_MEMBER_RIGHTS:
      {
        const { memberId, rights } = action
        const { [CHANNEL_ROOM_STORE_NAME]: channelRoom } = getState()
        const me = getMe(channelRoom)
        // Check if the local member is the member being updated
        if (me.member_id === memberId) {
          // Check if the rights being updated is making the member a room_moderator
          if (rights.room_moderator === true) {
            store.dispatch(
              notificationActions.createMadeHostByHostNotification()
            )
          }
          if (rights.room_moderator === false) {
            store.dispatch(
              notificationActions.createRevokedHostByHostNotification()
            )
          }
        }
      }
      break
    default:
      break
  }

  return nextState
}

export default reducer
