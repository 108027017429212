import analytics from 'app/services/analytics'
import ANALYTICS_EVENT_TYPES from 'app/services/analytics/analytics-events'
import { BACKGROUND_EFFECT_TYPES } from 'constants/constants'

import {
  STORE_NAME as ROOM_STORE_NAME,
  selectors as roomSelectors,
} from 'app/features/room/room.reducer'
import {
  ACTION_TYPES as BACKGROUND_EFFECT_ACTION_TYPES,
  STORE_NAME as BACKGROUND_EFFECT_STORE_NAME,
  selectors as backgroundEffectSelectors,
} from 'app/features/background-effect/background-effect.reducer'

function trackStartBlur(store) {
  const { [ROOM_STORE_NAME]: room } = store.getState()

  const payload = {}

  if (room) {
    payload.view = roomSelectors.getViewType(room)
  }

  analytics.track(ANALYTICS_EVENT_TYPES.BACKGROUND_EFFECT.BLUR_STARTED, payload)
}

function trackStartReplace(store) {
  const { [ROOM_STORE_NAME]: room } = store.getState()

  const payload = {}

  if (room) {
    payload.view = roomSelectors.getViewType(room)
  }

  analytics.track(
    ANALYTICS_EVENT_TYPES.BACKGROUND_EFFECT.REPLACE_STARTED,
    payload
  )
}

function trackStopEffect(store) {
  const {
    [ROOM_STORE_NAME]: room,
    [BACKGROUND_EFFECT_STORE_NAME]: backgroundEffect,
  } = store.getState()

  const previousEffect =
    backgroundEffectSelectors.getActiveEffect(backgroundEffect)

  let event

  if (previousEffect === BACKGROUND_EFFECT_TYPES.BLUR) {
    event = ANALYTICS_EVENT_TYPES.BACKGROUND_EFFECT.BLUR_STOPPED
  } else if (previousEffect === BACKGROUND_EFFECT_TYPES.REPLACE) {
    event = ANALYTICS_EVENT_TYPES.BACKGROUND_EFFECT.REPLACE_STOPPED
  }

  if (event) {
    const payload = {}

    if (room) {
      payload.view = roomSelectors.getViewType(room)
    }

    analytics.track(event, payload)
  }
}

function trackRememberEffect(store, action) {
  const { [ROOM_STORE_NAME]: room } = store.getState()

  let event

  if (action.payload) {
    event = ANALYTICS_EVENT_TYPES.BACKGROUND_EFFECT.REMEMBERED
  } else {
    event = ANALYTICS_EVENT_TYPES.BACKGROUND_EFFECT.FORGOTTEN
  }

  const payload = {}

  if (room) {
    payload.view = roomSelectors.getViewType(room)
  }

  analytics.track(event, payload)
}

export default (store) => (next) => (action) => {
  switch (action.type) {
    case BACKGROUND_EFFECT_ACTION_TYPES.START_BLUR:
      trackStartBlur(store)
      break

    case BACKGROUND_EFFECT_ACTION_TYPES.START_REPLACE:
      trackStartReplace(store)
      break

    case BACKGROUND_EFFECT_ACTION_TYPES.STOP_EFFECT:
      trackStopEffect(store)
      break

    case BACKGROUND_EFFECT_ACTION_TYPES.SET_REMEMBER_EFFECT:
      trackRememberEffect(store, action)
      break

    default:
  }

  return next(action)
}
