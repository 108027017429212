import {
  useMemo,
  useRef,
  type Ref,
  type RefObject,
  type MutableRefObject,
} from 'react'

/**
 *
 * @param ref
 * @param value
 */
function setRef<T>(ref: Ref<T>, value: T): void {
  if (typeof ref === 'function') {
    ref(value)
  } else if (ref !== null) {
    ;(ref as MutableRefObject<T>).current = value
  }
}

/**
 *
 * @param refs
 */
function useForkRef<T>(...refs: Array<Ref<T>>): RefObject<T> {
  const originalRef = useRef(null)
  const refsCollection = useRef(refs)
  refsCollection.current = refs

  return useMemo(
    () => ({
      get current() {
        return originalRef.current
      },
      set current(instance) {
        setRef(originalRef, instance)
        refsCollection.current.forEach((r) => setRef(r, instance))
      },
    }),
    []
  )
}

export default useForkRef
