import _ from 'lodash'

import {
  events,
  initialize as initializeBroadcaster,
} from 'app/services/events'
import { isHost, getMe } from 'app/state/utils'
import urlUtils from 'lib/url-utils'

import {
  ACTION_TYPES as CHANNEL_ROOM_ACTION_TYPES,
  STORE_NAME as CHANNEL_ROOM_STORE_NAME,
  selectors as channelRoomSelectors,
  actions as channelRoomActions,
} from 'app/state/api/channels/channel-room.reducer'

export default (store) => (next) => (action) => {
  const nextState = next(action)
  const { getState, dispatch } = store

  switch (action.type) {
    case CHANNEL_ROOM_ACTION_TYPES.JOINED:
      {
        const { [CHANNEL_ROOM_STORE_NAME]: channelRoom } = getState()
        const urlParams = urlUtils.getUrlParams()
        const iframeFromRoomMeta =
          channelRoomSelectors.getIframeURL(channelRoom)

        const me = getMe(channelRoom)

        if (isHost(me) && urlParams.i) {
          dispatch(
            channelRoomActions.updateRoomMeta({ iframeUrl: urlParams.i })
          )
        }

        if (iframeFromRoomMeta && !urlParams.i) {
          initializeBroadcaster(iframeFromRoomMeta)
        }

        if (_.has(me, 'meta.status')) {
          events.userState({
            meetingId: channelRoomSelectors.getMeetingId(channelRoom),
            userId: channelRoomSelectors.getMemberId(channelRoom),
            status: me.meta.status,
          })
        }
      }
      break

    case CHANNEL_ROOM_ACTION_TYPES.UPDATED_MEMBER_META:
      {
        const { [CHANNEL_ROOM_STORE_NAME]: channelRoom } = getState()

        if (
          action.memberId === channelRoom.member_id &&
          _.has(action, 'meta.status')
        ) {
          events.userState({
            meetingId: channelRoomSelectors.getMeetingId(channelRoom),
            userId: channelRoomSelectors.getMemberId(channelRoom),
            status: action.meta.status,
          })
        }
      }
      break

    default:
  }

  return nextState
}
