import type { SVGProps } from 'react'
const SvgAgile11Pair = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle cx={82.17} cy={78.48} r={62} fill='rgb(var(--color-brand-50))' />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M117.86 109.17c.02-.43.03-.86.03-1.29 0-13.1-8.96-24.11-21.09-27.22 6.97-1.39 12.23-7.54 12.23-14.92 0-8.41-6.82-15.22-15.22-15.22s-15.22 6.82-15.22 15.22c0 4.64 2.09 8.79 5.37 11.58l.1 3.05c-12.77 2.65-22.37 13.95-22.37 27.51 0 .43.01.86.03 1.29h56.14z'
    />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M44.14 109.63c2.26-11.2 12.16-19.64 24.03-19.64s21.65 8.34 23.99 19.44'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M44.14 112.13a2.503 2.503 0 0 1-2.46-2.99c2.53-12.54 13.67-21.65 26.48-21.65s23.82 9.01 26.44 21.42a2.5 2.5 0 0 1-1.93 2.96 2.5 2.5 0 0 1-2.96-1.93c-2.13-10.12-11.19-17.46-21.54-17.46s-19.52 7.42-21.58 17.63a2.5 2.5 0 0 1-2.45 2.01z'
    />
    <circle
      cx={68.17}
      cy={74.12}
      r={15.89}
      fill='rgb(var(--color-neutral-50))'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M68.17 92.51c-10.14 0-18.39-8.25-18.39-18.39s8.25-18.39 18.39-18.39 18.39 8.25 18.39 18.39-8.25 18.39-18.39 18.39zm0-31.78c-7.38 0-13.39 6.01-13.39 13.39s6.01 13.39 13.39 13.39 13.39-6.01 13.39-13.39-6.01-13.39-13.39-13.39zm50.47 48.49h-.21a2.501 2.501 0 0 1-2.29-2.7c.05-.59.07-1.18.07-1.76 0-11.66-9.49-21.14-21.14-21.14a2.5 2.5 0 0 1 0-5c14.42 0 26.14 11.73 26.14 26.14 0 .72-.03 1.45-.09 2.17-.11 1.31-1.2 2.3-2.49 2.3z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M95.07 83.64a2.5 2.5 0 0 1 0-5c7.07 0 12.83-5.75 12.83-12.83s-5.75-12.82-12.83-12.82c-3.23 0-6.32 1.21-8.69 3.39a2.498 2.498 0 1 1-3.39-3.67c3.3-3.04 7.59-4.72 12.08-4.72 9.83 0 17.83 8 17.83 17.82s-8 17.83-17.83 17.83z'
    />
    <path
      fill='rgb(var(--color-brand-200))'
      d='M57.18 112.97h-4.47c-.97 0-1.75-.78-1.75-1.75s.78-1.75 1.75-1.75h4.47c.97 0 1.75.78 1.75 1.75s-.78 1.75-1.75 1.75zm55.39 0h-11.9c-.97 0-1.75-.78-1.75-1.75s.78-1.75 1.75-1.75h11.9c.97 0 1.75.78 1.75 1.75s-.78 1.75-1.75 1.75zm-30.05 0H63.97c-.97 0-1.75-.78-1.75-1.75s.78-1.75 1.75-1.75h18.55c.97 0 1.75.78 1.75 1.75s-.78 1.75-1.75 1.75z'
    />
  </svg>
)
export default SvgAgile11Pair
