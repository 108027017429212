import { useCallback, useEffect, useRef } from 'react'
import { ClSelectField } from '@enreach/core-component-library-react'
import {
  ClSelectFieldCustomEvent,
  defineCustomElementClSelectField,
} from '@enreach/core-component-library/dist/components/index'
import clsx from 'clsx'

import { makeStyles } from 'app/ui'
import { Icon, Dropdown, DropdownItem } from 'ui/revision'

import { type SelectProps, type SelectItem } from './select.types'

const useStyles = makeStyles({
  fullWidth: {
    '--field-width': '100%',
  },
})
/**
 * Single value select field
 *
 */
export const Select: React.FC<SelectProps> = ({
  disabled,
  helperText,
  iconName,
  items = [],
  label,
  value: valueProps,
  onChange,
  fullWidth,
  ...selectFieldProps
}: SelectProps) => {
  const ref = useRef<HTMLClSelectFieldElement>()
  const classes = useStyles()

  const handleChange = useCallback(
    (event: ClSelectFieldCustomEvent<{ value: SelectItem[] }>) => {
      onChange(event.detail.value[0])
    },
    [onChange]
  )

  const value = items.filter((item) => item.value === valueProps)

  return (
    <ClSelectField
      {...selectFieldProps}
      closeOnSelect
      className={clsx(
        fullWidth && classes.fullWidth,
        selectFieldProps.className
      )}
      disabled={disabled}
      helperText={helperText}
      label={label}
      ref={ref}
      value={value}
      onClChange={handleChange}
    >
      <span slot='leading'>{iconName && <Icon name={iconName} />}</span>
      <Dropdown>
        <span>
          {items.map((item) => (
            <DropdownItem key={item.value} {...item} />
          ))}
        </span>
      </Dropdown>
    </ClSelectField>
  )
}
export default Select

defineCustomElementClSelectField()
