import _ from 'lodash'

import {
  USER_STATUS,
  USER_TYPES,
  RIGHT_REQUESTING_STATE,
} from 'constants/constants'

import env from 'app/config/env'

type Member = {
  member_id: string
}

type Members =
  | {
      [key: string]: Member
    }
  | Member[]

type MeetingRoom = {
  members: Members
}

type ChannelRoom = {
  meeting_room: MeetingRoom
}

type AnyCollection = ChannelRoom | MeetingRoom | Members

// ------------------------------------
// Utils
// ------------------------------------
/**
 * Return the object with the members.
 *
 * @example <caption>Example when channelRoom is given</caption>
 * // returns
 * // {
 * //   '25ef0eb0-26bb-438a-b902-efeb9293b78d': {
 * //     member_id: '25ef0eb0-26bb-438a-b902-efeb9293b78d',
 * //     meta: { type: 'phone' },
 * //   },
 * // }
 * getMembers({
 *   meeting_room: {
 *     members: {
 *       '25ef0eb0-26bb-438a-b902-efeb9293b78d': {
 *         member_id: '25ef0eb0-26bb-438a-b902-efeb9293b78d',
 *         meta: { type: 'phone' },
 *       },
 *     },
 *   },
 * })
 * @example <caption>Example when meeting_room is given</caption>
 * // returns
 * // {
 * //   '25ef0eb0-26bb-438a-b902-efeb9293b78d': {
 * //     member_id: '25ef0eb0-26bb-438a-b902-efeb9293b78d',
 * //     meta: { type: 'phone' },
 * //   },
 * // }
 * getMembers({
 *   members: {
 *     '25ef0eb0-26bb-438a-b902-efeb9293b78d': {
 *       member_id: '25ef0eb0-26bb-438a-b902-efeb9293b78d',
 *       meta: { type: 'phone' },
 *     },
 *   },
 * })
 * @example <caption>Example when members are given</caption>
 * // returns
 * // {
 * //   '25ef0eb0-26bb-438a-b902-efeb9293b78d': {
 * //     member_id: '25ef0eb0-26bb-438a-b902-efeb9293b78d',
 * //     meta: { type: 'phone' },
 * //   },
 * // }
 * getMembers({
 *   '25ef0eb0-26bb-438a-b902-efeb9293b78d': {
 *     member_id: '25ef0eb0-26bb-438a-b902-efeb9293b78d',
 *     meta: { type: 'phone' },
 *   },
 * })
 * @param collection
 * @return Object with all members
 */
function getMembers(collection: AnyCollection): Members {
  if (
    collection &&
    'meeting_room' in collection &&
    'members' in collection.meeting_room
  ) {
    return collection.meeting_room.members
  }
  if (collection && 'members' in collection) {
    return collection.members as { [key: string]: Member }
  }
  return collection as Members
}

/**
 * Filter the given collection with the given predicate.
 *
 * @example
 * // returns [{ member_id: '25ef0eb0-26bb-438a-b902-efeb9293b78d', meta: { type: 'phone' } }]
 * filterMembers(
 * {
 *   meeting_room: {
 *      members: {
 *        '25ef0eb0-26bb-438a-b902-efeb9293b78d': {
 *          member_id: '25ef0eb0-26bb-438a-b902-efeb9293b78d',
 *          meta: { type: 'phone' },
 *        },
 *        '43dee3c9-da9e-4a17-b318-953e83cfde92': {
 *          member_id: '43dee3c9-da9e-4a17-b318-953e83cfde92',
 *          meta: { type: 'guest' },
 *        },
 *        '08841501-529e-4d12-8fa8-b7cd19165721': {
 *          member_id: '08841501-529e-4d12-8fa8-b7cd19165721',
 *          meta: { type: 'user' },
 *        },
 *      },
 *    },
 *  },
 *  { meta: { type: 'phone' } }
 * )
 * @param collection
 * @param predicate
 * @return
 */
function filterMembers(collection: AnyCollection, predicate): Member[] {
  const members = getMembers(collection)
  if (Array.isArray(predicate)) {
    return predicate
      .map((p) => _.filter(members, p))
      .reduce((ret, col) => ({ ...ret, ...col }))
  }
  return _.filter(members, predicate)
}

/**
 * Reject the given collection with the given predicate.
 *
 * @example
 * // returns
 * // [
 * //   { member_id: '43dee3c9-da9e-4a17-b318-953e83cfde92', meta: { type: 'guest' } },
 * //   { member_id: '08841501-529e-4d12-8fa8-b7cd19165721', meta: { type: 'phone' } },
 * // ]
 * rejectMembers(
 * {
 *   meeting_room: {
 *      members: {
 *        '25ef0eb0-26bb-438a-b902-efeb9293b78d': {
 *          member_id: '25ef0eb0-26bb-438a-b902-efeb9293b78d',
 *          meta: { type: 'phone' },
 *        },
 *        '43dee3c9-da9e-4a17-b318-953e83cfde92': {
 *          member_id: '43dee3c9-da9e-4a17-b318-953e83cfde92',
 *          meta: { type: 'guest' },
 *        },
 *        '08841501-529e-4d12-8fa8-b7cd19165721': {
 *          member_id: '08841501-529e-4d12-8fa8-b7cd19165721',
 *          meta: { type: 'user' },
 *        },
 *      },
 *    },
 *  },
 *  { meta: { type: 'phone' } }
 * )
 * @param collection
 * @param predicate
 * @return
 */
function rejectMembers(collection: AnyCollection, predicate): Member[] {
  const members = getMembers(collection)
  return _.reject(members, predicate) as Member[]
}

const memberPredicate = { meta: {}, rights: {}, states: {} }
const audioSendRightPredicate = { rights: { audio_send: true } }
const audioReceiveRightPredicate = { rights: { audio_receive: true } }
const videoSendRightPredicate = { rights: { video_send: true } }
const thumbnailSendRightPredicate = { rights: { thumbnails_send: true } }
const screenSendRightPredicate = { rights: { screens_send: true } }
const hostPredicate = { rights: { room_moderator: true } }

const videoSendPredicate = { states: { video_send: true } }
const thumbnailSendPredicate = { states: { thumbnails_send: true } }
const screenSendPredicate = { states: { screens_send: true } }
const raiseHandPredicate = { meta: { isHandRaised: true } }

const userTypePredicate = { meta: { type: USER_TYPES.USER } }
const guestTypePredicate = { meta: { type: USER_TYPES.GUEST } }
const phoneTypePredicate = { meta: { type: USER_TYPES.PHONE } }

const wizardReadyPredicate = { meta: { wizardReady: true } }
const inWaitingRoomPredicate = { meta: { status: USER_STATUS.WAITING } }
const inRoomPredicate = { meta: { status: USER_STATUS.IN_ROOM } }
const requestingScreenSharePredicate = {
  meta: { requestingScreenShareRights: RIGHT_REQUESTING_STATE.REQUESTING },
}
const preparedToLeavePredicate = { meta: { prepared_to_leave: true } }

const videoMutedPredicate = { meta: { isVideoMuted: true } }
const videoMutedByHostPredicate = { rights: { video_send: false } }
const audioMutedPredicate = { meta: { isAudioMuted: true } }
const audioMutedByHostPredicate = { meta: { isAudioMutedByHost: true } }
const hasVideoPredicate = { meta: { hasVideo: true } }
const hasAudioPredicate = { meta: { hasAudio: true } }

const offlinePredicate = { meta: { offline: true } }
const onlinePredicate = { meta: { offline: false } }

const isTalkingMetaPredicate = { meta: { talking: true } }
const isNotTalkingMetaPredicate = { meta: { talking: false } }
const isTalkingStatesPredicate = { states: { talking: true } }
const isNotTalkingStatesPredicate = { states: { talking: false } }

const talkingPredicate =
  env('active_speaker.mode', 'client') === 'client'
    ? isTalkingMetaPredicate
    : isTalkingStatesPredicate
// ------------------------------------
// Selectors
// ------------------------------------
/**
 * Get all the members who are sending video.
 *
 * @param collection
 * @return
 */
function getVideoSendingMembers(collection: AnyCollection): Member[] {
  return filterMembers(collection, videoSendPredicate)
}
/**
 * Get all the members who are sending thumbnails.
 *
 * @param collection
 * @return
 */
function getThumbnailSendingMembers(collection: AnyCollection): Member[] {
  return filterMembers(collection, thumbnailSendPredicate)
}
/**
 * Get all the members who are sending screens.
 *
 * @param collection
 * @return
 */
function getScreenSendingMembers(collection: AnyCollection): Member[] {
  return filterMembers(collection, screenSendPredicate)
}

/**
 * Get all the users who's type is user.
 *
 * @param collection
 * @return
 */
function getUserTypeUsers(collection: AnyCollection): Member[] {
  return filterMembers(collection, userTypePredicate)
}
/**
 * Get all the users who's type is guest.
 *
 * @param collection
 * @return
 */
function getGuestTypeUsers(collection: AnyCollection): Member[] {
  return filterMembers(collection, guestTypePredicate)
}
/**
 * Get all the users who's type is phone.
 *
 * @param collection
 * @return
 */
function getPhoneTypeUsers(collection: AnyCollection): Member[] {
  return filterMembers(collection, phoneTypePredicate)
}

/**
 * Get the members who are in the conference room.
 *
 * @param collection
 * @return
 */
function getInRoomMembers(collection: AnyCollection): Member[] {
  return filterMembers(collection, inRoomPredicate)
}

/**
 * Reject the members who are in the conference room.
 *
 * @param collection
 * @return
 */
function rejectInRoomMembers(collection: AnyCollection): Member[] {
  return rejectMembers(collection, inRoomPredicate)
}

/**
 * Get the members who's video is muted by the room moderator.
 *
 * @param collection
 * @return
 */
function getVideoMutedByHostMembers(collection: AnyCollection): Member[] {
  return filterMembers(collection, videoMutedByHostPredicate)
}
/**
 * Get the members who's audio is muted.
 *
 * @param collection
 * @return
 */
function getVideoMutedMembers(collection: AnyCollection): Member[] {
  return filterMembers(
    collection,
    (member: Member) =>
      _.isMatch(member, videoMutedByHostPredicate) ||
      _.isMatch(member, videoMutedPredicate)
  )
}
/**
 * Get the members who's audio is muted by the room moderator.
 *
 * @param collection
 * @return
 */
function getAudioMutedByHostMembers(collection: AnyCollection): Member[] {
  return filterMembers(collection, audioMutedByHostPredicate)
}
/**
 * Get the members who's audio is muted.
 *
 * @param collection
 * @return
 */
function getAudioMutedMembers(collection: AnyCollection): Member[] {
  return filterMembers(
    collection,
    (member: Member) =>
      _.isMatch(member, audioMutedByHostPredicate) ||
      _.isMatch(member, audioMutedPredicate)
  )
}

/**
 * Get all offline members.
 *
 * @param collection
 * @return
 */
function getOfflineMembers(collection: AnyCollection): Member[] {
  return filterMembers(collection, offlinePredicate)
}

/**
 * Removes dial in members.
 * @param collection
 * @returns
 */
function rejectDialInMembers(collection: AnyCollection): Member[] {
  return rejectMembers(collection, phoneTypePredicate)
}

/**
 * Reject all offline members.
 *
 * @param collection
 * @return
 */
function rejectOfflineMembers(collection: AnyCollection): Member[] {
  return rejectMembers(collection, offlinePredicate)
}

/**
 * Reject a specific memberId.
 *
 * @param collection
 * @param memberId uuid of a member
 * @return
 */
function rejectMemberId(collection: AnyCollection, memberId: string): Member[] {
  return rejectMembers(collection, { member_id: memberId })
}

/**
 * Get all the room moderators.
 *
 * @param collection
 * @return
 */
function getHosts(collection: AnyCollection): Member[] {
  return filterMembers(collection, hostPredicate)
}

/**
 * Reject all the room moderators.
 *
 * @param collection
 * @return
 */
function rejectHosts(collection: AnyCollection): Member[] {
  return rejectMembers(collection, hostPredicate)
}

/**
 * Get all the online guests which are in the room.
 *
 * @param collection
 * @return
 */
function getOnlineGuests(collection: AnyCollection): Member[] {
  const onlineMembers = rejectOfflineMembers(collection)
  const inRoomMembers = getInRoomMembers(onlineMembers)

  // FIXME: SUM-4495 Introduces a bug. When there are more than one moderator in the room, this will return [] members as there arent any guests
  // @link https://jira.voiceworks.com/browse/SUM-4495
  return rejectHosts(inRoomMembers)
}

/**
 * Get the member id of the first room moderator.
 *
 * @param collection
 * @return uuid of the host
 */
function getHostId(collection: AnyCollection): string {
  const hosts = getHosts(collection)
  return hosts[0]?.member_id
}

/**
 * Get all the phone member which are not moved to the conference room.
 *
 * @param collection
 * @return
 */
function getWaitingPhoneMembers(collection: AnyCollection): Member[] {
  const phoneMembers = getPhoneTypeUsers(collection)
  return rejectInRoomMembers(phoneMembers)
}

/**
 * Get all the members who are marked ready or phone users and NOT moved to
 * the conference room.
 *
 * @param collection
 * @return
 */
function getWaitingMembers(collection: AnyCollection): Member[] {
  const waitingUsers = filterMembers(collection, wizardReadyPredicate)
  const waitingPhoneUsers = getWaitingPhoneMembers(collection)

  const filteredUsers = _.uniqBy(
    [...waitingUsers, ...waitingPhoneUsers],
    'member_id'
  )

  return rejectInRoomMembers(filteredUsers)
}

/**
 * Get all the members who are requesting screen share rights.
 *
 * @param collection
 * @return
 */
function getScreenShareRequestingMembers(collection: AnyCollection): Member[] {
  return filterMembers(collection, requestingScreenSharePredicate)
}

/**
 * select sticker prop
 *
 * @param member
 * @return
 */
function getStickerOfMember(member): string {
  return _.get(member, 'meta.sticker') as string | undefined
}

export {
  // Utils
  filterMembers,
  getMembers,
  rejectMembers,
  // Predicates
  audioMutedByHostPredicate,
  audioMutedPredicate,
  audioReceiveRightPredicate,
  audioSendRightPredicate,
  guestTypePredicate,
  hasAudioPredicate,
  hasVideoPredicate,
  hostPredicate,
  inRoomPredicate,
  inWaitingRoomPredicate,
  memberPredicate,
  offlinePredicate,
  onlinePredicate,
  phoneTypePredicate,
  preparedToLeavePredicate,
  raiseHandPredicate,
  requestingScreenSharePredicate,
  screenSendPredicate,
  screenSendRightPredicate,
  talkingPredicate,
  thumbnailSendPredicate,
  thumbnailSendRightPredicate,
  userTypePredicate,
  videoMutedByHostPredicate,
  videoMutedPredicate,
  videoSendPredicate,
  videoSendRightPredicate,
  wizardReadyPredicate,
  isTalkingMetaPredicate,
  isNotTalkingMetaPredicate,
  isTalkingStatesPredicate,
  isNotTalkingStatesPredicate,
  // Selectors
  getAudioMutedByHostMembers,
  getAudioMutedMembers,
  getGuestTypeUsers,
  getHostId,
  getHosts,
  getInRoomMembers,
  getOfflineMembers,
  getOnlineGuests,
  getPhoneTypeUsers,
  getScreenSendingMembers,
  getScreenShareRequestingMembers,
  getStickerOfMember,
  getThumbnailSendingMembers,
  getUserTypeUsers,
  getVideoMutedByHostMembers,
  getVideoMutedMembers,
  getVideoSendingMembers,
  getWaitingMembers,
  getWaitingPhoneMembers,
  rejectHosts,
  rejectInRoomMembers,
  rejectMemberId,
  rejectOfflineMembers,
  rejectDialInMembers,
}
