import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { useId } from 'app/react/hooks/id-registry.hooks'
import { Typography } from 'ui/revision'
import Button from 'ui/revision/button'
import Dialog, { DialogActions, DialogContent } from 'ui/revision/dialog'

function AlertDialog({ content, title, onSubmit }) {
  const { t } = useTranslation()
  const { id } = useId()
  return (
    <Dialog open header={title} onClosed={onSubmit}>
      <DialogContent>
        <Typography>{content}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          data-testid={id('Dialogs.Alert.ok')}
          key='submit'
          type='submit'
          onClick={onSubmit}
        >
          {t('common.actions.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AlertDialog.propTypes = {
  content: PropTypes.string,
  title: PropTypes.string,

  onSubmit: PropTypes.func,
}

AlertDialog.defaultProps = {
  content: '',
  title: '',

  onSubmit: Function.prototype,
}

export default AlertDialog
