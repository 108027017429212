import { type HTMLAttributes } from 'react'
import cn from 'clsx'

type Props = HTMLAttributes<HTMLDivElement>

const EventIcon: React.FC<Props> = ({ className, ...other }: Props) => (
  <div className={cn('event-icon', className)} {...other} />
)

export default EventIcon
