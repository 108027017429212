import type { SVGProps } from 'react'
const SvgAgileCard1 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <rect
      width={94.33}
      height={126.14}
      x={33.78}
      y={17.68}
      fill='rgb(var(--color-neutral-50))'
      rx={8.63}
      ry={8.63}
    />
    <path
      fill='rgb(var(--color-brand-500))'
      d='M119.48 145.82H42.4c-5.86 0-10.62-4.77-10.62-10.63V26.31c0-5.86 4.77-10.63 10.62-10.63h77.08c5.86 0 10.63 4.77 10.63 10.63V135.2c0 5.86-4.77 10.63-10.63 10.63zM42.4 19.68c-3.65 0-6.62 2.97-6.62 6.63V135.2c0 3.65 2.97 6.63 6.62 6.63h77.08c3.65 0 6.63-2.97 6.63-6.63V26.31c0-3.65-2.97-6.63-6.63-6.63H42.4z'
    />
    <rect
      width={79.02}
      height={110.67}
      x={41.43}
      y={25.42}
      fill='rgb(var(--color-brand-100))'
      rx={2.78}
      ry={2.78}
    />
    <circle cx={50.06} cy={34.43} r={5.57} fill='rgb(var(--color-brand-200))' />
    <circle
      cx={111.83}
      cy={127.08}
      r={5.57}
      fill='rgb(var(--color-brand-200))'
    />
    <path
      fill='rgb(var(--color-brand-600))'
      d='M79.24 103.03c-.69 0-1.25-.56-1.25-1.25V68.85h-6.11c-.69 0-1.25-.56-1.25-1.25v-4.99c0-.69.56-1.25 1.25-1.25 1.88 0 3.4-.16 4.49-.46 1-.28 1.76-.72 2.33-1.34.61-.67 1.17-1.68 1.67-3.01.18-.49.65-.81 1.17-.81h4.74c.69 0 1.25.56 1.25 1.25v44.8c0 .69-.56 1.25-1.25 1.25h-7.04z'
    />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='M86.28 56.98v44.8h-7.04V67.6h-7.36v-4.99c2 0 3.62-.17 4.83-.51s2.19-.91 2.91-1.7c.72-.79 1.36-1.93 1.92-3.42h4.74m0-2.5h-4.74c-1.04 0-1.98.65-2.34 1.63-.55 1.47-1.07 2.23-1.42 2.6-.41.44-.98.76-1.75.98-.99.28-2.39.42-4.16.42a2.5 2.5 0 0 0-2.5 2.5v4.99a2.5 2.5 0 0 0 2.5 2.5h4.86v31.68a2.5 2.5 0 0 0 2.5 2.5h7.04a2.5 2.5 0 0 0 2.5-2.5v-44.8a2.5 2.5 0 0 0-2.5-2.5z'
    />
  </svg>
)
export default SvgAgileCard1
