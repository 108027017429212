import _ from 'lodash'

import localStore from 'app/services/state/local-store'
import * as StickerModule from 'ui/stickers'
import { STICKER_CATEGORY_ORDER } from 'constants/constants'

const categoryToStickersMap = StickerModule.categories.reduce(
  (acc, category) => {
    return {
      ...acc,
      [category]: Object.keys(
        StickerModule[category] as Record<string, React.ComponentType>
      ).sort(),
    }
  },
  {}
)

// Remove the categories that aren't in the CATEGORY_ORDER array, and sort the categories by the order in CATEGORY_ORDER
const categories = [
  'recently_used',
  ..._.intersection(STICKER_CATEGORY_ORDER, StickerModule.categories),
]

/**
 * all stickers mapped to their category
 */
const stickerToCategoryMap: Record<string, string> =
  StickerModule.categories.reduce((acc, category) => {
    Object.keys(
      StickerModule[category] as Record<string, React.ComponentType>
    ).forEach((sticker) => {
      acc[sticker] = category
    })
    return acc
  }, {})

/**
 * make sure localstore stickers are still valid
 */
export const updateRecentlyUsedStickers = (): void => {
  const recentlyUsed = localStore.getRecentlyUsedStickers()
  const stickerNames = Object.keys(stickerToCategoryMap)
  const newRecentlyUsed = _.uniq(
    _.intersection(recentlyUsed, stickerNames)
  ).slice(-20)
  localStore.setRecentlyUsedStickers(newRecentlyUsed)
}

/**
 * get stickers and categories
 */
export const getStickersAndCategories = (): {
  /**
   * map of sticker category to array of sticker names
   */
  categoryToStickersMap: Record<string, string[]>
  /**
   * array of sticker categories
   */
  categories: string[]
  /**
   * map of sticker name to sticker category
   */
  stickerToCategoryMap?: Record<string, string>
} => {
  return {
    categoryToStickersMap,
    stickerToCategoryMap,
    categories,
  }
}
