class NotificationBuilder {
  title: string
  body?: string
  icon?: string
  tag?: string
  onClick?: (event: MouseEvent) => void
  autoClose?: boolean
  requireInteraction?: boolean

  constructor(title: string) {
    this.title = title
  }

  withBody(body: string): NotificationBuilder {
    this.body = body
    return this
  }

  withIcon(icon: string): NotificationBuilder {
    this.icon = icon
    return this
  }

  withTag(tag: string): NotificationBuilder {
    this.tag = tag
    return this
  }

  withOnClick(onClick: (event: MouseEvent) => void): NotificationBuilder {
    this.onClick = onClick
    return this
  }

  withAutoClose(): NotificationBuilder {
    this.autoClose = true
    return this
  }

  withRequireInteraction(): NotificationBuilder {
    this.requireInteraction = true
    return this
  }

  build(): Notification {
    const notification = new Notification(this.title, {
      body: this.body,
      icon: this.icon,
      tag: this.tag,
      requireInteraction: this.requireInteraction,
    })

    notification.addEventListener('click', (event: MouseEvent): void => {
      window.focus()
      const notifcation = event.target as Notification
      notifcation.close()
    })

    if (this.onClick) {
      notification.addEventListener('click', this.onClick)
    }

    if (this.autoClose) {
      NotificationBuilder.addAutoClose(notification)
    }

    return notification
  }

  static addAutoClose(notification: Notification): void {
    const handleFocusChange = (): void => {
      notification.close()
    }

    window.addEventListener('focus', handleFocusChange)
    notification.addEventListener('close', () => {
      window.removeEventListener('focus', handleFocusChange)
    })
  }
}

export default NotificationBuilder
