/**
 * Enum for notification types
 * @readonly
 * @enum {String}
 */
export default {
  AUDIO_MUTED_BY_HOST: 'audioMutedByHost',
  AUDIO_UNMUTED_BY_HOST: 'audioUnmutedByHost',
  BACKGROUND_EFFECT_LOADING: 'backgroundEffectLoading',
  FILE_UPLOADED: 'fileUploaded',
  GUEST_MESSAGE: 'guestMessage',
  HOST_RAISE_HAND: 'hostRaiseHand',
  INCREASE_GRID_SIZE: 'increaseGridSize',
  JOIN_ROOM_FAILED: 'joinRoomFailed',
  LOCAL_RAISE_HAND: 'localRaiseHand',
  PRIVATE_MESSAGE: 'privateMessage',
  REQUESTING_SCREEN_SHARE: 'requestingScreenShare',
  ROOM_FULL: 'roomFull',
  ROOM_LINK: 'roomLink',
  SCREEN_SHARE_REQUEST: 'screenShareRequest',
  SCREEN_SHARE_REQUEST_APPROVED: 'screenShareRequestApproved',
  SCREEN_SHARE_REQUEST_REJECTED: 'screenShareRequestRejected',
  SCREEN_SHARE_STARTED: 'screenShareStarted',
  SLOW_LINK: 'slowLink',
  TALKING_WHILE_MUTED: 'talkingWhileMuted',
  USER_ENTERS_ROOM: 'userEntersRoom',
  USER_ENTERS_WAITING_ROOM: 'userEntersWaitingRoom',
  USER_LEAVES_WAITING_ROOM: 'userLeavesWaitingRoom',
  USER_WAITING_ROOM: 'userWaitingRoom',
  VIDEO_MUTED_BY_HOST: 'videoMutedByHost',
  VIDEO_UNMUTED_BY_HOST: 'videoUnmutedByHost',
  MADE_HOST_BY_HOST: 'madeHostByHost',
  REVOKED_HOST_BY_HOST: 'revokedHostByHost',
}
