import React from 'react'
import { ReduxRouter } from '@lagunovsky/redux-react-router'

import { AuthProvider } from 'app/features/auth'
import { history } from 'app/services/history'

import DialogsProvider from './dialogs-provider.component'
import I18nProvider from './i18n-provider.component'
import IdProvider from './id-provider.component'
import StoreProvider from './store-provider.component'
import ThemeProvider from './theme-provider.component'
import ViewportProvider from './viewport-provider.component'

type Props = {
  children: React.ReactNode
}

const Provider = ({ children }: Props): JSX.Element => (
  <IdProvider>
    <I18nProvider>
      <ViewportProvider>
        <ThemeProvider>
          <DialogsProvider>
            <StoreProvider history={history}>
              <ReduxRouter history={history}>
                <AuthProvider>{children}</AuthProvider>
              </ReduxRouter>
            </StoreProvider>
          </DialogsProvider>
        </ThemeProvider>
      </ViewportProvider>
    </I18nProvider>
  </IdProvider>
)

export default Provider
