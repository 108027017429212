import { cloneElement, useState, useCallback } from 'react'

/**
 * this hook maintains a button state during the execution of a click
 * when click is executed the disabled state is set
 * @param asyncFn
 */
export function useAsyncClick(onClick) {
  const [disabled, setDisabled] = useState(false)
  const handleClick = useCallback(
    async (e) => {
      setDisabled(true)
      try {
        await onClick(e)
      } finally {
        setDisabled(false)
      }
    },
    [onClick]
  )
  return { disabled, onClick: handleClick }
}

/**
 * Applies automagically the async click
 * @param node Button that will have the disabled state managed during execution of the click
 * @param onClick callback used for execution of the click
 */
export function useAsyncButton(node, onClick) {
  const asyncProps = useAsyncClick(onClick)
  if (node.props.disabled) {
    asyncProps.disabled = node.props.disabled
  }
  return cloneElement(node, asyncProps)
}
