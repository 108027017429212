import ColigoTrack from './coligo-track'

/**
 * @class
 * @extends ColigoTrack
 */
class LocalColigoTrack extends ColigoTrack {
  /**
   * The id of the device
   *
   */
  deviceId = ''

  /**
   *
   * @constructs
   * @param options
   * @param options.deviceId
   * @param options.stream
   * @param options.track
   */
  constructor(options) {
    super(options)

    this.deviceId = options.deviceId
  }

  setContentHint(hint) {
    if ('contentHint' in this.track) {
      this.track.contentHint = hint
      if (this.track.contentHint !== hint) {
        throw new Error(`Invalid video track contentHint: '${hint}'`)
      }
    } else {
      logger.error('MediaStreamTrack contentHint attribute not supported')
    }
  }
}

export default LocalColigoTrack
