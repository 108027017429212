import { AnyAction } from 'redux'

export const STORE_NAME = 'feature/auth'

export enum ACTION_TYPES {
  LOGIN = `sm-web/${STORE_NAME}/LOGIN`,
  LOGOUT = `sm-web/${STORE_NAME}/LOGOUT`,
  SET_IS_AUTH = `sm-web/${STORE_NAME}/SET_IS_AUTH`,
  START_AUTHENTICATING = `sm-web/${STORE_NAME}/START_AUTHENTICATING`,
  STOP_AUTHENTICATING = `sm-web/${STORE_NAME}/STOP_AUTHENTICATING`,
  SET_AUTHENTICATION_ERROR = `sm-web/${STORE_NAME}/SET_AUTHENTICATION_ERROR`,
}

const login = (memberId: string, type: 'user' | 'guest'): AnyAction => ({
  type: ACTION_TYPES.LOGIN,
  payload: { member_id: memberId, type },
})

const logout = (): AnyAction => ({ type: ACTION_TYPES.LOGOUT })

const setIsAuth = (payload: boolean): AnyAction => ({
  type: ACTION_TYPES.SET_IS_AUTH,
  payload,
})

const startAuthenticating = (payload: 'user' | 'guest'): AnyAction => ({
  type: ACTION_TYPES.START_AUTHENTICATING,
  payload,
})

const stopAuthenticating = (): AnyAction => ({
  type: ACTION_TYPES.STOP_AUTHENTICATING,
})

const setAuthenticationError = (
  payload: State['authenticationError']
): AnyAction => ({
  type: ACTION_TYPES.SET_AUTHENTICATION_ERROR,
  payload,
})

export const actions = {
  login,
  logout,
  setIsAuth,
  startAuthenticating,
  stopAuthenticating,
  setAuthenticationError,
}

const INITIAL_STATE: State = {
  authenticating: false,
  isAuth: false,
  auth: null,
  authenticationError: null,
}

type State = {
  authenticating: boolean
  isAuth: boolean
  auth: {
    member_id: string
    type: 'user' | 'guest'
  }
  authenticationError: {
    type: string
    description?: string
    code?: string
  }
}

const ACTION_HANDLERS = {
  [ACTION_TYPES.LOGIN]: (
    state: State,
    action: ReturnType<typeof login>
  ): State => ({
    ...state,
    isAuth: true,
    auth: action.payload,
    authenticationError: null,
  }),
  [ACTION_TYPES.LOGOUT]: (): State => INITIAL_STATE,
  [ACTION_TYPES.SET_IS_AUTH]: (
    state: State,
    action: ReturnType<typeof setIsAuth>
  ): State => {
    let { authenticationError } = state

    if (action.payload) {
      authenticationError = null
    }

    return {
      ...state,
      isAuth: action.payload,
      authenticationError,
    }
  },
  [ACTION_TYPES.START_AUTHENTICATING]: (
    state: State,
    action: ReturnType<typeof startAuthenticating>
  ): State => ({
    ...state,
    authenticating: true,
    auth: { ...state.auth, type: action.payload },
  }),
  [ACTION_TYPES.STOP_AUTHENTICATING]: (state: State): State => ({
    ...state,
    authenticating: false,
  }),
  [ACTION_TYPES.SET_AUTHENTICATION_ERROR]: (
    state: State,
    action: ReturnType<typeof setAuthenticationError>
  ): State => ({
    ...state,
    authenticationError: action.payload,
  }),
  'sm-web/RESET': (): State => INITIAL_STATE,
}

const reducer = (state: State = INITIAL_STATE, action?: AnyAction): State => {
  const actionHandler = ACTION_HANDLERS[action?.type as ACTION_TYPES]
  return actionHandler ? actionHandler(state, action) : state
}

export default reducer

export const selectors = {
  isAuthenticating(state: State): boolean {
    return !!state.authenticating || false
  },
  isAuthenticated(state: State): boolean {
    return !!state.isAuth || false
  },
  getAuthenticatedUser(
    state: State
  ): State['auth'] | { member_id: ''; type: '' } {
    return state.auth || { member_id: '', type: '' }
  },
  getAuthenticationError(state: State): State['authenticationError'] {
    return state.authenticationError || null
  },
}
