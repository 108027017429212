import TASK_STATUS from './coligo-task-status'
import Task from './coligo-task'

export class SerialTask extends Task {
  name = 'SerialTask'

  tasks

  currentTask

  constructor(name, tasks) {
    super()

    this.name = name

    this.tasks = tasks
  }

  cancel() {
    if (this.currentTask) {
      logger.debug(
        `canceling serial group task ${this.name} subtask ${this.currentTask.name}`
      )
      this.currentTask.cancel()
    }
  }

  async run() {
    for (const task of this.tasks) {
      if (this.status === TASK_STATUS.CANCELED) {
        throw new Error(`serial group task ${this.name} was canceled`)
      }
      try {
        logger.debug(
          `serial group task ${this.name} running subtask ${task.name}`
        )
        this.currentTask = task
        await task.run()
        logger.debug(
          `serial group task ${this.name} completed subtask ${task.name}`
        )
      } catch (err) {
        throw new Error(
          `serial group task ${this.name} was canceled due to subtask ` +
            `${this.currentTask.name} error: ${err.message}`
        )
      } finally {
        this.currentTask = null
      }
    }
    logger.debug(`serial group task ${this.name} completed`)
  }
}

export default SerialTask
