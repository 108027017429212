import { makeStyles } from 'app/ui'
import { useId } from 'app/react/hooks/id-registry.hooks'
import { Icon, Toast } from 'ui/revision'

import { type SnackBarIcons } from './snackbar-icons'

/**
 * Ensure we have CSS provided for each class being used
 */
type StyledItems = 'snackbar' // New properties are added like so 'snackbar' | 'icon' | 'etc
type StyledCss = Record<StyledItems, React.CSSProperties>
type StyledClassNames = Record<StyledItems, string>

const useStyles = makeStyles(
  (): StyledCss => ({
    snackbar: { marginBottom: '2px' },
  }),
  {
    name: 'Snackbar',
  }
) as () => StyledClassNames

export interface SnackBarProps {
  autoHideDuration?: number
  classes?: StyledClassNames
  close?: boolean
  /**
   * An icon string or an Icon to render
   */
  icon?: SnackBarIcons
  id: string
  message?: string
  status?: 'success' | 'warning' | 'error' | 'info'

  onClose?: (id: string) => void
  onExited?: (id: string) => void
}

/**
 * Shows a toast message
 * FIXME: https://jira.voiceworks.com/browse/SUM-4730 Currently only support text messages without actions  @link https://designsystem.enreach.com/components/toast/code#Actions
 */
const Snackbar = ({
  autoHideDuration = 0,
  close = false,
  icon = undefined,
  id,
  message = '',
  status = 'info',
  onClose,
  onExited,
}: SnackBarProps) => {
  function handleDismiss() {
    onClose(id)
  }

  function handleExited() {
    onExited(id)
  }

  const { id: makeId } = useId()
  const classes = useStyles()

  return (
    <Toast
      inline
      class={classes.snackbar}
      data-testid={makeId('Snackbar.toast')}
      duration={autoHideDuration}
      position={'top-left'}
      show={!close}
      text={message}
      transition='slide-right'
      variant={status}
      onDismiss={handleDismiss}
      onExited={handleExited}
    >
      <span slot='icon'>{icon && <Icon name={icon} />}</span>
    </Toast>
  )
}

export default Snackbar
