import { Grid } from '@mui/material'
import { alpha } from '@mui/material/styles'
import cn from 'clsx'
import { EditorState, Modifier } from 'draft-js'
import createEmojiPlugin from 'draft-js-emoji-plugin'
import createLinkifyPlugin from 'draft-js-linkify-plugin'
import Editor from 'draft-js-plugins-editor'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { makeStyles } from 'app/ui'
import useForkRef from 'lib/react/hooks/use-fork-ref.hook'

import { useAutoFocus, useKeyCommands } from '../chat-input.hooks'
import { keyBindingFn, positionSuggestions } from '../chat-input.utils'

import 'draft-js-emoji-plugin/lib/plugin.css'

const useStyles = makeStyles((theme) => {
  const emojiSuggestionsEntry = {
    fontSize: theme.typography.pxToRem(20),
    padding: '1px 4px',
    borderRadius: '50%',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
  }

  return {
    '@global': {
      '.public-DraftEditorPlaceholder-root': {
        position: 'absolute',
      },
    },
    disabled: {
      position: 'relative',

      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        backgroundColor: alpha('#000', 0.12),
      },
    },

    editor: {
      maxWidth: '100%',
      padding: theme.spacing(2, 8, 2, 2),
      overflow: 'auto',
    },

    emojiSuggestions: {
      boxShadow: theme.shadows[6],
      marginTop: -theme.spacing(1),
      position: 'absolute',
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
      zIndex: 3,
      display: 'flex',
      flexDirection: 'row',
      boxSizing: 'border-box',
      transform: 'scale(0)',
      transformOrigin: 'left bottom',
      transition: theme.transitions.create(['transform'], { duration: 200 }),
    },

    emojiSuggestionsEntry,

    emojiSuggestionsEntryFocused: {
      ...emojiSuggestionsEntry,
      backgroundColor: alpha(theme.palette.background.avatar, 0.5),
    },

    emojiSuggestionsEntryIcon: {
      marginLeft: '0.25em',
      marginRight: '0.25em',
      display: 'inline-block',
    },

    emojiSuggestionsEntryText: {
      display: 'none',
    },
  }
})

type Props = {
  autoFocus?: boolean
  className?: string
  'data-testid'?: string
  disabled?: boolean
  emojiPickerPlugin?: any
  inputRef?: any
  messageIsSending?: boolean
  placeholder?: string
  tempMessage?: any
  onFocus?: () => void
  onHandleSubmit?: () => void
  onInputChange?: (string) => void
  onSaveTempMessage?: (editorState) => void
}

const ChatInputEditor: React.FC<Props> = ({
  autoFocus,
  className,
  'data-testid': dataTestid,
  disabled,
  emojiPickerPlugin,
  inputRef = {},
  messageIsSending,
  placeholder,
  tempMessage,
  onFocus,
  onHandleSubmit,
  onInputChange,
  onSaveTempMessage,
}: Props) => {
  const editor = useForkRef(inputRef)
  const emojiSuggestionsRef = useRef()
  const classes = useStyles()

  const emojiPlugin = useMemo(
    () =>
      createEmojiPlugin({
        positionSuggestions,
        useNativeArt: true,
        theme: {
          emojiSuggestions: classes.emojiSuggestions,
          emojiSuggestionsEntry: classes.emojiSuggestionsEntry,
          emojiSuggestionsEntryFocused: classes.emojiSuggestionsEntryFocused,
          emojiSuggestionsEntryText: classes.emojiSuggestionsEntryText,
          emojiSuggestionsEntryIcon: classes.emojiSuggestionsEntryIcon,
        },
      }),
    [classes]
  )

  const [linkifyPlugin] = useState(createLinkifyPlugin())

  const [useKeyCommand, handleKeyCommand] = useKeyCommands()

  const [editorState, setEditorState] = useState(
    tempMessage || EditorState.createEmpty()
  )

  useAutoFocus(autoFocus && editor, 100, [editor.current])

  const focusEditor = useCallback(() => {
    if (editor.current) editor.current.focus()
  }, [editor])

  const handleChange = useCallback(
    (newEditorState) => {
      setEditorState(newEditorState)
    },
    [setEditorState]
  )

  useEffect(() => {
    if (typeof inputRef === 'function') {
      inputRef(editor.current)
    }

    return () => {
      if (editor.current) {
        onSaveTempMessage(editor.current.props.editorState)
      }
    }
  }, [inputRef])

  useEffect(() => {
    if (!editorState) {
      setEditorState(EditorState.createEmpty())
      return () => setTimeout(focusEditor, 100)
    }
    return Function.prototype
  }, [editorState])

  useEffect(() => {
    const string = editorState
      ? editorState.getCurrentContent().getPlainText()
      : ''

    if (editorState) onInputChange(string)
    if ((disabled && string !== '') || messageIsSending) setEditorState(null)
  }, [disabled, editorState, messageIsSending])

  useKeyCommand('submit-me', onHandleSubmit)

  useKeyCommand('split-block', () => {
    const newContentState = Modifier.insertText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      '\n'
    )
    setEditorState(
      EditorState.forceSelection(
        EditorState.push(editorState, newContentState),
        newContentState.getSelectionAfter()
      )
    )
  })

  return (
    <Grid
      item
      className={cn(classes.editor, disabled && classes.disabled, className)}
      onClick={focusEditor}
    >
      {editorState && (
        <Editor
          stripPastedStyles
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={(e) => {
            // In order to have the left arrow work as the up arrow, we change the keycode which is used by draft-js
            if (e.key === 'ArrowLeft') e.keyCode = 38
            // In order to have the right arrow work as the down arrow, we change the keycode which is used by draft-js
            if (e.key === 'ArrowRight') e.keyCode = 40
            emojiPlugin.keyBindingFn(e)
            return keyBindingFn(e)
          }}
          placeholder={placeholder}
          plugins={[emojiPlugin, emojiPickerPlugin, linkifyPlugin]}
          readOnly={disabled}
          ref={editor}
          webDriverTestID={dataTestid}
          onChange={handleChange}
          onFocus={onFocus}
        />
      )}
      <emojiPlugin.EmojiSuggestions ref={emojiSuggestionsRef} />
    </Grid>
  )
}

export default ChatInputEditor
