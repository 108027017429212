import { createContext, forwardRef, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import hoistStatics from 'hoist-non-react-statics'

import registry from 'app/services/element-ids'

export const IdContext = createContext({
  instance: null,
  data: {},
  getId: (path): string => path,
})

/**
 * A public higher-order component to access the imperative API
 */
export function withId(Component) {
  const C = forwardRef((props, ref) => {
    return (
      <IdContext.Consumer>
        {(value) => <Component {...props} getId={value.getId} ref={ref} />}
      </IdContext.Consumer>
    )
  })

  C.displayName = `withId(${Component.displayName || Component.name})`
  C.WrappedComponent = Component
  C.propTypes = Component.propTypes
  C.defaultProps = Component.defaultProps

  return hoistStatics(C, Component)
}

const IdRegistryProvider = ({ children }) => {
  const getId = useCallback((path): string => {
    if (!path) throw new Error('No path given')
    return registry.getId(path) || path
  }, [])
  const data = useMemo(() => registry.getIds(), [])

  return (
    <IdContext.Provider
      value={{
        instance: registry,
        data,
        getId,
      }}
    >
      {children}
    </IdContext.Provider>
  )
}

IdRegistryProvider.propTypes = {
  children: PropTypes.node,
}

IdRegistryProvider.defaultProps = {
  children: null,
}

export default IdRegistryProvider
