import { ClStepper } from '@enreach/core-component-library-react'
import { defineCustomElementClStepper } from '@enreach/core-component-library/dist/components/index'

import { type StepperProps } from './stepper.types'

export function Stepper({
  activeSegments = 1,
  segments = 3,
  small = false,
  ...other
}: StepperProps): JSX.Element {
  return (
    <ClStepper
      activeSegments={activeSegments}
      segments={segments}
      small={small}
      {...other}
    />
  )
}

export default Stepper

defineCustomElementClStepper()
