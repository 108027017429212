import Task from './coligo-task'

export class ParallelTask extends Task {
  name = 'ParallelTask'

  /**
   * @type {Task[]}
   */
  tasks

  /**
   *
   */
  currentTask

  constructor(name, tasks) {
    super()

    this.name = name

    this.tasks = tasks
  }

  async run() {
    const taskResults = []

    for (const task of this.tasks) {
      logger.debug(
        `parallel group task ${this.name} running subtask ${task.name}`
      )
      taskResults.push(task.run())
    }

    const failures = []
    for (let i = 0; i < taskResults.length; i += 1) {
      try {
        await taskResults[i]
      } catch (err) {
        failures.push(`task ${this.tasks[i].name} failed: ${err.message}`)
      }
      logger.debug(
        `parallel group task ${this.name} completed subtask ${this.tasks[i].name}`
      )
    }
    if (failures.length > 0) {
      const failureMessage = failures.join(', ')
      throw new Error(
        `parallel group task ${this.name} failed for tasks: ${failureMessage}`
      )
    }
    logger.debug(`parallel group task ${this.name} completed`)
  }
}

export default ParallelTask
