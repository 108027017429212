export const STORE_NAME = 'feature/room'

export const VIEWS = {
  LOADING: 'LOADING',
  SETUP_HOST: 'SETUP_HOST',
  SETUP_GUEST: 'SETUP_GUEST',
  CONFERENCE: 'CONFERENCE',
  DISCONNECTED: 'DISCONNECTED',
}

/**
 * Collection of all actions
 *
 */
const INIT = `sm-web/${STORE_NAME}/INIT`
const JOINING_MEETING = `sm-web/${STORE_NAME}/JOINING_MEETING`
const JOINING_MEETING_ERROR = `sm-web/${STORE_NAME}/JOINING_MEETING_ERROR`
const JOINED_MEETING = `sm-web/${STORE_NAME}/JOINED_MEETING`
const SET_VIEW = `sm-web/${STORE_NAME}/SET_VIEW`
const SET_MEETING_ID = `sm-web/${STORE_NAME}/SET_MEETING_ID`
const SET_MEETING_IDENTIFIER = `sm-web/${STORE_NAME}/SET_MEETING_IDENTIFIER`
const UPDATE_MEETING_IDENTIFIER = `sm-web/${STORE_NAME}/UPDATE_MEETING_IDENTIFIER`
const GOTO_MEETING = `sm-web/${STORE_NAME}/GOTO_MEETING`
const CHANGE_MEETING = `sm-web/${STORE_NAME}/CHANGE_MEETING`

export const ACTION_TYPES = {
  INIT,
  JOINING_MEETING,
  JOINING_MEETING_ERROR,
  JOINED_MEETING,
  SET_VIEW,
  SET_MEETING_ID,
  SET_MEETING_IDENTIFIER,
  UPDATE_MEETING_IDENTIFIER,
  GOTO_MEETING,
  CHANGE_MEETING,
}

function joiningMeeting() {
  return { type: JOINING_MEETING }
}

function joiningMeetingError(error) {
  return {
    type: JOINING_MEETING_ERROR,
    payload: { error },
  }
}

function joinedMeeting() {
  return { type: JOINED_MEETING }
}

/**
 *
 * @param view
 * @param data
 * @return
 */
function setView(view, data) {
  return {
    type: SET_VIEW,
    payload: { view, data },
  }
}

/**
 * Set the view of the room to a loading state.
 * @return
 */
function setLoadingAsView() {
  return setView(VIEWS.LOADING, {})
}

/**
 * Set the view of the room to a view displaying the disconnected state.
 * @param reason
 * @param additionalData
 * @return
 */
function setDisconnectedAsView(reason, additionalData) {
  return setView(VIEWS.DISCONNECTED, { reason, additionalData })
}

/**
 * Set the guest setup as the view of the room.
 * @return
 */
function setGuestSetupAsView() {
  return setView(VIEWS.SETUP_GUEST, {})
}

/**
 * Set the host setup as the view of the room.
 * @return
 */
function setHostSetupAsView() {
  return setView(VIEWS.SETUP_HOST, {})
}

/**
 * Set the conference as the view of the room.
 * @return
 */
function setConferenceAsView() {
  return setView(VIEWS.CONFERENCE, {})
}

/**
 *
 * @param id
 * @return
 */
function setMeetingId(id) {
  return {
    type: SET_MEETING_ID,
    payload: { id },
  }
}

/**
 *
 * @param identifier
 * @return
 */
function setMeetingIdentifier(identifier) {
  return {
    type: SET_MEETING_IDENTIFIER,
    payload: { identifier },
  }
}

/**
 *
 * @param identifier
 * @return
 */
function updateMeetingIdentifier(identifier) {
  return {
    type: UPDATE_MEETING_IDENTIFIER,
    payload: { identifier },
  }
}

/**
 *
 * @param identifier
 * @return
 */
function gotoMeeting(identifier) {
  return {
    type: GOTO_MEETING,
    payload: { identifier },
  }
}

/**
 *
 * @param identifier
 * @param options
 * @param [options.action=undefined]
 * @return
 */
function changeMeeting(identifier, options = {}) {
  return {
    type: CHANGE_MEETING,
    payload: { identifier, action: options.action },
  }
}

export const actions = {
  joiningMeeting,
  joiningMeetingError,
  joinedMeeting,
  setView,
  setLoadingAsView,
  setDisconnectedAsView,
  setGuestSetupAsView,
  setHostSetupAsView,
  setConferenceAsView,
  setMeetingId,
  setMeetingIdentifier,
  updateMeetingIdentifier,
  gotoMeeting,
  changeMeeting,
}

/**
 * Initial side-panel state
 *
 * @property {string|null} view
 * @property {string|null} meetingId
 * @property {string|null} meetingIdentifier
 */
const INITIAL_STATE = {
  view: {
    type: VIEWS.LOADING,
    data: null,
  },
  meetingId: null,
  meetingIdentifier: null,
  state: null,
}

/**
 * Action handler
 * @type {{[p: string]: *}}
 */
const ACTION_HANDLERS = {
  [INIT]: (state, action) => ({
    ...state,
    meetingIdentifier: action.payload,
  }),
  [JOINING_MEETING]: (state) => ({
    ...state,
    state: JOINING_MEETING,
  }),
  [JOINING_MEETING_ERROR]: (state) => ({
    ...state,
    state: JOINING_MEETING_ERROR,
  }),
  [JOINED_MEETING]: (state) => ({
    ...state,
    state: JOINED_MEETING,
  }),
  [SET_VIEW]: (state, action) => ({
    ...state,
    view: {
      type: action.payload.view,
      data: action.payload.data,
    },
  }),
  [SET_MEETING_ID]: (state, action) => ({
    ...state,
    meetingId: action.payload.id,
  }),
  [SET_MEETING_IDENTIFIER]: (state, action) => ({
    ...state,
    meetingIdentifier: action.payload.identifier,
  }),
  [UPDATE_MEETING_IDENTIFIER]: (state, action) => ({
    ...state,
    meetingIdentifier: action.payload.identifier,
  }),
  [GOTO_MEETING]: (state, action) => ({
    ...state,
    meetingIdentifier: action.payload.identifier,
  }),
  [CHANGE_MEETING]: (state, action) => ({
    ...state,
    meetingIdentifier: action.payload.identifier,
  }),
  'sm-web/RESET': () => INITIAL_STATE,
}

/**
 * Exporting the reducer
 * @param state
 * @param action
 */
export default (state = INITIAL_STATE, action = {}) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export const selectors = {
  getView(state) {
    return state.view
  },
  getViewType(state) {
    const view = selectors.getView(state)
    return view.type
  },
  getViewData(state) {
    const view = selectors.getView(state)
    return view.data
  },
  getMeetingId(state) {
    return state.meetingId
  },
  getMeetingIdentifier(state) {
    return state.meetingIdentifier
  },
}
