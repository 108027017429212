/**
 * A
 * @param blob              [description]
 * @param name            [description]
 */
export default function downloadBlob(blob, name) {
  if (blob && blob instanceof Blob) {
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = window.URL.createObjectURL(blob)

    link.download = name

    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
  } else if (!blob) {
    throw new Error('There is no blob given')
  } else {
    throw new Error('Given blob is not an instanceof Blob')
  }
}
