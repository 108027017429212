import { useState } from 'react'
import { Backdrop } from '@mui/material'
import cn from 'clsx'

import env from 'app/config/env'
import FeatureFlag from 'ui/feature-flag'
import { FEATURE_NAMES } from 'constants/features-map'

import FeedbackSideButton from './user-feedback-side-button'
import classes from './user-feedback.module.scss'

export interface UserFeedbackProps {
  configId?: string
  position?: 'bottom' | 'middle'
}

export default function UserFeedback({
  configId = env('ideanote.configId'),
  position = 'bottom',
}: UserFeedbackProps) {
  const [open, setOpen] = useState(false)

  if (!configId) {
    return null
  }

  return (
    <FeatureFlag feature={FEATURE_NAMES.IDEANOTE}>
      <FeedbackSideButton
        open={open}
        position={position}
        onClick={() => setOpen(!open)}
      />

      <Backdrop
        className={classes.backdrop}
        open={open}
        onClick={() => setOpen(false)}
      >
        <iframe
          allow='fullscreen'
          className={cn(classes.feedback, classes[position])}
          name='idea-widget-frame'
          src={`https://idea-widget.ideanote.io?config=${configId}`}
          title='Idea Collection Widget'
        />
      </Backdrop>
    </FeatureFlag>
  )
}
