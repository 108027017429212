import dotProp from 'dot-prop-immutable'
import _ from 'lodash'

import localStore from 'app/services/state/local-store'
import { handleMessage, createStateLoggingActionHandler } from './utils'

import { getSocket } from '../socket'

const channelConf = {
  id: 'Summa.Meeting.Api.Conference',
  conf: { version: '1.2' },
}

export const STORE_NAME = 'channel/conference'

export type Conference = {
  code: string
  favorite: boolean
  id: string
  meta: {}
  name?: string
  type: string
}

/**
 * Map the names of all the conferences from the given list.
 * Makes the name of the conferences lowercase
 *
 * TODO: Figure out why this function is needed
 * @param conferenceList
 * @return
 */
function mapConferenceCodes(conferenceList) {
  return conferenceList.map((conf) => ({
    ...conf,
    name: conf.name ? conf.name.toLowerCase() : undefined,
  }))
}

/**
 * channel instance
 * @param
 */
let channel = null

// ------------------------------------
// Actions
// ------------------------------------
const JOINING = `sm-web/${STORE_NAME}/JOINING`
const JOINED = `sm-web/${STORE_NAME}/JOINED`
const JOINING_ERROR = `sm-web/${STORE_NAME}/JOINING_ERROR`
const JOINING_TIMEOUT = `sm-web/${STORE_NAME}/JOINING_TIMEOUT`
const ERROR = `sm-web/${STORE_NAME}/ERROR`
const CLOSING = `sm-web/${STORE_NAME}/CLOSING`
const CLOSED = `sm-web/${STORE_NAME}/CLOSED`
const CLOSING_ERROR = `sm-web/${STORE_NAME}/CLOSING_ERROR`
const CLOSING_TIMEOUT = `sm-web/${STORE_NAME}/CLOSING_TIMEOUT`
const LOG = `sm-web/${STORE_NAME}/LOG`
const CONFERENCE_SET = `sm-web/${STORE_NAME}/CONFERENCE_SET`
const UPDATE_CONFERENCE = `sm-web/${STORE_NAME}/UPDATE_CONFERENCE`
const CONFERENCE_MEMBER = `sm-web/${STORE_NAME}/CONFERENCE_MEMBER`

export const ACTION_TYPES = {
  JOINING,
  JOINED,
  JOINING_ERROR,
  JOINING_TIMEOUT,
  ERROR,
  CLOSING,
  CLOSED,
  CLOSING_ERROR,
  CLOSING_TIMEOUT,
  LOG,
  CONFERENCE_SET,
  UPDATE_CONFERENCE,
  CONFERENCE_MEMBER,
}

/**
 * @param payload
 */
const joined = (payload) => ({ type: JOINED, payload })
/**
 * @param error
 */
const joiningError = (error) => ({ type: JOINING_ERROR, error })
/**
 */
const joiningTimeout = () => ({ type: JOINING_TIMEOUT })
/**
 * @param e
 */
const error = (e) => ({ type: ERROR, error: e })
/**
 * @param payload
 */
const closed = (payload) => ({ type: CLOSED, payload })
/**
 * @param e
 */
const closingError = (e) => ({ type: CLOSING_ERROR, error: e })
/**
 */
const closingTimeout = () => ({ type: CLOSING_TIMEOUT })
/**
 */
const log = (event, payload) => (dispatch) => {
  if (localStore.getDebugMode()) {
    dispatch({ type: LOG, event, payload })
  }
}
/**
 * @param payload
 */
const setConference = (payload) => ({ type: CONFERENCE_SET, payload })
/**
 * @param payload
 */
const updateConference = (payload) => ({
  type: UPDATE_CONFERENCE,
  payload: {
    ...payload,
    name: payload.name ? payload.name.toLowerCase() : undefined,
  },
})

const conferenceMember = (payload) => ({
  type: CONFERENCE_MEMBER,
  payload,
})

/**
 * exported action to join channel
 */
const join = () => async (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: JOINING })
    const socket = getSocket()
    channel = socket.channel(channelConf.id, channelConf.conf)
    channel.onMessage = handleMessage(
      dispatch,
      channelEventActionMapping,
      channelConf,
      log
    )
    channel.onError((event) => dispatch(error(event)))
    channel.onClose((event) => dispatch(closed(event)))
    channel
      .join()
      .receive('ok', (payload) => {
        payload.conference_list = mapConferenceCodes(payload.conference_list)
        dispatch(setConference(payload))
        dispatch(joined(payload))
        resolve(payload)
      })
      .receive('error', (e) => {
        dispatch(joiningError(e))
        reject(new Error(JSON.stringify(e)))
      })
      .receive('timeout', () => {
        dispatch(joiningTimeout())
        reject(new Error('Timeout'))
      })
  })
/**
 * exported action to leave the channel
 */
const leave = () => async (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: CLOSING })
    if (channel) {
      channel
        .leave()
        .receive('ok', resolve)
        .receive('error', (e) => {
          dispatch(closingError(e))
          reject(new Error(JSON.stringify(e)))
        })
        .receive('timeout', () => {
          dispatch(closingTimeout())
          reject(new Error('Timeout'))
        })
    } else {
      resolve()
    }
  })

/**
 * update the conference data
 * @param conferenceId
 * @param meta
 * @return
 */
const conferenceMetaUpdate = async (conferenceId, meta) =>
  new Promise((resolve, reject) => {
    channel
      .push('update_conference', { meeting_id: conferenceId, meta })
      .receive('ok', resolve)
      .receive('error', (e) => {
        reject(new Error(`"update_conference": ${JSON.stringify(e)}`))
      })
      .receive('timeout', () => {
        reject(new Error('"update_conference": Timeout'))
      })
  })

/**
 * Update the conference name
 * @param meetingId
 * @param name
 * @returns {Promise<void>}
 */
const updateConferenceName = (meetingId, name) => async (dispatch) => {
  const response = await new Promise((resolve, reject) => {
    channel
      .push('update_conference_name', { meeting_id: meetingId, name })
      .receive('ok', resolve)
      .receive('error', (e) => {
        reject(new Error(`"update_conference_name": ${JSON.stringify(e)}`))
      })
      .receive('timeout', () => {
        reject(new Error('"update_conference_name": Timeout'))
      })
  })

  dispatch(updateConference(response))
}

/**
 * Generate a new code for the conference matching the given conferenceId.
 * After receiving an `ok` update the conference in the store.
 * @param conferenceId
 * @return
 */
const conferenceGenerateConferenceCode = (conferenceId) => async (dispatch) => {
  try {
    const response = await new Promise((resolve, reject) => {
      channel
        .push('generate_conference_code', { meeting_id: conferenceId })
        .receive('ok', resolve)
        .receive('error', (e) => {
          reject(new Error(`"generate_conference_code": ${JSON.stringify(e)}`))
        })
        .receive('timeout', () => {
          reject(new Error('"generate_conference_code": Timeout'))
        })
    })

    dispatch(updateConference(response))
  } catch (e) {
    logger.error(e)
  }
}

/**
 */
export const actions = {
  closed,
  join,
  leave,
  conferenceMetaUpdate,
  conferenceGenerateConferenceCode,
  updateConference,
  updateConferenceName,
}
// ------------------------------------
// Channel events
// ------------------------------------
const EVENT_CONFERENCE_MEMBER = 'conference_member'

const channelEventActionMapping = {
  [EVENT_CONFERENCE_MEMBER]: (payload) => conferenceMember(payload),
}
// ------------------------------------
// Reducer
// ------------------------------------
const INITIAL_STATE = {
  '@@channel': {
    state: CLOSED,
    log: [],
  },
  conference_list: [] as Conference[],
}

type ConferenceState = typeof INITIAL_STATE

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [JOINING]: createStateLoggingActionHandler(JOINING, 'JOINING'),
  [JOINED]: createStateLoggingActionHandler(JOINED, 'JOINED', 'payload'),
  [JOINING_ERROR]: createStateLoggingActionHandler(
    JOINING_ERROR,
    'JOINING_ERROR',
    'error'
  ),
  [JOINING_TIMEOUT]: createStateLoggingActionHandler(
    JOINING_TIMEOUT,
    'JOINING_TIMEOUT'
  ),
  [CLOSING]: createStateLoggingActionHandler(CLOSING, 'CLOSING'),
  [CLOSED]: createStateLoggingActionHandler(CLOSED, 'CLOSED'),
  [CLOSING_ERROR]: createStateLoggingActionHandler(
    CLOSING_ERROR,
    'CLOSING_ERROR',
    'error'
  ),
  [CLOSING_TIMEOUT]: createStateLoggingActionHandler(
    CLOSING_TIMEOUT,
    'CLOSING_TIMEOUT'
  ),
  [ERROR]: createStateLoggingActionHandler(ERROR, 'ERROR', 'error'),
  [LOG]: (state, action) =>
    dotProp.set(state, '@@channel.log', (l) => [
      ...l,
      {
        ts: Date.now(),
        kind: 'event',
        msg: action.event,
        data: action.payload,
      },
    ]),
  [CONFERENCE_SET]: (state, action) => ({ ...state, ...action.payload }),
  [UPDATE_CONFERENCE]: (state, action) => {
    const conferenceList = [...state.conference_list]
    const index = _.findIndex(conferenceList, {
      id: action.payload.id,
    })

    conferenceList[index] = action.payload

    return {
      ...state,
      conference_list: conferenceList,
    }
  },
  'sm-web/RESET': () => INITIAL_STATE,
}

export default (state = INITIAL_STATE, action = {}) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export const selectors = {
  /**
   */
  getChannelState(state: ConferenceState) {
    return _.get(state, '@@channel.state')
  },
  /**
   */
  isJoining(state: ConferenceState) {
    const channelState = selectors.getChannelState(state)
    return channelState === JOINING
  },
  /**
   */
  isJoiningError(state: ConferenceState) {
    const channelState = selectors.getChannelState(state)
    return channelState === JOINING_ERROR
  },
  /**
   */
  isJoiningTimeout(state: ConferenceState) {
    const channelState = selectors.getChannelState(state)
    return channelState === JOINING_TIMEOUT
  },
  /**
   */
  isJoined(state: ConferenceState) {
    const channelState = selectors.getChannelState(state)
    return channelState === JOINED
  },
  /**
   */
  isClosing(state: ConferenceState) {
    const channelState = selectors.getChannelState(state)
    return channelState === CLOSING
  },
  /**
   */
  isClosed(state: ConferenceState) {
    const channelState = selectors.getChannelState(state)
    return channelState === CLOSED
  },
  /**
   * Get all the current conferences from the state
   */
  getConferences(state: ConferenceState) {
    return _.get<Conference[]>(state, 'conference_list', undefined)
  },
  /**
   * Get one conference based on the conference ID from the state
   */
  getConference(state: ConferenceState, id) {
    const conferences = selectors.getConferences(state)
    return _.find(conferences, { id }) || undefined
  },
  /**
   * get conference by conference "room code"
   */
  getConferenceByCode(state: ConferenceState, code) {
    const conferences = selectors.getConferences(state)
    return _.find(conferences, { code }) || undefined
  },
  /**
   * get conference by conference "room name"
   */
  getConferenceByName(state: ConferenceState, name) {
    const conferences = selectors.getConferences(state)
    return _.find(conferences, { name })
  },
  /**
   * Get the first conference of all conferences from the state
   */
  getRoomConference(state: ConferenceState, options = {}) {
    if (options) {
      if (options.name) {
        return selectors.getConferenceByName(state, options.name)
      }
      if (options.code) {
        return selectors.getConferenceByCode(state, options.code)
      }
    }
    return selectors.getConferences(state)?.[0] || undefined
  },

  /**
   * Get the roomCode of one conference based on the conference ID from the state
   */
  getConferenceCode(state: ConferenceState, id) {
    const conference = selectors.getConference(state, id)
    return conference ? _.get(conference, 'code') : undefined
  },
  /**
   * Get the roomName of one conference based on the conference ID from the state
   */
  getConferenceName(state: ConferenceState, id) {
    const conference = selectors.getConference(state, id)
    return conference ? _.get(conference, 'name') : undefined
  },
}
