import { KeyBindingUtil, convertToRaw, getDefaultKeyBinding } from 'draft-js'
import _ from 'lodash'

/**
 * Get the first parent NOT having the `position: static` css rule.
 * @param element
 * @return
 */
export function getRelativeParent(element) {
  if (!element || !element.parentElement) return null

  const position = window
    .getComputedStyle(element.parentElement)
    .getPropertyValue('position')
  if (position !== 'static') return element.parentElement

  return getRelativeParent(element.parentElement)
}

/**
 * Calculate and return the position for the emoji suggestions.
 * @param decoratorRect - This is the position of the carot
 * @param relativeParent
 * @param popover
 * @return
 */
export function calculatePosition(decoratorRect, relativeParent, popover) {
  const relativeParentRect = relativeParent.getBoundingClientRect()

  const left = Math.min(
    decoratorRect.left - relativeParentRect.left + relativeParent.scrollLeft,
    relativeParent.scrollLeft + relativeParent.offsetWidth - popover.offsetWidth
  )
  const top = decoratorRect.top - relativeParentRect.top - popover.offsetHeight

  return { left, top }
}

/**
 * Get the position for the emoji suggestions and the relative parent.
 * @param decoratorRect
 * @param popover
 * @param state
 * @param filteredEmojis
 * @param
 * @return
 */
export function positionSuggestions({
  decoratorRect,
  popover,
  state,
  filteredEmojis,
}) {
  const relativeParent = getRelativeParent(popover)

  let transform

  if (state.isActive) transform = `scale(${+(filteredEmojis.size > 0)})`

  const position = calculatePosition(decoratorRect, relativeParent, popover)

  return {
    left: `${position.left}px`,
    top: `${position.top}px`,
    transform,
  }
}

/**
 * Check for needed actions based on the keyboard input
 * @param e
 * @return - Desired action based on given KeyboardEvent
 */
export function keyBindingFn(e) {
  if (
    e.key === 'Enter' &&
    !e.shiftKey &&
    !KeyBindingUtil.hasCommandModifier(e)
  ) {
    return 'submit-me'
  }
  return getDefaultKeyBinding(e)
}

/**
 * Convert the given EditorState to a string by joining all blocks with new lines.
 * @param editorState
 * @return
 */
export function convertEditorStateToString(editorState) {
  return convertToRaw(editorState.getCurrentContent())
    .blocks.map((b) => b.text.trim())
    .join('\n')
}

/**
 * Check if string contains spaces or whitelines
 * @param string
 * @return
 */

export function hasWhiteSpacesOnly(string) {
  const onlySpaces = /^ *$/
  const onlyNewLines = /^\n*$/

  return !!(string && (string.match(onlySpaces) || string.match(onlyNewLines)))
}

/**
 * Check if string is valid
 * @param string
 * @return
 */

export function checkStringValidity(string) {
  return hasWhiteSpacesOnly(string)
}

/**
 * Check string length and if limit is exceeded
 * @param string
 * @param limit
 * @return
 */
export function checkStringLengthAndLimit(string, limit) {
  const countSize = string ? _.toArray(string).length : 0
  const isLimitReached = countSize > limit

  return { isLimitReached, countSize }
}
