import { useTooltip, Action } from 'app/services/commands'
import { IconButton as UiIconButton, Tooltip } from 'ui/revision'

type Props = React.ComponentPropsWithRef<typeof UiIconButton> & {
  action?: Action
  actionState?: boolean | string | number
  position?: 'top' | 'bottom' | 'left' | 'right'
}

export const IconButton: React.FC<Props> = (props: Props) => {
  const { tooltipProps, otherProps } = useTooltip<typeof IconButton>(
    props,
    'onClick'
  )

  return tooltipProps ? (
    <Tooltip {...tooltipProps}>
      <UiIconButton {...otherProps} />
    </Tooltip>
  ) : (
    <UiIconButton {...otherProps} />
  )
}

export default IconButton
