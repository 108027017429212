import ChatInputMobileEmojiPicker from './chat-input-mobile-emoji-picker.component'

export default () => {
  const store = {
    getChatInput: undefined,
    setChatInput: undefined,
  }

  return {
    EmojiPicker: (props) => {
      return <ChatInputMobileEmojiPicker store={store} {...props} />
    },
    initialize: ({ getChatInput, setChatInput }) => {
      store.getChatInput = getChatInput
      store.setChatInput = setChatInput
    },
  }
}
