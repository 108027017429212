import { combineReducers } from 'redux'
import type { Action, AnyAction, Reducer, ReducersMapObject } from 'redux'

/**
 * A registry for Redux reducers, allowing features to register themselves
 * without needing to create additional inter-feature dependencies.
 */
class ReducerRegistry<S = any, A extends Action = AnyAction> {
  static create<S = any, A extends Action = AnyAction>(
    reducers: ReducersMapObject<S, A>
  ): ReducerRegistry<S, A> {
    return new ReducerRegistry(reducers)
  }

  /**
   * The set of registered reducers, keyed based on the field each reducer
   * will manage.
   * @private
   */
  private _elements: ReducersMapObject<S, A>

  constructor(elements: ReducersMapObject<S, A>) {
    this._elements = elements
  }

  /**
   * Combines all registered reducers into a single reducing function.
   * @returns
   */
  combineReducers() {
    return combineReducers(this._elements)
  }

  /**
   * Adds a reducer to the registry.
   * The method is to be invoked only before {@link #combineReducers()}.
   * @param name - The field in the state object that will be managed
   * by the provided reducer.
   * @param reducer - A Redux reducer.
   */
  register<K extends keyof S>(name: K, reducer: Reducer<S[K]>) {
    this._elements[name] = reducer
  }

  /**
   * Removes a reducer from the registry.
   * The method is to be invoked only before {@link #combineReducers()}.
   * @param name - The field in the state object
   */
  unregister(name: keyof S) {
    delete this._elements[name]
  }
}

export default ReducerRegistry
