import _ from 'lodash'

import {
  CHAT_CONTENT_TYPES,
  CHAT_COMMAND_TYPES,
  RIGHT_REQUESTING_STATE,
} from 'constants/constants'

import roomMediaManager from 'app/services/media/room-media-manager'
import roomMediaCommands from 'app/services/media/room-media-commands'

import {
  STORE_NAME as CHANNEL_ROOM_STORE_NAME,
  actions as channelRoomActions,
} from 'app/state/api/channels/channel-room.reducer'
import {
  STORE_NAME as CHANNEL_CONFERENCE_STORE_NAME,
  selectors as channelConferenceSelectors,
} from 'app/state/api/channels/channel-conference.reducer'

import { getHostId, getMe, getUser, isHost as isHostFn } from 'app/state/utils'
import {
  hostPredicate,
  screenSendRightPredicate,
  getMembers,
  offlinePredicate,
  requestingScreenSharePredicate,
} from 'app/state/utils/member-filters'

/**
 * @param type
 * @param from
 * @param additional
 */
export const createLocalSystemMessage = (
  type,
  from = undefined,
  additional = {}
) =>
  channelRoomActions.createLocalSystemMessage(
    { content: { type, ...additional } },
    '*',
    from
  )

/**
 * @param memberId
 */
export const createUserJoinsMessage = (memberId) =>
  createLocalSystemMessage(CHAT_CONTENT_TYPES.USER_IN, memberId, {
    userId: memberId,
  })

/**
 * @param memberId
 */
export const createUserLeavesMessage = (memberId) =>
  createLocalSystemMessage(CHAT_CONTENT_TYPES.USER_OUT, undefined, {
    userId: memberId,
  })

/**
 * @param memberId
 */
export const createUserKickedMessage = (memberId) =>
  createLocalSystemMessage(CHAT_CONTENT_TYPES.KICK_USER, undefined, {
    userId: memberId,
  })

/**
 * @param memberId
 */
export const createAcceptedScreenShareMessage = (memberId) =>
  createLocalSystemMessage(CHAT_CONTENT_TYPES.REQUEST_SS_ACCEPTED, memberId, {
    userId: memberId,
  })

/**
 * @param memberId
 */
export const createRequestScreenShareMessage = (memberId) =>
  createLocalSystemMessage(CHAT_CONTENT_TYPES.REQUEST_SS, memberId, {
    userId: memberId,
  })

/**
 * @param memberId
 */
export const createScreenShareAcceptedMessage = (memberId) =>
  createLocalSystemMessage(CHAT_CONTENT_TYPES.SS_ACCEPTED, memberId, {
    userId: memberId,
  })

/**
 * @param memberId
 */
export const createScreenShareRejectedMessage = (memberId) =>
  createLocalSystemMessage(CHAT_CONTENT_TYPES.SS_REJECTED, memberId, {
    userId: memberId,
  })

/**
 * Broadcast a message to the users in the room when local user starts/stops recording.
 * @param name
 * @return
 */
export const broadcastConferenceNameUpdateMessage =
  (name, code) => (dispatch, getState) => {
    const {
      [CHANNEL_ROOM_STORE_NAME]: channelRoom,
      [CHANNEL_CONFERENCE_STORE_NAME]: channelConference,
    } = getState()
    const hostId = getHostId(channelRoom)
    const conference = channelConferenceSelectors.getConference(
      channelConference,
      channelRoom.meeting_id
    )
    return dispatch(
      channelRoomActions.broadcastUserSysMessage(
        hostId,
        CHAT_COMMAND_TYPES.CONFERENCE_NAME_CHANGE,
        { name, code: code || conference.code }
      )
    )
  }

/**
 * Broadcast a message to the users in the room when local user starts/stops recording.
 * @param recording
 * @return
 */
export const broadcastRecordingMessage =
  (recording) => (dispatch, getState) => {
    const hostId = getHostId(getState()[CHANNEL_ROOM_STORE_NAME])
    return dispatch(
      channelRoomActions.broadcastUserSysMessage(
        hostId,
        CHAT_CONTENT_TYPES[recording ? 'START_RECORDING' : 'STOP_RECORDING']
      )
    )
  }

/**
 * Broadcast a message to the users in the room when local user starts/stops recording.
 * @param recording
 * @return
 */
export const broadcastStopScreenShareMessage = () => (dispatch, getState) => {
  const { [CHANNEL_ROOM_STORE_NAME]: channelRoom } = getState()
  return dispatch(
    channelRoomActions.broadcastUserSysMessage(
      channelRoom.member_id,
      CHAT_CONTENT_TYPES.STOP_SS,
      { userId: channelRoom.member_id }
    )
  )
}

/**
 * Broadcast a message to the users in the room when local user starts/stops recording.
 * @param memberId
 * @return
 */
export const broadcastHostStopScreenShareMessage =
  (memberId) => (dispatch, getState) => {
    const { [CHANNEL_ROOM_STORE_NAME]: channelRoom } = getState()
    const me = getMe(channelRoom)
    return dispatch(
      channelRoomActions.broadcastUserSysMessage(
        me.member_id,
        CHAT_CONTENT_TYPES.H_STOP_SS,
        { userId: memberId }
      )
    )
  }

/**
 * Creates a local system message about the host having started/stopped recording.
 * @param recording
 */
export const createRecordingMessage = (recording) => (dispatch, getState) => {
  const hostId = getHostId(getState()[CHANNEL_ROOM_STORE_NAME])
  dispatch(
    createLocalSystemMessage(
      CHAT_CONTENT_TYPES[recording ? 'START_RECORDING' : 'STOP_RECORDING'],
      hostId
    )
  )
}

export const createRoomVideoMutedMessage = () => (dispatch, getState) => {
  const hostId = getHostId(getState()[CHANNEL_ROOM_STORE_NAME])
  return dispatch(
    createLocalSystemMessage(CHAT_CONTENT_TYPES.ROOM_MUTE_VIDEO, hostId)
  )
}

export const createRoomAudioMutedMessage = () => (dispatch, getState) => {
  const hostId = getHostId(getState()[CHANNEL_ROOM_STORE_NAME])
  return dispatch(
    createLocalSystemMessage(CHAT_CONTENT_TYPES.ROOM_MUTE_AUDIO, hostId)
  )
}

/**
 * stop member screenshare
 * screenshare
 */
export const stopMemberScreenShare =
  (memberId) => async (dispatch, getState) => {
    logger.debug(`LocalVideoMedia.stopMemberScreenShare("${memberId}")`)
    const { [CHANNEL_ROOM_STORE_NAME]: channelRoom } = getState()
    if (!isHostFn(getMe(channelRoom))) {
      throw new Error('User is not host')
    }

    await dispatch(
      channelRoomActions.updateMemberRights(memberId, { screens_send: false })
    )
    await dispatch(broadcastHostStopScreenShareMessage(memberId))
  }

export const stopMembersScreenShare = () => async (dispatch, getState) => {
  logger.debug(`ChannelRoomReducer.stopScreenShare()`)
  const { [CHANNEL_ROOM_STORE_NAME]: channelRoom } = getState()
  return Promise.all(
    _.chain(getMembers(channelRoom))
      .reject(offlinePredicate)
      .reject(hostPredicate)
      .filter(screenSendRightPredicate)
      .map((member) => dispatch(stopMemberScreenShare(member.member_id)))
      .value()
  )
}

/**
 * stops the current user's screenshare
 */
async function stopMyScreenShare() {
  if (!roomMediaManager.trackManager.getLocalScreenTrack()) {
    throw new Error('User not screensharing')
  }
  return roomMediaCommands.stopSendScreen()
}

/**
 * Stop either local or other current broadcasting member screenshareproom
 */
export const stopScreenShare = () => {
  logger.debug(`ChannelRoomReducer.stopScreenShare()`)
  if (roomMediaManager.trackManager.getLocalScreenTrack()) {
    stopMyScreenShare()
  }
  return stopMembersScreenShare()
}

/**
 * Approve the request for `screens_send` rights for the member sharing the
 * given memberId as long as he is still requesting the rights.
 * Before given the `screens_send` rights we remove the rights from all
 * users having this right except for the host.
 * @param memberId
 */
export const approveScreenShareRights =
  (memberId) => async (dispatch, getState) => {
    const { [CHANNEL_ROOM_STORE_NAME]: channelRoom } = getState()
    const me = getMe(channelRoom)

    if (!isHostFn(me)) {
      throw new Error('Only host can accept screensharing request')
    }

    // cancel any current screenshare
    // FIXME: This can cause a race condition when there are more hosts. It will cancel an existing screenshare if both hosts press allow at the same time
    await dispatch(stopScreenShare())

    const member = getUser(channelRoom, memberId)

    /**
     * Only give the member the rights if the member is still requesting for the
     * `screens_send` rights.
     */
    if (_.isMatch(member, requestingScreenSharePredicate)) {
      await channelRoomActions.setMemberRights(memberId, {
        ...member.rights,
        screens_send: true,
      })
      await channelRoomActions.updateMemberMeta(memberId, {
        requestingScreenShareRights: RIGHT_REQUESTING_STATE.APPROVED,
      })
    }
  }

/**
 *
 * @param memberId
 */
export const rejectScreenShareRights = (memberId) => async () =>
  channelRoomActions.updateMemberMeta(memberId, {
    requestingScreenShareRights: RIGHT_REQUESTING_STATE.REJECTED,
  })

/**
 * Get the identifiers from the current room (as far as possible).
 * @param channelConference
 * @param meetingId
 * @return
 */
export function getRoomIdentifiers(channelConference, meetingId) {
  let name, code

  if (meetingId && channelConference?.conference_list?.length) {
    // For host we get the code and/or name from the conference out of the conference channel.
    code = channelConferenceSelectors.getConferenceCode(
      channelConference,
      meetingId
    )
    name = channelConferenceSelectors.getConferenceName(
      channelConference,
      meetingId
    )
  }

  return { name, code }
}

/**
 * Assign moderator rights for a member
 * TODO: move this to channelRoomActions
 */
export const assignModeratorRights = (memberId) => async (dispatch) =>
  dispatch(
    channelRoomActions.updateMemberRights(memberId, {
      room_moderator: true,
    })
  )

/**
 * Revoke moderator rights for a member
 * TODO: move this to channelRoomActions
 */
export const revokeModeratorRights = (memberId) => async (dispatch) =>
  dispatch(
    channelRoomActions.updateMemberRights(memberId, {
      room_moderator: false,
    })
  )
