import InputAdornment from '@mui/material/InputAdornment'
import { useEffect, useRef, useState } from 'react'

import { Icons } from 'constants/icons'
import IconButton from 'ui/icon-button'
import { Icon } from 'ui/revision'

import TextField from '../text-field'

type Props = {
  className?: string
  loginFailed?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  value?: string
}

export const PasswordField: React.FC<Props> = (props: Props) => {
  const [capsLock, setCapsLock] = useState(false)
  const [focused, setFocused] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const inputRef = useRef(null)

  useEffect(() => {
    function handleKeyPress(event) {
      if (event.key === 'CapsLock') {
        setCapsLock(event.getModifierState('CapsLock'))
      }
    }
    window.addEventListener('keydown', handleKeyPress)
    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [])

  function handleInputFocus() {
    setFocused(true)
  }

  function handleInputBlur() {
    setFocused(false)
  }

  function toggleShowPassword() {
    setShowPassword(!showPassword)
  }

  const { className, ...other } = props

  return (
    <TextField
      {...other}
      className={className}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton onClick={toggleShowPassword}>
              <Icon
                name={
                  showPassword
                    ? Icons.viewVisibilityOn
                    : Icons.viewVisibilityOff
                }
              />
            </IconButton>
          </InputAdornment>
        ),
      }}
      inputRef={inputRef}
      type={showPassword ? 'text' : 'password'}
      loginFailed={
        capsLock && focused
          ? t('LoginPage.UserForm.capsLock', 'Caps lock enabled')
          : props.loginFailed
      }
      onFocus={handleInputFocus}
      onBlur={handleInputBlur}
    />
  )
}

export default PasswordField
