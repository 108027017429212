import { v4 as uuid } from 'uuid'

export const STORE_NAME = 'feature/snackbar-queue'

export const AUTO_HIDE_DURATION_OPTIONS = {
  SHORT: 2000,
  MEDIUM: 4000,
  LONG: 6000,
}

/**
 * Collection of all actions
 *
 */
const INIT = `sm-web/${STORE_NAME}/INIT`
const ENQUEUE_SNACKBAR = `sm-web/${STORE_NAME}/ENQUEUE_SNACKBAR`
const DEQUEUE_SNACKBAR = `sm-web/${STORE_NAME}/DEQUEUE_SNACKBAR`
const CLOSE_SNACKBAR = `sm-web/${STORE_NAME}/CLOSE_SNACKBAR`
const SET_AUTO_HIDE_DURATION = `sm-web/${STORE_NAME}/SET_AUTO_HIDE_DURATION`
const REMOVE_NAME_FROM_SHOWN_SNACKBARS = `sm-web/${STORE_NAME}/REMOVE_NAME_FROM_SHOWN_SNACKBARS`

export const ACTION_TYPES = {
  INIT,
  ENQUEUE_SNACKBAR,
  DEQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  SET_AUTO_HIDE_DURATION,
  REMOVE_NAME_FROM_SHOWN_SNACKBARS,
}

const enqueueSnackbar =
  (props = {}, name = undefined) =>
  (dispatch) => {
    const id = uuid()
    dispatch({
      type: ENQUEUE_SNACKBAR,
      payload: {
        props: { ...props, close: false },
        id,
        name,
      },
    })
    return id
  }

const dequeueSnackbar = (payload) => ({ type: DEQUEUE_SNACKBAR, payload })

const closeSnackbar = (payload) => ({
  type: CLOSE_SNACKBAR,
  payload,
})

const setAutoHideDuration = (payload) => ({
  type: SET_AUTO_HIDE_DURATION,
  payload,
})

const removeNameFromShownSnackbars = (name) => ({
  type: REMOVE_NAME_FROM_SHOWN_SNACKBARS,
  payload: name,
})

/**
 * Exporting all actions
 */
export const actions = {
  enqueueSnackbar,
  dequeueSnackbar,
  closeSnackbar,
  setAutoHideDuration,
  removeNameFromShownSnackbars,
}

/**
 * Initial snackbar state
 */
export const INITIAL_STATE = {
  snackbars: {},
  snackbarIndices: [],
  autoHideDuration: AUTO_HIDE_DURATION_OPTIONS.MEDIUM,
  shownNamedSnackbars: [],
}

/**
 * Action handler
 */
const ACTION_HANDLERS = {
  [ENQUEUE_SNACKBAR]: (state, action) => {
    const nextState = { ...state }

    if (action.priority) {
      nextState.snackbarIndices = [action.payload.id, ...state.snackbarIndices]
    } else {
      nextState.snackbarIndices = [...state.snackbarIndices, action.payload.id]
    }

    nextState.snackbars = {
      ...state.snackbars,
      [action.payload.id]: {
        props: action.payload.props,
        id: action.payload.id,
      },
    }

    if (
      action.payload.name &&
      !nextState.shownNamedSnackbars.includes(action.payload.name)
    ) {
      nextState.shownNamedSnackbars = [
        ...nextState.shownNamedSnackbars,
        action.payload.name,
      ]
    }

    return nextState
  },
  [DEQUEUE_SNACKBAR]: (state, action) => {
    let { snackbarIndices } = state

    if (!action.payload) {
      snackbarIndices = snackbarIndices.slice(1, snackbarIndices.length)
    } else {
      const index = snackbarIndices.indexOf(action.payload)
      snackbarIndices = [
        ...snackbarIndices.slice(0, index),
        ...snackbarIndices.slice(index + 1, snackbarIndices.length),
      ]
    }

    return {
      ...state,
      snackbarIndices,
      snackbars: snackbarIndices.reduce(
        (acc, key) => ({ ...acc, [key]: state.snackbars[key] }),
        {}
      ),
    }
  },
  [CLOSE_SNACKBAR]: (state, action) => {
    if (!state.snackbars[action.payload]) return state

    const snackbar = { ...state.snackbars[action.payload] }

    if (!snackbar.props) snackbar.props = { close: true }
    else snackbar.props.close = true

    return {
      ...state,
      snackbars: { ...state.snackbars, [action.payload]: snackbar },
    }
  },
  [SET_AUTO_HIDE_DURATION]: (state, action) => ({
    ...state,
    autoHideDuration: action.payload,
  }),
  [REMOVE_NAME_FROM_SHOWN_SNACKBARS]: (state, action) => ({
    ...state,
    shownNamedSnackbars: state.shownNamedSnackbars.filter(
      (name) => name !== action.payload
    ),
  }),
}

/**
 * Exporting the reducer
 */
export default (state = INITIAL_STATE, action = {}) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

export const selectors = {
  /**
   *
   * @param state
   * @returns
   */
  getSnackbars(state) {
    return state.snackbars
  },
  /**
   *
   * @param state
   * @returns {string[]}
   */
  getSnackbarIndices(state) {
    return state.snackbarIndices
  },
  /**
   *
   * @param state
   * @returns {string[]}
   */
  getShownNamedSnackbars(state) {
    return state.shownNamedSnackbars
  },
}
