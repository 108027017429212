import type { SVGProps } from 'react'
const SvgAgile5NoCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...props}>
    <circle cx={82.17} cy={78.48} r={62} fill='rgb(var(--color-brand-50))' />
    <path
      fill='rgb(var(--color-neutral-50))'
      d='m69.11 55.19-7.42 4.16-3.24 5.8 10.89 12.51-12.28 14.59 8.75 9.83 3.3 1.29L82.1 91.31l10.77 10.77 3.57.59 10.66-9.96L94.2 79.2l11.51-13.36-.73-2.93-9.46-8.65L82.1 67.1 69.11 55.19z'
    />
    <path
      fill='rgb(var(--color-brand-400))'
      d='M95.63 105.22c-1.58 0-3.06-.61-4.17-1.73l-9.36-9.36-9.36 9.36c-1.12 1.12-2.6 1.73-4.17 1.73s-3.06-.61-4.17-1.73l-6.58-6.58c-2.3-2.3-2.3-6.05 0-8.35l9.36-9.36-9.36-9.36c-1.12-1.12-1.73-2.6-1.73-4.17s.61-3.06 1.73-4.17l6.58-6.58c1.12-1.12 2.6-1.73 4.17-1.73s3.06.61 4.17 1.73l9.36 9.36 9.36-9.36c1.12-1.12 2.6-1.73 4.17-1.73s3.06.61 4.17 1.73l6.58 6.58c1.12 1.12 1.73 2.6 1.73 4.17s-.61 3.06-1.73 4.17l-9.36 9.36 2.24 2.24c.78.78.78 2.05 0 2.83s-2.05.78-2.83 0l-5.07-5.07 12.18-12.18c.74-.74.74-1.95 0-2.69l-6.58-6.58c-.74-.74-1.95-.74-2.69 0L82.09 69.93 69.91 57.75c-.74-.74-1.95-.74-2.69 0l-6.58 6.58c-.74.74-.74 1.95 0 2.69L72.82 79.2 60.64 91.38c-.74.74-.74 1.95 0 2.69l6.58 6.58c.74.74 1.95.74 2.69 0l12.18-12.18 12.18 12.18c.74.74 1.95.74 2.69 0l6.58-6.58c.74-.74.74-1.95 0-2.69l-1.44-1.44c-.78-.78-.78-2.05 0-2.83.78-.78 2.05-.78 2.83 0l1.44 1.44c2.3 2.3 2.3 6.05 0 8.35l-6.58 6.58a5.855 5.855 0 0 1-4.17 1.73z'
    />
  </svg>
)
export default SvgAgile5NoCheck
