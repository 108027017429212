export { default as getUserInitials } from './get-user-initials'

export function getUrlsFromText(text) {
  return /(https?:\/\/[^\s]+)/g.exec(text);
}

/**
 * Clamps the given amount between the given min and max.
 * @param amount - Number you wish to be clamped
 * @param min - Minimum amount
 * @param max - Maximum amount
 * @return
 */
export const clamp = (amount, min, max) => Math.max(Math.min(amount, max), min)
