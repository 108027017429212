export type Command<P = any, R = any> = (payload: P) => R

/**
 * The CommandsRegistry is used to maintain a list of executable functions (commands)
 * indexed by a command string to identify the commands
 */
class CommandsRegistry {
  commands: Record<string, Command> = {}

  disabledCommands: string[] = []

  /**
   * add new command to registry
   * @param commandName
   * @param commandFunction
   */
  add(commandName: string, commandFunction: Command) {
    if (!commandName) {
      throw new TypeError(
        `invalid parameter commandName, received "${commandName}" expected String`
      )
    }

    if (!commandFunction) {
      throw new TypeError(
        `invalid parameter commandName, received "${commandFunction}" expected Function`
      )
    }

    logger.debug(
      `CommandsRegistry::add() adding command: "${commandName}"`,
      commandFunction
    )

    this.commands[commandName] = commandFunction
  }

  /**
   * remove command function from collection
   * @param commandName
   */
  remove(commandName: string) {
    logger.debug(`CommandsRegistry::remove() remove command "${commandName}"`)

    if (this.commands[commandName]) {
      delete this.commands[commandName]
    }

    if (this.disabledCommands.includes(commandName)) {
      this.disabledCommands.splice(this.disabledCommands.indexOf(commandName))
    }
  }

  /**
   * get command function from collection
   * @param commandName
   */
  get(commandName: string): Command | undefined {
    logger.debug(`CommandsRegistry::get() getting command "${commandName}"`)

    if (this.disabledCommands.includes(commandName)) {
      logger.debug(
        `CommandsRegistry::get() requested command "${commandName}" is disabled`
      )
      return undefined
    }

    return this.commands[commandName]
  }

  /**
   * Disable a specific command
   * @param commandName
   */
  disableCommand(commandName: string) {
    if (!this.disabledCommands.includes(commandName)) {
      logger.debug(
        `CommandsRegistry::disableCommand() disabling command "${commandName}"`
      )
      this.disabledCommands.push(commandName)
    }
  }

  /**
   * Enable a specific command
   * @param commandName
   */
  enableCommand(commandName: string) {
    if (this.disabledCommands.includes(commandName)) {
      logger.debug(
        `CommandsRegistry::enableCommand() enabling command "${commandName}"`
      )
      this.disabledCommands.splice(this.disabledCommands.indexOf(commandName))
    }
  }
}

export default new CommandsRegistry()
