import PropTypes from 'prop-types'

import { useId } from 'app/react/hooks/id-registry.hooks'

import Button from 'ui/revision/button'
import Dialog, { DialogActions, DialogContent } from 'ui/revision/dialog'

const ErrorDialog = ({ buttonText, onHide, children, ...other }) => {
  const { id } = useId()

  return (
    <Dialog {...other}>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          color='primary'
          data-testid={id('Dialogs.Error.submit')}
          type='submit'
          onClick={onHide}
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ErrorDialog.propTypes = {
  ...Dialog.propTypes,
  buttonText: PropTypes.string,

  onHide: PropTypes.func,
}

ErrorDialog.defaultProps = {
  ...Dialog.defaultProps,
  buttonText: 'Close',

  onHide: Function.prototype,
}

export default ErrorDialog
