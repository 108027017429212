import { BehaviorSubject } from 'rxjs'

import ColigoListener from 'lib/rtc/coligo-listener'
import env from 'app/config/env'

import { actions } from 'app/state/api/channels/channel-room.reducer'

import urlUtils from 'lib/url-utils'

import ROOM_SERVICES from './room-services'
import Connection from './connection'

class ConnectionManager {
  connections = {
    [ROOM_SERVICES.ROOM_AUDIO]: null,
    [ROOM_SERVICES.ROOM_VIDEO]: null,
    [ROOM_SERVICES.ROOM_THUMBNAIL]: null,
    [ROOM_SERVICES.ROOM_SCREEN]: null,
  }

  /**
   * @type {{ [key: string]: BehaviorSubject<{ [key: string]: Connection | null }> }}
   */
  memberConnections$ = {
    [ROOM_SERVICES.EXTERNAL_VIDEO]: new BehaviorSubject({}),
    [ROOM_SERVICES.EXTERNAL_THUMBNAIL]: new BehaviorSubject({}),
    [ROOM_SERVICES.EXTERNAL_SCREEN]: new BehaviorSubject({}),
  }

  publishQualities = {
    [ROOM_SERVICES.ROOM_VIDEO]: env('room_video_bitrates.h', 768000),
    [ROOM_SERVICES.ROOM_THUMBNAIL]: env('room_video_bitrates.l', 128000),
    [ROOM_SERVICES.ROOM_SCREEN]: env('room_video_bitrates.h', 768000),
  }

  remoteTrackUsages = {
    [ROOM_SERVICES.EXTERNAL_VIDEO]: {},
    [ROOM_SERVICES.EXTERNAL_THUMBNAIL]: {},
    [ROOM_SERVICES.EXTERNAL_SCREEN]: {},
  }

  remoteTrackRemoveTimers = {
    [ROOM_SERVICES.EXTERNAL_VIDEO]: {},
    [ROOM_SERVICES.EXTERNAL_THUMBNAIL]: {},
    [ROOM_SERVICES.EXTERNAL_SCREEN]: {},
  }

  apiActions = {
    [ROOM_SERVICES.ROOM_AUDIO]: {
      sendCandidate: actions.sendAudioIceCandidate,
      sendOffer: actions.sendAudioOffer,
      sendStop: actions.sendAudioStop,
    },
    [ROOM_SERVICES.ROOM_VIDEO]: {
      sendCandidate: actions.sendVideoIceCandidate,
      sendOffer: actions.sendVideoOffer,
      sendStop: actions.sendVideoStop,
    },
    [ROOM_SERVICES.ROOM_THUMBNAIL]: {
      sendCandidate: actions.sendThumbnailsIceCandidate,
      sendOffer: actions.sendThumbnailVideoOffer,
      sendStop: actions.sendThumbnailVideoStop,
    },
    [ROOM_SERVICES.ROOM_SCREEN]: {
      sendCandidate: actions.sendScreensIceCandidate,
      sendOffer: actions.sendScreenVideoOffer,
      sendStop: actions.sendScreenVideoStop,
    },
    [ROOM_SERVICES.EXTERNAL_VIDEO]: {
      createOffer: actions.requestVideoOfferForMember,
      sendStop: actions.sendMemberVideoReceiveStop,
      sendCandidate: actions.sendVideoMemberIceCandidate,
      sendAnswer: actions.sendVideoAnswerForMember,
    },
    [ROOM_SERVICES.EXTERNAL_THUMBNAIL]: {
      createOffer: actions.requestThumbnailVideoOfferForMember,
      sendStop: actions.sendMemberThumbnailVideoReceiveStop,
      sendCandidate: actions.sendThumbnailsMemberIceCandidate,
      sendAnswer: actions.sendThumbnailVideoAnswerForMember,
    },
    [ROOM_SERVICES.EXTERNAL_SCREEN]: {
      createOffer: actions.requestScreenVideoOfferForMember,
      sendStop: actions.sendMemberScreenVideoReceiveStop,
      sendCandidate: actions.sendScreensMemberIceCandidate,
      sendAnswer: actions.sendScreenVideoAnswerForMember,
    },
  }

  listener = new ColigoListener()

  /**
   * @param event
   * @param listener
   */
  addListener(event, listener) {
    this.listener.addListener(event, listener)
  }

  /**
   * @param event
   * @param listener
   */
  removeListener(event, listener) {
    this.listener.removeListener(event, listener)
  }

  /**
   * @param service
   * @param maxBitrate
   */
  setMaxBitrate(service, maxBitrate) {
    if (!service) return
    if (!maxBitrate) return
    const connection = this.connections[service]
    if (connection) {
      connection.setMaxBitrate(maxBitrate)
    }
  }

  /**
   * @param maxBitrate
   */
  setAudioMaxBitrate(maxBitrate) {
    if (!maxBitrate) return
    this.setMaxBitrate(ROOM_SERVICES.ROOM_AUDIO, maxBitrate)
  }

  /**
   * @param maxBitrate
   */
  setVideoMaxBitrate(maxBitrate) {
    if (!maxBitrate) return
    this.setMaxBitrate(ROOM_SERVICES.ROOM_VIDEO, maxBitrate)
  }

  /**
   * @param maxBitrate
   */
  setThumbnailMaxBitrate(maxBitrate) {
    if (!maxBitrate) return
    this.setMaxBitrate(ROOM_SERVICES.ROOM_THUMBNAIL, maxBitrate)
  }

  /**
   * @param maxBitrate
   */
  setScreenMaxBitrate(maxBitrate) {
    if (!maxBitrate) return
    this.setMaxBitrate(ROOM_SERVICES.ROOM_SCREEN, maxBitrate)
  }

  /**
   * @param connection
   * @return
   */
  isConnected(connection) {
    return (
      connection._rtcPeerConnection.iceConnectionState === 'connected' ||
      connection._rtcPeerConnection.iceConnectionState === 'completed'
    )
  }

  /**
   * @param connection
   * @return
   */
  isConnecting(connection) {
    return (
      connection._rtcPeerConnection.iceConnectionState === 'checking' ||
      connection._rtcPeerConnection.iceConnectionState === 'new'
    )
  }

  /**
   * @return
   */
  getConnectionConfig() {
    const config = env('connection_config') || {}
    const urlParams = urlUtils.getUrlParams()
    if (urlParams.iceTransportPolicy) {
      config.iceTransportPolicy = urlParams.iceTransportPolicy
    }
    if (urlParams.iceCandidatePoolSize) {
      config.iceCandidatePoolSize = urlParams.iceCandidatePoolSize
    }
    return config
  }

  /**
   * @param service
   * @param transceivers
   * @return
   */
  createPublishingConnection(service, transceivers) {
    if (!service) return null
    if (!transceivers) return null
    const connection = new Connection(
      {
        sendCandidate: this.apiActions[service].sendCandidate,
        sendOffer: this.apiActions[service].sendOffer,
      },
      {
        pcConfiguration: this.getConnectionConfig(),
        disableMDNSCandidates: true,
        transceivers,
      }
    )
    this.connections[service] = connection
    this.listener.emit('connectionAdded', { service, connection })
    return connection
  }

  /**
   * @param service
   * @param sendScreensMemberIceCandidate
   * @return
   */
  createReceivingConnection(service, memberId) {
    if (!service) return null
    if (!memberId) return null
    const connection = new Connection(
      {
        sendCandidate: async (candidate) =>
          this.apiActions[service].sendCandidate(candidate, memberId),
        sendAnswer: async (answer) =>
          this.apiActions[service].sendAnswer(answer, memberId),
      },
      {
        pcConfiguration: this.getConnectionConfig(),
        disableMDNSCandidates: true,
      }
    )
    this.memberConnections$[service].next({
      ...this.memberConnections$[service].getValue(),
      [memberId]: connection,
    })

    return connection
  }

  /**
   * @param service
   * @param track
   * @return
   */
  async publishTrack(service, track) {
    if (!service) return
    if (!track) return
    let connection = this.connections[service]
    if (!connection) {
      connection = this.createPublishingConnection(service, [
        {
          direction: 'sendonly',
          trackOrKind: track.getTrack(),
          sendEncodings: [{ maxBitrate: this.publishQualities[service] }],
        },
      ])
      await connection.init()
    } else {
      connection.replaceTrack(connection.localTracks[0] || null, track)
    }
  }

  /**
   * @param track
   * @return
   */
  publishVideoTrack(track) {
    this.publishTrack(ROOM_SERVICES.ROOM_VIDEO, track)
  }

  /**
   * @param track
   * @return
   */
  publishThumbnailTrack(track) {
    this.publishTrack(ROOM_SERVICES.ROOM_THUMBNAIL, track)
  }

  /**
   * @param track
   * @return
   */
  publishScreenTrack(track) {
    this.publishTrack(ROOM_SERVICES.ROOM_SCREEN, track)
  }

  /**
   * @param service
   * @return
   */
  async unpublishTrack(service) {
    if (!service) return
    const connection = this.connections[service]
    if (!connection) return
    if (!connection.localTracks.length) return

    connection.removeTrack(connection.localTracks[0])
  }

  /**
   * @param track
   * @return
   */
  unpublishVideoTrack() {
    this.unpublishTrack(ROOM_SERVICES.ROOM_VIDEO)
  }

  /**
   * @param track
   * @return
   */
  unpublishThumbnailTrack() {
    this.unpublishTrack(ROOM_SERVICES.ROOM_THUMBNAIL)
  }

  /**
   * @param track
   * @return
   */
  unpublishScreenTrack() {
    this.unpublishTrack(ROOM_SERVICES.ROOM_SCREEN)
  }

  /**
   * Function starts a connection which will receive the given service of
   * the given member.
   * @param service
   * @param memberId
   * @return
   */
  async startReceivingConnection(service, memberId) {
    if (!service) return null
    if (!memberId) return null

    let connection = this.memberConnections$[service].getValue()[memberId]
    /**
     * When there was a connection before and it was either still connecting or it
     * was already connected, we don't wan't to create a new connection for receiving
     * the track of the given member.
     */
    if (
      !connection ||
      (!this.isConnected(connection) && !this.isConnecting(connection))
    ) {
      /**
       * When it creates a new connection but there was still a connection lingering around in an
       * unusable state, we close the old connection to make sure that it is properly garbage collected.
       */
      if (connection) {
        await this.closeReceivingConnection(service, memberId)
      }
      connection = this.createReceivingConnection(service, memberId)
      // Request an offer from the server for receiving the service of the given member.
      const offer = await this.apiActions[service].createOffer(memberId)
      connection.setRemoteOffer(offer)
      await connection.init()
    }
    return connection
  }

  /**
   * @param memberId
   * @return
   */
  startReceivingVideoConnection(memberId) {
    this.startReceivingConnection(ROOM_SERVICES.ROOM_VIDEO, memberId)
  }

  /**
   * @param memberId
   * @return
   */
  startReceivingThumbnailConnection(memberId) {
    this.startReceivingConnection(ROOM_SERVICES.ROOM_THUMBNAIL, memberId)
  }

  /**
   * @param memberId
   * @return
   */
  startReceivingScreenConnection(memberId) {
    this.startReceivingConnection(ROOM_SERVICES.ROOM_SCREEN, memberId)
  }

  /**
   * @param service
   * @param memberId
   * @return
   */
  async initialiseAudioConnection(track = null) {
    let connection = this.connections[ROOM_SERVICES.ROOM_AUDIO]
    let shouldCreateConnection = true
    if (connection) {
      const isCurrentlyConnected = this.isConnected(connection)
      const isCurrentlyConnecting = this.isConnecting(connection)
      if (isCurrentlyConnected || isCurrentlyConnecting) {
        shouldCreateConnection = false
      }
    }
    if (shouldCreateConnection) {
      if (connection) {
        await this.closeAudioConnection()
      }
      connection = this.createPublishingConnection(ROOM_SERVICES.ROOM_AUDIO, [
        {
          direction: 'sendrecv',
          trackOrKind: track ? track.getTrack() : 'audio',
        },
      ])
      await connection.init()
      this.connections[ROOM_SERVICES.ROOM_AUDIO] = connection
    }
    return connection
  }

  /**
   * @param track
   * @return
   */
  async publishAudioTrack(track) {
    if (track) {
      const connection = this.connections[ROOM_SERVICES.ROOM_AUDIO]
      if (!connection) throw new Error('No connection for "audio"')
      connection.replaceTrack(connection.localTracks[0] || null, track)
    }
  }

  /**
   * @return
   */
  unpublishAudioTrack() {
    const connection = this.connections[ROOM_SERVICES.ROOM_AUDIO]
    if (!connection) return
    if (!connection.localTracks.length) return
    connection.removeTrack(connection.localTracks[0])
  }

  /**
   * @return
   */
  async closeAudioConnection() {
    if (this.connections[ROOM_SERVICES.ROOM_AUDIO]) {
      this.connections[ROOM_SERVICES.ROOM_AUDIO].close()
      this.connections[ROOM_SERVICES.ROOM_AUDIO] = null
    }
  }

  /**
   * @param service
   * @return
   */
  async closePublishingConnection(service) {
    logger.debug(`RoomConnectionManager::closePublishingConnection(${service})`)
    if (service && this.connections[service]) {
      this.connections[service].close()
      this.connections[service] = null
      await this.apiActions[service].sendStop()
    }
  }

  /**
   * @return
   */
  async closePublishingVideoConnection() {
    return this.closePublishingConnection(ROOM_SERVICES.ROOM_VIDEO)
  }

  /**
   * @return
   */
  async closePublishingThumbnailConnection() {
    return this.closePublishingConnection(ROOM_SERVICES.ROOM_THUMBNAIL)
  }

  /**
   * @return
   */
  async closePublishingScreenConnection() {
    return this.closePublishingConnection(ROOM_SERVICES.ROOM_SCREEN)
  }

  /**
   * @param service
   * @param memberId
   * @return
   */
  async closeReceivingConnection(service, memberId) {
    if (!service) return
    if (!memberId) return
    if (!this.memberConnections$[service]) return
    if (!this.memberConnections$[service].getValue()[memberId]) return
    const memberConnections = this.memberConnections$[service].getValue()
    memberConnections[memberId].close()
    const clonedMemberConnections = { ...memberConnections }
    delete clonedMemberConnections[memberId]
    this.memberConnections$[service].next(clonedMemberConnections)
    // ignore errors here due to signaling socket unavailble
    await this.apiActions[service]?.sendStop(memberId).catch(Function.prototype)
  }

  /**
   * @param memberId
   * @return
   */
  async closeReceivingVideoConnection(memberId) {
    return this.closeReceivingConnection(ROOM_SERVICES.EXTERNAL_VIDEO, memberId)
  }

  /**
   * @param memberId
   * @return
   */
  async closeReceivingThumbnailConnection(memberId) {
    return this.closeReceivingConnection(
      ROOM_SERVICES.EXTERNAL_THUMBNAIL,
      memberId
    )
  }

  /**
   * @param memberId
   * @return
   */
  async closeReceivingScreenConnection(memberId) {
    return this.closeReceivingConnection(
      ROOM_SERVICES.EXTERNAL_SCREEN,
      memberId
    )
  }

  removeTrackRemoveTimer(service, memberId) {
    if (
      this.remoteTrackUsages[service][memberId] > 0 &&
      this.remoteTrackRemoveTimers[service][memberId]
    ) {
      window.clearTimeout(this.remoteTrackRemoveTimers[service][memberId])
      delete this.remoteTrackRemoveTimers[service][memberId]
    }
  }

  async createTrackRemoveTimer(service, memberId) {
    return new Promise((resolve, reject) => {
      this.remoteTrackRemoveTimers[service][memberId] = window.setTimeout(
        () => {
          logger.debug(
            `RoomConnectionManager::removeRemoteTrackUsage::timer hit, removing remote track service: ${service}, memId: ${memberId}`
          )
          delete this.remoteTrackRemoveTimers[service][memberId]
          this.closeReceivingConnection(service, memberId)
            .then(resolve)
            .catch((e) => {
              /**
               * we ignore timeout here because signaling might fail in case the roomconnection is closed
               */
              if (e.message?.includes('Timeout')) {
                logger.debug(
                  `RoomConnectionManager::removeRemoteTrackUsage(${service},${memberId}): ${e.message}`
                )
                resolve()
              } else {
                reject(e)
              }
            })
        },
        1500
      )
    })
  }

  /**
   *
   * @param service
   * @param memberId
   * @returns {Promise<void>}
   */
  async addRemoteTrackUsage(service, memberId) {
    if (!service) return
    if (!memberId) return
    this.remoteTrackUsages[service][memberId] =
      (this.remoteTrackUsages[service][memberId] || 0) + 1

    // remove any trackremove timer
    this.removeTrackRemoveTimer(service, memberId)

    await this.startReceivingConnection(service, memberId)
  }

  /**
   * @returns {Promise<void>}
   */
  async removeRemoteTrackUsage(service, memberId) {
    this.remoteTrackUsages[service][memberId] =
      (this.remoteTrackUsages[service][memberId] || 0) - 1
    if (
      this.remoteTrackUsages[service][memberId] <= 0 &&
      !this.remoteTrackRemoveTimers[service][memberId]
    ) {
      await this.createTrackRemoveTimer(service, memberId)
    }
  }
}

export default new ConnectionManager()
