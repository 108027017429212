import { BehaviorSubject, from, combineLatest } from 'rxjs'
import { filter, withLatestFrom } from 'rxjs/operators'

import ColigoTrackProcessor from 'lib/rtc/coligo-track-processor'
import coligoTrackFactory from 'lib/rtc/coligo-track-factory'

import backgroundEffectSingleton from './background-effect-singleton'

class BackgroundEffectProcessor extends ColigoTrackProcessor {
  /**
   * @type {BehaviorSubject<MediaStreamEffect | null>}
   * @private
   */
  _mediaStreamEffectSubject = new BehaviorSubject(null)

  /**
   * @type {BehaviorSubject<Object | undefined>}
   * @private
   */
  _drawingStrategySubject

  /**
   * @type {BehaviorSubject<Object | undefined>}
   * @private
   */
  _drawingConfigSubject

  /**
   * @type {BehaviorSubject<Object | undefined>}
   * @private
   */
  _frameProcessingStrategySubject

  /**
   *
   * @private
   */
  _originalConstraints = null

  /**
   *
   * @private
   */
  _constraints

  /**
   * Constraints applied to the track given
   *
   */
  get constraints() {
    return this._constraints
  }

  /**
   * Returns true when the required parts of the processor has been initialised
   * for processing.
   *
   * @public
   */
  get initialised() {
    return (
      this._mediaStreamEffectSubject &&
      this._mediaStreamEffectSubject.value &&
      !this._mediaStreamEffectSubject.value.disposed
    )
  }

  /**
   * @param [enabled=false]
   * @param]
   * @param]
   * @param [config.frameProcessingStrategy=undefined]
   * @param [config.drawingStrategy=undefined]
   */
  constructor(enabled = false, config = {}) {
    super(enabled, [])

    this._constraints = config.constraints || {}

    this._drawingStrategySubject = new BehaviorSubject(
      config.frameProcessingStrategy || undefined
    )
    this._coreSubscriptions.push(
      this._drawingStrategySubject
        .pipe(
          withLatestFrom(this._mediaStreamEffectSubject),
          filter((val) => !val.some((v) => !v))
        )
        .subscribe(([strategy, effect]) => {
          effect.setDrawingStrategy(strategy)
        })
    )

    this._drawingConfigSubject = new BehaviorSubject(
      config.drawingStrategy || undefined
    )
    this._coreSubscriptions.push(
      this._drawingConfigSubject
        .pipe(
          withLatestFrom(this._mediaStreamEffectSubject),
          filter((val) => !val.some((v) => !v))
        )
        .subscribe(([drawingConfig, effect]) => {
          effect.setDrawingConfig(drawingConfig)
        })
    )

    this._frameProcessingStrategySubject = new BehaviorSubject(
      config.drawingConfig || undefined
    )
    this._coreSubscriptions.push(
      this._frameProcessingStrategySubject
        .pipe(
          withLatestFrom(this._mediaStreamEffectSubject),
          filter((val) => !val.some((v) => !v))
        )
        .subscribe(([frameProcessingStrategy, effect]) => {
          effect.setFrameProcessingStrategy(frameProcessingStrategy)
        })
    )
  }

  /**
   * @param drawingStrategy
   * @returns
   * @public
   */
  setDrawingStrategy(drawingStrategy) {
    this._drawingStrategySubject.next(drawingStrategy)
  }

  /**
   * @param drawingConfig
   * @returns
   * @public
   */
  setDrawingConfig(drawingConfig) {
    this._drawingConfigSubject.next(drawingConfig)
  }

  /**
   * @param strategy
   * @returns
   * @public
   */
  setFrameProcessingStrategy(frameProcessingStrategy) {
    this._frameProcessingStrategySubject.next(frameProcessingStrategy)
  }

  _apply() {
    this._processingSubscriptions.push(
      // When the effect is applied we connect the source (in$)
      // to the currently initialised MediaStreamEffect.
      combineLatest([this.in$, this._mediaStreamEffectSubject])
        .pipe(filter((values) => !values.some((v) => !v)))
        .subscribe(this._connectSourceToEffectStream.bind(this))
    )

    this._processingSubscriptions.push(
      // When the effect is applied we make sure that there is no
      // output as long as there is no input or effect.
      combineLatest([this.in$, this._mediaStreamEffectSubject])
        .pipe(filter((values) => values.some((v) => !v)))
        .subscribe(this._disconnectSourceFromEffectStream.bind(this))
    )

    if (!this.initialised) {
      from(
        backgroundEffectSingleton.build(
          this._drawingStrategySubject.value || undefined,
          this._frameProcessingStrategySubject.value || undefined
        )
      ).subscribe((value) => {
        this._mediaStreamEffectSubject.next(value)
      })
    }
  }

  _bypass() {
    super._bypass()

    this._stopEffect()
    this._restoreCurrentTracksOriginalConstraints()
  }

  /**
   * @param param0
   * @returns {Promise<void>}
   * @private
   */
  async _connectSourceToEffectStream([track, effect]) {
    // When there is already a mediaStreamEffect active, we stop it
    if (effect.outputStream) {
      effect.stopEffect()
    }

    this.originalConstraints = track.track.getConstraints()

    // Merge the source track constraints with the background effect specific ones
    const constraints = { ...this._originalConstraints, ...this._constraints }

    // Attempt to start the effect with the source stream
    try {
      await track.track.applyConstraints(constraints)

      const stream = await effect.startEffect(track.stream, false, 30_000)

      const effectTracks = stream.getVideoTracks()

      this._outSubject.next(
        coligoTrackFactory.createTrack(coligoTrackFactory.TYPES.LOCAL, {
          track: effectTracks[0],
          stream,
          // TODO: Check if original stream can be the stream from the source track.
          originalStream: stream,
        })
      )
    } catch (e) {
      track.track.applyConstraints(this._originalConstraints)

      // Rethrow the exception for error handling by the caller
      this._runningSubject.error(e)
    }
  }

  /**
   * @private
   */
  _disconnectSourceFromEffectStream() {
    this._outSubject.next(null)

    this._stopEffect()
    this._restoreCurrentTracksOriginalConstraints()
  }

  /**
   * @returns {Promise<void>}
   * @private
   */
  async _restoreCurrentTracksOriginalConstraints() {
    const inSource = this._inSubject.getValue()

    if (inSource) {
      await inSource.track.applyConstraints(this._originalConstraints)
    }
  }

  /**
   * @private
   */
  _stopEffect() {
    if (this.initialised) {
      const effect = this._mediaStreamEffectSubject.getValue()

      if (effect && effect.outputStream) {
        effect.stopEffect()
      }
    }
  }

  dispose() {
    super.dispose()

    this.originalConstraints = null

    if (this.initialised) {
      this._mediaStreamEffectSubject.value.dispose()
    }
    this._mediaStreamEffectSubject.complete()
  }
}

export default BackgroundEffectProcessor
