import queryString from 'qs'
import _ from 'lodash'

import { COLIGO_PRD } from 'constants/constants'

import browserUtils from 'lib/browser-utils'

function getOrigin() {
  return /^(file:\/\/)/.test(window.location.origin)
    ? COLIGO_PRD
    : window.location.origin
}

/**
 * Params from the url which are not persistent.
 * This means that after creating a new meeting pathname,
 * these params will be filtered out.
 * @type {string[]}
 */
const PROHIBITED_PERSISTENT_PARAMS = ['token', 'pin']

/**
 * Parse the given query string to a key value Object containing
 * all the params and its values.
 * @param [query=window.location.search]
 * @returns
 */
function parseQueryString(query = window.location.search) {
  return queryString.parse(query, { ignoreQueryPrefix: true })
}

/**
 * Get a string of all search params.
 *
 * @param [location=window.location]
 * @returns
 */
function getSearch(location = window.location) {
  let { search } = location

  if (browserUtils.usesHashHistory()) {
    // When the browser uses hash history, the search is in the hash.
    const matches = location.hash.match(/\?([a-zA-Z0-9]*=.+?$)/)

    search = matches?.[1] ?? search
  }

  return search
}

/**
 * Get the params defined in the search.
 */
function getUrlParams(location = window.location) {
  const search = getSearch(location)
  return parseQueryString(search)
}

/**
 * Omit prohibited params out of the given params object.
 *
 * @param params
 * @param [paramNames=PROHIBITED_PERSISTENT_PARAMS] Specific params which should be filtered out of the params
 * @return
 */
function filterParams(params, paramNames = PROHIBITED_PERSISTENT_PARAMS) {
  return _.omit(params, paramNames)
}

/**
 * Get the params defined in the search but without prohibited persistent params.
 *
 * @param [location=window.location]
 * @param [paramNames=PROHIBITED_PERSISTENT_PARAMS] Specific params which should be filtered out of the params
 * @return
 */
function getFilteredUrlParams(
  location = window.location,
  paramNames = PROHIBITED_PERSISTENT_PARAMS
) {
  const params = getUrlParams(location)

  return filterParams(params, paramNames)
}

/**
 * Return the identifier part for a pathname formatted as "/meeting/:identifier"
 * TODO: why not have router fix for us?
 *
 * @param pathname
 * @returns
 */
function getMeetingIdentifierFromPathname(pathname = window.location.pathname) {
  const matches = pathname.match(/[/]meeting\/([^/?]+)/)

  return (matches?.[1] || '').toLowerCase()
}

/**
 * Gets the meeting identifier (called pin) from pathname query params or
 * returns empty string.
 *
 * @param params
 * @returns
 */
function getMeetingIdentifierFromParams(params = getUrlParams()) {
  return (params?.pin || '').toLowerCase()
}

/**
 * Gets the meeting identifier from pathname, either from path or from query params
 *
 * @param pathname
 * @param [params=getUrlParams()]
 * @returns
 */
function getMeetingIdentifier(
  pathname = window.location.pathname,
  params = getUrlParams()
) {
  const meetingIdentifierFromParams = getMeetingIdentifierFromParams(params)

  if (meetingIdentifierFromParams) {
    return meetingIdentifierFromParams
  }

  return getMeetingIdentifierFromPathname(pathname)
}

/**
 * Stringify the given map of params to a query string.
 * @param params
 * @param]
 * @returns
 */
function stringifySearchParams(params, options = { addQueryPrefix: true }) {
  return queryString.stringify(params, options)
}

/**
 * Function to create a link to the meeting page based on the given meeting identifier.
 *
 * @param meetingIdentifier
 * @return
 */
function getNewMeetingPathname(meetingIdentifier) {
  const filteredParams = getFilteredUrlParams(window.location)

  const search = stringifySearchParams(filteredParams)

  return `/meeting/${meetingIdentifier}${search}`
}

/**
 * Parse params from the url using callbacks to transform the value
 * @param config for params to parse from current url
 * @returns {Object.<string, *>} collection of params parsed from url
 */
function parseParams(filter, params = getUrlParams()) {
  if (!filter || typeof filter !== 'object' || _.isEmpty(filter))
    throw new Error(
      'param filter invalid, expected collection of param keys with callbacks for mapping each found url param'
    )
  return _.mapValues(filter, (v, k) => {
    if (typeof v === 'function') {
      return _.has(params, k) ? v(_.get(params, k)) : undefined
    }
    return _.get(params, k)
  })
}

/**
 * create link to the dial-in page based on the given roompin
 *
 * @param [roomID='']
 * @return dial-in link
 */
function getDialInUrl(roomID = '') {
  const meetingLocation = getOrigin()
  return `${meetingLocation}/dialin/${roomID}`
}

/**
 * create link to the room based on the given roompin
 *
 * @param roomID
 * @param stripProtocol if true, strips protocol from url
 * @return room link
 */
function getRoomUrl(roomID, stripProtocol = false) {
  if (!roomID) throw new Error(`param roomID invalid: ${roomID}`)

  const meetingLocation = /^(file:\/\/)/.test(window.location.origin)
    ? COLIGO_PRD
    : window.location.origin

  let url = `${meetingLocation}/meeting/${roomID}`

  if (stripProtocol) {
    url = url.replace(/(^\w+:|^)\/\//, '')
  }

  return url
}

/**
 * @param linkData
 * @param linkData.protocol
 * @param linkData.address
 * @param linkData.port
 * @param linkData.path
 * @param linkData.token
 * @return
 */
function composeUrlFromLinkData(linkData) {
  if (!linkData.protocol) {
    throw new TypeError('No protocol given')
  }
  if (!linkData.address) {
    throw new TypeError('No adress given')
  }
  if (!linkData.port) {
    throw new TypeError('No port given')
  }
  if (!linkData.path) {
    throw new TypeError('No path given')
  }

  let url = `${linkData.protocol}${linkData.address}${linkData.port}${linkData.path}`

  if (linkData.token) {
    url += linkData.token
  }

  return url
}

/**
 * Removes semi-colon from string.
 * @param identifier
 * @return
 */
function removeSemiColons(identifier) {
  if (typeof identifier !== 'string') {
    return identifier
  }

  return identifier.replace(';', '')
}

export default {
  PROHIBITED_PERSISTENT_PARAMS,

  parseQueryString,
  filterParams,
  stringifySearchParams,
  getSearch,
  getFilteredUrlParams,
  getOrigin,
  getMeetingIdentifierFromPathname,
  getMeetingIdentifierFromParams,
  getMeetingIdentifier,
  getUrlParams,
  getNewMeetingPathname,
  parseParams,
  getDialInUrl,
  getRoomUrl,
  composeUrlFromLinkData,
  removeSemiColons,
}
